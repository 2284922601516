import React from 'react'
import SimpleFormio from '../../../../Misc/SimpleFormio/SimpleFormio';
import { useHistory } from 'react-router-dom';
import bascImg from '../../../../../assets/images/basc_voucher_reminder.png'

const WelcomePopup = (props) =>  {

    // create react-router history object to navigate user to other pages
    const history = useHistory();    

    // get data from props
    const submittedCentreId = props.submittedCentreId

    // determine message
    let welcomeMessage;
    if (+submittedCentreId === "never true") {
        welcomeMessage = (
            <div>
                <p>Active OOSH Kendall will be opening October 10th.</p>
                <p>Your account will be activated once your enrolment has been approved, 2-3 weeks prior to opening.</p>
                <p>Meanwhile, we’d really appreciate your feedback to help us improve our enrolment process!</p>
            </div>
        );
    } else {
        welcomeMessage = (
            <div>
                <p>Please allow 1-2 business days for us to review and approve your enrolment.</p>
                <p>Meanwhile, we’d really appreciate your feedback to help us improve our enrolment process!</p>
            </div>
        );

    }

    return (
        <div >
            <h3>Thanks for your enrolment!</h3>
            <hr/>
            {welcomeMessage}
            <SimpleFormio token={props.token} formType='enrolmentFeedback' centreId={submittedCentreId} onSubmitted={() => {history.replace('/account')}}/>
            <hr/>
            <div style={{textAlign: 'left', width: '50%'}}>
                <img alt="basc-voucher-reminder" src={bascImg} width="100%" />
            </div>
            <hr/>
            <h5>Apply for BASC Voucher Link</h5>            
            <a target="_blank" rel="noreferrer" href="https://www.service.nsw.gov.au/transaction/apply-basc-voucher">https://www.service.nsw.gov.au/transaction/apply-basc-voucher</a>
        </div>
    );
}

export default WelcomePopup;