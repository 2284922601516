import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';


// i18n
// import {I18nextProvider} from "react-i18next";
// import i18next from "i18next";
// import raw_en from './translations/en/raw.json';


// i18next.init({
//   interpolation: { escapeValue: false },  // React already does escaping
//   lng: 'en',                              // language to use
//   resources: {
//       en: {
//           raw: raw_en               // 'raw' is our custom namespace
//       }
//   },
// });


ReactDOM.render(
  <React.StrictMode>
      {/* <I18nextProvider i18n={i18next}>
      </I18nextProvider> */}
        <BrowserRouter>
        <App/>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();