import {React, Fragment, useEffect, useState, useRef} from 'react';
import classes from './BSTable.module.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter, multiSelectFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { bootstrapNumberFields, bootstrapTableConfig, bootstrapTableDateFields, bootstrapTableDateTimeFields, bootstrapTableTimeFields } from '../../utilities/globalObjects';
import { timezone } from '../../utilities/globalVariables';
import { arrayObjsToCsv, humanize } from '../../utilities/functions';
import {FaDownload} from "react-icons/fa";

// function to format values as specified
const formatDataValues = (arrayOfObjects, uniqueSelectId) => {
    // console.log('[BSTable.js] - Starting function formatDataValues');
    const returnArray = arrayOfObjects?.map(obj => {
        // deep copy obj
        const newObj = {...obj};

        // loop through each key, formatting where necessary
        Object.keys(newObj).forEach(key => {
            
            // check if value specified as one to format to only show time
            if (bootstrapTableTimeFields.includes(key)) {
                const value = newObj[key];
                if (value) {
                    // format value and update in obj
                    const newValue = new Date(value).toLocaleTimeString("sv-SW", {timeZone: timezone});
                    newObj[key] = newValue;
                }
            }

            // check if value specified to ensure number, not number stored as string
            if (bootstrapNumberFields.includes(key)) {
                const value = newObj[key];
                newObj[key] = +value ?? value;
            }

            // check if value specified to show date 
            if (bootstrapTableDateFields.includes(key)) {
                const value = newObj[key];
                if (value) {
                    // format value and update in obj
                    const newValue = new Date(value).toLocaleDateString("sv-SW", {timeZone: timezone});
                    newObj[key] = newValue;
                }                
            }

            // check if value specified to show date time
            if (bootstrapTableDateTimeFields.includes(key)) {
                const value = newObj[key];
                if (value) {
                    // format value and update in obj
                    const newValue = new Date(value).toLocaleString("sv-SW", {timeZone: timezone});
                    newObj[key] = newValue;
                }                
            }

        
        })

        // if uniqueSelectId passed in is an array, create concat'd column for unique selection
        if (uniqueSelectId?.includes('+')) {
            const columnsToConcat = uniqueSelectId.split('+');
            let newKeyValue = '';
            for (const col of columnsToConcat) {
                newKeyValue = newKeyValue + '_' + obj?.[col];
            }   

            // remove leading underscore
            newKeyValue = newKeyValue.slice(1);

            // add new key to data
            newObj[uniqueSelectId] = newKeyValue;
        }

        return newObj;        
    })

    // console.log('[BSTable] - formatDataValues returning: ', returnArray);
    return returnArray;
}


const BSTable = (props) => {

    // console.log('[BSTable.js] - rendering');

    const [columns, setColumns] = useState();
    const [formattedData, setFormattedData] = useState();
    // const [testExpanded, setTestExpanded] = useState([]);

    // get data from props, applying specified formatting
    // const data = formatDataValues(props.data);
    const data = props.data;
    const detailData = formatDataValues(props.detailData) ?? [{'no drilldown data specified': 'no drilldown data specified'}];
    const selected = props.selected;
    const table = props.table;
    const detailTable = props.detailTable;
    const expanded = props.expanded;
    const parentFilter = props.parentFilter;
    const enableEdit = props.enableEdit;
    const editOnBlur = props.editOnBlur;
    const updateFunction = props.updateFunction;
    const paginationConfig = props.paginationConfig;
    const overrideEditOptions = props.overrideEditOptions;
    const stickyHeader = props.stickyHeader;
    const onCellClick = props.onCellClick;
    const horizontalScroll = props.horizontalScroll;

    // console.log('updateFunction: ', updateFunction);
    
    // get functions from props
    const getDetailData = props.getDetailData;
    const setExpanded = props.setExpanded;
    const setSelected = props.setSelected;
    const clearDetailData = props.clearDetailData;

    // get unique id field for table (also links data to detail data if detail data specified) 
    const tableConfig = bootstrapTableConfig[table];
    const uniqueSelectId = tableConfig?.uniqueSelectId;
    const detailLinkingId = tableConfig?.detailLinkingId;
    const detailDataUniqueSelectId = bootstrapTableConfig[detailTable]?.uniqueSelectId;
    // console.log('[BSTable.js] - data: ', data);
    // console.log('[BSTable.js] - parentFilter: ', parentFilter);
    // console.log('[BSTable.js] - table: ', table);
    // console.log('[BSTable.js] - uniqueSelectId: ', uniqueSelectId);
    // console.log('[BSTable.js] - detailLinkingId: ', detailLinkingId);
    // console.log('[BSTable.js] - detailDataUniqueSelectId: ', detailDataUniqueSelectId);


    // select options as Function
    // const selectOptions = useCallback((column) => {
    //     const dataField = column.dataField;

    //     const returnArr = [];
    //     const uniqueValues = data.map(obj => obj[dataField]).filter(el => el).filter((x, i, a) => a.indexOf(x) === i);
    //     uniqueValues.forEach(el => {
    //         returnArr.push({
    //             value: el,
    //             label: el
    //         })
    //     });
        
    //     return  returnArr;


    // }, [data]); 

    // construct columns for main data
    let programmaticFilter = useRef();
    
    // const columns = [];
    useEffect(() => {

        // console.log('[BSTable.js] - running main useEffect');

        // console.log('[BSTable.js] - calling formatDataValues for data: ', data);
        const newFormattedData = formatDataValues(data, uniqueSelectId);
        // console.log('newFormattedData: ', newFormattedData);

        // if no data passed in, abort column creation
        if (!newFormattedData?.length > 0) {
            console.log('[BSTable.js] - formatted data for table not > 0 len, aborting table creation');
            return false
        }

        const newColumns = [];

        // get screen width
        const containerDiv = document.querySelector('#container'); // Replace 'container' with the ID of your container div
        const screenWidth = containerDiv?.offsetWidth ?? (window?.innerWidth * 0.8);
        console.log('screenWidth: ', screenWidth);

        // get column lengths for dynamic width setting
        const columnLengths = {};
        Object?.keys(newFormattedData?.[0])?.forEach(key => {
            if (!tableConfig?.excludeColumns?.includes(key)) {
                const maxLengthInColumnArr = newFormattedData.map(obj => obj[key]?.length).filter(el => el);
                const maxLengthInColumn = maxLengthInColumnArr.length > 0 ?  Math.max(...maxLengthInColumnArr) : 1;
                const cappedMaxLength = Math.min(40, maxLengthInColumn);
                columnLengths[key] = cappedMaxLength;

            }
        });
        const sumColumnLengths = Object.values(columnLengths).reduce((a, b) => a + b);
        console.log('sumColumnLengths: ', sumColumnLengths);
        const minCap = screenWidth / sumColumnLengths;
        const numColumns = Object.keys(columnLengths)?.length;
        
        // const screenWidth = 

        // console.log('columnLengths: ', columnLengths);
        // console.log('sumColumnLengths: ', sumColumnLengths);
        // console.log('minCap: ', minCap);

        Object.keys(newFormattedData[0]).forEach(key => {

            
            // add column to list of columns to display if not specified in exclusions
            if (!tableConfig?.excludeColumns?.includes(key)) {

                // console.log('KEY : ', key);
    
                // get max length of values in this column
                // const numColumns = Object.keys(newFormattedData[0]).filter(key => !tableConfig?.excludeColumns?.includes(key))?.length;
                // console.log('numColumns: ', numColumns);
                // const maxLengthInColumnArr = newFormattedData.map(obj => obj[key]?.length).filter(el => el);
                // const maxLengthInColumn = maxLengthInColumnArr.length > 0 ?  Math.max(...maxLengthInColumnArr) : 1;
                // console.log(key, maxLengthInColumn);
                
                // const avgSize = 100 / numColumns;
    
                // get column name size
                const columnNameLength = key?.length;
                const minHeaderWidthPx = columnNameLength * 8;
                // console.log('minHeaderWidthPx: ', minHeaderWidthPx);
                
                // set default header style
                const minPxForCharsInColumn = columnLengths[key] * 5;
                const minHeaderAndValues = Math.max(minHeaderWidthPx, minPxForCharsInColumn);

                // const widthPct = Math.min(Math.max(7, proportion), 60);
                
                // calculate what width PCT would correspond to on current screen size
                // const proportion = columnLengths[key] / sumColumnLengths * 100;
                // let widthPct = proportion;
                // const widthPctInPx = screenWidth * widthPct / 100;
    
                let finalWidth;
                if (!horizontalScroll) {
                    
                    const proportion = columnLengths[key] / sumColumnLengths * 100;
                    let widthPct = Math.max(minCap, proportion);
                    if (numColumns > 6) {
                        widthPct = Math.min(20, widthPct);
                    }

                    finalWidth =  widthPct + '%';
                    // console.log(`key ${key} - finalWidth: ${finalWidth}`)

                } else {
                    if (numColumns <= 6) {
                        finalWidth =  100 / numColumns +  '%';
                    } else {
                        finalWidth = minHeaderAndValues + 'px'
                    }

                }
                // } else if (widthPctInPx < minHeaderAndValues) {
                //     finalWidth = minHeaderAndValues + 'px'
                // } else {
                //     finalWidth = widthPctInPx + 'px';
                // }
                // const widthPct = proportion
                // console.log(key, proportion);
                const headerStyle = {
                    verticalAlign: 'top',
                    // width: widthPct + '%',
                    width: finalWidth
                }
                
    
                // set extraWidthCutoff
                // const extraWidthCutoff = 9;
                // const idColumnCutoff = 5;
        
                // if column values max length above extraWidthCutoff, increase column width proportionally
                // if (maxLengthInColumn === 1) {
                //     headerStyle.width = '5%';
                // } else if (maxLengthInColumn > extraWidthCutoff) {
                //     headerStyle.width = Math.min(maxLengthInColumn * 5, extraWidthCutoff * 15) + 'px';
                // } else if (maxLengthInColumn <= idColumnCutoff) {
                //     headerStyle.width = '60px';
                // }
                // else {
                //     headerStyle.width = maxLengthInColumn * 10;
                // }
        
                // if column values max length above extraWidthCutoff, set whitespace to wrap text to make easier to read
                const style = {
                    whiteSpace: 'normal'
                };
                // if (maxLengthInColumn > extraWidthCutoff * 2) {
                //     style.whiteSpace = 'normal'
                // }
                
                // check if we want to hide filter
                let filter;
                const filterConfig = tableConfig?.filterColumns?.filter(obj => obj?.columns?.includes(key))?.[0];
                

                if (filterConfig) {

                    const filterType = filterConfig?.filterType;
                    const defaultFilterConfig = tableConfig?.defaultFilters?.filter(obj => obj?.columns?.includes(key))?.[0];

                    // define default filter options
                    const filterOptions = {
                        withoutEmptyOption: false,
                    };

                    // add default filter value if specified
                    if (defaultFilterConfig) {
                        filterOptions.defaultValue = defaultFilterConfig?.value;
                    }
                    
                    // add reference to programatic filter function on specified column
                    if (parentFilter?.column === key) {
                        
                        filterOptions.getFilter = (filter) => {
                            programmaticFilter.current = filter;
                        }
                    }


                    // add filter if required
                    if (filterType === 'text') {

                        filterOptions.className = classes.SelectFilter;
                        filter = textFilter(filterOptions);

                    } else if (filterType === 'select') {
                        
                        const selectOptions = (column) => {
                            const dataField = column.dataField;
                            const returnArr = [];
                            const uniqueValues = newFormattedData?.map(obj => obj[dataField])?.filter(el => el)?.filter((x, i, a) => a.indexOf(x) === i).sort((a,b) => {
                                if ( a < b )  return -1;
                                if ( a > b ) return 1;
                                return 0;
                            });
                            uniqueValues.forEach(el => {
                                returnArr.push({
                                    value: el,
                                    label: el
                                })
                            });
                            return  returnArr;
                        }     

                        filterOptions.options = selectOptions;
                        filterOptions.className = classes.SelectFilter;
                        // const filterOptions = {
                        //     options: selectOptions,
                        //     className: classes.SelectFilter,
                        // };      

                        filter = selectFilter(filterOptions);


                    } else if (filterType === 'multi-select') {
                        
                        // const selectOptions = (column) => {
                        //     const dataField = column.dataField;
                        //     const uniqueValues = newFormattedData?.map(obj => obj[dataField])?.filter(el => el)?.filter((x, i, a) => a.indexOf(x) === i).sort((a,b) => {
                        //         if ( a < b )  return -1;
                        //         if ( a > b ) return 1;
                        //         return 0;
                        //     });
                        //     const returnObj = {};
                        //     uniqueValues.forEach((el, i) => {
                        //         returnObj[i] = el;
                        //     });
                        //     console.log('returnObj: ', returnObj);
                        //     return  returnObj;
                        // }     
                        // filterOptions.options = selectOptions;
                        
                        const dataField = key;
                        const uniqueValues = newFormattedData?.map(obj => obj[dataField])?.filter(el => el)?.filter((x, i, a) => a.indexOf(x) === i).sort((a,b) => {
                            if ( a < b )  return -1;
                            if ( a > b ) return 1;
                            return 0;
                        });
                        const returnObj = {};
                        uniqueValues.forEach((el, i) => {
                            returnObj[el] = el;
                        });
                        // console.log('returnObj: ', returnObj);

                        filterOptions.options = returnObj;
                        filterOptions.className = classes.SelectFilter;
                        // const filterOptions = {
                        //     options: selectOptions,
                        //     className: classes.SelectFilter,
                        // };      

                        filter = multiSelectFilter(filterOptions);
                    }

                    // filter = selectFilter({
                    //     options: selectOptions,
                    //     className: classes.SelectFilter,
                    //     getFilter: (filter) => {
                    //         programmaticFilter.current = filter;
                    //     }
                    // });
                }
                
                let conditionalProps = {};

                // config editable
                let editable = false;
                const editConfig = (overrideEditOptions) ? overrideEditOptions : tableConfig?.editableFields;
                if (editConfig) {
                    
                    // get editableFields object which corresponds to this column if exists
                    const columnEditConfig = editConfig.filter(obj => {
                        const cols = obj.columns;

                        for (const column of cols) {
                            
                            // wildcard search - currently only supports wildcards in format column*, which will allow editing on any column which starts with 'column' regardless of what comes after
                            if (column.includes('*')) {
                                const colToSearch = column.split('*')?.[0];
                                const keyToSearch = key.slice(0,colToSearch.length);
                                
                                if (colToSearch === keyToSearch) {
                                    // console.log('returning true as colToSearch = keyToSearch: ', colToSearch, keyToSearch)
                                    return true
                                }

                            } else {
                                // exact match
                                if (column === key) {
                                    // console.log('returning true as colummn = key: ', column, key)
                                    return true
                                }

                            }
                        }
                        
                        // otherwise no match for any of the specified colummns in this config object, return false
                        return false
                    })?.[0];

                    if (columnEditConfig) {
                        editable = true;
                        conditionalProps.editor = {type: Type[columnEditConfig.dataType]}
                        
                        // if type of cell edit is checkbox, make value true/false rather than default off/on
                        if (conditionalProps.editor.type === Type.CHECKBOX) {
                            conditionalProps.editor.value = 'true:false'
                        } else if (conditionalProps.editor.type === Type.SELECT) {
                            conditionalProps.editor.options = columnEditConfig?.options;

                        }
                    }
                }

                // add in cell click event if function passed in
                let events = {}
                if (onCellClick) {
                    events = {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                            onCellClick(e, column, columnIndex, row, rowIndex)
                        },
                    }
                }
                
                newColumns.push({
                    dataField: key, 
                    text: humanize(key), 
                    sort: true, 
                    filter: filter,
                    classes: props.dynamicClassFunction,
                    headerClasses: props.dynamicHeaderClassFunction,
                    headerStyle: headerStyle,
                    headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                        const headerLines = column.text?.split('\n');

                        // set header content based on whether new lines have been specified in data passed in
                        let headerClasses = [classes.HeaderItem];
                        if (headerLines?.length > 1) headerClasses.push(classes.HeaderItemNewLine)

                        return (
                          <div className={headerClasses?.join(' ')}>
                            {headerLines.map(line => <div>{line}</div>)}
                            {/* <p></p> */}
                            <div>{filterElement}</div>

                            {/* You can include other elements or customizations here */}
                          </div>
                        );
                      },
                    style: style,
                    editable: editable,
                    events: events,
                    ...conditionalProps

                });
    
                // selectFilter({
                //     options: selectOptions,
                //     className: classes.SelectFilter
                // });
    
                // if default filter passed in, apply filter to column/value combination
                // if (filterNeeded && parentFilter) {
                //     const column = parentFilter.column;
                //     const value = parentFilter.value;
                    
                //     // if column is specified column to apply default filter to, apply filter
                //     if (key === column) {
                //         filterOptions.value = value;
                //     }
                    
                // }
                
                
                
            }
        });

        setColumns(newColumns)
        setFormattedData(newFormattedData)
    }, [data, props.dynamicClassFunction, props.dynamicHeaderClassFunction, table, parentFilter, tableConfig, uniqueSelectId, overrideEditOptions, onCellClick, horizontalScroll])

    // console.log('programmaticFilter: ', programmaticFilter);

    // apply parentFilter if supplied
    useEffect(() => {
        // console.log('[BSTable.js] - running parent filter useEffect');
        
        if (programmaticFilter?.current && parentFilter?.value) {
            // console.log('[BSTable.js] - applying parent filter function: ', programmaticFilter?.current);
            // console.log('[BSTable.js] - applying parent filter with value: ', parentFilter?.value);
            try {
                programmaticFilter.current(parentFilter?.value)
            } catch (e) {
                console.log('[BSTable.js] - error whilst trying to set parent filter: ', e);
            }
        }
        
    }, [parentFilter]);

    // don't load data table if no unique ID specified
    if (!uniqueSelectId) {
        console.error('no unique ID specified for table, risk of unexpected rendering behaviour and therefore returning false')
        return false;
    }

    // construct columns for detailed data if exists
    const detailColumns = [];
    if (detailData) {
        Object.keys(detailData[0]).forEach(key => {

            // add column to list of columns to display if not specified in exclusions
            if (!bootstrapTableConfig[detailTable]?.excludeColumns?.includes(key)) {
                detailColumns.push({dataField: key, text: humanize(key)})
            }

        });
    }

    

    // console logs
    // console.log('data: ', data);
    // console.log('selected: ', selected);
    // console.log('detailData: ', detailData);
    // console.log('expanded: ', expanded);



    // function to handle single row selects
    const handleOnSelect = (row, isSelect) => {

        // only handle select if a function has been passed in to update selected values
        if (setSelected) {
            
            if (isSelect) {
              setSelected([...selected, row[uniqueSelectId]]);
            } else {
              setSelected(selected.filter(x => x !== row[uniqueSelectId]));
            }
        }
        // console.log('row: ', row);
    }
    
    // function to handle all row selects
    const handleOnSelectAll = (isSelect, rows) => {

        
        // only handle select if a function has been passed in to update selected values
        if (setSelected) {
            const ids = rows.map(r => r[uniqueSelectId]);
            if (isSelect) {
                setSelected(ids);
            } else {
                setSelected([]);
            }
        }
    }


    const handleOnExpand = (row, isSelect) => {
        // console.log('row: ', row);
        
        // call function in parent component to get detail data for selected row if we have a set expanded function passed in 
        if (setExpanded) {
            
            if (isSelect) {
                getDetailData(detailLinkingId, row[detailLinkingId]);
                setExpanded([row[uniqueSelectId]]);
            } else {
                setExpanded(expanded.filter(x => x !== row[uniqueSelectId]));
                clearDetailData();
            }
            
        } 
    }

    // object bootstrap table needs to handle selects
    let clickToExpand = false;
    if (setExpanded) {
        clickToExpand = true;
    }


    let conditionalProps = {};

    if (setSelected) {
        conditionalProps.selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            clickToExpand: clickToExpand,
            selected: selected,
            onSelect:  handleOnSelect,
            onSelectAll: handleOnSelectAll,
            hideSelectColumn: uniqueSelectId ? false : true
        };
    }


    // const selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: false,
    //     clickToExpand: clickToExpand,
    //     selected: selected,
    //     onSelect:  handleOnSelect,
    //     onSelectAll: handleOnSelectAll,
    //     hideSelectColumn: uniqueSelectId ? false : true
    // };

    if (setExpanded && detailLinkingId && detailDataUniqueSelectId) {
        conditionalProps.expandRow = {
            renderer: row => (
                <BootstrapTable 
                    keyField={detailDataUniqueSelectId ?? 'id'} 
                    data={ detailData } 
                    columns={detailColumns} 
                    condensed
                    bootstrap4
                    rowClasses={classes.Row}
                />         
            ),
            expanded: expanded,
            onExpand: handleOnExpand,
            showExpandColumn: true,
            expandByColumnOnly: false
        };
    } else {
        console.log('One of setExpanded, detailLinkingId, detailDataUniqueSelectId missing, no expand function added');
    }

    if (paginationConfig) {
        conditionalProps.pagination =  paginationFactory(paginationConfig)
    }
    
    // const expandRow = {
    //     renderer: row => (
    //         <BootstrapTable 
    //             keyField={detailDataUniqueSelectId} 
    //             data={ detailData } 
    //             columns={detailColumns} 
    //             condensed
    //             bootstrap4
    //             rowClasses={classes.Row}
    //         />         
    //     ),
    //     expanded: expanded,
    //     onExpand: handleOnExpand,
    //     showExpandColumn: (detailLinkingId && detailDataUniqueSelectId) ? true : false,
    //     expandByColumnOnly: false
    // };

    // get defaultsorted from bootstrap config if present
    const defaultSorted = tableConfig?.defaultSorted ?? [];

    // console.log('columns: ', columns);

    let tableContent = <div></div>;



    // handle cell editing override options
    let cellEdit = {};
    if ( enableEdit && updateFunction && editOnBlur) {
        cellEdit = cellEditFactory({ mode: 'dbclick', blurToSave: true, afterSaveCell: (oldValue, newValue, row, column) => updateFunction(oldValue, newValue, row, column) })
    } else if (enableEdit && updateFunction) {
        cellEdit = cellEditFactory({ mode: 'dbclick', afterSaveCell: (oldValue, newValue, row, column) => updateFunction(oldValue, newValue, row, column) })
    }

    // handle sticky header options
    let headerWrapperClasses;
    if (stickyHeader) {

        // set specific header class based on child component using
        if (stickyHeader === 'AdminRoll') {

            headerWrapperClasses = classes.StickyHeaderAR;
        } else if (stickyHeader === 'RollActivityLog') {

            headerWrapperClasses = classes.StickyHeaderRAL;
        }
    }

    // const rowEvents = {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //         onCellClick(e, column, columnIndex, row, rowIndex)
    //     }
    // };    
    
    if (columns && formattedData) {
        tableContent = (
            <Fragment>
                <div className={classes.HeaderButtonRowRight}>
                    <button className="btn btn-success btn-sm" onClick={() => arrayObjsToCsv(formattedData?.filter(obj => selected?.includes(obj[uniqueSelectId])))}><FaDownload size={'.8rem'} color={'white'} /> </button>
                </div>
                <div className={classes.HeaderButtonRowLeft}>
                    <span>{selected?.length} / {formattedData?.[0]?.[uniqueSelectId] ? formattedData?.length : 0}</span>
                </div>

                <div id='container'>

                    <BootstrapTable 
                        keyField={uniqueSelectId} 
                        data={ formattedData } 
                        columns={ columns }
                        classes={classes.Table}
                        filter={ filterFactory() }
                        rowClasses={classes.Row}
                        headerClasses={classes.Header}
                        headerWrapperClasses={headerWrapperClasses}
                        striped 
                        bootstrap4 
                        condensed
                        bordered={ false }
                        defaultSorted={defaultSorted}
                        cellEdit={cellEdit}
                        // rowEvents={onCellClick ? rowEvents : {}}
                        // cellEdit={( enableEdit && updateFunction) ? cellEditFactory({ mode: 'dbclick', afterSaveCell: (oldValue, newValue, row, column) => updateFunction(oldValue, newValue, row, column) }) : {} }
                        // pagination={ (paginationConfig) ? paginationFactory() : {}}
                        // cellEdit={( enableEdit && updateFunction) ? cellEditFactory({ mode: 'dbclick'}) : {} }
                        // expandRow={ expandRow }
                        // selectRow={selectRow} 
                        {...conditionalProps}
                    />  
                </div>
            </Fragment>
        );

    }

    return tableContent;
}

export default BSTable;

