import React, { useCallback,useEffect,useState } from 'react'
import classes from './IncompleteAccounts.module.css';
import { useHistory, useLocation } from "react-router-dom";
import SubmissionsTable from '../SubmissionsTable/SubmissionsTable';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../../utilities/globalVariables';
import axios from 'axios';
import { handleAwsApiError, 
    // handleBannerText 
} from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import Banner from '../../../../UI/Banner/Banner';
// import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import { Fragment } from 'react';
// import { bootstrapTableConfig } from '../../../../utilities/globalObjects';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
// import { Form } from 'react-formio';
import {IoRefreshOutline} from "react-icons/io5";


const IncompleteAccounts = (props) =>  {

    // data state
    // const [viewingTable] = useState('view_accounts_missing_fields') 
    const [incompleteAccountData, setIncompleteAccountData] = useState();

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText] = useState();
    const [selectedCentre, setSelectedCentre] = useState();

    // Bootstrap table state
    const [selected, setSelected] = useState([]);      

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    // get data from props
    const centres = props.centres;    

    console.log('incompleteAccountData:', incompleteAccountData);
    console.log('selectedCentre:', selectedCentre);


    const getMasterData = useCallback(async (table, setStateFn, conditions, noLoading) => {
        
        noLoading ? void(0) : setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        noLoading ? void(0) : setLoading(false);

    }, [history, props, location]);  
    



    // fetch data on centre change
    useEffect(() => {

        if (selectedCentre) {
            getMasterData('view_accounts_missing_fields', setIncompleteAccountData, {column: 'centre_id', value: selectedCentre});
        }
            
    }, [selectedCentre, getMasterData])

    // transform incomplete account data into more user friendly view
    let transformedIncompleteAccountData;
    if (incompleteAccountData?.length > 0) {

        const accountIds = incompleteAccountData.map(obj => obj.account_id).filter((x,i,a) => a.indexOf(x) === i);

        const newData = accountIds.map(accountId => {

            const matchingRows = incompleteAccountData.filter(obj => obj.account_id === accountId);
            const children = matchingRows.map(obj => obj.child_name).join(', ');
            const accountName = matchingRows?.[0]?.account_name;
            const accountEmail = matchingRows?.[0]?.account_email;
            const accountContact = matchingRows?.[0]?.account_contact;

            let countMissingFields = 0;
            matchingRows.forEach(row => {
                const keys = Object.keys(row);
                const missingFields = keys.filter(key => row[key] === true);
                const count = missingFields?.length;
                countMissingFields += count;
            })

            return ({
                account_id: accountId,
                account_name: accountName,
                account_contact: accountContact,
                account_email: accountEmail,
                children: children,
                count_missing_fields: countMissingFields

            });
        })

        transformedIncompleteAccountData = newData.sort((a,b) => b.count_missing_fields - a.count_missing_fields);

    }


    // render service choices
    const services = (
        <select className='form-control' value={selectedCentre ?? ''} onChange={(e) => setSelectedCentre(e.target.value)}>
            <option disabled value=''>Select Service</option>
            {centres.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
        </select>        
    );      

    let content = (
        <Fragment>
            <h3>Incomplete Accounts</h3>
            <hr/>         
            <div className={classes.HeaderButtonRow}>
                <button className='btn btn-sm btn-secondary' onClick={() => false}><IoRefreshOutline size={'1rem'} color={'white'} /></button>
            </div>
            <hr/>         
            <p><i>Select a centre to view list of incomplete accounts</i></p>
            <div className={classes.Row}>
                {services}
            </div>   

            {transformedIncompleteAccountData && <Fragment> <SubmissionsTable table={'calculated-incomplete-account-data-view'} selected={selected} setSelected={setSelected} data={transformedIncompleteAccountData}/> </Fragment>}

            
        </Fragment>

    );
    
    if (loading) {
        content = <SpinnerDark />;
    }

    return (
        <div className={classes.IncompleteAccounts}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Banner show={bannerText}>{bannerText}</Banner> 
            {content}
        </div>
    );
}

export default IncompleteAccounts;
