import React from 'react';
import Modal from '../Modal/Modal';

// import classes from './Prompt.module.css';

const Prompt = (props) => {

    // handle optional link, which if present should add a "click here" to subtitle
    const link = props?.data?.link;
    const subtitle = props?.data?.subtitle;
    const customTitle = props?.data?.customTitle;
    const subtitle2 = props?.data?.subtitle2;
    const link2 = props?.data?.link2;

    // configure title
    let title = <h6>Are you sure you want to {props.data.message}?</h6>;
    if (customTitle) {
        title = <h6>{customTitle}</h6>
    }

    let linkTag;
    if (link) {
        linkTag = <a href={link} target="_blank" rel="noreferrer"> click here.</a>
    }
    let linkTag2;
    if (link2) {
        linkTag2 = <a href={link2} target="_blank" rel="noreferrer"> click here.</a>
    }

    return (
        <Modal showOnTop show={props.data.show} modalClosed={() => props.setData({...props.data, show: false})}>
            {title}
            <hr/>
            {subtitle && <p ><i>{subtitle} {linkTag}</i></p>}
            {subtitle2 && <p ><i>{subtitle2} {linkTag2}</i></p>}
            {(subtitle?.length > 100 || subtitle2?.length > 100) && <hr/>}
            <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
                <button onClick={() => props.setData({...props.data, show: false})} className="btn btn-outline-danger" style={{flex: 1}}>Go Back</button>
                <button onClick={() => {props.data.proceedFunction(); props.setData({...props.data, show: false});}} className="btn btn-success" style={{flex: 1}}>Proceed</button>
            </div>
        </Modal>   

    );
};


  
export default Prompt;