import { useState, useEffect, Fragment, } from 'react';
// import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
// import { handleAwsApiError } from '../../../utilities/functions';
// import { useHistory, useLocation } from "react-router-dom";
// import Modal from '../../../UI/Modal/Modal';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import { raw } from '../../../translations/en/raw';
import { autoApprovedForms } from '../../../utilities/globalObjects';
import {  useHistory } from 'react-router-dom';
import { checkForArrayChanges } from '../../../utilities/functions';



const Notifications = (props) => {

    // HOC notification state
    const [newNotifications, setNewNotifications] = useState();

    // get data from props
    const formSubmissionData = props.formSubmissionData;
    const permissionSlipData = props.permissionSlipData;
    const childrenData = props.childrenData;
    const entitlementsData = props.entitlementsData;
    const setNotifications = props.setNotifications;
    const setPermissionSlipId = props.setPermissionSlipId;
    const setDisplayContent = props.setDisplayContent;
    // const primaryGuardianData = props.primaryGuardianData;
    const missingAccountData = props.missingAccountData;
    // const secondaryGuardianData = props.secondaryGuardianData;

    let history = useHistory();
    // let newNotifications;

    // console logs
    // console.log('[Notifcations.js] - permissionSlipData: ', permissionSlipData);
    console.log('[Notifcations.js] - formSubmissionData: ', formSubmissionData);
    // console.log('[Notifcations.js] - newNotifications: ', newNotifications);

    // set app.js notifications any time local state "newNotifications"  is updated
    useEffect(() => {
        setNotifications(newNotifications);
    }, [newNotifications, setNotifications])

    // effect to determine notifications to show accountOwner based on pending requests and approved requests
    useEffect(() => {

        const newNots = [];

        // missing fields to flag
        if (missingAccountData?.length > 0) {

            // const numChildren = missingAccountData.map(obj => obj.child_id).filter((x,i,a) => a.indexOf(x) === i)?.length;
            // const missingFields = 

            // check how many children are missing data
            // const childIds = missingAccountData.map(obj => {
            //     const childRecord = childrenData.filter(o => o.child_id === obj.child_id)?.[0];
            //     const childName = childRecord?.first_name;
            //     return childName;
            // });



            // newNots.push({title: 'Incomplete Account Data', description: 'Please complete account data relating to children: ' + childIds.join(', '), icon: 'personal-details', type: 'action', onClick: () => history.replace("/account/parentData")})
            newNots.push({title: 'Incomplete Account Data', description: 'Account setup required to complete missing information', icon: 'personal-details', type: 'action', onClick: () => history.replace("/account/parentData")})
        }        

        // entitlement changes notifications
        if (entitlementsData?.length > 0) {
            // get child ids
            const childIds = entitlementsData.map(obj => +obj.child_id).filter((x, i, a) => a.indexOf(x) === i);

            // check for each child whether key entitlements have been changed
            let changed = false;
            for (const childId of childIds) {
                const entitlements = entitlementsData.filter(obj => +obj.child_id === childId);
                const ccs = entitlements.map(obj => obj.rebate_pct);
                const accs = entitlements.map(obj => obj.accs_hourly_rate_cap_increase_pct);
                changed = changed || checkForArrayChanges([ccs, accs]);
            }

            // if any changes, alert user
            if (changed) {
                newNots.push({title: 'CCS Entitlement Change', description:  'Please check CCS entitlement table for details', icon: 'warning', type: 'action', onClick: () => setDisplayContent('entitlements')});
        
            }
        }  

        // permission slip notifications
        if (permissionSlipData?.length > 0 && childrenData?.length > 0) {
            const outstandingPermissionSlips = permissionSlipData.sort((a,b) => a.child_id - b.child_id);
            outstandingPermissionSlips.forEach(obj => {
                const name = childrenData.filter(child => child.child_id === obj.child_id)[0].first_name;
                newNots.push({title: 'Outstanding Permission Slip', description:  new Date(obj.date).toLocaleDateString() + ' - ' + name, icon: 'signature', type: 'action', onClick: () => setPermissionSlipId(obj.id)});
            });
        }
 
 
        let newApprovedForms, newPendingForms, newActionRequiredForms;
        if (formSubmissionData) {
            newActionRequiredForms = formSubmissionData.filter(obj => !obj.approved && obj.status !== 'submitted' && !autoApprovedForms.includes(obj.form_type));
            newPendingForms = formSubmissionData.filter(obj => !obj.approved && obj.status === 'submitted' && !autoApprovedForms.includes(obj.form_type));
            newApprovedForms = formSubmissionData.filter(obj => obj.approved && obj.form_type !== 'updateBookingSchedule'); // temporary hardcoding of booking schedule change here before adding to autoapprovedforms list, so during changeover period any forms submitted before autoapproal logic addition will still show in approvals centre to be approved
        };
        console.log('newApprovedForms: ', newApprovedForms);
 

        // action required forms (i.e. bulk enrol)
        if (newActionRequiredForms?.length > 0) {
            newActionRequiredForms.forEach(obj => {
                newNots.push({title: 'Form Needing Action', description: (raw[obj.form_type] ?? obj.form_type) + ' #' + obj.form_id, icon: 'form', type: 'action', onClick: () => history.replace("/enrol")});
            });
        }
        
        // approved forms
        if (newApprovedForms?.length > 0) {
            newApprovedForms.forEach(obj => {
                newNots.push({title: 'Approved Form', description: (raw[obj.form_type] ?? obj.form_type) + ' # ' + obj.form_id + ' - ' + obj.child_first_name, icon: 'form', type: 'approved'});
            });
        }

        // pending forms
        if (newPendingForms?.length > 0) {
            newPendingForms.forEach(obj => {
                newNots.push({title: 'Pending Form', description: (raw[obj.form_type] ?? obj.form_type)+ ' #' + obj.form_id , icon: 'form', type: 'pending'});
            });
        }

        // ------------------------------------------------------
        // check for any missing data that parent needs to add/update 


        



            
        // SG data

        // child data

        // medical data

        // group auths data

        // emergency contacts?
        
        
        
        
        
        
        // ------------------------------------------------------

        setNewNotifications(newNots);

    }, [formSubmissionData, missingAccountData, childrenData, entitlementsData, permissionSlipData, setPermissionSlipId,setDisplayContent, history])  
    

    return <Fragment />;
}

export default Notifications;