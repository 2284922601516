import { React, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import classes from './SessionSignIns.module.css';
import { AWS_API, cognitoClientId, cognitoUserpoolId, public_s3_url, timezone } from '../../../utilities/globalVariables';
import { capitalizeFirstLetter, getAgeFromDateStr, handlePrompt, incrementDate, isBirthday, scrollPageToTop } from '../../../utilities/functions';
// import { useHistory, useLocation } from "react-router-dom";
// import Modal from '../../../UI/Modal/Modal';
// import centreIcon from '../../../assets/images/centre-icon.png';
// import busIcon from '../../../assets/images/bus-icon.png';
import centreIcon from '../../../assets/images/centre-roll-icon.png';
import busIcon from '../../../assets/images/bus-roll-icon-v2.png';
import { bscSessionLength, bscStartTime } from '../../../utilities/globalVariables';
import Modal from '../../../UI/Modal/Modal';
import SmallSpinnerDark from '../../../UI/SmallSpinnerDark/SmallSpinnerDark';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
// import { handleAwsApiError } from '../../../utilities/functions';
import { raw } from '../../../translations/en/raw';
import Prompt from '../../../UI/Prompt/Prompt';
import Drills from '../../Admin/Roll/Drills/Drills';
import Select from 'react-select'
import AddChildren from '../../Admin/AdminMgmt/AdminRoll/AddChildren/AddChildren';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import InitialIcon from '../InitialIcon/InitialIcon';
import { reservedRollGroupNames } from '../../../utilities/globalObjects';

// icons
import {AiOutlineWarning} from "react-icons/ai";
import {MdNoFood} from "react-icons/md";
import {FaNotesMedical} from "react-icons/fa";
import {FiCameraOff} from "react-icons/fi";
import u7icon from '../../../assets/images/u7-icon-v2.png'
import {BsPencilSquare} from "react-icons/bs";
import {GrDocumentMissing} from "react-icons/gr";
// import {FaBirthdayCake} from "react-icons/fa";
import birthdayIcon from '../../../assets/images/birthday.png';
import kindyIcon from '../../../assets/images/kindy.png';

import NewBanner from '../NewBanner/NewBanner';
import { useCallback } from 'react';
import ChildSummary from '../../Admin/Roll/ChildSummary/ChildSummary';
// import {RiNumber7} from "react-icons/ri";
// import {GiCard7Diamonds} from "react-icons/gi";

const SessionSignIns = (props) => {

    // data state
    const [updateRollIds, setUpdateRollIds] = useState([]);
    const [displayedRollIds, setDisplayedRollIds] = useState([]);
    const [photos, setPhotos] = useState({});
    const [userExperience, setUserExperience] = useState();
    const [comment, setComment] = useState();
    const [question, setQuestion] = useState("");
    
    // UI state
    const [prompt, setPrompt] = useState({});
    const [showDrillForm, setShowDrillForm] = useState();
    const [showSecondSignIn, setShowSecondSignIn] = useState(true);
    const [showAddChildren, setShowAddChildren] = useState();
    const [childrenStatusCounts, setChildrenStatusCounts] = useState({});
    const [showManageGroups, setShowManageGroups] = useState();
    const [showKioskExperienceModal, setShowKioskExperienceModal] = useState(false);

    // filtering state
    const [displaySessionOptions, setDisplaySessionOptions] = useState([]);
    const [displayCentreOptions, setDisplayCentreOptions] = useState([]);
    const [displaySession, setDisplaySession] = useState();
    const [displayCentre, setDisplayCentre] = useState();
    const [displayLocation, setDisplayLocation] = useState();
    const [displayLocationOptions, setDisplayLocationOptions] = useState([]);
    const [displaySchool, setDisplaySchool] = useState();
    const [displaySchoolOptions, setDisplaySchoolOptions] = useState([]);
    const [displayGroupOptions, setDisplayGroupOptions] = useState([]);
    const [displayGroup, setDisplayGroup] = useState();
    const [displayStatus, setDisplayStatus] = useState();
    const [displayBusListOptions, setDisplayBusListOptions] = useState();
    const [displayBusList, setDisplayBusList] = useState();
    
    // sorting state
    const [sortField, setSortField] = useState('surname');
    const [sortOnFlags, setSortOnFlags] = useState('');

    // child summary state
    const [viewingChildSummaryId, setViewingChildSummaryId] = useState();
    const [viewingChildSummaryRollId, setViewingChildSummaryRollId] = useState();

    // ref data state
    const [schoolNameData, setSchoolNameData] = useState();

    // hoc state
    const [photosLoading, setPhotosLoading] = useState(false);
    // const [rollMarkingLoading, setRollMarkingLoading] = useState(false);

    // datetime state
    const [displayDate, setDisplayDate] = useState(new Date());

    // get data from props
    const centresData = props.centresData;
    const upcomingSessions = props.upcomingSessions;
    const childFlagsData = props.childFlagsData;
    const updateRoll = props.updateRoll;
    const headcountAllowed = props.headcountAllowed;
    const uploadPhoto = props.uploadPhoto;
    const logout = props.logout;
    const checkinAllowed = props.checkinAllowed;
    const absentAllowed = props.absentAllowed;
    const userData = props.userData;
    const parentView = props.parentView;
    const kioskView = props.kioskView;
    const multipleCentres = props.multipleCentres;
    const submitRollEvent = props.submitRollEvent;
    const adminFlag = props.adminFlag;
    const getHeadcounts = props.getHeadcounts;
    const childrenData = props.childrenData;
    const fetchAddNewChildrenData = props.fetchAddNewChildrenData;
    const writeBlockBooking = props.writeBlockBooking;
    const postMasterData = props.postMasterData;
    const parentCloseModalsFlag = props.parentCloseModalsFlag;
    const centreSessionData = props.centreSessionData;
    const questions = props.questions;
    // const submitKioskExperience = props.submitKioskExperience;
    // const kioskError = props.kioskError;
    // const submitRollAsMarked = props.submitRollAsMarked;
    // const isMobile= props.isMobile;

    // optional props only passed in on admin side
    const setSelectedCentreName = props.setSelectedCentreName

    // child specific data
    const clearChildSummaryData = props.clearChildSummaryData;
    const getChildSummaryData = props.getChildSummaryData;
    const childSummaryData = props.childSummaryData;
    const childSpecificChildFlagsData = props.childSpecificChildFlagsData;
    const childSpecificEmergencyContactData = props.childSpecificEmergencyContactData;

    // create react-router history object to navigate user to other pages
    // const history = useHistory();    

    // console logs
    // console.log('sortField: ', sortField);
    // console.log('sortOnFlags: ', sortOnFlags);
    // console.log('displayDate: ', displayDate);
    // console.log('displayGroupOptions: ', displayGroupOptions);
    // console.log('displayGroup: ', displayGroup);
    // console.log('updateRollIds: ', updateRollIds);
    // console.log('upcoming sessions: ', upcomingSessions);
    // console.log('displayLocationOptions: ', displayLocationOptions);
    // console.log('emergencyContactData: ', emergencyContactData);
    // console.log('displayStatus: ', displayStatus);
    // console.log('displayCentre: ', displayCentre);
    // console.log('childFlagsData: ', childFlagsData);
    // console.log('displaySchool: ', displaySchool);
    // console.log('childrenData: ', childrenData);
    // console.log('displayLocation: ', displayLocation);
    // console.log('rollNotes: ', rollNotes);
    // console.log('schoolname data: ', schoolNameData);
    // console.log('displayBusList: ', displayBusList);
    console.log('questions: ', questions);
    // 
    // get signed in user ID from passed in user data if component is being rendered in kiosk, otherwise don't pass this param to update roll and function will just use signed in user ID from context
    let signedInUserId;
    if (userData) {
        signedInUserId = userData.person_id;
    }
    useEffect(() => {
        if (questions && questions.length > 0){
                const min = Math.ceil(0);
                const max = Math.floor(questions.length);
                setQuestion(questions[Math.floor(Math.random() * (max - min) + min)].question);
        } else {
            setQuestion("How was your experience today?");
        }
    }, [questions]);
    
    // if (questions && !question){
    //     if (questions.length > 0){
    //         const min = Math.ceil(0);
    //         const max = Math.floor(questions.length);
    //         // setQuestion(questions[Math.floor(Math.random() * (max - min) + min)].question);
    //     }
    // } else {
    //     // setQuestion("How was your experience today?");
    // }
    // show child summary
    const handleShowChildSummary = (childId, rollId) => {
        setViewingChildSummaryId(childId);
        setViewingChildSummaryRollId(rollId);
        getChildSummaryData(childId);
        

    }
    
    // close child summary
    const handleCloseChildSummary = () => {
        setViewingChildSummaryId();
        clearChildSummaryData();
        setViewingChildSummaryRollId();

    }

    const closeModals = useCallback( () => {
        setShowAddChildren();
    }, []);

    // test closing modals from parent component
    useEffect(() => {
        closeModals();
    }, [parentCloseModalsFlag, closeModals])

    // get school id - name mappings from public s3 bucket
    useEffect(() => {
        const fetchSchoolNameData = async (ids) => {
            try {
                let schoolLookupObj = {};
                const res = await axios.get(public_s3_url + 'schools.json');
                // console.log('res: ', res.data);
                const filteredSchools = res.data.filter(obj => ids.includes(+obj.ACARAId))
                // console.log('filteredSchools: ', filteredSchools);
                filteredSchools.forEach(obj => schoolLookupObj[obj.ACARAId] =  obj.schoolName);
                setSchoolNameData(schoolLookupObj);
            } catch (err) {
                console.log('error while fetching school names: ', err);
            }           
        }

        if (upcomingSessions) {
            const schoolIds = upcomingSessions.map(obj => obj.school_child_attends).filter((x, i, a) => a.indexOf(x) === i);
            fetchSchoolNameData(schoolIds);
        }

    }, [upcomingSessions])

    // look for any groups children in current roll display have been added to
    useEffect(() => {

        let newDisplayedRollEntries = upcomingSessions
        ?.filter(obj => obj.session === displaySession && +obj.centre_id === +displayCentre && obj.date.slice(0,10) === displayDate.toLocaleDateString('sv-SW'))

        // console.log('displayDate: ', displayDate)
        // console.log('displayCentre: ', displayCentre)
        // console.log('displaySession: ', displaySession)
        // console.log('newDisplayedRollEntries: ', newDisplayedRollEntries);

        if (newDisplayedRollEntries?.length > 0) {
            // const displayedSessions = upcomingSessions.filter(obj => displayedRollIds.includes(obj.id));
            const rollGroups = newDisplayedRollEntries.filter(obj => obj.roll_group).map(obj => obj.roll_group).filter((x,i,a) => a.indexOf(x) === i);
            if (rollGroups?.length > 0) {
                rollGroups.unshift(reservedRollGroupNames[0]);
                rollGroups.push(reservedRollGroupNames[1]);
                setDisplayGroupOptions(rollGroups);
                setDisplayGroup(reservedRollGroupNames[0]);
            } else {
                setDisplayGroupOptions([]);
                setDisplayGroup();
            }
        }
    }, [upcomingSessions, displayDate, displaySession, displayCentre])


    // whenever date or centre changes, ensure session and location only show available options and default to sensible options
    useEffect(() => {
        // get current date and time to only show sessions happening in near future
        const today = new Date();
        const hourNow = today.getHours();
        // setDisplayDate(today);
        // console.log('hourNow: ', hourNow);
        // console.log('isplayDate.toLocaleDateString: ', displayDate.toLocaleDateString('sv-SW'));

        const upcomingSessionOnDisplayDate = upcomingSessions?.filter(obj => obj.date.slice(0,10) === displayDate.toLocaleDateString('sv-SW'));
        // console.log('upcomingSessionOnDisplayDate: ', upcomingSessionOnDisplayDate);

        
        // create array of locations - only show locations that exist at currently selected centre
        // console.log('[testing] - displayCentre: ', displayCentre);
        const centreName = upcomingSessions?.filter(obj => obj.centre_id === +displayCentre)?.[0]?.centre_name;
        const availableLocationsAtCentre = upcomingSessions?.filter(obj => obj.centre_name === centreName).map(obj => obj.location_type).filter((v, i, a) => a.indexOf(v) === i);
        // console.log('centreName: ', centreName);
        // console.log('availableLocationsAtCentre: ', availableLocationsAtCentre);
        const locationOptions = availableLocationsAtCentre;
        // const locationOptions = upcomingSessionOnDisplayDate.map(obj => obj.location_type).filter((v, i, a) => a.indexOf(v) === i);

        
        // console.log('locationOptions: ', locationOptions);
        setDisplayLocationOptions(locationOptions);
        setDisplayLocation(prevLocation => {
            
            // if there's only one option on selected day/session, display that
            if (locationOptions?.length === 1) return locationOptions[0];
            else if (prevLocation) return prevLocation;
            else return 'centre'
            // prevLocation ? prevLocation : 'centre'});
        });
        
        // // create array of centres (for when component being used by admin for multiple centres)
        // const centreOptions = upcomingSessions.map(obj => obj.centre_id).filter((v, i, a) => a.indexOf(v) === i).sort((a,b) => a-b);
        // console.log('centreOptions: ', centreOptions);
        // setDisplayCentreOptions(centreOptions);
        // setDisplayCentre(centreOptions[0]);  
        
        

        // set possible session options based on user type
        // const allSessionOptions = upcomingSessions.map(obj => obj.session).filter((v, i, a) => a.indexOf(v) === i).sort().reverse();
        const allSessionOptions = upcomingSessionOnDisplayDate?.map(obj => obj.session)?.filter((v, i, a) => a.indexOf(v) === i).sort().reverse();
        const openSessionOptions = allSessionOptions?.filter(el => {
            // console.log('bsc cuttoff: ', bscSessionLength + bscStartTime);
            if (hourNow <= bscStartTime + bscSessionLength + 3) {
                return el === 'bsc' || el === 'vac'
            } else {
                return el === 'asc' || el === 'vac'
            }        
        });
        // console.log('openSessionOptions: ', openSessionOptions);
        
        if (kioskView || parentView) {
            
            // parents and kiosk can only view "open" sessions
            setDisplaySessionOptions(openSessionOptions);
            setDisplaySession(openSessionOptions[0]);
            
            // create array of sessions happening on display date
            // console.log('sessionOptions: ', sessionOptions);
            
        } else {
            
            // centre staff and head office staff can see any session happneing today, but still default to the open session if exists
            setDisplaySessionOptions(allSessionOptions);
            setDisplaySession(prevSession => {
                if (prevSession && allSessionOptions.includes(prevSession)) {
                    // console.log('returning prevSession: ', prevSession);
                    return prevSession
                } else {
                    // console.log('returning new session: ', openSessionOptions[0] ?? allSessionOptions[0]);
                    return openSessionOptions?.[0] ?? allSessionOptions?.[0]
                }
            });
            
        }
        
    }, [upcomingSessions, displayDate, kioskView, parentView, displayCentre])

    // set display options which depend on other display options
    useEffect(() => {

        // create array of centres (for when component being used by admin for multiple centres)
        // const centreOptions = upcomingSessions.filter(obj => obj.location_type === displayLocation).map(obj => obj.centre_id).filter((v, i, a) => a.indexOf(v) === i)
        // .sort((a,b) => a - b);
        
        // if centres data passed in (i.e. from admin roll components) use that, otherwise use upcoming sessions
        let centreDataSource = (centresData?.length > 0) ? centresData : upcomingSessions;
        const centreOptions = centreDataSource?.filter(obj => obj.location_type === displayLocation)?.map(obj => obj.centre_id).filter((v, i, a) => a.indexOf(v) === i)
        console.log('centreOptions: ', centreOptions);
        // .sort((a,b) => a - b);




        console.log('centreOptions: ', centreOptions);
        setDisplayCentreOptions(centreOptions);

        // only set display centre if one not already selected
        setDisplayCentre(prevCentre => {
            // get corresponding bus/centre to prevCentre
            const prevCentreName = centreDataSource.filter(obj => obj.centre_id === +prevCentre)?.[0]?.centre_name;
            const prevCentreOrBusId = upcomingSessions?.filter(obj => obj.centre_name === prevCentreName && obj.location_type === displayLocation)?.[0]?.centre_id ?? prevCentre;
            console.log('prevCentreName: ', prevCentreName);
            console.log('prevCentreOrBusId: ', prevCentreOrBusId);

            if (!prevCentre) {
                console.log('setting default display centre to ', centreOptions[0])
                const newCentre = centreOptions[0];
                const newCentreName = centreDataSource.filter(obj => obj.centre_id === newCentre)?.[0]?.centre_name;
                setSelectedCentreName(newCentreName);
                return newCentre;
            } else {
                console.log('setting default display centre to ', prevCentreOrBusId)
                return prevCentreOrBusId;
            }
        });
        
    }, [upcomingSessions, displayLocation, centresData, setSelectedCentreName ])   

    // set display options which depend on other display options
    useEffect(() => {

        // create array of schools, only including those for children in current centre/session/bus list
        console.log('displayCentre: ', displayCentre);
        console.log('displayDate: ', displayDate);
        console.log('displaySession: ', displaySession);
        const schoolSessionsFiltered = upcomingSessions?.filter(obj => obj.centre_id === displayCentre && obj.session === displaySession && obj.date.slice(0,10) === displayDate.toLocaleDateString("sv-SW", {timeZone: timezone}) && obj.bus_list_name === displayBusList);
        console.log('schoolSessionsFiltered: ', schoolSessionsFiltered);
        const schoolOptions = schoolSessionsFiltered?.map(obj => obj.school_child_attends).filter((v, i, a) => a.indexOf(v) === i);
        schoolOptions?.unshift('All Schools');
        // console.log('schoolOptions: ', schoolOptions);
        setDisplaySchoolOptions(schoolOptions);
        setDisplaySchool([{value: 'All Schools', label: 'All Schools'}]);        
        
        
    }, [upcomingSessions, displayCentre, displaySession, displayDate, displayBusList])    


    // whenever centre, date or session change, update bus list options
    useEffect(() => {

        // only run if location is bus
        if (displayLocation === 'bus') {

            // get sessions
            const dateCentreSessions = upcomingSessions?.filter(obj => obj.centre_id === displayCentre && obj.session === displaySession && obj.date.slice(0,10) === displayDate.toLocaleDateString("sv-SW", {timeZone: timezone}));
            // console.log('[busListName Logic] - displayCentre: ', displayCentre);
            // console.log('[busListName Logic] - dateCentreSessions: ', dateCentreSessions);
            
            const busListOptions = dateCentreSessions.map(obj => obj.bus_list_name).filter((x,i,a) => a.indexOf(x) === i);
            console.log('[busListName Logic] - busListOptions: ', busListOptions);

            // set options
            setDisplayBusListOptions(busListOptions);

            // default selected bus list
            setDisplayBusList(prevList => {
                // console.log('prevList: ', prevList);
                if (prevList && busListOptions.includes(prevList)) {
                    // console.log('returning prevList: ', prevList);
                    return prevList
                } else {
                    // console.log('returning new session: ', openSessionOptions[0] ?? allSessionOptions[0]);
                    return busListOptions[0];
                }
            });            



        } 

    }, [displayCentre, upcomingSessions, displayLocation, displayDate, displaySession]);

    // // set display options which depend on other display options
    // useEffect(() => {

    //     // if no display group set, 
        
        
    // }, [upcomingSessions, displayCentre, displaySession, displayDate])    


    const handleUpdateRollIds = (rollId) => {
        // if ID already in array, remove it, otherwise add it
        if (updateRollIds.includes(rollId)) {
            const index = updateRollIds.indexOf(rollId);
            const newRollIds = [...updateRollIds];
            newRollIds.splice(index, 1);
            setUpdateRollIds(newRollIds);
        } else {
            setUpdateRollIds([...updateRollIds, rollId]);
        }
    }

    // function to handle submission of check-in/check-out
    const handleSubmit = (updateRollEntries, signedInUserId, updateType, onSuccessFn, question) => {

        // removing permission slip logic as this is now handled via an acknowledgement upon booking
        // first check there are no outstanding permission slips
        // const rollIdsMissingSlip = upcomingSessions.filter(obj => obj.permission_slip_outstanding).map(obj => obj.id);
        // // console.log('rollIdsMissingSlip: ', rollIdsMissingSlip);

        // const entriesMissingSlip = updateRollEntries.filter(obj => rollIdsMissingSlip.includes(obj.id));
        // if (entriesMissingSlip.length > 0) {
        //     alert('Permission slip missing for: ' + entriesMissingSlip.map(obj => upcomingSessions.filter(session => session.id === obj.id)[0].first_name));
        // }
        // console.log('updateRollEntries: ', updateRollEntries);
        // console.log('signedInUserId: ', signedInUserId);
        // console.log('updateType: ', updateType);


        if (kioskView){
            updateRoll(updateRollEntries, signedInUserId, updateType, displayCentre, userExperience, comment, question);
        } else {
            updateRoll(updateRollEntries, signedInUserId, updateType, onSuccessFn);
        }
    }




    // if a roll entry has been selected, create the object to pass to the backend with all roll information necessary to control access
    let updateRollEntries;
    if (updateRollIds && upcomingSessions?.length > 0) {
        const updateRollEntriesAllFields = upcomingSessions.filter(obj => updateRollIds.includes(obj.id));
        updateRollEntries = updateRollEntriesAllFields.map(({id, centre_id, child_id}) => ({id, centre_id, child_id}))
        // console.log('updateRollEntries: ', updateRollEntries);
    }

    const handleNewDrill = () => {
        if (updateRollIds?.length > 0) {
            setShowDrillForm(true)
        } else {
            alert('No children selected');
        }
    }

    
    // useEffect to determine childIds to display for roll sessions matching today, selected centre and selected session
    useEffect(() => {

        // get current date and time to only show sessions happening in near future
        const today = displayDate;
        // const hourNow = today.getHours();
        // console.log('hourNow: ', hourNow);

        let newDisplayedRollEntries = upcomingSessions
        ?.filter(obj => obj.session === displaySession && +obj.centre_id === +displayCentre && obj.location_type === displayLocation)
        ?.filter(obj => {
            // get date to only show sessions happening today
            const date = new Date(obj.date);

            return (
                date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
            );

        })

        // console.log('newDisplayedRollEntries: ', newDisplayedRollEntries);


        // if we're on bus view, filter children to those at selected school and in selected bus list
        if (displayLocation === 'bus') {
            const displaySchoolIds = displaySchool?.map(obj => obj.value);
            // console.log('displaySchoolIds: ', displaySchoolIds);
            newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => obj.bus_list_name === displayBusList && (displaySchoolIds.includes(obj.school_child_attends) || displaySchoolIds.includes('All Schools')));
        }

        // if we have any groups present on roll, filter to selected
        if (displayGroup) {
            newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => obj.roll_group === displayGroup || displayGroup === reservedRollGroupNames[0] || (!obj.roll_group && displayGroup === reservedRollGroupNames[1])) // 'All Groups' and 'No Group'
        }
        
        
        // get children in each status
        const childrenIn = newDisplayedRollEntries?.filter(obj => obj.status !== 'absent' && ( (obj.checked_in1 && !obj.checked_out1) || (obj.checked_in2 && !obj.checked_out2) )).map(obj => obj.id);
        const childrenOut = newDisplayedRollEntries?.filter(obj => obj.status !== 'absent' && ( (obj.checked_in1 && obj.checked_out1 && !obj.checked_in2) || (obj.checked_in2 && obj.checked_out2) )).map(obj => obj.id);
        const childrenAbsent = newDisplayedRollEntries?.filter(obj => obj.status === 'absent').map(obj => obj.id);
        const childrenUnaccounted = newDisplayedRollEntries?.filter(obj => !childrenIn.includes(obj.id) && !childrenOut.includes(obj.id) && !childrenAbsent.includes(obj.id)).map(obj => obj.id);
        
        // const newDisplayedRollIds = newDisplayedRollEntries.map(obj => obj.id);
        // const countChildrenIn = newDisplayedRollEntries.filter(obj => obj.status !== 'absent' && ( (obj.checked_in1 && !obj.checked_out1) || (obj.checked_in2 && !obj.checked_out2) )).length;
        // const countChildrenOut = newDisplayedRollEntries.filter(obj => obj.status !== 'absent' && ( (obj.checked_in1 && obj.checked_out1 && !obj.checked_in2) || (obj.checked_in2 && obj.checked_out2) )).length;
        // const countChildrenAbsent = newDisplayedRollEntries.filter(obj => obj.status === 'absent').length;
        // const countChildrenUnaccounted = newDisplayedRollEntries.length - countChildrenIn - countChildrenOut - countChildrenAbsent;
        
        
        // if a status (i.e. absent, in, unknown etc) has been selected, filter display children
        if (displayStatus) {
            if (displayStatus === 'in') {
                
                newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => childrenIn.includes(obj.id));
            } else if (displayStatus === 'out') {
                newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => childrenOut.includes(obj.id));
                
            } else if (displayStatus === 'absent') {
                newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => childrenAbsent.includes(obj.id));
                
            } else if (displayStatus === 'unknown') {
                newDisplayedRollEntries = newDisplayedRollEntries.filter(obj => childrenUnaccounted.includes(obj.id));
                
            }
        }
        
        
        
        const newDisplayedRollIds = newDisplayedRollEntries?.map(obj => obj.id);
        // console.log('newDisplayedRollIds: ', newDisplayedRollIds);
        setDisplayedRollIds(newDisplayedRollIds);
        // setChildrenStatusCounts({in: countChildrenIn, out: countChildrenOut, absent: countChildrenAbsent, unknown: countChildrenUnaccounted});
        setChildrenStatusCounts({in: childrenIn?.length, out: childrenOut?.length, absent: childrenAbsent?.length, unknown: childrenUnaccounted?.length});

        
    }, [upcomingSessions, displayCentre, displaySession, displayLocation, displaySchool, displayDate, displayGroup, displayStatus, displayBusList]);


    // useEffect to fetch child photos for roll items displayed
    useEffect(() => {

        
        const getPhotos = async (childIds) => {
       


            // upcoming sessions data
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            const postData = {childId: childIds, centreId: displayCentre};
            try {
                const res = await axios.post(AWS_API + 'form-submissions/fetch-photos', {postData: postData}, headers);
                // setUpcomingSessions(res.data);
                const newPhotos = res.data;
                // console.log('res: ', newPhotos);
                
                // if there are any new children to add, update photos in state
                const existingIds = Object.keys(photos);
                const newIds = Object.keys(newPhotos);
                const difference = newIds.filter(id => !existingIds.includes(id));
                // console.log('new photos fetched not already in photos for: ', difference);
                if (difference.length > 0) {
                    setPhotos({...photos, ...newPhotos});
                }
                // setLoading(false);
            } catch (err) {
                // setLoading(false);
                // setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                console.log('error whilst fetching photos: ', err);
            }   
            
            setPhotosLoading(false);
            
      
        }

        // get child Ids for whom we need photos and call function
        if (upcomingSessions) {
            const childIds = upcomingSessions?.filter(obj => displayedRollIds?.includes(obj.id))?.map(obj => obj.child_id)?.filter((x, i, a) => a.indexOf(x) === i);
            // console.log('displayedRollIds to fetch photos for: ', displayedRollIds);
            // console.log('childIds to fetch photos for: ', childIds);
            // if photos already in state, check if we are missing a photo for any children on roll and only request those ones
            let photoMissingIds = [];
            if (photos) {
                const photosLoadedIds = Object.keys(photos);
                // console.log('photos loaded child Ids: ', photosLoadedIds);
    
                // check if we already have photos for all children, otherwise fetch new ones needed
                photoMissingIds = childIds.filter(id => !photosLoadedIds.includes(id.toString()));
                // console.log('photoMissingIds: ', photoMissingIds);

            } else {
                // no photos in state, so request photo for all children on roll
                photoMissingIds = childIds;
            }


            if (photoMissingIds.length > 0) {
                setPhotosLoading(true);
                console.log('attempting to fetch photos for ids: ', photoMissingIds);
                getPhotos(photoMissingIds);

            }

        }
        
        
    }, [displayedRollIds, props.token, upcomingSessions, photos, displayCentre]);

    const handleSelectAll = (checked) => {
        if (checked) {
            setUpdateRollIds(displayedRollIds);
        } else {
            setUpdateRollIds([]);
        }
   
    }



    // generate JSX for roll sessions matching today, selected centre and selected session
    let rollMarkedStatus;
    const rows = upcomingSessions?.filter(obj => displayedRollIds?.includes(obj.id))
    ?.sort((a,b) => {
        if ( a?.[sortField]?.toLowerCase() < b?.[sortField]?.toLowerCase() ) return -1;
        if ( a?.[sortField]?.toLowerCase() > b?.[sortField]?.toLowerCase() ) return 1;
        return 0;
    })
    ?.sort((a,b) => {
        // temp sort logic to group flags together if clicked
        if (['asc', 'desc'].includes(sortOnFlags)) {

            
            // figure out flags that each a and b have
            const aKindy = a?.child_school_year.includes('kindy');
            const bKindy = b?.child_school_year.includes('kindy');
            // console.log('sorting a and b: ', aKindy, bKindy);

            if (aKindy && !bKindy) {return (sortOnFlags === 'asc') ? -1 : 1}
            if (!aKindy && bKindy) {return (sortOnFlags === 'asc') ? 1 : -1}
            return 0;

        } else {
            return 0;
        }
    })
    ?.sort((a,b) => {
        // console.log('a: ', a);
        // console.log('b: ', b);
        if (a.status === 'absent' && b.status !== 'absent') {return 1}
        if (a.status !== 'absent' && b.status === 'absent') {return -1}
        else {return 0}
    })
    ?.map(obj => {

        // get roll marked status from one of filtered rows (they should all have the same status as only one session can be displayed at once)
        rollMarkedStatus = obj.roll_marked_status
        
        
        // add class based on checked in status
        let classList = [classes.Row];
        const imageClasses = [classes.Image];
        
        if ((obj.checked_out1 && !obj.checked_in2) || (obj.checked_out2)) {
            classList.push(classes.RowCheckedOut);
        } else if (obj.checked_in1 || obj.checked_in2) {
            classList.push(classes.RowCheckedIn);
        } else if (obj.status === 'absent') {
            classList.push(classes.RowAbsent);
        }
        
        //-----------------
        // added tickbox instead of photo border for small screen
        
        // add selected class if selected
        // let selected;
        
        // add extra marker for small screens
        // if (selected && isMobile) {
        //     imageClasses.push(classes.SelectedImage);

        // }
        //-----------------
        
        if (updateRollIds.includes(obj.id)) {
            // selected = true;
            classList.push(classes.SelectedRow);
        } 

        let newBanner;
        // console.log('obj.first_enrolment_at: ', obj.first_enrolment_at);
        if (new Date().getTime() - new Date(obj.first_enrolment_at).getTime() < 1225756041) { // enrolment less than two weeks old
            newBanner = <NewBanner />
        }

        // create child photo img tag if we have one
        // let childPhoto = <FiCameraOff size={'1.5rem'} color={'black'} /> 
        const initial1 = obj.first_name.slice(0,1);
        const initial2 = obj.surname.slice(0,1);
        const initials = (initial1 + initial2).toUpperCase();

        let childPhotoImg = <InitialIcon initials={initials}/> 
        if (photos && photos[obj.child_id]) {
            childPhotoImg = <img alt="child's face" src={'data:image/jpg;base64,' + photos[obj.child_id]} className={imageClasses.join(' ')} />
        }

        const childPhoto = <div style={{margin: '0 -15px'}}>
            {newBanner}
            {childPhotoImg}
        </div>




        // set default values to show which may be overridden if roll isn't yet marked
        let checkedInTD1 = obj.checked_in1 ? new Date(obj.checked_in1).toLocaleTimeString(): null;
        let checkedOutTD1 = obj.checked_out1 ? new Date(obj.checked_out1).toLocaleTimeString(): null;
        // let checkedInTD2 = obj.checked_in2 ? new Date(obj.checked_in2).toLocaleTimeString(): null;
        // let checkedOutTD2 = obj.checked_out2 ? new Date(obj.checked_out2).toLocaleTimeString(): null;
        let rollStatus = capitalizeFirstLetter(obj.status);
        
        // ------------------------------------------
        // set status change symbols
        const createdFrom = obj?.created_from;
        let createdFromSymbol = '';
        // created from guardian
        if (['perm_bookings', 'casual_bookings'].includes(createdFrom)) {
            createdFromSymbol =  <div className={classes.StatusSymbolGuardian}>G</div>    

        // otherwise created from admin roll add, i.e. staff            
        } else {
            createdFromSymbol =  <div className={classes.StatusSymbolStaff}>S</div>    
        }
        
        // get info on who changed status (i.e. who marked child as absent)
        const statusChangedBy = obj.status_changed_by_account_type;
        const statusChangedById = +obj.status_changed_by;
        let statusChangedBySymbol = '';
        // status changed by guardian
        if (statusChangedBy === 'accountOwner') {
            statusChangedBySymbol = <div className={classes.StatusSymbolGuardian}>G</div>;

        // otherwise status changed by roll clean engine or centreAdmin/headOffice/diretor, i.e. staff
        } else if (statusChangedById === 1 || ['centreAdmin', 'headOffice', 'director'].includes(statusChangedBy)) {
            statusChangedBySymbol = <div className={classes.StatusSymbolStaff}>S</div>  ;
        }

        // ------------------------------------------
        
        // limit parent view until roll is marked
        if (parentView && !rollMarkedStatus) {
            checkedInTD1 = 'Pending'; 
            checkedOutTD1 = 'Pending';
            // checkedInTD2 = 'Pending'; 
            // checkedOutTD2 = 'Pending';
            classList = classList.filter(el => ![classes.RowCheckedIn, classes.RowCheckedOut].includes(el));
            
            // only override the status if it wasn't changed by the parent
            if (statusChangedBy !== 'accountOwner') {
                rollStatus = 'Pending';
                statusChangedBySymbol = '';
                classList = classList.filter(el => ![classes.RowAbsent].includes(el));
                
            }
        } 

        // look for child flags
        const childId = obj.child_id;
        let childFlags;
        if (childFlagsData) {
            childFlags = childFlagsData.filter(obj => obj.child_id === childId);
        }
        // console.log('childFlags: ', childFlags);

        // check child's age
        const childAge = getAgeFromDateStr(obj.dob);

        // check if child's birthday
        const birthdayFlag = isBirthday(obj?.dob, displayDate);       

        // set icon style
        const iconSize = '1.2rem';
        const iconColor = 'black'

        // if not in kiosk view, render necessary flags/symbols
        let symbols;
        if (!kioskView) {
            symbols = 
                <Fragment>

                    {/* birthday symbol */}
                    {birthdayFlag &&  <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        {/* <FaBirthdayCake size={iconSize} color={'#ec2f94'} />     */}
                        <img alt="birthday-icon" src={birthdayIcon} style={{height: '2rem'}} />  
                    </div>}

                    {/* kindy flag */}
                    {obj?.child_school_year?.includes('kindy') && <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <img alt="kindy-icon" src={kindyIcon} style={{height: '1.35rem'}} />  
                    </div>}
                    
                    {/* notes symbol */}
                    {!parentView && (obj.notes || obj.recurring_roll_note) &&  <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <BsPencilSquare size={iconSize} color={iconColor} />    
                    </div>}      

                    {/* at risk symbol - currently court orders in children_medical, or at_risk flag in child_flags */}
                    {obj.court_orders_1 || obj.court_orders_2 || childFlags?.length > 0 
                    ? <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <AiOutlineWarning size={iconSize} color={iconColor} />    
                    </div> : null}

                    {/* dietary restrictions symbol */}
                    {obj.dietary_restrictions ? <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <MdNoFood size={iconSize} color={iconColor} />    
                    </div> : null}

                    {/* medical condition symbol */}
                    {obj.diagnosed_condition || !obj.auth_sunscreen || !obj.auth_panadol || !obj.auth_first_aid || !obj.auth_ambulance
                    ? <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <FaNotesMedical size={iconSize} color={iconColor} />    
                    </div> : null}

                    {/* photo permissions missing symbol */}
                    {(!obj.photo_permissions_internal || !obj.photo_permissions_external) ? <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <FiCameraOff size={iconSize} color={iconColor} />    
                    </div>  : null}  

                    {/* child < 7 symbol */}
                    {(childAge < 7) ? <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        {/* <RiNumber7 size={iconSize} color={iconColor} />     */}
                        <img alt="under-7-icon" src={u7icon} style={{height: iconSize}} />   
                    </div>  : null}                  

                    {/* account incomplete symbol */}
                    {obj.account_incomplete ? <div onClick={() => handleShowChildSummary(obj.child_id, obj.id)}>
                        <GrDocumentMissing size={iconSize} color={iconColor} />    
                    </div>  : null}                  
                </Fragment>
        }

        
                
        // console.log("updateRollIds: ", updateRollIds);
        return (
            <tr className={classList.join(' ')} key={obj.id}>
                <td>
                    <div className={classes.CheckboxContainer} onClick={() => handleUpdateRollIds(obj.id)}>
                        <input checked={updateRollIds.includes(obj.id)} onChange={() => handleUpdateRollIds(obj.id)} type="checkbox" className={classes.Checkbox}/>
                        <span className={classes.CheckboxCheckmark}></span>
                    </div>
                </td>
                <td className={classes.TableItem} onClick={() => handleUpdateRollIds(obj.id)}>{photosLoading ? <div style={{marginBottom: '20px'}}><SmallSpinnerDark /></div> : childPhoto}</td>
                {/* <td className={classes.TableItem}>{new Date(obj.date).toLocaleDateString().slice(0,5)}</td> */}
                {/* <td onClick={() => handleChildDetail(obj.id, ['guardian_first_name', 'guardian_surname', 'preferred_contact'], false, true)} className={classes.TableItem}>{obj.first_name + ' ' + obj.surname}</td> */}
                <td onClick={() => handleShowChildSummary(obj.child_id, obj.id)} className={classes.TableItem} style={{fontSize: '.70rem'}}>{obj.first_name + ' ' + obj.surname}</td>
                {/* <td className={classes.TableItem}>{obj.centre_id}</td> */}
                {/* <td className={classes.TableItem}>{obj.session}</td> */}
                {/* <td className={classes.TableItem}>{obj.checked_in ? <div className={classes.CheckInBadge}>{new Date(obj.checked_in_at).toLocaleTimeString()}</div>: null}</td> */}
                {/* <td className={classes.TableItem}>{obj.checked_out ? <div className={classes.CheckInBadge}>{new Date(obj.checked_out_at).toLocaleTimeString()}</div>: null}</td> */}
                <td className={classes.TableItem}>{checkedInTD1}</td>
                <td className={classes.TableItem}>{checkedOutTD1}</td>
                {/* <td className={classes.TableItem}>{checkedInTD2}</td> */}
                {/* <td className={classes.TableItem}>{checkedOutTD2}</td> */}
                <td className={classes.TableItem}>
                    <div className={classes.StatusContainer}>
                        {rollStatus}
                        &nbsp;
                        {createdFromSymbol && createdFromSymbol}
                        &nbsp;
                        {statusChangedBySymbol && statusChangedBySymbol}
                    </div>
                </td>
                <td>
                    <div className={classes.TableSymbols}>
                        {symbols}
                    </div>
                </td>
            </tr>
            );        
    });
    

    let centreSelect;
    if (multipleCentres) {
        centreSelect = (
            <div className={classes.RollSelect}>
                <span>Service</span>
                <select value={displayCentre} onChange={(e) => {setDisplayCentre(+e.target.value); setSelectedCentreName(centresData.filter(obj => obj.centre_id === +e.target.value)?.[0]?.centre_name); setUpdateRollIds([]);}} className='form-control'>
                    {displayCentreOptions.map(id => <option key={id} value={id}>{centresData.filter(obj => obj.centre_id === id)[0].centre_name}</option>)}
                </select>

            </div>

        );
    }

    // console.log('prompt: ', prompt);
    // console.log('displaySchool: ', displaySchool)

    const handleUpdateRollGroups = (action, groupName) => {
        
        postMasterData('master-data/manage-roll-groups', {
            action: action,
            rollIds: updateRollIds,
            rollGroupName: groupName
        }, () => setShowManageGroups(false));

    }

    const handleSubmitRollNote = (childId, newNote, noteType, rollId, recurringRollNoteId) => {
        
        postMasterData('master-data/manage-roll-notes', {childId: childId, newNote: newNote, noteType: noteType, rollId: rollId, recurringRollNoteId: recurringRollNoteId}, () => handleCloseChildSummary());
    }    

    const handleSetDisplayStatus = (newStatus) => {
        if (newStatus === displayStatus) {
            setDisplayStatus();
        } else {
            setDisplayStatus(newStatus);

        }
    }

    const cleanupAfterSubmit = () => {
        setShowDrillForm();
        setUpdateRollIds([]);
        scrollPageToTop();
    }


    // set bus/centre class
    let headerClasses = [classes.Title];
    if (displayLocation === 'bus') {
        headerClasses.push(classes.TitleBusTheme)
    }

    const returnDateToToday = () => {
        setDisplayDate(new Date());
    }
    
    const handleSubmitUserExperience = (signedInUserId, updateRollEntries, question) => {
        handleSubmit(updateRollEntries, signedInUserId, 'checked_out1', () => cleanupAfterSubmit(), question);

        // console.log("Kiosk Error: ", kioskError);

        // submitKioskExperience(signedInUserId, displayCentre, userExperience, comment);
        
    };

    // Kiosk experience
    const handleCheckOut = () => {
        // await handleSubmit(updateRollEntries, signedInUserId, updateType, onSuccessFn);
        setShowKioskExperienceModal(true);
    }
    
    const handleExperienceChange = (event) => {
        setUserExperience(event.target.value);
    }
    console.log("user experience: ", userExperience);
    let kioskExperience;
    if (showKioskExperienceModal){
        kioskExperience = (
            <div>
                <h3>{question}</h3>
                <div className={classes.Container}>
                    <div className={classes.Item}>
                        <label for="1">
                        <input className={classes.Radio} type="radio" name="feedback" id="1" value="1" onChange={handleExperienceChange}/>
                        <span>😡</span>
                        </label>
                    </div>

                    <div className={classes.Item}>
                        <label for="2">
                        <input className={classes.Radio} type="radio" name="feedback" id="2" value="2" onChange={handleExperienceChange}/>
                        <span>🙁</span>
                        </label>
                    </div>

                    <div className={classes.Item}>
                        <label for="3">
                        <input className={classes.Radio} type="radio" name="feedback" id="3" value="3" onChange={handleExperienceChange}/>
                        <span>😶</span>
                        </label>
                    </div>

                    <div className={classes.Item}>
                        <label for="4">
                        <input className={classes.Radio} type="radio" name="feedback" id="4" value="4" onChange={handleExperienceChange}/>
                        <span>😁</span>
                        </label>
                    </div>

                    <div className={classes.Item}>
                        <label for="5">
                        <input className={classes.Radio} type="radio" name="feedback" id="5" value="5" onChange={handleExperienceChange}/>
                        <span>😍</span>
                        </label>
                    </div>
                </div>
                <h5>Comments:</h5>
                <textarea 
                    // key={modalResourceId}
                    type='text' 
                    className={['form-control', classes.CommentInputText].join(' ')}
                    value={comment}
                    cols='2'
                    rows='3'
                    onChange={(e) => setComment(e.target.value)}
                />
                <br/>
                <button className="btn btn-block btn-success" onClick={() => handleSubmitUserExperience(signedInUserId, updateRollEntries, question)}>Submit</button>

            </div>
        )
    }
  
    return (
        <Fragment >

            <Modal tall show={viewingChildSummaryId} modalClosed={() => {handleCloseChildSummary();}}>
                {viewingChildSummaryId && <ChildSummary 
                    parentComponent={adminFlag ? 'roll' : 'kiosk'} 
                    childImg={photos[viewingChildSummaryId]} 
                    childSummaryData={childSummaryData} 
                    childFlagsData={childSpecificChildFlagsData} 
                    emergencyContactsData={childSpecificEmergencyContactData}
                    childRollRecord={upcomingSessions.filter(obj => obj.id === viewingChildSummaryRollId)?.[0]}
                    handleSubmitRollNote={handleSubmitRollNote}
                />}
            </Modal>   

            <Modal tall show={showManageGroups} modalClosed={() => {setShowManageGroups(false);}}>
                <RollGroups manageGroupsFn={handleUpdateRollGroups} existingGroups={displayGroupOptions}/>
            </Modal>   


            <Modal show={showKioskExperienceModal} modalClosed={() => {setShowKioskExperienceModal(false);}}>
                {kioskExperience}
            </Modal>

            <Modal show={showDrillForm} modalClosed={() => setShowDrillForm(false)}>
                <Drills submitFn={submitRollEvent} cleanupFn={cleanupAfterSubmit} rollEntries={updateRollEntries} />
            </Modal>             
            <Modal show={showAddChildren} large modalClosed={() => setShowAddChildren(false)}>
                {(childrenData?.length > 0 && centresData?.length > 0) 
                ? <AddChildren addingFromRollFlag centreId={displayCentre} centreSessionData={centreSessionData} centresData={displayCentreOptions.map(id => ({centre_id: id, centre_name: centresData?.filter(obj => obj.centre_id === id)[0]?.centre_name}))} dates={[displayDate]} session={displaySession} childrenData={childrenData}  writeBlockBooking={writeBlockBooking}/> 
                : <div style={{width: '100%', margin: 'auto'}}><SpinnerDark/></div>}
            </Modal>               
            <Prompt data={prompt} setData={setPrompt} />

            
            <div className={headerClasses.join(' ')}>
                <div className={classes.TitleInfo}>
                    <span >
                        <button className='btn btn-light btn-sm' onClick={() => {incrementDate(displayDate, -1, setDisplayDate);  setUpdateRollIds([]);}}>◀</button>
                        &nbsp;<span className={classes.TitleText}>{displayDate.toDateString("en-GB", {timeZone: timezone})}</span>&nbsp;
                        <button className='btn btn-light btn-sm' onClick={() => {incrementDate(displayDate, +1, setDisplayDate);  setUpdateRollIds([]);}}>▶</button>
                    </span>
                    {adminFlag &&  displayLocation === 'centre' &&
                        <Fragment>
                            <div style={{width: '19%'}}><button className="btn btn-block btn-warning" onClick={() => {fetchAddNewChildrenData(displayCentre); setShowAddChildren(true)}}><b style={{color: 'darkslategrey'}}>Add Child</b></button></div>
                            <div style={{width: '14%'}}><button className="btn btn-block btn-secondary" onClick={() => setShowManageGroups(true)}><b>Groups</b></button></div>
                             {/* <button 
                                disabled={rollMarkedStatus === 'marked' ? true : false} 
                                onClick={() => submitRollAsMarked(displayDate.toLocaleDateString("sv-SW", {timeZone: timezone}), displaySession, displayCentre, 'marked')} 
                                className={rollMarkedStatus === 'marked' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-warning'}
                            >Roll Marked</button> */}
                        </Fragment>
                    }
                    <img alt="location type" src={displayLocation === 'centre' ? centreIcon : busIcon}  className={classes.HomeIcon} onClick={returnDateToToday} />

                </div>
                <div className={classes.TitleSelects}>
                    {centreSelect}

                    <div className={classes.RollSelect}>
                        <span>Session</span>
                        <select  value={displaySession} onChange={(e) => {setDisplaySession(e.target.value); setUpdateRollIds([]);}} className='form-control'>
                            {displaySessionOptions?.map(option => <option key={option} value={option}>{raw[option]}</option>)}
                        </select>
                    </div>

                    <div className={classes.RollSelect}>
                        <span>Centre or Bus</span>
                        <select value={displayLocation} onChange={(e) => {setDisplayLocation(e.target.value); setUpdateRollIds([]);}} className='form-control' style={{textTransform: 'capitalize'}}>
                            {displayLocationOptions?.map(option => <option key={option} value={option}>{option}</option>)}
                        </select>
                    </div>

                    {displayLocation === 'bus' && displayBusListOptions && <div className={classes.RollSelect}>
                        <span>Bus List</span>
                        <select value={displayBusList} onChange={(e) => {setDisplayBusList(e.target.value); setUpdateRollIds([]);}} className='form-control' style={{textTransform: 'capitalize'}}>
                            {displayBusListOptions?.map(option => <option key={option} value={option}>{option}</option>)}
                        </select>
                    </div>}

                    {adminFlag && displayGroupOptions?.length > 0 && <div className={classes.RollSelect}>
                        <span>Group</span>
                        <select  value={displayGroup} onChange={(e) => {setDisplayGroup(e.target.value); setUpdateRollIds([]);}} className='form-control'>
                            {displayGroupOptions?.map(option => <option key={option} value={option}>{option}</option>)}
                        </select>
                    </div>}
                </div>

                {displayLocation === 'bus' && <div className={classes.TitleSelects}>
                    <div className={classes.RollSelect}>
                        <span>School</span>
                        <Select 
                            isMulti 
                            options={displaySchoolOptions.map(id => ({value: id, label: schoolNameData?.[id]?.split(',')?.[0] ?? id}))} 
                            onChange={setDisplaySchool} 
                            value={displaySchool}
                            styles={{
                                option: provided => ({
                                    ...provided,
                                    color: 'darkslategrey',
                                    fontWeight: 'normal'

                                })
                            }}
                        />
                        {/* <select  value={displaySchool} onChange={(e) => {setDisplaySchool(e.target.value); setUpdateRollIds([]);}} className='form-control'>
                            {displaySchoolOptions.map(id => <option key={id} value={id}>{schoolNameData[id] ?? id}</option>)}
                        </select> */}
                    </div>
                </div>}
                
            </div>

            {/* <div className="form-check" style={{margin: '3px 0'}}>
            <input className="form-check-input" type="checkbox" id="flexCheckDefault"/>
            <label className={classes.Label}>Select All</label>
            
            </div> */}
        

            {parentView && <div><i style={{fontSize: '.65rem', fontWeight: '500'}}>Note 1 - Check-in/Check-out status will show as "Pending" until roll has been marked</i></div>}
            {parentView && <div><i style={{fontSize: '.65rem', fontWeight: '500', color: 'darkred'}}>Note 2 - Marking child as absent will keep child on the roll but alert staff that child will be absent. If you wish to cancel a booking on a future day, please do so through the calendar.</i></div>}
            {/* <div className={classes.StickyHeader}>            */}
                <table className={["table", classes.Table].join(" ")}>
                    <thead  className={classes.StickyHeader}>
                        <tr>
                            <th colSpan='10' >
                                <div className={classes.FlexRow}>
                                    
                                <KioskStatusCard displayStatus={displayStatus} type="selected" counts={childrenStatusCounts} selected={updateRollEntries?.length}/>
                                <KioskStatusCard displayStatus={displayStatus} clicked={() => handleSetDisplayStatus('in')} type="in" counts={childrenStatusCounts}/>
                                <KioskStatusCard displayStatus={displayStatus} clicked={() => handleSetDisplayStatus('out')} type="out" counts={childrenStatusCounts}/>
                                <KioskStatusCard displayStatus={displayStatus} clicked={() => handleSetDisplayStatus('absent')} type="absent" counts={childrenStatusCounts}/>
                                <KioskStatusCard displayStatus={displayStatus} clicked={() => handleSetDisplayStatus('unknown')} type="unknown" counts={childrenStatusCounts} />
                                </div>
                            </th>
                        </tr>
                        <tr >
                            <th><div><input onChange={(e) => handleSelectAll(e.target.checked)} className="form-check" type="checkbox" id="flexCheckDefault"/></div></th>
                            <th></th>
                            {/* <th>Date</th> */}
                            <th className={classes.ChildTableHeader} onClick={() => {setSortField(prevSortField => prevSortField === 'surname' ? 'first_name' : 'surname'); setSortOnFlags('')}}>Child</th>
                            <th>In</th>
                            <th>Out</th>
                            {/* <th>In</th> */}
                            {/* <th>Out</th> */}
                            <th>Status</th>
                            <th className={classes.ChildTableHeader} onClick={() => {sortOnFlags === 'asc' ? setSortOnFlags('desc') : setSortOnFlags('asc') }}>Flags</th>
                        </tr>

                    </thead>
                    {/* <tbody style={{visibility: 'collapse'}}> */}
                    <tbody >
                        {rows}  
                    </tbody>
                </table>

            {/* </div> */}
            {/* <table className={["table", classes.Table].join(" ")}>
                <thead style={{visibility: 'collapse'}}>
                    <tr>
                        <th><div><input onChange={(e) => handleSelectAll(e.target.checked)} className="form-check" type="checkbox" id="flexCheckDefault"/></div></th>
                        <th>Date</th>
                        <th>Child</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Status</th>
                        <th>Flags</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}  
                </tbody>
            </table> */}
            <div className={classes.Footer}>
                <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
                    {checkinAllowed && 
                        <Fragment>
                            <button onClick={() => handlePrompt('submit check in', () => handleSubmit(updateRollEntries, signedInUserId, 'checked_in1', () => cleanupAfterSubmit()), setPrompt)} className="btn CustomButtonSignIn" style={{flex: 1}}>Sign In</button>
                            {!kioskView && <button onClick={() => handlePrompt('submit check out', () => handleSubmit(updateRollEntries, signedInUserId, 'checked_out1', () => cleanupAfterSubmit()), setPrompt)} className={["btn CustomButtonSignOut"].join(' ')} style={{flex: 1}}>Sign Out</button>}
                            {kioskView && <button onClick={() => handlePrompt('submit check out', () => handleCheckOut(), setPrompt)} className={["btn CustomButtonSignOut"].join(' ')} style={{flex: 1}}>Sign Out</button>}
                        </Fragment>
                    }

                    {absentAllowed && <button onClick={() => handlePrompt('submit absence', () => handleSubmit(updateRollEntries, signedInUserId, 'absent', () => cleanupAfterSubmit()), setPrompt)} className="btn CustomButtonAbsent" style={{flex: 1}}>Absent</button>}
                </div>
                
                <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px', justifyContent: 'space-around'}}>
                    {headcountAllowed && <button onClick={() => handlePrompt('submit headcount', () => submitRollEvent(updateRollEntries, 'headcount', () => cleanupAfterSubmit()), setPrompt)} className="btn CustomButtonHeadcount" style={{flex: 1, maxWidth: '250px'}}>Headcount</button>}
                    {headcountAllowed && <button onClick={handleNewDrill} className="btn CustomButtonDrill" style={{flex: 1, maxWidth: '250px'}}>Emergency Drill</button>}
                    {/* {headcountAllowed && <button onClick={() => handlePrompt('test message', () => alert('proceeded!'), setPrompt)} className="btn btn-outline-danger" style={{flex: 1}}>Test</button>} */}
                </div>
                <div style={{display: 'flex', padding: '5px 0', width: '100%', flexDirection: 'row', gap: '10px'}}>
                    {uploadPhoto && <button onClick={() => uploadPhoto(updateRollEntries, () => cleanupAfterSubmit())} className="btn btn-outline-secondary" style={{flex: 1}}><b>Upload New Photo</b></button>}
                    {headcountAllowed && <button onClick={() => {getHeadcounts(displayCentre, displayDate);}} className="btn btn-outline-secondary" style={{flex: 1}}><b>Attendance Log</b></button>}
                    {checkinAllowed && !showSecondSignIn && <button className="btn btn-outline-secondary"  style={{flex: 1}} onClick={() => setShowSecondSignIn(!showSecondSignIn)} ><b>Show Second Sign In</b></button>}
                    {checkinAllowed && showSecondSignIn && 
                        <Fragment>
                            <button onClick={() => handlePrompt('submit check in', () => handleSubmit(updateRollEntries, signedInUserId, 'checked_in2', () => cleanupAfterSubmit()), setPrompt)} className="btn btn-light" style={{flex: 1}}><b style={{color: 'darkslategrey'}}>Sign In 2</b></button>
                            <button onClick={() => handlePrompt('submit check out', () => handleSubmit(updateRollEntries, signedInUserId, 'checked_out2', () => cleanupAfterSubmit()), setPrompt)} className="btn btn-light" style={{flex: 1}}><b style={{color: 'darkslategrey'}}>Sign Out 2</b></button>
                        </Fragment>
                    }                    
                </div>
                
                {/* <button onClick={() => updateRoll('checkOut', updateRollEntries, signedInUserId)} style={{width: '49%'}} className="btn btn-outline-success">Check Out</button> */}
            </div>
            {logout ? <hr/> : null}
            {logout ? <button onClick={logout} className='btn btn-block btn-outline-info'>Close Session</button> : null}

        </Fragment>
    );
}

export default SessionSignIns;


const RollGroups = (props) => {

    const [groupName, setGroupName] = useState();

    
    // data from props
    // const updateRollIds = props.updateRollIds;
    const manageGroupsFn = props.manageGroupsFn;
    const existingGroups = props.existingGroups;
    
    
    return (
        <Fragment>
            <h3>Manage Roll Groups</h3>
            <hr />
            <h6>Add to New Group</h6>
            <input placeholder="Type group name here" className='form-control' value={groupName} onChange={(e) => setGroupName(e.target.value)} />
            <br />
            <button className="btn btn-success btn-block" onClick={() => {reservedRollGroupNames.includes(groupName) ? alert('Group name reserved, please choose another') : manageGroupsFn('add', groupName)}}>Add Children</button>
            {existingGroups?.length > 0 && 
                <Fragment>
                    <br />
                    <hr />
                    {/* <p><i>OR</i></p> */}
                    <h6>Add to Existing Group</h6>
                    {existingGroups.filter(group => !reservedRollGroupNames.includes(group)).map(group => <button key={group} className="btn CustomButtonPrimary btn-block" onClick={() => manageGroupsFn('add', group)}>{group}</button>)}
                </Fragment>
            }
            <br />
            <hr/>
            {/* <p><i>OR</i></p> */}
            {/* <hr/> */}
            <h6>Remove from Current Group</h6>
            <button className="btn btn-secondary btn-block" onClick={() => manageGroupsFn('remove')}>Remove Children</button>
        </Fragment>
    );
}


const KioskStatusCard = (props) => {

    // define classes
    const type = props.type;
    const counts = props.counts;
    const number = counts[type] ?? props.selected;
    const clicked = props.clicked;
    const displayStatus = props.displayStatus;

    let colourClass;
    if (type === 'absent') {
        colourClass = 'badge-info';
    } else if (type === 'in') {
        colourClass = classes.RowCheckedIn
    } else if (type === 'out') {
        colourClass = classes.RowCheckedOut
    } else if (type === 'selected') {
        colourClass = 'badge-light'
    } else {
        colourClass = 'badge-secondary'
    }

    let wrapperClasses = [classes.KioskStatusCard];
    // if (type === 'selected') wrapperClasses.push(classes.KioskStatusCardDark);
    
    // set class based on display status selected
    if (displayStatus === type || (!displayStatus && type === 'selected')) {
        wrapperClasses.push(classes.KioskStatusCardDark);
    }

    return (
        <div className={wrapperClasses.join(' ')} onClick={clicked}>
            <span className={["badge", "rounded-pill", colourClass].join(' ')}>
                <span style={{fontSize: '.8rem'}}>{number}</span>
            </span>
            <span>{(props.type).toUpperCase()}</span>
        </div>
    )
}
