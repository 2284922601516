import React, { 
    useCallback,
    useEffect,
    useState, 
    } from 'react'
import classes from './FailedSubmissions.module.css';
import { useHistory, useLocation } from "react-router-dom";
import SubmissionsTable from '../SubmissionsTable/SubmissionsTable';
import { cognitoClientId, cognitoUserpoolId, AWS_API, formioAPI } from '../../../../utilities/globalVariables';
import axios from 'axios';
import Formio from 'formiojs/Formio';
import {  handleAwsApiError, handleBannerText, scrollPageToTop } from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import Banner from '../../../../UI/Banner/Banner';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import { formioConfig } from '../../../../utilities/globalObjects';


const FailedSubmissions = (props) =>  {

    // data state
    const [viewingTable, setViewingTable] = useState('')
    const [submissionTableData, setSubmissionTableData] = useState();
    const [viewingFormSubmission, setViewingFormSubmission] = useState();
    const [viewingFormRow, setViewingFormRow] = useState();

    // form state
    const [showInvalidModal, setShowInvalidModal] = useState(false);
    const [missingFields, setMissingFields] = useState();    
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();
    
    // Bootstrap table state
    const [selected, setSelected] = useState([]);      
    // const [selectedSubtable, setSelectedSubtable] = useState([]);

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    // data from props
    
    // console logs
    console.log('[FailedSubmissions.js] - viewingFormSubmission: ', viewingFormSubmission)
    console.log('[FailedSubmissions.js] - viewingFormRow: ', viewingFormRow)

    const getMasterData = useCallback(async (table, setStateFn, conditions) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);

    }, [history, props, location]);        

    // get data on component load
    useEffect(() => {
        const table = 'failed_form_submissions';
        getMasterData(table, setSubmissionTableData);
        setViewingTable(table);

    }, [getMasterData])

    const getFailedSubmissionObject = useCallback(async (submissionRow) => {
    
        setLoading(true);

        const row = submissionRow;
        const formId = row?.form_id;
        const formType = row?.form_type;
        const centreId = row?.centre_id;
        const submittedBy = row?.submitted_by;

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
        const postData = {ids: [formId], centreId: centreId, formType: formType, submittedBy: submittedBy, failedSubmission: true};
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
            console.log('response from form-submissions/fetch: ', res.data);
            setViewingFormSubmission(res.data.formSubmissions[0]);
            handleBannerText(setBannerText, 'Form loaded successfully');
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
        }

        setLoading(false);

    }, [history, props, location]);       

    const handleFetchForm = (id) => {
        console.log('fetching form for : ', id);

        const selectedRow = submissionTableData.filter(obj => obj.form_id === id)?.[0];
        setViewingFormRow(selectedRow);
        console.log('selectedRow: ', selectedRow);
        // setViewingFormSubmission()

        // get form submission
        getFailedSubmissionObject(selectedRow);
    }

    const postSubmission = useCallback(async (submission, onSubmissionDone) => {
        
        console.log('[postSubmission] - submission: ', submission);  
        scrollPageToTop();
        alert('Ability to submit on behalf of guardian still under construction, please advise guardian to resubmit form themselves after fixing errors');
        setSelected([]);
        // setLoading(true);

        // const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        
        // // construct postdata - if centre exists in top level of form data, use it for meta data and S3 partition key
        // const postData = {}; 
        // console.log('postSubmission with post data: ', postData);
        
        // try {
        //     const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
        //     console.log('response from postSubmission to S3: ', res.data);
        //     handleBannerText(setBannerText, 'Form Submitted');
        // } catch (err) {
        //     setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
        // }

        // // show success banner and then redirec to accountOwner home
        // setLoading(false);
    

    // }, [history, props.token,  location]);   
    }, []);   

    // render form  
    useEffect(() => {

        if (viewingFormSubmission && viewingFormRow) {

            // if form loaded, display that form with contents, otherwise display empty selected form if in form-viewer mode
            const formType = viewingFormRow?.form_type; 
            const formToDisplay = formioConfig[formType]?.useForm ?? formType;
            console.log('loading up form for formType and formToDisplay: ', formType, formToDisplay);

            Formio.icons = 'fontawesome';
            Formio.createForm(document.getElementById('formio'), formioAPI + formToDisplay)
            .then(function(form) {
                console.log('rendering form: ', form);
                // Prevent the submission from going to the form.io server.
                form.nosubmit = true;

                
                const submit = (submission) => {
                    const isValid = form.checkValidity(null, true, null, false);
                    console.log('isValid: ', isValid);  
                    
                    const onSubmissionDone = () => {
                        form.emit('submitDone', submission)
                    }
                    
                    // if form is invalid, figure out which fields haven't been touched and alert user, otherwise submit form
                    if (!isValid) {
                        form.setPristine(false);

                        // initalise array to add empty fields if form fails validation
                        const newMissingFields = [];
    
                        // iterate through components and check any that are visible, required and have no value given - these have likely been missed by user
                        form.everyComponent((component) => {
                            const required = component.component.validate.required;
                            const value = component['_data'][component.component.key];
                            const visible = component['_visible'];
                            const valueType = typeof value;
                            let valueConstruct
                            if (valueType === 'object') {valueConstruct = value.constructor}

                            const path = component.path;
                            const label = component.component.label;
                            const parentLabel = component.parent.label;
                            const parentTitle = component.parent.component.title;
                            const tab = component.tab;                            

                            if (component.component.label.toLowerCase().includes('practice address')) {
                                console.log('component including address: ', component);
                                console.log('label: ', label);
                                console.log('visible: ', visible);
                                console.log('required: ', required);
                                console.log('value: ', value);
                                console.log('valueType: ', valueType);
                                console.log('valueConstruct: ', valueConstruct);                                
                                console.log('object keys len: ', Object.keys(value).length);                                
                            }
                                // console.log('every comp: ', component);
                                
                            if (required && visible && (!value || (valueConstruct === Object && Object.keys(value).length === 0))) {
                                

                                
                                // construct display label with best info we've got 
                                let displayLabel = path;
                                if (tab) {
                                    displayLabel = component.parent.component.components[tab].label;
                                } else if (parentTitle) {
                                    displayLabel = parentTitle
                                } else if (parentLabel) {
                                    displayLabel = parentLabel;
                                } 
                                
                                console.log('missing value for: ', displayLabel);
                                console.log('component: ', component);
                                console.log('tab: ', tab);
                                console.log('parentLabel: ', parentLabel);
                                console.log('parentTitle: ', parentTitle);
                                
                                newMissingFields.push(displayLabel + ' - ' + label);
                            }
                        });
                        
                        // set state to show empty fields to user in modal 
                        if (newMissingFields.length <= 0) newMissingFields.push("Apologies, we can't find the missing field - please look through the form for the red error message");
                        setMissingFields(newMissingFields);
                        setShowInvalidModal(true);
                    } else {
                        
                        // form passed validation, submit
                        console.log('posting submission: ', submission);
                        postSubmission(submission, onSubmissionDone)
                    }

                    
                }
            
                form.on('NextTab', scrollPageToTop);
                form.on('submit', (submission) => submit(submission.data)); // "submission" object is different when on submit vs a custom event, therefore this is required to make data structrue match all of our customevent forms
                form.on('CustomSubmit', submit);

                // if a draft has been restored, populate form with draft submission
                if (viewingFormSubmission) {
                    const formData = {data: viewingFormSubmission?.data};
                    form.ready.then(() => form.setSubmission(formData));
                    console.log('inserted submission into form: ', formData);
                }

            });        
        }
    }, [postSubmission, viewingFormSubmission, viewingFormRow])    

    
    let displayMissingFields = null;
    if (missingFields) {
        displayMissingFields = missingFields.map(el => <li key={el}>{el}</li>);
    }


    return (
        <div className={classes.FailedSubmissions}>
            {loading && <BackdropSpinner spinner='small-light' />}

            <Modal show={showInvalidModal} modalClosed={() => setShowInvalidModal(false)}>
                <h5>Sorry, it looks like there's something missing!</h5>
                <hr />
                    <h6>The below required fields appear to be empty:</h6>
                <div style={{textAlign: 'left'}}> 
                    <ul>{displayMissingFields}</ul>
                </div>
                    <p>If you look through the form, you should see any missing information highlighted in <span style={{color: 'red', fontWeight: 'bold'}}> red.</span></p>
            </Modal>

            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Banner show={bannerText}>{bannerText}</Banner>  
            <div className={classes.HeaderButtonRow}>
                {/* <button className="btn btn-secondary btn-sm" onClick={() => handleModeChange('account-notes')}>View Account Notes</button> */}
            </div>  
            <br/>  
            <h3>Failed Submissions</h3>          
            <hr />
            {submissionTableData?.length > 0 && <div className={classes.HeaderButtonRow}>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => selected?.length === 1 ? handleFetchForm(selected[0]) : alert('Please select just one form')}>View Form</button>
            </div>  }            
            {submissionTableData?.length > 0 &&  <SubmissionsTable table={viewingTable} selected={selected} setSelected={setSelected} data={submissionTableData}/>}
            <hr />
            {(viewingFormSubmission) && <div className={classes.FormContainer}>
                <div className={classes.Form} id="formio"></div>
            </div>}            
        </div>
    );
}

export default FailedSubmissions;