import React, { useCallback,useEffect,useState } from 'react'
import classes from './AdminEnrol.module.css';
import { useHistory, useLocation } from "react-router-dom";
import SubmissionsTable from '../SubmissionsTable/SubmissionsTable';
import { cognitoClientId, cognitoUserpoolId, AWS_API, formioAPI } from '../../../../utilities/globalVariables';
import axios from 'axios';
import { handleAwsApiError, handleBannerText, handlePrompt, scrollPageToTop } from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import Banner from '../../../../UI/Banner/Banner';
// import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import { Fragment } from 'react';
import { bootstrapTableConfig } from '../../../../utilities/globalObjects';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import { Form } from 'react-formio';
import {IoRefreshOutline} from "react-icons/io5";

// child componenents
import EnrolConfigForm from '../../AdminMgmt/Enrolments/EnrolConfigForm/EnrolConfigForm';
import TertiaryNavbar from '../../../../UI/Navigation/TertiaryNavbar/TertiaryNavbar';
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import Prompt from '../../../../UI/Prompt/Prompt';

const AdminEnrol = (props) =>  {

    // data state
    const [viewingTable] = useState('view_enrolment_forms_no_account') 
    const [submissionTableData, setSubmissionTableData] = useState();
    const [mode, setMode] = useState('migrate-enrolment');

    // ccs enrolment data
    const [selectedFormObj, setSelectedFormObj] = useState();
    const [selectedFormRow, setSelectedFormRow] = useState();
    const [userInputFormData, setUserInputFormData] = useState({})    
    const [enrolmentEmail, setEnrolmentEmail] = useState('');

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();
    const [selectedCentre, setSelectedCentre] = useState([]);
    const [showRunMigrationEngineForm, setShowRunMigrationEngineForm] = useState();
    const [migrationEngineFormData, setMigrationEngineFormData] = useState();
    const [prompt, setPrompt] = useState({});     

    // Bootstrap table state
    const [selected, setSelected] = useState([]);      

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    // console.log('location:', location);

    // get data from props
    const centres = props.centres;

    // console logs
    console.log('[AdminEnrol.js] - submissionTableData: ', submissionTableData);
    console.log('[AdminEnrol.js] - selectedFormRow: ', selectedFormRow);
    console.log('[AdminEnrol.js] - selectedFormObj: ', selectedFormObj);
    console.log('[AdminEnrol.js] - selected: ', selected);

    const getMasterData = useCallback(async (table, setStateFn, conditions, noLoading) => {
        
        noLoading ? void(0) : setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        noLoading ? void(0) : setLoading(false);

    }, [history, props, location]);  
    


    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        
        try {
            const res = await axios.post(AWS_API + route, postData, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            handleBannerText(setBannerText, `Request Completed Successfully`);

            // clear form data
            setUserInputFormData({});
            setSelectedFormObj({});
            setSelectedFormRow({});
            setEnrolmentEmail();
            
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoading(false);
    }          


    // // generic function to get data and save to state
    // const genericFetchFunction = useCallback(async (route, params, stateFn, load, scrollPageTo) => {
        
    //     load ? setLoading(true) : void(0);

    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: params
    //     };
    //     console.log('params: ', params);
        
    //     // call lambda function to get master data
    //     try {
    //         const res = await axios.get(AWS_API + route, config);
    //         console.log('data fetch res: ', res.data);
    //         stateFn(res.data);
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //     }            


    //     load ? setLoading(false) : void(0);

    //     // scroll if requested
    //     if (scrollPageTo === 'bottom') {
    //         setTimeout(() => {scrollPageToBottom()}, 100);
    //     }


    // }, [history, props.token, location]);     

    const fetchDataOnLoad = useCallback(async (centreId) => {

        setSelected([]);
        getMasterData(viewingTable, setSubmissionTableData, {column: 'centre_id', value: centreId});

    }, [getMasterData, viewingTable, setSelected])

    // fetch data on selected centre change
    useEffect(() => {
        if (selectedCentre) {
            fetchDataOnLoad(selectedCentre);
        }
    }, [fetchDataOnLoad, selectedCentre])


    // function to intercept form submission data and save to S3 when submit button pressed and CustomSubmit event fired - used for admin to make overwrites to user submitted form
    const updateFormSubmission = async (newSubmissionObject, middleware) => {
        
        setLoading(true);
        console.log('new submission object: ', newSubmissionObject);
        
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        let rdsFormId;
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: newSubmissionObject}, headers);
            console.log('response from postSubmission to S3 via form-submissions/record: ', res.data);
            
            if (!middleware) {

                // update data and show banner
                getMasterData(viewingTable, setSubmissionTableData, {column: 'form_type', value: 'bulkEnrol'});
                handleBannerText(setBannerText, 'Form Submission Recorded/Updated');
            }

            rdsFormId = res.data.formIdRDS;
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error while updating form submission');
        }
        
        // only set loading false if this isn't an intermediary function
        if (!middleware) {
            setLoading(false);

        // if middleware, return whether form saved successfully or not to determine whether to carry on in parent function logic
        } else {
            return rdsFormId
        }
    }    

    // function to get submission json data from S3 for selected form
    const getFormSubmission = async (formObj) => {

        console.log('[getFormSubmission] - formObj: ', formObj);
        const formId = formObj.form_id;
        const formType = formObj.form_type.replace('pending-', '').replace('approved-', ''); // remove pending- prefix that may be added in view_pending_forms_obj to forms approved for pending enrolment but still needing centrelink submission
        const submittedBy = formObj.submitted_by;
        const centreId = formObj.centre_id;
        
        console.log('get form submission for id, type, submittedBy, centreId: ', formId, formType, submittedBy, centreId);
        
        setLoading(true);

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        const postData = {ids: [formId], centreId: centreId, formType: formType, submittedBy: submittedBy};

        // get submission json data for selected form ID
        try {
            const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
            console.log('response from form-submissions/fetch: ', res.data);
            setSelectedFormObj(res.data.formSubmissions[0]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
        }
        setLoading(false);

    }    

    const handleViewEnrolmentForm = () => {

        // try and used selected child if they only have one enrolment, otherwise look for selected table row
        if (selected?.length === 1) {

            const rowId = selected[0];
            const uniqueSelectId = bootstrapTableConfig[viewingTable]?.uniqueSelectId;
            const selectedRow = filteredSubmissionTableData?.filter(obj => obj[uniqueSelectId] === rowId)?.[0];  
            const formId = selectedRow?.form_id;
            console.log('selectedRow: ', selectedRow);
            
            // only attempt to pull s3 json data if a form ID has been found attached to enrolment, otherwise it will fail anyway
            if (!formId) {
                alert('No enrolment form found for selected enrolment');
            } else {
                getFormSubmission(selectedRow);
                setSelectedFormRow(selectedRow);
            }
                
            
        }  else if (selected?.length === 0 || selected?.length > 1) {
            alert('Please select just one child and/or one enrolment from the enrolments table');
            

        }

    }


    // filter data down for selected centre
    let filteredSubmissionTableData;
    if (selectedCentre) {
        filteredSubmissionTableData = submissionTableData?.filter(obj => +obj.centre_id === +selectedCentre);
    }
    
    const handleSaveAndPostEnrolment = async () => {

        // configure metadata based on whether we're editing a migrated form or starting from scratch
        let newSubmissionObject;
        if (mode === 'migrate-enrolment') {

            // create new object with correct ID and new submission data
            newSubmissionObject = {
                ...selectedFormObj,
                data: selectedFormObj.data,
                centreId: selectedFormRow.centre_id,
            };

        } else if (mode === 'new-blank-enrolment') {

            newSubmissionObject = {
                submittedBy: enrolmentEmail,
                data: selectedFormObj.data,
                centreId: selectedFormObj.data.centre,
                formType: 'adminEnrol'
            };
            
        }


        // first save submission to S3  
        const rdsFormId = await updateFormSubmission(newSubmissionObject, true);
        console.log('rdsFormId: ', rdsFormId);
        
        // now post to write master data function if above function succeeded
        if (rdsFormId) {
            console.log('submitting to centrelink');
            postMasterData('initial-master-data/write', {data: {...newSubmissionObject, formId: rdsFormId, userInputFormData: userInputFormData, adminFlag: true}}, () => refreshData())
        } else {
            setLoading(false);
        }
    }

    const refreshData = () => {
        fetchDataOnLoad();
    }

    const handleModeChange = useCallback((newMode) => {
        
        if (newMode === 'migrate-enroment') {

        } else if (newMode === 'new-blank-enrolment') {
            setSelectedFormObj({data: {}});
            setSelectedFormRow({data: {}});
        }        
        
        setMode(newMode);
        setSelected([]);
        setSelectedCentre();

    }, []);    

    // populate enrolment form with selected submission data if form has been selected
    let populatedForm = null;
    if (selectedFormObj && !loading) {
        // const submissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};
        // console.log('submissionObject: ', submissionObject);
        populatedForm = (
            <div>
                <EnrolConfigForm userInputFormData={userInputFormData} setUserInputFormData={setUserInputFormData} />
                <br/>
                <button className='btn btn-block btn-success' onClick={() => handleSaveAndPostEnrolment()} >Approve Form</button>
                <hr />
                <Form 
                    src={formioAPI + 'enrol'} 
                    submission={selectedFormObj}
                    onNextTab={scrollPageToTop}
                    onCustomSubmit={updateFormSubmission} 
                />
                <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>Note: This submit button will update the original form submission - Intended use is to correct any mistakes in user's form</p>
            </div>
        );
    }    

    // render service choices
    const services = (
        <select className='form-control' value={selectedCentre ?? ''} onChange={(e) => setSelectedCentre(e.target.value)}>
            <option disabled value=''>Select Service</option>
            {centres?.sort((a,b) => {
                if ( a.centre_name < b.centre_name ) return -1;
                if ( a.centre_name > b.centre_name ) return 1;
                return 0;
            })?.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
                </select>        
            );    
    
    
    // create centre options for forms
    const centreOptions = centres?.map(obj => ({value: obj.centre_id, label: obj.centre_name}));


    let content;
    if (mode === 'migrate-enrolment') {

        content = (
           <Fragment>
               <h3>Migrate Enrolment</h3>
               <hr/>         
               <p><i>Select a centre to view prepopulated form submissions</i></p>
               <div className={classes.Row}>
                   {services}
               </div>   
               <hr/>         
               <div className={classes.HeaderButtonRow}>
                   <button className="btn btn-success btn-sm" onClick={() => {handleViewEnrolmentForm();}}>View Enrolment Form</button>
                   <button className="btn btn-danger btn-sm" onClick={() => {setShowRunMigrationEngineForm(true);}}>Run Migration Engine</button>
               </div>
   
               {filteredSubmissionTableData && <Fragment> <SubmissionsTable table={viewingTable} selected={selected} setSelected={setSelected} data={filteredSubmissionTableData}/> </Fragment>}
   
               <Fragment><hr/>{populatedForm}</Fragment>
               
           </Fragment>
       );
       
    } else if (mode === 'new-blank-enrolment') {
        
        content = (
           <Fragment>
               <h3>New Enrolment</h3>
               <hr/>  
               <span className={classes.Label}>Email to Link to New Account</span>       
               <input className='form-control' value={enrolmentEmail} onChange={(e) => {setEnrolmentEmail(e.target.value)}} />
               <Fragment><hr/>{populatedForm}</Fragment>
           </Fragment>
       );
    }

    if (loading) {
        content = <SpinnerDark />;
    }

    const handleRunMigrationEngine = () => {
        // close modal
        setShowRunMigrationEngineForm(false);

        // post data
        postMasterData('ccs/run-migration-engine', {postData: {...migrationEngineFormData}}, setMigrationEngineFormData())
    }

    return (
        <div className={classes.AdminEnrol}>
            <Prompt data={prompt} setData={setPrompt} />
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Modal tall show={showRunMigrationEngineForm} modalClosed={() => setShowRunMigrationEngineForm(false)}>
                <h3>Run Migration Engine</h3>
                <hr/>
                <ParamsForm 
                    data={migrationEngineFormData} 
                    setData={setMigrationEngineFormData} 
                    config={[
                        {name: 'centreId', type: 'react-select', options: centreOptions},
                        {name: 'enrolmentStartDate', type: 'date'},
                    ]}
                    />
                <button className='btn btn-outline-success btn-block'onClick={() => handlePrompt('run automated migration engine', () => handleRunMigrationEngine(), setPrompt) }>Submit</button>
            </Modal>                 
            <Banner show={bannerText}>{bannerText}</Banner> 
            <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                <TertiaryNavbar tab={mode} updateStateFn={handleModeChange} config={[
                    {label: 'Migrate Enrolment', value: 'migrate-enrolment'}, 
                    {label: 'New Blank Enrolment', value: 'new-blank-enrolment'}, 
                    ]} 
                />
                <button className='btn btn-sm btn-secondary' onClick={refreshData}><IoRefreshOutline size={'1rem'} color={'white'} /></button>
            </div>             

            {content}
        </div>
    );
}

export default AdminEnrol;
