import classes from './ContactUs.module.css'


const ContactUs = (props) => {

    // props
    const centresData = props.centresData;
    const childrenData = props.childrenData;
    const homeCentreIds = childrenData.map(obj => obj.home_centre_id);
    const relevantCentres = centresData.filter(obj => homeCentreIds.includes(obj.centre_id));

    // console logs
    console.log('centresData: ', centresData);

    return (
        <div className={classes.ContactUs}>
            <h3>Contact Us</h3>

            {relevantCentres.map(obj => <div key={obj.centre_id} className={classes.Container}>
                <hr/>
                <h4 >Active OOSH {obj.centre_name}</h4>
                <div className={classes.Hours}><u>Centre Operating Hours</u></div>
                {/* <div className={classes.Hours}>Before School: 6:30am to 9:00am</div> */}
                {/* <div className={classes.Hours}>After School: 2:30pm to 6:30pm</div>
                <div className={classes.Hours}>School Holidays: 7:00am to 6:00pm</div> */}
                <div className={classes.Hours}>Before School: {convertTimeRange(obj.bsc_session_times)}</div>
                <div className={classes.Hours}>After School: {convertTimeRange(obj.asc_session_times)}</div>
                <div className={classes.Hours}>School Holidays: {convertTimeRange(obj.vac_session_times)}</div>
                <div className={classes.Hours}>CLOSED PUBLIC HOLIDAYS</div>
                <br/>
                <div className={classes.Info}>Centre Phone Number: <span className={classes.Value} >{obj.phone_number}</span></div>
                <div className={classes.Info}>Centre Address:  <span className={classes.Value} >{obj.display_address}</span></div>
                <div className={classes.Info}><span className={[classes.HiddenLabel].join(' ')}>School Address: </span><span className={classes.Value} >{obj.address_street_line_1}</span></div>
                <div className={classes.Info}><span className={[classes.HiddenLabel].join(' ')}>School Address: </span><span className={classes.Value} >{obj.address_suburb}</span></div>
                <div className={classes.Info}><span className={[classes.HiddenLabel].join(' ')}>School Address: </span><span className={classes.Value} >{obj.address_state}</span></div>
                <div className={classes.Info}>Location Within School:  <span className={classes.Value} >{obj.location_within_school}</span></div>
                {/* <div className={classes.Info}><span className={classes.MinWidth}>Centre Phone Number: </span><span className={classes.Value} >{obj.phone_number}</span></div>
                <div className={classes.Info}><span className={classes.MinWidth}>Centre Address: </span> <span className={classes.Value} >{obj.display_address}</span></div>
                <div className={classes.Info}><span className={[classes.MinWidth, classes.HiddenLabel].join(' ')}>School Address: </span><span className={classes.Value} >{obj.address_street_line_1}</span></div>
                <div className={classes.Info}><span className={classes.MinWidth}>Location Within School: </span> <span className={classes.Value} >{obj.location_within_school}</span></div> */}
            </div>)}
            <hr/>
            <div className={classes.Container}>
                <h4 >Active OOSH Head Office</h4>
                <div className={classes.Hours}><u>Office Operating Hours</u></div>
                <div className={classes.Hours}>Monday - Friday: 8:30am to 4:30pm</div>
                <div className={classes.Hours}>CLOSED PUBLIC HOLIDAYS</div>
                <br/>
                <div className={classes.Info}>Office Phone Number: <span className={classes.Value} >(02) 6554 8905</span></div>
                {/* <div className={classes.Info}>Office Email Address: admin@activeoosh.com.au</div> */}
            </div>
            
            <hr/>
            <br/>

            <h6>Have a general enquiry? <a target="_blank" rel="noreferrer" href="https://www.activeoosh.com.au/">Click here</a></h6>
            <h6>Have a billing enquiry? <a target="_blank" rel="noreferrer" href="https://www.activeoosh.com.au/account-statement-enquiry">Click here</a></h6>
            <h6>Check out our upcoming programs <a target="_blank" rel="noreferrer" href="https://www.activeoosh.com.au/">here</a></h6>
            <h6>Want to leave some feedback? <a target="_blank" rel="noreferrer" href="https://www.activeoosh.com.au/">Click here</a></h6>
            <h6>Check out our website for other useful information <a target="_blank" rel="noreferrer" href="https://www.activeoosh.com.au/">www.activeoosh.com.au</a></h6>


        </div>

    );
}

export default ContactUs;


const convertTimeRange = (timeRange) => {
    const [startTime, endTime] = timeRange.split('-');
  
    const formatTime = (time) => {
      let [hours, minutes] = time.match(/\d{2}/g);
      const suffix = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12 || 12;
      return `${hours}:${minutes}${suffix}`;
    };
  
    return `${formatTime(startTime)} to ${formatTime(endTime)}`;
  };