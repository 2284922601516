import './App.css';

import { React, useState, useEffect, useRef, useCallback } from 'react';
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import 'url-search-params-polyfill';
import axios from 'axios';

import Layout from './hoc/Layout/Layout';
import Enrol from './Components/AccountOwner/Enrol/Enrol/Enrol';
import Approvals from './Components/Admin/Approvals/Approvals';
// import Auth from './Components/Auth/Auth';
import Login from './Components/Auth/Login/Login';
import MasterData from './Components/Admin/MasterData/MasterData';
import AccountOwner from './Components/AccountOwner/AccountOwner';
import Kiosk from './Components/Kiosk/Kiosk';
// import CheckIns from './Components/AccountOwner/CheckIns/CheckIns';
import AdminMgmt from './Components/Admin/AdminMgmt/AdminMgmt';
import QRScanner from './Components/AccountOwner/QRScanner/QRSCanner';
import Roll from './Components/Admin/Roll/Roll';
import CentreLink from './Components/Admin/CentreLink/CentreLink';
import CentreMgmt from './Components/Admin/CentreMgmt/CentreMgmt';
import Modal from './UI/Modal/Modal';
// import { authenticateUser } from './utilities/functions';
import { AWS_API, cognitoClientId, cognitoGrantTypeAuth
    , cognitoGrantTypeRefresh
    , cognitoRedirectURL, cognitoTokenAPI
    , cognitoURL
    , cognitoUserpoolId } from './utilities/globalVariables';
// import Spinner from './UI/Spinner/Spinner';
// import FormioDynamic from './Components/Admin/FormioDynamic/FormioDynamic';
import Cabinet from './Components/Admin/Cabinet/Cabinet';
import SpinnerDark from './UI/SpinnerDark/SpinnerDark';
import Preferences from './Components/AccountOwner/Preferences/Preferences';
import Communications from './Components/Admin/Communications/Communications';
// import Test from './Components/AccountOwner/Enrol/Enrol/Test/Test';
// import PhotoUpload from './Components/Kiosk/PhotoUpload/PhotoUpload';
// import Spinner from './UI/Spinner/Spinner';


function App() {

    // hooks for dealing with URLs 
    const history = useHistory();
    const location = useLocation();

    // hoc state
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState();

    // nav bars state
    const [notifications, setNotifications] = useState();
    const [sublinks, setSublinks] = useState();
    
    // auth flow state
    const [tradingCodeForToken, setTradingCodeForToken] = useState();
    const [dealingWithBadToken, setDealingWithBadToken] = useState();

    // signed in user state
    const [email, setEmail] = useState(); 
    const [role, setRole] = useState(); 

    // token strategy: pass through props to components that definitely need it to make API calls, use localStorage for components like
    // Auth.js which may be redirected to after an API call fails or a refresh (i.e. localRefreshToken will come from localstorage)
    const [token, setToken] = useState(); 

    // PWA state
    const [showInstallPrompt, setShowInstallPrompt] = useState();
    const [showInstallInstruct, setShowInstallInstruct] = useState();

    console.log('[App.js] - rendered');
    // console.log('[App.js] - token: ', token);

    // ---------------
    // mobile responsiveness
    const isMobile = useMediaQuery({ query: '(max-width: 733px)' })
    
    // ---------------



  //---------------------------------------------------------------------------------------------------------------------------------
  // PWA SETUP
  //---------------------------------------------------------------------------------------------------------------------------------

    let installPrompt = useRef();

    useEffect(() => {
        console.log("Listening for Install prompt");
        window.addEventListener('beforeinstallprompt',e => {
          // For older browsers
          e.preventDefault();
          console.log("Install Prompt fired");
          installPrompt.current = e;
          // See if the app is already installed, in that case, do nothing
          if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
            return false;
          }
          // Set the state variable to make button visible
          setShowInstallPrompt(true);
        });
    
        // Checks if should display install instructions in sidebar
        if (isIos() && !isInStandaloneMode()) {
          setShowInstallInstruct(true);
        }

    }, []);


    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);


    const installApp = async () => {
        if(!installPrompt.current) return false;
            installPrompt.current.prompt();
            let outcome = await installPrompt.current.userChoice;
        if(outcome.outcome === 'accepted'){
            console.log("App Installed")
        }
        else{
            console.log("App not installed");
        }
        // Remove the event reference
        installPrompt.current = null;
        // Hide the button
        setShowInstallPrompt(false);
    }
    

  //---------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------------------

    const updateAuthState = useCallback((email, groups, token, currentPath) => {

        // if path not / or /login, redirect to current path after setting token & role in state, otherwise get default 
        let redirectPath;
        if (!['/', '/login', '/badToken'].includes(currentPath)) {
            redirectPath = currentPath;
            console.log(`[App.js Auth] - remembering user's route, redirect path set to ${currentPath}`);
        } else {
            console.log(`[App.js Auth] - currentpath = ${currentPath}, so leaving app to auto redirect to default route if nothing changes`);
            
        }


        // remove google auth autogenerated group
        const cognitoGroups = groups.filter(el => !el.toLowerCase().includes('google'));
        console.log('cognitoGroups: ', cognitoGroups);

        if (cognitoGroups.includes('director')) {
            
            setRole('director');
            setToken(token);
            setEmail(email);
            redirectPath && history.replace(redirectPath);
            
            
        } else if (cognitoGroups.includes('headOffice')) {

            setRole('headOffice');
            setToken(token);
            setEmail(email);
            redirectPath && history.replace(redirectPath);

        } else if (cognitoGroups.includes('centreAdmin')) {

            setRole('centreAdmin');
            setToken(token);
            setEmail(email);
            redirectPath && history.replace(redirectPath);

        } else if (cognitoGroups.length > 0) {

            setRole(cognitoGroups[0]);
            setToken(token);
            setEmail(email);
            redirectPath && history.replace(redirectPath);
        } else {
            setRole('guest');
            setToken(token);
            setEmail(email);
        }        

        // if (cognitoGroups.includes('headOffice')) {
        //     setRole('Head Office');
        //     setToken(token);
        //     setEmail(email);
        //     redirectPath && history.replace(redirectPath);

        // } else if (cognitoGroups.includes('accountOwner')) {
        //     setRole('Account Owner');
        //     setToken(token);
        //     setEmail(email);
        //     redirectPath && history.replace(redirectPath);

        // } else if (cognitoGroups.includes("centreAdmin")) {
        //     setRole('Centre Admin');
        //     setToken(token);
        //     setEmail(email);
        //     redirectPath && history.replace(redirectPath);
            
        // } else if (cognitoGroups.includes("kiosk")) {
        //     setRole('Kiosk');
        //     setToken(token);
        //     setEmail(email);
        //     redirectPath && history.replace(redirectPath);
            
        // } else if (cognitoGroups.length > 0) {
        //     setRole();
        //     setToken(token);
        //     setEmail(email);
        //     redirectPath && history.replace(redirectPath);
            
        // } else {
        //     setRole('Guest');
        //     setToken(token);
        //     setEmail(email);
        // }
    }, [history]);
    

    const authRefreshToken = async (localRefreshToken) => {

        const params = new URLSearchParams();
        params.append('grant_type', cognitoGrantTypeRefresh);
        params.append('refresh_token', localRefreshToken);
        params.append('client_id', cognitoClientId);
        params.append('redirect_uri', cognitoRedirectURL);
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        // exchange refresh token for fresh access token with AUTH API
        let token;
        try {
            const res = await axios.post(cognitoTokenAPI, params, axiosConfig);
            // console.log('response from cognito refresh_token API: ', res.data);
            token = res.data.id_token;
            // console.log('token: ', token);
            localStorage.setItem("jwt-token", res.data.id_token);
            localStorage.setItem("jwt-token-refresh", res.data.refresh_token);
        } catch (err) {
            console.log('error: ', err.stack);
            return false;
            // console.log('failed to exchange refresh token for access token, removing from local storage');
            // localStorage.removeItem('jwt-token-refresh');
            // setError(true);
            // setLoading(false);
            // history.replace('/');
            // authenticateUser();
        } 

        // decode token to make sure it's valid and determine user's identity and privileges    
        try {
            const res = await axios.post(AWS_API + 'decode-verify-jwt', {'token': token, 'appClientId': cognitoClientId, 'userpoolId': cognitoUserpoolId});
            // console.log('ID token data: ', res.data);
            const groups = res.data["cognito:groups"];
            // console.log('groups: ', groups);
            const email = res.data.email;
            return {groups: groups, token: token, email: email};

        } catch (err) {
            console.log('error: ', err.stack);
            return false;
            // setLoading(false);
            // setError(true);
            // setErrorMessage('Authorisation could not be completed, invalid token');            
        }     
    }

    const authGetTokenFromCode = async (code) => {


        const params = new URLSearchParams();
        params.append('grant_type', cognitoGrantTypeAuth);
        params.append('code', code);
        params.append('client_id', cognitoClientId);
        params.append('redirect_uri', cognitoRedirectURL);
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        // exchange code for token with AUTH API
        let token = null;
        try {
            const res = await axios.post(cognitoTokenAPI, params, axiosConfig);
            token = res.data.id_token;
            localStorage.setItem("jwt-token", res.data.id_token);
            localStorage.setItem("jwt-token-refresh", res.data.refresh_token);
        } catch (err) {
            console.log('error: ', err.stack);
            // setError(true);
            // setLoading(false);
        }

        // decode token to make sure it's valid and determine user's identity and privileges    
        try {
            const res = await axios.post(AWS_API + 'decode-verify-jwt', {'token': token, 'appClientId': cognitoClientId, 'userpoolId': cognitoUserpoolId});
            // console.log('ID token data: ', res.data);
            const groups = res.data["cognito:groups"];
            // console.log('groups: ', groups);
            const email = res.data.email;
            return ({email: email, groups: groups, token: token});
        } catch (err) {
            console.log('error: ', err.stack);
            return false;
        } 


    }

    const authVerifyToken = async (localToken) => {

        // decode token to make sure it's valid and determine user's identity and privileges    
        try {
            const res = await axios.post(AWS_API + 'decode-verify-jwt', {'token': localToken, 'appClientId': cognitoClientId, 'userpoolId': cognitoUserpoolId});
            const tokenVerifyRes = res.data;
            // console.log('ID token data: ', tokenVerifyRes);
            const groups = tokenVerifyRes["cognito:groups"];
            // console.log('groups: ', groups);
            const email = tokenVerifyRes.email;
            return {groups: groups, token: localToken, email: email}; // If authenticated this will switch routes and unmount component - don't change state after this!
        } catch (err) {
            console.log('error: ', err.stack);
            return false;

            // token validation failed, probably expired - so remove from storage and call function again to either use refresh token or redirect to sign in again
            // console.log('verifying of local token failed, remove from local storage to avoid endless loop');
            // localStorage.removeItem("jwt-token");
            // authenticateUser();
        } 
    }
    
    const redirectToCognito = () => {
        // console.log('[App.js redirectToCognito] - no tokens found or working, redirecting to sign in');
        window.location.replace(cognitoURL);
    }

  // signout function
  const signout = () => {
    history.replace("/login");

    setSublinks();
    setNotifications();
    setEmail();
    setRole();
    localStorage.removeItem('jwt-token');
    localStorage.removeItem('jwt-token-refresh');
  }

    // check if we've been redirected here from Cognito with a grant code, and if not check if user has a token in local storage or a refresh token to auth with rather than making them sign into Cognito again
    let cognitoGrantCode = useRef();
    let localRefreshToken = useRef();
    let localToken = useRef();
    useEffect(() => {

        // get data needed to determine correct auth path
        const currentPath = location.pathname;
        localRefreshToken.current = localStorage.getItem("jwt-token-refresh");
        localToken.current = localStorage.getItem("jwt-token");       
        const parsedURL = new URLSearchParams(location.search)
        cognitoGrantCode.current = parsedURL.get("code");           
        let badToken = parsedURL.get("badToken");
        console.log('badToken: ', badToken);
        
        
        // if redirected here from error handler due to forbidded API call, reset token (it may have expired)
        if (badToken && !dealingWithBadToken) {
            
            // put a 10s hold on deailing with bad tokens to ensure multiple failed requests in one go don't redirect many times
            setDealingWithBadToken(true);
            setTimeout(() => {
                console.log('[App.js Auth] - setting dealingWithBadToken back to false')
                setDealingWithBadToken(false);
            }, 10000);

            console.log('[App.js Auth] - redirected with ?badToken=true, resetting token in state and removing search param');
            setToken();
            history.replace(location.pathname);
        } else if (badToken && dealingWithBadToken) {
            console.log('[App.js Auth] - redirected with ?badToken=true but already dealing with it, return false');
            history.replace(location.pathname);

        }
    
        // ------------------------------------------------------------
        // auth aysnc functions to call token/verification APIs and await reponse
        
        // if code in url, try swap it for a token to auth user
        const swapCode = async (code) => {
            
            setLoading(true);
            const res = await authGetTokenFromCode(code);
            console.log('[App.js - Swap Code] - code verified, token returned for user groups: ', res.groups);
            // console.log('location.pathname: ', location.pathname)
            updateAuthState(res.email, res.groups, res.token, currentPath);
            setLoading(false);
            setTradingCodeForToken(false);
            
        }

        // swap refresh token for new token
        const refreshToken = async (refreshToken) => {
            
            setLoading(true);
            const res = await authRefreshToken(refreshToken);
            
            // if token successfully refreshed and verified, set token & role in state
            if (res) {
                console.log('[App.js - Refresh Token] - token refreshed and verified successfully for groups', res.groups);
                updateAuthState(res.email, res.groups, res.token, currentPath);
                setLoading(false);
                
            } else {
                console.log('[App.js - Refresh Token] - token refresh failed, remove refresh token from local storage and redirect to Cognito');
                localStorage.removeItem('jwt-token-refresh');
                redirectToCognito();
            }
        }        
        
        // verify validity of token
        const verifyToken = async (localToken) => {
            
            setLoading(true);
            const res = await authVerifyToken(localToken);
            
            // if token successfully verified, set token & role in state
            if (res) {
                console.log('[App.js - Verify Token] - token verified successfully for groups', res.groups);
                updateAuthState(res.email, res.groups, res.token, currentPath);
                setLoading(false);
                
            } else {
                // otherwise token verification failed, 
                console.log('[App.js - Verify Token] - token verification failed, remove from local storage and check for refresh token');
                localStorage.removeItem('jwt-token');
                
                if (localRefreshToken.current) {
                    console.log('[App.js - Verify Token] - refresh token found, attempt to refresh');
                    refreshToken(localRefreshToken.current);
                } else {
                    console.log('[App.js - Verify Token] - no refresh token found - redirect to cognito');
                    redirectToCognito();
                    
                }
                
            }

        }
        // ------------------------------------------------------------
        
        
        
        
        // ------------------------------------------
        // auth flow logic here
        
        if (!tradingCodeForToken) {

            if (localToken.current && (!token || badToken)) {
                console.log('[App.js Auth] - token found in local storage, verify: ', localToken.current?.slice(0,10) + '...');
                verifyToken(localToken.current);
            } else if (localRefreshToken.current && (!token || badToken)) {
                console.log('[App.js Auth] - refresh token found in local storage, refresh and verify: ', localRefreshToken.current?.slice(0,10) + '...');
                refreshToken(localRefreshToken.current);
            } else if (cognitoGrantCode.current && (!token || badToken)) {
                setTradingCodeForToken(true);
                console.log('[App.js Auth] - cognitoGrantCode found in URL - trade for token: ', cognitoGrantCode.current?.slice(0,10) + '...');
                swapCode(cognitoGrantCode.current);
            } else if (!token || badToken) {
                console.log('[App.js Auth] - no code, no token and no refresh token, redirecting to Cognito');
                redirectToCognito();
            } else {
                console.log('[App.js Auth] - reached end of if/else, must be token in state - go forward and use! ', token?.slice(0,10) + '...');
                
            }
            
        } else  {
            
            console.log('[App.js Auth] - currently trading code for token, wait for response before running any other auth flows ');
        }


        // -------------------------------
        
        
        
    }, [history, location.search, location.pathname, updateAuthState, token, tradingCodeForToken, dealingWithBadToken]);
    
    

  
    // check if we've been redirected here from Cognito with a grant code, and if not check if user has a token in local storage or a refresh token to auth with rather than making them sign into Cognito again
//   let cognitoGrantCode = useRef();
//   let localRefreshToken = useRef();
//   let localToken = useRef();
    // useEffect(() => {

    //     const runAuth = async () => {
    //         const parsedURL = new URLSearchParams(location.search)
    //         cognitoGrantCode.current = cognitoGrantCode.current ?? parsedURL.get("code");

            

    //         if (cognitoGrantCode.current && parsedURL.get("code")) {
        
    //             // code found, so redirect to auth with code to authenticate user
    //             // setCode(cognitoGrantCode);
    //             console.log('code found: ', cognitoGrantCode);
    //             console.log('calling history/replace("auth")');
    //             // setRole('beingAuthenticated');
    //             //   history.replace("/auth");
    //             authenticateUser(cognitoGrantCode.current, cognitoGrantTypeAuth, cognitoClientId, axios, cognitoTokenAPI, AWS_API, cognitoRedirectURL, cognitoUserpoolId, cognitoGrantTypeRefresh, cognitoURL, setError, updateAuthState, setLoading);
    //         } else {
    //             localRefreshToken.current = localStorage.getItem("jwt-token-refresh");
    //             localToken.current = localStorage.getItem("jwt-token");

    //             // if there's no grant code and there is a token or refresh token, force redirect to Auth where Auth component will see there's no code in props, but there is a refresh token in local storage
    //             if (localRefreshToken.current || localToken.current) {
    //                 console.log('[App.js] - local token or refresh token found, redirecting to auth with said token');
    //                 // history.replace('/auth');
    //                 // setLoading(true);
    //                 const res = await authenticateUser(null, cognitoGrantTypeAuth, cognitoClientId, axios, cognitoTokenAPI, AWS_API, cognitoRedirectURL, cognitoUserpoolId, cognitoGrantTypeRefresh, cognitoURL, setError, updateAuthState, setLoading);
    //                 console.log('res: ', res);

                    
    //                 // setLoading(false);
    //             } else {
    //                 console.log('[App.js] - no code found and no tokens found, continuing with component render')
    //                 console.log('history: ', history);
    //             }
        
    //         }
    //     }

    //     // if ()
    //     runAuth();


    // }, [location.search, history, updateAuthState])

  // if we didn't get here via cognito with a grant code, check if user has a token in local storage or a refresh token to auth with rather than making them sign into Cognito again
//   let localRefreshToken = useRef();
//   let localToken = useRef();
//   useEffect(() => {
//     console.log('[localRefreshToken useEffect]');
//     if (!cognitoGrantCode.current) {
//     //   localRefreshToken.current = localStorage.getItem("jwt-token-refresh");
//     //   localToken.current = localStorage.getItem("jwt-token");

//       // if there's no grant code and there is a refresh token, force redirect to Auth where Auth component will see there's no code in props, but there is a refresh token in local storage
//       if (localRefreshToken.current || localToken.current) {
//         console.log('[App.js] - local token or refresh token found, redirecting to auth with said token');
//         history.replace('/auth');
//       }
//     }
//   }, [history]);


  
  let routes = <div style={{paddingTop: '15vh'}}><SpinnerDark /></div>;

  if (!loading) {

      if (role === 'kiosk') {
        routes = (
            <Switch>
              <Route path="/kiosk" render={(props) => <Kiosk {...props} isMobile={isMobile}  setSublinks={setSublinks} token={token} />} /> 
              {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
              <Redirect to="/kiosk" />          
            </Switch>     
          );
      } else if (role === 'accountOwner') {
        routes = (
            <Switch>
              {/* token being passed in as props here for authorisation in API calls */}
              <Route path="/account" render={(props) => <AccountOwner {...props} setSublinks={setSublinks} setNotifications={setNotifications} isMobile={isMobile} token={token} email={email} />} /> 
              <Route path="/qrscanner" render={(props) => <QRScanner {...props} setSublinks={setSublinks} token={token} />} /> 
              {/* <Route path="/checkin" render={(props) => <CheckIns {...props} setSublinks={setSublinks} token={token} />} />  */}
              <Route path="/enrol" render={(props) => <Enrol {...props} setSublinks={setSublinks} email={email} token={token}/>} /> 
              <Route path="/preferences" render={(props) => <Preferences {...props} setSublinks={setSublinks} email={email} token={token}/>} /> 
              {/* <Route path="/test" render={(props) => <Test {...props} email={email} token={token}/>} />  */}
              {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
    
              <Redirect to="/account" />
            </Switch>  
          );
      } else if (role === 'centreAdmin') {
        routes = (
                <Switch>
            {/* <Route path="/approvals" render={(props) => <Approvals {...props} email={email} token={token}/>} /> */}
            <Route path="/masterData" render={(props) => <MasterData {...props} setSublinks={setSublinks} token={token} role={role}/>} />
            <Route path="/centreMgmt" render={(props) => <CentreMgmt {...props} setSublinks={setSublinks} token={token} />} /> 
            <Route path="/roll" render={(props) => <Roll {...props} setSublinks={setSublinks} role={role} token={token} isMobile={isMobile} />} />
            {/* <Route path="/enrol" render={(props) => <Enrol {...props} setSublinks={setSublinks} />} /> */}
            <Route path="/filing" render={(props) => <Cabinet {...props} setSublinks={setSublinks} token={token} centreAdminFlag />} />
            {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
            <Redirect to="/centreMgmt" />
        </Switch>  
        );
      } else if (['headOffice'].includes(role)) {
        routes = (
            <Switch>
                <Route path="/approvals" render={(props) => <Approvals {...props} setSublinks={setSublinks}  setNotifications={setNotifications}  email={email} token={token}/>} />
                <Route path="/masterData" render={(props) => <MasterData {...props} setSublinks={setSublinks} token={token} role={role}/>} />
                <Route path="/roll" render={(props) => <Roll {...props} setSublinks={setSublinks} role={role} token={token} isMobile={isMobile} />} />
                <Route path="/filing" render={(props) => <Cabinet {...props} setSublinks={setSublinks} token={token} />} />
                <Redirect to="/approvals" />
            </Switch>     
      );
      } else if (['director'].includes(role)) {
        routes = (
            <Switch>
                <Route path="/approvals" render={(props) => <Approvals {...props} setSublinks={setSublinks}  setNotifications={setNotifications}  email={email} token={token}/>} />
                <Route path="/masterData" render={(props) => <MasterData {...props} setSublinks={setSublinks} token={token} role={role}/>} />
                <Route path="/centrelink" render={(props) => <CentreLink {...props} setSublinks={setSublinks} token={token} />} />
                <Route path="/roll" render={(props) => <Roll {...props} setSublinks={setSublinks} role={role} token={token} isMobile={isMobile} />} />
                {/* <Route path="/enrol" render={(props) => <Enrol {...props} setSublinks={setSublinks} />} /> */}
                <Route path="/filing" render={(props) => <Cabinet {...props} setSublinks={setSublinks} token={token} />} />
                {/* <Route path="/forms" render={(props) => <FormioDynamic {...props} token={token} />} /> */}
                {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
                <Route path="/mgmt" render={(props) => <AdminMgmt {...props} setSublinks={setSublinks} token={token} />} /> 
                <Route path="/communications" render={(props) => <Communications {...props} setSublinks={setSublinks} token={token}/>}/>
                <Redirect to="/approvals" />
            </Switch>     
      );
      } else if (role) {
          routes = (
            <Switch>
            </Switch>     
          );
      } else {
        routes = (
            <Switch>
              <Route path="/login" render={(props) => <Login {...props} setSublinks={setSublinks} />} />
              {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
              {/* <Route path="/enrol" render={(props) => <Enrol {...props}  />} /> */}
              <Redirect to="/login" />
            </Switch>
          );
      }
        // case 'guest':
        //   routes = (
        //     <Switch>
        //     </Switch>     
        //   );
        //   break;
    // switch (role) {
    //     case 'kiosk':
    //       routes = (
    //         <Switch>
    //           <Route path="/kiosk" render={(props) => <Kiosk {...props}  setSublinks={setSublinks} token={token} />} /> 
    //           {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
    //           <Redirect to="/kiosk" />          
    //         </Switch>     
    //       );
    //       break;
    //       case 'accountOwner':
    //           routes = (
    //         <Switch>
    //           {/* token being passed in as props here for authorisation in API calls */}
    //           <Route path="/account" render={(props) => <AccountOwner {...props} setSublinks={setSublinks} setNotifications={setNotifications} isMobile={isMobile} token={token} email={email} />} /> 
    //           <Route path="/qrscanner" render={(props) => <QRScanner {...props} setSublinks={setSublinks} token={token} />} /> 
    //           <Route path="/checkin" render={(props) => <CheckIns {...props} setSublinks={setSublinks} token={token} />} /> 
    //           <Route path="/enrol" render={(props) => <Enrol {...props} setSublinks={setSublinks} email={email} token={token}/>} /> 
    //           {/* <Route path="/test" render={(props) => <Test {...props} email={email} token={token}/>} />  */}
    //           {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
    
    //           <Redirect to="/account" />
    //         </Switch>  
    //       );
    //       break;
    //       case 'centreAdmin':
    //           routes = (
    //               <Switch>
    //           {/* <Route path="/approvals" render={(props) => <Approvals {...props} email={email} token={token}/>} /> */}
    //           <Route path="/masterData" render={(props) => <MasterData {...props} setSublinks={setSublinks} token={token} role={role}/>} />
    //             <Route path="/centreMgmt" render={(props) => <CentreMgmt {...props} setSublinks={setSublinks} token={token} />} /> 
    //           <Route path="/roll" render={(props) => <Roll {...props} setSublinks={setSublinks} token={token} />} />
    //           <Route path="/enrol" render={(props) => <Enrol {...props} setSublinks={setSublinks} />} />
    //           <Route path="/filing" render={(props) => <Cabinet {...props} setSublinks={setSublinks} token={token} centreAdminFlag />} />
    //           {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
    //           <Redirect to="/centreMgmt" />
    //         </Switch>  
    //       );
    //       break;
    //       case 'headOffice':
    //           routes = (
    //             <Switch>
    //                 <Route path="/approvals" render={(props) => <Approvals {...props} setSublinks={setSublinks}  setNotifications={setNotifications}  email={email} token={token}/>} />
    //                 <Route path="/masterData" render={(props) => <MasterData {...props} setSublinks={setSublinks} token={token} role={role}/>} />
    //                 <Route path="/centrelink" render={(props) => <CentreLink {...props} setSublinks={setSublinks} token={token} />} />
    //                 <Route path="/roll" render={(props) => <Roll {...props} setSublinks={setSublinks} token={token} />} />
    //                 <Route path="/enrol" render={(props) => <Enrol {...props} setSublinks={setSublinks} />} />
    //                 <Route path="/filing" render={(props) => <Cabinet {...props} setSublinks={setSublinks} token={token} />} />
    //                 {/* <Route path="/forms" render={(props) => <FormioDynamic {...props} token={token} />} /> */}
    //                 {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
    //                 <Route path="/mgmt" render={(props) => <AdminMgmt {...props} setSublinks={setSublinks} token={token} />} /> 
    //                 <Redirect to="/approvals" />
    //             </Switch>     
    //       );
    //       break;
    //     // case 'beingAuthenticated':
    //     //   routes = (
    //     //     <Switch>
    //     //       <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current} />} />
    //     //       <Redirect to="/auth" />
    //     //     </Switch>     
    //     //   );
    //     //   break;
    //     default:
    //         // routes = (
    //         //     <Switch>
    //         //     </Switch>     
    //         //   );
    //         //   break;            
    //       routes = (
    //         <Switch>
    //           <Route path="/login" render={(props) => <Login {...props} setSublinks={setSublinks} />} />
    //           {/* <Route path="/auth" render={(props) => <Auth {...props} updateAuthState={updateAuthState} code={cognitoGrantCode.current}/>} /> */}
    //           {/* <Route path="/enrol" render={(props) => <Enrol {...props}  />} /> */}
    //           <Redirect to="/login" />
    //         </Switch>
    //       );
        
    //   }
  }



  // function to deal with S3 routing issues - S3 will redirect URL paths to /#/pathname, and this function will set route accordingly
  // const replaceHashPath = () => {
  //   const hash = location.hash;

  //   // console.log('location: ', location);
  //   // console.log('hash: ', hash);
  //   if (hash) {
  //       console.log('checking if need to replace path: ', hash);
  //       const path = hash.replace(/^#/, '')
  //       if (path) {
  //         console.log('replacing path with: ', path);
  //         setTimeout(() => history.replace(path), 10);
  //         // history.replace(path);
  //       }
  //     }
  //   }
  //   replaceHashPath();
    // console.log('location: ', location);

  return (

      
      <div className="App">
        <Modal show={error} modalClosed={() => setError(false)}><h2>Oops!</h2><p>Something went wrong...</p></Modal> 
        {/* <Router> */}
          <Layout 
            email={email} 
            role={role} 
            signout={signout}
            showInstallPrompt={showInstallPrompt} 
            showInstallInstruct={showInstallInstruct}
            accepted={installApp} 
            dismissed={() => setShowInstallPrompt(false)}
            sublinks={sublinks}
            notifications={notifications}
            isMobile={isMobile}
          >

            {routes}
          </Layout>
        {/* </Router> */}
      </div>

    // </Suspense>
  );
}

export default App;
