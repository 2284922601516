import {React, Fragment, useState, useCallback, useEffect} from 'react';
import classes from './AdminMgmt.module.css';
// import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../UI/Modal/Modal';
import { useHistory, useLocation } from "react-router-dom";
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import Cognito from './Cognito/Cognito';
import DeviceMgmt from './DeviceMgmt/DeviceMgmt';
import ProviderProfile from './ProviderProfile/ProviderProfile';
import ServiceProfiles from './ServiceProfiles/ServiceProfiles';
import Personnel from './Personnel/Personnel';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../utilities/globalVariables';
import { handleAwsApiError } from '../../../utilities/functions';
import axios from 'axios';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import Enrolments from './Enrolments/Enrolments';
import ReturnFees from './ReturnFees/ReturnFees';
import Messages from './Messages/Messages';
import Debt from './Debt/Debt';
import Events from './Events/Events';
// import AdminRoll from './AdminRoll/AdminRoll';
import Settings from './Settings/Settings';
import SessionCalendar from './SessionCalendar/SessionCalendar';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';


const AdminMgmt = (props) => {

    // UI state
    // const [loading, setLoading] = useState(false);
    // const [showCentrelinkOptions, setShowCentrelinkOptions] = useState();
    
    const [error, setError] = useState(false);
    // const [loading, setLoading] = useState();

    // data state
    const [centres, setCentres] = useState();
    const [allCentresAndBuses, setAllCentresAndBuses] = useState();

    const [showContent, setShowContent] = useState('settings')

    
    // react router history instance to navigate user to other pages
    let history = useHistory();   
    let location = useLocation();

    // console logs
    console.log('[AdminMgmt.js] - centres: ', centres);

    // get data from props
    const setSublinks = props.setSublinks;

    // const handleDisplayContent = useCallback((show) => {
    //     const newShowContent = {...showContent};

    //     // set all to false
    //     Object.keys(newShowContent).forEach(key => {
    //         newShowContent[key] = false;
    //         });

    //     // show selected and update state
    //     newShowContent[show] = true;
    //     setShowContent(newShowContent);

    // }, []);


    // set leftpane sublinks
    useEffect(() => {
        setSublinks([
            // {title: 'Admin Roll', icon: 'question', onClick: () => setShowContent('adminRoll')},
            {title: 'Settings', icon: 'question', onClick: () => setShowContent('settings')},
            {title: 'Session Calendar', icon: 'question', onClick: () => setShowContent('sessionCalendar')},
            {title: 'Manage Users', icon: 'question', onClick: () => setShowContent('cognitoUsers')},
            {title: 'CCS Devices', collapsed: true, icon: 'question', onClick: () => setShowContent('ccsDevices')},
            {title: 'Provider Profiles', collapsed: true, icon: 'question', onClick: () => setShowContent('providerProfile')},
            {title: 'Service Profiles', collapsed: true, icon: 'question', onClick: () => setShowContent('serviceProfiles')},
            {title: 'Personnel', collapsed: true, icon: 'question', onClick: () => setShowContent('personnel')},
            {title: 'Enrolments', collapsed: true, icon: 'question', onClick: () => setShowContent('enrolments')},
            {title: 'ReturnFees', collapsed: true, icon: 'question', onClick: () => setShowContent('returnFees')},
            {title: 'Messages', collapsed: true, icon: 'question', onClick: () => setShowContent('messages')},
            {title: 'Debt', collapsed: true, icon: 'question', onClick: () => setShowContent('debt')},
            {title: 'Events', collapsed: true, icon: 'question', onClick: () => setShowContent('events')},
        ]);
    }, [setSublinks, setShowContent])    

    // get centres data to pass to children components
    const getCentresData = useCallback(async () => {
        
        // setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'view_centres'}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setCentres(res.data.filter(obj => obj.service_crn) ?? []); // only store centres with a CRN for now
            setAllCentresAndBuses(res.data ?? []);  // also store list of centres without a service CRN and buses
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        // setLoading(false);

    }, [history, props, location]);    

    // get initial centres data
    useEffect(() => {
        getCentresData();
    }, [getCentresData])



    

    let content = <SpinnerDark />;
    // components with data dependencies 
    if (centres) {
        if (showContent === 'serviceProfiles') {
            content = <ServiceProfiles token={props.token} centres={centres}/>
        } else if (showContent === 'personnel') {
            content = <Personnel token={props.token} centres={centres}/>
        } else if (showContent === 'enrolments') {
            content = <Enrolments token={props.token} centres={centres}/>
        } else if (showContent === 'returnFees') {
            content = <ReturnFees token={props.token} centres={centres}/>
        } else if (showContent === 'messages') {
            content = <Messages token={props.token} centres={centres}/>
        } else if (showContent === 'debt') {
            content = <Debt token={props.token} centres={centres}/>
        } else if (showContent === 'events') {
            content = <Events token={props.token} centres={centres}/>
        // } else if (showContent === 'adminRoll') {
        //     content = <AdminRoll token={props.token} centres={centres} role='headOffice'/>
        } else if (showContent === 'settings') {
            content = <Settings token={props.token} centres={centres} getCentresData={getCentresData} allCentresAndBuses={allCentresAndBuses}/>
        } else if (showContent === 'cognitoUsers') {
            content = <Cognito token={props.token} centres={centres} allCentresAndBuses={allCentresAndBuses}/>
        } else if (showContent === 'sessionCalendar') {
            content = <SessionCalendar token={props.token} centres={centres}/>

        }
    }

    if (showContent === 'ccsDevices') {
        content = <DeviceMgmt token={props.token} />
    } else if (showContent === 'providerProfile') {
        content = <ProviderProfile  token={props.token}/>
    } 


    return (
        <Fragment >
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <div className={classes.AdminMgmt}>
                {content}
            </div>
{/* 
            <div className={'LeftBar'}>
                <button className="btn btn-block btn-sm CustomButtonPrimary" onClick={() => handleDisplayContent('adminRoll')}>Roll</button>
                <button className="btn btn-block btn-sm CustomButtonPrimary" onClick={() => handleDisplayContent('settings')}>Internal Settings</button>
                <button className="btn btn-block btn-sm CustomButtonPrimary" onClick={() => handleDisplayContent('cognitoUsers')}>Manage Users</button>
                <button className="btn btn-block btn-sm CustomButtonPrimary" onClick={() => setShowCentrelinkOptions(!showCentrelinkOptions)}>▼ Centrelink Config</button>

                {showCentrelinkOptions &&
                <Fragment>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('ccsDevices')}>Manage CCS Devices</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('providerProfile')}>Provider Profile</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('serviceProfiles')}>Service Profiles</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('personnel')}>Personnel</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('enrolments')}>Enrolments</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('returnFees')}>Return Fee Reductions</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('messages')}>Messages</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('debt')}>Debt</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('events')}>Events</button>
                </Fragment>
                
                }                    
            </div> */}


        </Fragment>
    );
}

export default AdminMgmt;