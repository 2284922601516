import {React} from "react";
import classes from './NotificationIcon.module.css';
import { IoIosNotifications } from "react-icons/io";

const NotificationIcon = (props) => {
    
    const actionNotificationsNumber = props.actionNotificationsNumber;
    const setShowNotifications = props.setShowNotifications;

    console.log('actionNotificationsNumber: ', actionNotificationsNumber)

    return (
        <div className={classes.NotificationIcon}>
            {<IoIosNotifications className={classes.Icon} onClick={() => setShowNotifications(true)} size="2rem" />}
            {<span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-danger">
                {actionNotificationsNumber > 0 ? actionNotificationsNumber : ''}
            </span>}
        </div>
    )    
}

export default NotificationIcon