import {React, useState, useCallback, useEffect, Fragment} from 'react';
import classes from './ProviderProfile.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import SupportingEvidence from '../SupportingEvidence/SupportingEvidence';
// import Debt from './Debt/Debt';

const ProviderProfile = (props) => {

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    const [editing, setEditing] = useState();
    
    // data state
    const [data, setData] = useState();
    const [updatedProviderData, setUpdatedProviderData] = useState();
    
    // debt state
    // const [debtData, setDebtData] = useState();
    // const [showDebt, setShowDebt] = useState();

    // supporting evidence API call state
    const [showSupportingEvidenceFormFor, setShowSupportingEvidenceFormFor] = useState();        
    
    // react router history instance to navigate user to other pages
    let history = useHistory();       
    const location = useLocation();

    // console logs
    console.log('data: ', data);
    console.log('updatedProviderData: ', updatedProviderData);

    // const getData = useCallback(async () => {
        
    //     setLoading(true);
    
    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: {}
    //     };
        
    //     // call lambda function to get master data
    //     try {
    //         const res = await axios.get(AWS_API + 'ccs/read-provider-profile', config);
    //         console.log('data fetch res: ', res.data);
    //         setData(res.data);
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //     }            

    //     setLoading(false);


    // }, [history, props]);

    // function to get data
    const getData = useCallback(async (storeDataFn, routeToInvoke, additionalData) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: routeToInvoke, ...additionalData}
        };
        console.log('config.params: ', config.params);
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            storeDataFn(res?.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        setLoading(false);


    }, [history, props, location]);     


    // get initial data
    useEffect(() => {
        getData(setData, 'read-provider-profile');
    }, [getData])    
    
    // const handleShowDebt = () => {
    //     getData(setDebtData, 'read-debt');
    //     setShowDebt(true);

    // }

    // function to update provider profile data
    const updateProfile = useCallback(async () => {
        
        setLoading(true);
    
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            updatedProviderData
        };
        try {
            const res = await axios.post(AWS_API + 'ccs/update-provider-profile', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Profile updated`);
            setUpdatedProviderData();
            getData();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating provider profile');
            setLoading(false);
        }           


    }, [history, props, getData, updatedProviderData, location]); 



    const onSupportingEvidenceSuccess = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setShowSupportingEvidenceFormFor();
        handleBannerText(setBannerText, 'Supporting evidence successfully uploaded');
        
    }
    const onSupportingEvidencFailure = (err) => {
        setShowSupportingEvidenceFormFor();
        setError(handleAwsApiError(err, history, location) ?? 'Error encountered while uploading documents');
    }       

    // show inputs for editable fields if we're in editing mode
    let inputs;
    if (editing) {
        inputs = (
            <Fragment>
                <hr />
                <h6>New Profile Values</h6>
                <div className={classes.Row}> <span className={classes.Item}>Business Name</span> 
                <input className="form-control" type="text" value={updatedProviderData?.businessName ?? ''} onChange={(e) => {setUpdatedProviderData({...updatedProviderData, businessName: e.target.value})}}/></div>  
                <p> </p> 
                <button className='btn btn-success btn-block btn-sm' onClick={updateProfile}>Submit</button>             
            </Fragment>
        );
    }

    let content = <SpinnerDark />;
    if (!loading) {
        if (data) {
            const provData = data[0];
            content = (
                <Fragment>
                        <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                            {/* <button className="btn CustomButtonPrimary" onClick={handleShowDebt}>Debt</button> */}
                            <button className="btn btn-light" onClick={() => setShowSupportingEvidenceFormFor(provData.providerID)}>Upload Supporting Evidence</button>
                            <button className="btn CustomButtonPrimary" onClick={() => setEditing(!editing)}>Edit Profile</button>
                        </div>
                        <div className={classes.Row}> <span className={classes.Item}>ID</span> <div>{provData.providerID}</div> </div>
                        <div className={classes.Row}> <span className={classes.Item}>Legal Name (ABR Registered)</span> <div>{provData.ProviderName.results.filter(obj => obj.type === 'LGL')[0].name}</div> </div>
                        <div className={classes.Row}> <span className={classes.Item}>Business Name</span> <div>{provData.ProviderName.results.filter(obj => obj.type === 'BUS')[0].name}</div> </div>
                        <div className={classes.Row}> <span className={classes.Item}>Address</span> <div>{provData.Address.results[0].streetLine1 + ', ' + provData.Address.results[0].streetLine2 + ', ' + provData.Address.results[0].suburb}</div> </div>
                        <div className={classes.Row}> <span className={classes.Item}>CCS Approval Start Date</span> <div>{provData.CCSApproval.results[0].startDate}</div> </div>
                        <div className={classes.Row}> <span className={classes.Item}>CCS Approval End Date</span> <div>{provData.CCSApproval.results[0].endDate}</div> </div>
    
                        {inputs}
                </Fragment>
            );
        } else {
            content = <p><i>No data loaded</i></p>;
        }
    }

    return (
        <Fragment>
            <Banner show={bannerText}>{bannerText}</Banner>                
            <div className={classes.ProviderProfile}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <Modal show={showSupportingEvidenceFormFor} modalClosed={() => setShowSupportingEvidenceFormFor(false)}>
                        {showSupportingEvidenceFormFor && <SupportingEvidence token={props.token} onSuccess={onSupportingEvidenceSuccess} onFailure={onSupportingEvidencFailure} documentsFor='provider' id={showSupportingEvidenceFormFor}/>}
                </Modal>               
                {/* <Modal show={showDebt} modalClosed={() => setShowDebt(false)}>
                        {debtData && <Debt debtData={debtData}/>}
                </Modal>                */}
                <h3>
                    Provider Profile
                </h3>
                {content}
            </div>
        </Fragment>
    )
}

export default ProviderProfile;