import React, { useCallback,useEffect,useState } from 'react'
import classes from './AccountMgmt.module.css';
import { useHistory, useLocation } from "react-router-dom";
import SubmissionsTable from '../SubmissionsTable/SubmissionsTable';
import { cognitoClientId, cognitoUserpoolId, AWS_API, formioAPI } from '../../../../utilities/globalVariables';
import axios from 'axios';
import { handleAwsApiError, handleBannerText, humanize, scrollPageToBottom, scrollPageToTop } from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import Banner from '../../../../UI/Banner/Banner';
// import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import ScheduleView from '../../../AccountOwner/Bookings/ScheduleView/ScheduleView';
import UpdateSchedule from './UpdateSchedule/UpdateSchedule';
import { Fragment } from 'react';
import { bootstrapTableConfig, generalDocTypes } from '../../../../utilities/globalObjects';
import TertiaryNavbar from '../../../../UI/Navigation/TertiaryNavbar/TertiaryNavbar';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import {IoRefreshOutline} from "react-icons/io5";
import DirectDebitStatus from '../../../Misc/DirectDebitStatus/DirectDebitStatus';
import {cloneDeep} from 'lodash';
import { Form } from 'react-formio';

// child componenents
import ParentData from '../../../AccountOwner/ParentData/ParentData';
import AccountBalance from './AccountBalance/AccountBalance';
import NewInvoiceCreditForm from './NewInvoiceCreditForm/NewInvoiceCreditForm';
import UpdateContactDetails from './UpdateContactDetails/UpdateContactDetails';
import UpdateDetails from './UpdateDetails/UpdateDetails';
import ChildSelector from './ChildSelector/ChildSelector';
import AddChildren from '../../AdminMgmt/AdminRoll/AddChildren/AddChildren';
import EnrolConfigForm from '../../AdminMgmt/Enrolments/EnrolConfigForm/EnrolConfigForm';
// import StylisedTable from '../../../../UI/StylisedTable/StylisedTable';
import FileUpload from '../../../../UI/FileUpload/FileUpload';

import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import { ACCSDocTypes, ACCSExceptionalCircumstanceReasons, ACCSExtensionReasons, ACCSRiskReasons, ACCSStateTerritoryBodyTypes } from '../../../../utilities/globalObjects';
import ChildSummary from '../../Roll/ChildSummary/ChildSummary';
import MaterialList from '../../../../UI/MaterialList/MaterialList';
import EndSchedule from './EndSchedule/EndSchedule';
import RollActivityLog from './RollActivityLog/RollActivityLog';
// import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
// import EnrolmentHistory from './EnrolmentHistory/EnrolmentHistory';

const childLevelModes = ['bookings', 'filing', 'ccs', 'enrolments'];
const hideSubmissionTableModes = ['bookings', 'update-details'];

const AccountMgmt = (props) =>  {

    // data state
    const [mode, setMode] = useState('');
    const [submode, setSubmode] = useState('');
    const [viewingTable, setViewingTable] = useState('') 
    const [viewingSubtable, setViewingSubtable] = useState('') 
    const [submissionTableData, setSubmissionTableData] = useState();
    const [filteredSubmissionTableData, setFilteredSubmissionTableData] = useState();
    const [submodeData, setSubmodeData] = useState();
    const [filteredSubmodeData, setFilteredSubmodeData] = useState();
    const [allUserAccountsData, setAllUserAccountsData] = useState();
    const [filteredCentres, setFilteredCentres] = useState();
    const [directDebitData, setDirectDebitData] = useState();
    const [alternativeBillingArrangementData, setAlternativeBillingArrangementData] = useState();
    const [files, setFiles] = useState([]);
    
    // selected account data
    const [accountData, setAccountData] = useState();
    const [childBusListOverridesData, setChildBusListOverridesData] = useState();
    const [childEnrolmentsData, setChildEnrolmentsData] = useState();
    const [childrenData, setChildrenData] = useState();
    const [childGuardianAuthData, setChildGuardianAuthData] = useState();
    const [childGuardianRelationshipData, setChildGuardianRelationshipData] = useState();
    const [childrenMedicalData, setChildrenMedicalData] = useState();
    const [missingAccountData, setMissingAccountData] = useState();
    const [documentsData, setDocumentsData] = useState();
    const [guardianData, setGuardianData] = useState();
    const [emergencyContactsData, setEmergencyContactsData] = useState();
    const [childGroupAuthData, setChildGroupAuthData] = useState();
    const [childrenAddressData, setChildrenAddressData] = useState();
    const [guardianAddressData, setGuardianAddressData] = useState();    

    // ccs enrolment data
    const [selectedFormObj, setSelectedFormObj] = useState();
    const [selectedFormRow, setSelectedFormRow] = useState();
    const [userInputFormData, setUserInputFormData] = useState({})    

    // UI state
    const [loading, setLoading] = useState(false);
    // const [backgroundLoading, setBackgroundLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();
    const [showUpdateSchedule, setShowUpdateSchedule] = useState();
    const [showEndBookingSchedule, setShowEndBookingSchedule] = useState(false);
    const [showInvoiceCreditForm, setShowInvoiceCreditForm] = useState();
    // const [showEditChildDetails, setShowEditChildDetails] = useState();
    const [showEditContactDetails, setShowEditContactDetails] = useState();
    const [showAddContactForm, setShowAddContactForm] = useState();
    const [showAddCasualBooking, setShowAddCasualBooking] = useState();
    const [addCasualBookingCentreId, setAddCasualBookingCentreId] = useState();
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [showAddDocument, setShowAddDocument] = useState();

    // accs state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [selectedEnrolmentId, setSelectedEnrolmentId] = useState();
    const [ACCSLoading, setACCSLoading] = useState();
    const [showACCSModal, setShowACCSModal] = useState();
    const [modeACCS, setModeACCS] = useState('submit-accs-certificate');
    const [dataACCS, setDataACCS] = useState();
    const [filesACCS, setFilesACCS] = useState([]);
    const [childACCSCertificateData, setChildACCSCertificateData] = useState();
    const [childACCSDeterminationData, setChildACCSDeterminationData] = useState();

    // child specific data state
    const [childSummaryData, setChildSummaryData] = useState();
    const [childSpecificEmergencyContactData, setChildSpecificEmergencyContactData] = useState();
    const [childSpecificChildFlagsData, setChildSpecificChildFlagsData] = useState();
    const [viewingChildSummaryId, setViewingChildSummaryId] = useState();
    const [childPhoto, setChildPhoto] = useState();
    const [childRollActivityLogData, setChildRollActivityLogData] = useState();
    const [viewingChildActivityLogId, setViewingChildActivityLogId] = useState();

    // casual booking state
    const [centreSessionData, setCentreSessionData] = useState([]);
    
    // Bootstrap table state
    const [selected, setSelected] = useState([]);      

    // map of which table goes into which state object, used by children components to trigger specific data updates
    const tableToStateMap = {
        'children': setChildrenData,
        'children_medical': setChildrenMedicalData,
        'child_guardian_authorisations': setChildGuardianAuthData,
        'guardians': setGuardianData,
        'addresses:guardian': setGuardianAddressData,
        'addresses:child': setChildrenAddressData,
        'child_group_authorisations': setChildGroupAuthData,
        'child_guardian_relationships': setChildGuardianRelationshipData,
        'emergency_contacts': setEmergencyContactsData,
        'documents': setDocumentsData,
        'view_accounts_missing_fields': setMissingAccountData,

        
    };

    // map of which table goes into which state object, used by children components to trigger specific data updates
    const tableViewMap = {
        'children': 'view_children',
        'children_medical': 'view_children_medical',
        'child_guardian_authorisations': 'view_child_guardian_auths',
        'child_guardian_relationships': 'view_child_guardian_relationships',
        'view_accounts_missing_fields': 'view_accounts_missing_fields',
        'documents': 'view_documents_metadata',
        'guardians': 'view_guardians_account_level',
        'emergency_contacts': 'view_emergency_contacts_account_level_no_guardians',
        'child_group_authorisations': 'view_child_group_authorisations',
        'addresses:guardian': 'view_addresses_account_level',
        'addresses:child': 'view_addresses_account_level',
    };



    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    // console.log('location:', location);

    // get data from props
    const selectedAccount = props.selectedAccount;
    // const selectedAccountObj = props.selectedAccountObj;
    const accountId = selectedAccount?.value;
    const getSubmissionObject = props.getSubmissionObject;
    const setViewingFormSubmission = props.setViewingFormSubmission;
    const centres = props.centres;
    const centreAdminFlag = props.centreAdminFlag;

    // console logs
    // console.log('AccountMgmt.js] - accountData: ', accountData);
    // console.log('AccountMgmt.js] - childBusListOverridesData: ', childBusListOverridesData);
    // console.log('AccountMgmt.js] - childEnrolmentsData: ', childEnrolmentsData);
    // console.log('AccountMgmt.js] - childrenData: ', childrenData);
    // console.log('AccountMgmt.js] - childGuardianAuthData: ', childGuardianAuthData);
    // console.log('AccountMgmt.js] - childGuardianRelationshipData: ', childGuardianRelationshipData);
    // console.log('AccountMgmt.js] - childrenMedicalData: ', childrenMedicalData);
    // console.log('AccountMgmt.js] - missingAccountData: ', missingAccountData);
    // console.log('AccountMgmt.js] - documentsData: ', documentsData);
    // console.log('AccountMgmt.js] - guardianData: ', guardianData);
    // console.log('AccountMgmt.js] - emergencyContactsData: ', emergencyContactsData);
    // console.log('AccountMgmt.js] - childGroupAuthData: ', childGroupAuthData);
    // console.log('AccountMgmt.js] - childrenAddressData: ', childrenAddressData);
    // console.log('AccountMgmt.js] - guardianAddressData: ', guardianAddressData);
    // console.log('[AccountMgmt.js] - childACCSCertificateData: ', childACCSCertificateData);
    // console.log('[AccountMgmt.js] - childACCSDeterminationData: ', childACCSDeterminationData);
    // console.log('[AccountMgmt.js] - selectedFormObj: ', selectedFormObj);
    // console.log('[AccountMgmt.js] - filteredSubmissionTableData: ', filteredSubmissionTableData);
    console.log('[AccountMgmt.js] - selectedChildren: ', selectedChildren);
    console.log('[AccountMgmt.js] - addCasualBookingCentreId: ', addCasualBookingCentreId);
    console.log('[AccountMgmt.js] - centreSessionData: ', centreSessionData);
    // console.log('[AccountMgmt.js] - directDebitData: ', directDebitData);
    // console.log('[AccountMgmt.js] - alternativeBillingArrangementData: ', alternativeBillingArrangementData);
    // console.log('[AccountMgmt.js] - accountData: ', accountData);
    // console.log('[AccountMgmt.js] - accountId: ', accountId);
    // console.log('[AccountMgmt.js] - mode: ', mode);
    // console.log('[AccountMgmt.js] - childrenData: ', childrenData);
    // console.log('[AccountMgmt.js] - childEnrolmentsData: ', childEnrolmentsData);
    // console.log('[AccountMgmt.js] - filteredCentres: ', filteredCentres);
    // console.log('[AccountMgmt.js] - submissionTableData: ', submissionTableData);

    const getMasterData = useCallback(async (table, setStateFn, conditions, noLoading) => {
        
        noLoading ? void(0) : setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        noLoading ? void(0) : setLoading(false);

    }, [history, props, location]);  
    


    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn, updateDataSources) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        setShowInvoiceCreditForm(false);
        setShowAddCasualBooking(false);
        setShowAddDocument(false);
        
        try {
            const res = await axios.post(AWS_API + route, postData, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            handleBannerText(setBannerText, `Request Completed Successfully`);

            // handle specific data source updates requested by child components
            if (updateDataSources?.includes('children')) {
                getMasterData('view_children', setChildrenData, {column: 'account_id', value: accountId});
            }
            
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoading(false);
    }          

    // function to post form to pending form submissions table in RDS and save submission data to S3
    const postSubmission = async (submission, onSuccessFn) => {
        
        setLoading(true);
        
        // send submission to server to record in RDS and then save in S3, aborting transaction if any error encountered
        console.log('posting submission: ', submission);

        // close modals
        setShowUpdateSchedule(false);
        setShowEndBookingSchedule(false);
        
        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {data: submission, childId: submission.childIds[0], centreId: submission.centreId, formType: 'updateBookingSchedule'};
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
            console.log('response from postSubmission to S3: ', res.data);
            onSuccessFn();
            handleBannerText(setBannerText, 'Request Submitted');         
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission in RDS');
        }
        
        setLoading(false);    
    }        
    

    // actions on selected child change
    useEffect(() => {
        
        setSelected([]);
        setSubmodeData();
        setSelectedFormObj();
        setSelectedFormRow();
        setUserInputFormData({});
        
    }, [selectedChildren])
    
    // any time a child-level mode is selected, filter submission table data down to selected child/children
    useEffect(() => {
        
        // child needs to be selected to display data
        if (childLevelModes.includes(mode) && submissionTableData?.length > 0) {   
            
            const childIds = selectedChildren?.map(obj => obj.value);
            

            // check whether all children selected or one/multiple others
            if (childIds.includes('*')) {
                
                // no need to filter
                setFilteredSubmissionTableData(submissionTableData);
                setFilteredSubmodeData(submodeData);
            } else {
                
                // filter for selected children
                setFilteredSubmissionTableData(submissionTableData?.filter(obj => childIds?.includes(obj.child_id)));
                setFilteredSubmodeData(submodeData?.filter(obj => {return obj.child_id ? childIds?.includes(obj.child_id) : true}));
            }
            
        } else {
            // no filter needed
            setFilteredSubmissionTableData(submissionTableData);
            setFilteredSubmodeData(submodeData);
        }
            
    }, [selectedChildren, mode, submissionTableData, submodeData])
    
    // any time centres or child enrolments data changes, add filtered centres relevant to selected account to state
    useEffect(() => {
        
        // filter centres to only those children in this account are enrolled with and put into state
        if (centres?.length > 0 && childEnrolmentsData?.length > 0) {
            
            const centreIds = childEnrolmentsData?.map(obj => obj.centre_id).filter((x, i, a) => a.indexOf(x) === i);
            const newFilteredCentres = centres?.filter(obj => centreIds.includes(obj.centre_id));
            setFilteredCentres(newFilteredCentres)
        }
        
        
    }, [centres, childEnrolmentsData])



    // copy of function from Centrelink.js
    const getPresignedUrl = async (selected, urlType) => {
        
        setLoading(true);

        // exit if more than one document selected
        if (selected.length > 1) {
            alert('Please select only one row');
            setLoading(false);
            return false;
        }        

        // get selected row
        const uniqueSelectId = bootstrapTableConfig[viewingTable]?.uniqueSelectId;
        const id = selected[0];
        console.log('id: ', id);
        const selectedRow = submissionTableData.filter(obj => obj[uniqueSelectId] === id)?.[0];

        // url type config
        let fileKey;
        if (urlType === 'enrolment-document') {
            fileKey = selectedRow?.file_key;
            console.log('fileKey: ', fileKey);
        } else if (urlType === 'statement-of-entitlement') {
            const periodEnd = selectedRow?.period_end?.slice(0,10);
            const accountId = selectedRow?.account_id;
            const fileName = periodEnd + '-SoE-account-' + accountId + '-invoice-' +  id + '.pdf';
            fileKey = 'statements-of-entitlement/' + accountId + '/' + fileName;            
        }
        
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {urlType: urlType, fileKey: fileKey}
        };

        let url;
        try {
            const res = await axios.get(AWS_API + 'form-submissions/presigned-url', config);
            console.log('data fetch res: ', res.data);
            url = res.data.url;

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }        
        
        window.open(url,'_newtab');

        setLoading(false);        
    }


    // generic function to get data and save to state
    const genericFetchFunction = useCallback(async (route, params, stateFn, load, scrollPageTo) => {
        
        load ? setLoading(true) : void(0);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: params
        };
        console.log('params: ', params);
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + route, config);
            console.log('data fetch res: ', res.data);
            stateFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            


        load ? setLoading(false) : void(0);

        // scroll if requested
        if (scrollPageTo === 'bottom') {
            setTimeout(() => {scrollPageToBottom()}, 100);
        }


    }, [history, props.token, location]);     

    const writeBlockBooking = (bookingData, onSuccessFn) => {
        console.log('writing block booking for data: ', bookingData);
        postMasterData('master-data/add-roll-sessions-block', {postData: {...bookingData}}, onSuccessFn)
    }     

    const handleModeChange = useCallback((newMode) => {
        
        if (newMode === 'filing') {
            const table = 'view_filing_cabinet';
            getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
            setViewingTable(table);
            
        // } else if (newMode === 'ccs') {
        //     const table = 'view_latest_entitlements';
        //     getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
        //     setViewingTable(table);

        // } else if (newMode === 'account') {
        //     const table = 'accounts';
        //     getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
        //     setViewingTable(table);
            
        } else if (newMode === 'bookings') {
            const table = 'view_next_active_perm_booking';
            getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
            setViewingTable(table);
            
        } else if (newMode === 'children') {
            // const table = 'view_children';
            // getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
            // setViewingTable(table);
            setViewingTable();
            setSubmissionTableData();
        } else if (newMode === 'contacts') {
            let table = 'view_emergency_contacts_account_level';
            if (centreAdminFlag) table = 'view_emergency_contacts';
            getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
            setViewingTable(table);
            
        } else if (newMode === 'ccs') {
            const table = 'view_child_enrolments';
            getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});
            setViewingTable(table);
            // setSubmissionTableData();
            // setViewingTable();
        } else if (newMode === 'invoices') {
            const table = 'view_invoices';
            getMasterData(table, setSubmissionTableData, {column: 'account_id', value: accountId});

            setViewingTable(table);
        } else if (newMode === 'preferences') {
            const table = 'all_user_accounts';
            getMasterData(table, setAllUserAccountsData);
            setViewingTable();
        }        

        // if mode isn't a child-level mode, clear selected child
        // if (!childLevelModes.includes(newMode)) {
        //     setSelectedChildren([]);
        // }

        // if we're not in the children section, make sure flag to show add contact form within ParentData.js set back to false
        // if (newMode !== 'children') {
        if (newMode !== 'update-details') {
            setShowAddContactForm(false);
        }

        // setShowEditChildDetails(false);
        setMode(newMode);
        setSubmode();
        setSelected([]);

    }, [accountId, getMasterData, centreAdminFlag]);



    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // ACCS functions


    const getACCSData = useCallback(async (serviceCRN, childCRN, childDateOfBirth) => {
        
        setACCSLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: 'read-accs-certificate', serviceCRN: serviceCRN, childCRN: childCRN, childDateOfBirth: childDateOfBirth}
        };
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setChildACCSCertificateData(res.data);
        } catch (err) {
            setShowACCSModal(false);
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        // call lambda function to get determination data
        config.params.routeToInvoke = 'read-accs-determination';
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setChildACCSDeterminationData(res.data);
        } catch (err) {
            setShowACCSModal(false);
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching determination data');
        }            

        setACCSLoading(false);


    }, [history, props, location]);      

    // wipe state clean when closing modal to prevent lingering files etc from another child
    const resetACCSData = () => {
        setDataACCS();
        setFilesACCS([]);
        
        // setSelectedEnrolmentId();
    }    

    const postCCSGenericWrite = async (data, routeToInvoke) => {

        console.log('posting data: ', data);
        
        setLoading(true);
        
        // close modals
        setShowACCSModal(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            data: data,
            routeToInvoke: routeToInvoke,
        };
        
        try {
            const res = await axios.post(AWS_API + 'ccs/generic-write', {postData: postData}, headers);
            console.log('res: ', res?.data);
            setDataACCS();
            handleBannerText(setBannerText, 'ACCS Submission Successful');
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting CCS data');
            console.log('error whilst posting to generic write CCS: ', err);
        }    

        // clear data
        resetACCSData();   


        setLoading(false);
    }         
    
    const handlePostACCSFormData = (route) => {
        const data = {
            ...dataACCS
        }

        if (filesACCS?.length > 0) {
            data.SupportingDocuments = [...filesACCS];
        }

        postCCSGenericWrite(data, route);
    }    


    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



    const handleSubmodeChange = useCallback((newSubmode) => {
        
        if (mode === 'ccs') {

            // clear form data that might be being displayed from previous selection
            setSelectedFormRow();
            setSelectedFormObj();

            // first get key information for either selected child or first enrolment if none selected
            let childId, serviceCRN, ccsEnrolmentId;
            if (selected?.length > 0) {
                const uniqueSelectId = bootstrapTableConfig[viewingTable]?.uniqueSelectId;
                const id = selected[0];
                const firstSelectedEnrolment = filteredSubmissionTableData.filter(obj => obj[uniqueSelectId] === id)?.[0];
                serviceCRN = firstSelectedEnrolment?.service_crn;
                ccsEnrolmentId = firstSelectedEnrolment?.ccs_enrolment_id;
                childId = firstSelectedEnrolment?.child_id;
            } else {
                serviceCRN = filteredSubmissionTableData?.[0]?.service_crn;
                ccsEnrolmentId = filteredSubmissionTableData?.[0]?.ccs_enrolment_id;
                childId = filteredSubmissionTableData?.[0]?.child_id;
            }

            const childRecord = childrenData.filter(obj => obj.child_id === childId)?.[0];
            const childDOB = childRecord?.dob;
            const childCRN = childRecord?.crn;

            

            if (newSubmode === 'entitlement_history') {
                const table = 'view_latest_entitlements';
                getMasterData(table, setSubmodeData, {column: 'account_id', value: accountId});
                setViewingSubtable(table);
            } else if (newSubmode === 'enrolment_history') {
                const table = 'read-enrolments';
                setViewingSubtable(table);

                // get service CRN and enrolment ID for enrolment
                
                // handle both selected child, or just first enrolment if none selected

                
                if (serviceCRN && ccsEnrolmentId) {

                    genericFetchFunction('ccs/read-enrolments', {serviceCRN: serviceCRN, ccsEnrolmentId: ccsEnrolmentId}, setSubmodeData, true, 'bottom');
                } else {
                    alert(`Either Service CRN (${serviceCRN}) or CCS Enrolment ID (${ccsEnrolmentId}) missing`);
                }


            } else if (newSubmode === 'live_entitlements') {
                const table = 'read-entitlements';
                setViewingSubtable(table);

                // get service CRN and enrolment ID for enrolment
                
                // handle both selected child, or just first enrolment if none selected

                
                if (serviceCRN && ccsEnrolmentId) {

                    genericFetchFunction('ccs/generic-read', {routeToInvoke: 'entitlements', dateOfEntitlement: new Date().toISOString()?.slice(0,10), serviceCRN: serviceCRN, ccsEnrolmentId: ccsEnrolmentId}, setSubmodeData, true, 'bottom');
                } else {
                    alert(`Either Service CRN (${serviceCRN}) or CCS Enrolment ID (${ccsEnrolmentId}) missing`);
                }


            } else if (newSubmode === 'accs') {

                console.log('serviceCRN: ', serviceCRN);
                console.log('childCRN: ', childCRN);
                console.log('childDOB: ', childDOB);

                setSelectedServiceCRN(serviceCRN);
                setSelectedEnrolmentId(ccsEnrolmentId);
                getACCSData(serviceCRN, childCRN, childDOB);
                setShowACCSModal(true);

            }
            
        } 
        // setShowEditChildDetails(false);
        setSubmode(newSubmode);
    }, [mode, accountId, getMasterData, genericFetchFunction, selected, filteredSubmissionTableData, viewingTable, childrenData, getACCSData]);

    // // any time selected account ID changes, fetch account data for that account and reset mode
    // useEffect(() => {
    //     getMasterData('view_accounts', setAccountData, {column: 'account_id', value: accountId});
    //     setShowEditChildDetails(false);
    //     setSubmode();
    //     setSubmodeData();
        
    //     // if user has just navigated to cabinet and not selected a mode yet, default to billing
    //     if (!mode) handleModeChange('invoices');

    // }, [mode, accountId, handleModeChange, getMasterData])  
    

    // actions on account change
    useEffect(() => {
        
        // only run if account has actually changed or if no account was previously selected
        if (childrenData?.[0]?.account_id !== accountId) {

            // clear table data
            console.log('clearing submission table data');
            setSubmissionTableData();
            setViewingFormSubmission();
            setSelected([]);
            setSelectedChildren([]);
            setSelectedFormObj();
            setSelectedFormRow();
            setUserInputFormData({});
            
            // get data related to selected account
            getMasterData('view_accounts', setAccountData, {column: 'account_id', value: accountId});

            getMasterData('view_child_enrolments', setChildEnrolmentsData, {column: 'account_id', value: accountId});
            getMasterData('view_children', setChildrenData, {column: 'account_id', value: accountId});
            getMasterData('view_child_guardian_auths', setChildGuardianAuthData, {column: 'account_id', value: accountId});
            getMasterData('view_child_guardian_relationships', setChildGuardianRelationshipData, {column: 'account_id', value: accountId});
            getMasterData('view_children_medical', setChildrenMedicalData, {column: 'account_id', value: accountId});
            getMasterData('view_accounts_missing_fields', setMissingAccountData, {column: 'account_id', value: accountId});


            // temp fix for centre admins - don't fetch data which doesnt' have centre_id column yet
            if (!centreAdminFlag) {

                getMasterData('view_documents_metadata', setDocumentsData, {column: 'account_id', value: accountId});
                getMasterData('view_guardians_account_level', setGuardianData, {column: 'account_id', value: accountId});
                getMasterData('view_emergency_contacts_account_level_no_guardians', setEmergencyContactsData, {column: 'account_id', value: accountId});
                getMasterData('view_child_group_authorisations', setChildGroupAuthData, {column: 'account_id', value: accountId});
                getMasterData('view_addresses_account_level', setChildrenAddressData, {column: 'account_id', value: accountId});
                getMasterData('view_addresses_account_level', setGuardianAddressData, {column: 'account_id', value: accountId});
            }


            // setShowEditChildDetails(false);
            setSubmode();
            setSubmodeData();
            
            // if user has just navigated to cabinet and not selected a mode yet, default to billing
            if (centreAdminFlag) {
                handleModeChange('children')
            } else {
                handleModeChange('invoices');            
            }
        }
        
        
    }, [accountId, getMasterData, childrenData, setViewingFormSubmission, handleModeChange, centreAdminFlag])    
    
    // get optional data for specific routes
    useEffect(() => {

        // for pending invoices, get relevant xero secrets and direct debit statuses
        if (mode === 'invoices') {

            // fetch direct debit mandates for each of these emails
            if (submissionTableData?.length > 0) {
                const emails = submissionTableData.map(obj => obj.email).filter(email => email).filter((x, i, a) => a.indexOf(x) === i);
                // console.log('getting direct debit status for pending invoice emails: ', emails);
                if (emails?.length > 0) {
                    genericFetchFunction('master-data/direct-debit-status', {emails: JSON.stringify(emails)}, setDirectDebitData);
                    getMasterData('view_alternative_billing_arrangements', setAlternativeBillingArrangementData, {}, true);                    

                }

            }


        } 
    }, [mode, genericFetchFunction, submissionTableData, getMasterData])        

    const refreshAccountData = () => {
        // update account data
        getMasterData('view_accounts', setAccountData, {column: 'account_id', value: accountId});

        // update currently viewed section
        if (viewingTable) {
            getMasterData(viewingTable, setSubmissionTableData, {column: 'account_id', value: accountId});
        }
    }

    const handleUpdateDetails = (newEmail) => {
        // currently only handling email address, post new and old email to update email function
        const oldEmail = accountData?.[0]?.primary_email;
        console.log(`posting request to update email from ${oldEmail} to ${newEmail}`)
        postMasterData('master-data/update-account-email', {postData: {oldEmail: oldEmail, newEmail: newEmail}}, refreshAccountData)

    }

    const updateAccountBalance = () => {
        const accountId = accountData?.[0]?.account_id;
        console.log('[updateAccountBalance] - accountId: ', accountId);
        postMasterData('ccs/refresh-account-balances', {postData: {accountIds: [accountId]}}, refreshAccountData);
        
    }


    const handlePostInvoiceCredit = (postData) => {
        postMasterData('ccs/create-new-invoice-credit', {postData: postData}, refreshAccountData);
    }

    const refreshData = () => {
        handleModeChange(mode);
    }

    const fetchSpecificTableData = (table) => {

        // get state function which holds this data 
        const setStateFn = tableToStateMap[table];
        const viewOrTable = tableViewMap[table];
        getMasterData(viewOrTable, setStateFn, {column: 'account_id', value: accountId}, true);
    }    

    const onAccountDataUpdated = () => {
        // handleBannerText(setBannerText, `Request Completed Successfully`);
        setShowAddContactForm(false);
        // refreshData();
    }
    
    const handleUpdateContactData = async (table, updatedData) => {
        
        console.log(`running update on ${table} with data ${updatedData}`);

        
        // close modal
        setShowEditContactDetails(false);        
        
        // remove fields in view but not in raw data table 
        const updatedRow = {...updatedData};
        if (table === 'guardians') {
            delete updatedRow.contact_number;
            delete updatedRow.contact_type;
            delete updatedRow.email;
            delete updatedRow.crn;
            delete updatedRow.created_at;
            delete updatedRow.person_id;
            delete updatedRow.contact_id;
            delete updatedRow.account_id;
            delete updatedRow.relationship;
            delete updatedRow.account_owner;
            delete updatedRow.address_unit;
            delete updatedRow.address_building 
            delete updatedRow.address_house_number 
            delete updatedRow.address_road 
            delete updatedRow.address_postcode 
            delete updatedRow.address_suburb 
            delete updatedRow.address_state 
            delete updatedRow.address_country
            
        } else if (table === 'emergency_contacts') {
            delete updatedRow.contact_type;
            delete updatedRow.created_at;
            delete updatedRow.person_id;
            delete updatedRow.account_id;
            delete updatedRow.relationship;
            delete updatedRow.account_owner;
            delete updatedRow.email;
            delete updatedRow.nationality;
            delete updatedRow.language_spoken_at_home;
            delete updatedRow.crn;
            delete updatedRow.dob;
            delete updatedRow.guardian_id;
            delete updatedRow.preferred_contact;
            delete updatedRow.additional_contact;
            
        }
        
        console.log('posting data: ', updatedRow);
        
        // get primary keys
        const primaryKeys = bootstrapTableConfig[table]?.primaryKeys;
        if (!primaryKeys) {
            setError('Primary keys not found for selected table: ' + table);
            return false
        }        
        
        const postData = {
            table: table,
            updatedRow: updatedRow,
            primaryKeys: primaryKeys
        };
        console.log('posting: ', postData);


        // post data
        await postMasterData('master-data/update-entire-row', {postData: postData}, refreshAccountData)
    }

    const handleShowAddCasualBooking = () => {
        
        // determine centre ID of selected children and ensure all selected children share a home centre, otherwise throw error 
        const selectedChildIds = selectedChildren?.map(obj => obj.value);
        
        // get centres IN COMMON between all selected children
        let enrolledCentres = [];
        selectedChildIds.forEach((childId, index) => {

            const childEnrolments = childEnrolmentsData.filter(enrolment => enrolment.child_id === childId);
            
            if (index === 0) {
                enrolledCentres = childEnrolments.map(enrolment => enrolment.centre_id);
            } else {
                enrolledCentres = enrolledCentres.filter(centreId =>
                childEnrolments.some(enrolment => enrolment.centre_id === centreId)
                );
            }
        });            

        console.log('enrolledCentres: ', enrolledCentres);

        // if selected children array has > 0 selected children, look for a shared home centre - otherwise remove home centre
        if (selectedChildIds.length > 0 && enrolledCentres?.length > 0) {

            // get unique home centres
            const selectedHomeCentreIds = [...new Set(childrenData.filter(obj => selectedChildIds.includes(obj.child_id)).map(obj => obj.home_centre_id))];
            const allowedHomeCentreIds = selectedHomeCentreIds.filter(centreId => enrolledCentres?.includes(centreId));
            
            // if centre not yet selected, default to home centre
            const homeCentreId = allowedHomeCentreIds?.[0];
            if (homeCentreId) {

                getMasterData('view_centre_sessions_calendar', setCentreSessionData,  {column: 'centre_id', value: homeCentreId});   
                setAddCasualBookingCentreId(homeCentreId);
            }

        } else {
            if (addCasualBookingCentreId) setAddCasualBookingCentreId();
        }     

        // fetch session calendar data to show which days are bookable
        setShowAddCasualBooking(true);

        // if (homeCentreIds?.length > 1) {
        //     alert('Selected children have different home centres, please make their bookings separately');
        // } else if (homeCentreIds?.length === 0) {
        //     alert('Please select a child to make a booking')
        // } else if (homeCentreIds?.length === 1) {
        //     const centreId = homeCentreIds[0];
        //     setAddCasualBookingCentreId(centreId);
        //     setShowAddCasualBooking(true);
        // }

    }

    // function to intercept form submission data and save to S3 when submit button pressed and CustomSubmit event fired - used for admin to make overwrites to user submitted form
    const updateFormSubmission = async (submission, middleware) => {
        setLoading(true);
        console.log('updateFormSubmission input param: ', submission);

        // get old submission object to take ID for form submission updating
        // const oldSubmissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};

        const centreId = selectedFormRow.centre_id;

        // create new object with correct ID and new submission data
        const newSubmissionObject = {
            ...selectedFormObj,
            data: submission,
            centreId: centreId,
        };

        console.log('new submission object: ', newSubmissionObject);
        
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        try {
            const res = await axios.post(AWS_API + 'form-submissions/update', {postData: newSubmissionObject}, headers);
            console.log('response from postSubmission to S3 via form-submissions/update: ', res.data);
            
            if (!middleware) {

                // update data and show banner
                getMasterData(viewingTable, setSubmissionTableData, {column: 'form_type', value: 'bulkEnrol'});
                handleBannerText(setBannerText, 'Form Submission Updated');
            }

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error while updating form submission');
        }
        
        setLoading(false);
    }       

    // function to get submission json data from S3 for selected form
    const getFormSubmission = async (formObj) => {

        console.log('[getFormSubmission] - formObj: ', formObj);
        const formId = formObj.form_id;
        const formType = formObj.form_type.replace('pending-', '').replace('approved-', ''); // remove pending- prefix that may be added in view_pending_forms_obj to forms approved for pending enrolment but still needing centrelink submission
        const submittedBy = formObj.submitted_by;
        const centreId = formObj.centre_id;
        
        console.log('get form submission for id, type, submittedBy, centreId: ', formId, formType, submittedBy, centreId);
        
        setLoading(true);

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        const postData = {ids: [formId], centreId: centreId, formType: formType, submittedBy: submittedBy};

        // get submission json data for selected form ID
        try {
            const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
            console.log('response from form-submissions/fetch: ', res.data);
            setSelectedFormObj(res.data.formSubmissions[0]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
        }
        setLoading(false);

    }    

    const handleViewEnrolmentForm = () => {

        // clear any previously selected submode data
        setSubmode();
        setSubmodeData();

        // try and used selected child if they only have one enrolment, otherwise look for selected table row
        if (selected?.length === 1) {

            const rowId = selected[0];
            const uniqueSelectId = bootstrapTableConfig[viewingTable]?.uniqueSelectId;
            const selectedRow = submissionTableData?.filter(obj => obj[uniqueSelectId] === rowId)?.[0];  
            const formId = selectedRow?.form_id;
            console.log('selectedRow: ', selectedRow);
            
            // only attempt to pull s3 json data if a form ID has been found attached to enrolment, otherwise it will fail anyway
            if (!formId) {
                alert('No enrolment form found for selected enrolment');
            } else {
                getFormSubmission(selectedRow);
                setSelectedFormRow(selectedRow);

            }
                
            
        } else if (selectedChildren?.length === 1) {
            const childId = selectedChildren[0]?.value;
            const childEnrolments = submissionTableData.filter(obj => obj.child_id === childId);
            
            if (childEnrolments?.length === 1) {
                const childEnrolment = childEnrolments?.[0];
                const formId = childEnrolment?.form_id;
                // only attempt to pull s3 json data if a form ID has been found attached to enrolment, otherwise it will fail anyway
                if (!formId) {
                    alert('No enrolment form found for selected enrolment');
                } else {
                    getFormSubmission(childEnrolment);
                    setSelectedFormRow(childEnrolment);
                }
                
            } else if (selected?.length === 0 || selected?.length > 1) {
                alert('Selected child has more than one enrolment, please select just one from the enrolments table');
            }
            
        } else if (selected?.length === 0 || selected?.length > 1) {
            alert('Please select just one child and/or one enrolment from the enrolments table');
            

        }

    }

    const handleAddDocument = () => {

        // ensure only one child is selected, as this will be the child uploaded document is assiged to
        if (selectedChildren?.length === 1 && selectedChildren?.[0]?.value !== '*') {
            setShowAddDocument(true);
        } else {
            alert('Please select just one child for whom to upload documents');
        }
    }

    const handlePostDocument = () => {
        if (accountId === accountData?.[0]?.account_id) {
            const postData = {
                postData: {
                    accountId: accountId,
                    submittedForPersonId: accountData?.[0]?.account_owner,
                    submittedForEmail: accountData?.[0]?.primary_email,
                    childId: selectedChildren?.[0]?.value, 
                    centreId: childEnrolmentsData.filter(obj => obj.child_id === selectedChildren?.[0]?.value)?.[0]?.centre_id,
                    documents: [...files]
                }
            };
            postMasterData('form-submissions/upload-documents', postData, () => {setFiles([]); handleModeChange('filing');})
        } else {
            setError('Account ID does not match selected account data, please contact technical support')
        }
    }

    // data transforms
    let transformedData = [];
    if (filteredSubmissionTableData?.length > 0) {
        
        // create deep clone of object 
        transformedData =  cloneDeep(filteredSubmissionTableData);

        // add in optional extra display columns if needed
        if (mode === 'invoices' && directDebitData && alternativeBillingArrangementData) {
            transformedData.forEach(obj => obj.direct_debit = <DirectDebitStatus email={obj.email} directDebitData={directDebitData} alternativeBillingArrangementData={alternativeBillingArrangementData}/>)
        }
    }

    // subdata transforms
    let transformedSubmodeData = [];
    if (filteredSubmodeData?.length > 0) {

        // create deep clone of object 
        transformedSubmodeData =  cloneDeep(filteredSubmodeData);    
        console.log('transformedSubmodeData: ', transformedSubmodeData);    

        // ccs/enrolments transforms
        if (mode === 'ccs') {
            
            // enrolment history transforms
            if (submode === 'enrolment_history') {

                transformedSubmodeData.forEach(record => {

                    const ccsEnrolmentId = record?.enrolmentID;
                    const noticeType = record?.noticeType;
                    const arrangementType = record?.arrangementType;
                    const serviceID = record?.serviceID;

                    // const record = {...filteredSubmodeData[0]}
                    const sortedStatuses = record?.Statuses?.results?.sort((a,b) => new Date(b?.timeStamp).getTime() - new Date(a?.timeStamp).getTime());
                    console.log('sortedStatuses: ', sortedStatuses);
                    sortedStatuses?.forEach((status, i) =>  {

                        console.log('status, i: ', status, i);

                        if (i === 0) {
                            record.enrolmentStatus = status?.status;
                            record.statusTimestamp = status?.timeStamp;

                        } else {
                            const newRecord = {enrolmentID: ccsEnrolmentId, noticeType: noticeType, arrangementType: arrangementType, serviceID: serviceID, enrolmentStatus: status?.status, statusTimestamp: status?.timeStamp};
                            console.log('newRecord: ', newRecord);
                            transformedSubmodeData.push(newRecord);
                        }

                    });

                    // const latestStatus = sortedStatuses?.[0]?.status;
                    // transformedSubmodeData.push(record);                
                })
            }
        }
    }

    const handleSaveAndPostEnrolment = async () => {

        // first save submission to S3  
        await updateFormSubmission(selectedFormObj.data, true);

        // now post to write master data function
        console.log('submitting to centrelink');
        postMasterData('initial-master-data/write', {data: {...selectedFormObj, userInputFormData: userInputFormData}}, () => setUserInputFormData({}))
    }    

    const getChildPhoto = async (childId) => {

        // get centreIDs for child
        const centreIds = childEnrolmentsData.filter(obj => obj.child_id === childId).map(obj => obj.centre_id).filter((x,i,a) => a.indexOf(x) === i);
        console.log('[getChildPhoto] - centreIds: ', centreIds);


        // loop through centreIds and try fetch photo for each centre, stopping on first success
        let photo;
        for (const centreId of centreIds) {

            // upcoming sessions data
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            const postData = {childId: [childId], centreId: centreId};
    
            try {
                const res = await axios.post(AWS_API + 'form-submissions/fetch-photos', {postData: postData}, headers);
                photo = res.data?.[childId];
                if (photo) {
                    console.log(`[getChildPhoto] - photo found at centre ${centreId}, breaking`);
                    break;
                }
    
            } catch (err) {
                // setLoading(false);
                // setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                console.log('error whilst fetching photos: ', err);
                break;
            }   

        }

        // if new photo found put in state, otherwise clear in case of old photo from another child
        if (photo) {
            setChildPhoto(photo);
        } else {
            setChildPhoto();
        }

  
    }   
    
    // function to get child activity log data
    const getChildActivityLogData = (childId) => {

        getMasterData('view_roll_activity_log', setChildRollActivityLogData,  {column: 'child_id', value: childId});        

    }

    // function to get child summary info 
    const getChildSummaryData = (childId) => {

        getMasterData('view_child_summary', setChildSummaryData,  {column: 'child_id', value: childId});        
        getMasterData('view_emergency_contacts_roll_summary', setChildSpecificEmergencyContactData,  {column: 'child_id', value: childId});   
        getMasterData('view_active_child_flags', setChildSpecificChildFlagsData,  {column: 'child_id', value: childId});   
        getMasterData('view_child_bus_list_overrides', setChildBusListOverridesData,  {column: 'child_id', value: childId});   
        getChildPhoto(childId);

    }

    const clearChildSummaryData = () => {
        setChildSummaryData();
        setChildSpecificChildFlagsData();
        setChildSpecificEmergencyContactData();
        setChildPhoto();
        setChildRollActivityLogData();
    }    

    // show child roll activity log
    const handleShowRollActivityLog = () => {

        // check one selected child, if so fetch roll activity log for child
        if (selectedChildren?.length === 1 && selectedChildren?.[0]?.value !== '*') {
            const childId = selectedChildren?.[0]?.value;
            setViewingChildActivityLogId(childId);
            getChildActivityLogData(childId);
        } else {
            alert('Please select only one child to view roll activity log');
        }
    }
    
    // close child summary
    const handleCloseRollActivityLog = (childId) => {
        setViewingChildActivityLogId();
        clearChildSummaryData();
    }

    // show child summary
    const handleShowChildSummary = (childId) => {
        setViewingChildSummaryId(childId);
        getChildSummaryData(childId);
    }
    
    // close child summary
    const handleCloseChildSummary = () => {
        setViewingChildSummaryId();
        clearChildSummaryData();
    }    

    const handleSubmitRollNote = (childId, newNote, noteType, rollId, recurringRollNoteId) => {
        
        handleCloseChildSummary();

        // rollId only passed in when submitting session note from roll, cabinet won't handle session notes
        postMasterData('master-data/manage-roll-notes', {postData: {childId: childId, newNote: newNote, noteType: noteType, recurringRollNoteId: recurringRollNoteId}});
    }        



    // run when opening ACCS modal for a child
    // const handleOpenACCSModal = async () => {
    //     setShowACCSModal(true); 
    //     setSelectedEnrolmentId(ccsEnrolmentId);

    //     // get child crn and date of birth to fetch cert data with
    //     const childData = enrolmentsData.filter(obj => obj.enrolmentID === ccsEnrolmentId)[0];
    //     const childCRN = childData?.childCRN;
    //     const childDateOfBirth = childData?.childDateOfBirth;
    //     getACCSData(selectedServiceCRN, childCRN, childDateOfBirth);
    // }



    //-------------------
    // render content in ACCS modal depending on mode selected within modal


    
    let accsModalContent = <SpinnerDark />; 
    if (!ACCSLoading && showACCSModal) {
        
        // get certificate ID if child already has an ACCS
        let childACCSCertID, childACCSCertStartDate, certStatus, certRecordStatus, certLastUpdated, certSubmitted, certWeeksAtRisk, certIndicativeRiskToDate;
        if (childACCSCertificateData) {

            // get latest certificate/submission
            const latestCert = childACCSCertificateData.sort((a,b) => new Date(b.submissionDateTime).getTime() - new Date(a.submissionDateTime))?.[0];
            console.log('latestCert: ', latestCert);
            
            childACCSCertID = latestCert?.certificateID ?? 'None found';
            childACCSCertStartDate = latestCert?.certificateStartDate;
            certStatus = latestCert?.status;
            certRecordStatus = latestCert?.recordStatus ;
            certSubmitted = latestCert?.submissionDateTime ;
            certLastUpdated = latestCert?.lastUpdatedDateTime ;
            certWeeksAtRisk = latestCert?.weeksAtRisk;
            certIndicativeRiskToDate = latestCert?.indicativeRiskToDate;
        }
        
        // get determination ID if child already has a determination
        let childACCSDetermID, childACCSDetermStartDate, detStatus, detRecordStatus, detLastUpdated, detSubmitted, detWeeksAtRisk, detIndicativeRiskToDate;
        if (childACCSDeterminationData) {
            
            // get latest determination/submission
            const latestDet = childACCSDeterminationData.sort((a,b) => new Date(b.dateDeterminationSubmitted).getTime() - new Date(a.dateDeterminationSubmitted))?.[0];
            console.log('latestCert: ', latestDet);

            childACCSDetermID = latestDet?.determinationID ?? 'None found';
            childACCSDetermStartDate = latestDet?.determinationStartDate;
            detStatus = latestDet?.status;
            detRecordStatus = latestDet?.recordStatus ;
            detSubmitted = latestDet?.dateDeterminationSubmitted ;
            detLastUpdated = latestDet?.lastUpdatedDateTime ;
            detWeeksAtRisk = latestDet?.weeksAtRisk;
            detIndicativeRiskToDate = latestDet?.indicativeRiskToDate;
        }

        const accsSummary = (
            <Fragment>
                <div className={classes.ACCSSummary}> 
                    <div>
                        <div className={classes.Row}> <span className={classes.Item}>Certificate ID</span> <div>{childACCSCertID}</div> </div>
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Start Date</span> <div>{childACCSCertStartDate}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Submitted</span> <div>{certSubmitted}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Status</span> <div>{certStatus}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Record Status</span> <div>{certRecordStatus}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Last Updated</span> <div>{certLastUpdated}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Weeks At Risk</span> <div>{certWeeksAtRisk}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Indicative Risk To Date</span> <div>{certIndicativeRiskToDate}</div> </div>}
                    </div>
                    <div>
                        <div className={classes.Row}> <span className={classes.Item}>Determination ID</span> <div>{childACCSDetermID}</div> </div>
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Start Date</span> <div>{childACCSDetermStartDate}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Submitted</span> <div>{detSubmitted}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Status</span> <div>{detStatus}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Record Status</span> <div>{detRecordStatus}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Last Updated</span> <div>{detLastUpdated}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Weeks At Risk</span> <div>{detWeeksAtRisk}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>indicativeRiskToDate</span> <div>{detIndicativeRiskToDate}</div> </div>}
                    </div>
                    </div>                
            </Fragment>
        );

        if (modeACCS === 'submit-accs-certificate') {
            accsModalContent = (
                <Fragment>
                    <h3>Submit ACCS Certificate</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'childFirstName', type: 'text', value: childEnrolmentsData.filter(obj => obj.ccs_enrolment_id === selectedEnrolmentId)[0]?.child_name}, 
                            {name: 'enrolmentID', type: 'text', value: selectedEnrolmentId}, 
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'certificateStartDate', type: 'date'}, 
                            {name: 'weeksAtRisk', type: 'number'},
                            {name: 'riskReason',  type: 'react-select', options: ACCSRiskReasons, valueProperty: 'value'},
                            {name: 'exceptionalCircumstanceReason', type: 'react-select',  options: ACCSExceptionalCircumstanceReasons, valueProperty: 'value'},
                            {name: 'exceptionalCircumstanceText', type: 'text'},
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                            {name: 'isEvidenceHeld', type: 'checkbox'},
                        ]}
                        />
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>

                </Fragment>      
            );      
        } else if (modeACCS === 'update-state-territory-accs') {
            
            accsModalContent = (
                <Fragment>
                    <h3>Update State/Territory Body</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'certificateID', type: 'text', value: childACCSCertificateData[0]?.certificateID ?? 'No certificate found'}, 
                            {name: 'certificateStartDate', type: 'text', value: childACCSCertificateData[0]?.certificateStartDate ?? 'No certificate found'}, 
                            {name: 'childFirstName', type: 'text', value: childEnrolmentsData.filter(obj => obj.ccs_enrolment_id === selectedEnrolmentId)[0]?.child_name}, 
                            {name: 'enrolmentID', type: 'text', value: selectedEnrolmentId}, 
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'dateNoticeGiven', type: 'date'}, 
                            {name: 'bodyType', type: 'react-select',  options: ACCSStateTerritoryBodyTypes, valueProperty: 'value'},
                            {name: 'organisationName', type: 'text'},
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                            {name: 'isNotifiedByStateTerritory', type: 'checkbox'}, 
                            {name: 'isNoticeToStateTerritory', type: 'checkbox'},
                        ]}
                        />
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>

                </Fragment>      
            );   
            
        } else if (modeACCS === 'apply-for-accs-determination') {
            
            accsModalContent = (
                <Fragment>
                    <h3>Apply for Determination</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'certificateID', type: 'text', value: childACCSCertificateData[0]?.certificateID ?? 'No certificate found'}, 
                            {name: 'certificateStartDate', type: 'text', value: childACCSCertificateData[0]?.certificateStartDate ?? 'No certificate found'}, 
                            {name: 'childFirstName', type: 'text', value: childEnrolmentsData.filter(obj => obj.ccs_enrolment_id === selectedEnrolmentId)[0]?.child_name}, 
                            {name: 'enrolmentID', type: 'text', value: selectedEnrolmentId}, 
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'determinationStartDate', type: 'date'}, 
                            {name: 'indicativeRiskToDate', type: 'date'}, 
                            {name: 'weeksAtRisk', type: 'number'},
                            {name: 'additionalInfo', type: 'text'},
                            {name: 'exceptionalCircumstanceReason', type: 'react-select',  options: ACCSExceptionalCircumstanceReasons, valueProperty: 'value'},
                            {name: 'exceptionalCircumstanceText', type: 'text'},
                            {name: 'extensionReason', type: 'react-select',  options: ACCSExtensionReasons, valueProperty: 'value'},
                            {name: 'dateNoticeGiven', type: 'date'}, 
                            {name: 'bodyType', type: 'react-select',  options: ACCSStateTerritoryBodyTypes, valueProperty: 'value'},
                            {name: 'organisationName', type: 'text'},
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                            {name: 'isNotifiedByStateTerritory', type: 'checkbox'}, 
                            {name: 'isNoticeToStateTerritory', type: 'checkbox'},
                        ]}
                    />
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>

                </Fragment>      
            );   
            
        } else if (modeACCS === 'upload-supporting-docs-accs') {
            accsModalContent = (
                <Fragment>
                    <h3>Upload Supporting Documents</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'ID', type: 'text'}, 
                        ]}
                    />                    
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>
                </Fragment>      
            );             
        } else if (modeACCS === 'advise-child-no-longer-at-risk') {
            accsModalContent = (
                <Fragment>
                    <h3>Upload Supporting Documents</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'determinationID', type: 'text', value: childACCSDetermID}, 
                            {name: 'dateNoLongerAtRisk', type: 'date'}, 
                            {name: 'noLongerAtRiskReason', type: 'text'}, 
                            {name: 'recordToSupportNoLongerAtRisk', type: 'checkbox'}, 
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                        ]}
                    />                    
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>
                </Fragment>      
            );             
        }
    }
    

    // END OF ACCS MDOAL CONTENT RENDERING
    //-------------------



    // console.log('childGuardianAuthData: ', childGuardianAuthData);   

    // populate enrolment form with selected submission data if form has been selected
    let populatedForm = null;
    if (selectedFormObj && !loading) {
        // const submissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};
        // console.log('submissionObject: ', submissionObject);
        populatedForm = (
            <div>
                <EnrolConfigForm userInputFormData={userInputFormData} setUserInputFormData={setUserInputFormData} />
                <br/>
                <button className='btn btn-block btn-success' onClick={() => handleSaveAndPostEnrolment()} >Approve Form</button>
                <hr />
                <Form 
                    src={formioAPI + 'enrol'} 
                    submission={selectedFormObj}
                    onNextTab={scrollPageToTop}
                    onCustomSubmit={updateFormSubmission} 
                />
                <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>Note: This submit button will update the original form submission - Intended use is to correct any mistakes in user's form</p>
            </div>
        );
    } 

    // function to send username and new group to update a user's cognito group
    const deleteCognitoUser = async () => {

        setLoading(true);

        // get username from email
        const email = accountData?.[0]?.primary_email;

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            userpoolId: cognitoUserpoolId,
            email: email
        };
        try {
            const res = await axios.post(AWS_API + 'cognito/delete-cognito-user', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, 'Account reset')
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
        }     
        
        setLoading(false);
    }        

    let content = (
        <Fragment>
            {/* {mode === 'enrolments' && filteredCentres?.length > 0 && <Enrolments token={props.token} centres={filteredCentres}/>} */}
            {mode === 'preferences' && !loading && 
                <UpdateDetails postMasterData={postMasterData} handleUpdateDetails={handleUpdateDetails} deleteCognitoUser={deleteCognitoUser}
                allUserAccountsData={allUserAccountsData} childrenData={childrenData} centresData={filteredCentres}/>}
            {mode === 'invoices' && <div style={{position: 'relative'}}>
                <div className={classes.HeaderButtonRow}>
                    <AccountBalance selectedAccountObj={accountData?.[0]} />
                </div>
                <div className={classes.HeaderButtonRowRightAbsolute}>
                    <button className="btn btn-secondary btn-sm" onClick={() => updateAccountBalance()}>Refresh Balance</button>
                    <button className="btn CustomButtonPrimary btn-sm" onClick={() => getPresignedUrl(selected, 'statement-of-entitlement')}>View Statement</button>
                    <button className="btn CustomButtonPrimary btn-sm" onClick={() => setShowInvoiceCreditForm(true)}>New Credit/Debit</button>
                </div>
                <br/>
            </div>
            }
            {mode === 'children' && <Fragment>
                <div className={classes.HeaderButtonRowRight}>
                    {/* <button className="btn CustomButtonPrimary btn-sm" onClick={() => setShowEditChildDetails(!showEditChildDetails)}>Edit Child Information</button> */}
                    {!centreAdminFlag && <button className="btn CustomButtonPrimary btn-sm" onClick={() => handleModeChange('update-details')}>Edit Child Information</button>}
                </div>
                <div className={classes.HeaderButtonRowColumn}>
                    {/* {childrenData?.length > 0 && childrenData.map(obj => <button key={obj?.child_id} className='btn btn-sm btn-secondary' onClick={() => handleShowChildSummary(obj.child_id)}>{obj?.first_name + ' ' + obj?.surname}</button>)} */}
                    {childrenData?.length > 0 &&  <MaterialList
                            list={childrenData} 
                            primaryAction={handleShowChildSummary}
                            table='children' primaryField='first_name' secondaryFields={['surname', 'dob' ]} 
                            icon='person'
                        />                             
                    }   
                </div>
                {/* {transformedData?.length > 0 && <StylisedTable data={transformedData} columns={['child_id', 'first_name' ,'surname', 'dob', 'crn']}/>} */}
             </Fragment>
            }
            {mode === 'contacts' && !centreAdminFlag && <div className={classes.HeaderButtonRowRight}>
                {/* <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setShowEditContactDetails(true);}}>Edit Contact Information</button> */}
                {/* <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setShowAddContactForm(true); handleModeChange('children'); setShowEditChildDetails(true);}}>Add Contact</button> */}
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleModeChange('update-details');}}>Edit Contact Information</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setShowAddContactForm(true); handleModeChange('update-details');}}>Add Contact</button>
            </div>}
            {mode === 'filing' && !centreAdminFlag && <div className={classes.HeaderButtonRowRight}>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleAddDocument();}}>Add Documents</button>
            </div>}

            {mode === 'bookings' && submissionTableData?.length > 0 && <Fragment>
                    {!centreAdminFlag && <div className={classes.HeaderButtonRowRight}>
                        <button className="btn btn-secondary btn-sm" onClick={handleShowRollActivityLog}>Activity Log</button>
                        <button className="btn btn-success btn-sm" onClick={() => setShowUpdateSchedule(true)}>Update Schedule</button>
                        <button className="btn btn-danger btn-sm" onClick={() => setShowEndBookingSchedule(true)}>End Schedule</button>
                        <button className="btn btn-warning btn-sm" onClick={handleShowAddCasualBooking}>New Casual Booking</button>
                    </div>}          
                    <ScheduleView token={props.token} data={filteredSubmissionTableData} centres={centres} children={childrenData}/>
                    <hr /><hr/>
                </Fragment>
            }

            {mode === 'ccs' && <Fragment>
                {selectedChildren?.length > 0 && <div className={classes.HeaderButtonRowRight}>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleViewEnrolmentForm();}}>View/Edit Enrolment</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleSubmodeChange('enrolment_history');}}>Live CL Enrolments</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleSubmodeChange('live_entitlements');}}>Live CL Entitlements</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleSubmodeChange('entitlement_history');}}>OSHC+ Entitlements</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {handleSubmodeChange('accs')}}>ACCS</button>
                </div>}
               

            </Fragment>
            }

            {mode === 'update-details' && <div> 
            {/* {mode === 'children' && showEditChildDetails && <div className={classes.EditChildDetailsContainer} >  */}
                {/* <Modal large show={mode === 'children' && showEditChildDetails} modalClosed={() => setShowEditChildDetails(false)}> */}
                    <ParentData 
                        adminFlag
                        token={props.token} 
                        missingAccountData={missingAccountData} 
                        documentsData={documentsData}
                        guardianData={guardianData} 
                        emergencyContactsData={emergencyContactsData}
                        childGuardianAuthData={childGuardianAuthData} 
                        childGroupAuthData={childGroupAuthData} 
                        guardianAddressData={guardianAddressData} 
                        childrenData={childrenData} 
                        childrenMedicalData={childrenMedicalData} 
                        childrenAddressData={childrenAddressData}
                        childGuardianRelationshipData={childGuardianRelationshipData}
                        showAddContactForm={showAddContactForm} 
                        fetchSpecificTableData={fetchSpecificTableData} 
                        onPostSuccessFn={onAccountDataUpdated}
                        />
                {/* </Modal> */}
            </div>}

            {submissionTableData && !hideSubmissionTableModes.includes(mode) && <Fragment> <SubmissionsTable table={viewingTable} selected={selected} setSelected={setSelected} data={transformedData} getSubmissionObject={getSubmissionObject}/> </Fragment>}

            {submode && submodeData && <Fragment> 
                <br/>
                <h5>{humanize(submode)}</h5>
                <SubmissionsTable table={viewingSubtable} selected={[]} setSelected={() => false} data={transformedSubmodeData}/> 
            </Fragment>}

            {/* {mode === 'ccs' && submode === 'enrolment_history' && <EnrolmentHistory enrolmentData={submodeData} />} */}
            {mode === 'ccs' && <Fragment><hr/>{populatedForm}</Fragment>}
            
        </Fragment>

    );

    // set tabs available based on user role
    let tabConfig;
    if (centreAdminFlag) {
        tabConfig = [
            {label: 'Children', value: 'children'}, 
            {label: 'Contacts', value: 'contacts'}, 
            {label: 'Documents', value: 'filing'}, 
            {label: 'Bookings', value: 'bookings'},
        ];
    } else {
        tabConfig = [
            {label: 'Billing', value: 'invoices'},
            {label: 'Children', value: 'children'}, 
            {label: 'Contacts', value: 'contacts'}, 
            {label: 'Update', value: 'update-details'}, 
            {label: 'Documents', value: 'filing'}, 
            {label: 'Bookings', value: 'bookings'},
            {label: 'CCS', value: 'ccs'},
            {label: 'Preferences', value: 'preferences'},
        ];
    }

    

    if (loading) {
        content = <SpinnerDark />;
    }

    return (
        <div className={classes.AccountMgmt}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Modal tall show={showEditContactDetails} modalClosed={() => setShowEditContactDetails(false)}>
                {showEditContactDetails && <UpdateContactDetails contactData={submissionTableData} handleUpdateContactData={handleUpdateContactData}/>}
            </Modal>                 
            <Modal large show={viewingChildActivityLogId} modalClosed={handleCloseRollActivityLog}>
                {!loading && <RollActivityLog childRollActivityLogData={childRollActivityLogData}/> }
                {loading && <SpinnerDark />}
            </Modal>                 
            <Modal show={showAddDocument} modalClosed={() => setShowAddDocument(false)}>
                {showAddDocument && <Fragment>
                    <h3>Upload Documents</h3>
                    <br/>
                    <p>Selected Child: <i>{childrenData?.filter(obj => obj.child_id === selectedChildren?.[0]?.value)?.[0]?.first_name}</i></p>
                    <p>Selected Centre: <i>{childEnrolmentsData.filter(obj => obj.child_id === selectedChildren?.[0]?.value)?.[0]?.centre_id}</i></p>
                    <p>Selected Account: <i>{ accountData?.[0]?.account_id}</i></p>
                    <p>Selected Email: <i>{ accountData?.[0]?.primary_email}</i></p>
                    <hr/>
                    <FileUpload setFiles={setFiles} files={files} docTypes={generalDocTypes} useJSMimesNotCCSMimes/>    
                    <button className='btn btn-block btn-success' onClick={handlePostDocument} >Submit</button>
                </Fragment>
                }
            </Modal>                 
            <Modal large show={showUpdateSchedule} modalClosed={() => setShowUpdateSchedule(false)}>
                {showUpdateSchedule && <UpdateSchedule childrenData={childrenData} alreadySelectedChildren={selectedChildren?.map(obj => obj.value)} centresData={filteredCentres} postSubmission={postSubmission}/>}
            </Modal>                 
            <Modal large show={showEndBookingSchedule} modalClosed={() => setShowEndBookingSchedule(false)}>
                {showEndBookingSchedule && <EndSchedule childrenData={childrenData} alreadySelectedChildren={selectedChildren?.map(obj => obj.value)} centresData={filteredCentres} postSubmission={postSubmission}/>}
            </Modal>                 
            <Modal large show={showAddCasualBooking} modalClosed={() => setShowAddCasualBooking(false)}>
                <AddChildren centreSessionData={centreSessionData} cabinetFlag alreadyTickedChildren={selectedChildren?.map(obj => obj.value)} centreId={addCasualBookingCentreId}
                centresData={filteredCentres?.filter(obj => obj.centre_id === addCasualBookingCentreId)} childrenData={childrenData} writeBlockBooking={writeBlockBooking}/>
            </Modal>                  
            <Modal tall show={viewingChildSummaryId} modalClosed={handleCloseChildSummary}>
                {viewingChildSummaryId && <ChildSummary 
                        parentComponent={'cabinet'} 
                        childImg={childPhoto} 
                        childSummaryData={childSummaryData} 
                        childFlagsData={childSpecificChildFlagsData} 
                        emergencyContactsData={childSpecificEmergencyContactData}
                        childEnrolmentsData={childEnrolmentsData.filter(obj => obj.child_id === +viewingChildSummaryId)}
                        childBusListOverridesData={childBusListOverridesData}
                        handleSubmitRollNote={handleSubmitRollNote}
                    />}
            </Modal>                  
            <Modal tall show={showInvoiceCreditForm} modalClosed={() => setShowInvoiceCreditForm(false)}>
                {accountData && <NewInvoiceCreditForm selectedAccountObj={accountData?.[0]} filteredCentres={filteredCentres} submitDataFn={handlePostInvoiceCredit}/>}
            </Modal> 
            <Modal large show={showACCSModal} modalClosed={() => {setShowACCSModal(false); resetACCSData();}}>
                    {showACCSModal && 
                        <Fragment>
                            <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setModeACCS('advise-child-no-longer-at-risk'); resetACCSData();}}>Advise Not At Risk</button>
                                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setModeACCS('upload-supporting-docs-accs'); resetACCSData();}}>Upload Docs</button>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setModeACCS('update-state-territory-accs'); resetACCSData();}}>Update State</button>
                                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setModeACCS('apply-for-accs-determination'); resetACCSData();}}>Apply For Determination</button>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setModeACCS('submit-accs-certificate'); resetACCSData();}}>Submit Certificate</button>
                            </div>                            
                            {accsModalContent}
                        </Fragment>
                    }
                </Modal>                             
            <Banner show={bannerText}>{bannerText}</Banner> 
            <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                
                <TertiaryNavbar tab={mode} updateStateFn={handleModeChange} config={tabConfig} />
                <button className='btn btn-sm btn-secondary' onClick={refreshData}><IoRefreshOutline size={'1rem'} color={'white'} /></button>
            </div> 

            {childLevelModes.includes(mode) && <ChildSelector childrenData={childrenData} selectedChildren={selectedChildren} setSelectedChildren={setSelectedChildren}/>}
            {content}

            
            {/* {loading && <BackdropSpinner spinner='small-light' />} */}
            {/* {<BackdropSpinner spinner='small-light' />} */}
            <hr />
        </div>
    );
}

export default AccountMgmt;
