import React, { 
    useEffect,
    useState 
} from 'react'
import classes from './AddChildren.module.css';
import DatePicker from "react-multi-date-picker";
import Select from 'react-select'
import { sessionTypes } from '../../../../../utilities/globalObjects';
import { raw } from '../../../../../translations/en/raw';
import { datePickerObjToISOFormat } from '../../../../../utilities/functions';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const AddChildren = (props) =>  {


    // get data from props
    const childrenData = props.childrenData;
    const alreadyTickedChildren = props.alreadyTickedChildren;
    const dates = props.dates;
    const session = props.session;
    const centreId = props.centreId;
    const centresData = props.centresData;
    const centreSessionData  = props.centreSessionData;
    // const addingFromRollFlag = props.addingFromRollFlag;   
    const writeBlockBooking = props.writeBlockBooking;
    const cabinetFlag = props.cabinetFlag;

    // defaults
    // const normalBusLogicDefault = addingFromRollFlag ? false : true;


    // casual booking form state
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedSessions, setSelectedSessions] = useState([]);    
    const [overrideCapacityCheck, setOverrideCapacityCheck] = useState(false);
    const [overrideSessionApprovalCheck, setOverrideSessionApprovalCheck] = useState(false);
    const [busListOnly, setBusListOnly] = useState(false);
    const [normalBusLogic, setNormalBusLogic] = useState(true);
    // const [updateOnConflict, setUpdateOnConflict] = useState(false);

    // console logs
    // console.log('centreSessionData: ', centreSessionData);
    // console.log('childrenData: ', childrenData);
    console.log('centresData: ', centresData);
    console.log('centreId: ', centreId);
    // console.log('selectedChildren: ', selectedChildren);
    // console.log('alreadyTickedChildren: ', alreadyTickedChildren);
    // console.log('selectedDates: ', selectedDates);
    // console.log('selectedSessions: ',selectedSessions);



    // upon component load, add already ticked children to selected children
    useEffect(() => {

        // pre-selected children
        // console.log('running add already selected chilren useEffect');
        if (alreadyTickedChildren && childrenData) {

            // get value and label for selectedChildren object
            const newSelectedChildren = [];
            const filteredChildrenData = childrenData.filter(obj => alreadyTickedChildren.includes(obj.child_id));
            // console.log('filteredChildrenData: ', filteredChildrenData);
            filteredChildrenData.forEach(obj => {
                
                // add selected child to react select component with info depending on what's available passed in by parent component
                if (obj.account_holder) {
                    newSelectedChildren.push({value: obj.child_id, label: obj.child_name + ' (Acct: ' + obj.account_holder + ')'})
                } else {
                    newSelectedChildren.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})
                }
                
            });
            setSelectedChildren(newSelectedChildren);
        }

        // pre-selected dates
        if (dates) {
            const dateObjs = dates.map(date => new Date(date))
            setSelectedDates(dateObjs);
            
        }
        
        // pre-selected sessions
        if (session) {
            const newSessions = [{value: session, label: raw[session]}];
            // console.log('newSessions: ', newSessions);
            setSelectedSessions(newSessions);
        }
        


    }, [alreadyTickedChildren, childrenData, dates, session]);


    const submitAddChildrenForm = () => {

        if (!selectedChildren || !selectedSessions || !selectedDates || !centreId) {
            alert('please select centre, children, dates and sessions');
            return false;
        }

        // convert date from DateObj in DatePicker to YYYY-MM-DD format needed in lambda
        const datesToSubmit = [];
        for (const date of selectedDates) {
            datesToSubmit.push(datePickerObjToISOFormat(date));
        }              

        const childIds = selectedChildren.map(obj => obj.value);
        const sessions = selectedSessions.map(obj => obj.value);

        const bookingData = {
            centreId: centreId,
            dates: datesToSubmit, 
            childIds: childIds, 
            sessions: sessions, 
            overrideCapacityCheck: overrideCapacityCheck,
            overrideSessionApprovalCheck: overrideSessionApprovalCheck,
            busListOnly: busListOnly,
            normalBusLogic: normalBusLogic,
        }
        // updateOnConflict: updateOnConflict

        // console.log('[AddChildren.js] - posting booking data: ', bookingData);

        writeBlockBooking(bookingData, clearForm);
    

    }


    const clearForm = () => {
        // restore form defaults
        setSelectedChildren([]);
        setSelectedDates([]);
        setSelectedSessions([]);
        setBusListOnly(false);
        setOverrideCapacityCheck(false);
        setOverrideSessionApprovalCheck(false);
        setNormalBusLogic(true);
    }

    // function to check what dates at selected centre are running selected sessions
    const isDateDisabled = (date) => {

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        // console.log('[isDateDisabled] - date: ', date);
        // console.log('[isDateDisabled] - formattedDate: ', formattedDate);

        // get centre sessions calendar entry for selected day and centre
        const filteredSessionData = centreSessionData?.filter(obj => obj?.date?.slice(0,10) === formattedDate && +obj?.centre_id === +centreId)?.[0];
        const selectedSessionLabels = selectedSessions?.map(obj => obj.value)

        // check if day is running all selected sessions
        const allSessionsOperating = selectedSessionLabels?.length > 0 && selectedSessionLabels.every(session => filteredSessionData?.[session] === true);

        return !allSessionsOperating;
    };    
    
    // add children to roll form
    let addChildrenForm;
    if (childrenData) {
        // create options for multi select
        const childOptions = [];
        const sessionOptions = [];
        sessionTypes.forEach(el => {sessionOptions.push({value: el, label: raw[el]})})
        childrenData.forEach(obj => {childOptions.push({value: obj.child_id, label: obj.child_name + ' (Acct: ' + obj.account_holder + ')'})})

        // set config based on where component is being rendered
        let childSelectDisabled = false;
        if (cabinetFlag) {
            childSelectDisabled = true;
        }

        let datePicker = (
            <div className="form-group">
                <label className={classes.Label}>Select Dates</label> <br/>
                <DatePicker inputClass="form-control" placeholder="Select Date" value={selectedDates} fixMainPosition={true} format='YYYY-MM-DD' multiple onChange={(e) => {setSelectedDates(e);}}/>
            </div>
        );

        if (centreSessionData?.length > 0) {
            datePicker = (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <div>
                        <DayPicker
                            mode="multiple"
                            selected={selectedDates}
                            onSelect={setSelectedDates}
                            disabled={isDateDisabled}
                        />
                    </div>
                </div>  
            );
        }


        addChildrenForm = (
                <div> 
                    <div className="form-group" >
                        <label className={classes.Label}>Centre</label>
                        <input disabled className="form-control" type="text" value={centresData?.filter(obj => obj.centre_id === +centreId)?.[0]?.centre_name ?? ''}/>
                    </div> 
                    <div  className="form-group" >
                        <label className={classes.Label}>Select Children</label>
                        <Select isDisabled={childSelectDisabled} isMulti options={childOptions} onChange={setSelectedChildren} value={selectedChildren} ></Select>    
                    </div>  
                    <div className="form-group" >
                        <label className={classes.Label}>Select Sessions</label>
                        <Select isMulti options={sessionOptions} onChange={setSelectedSessions} value={selectedSessions}></Select>    
                    </div> 
                    {datePicker}
                    <div className="form-check" >
                        <input checked={overrideCapacityCheck} onChange={(e) => setOverrideCapacityCheck(e.target.checked)} className="form-check-input" type="checkbox" id="flexCheckDefault"/>
                        <label >Override capacity check</label>
                    </div> 
                    <div className="form-check" >
                        <input checked={overrideSessionApprovalCheck} onChange={(e) => setOverrideSessionApprovalCheck(e.target.checked)} className="form-check-input" type="checkbox" id="sessionApprovalCheck"/>
                        <label >Override session approval check</label>
                    </div> 
                    {/* <div className="form-check" >
                        <input checked={busListOnly} onChange={(e) => setBusListOnly(e.target.checked)} className="form-check-input" type="checkbox" id="flexCheckDefault"/>
                        <label >Bus list only</label>
                        </div> 
                    <div className="form-check" >
                        <input checked={normalBusLogic} onChange={(e) => setNormalBusLogic(e.target.checked)} className="form-check-input" type="checkbox" id="flexCheckDefault"/>
                        <label >Apply standard bus list logic</label>
                    </div>  */}
                    {/* <div className="form-check" >
                        <input checked={updateOnConflict} onChange={(e) => setUpdateOnConflict(e.target.checked)} className="form-check-input" type="checkbox" id="flexCheckDefault"/>
                        <label >Update conflicting records (i.e. child already booked in under wrong account)</label>
                    </div>  */}
                     
                    <p><i>The parent/caregiver has given me verbal/written authorisation to make this booking on their behalf and has authorised any permission forms that are associated with this booking.</i></p>
                    <button className='btn btn-success btn-block' onClick={submitAddChildrenForm} >Submit</button>
                </div>         
        );

    }


    return (
      <div className={classes.AddChildren}>
        <h3>Add Children To Roll</h3>
        <hr />
        {addChildrenForm}
      </div>
    );
}

export default AddChildren;