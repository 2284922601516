import React from 'react';

import classes from './ScheduleView.module.css';
import { raw } from '../../../../translations/en/raw';
import { 
    // centrelinkMon1, 
    timezone } from '../../../../utilities/globalVariables';
// import { calculateCentrelinkWeek, getPrevMonday} from '../../../../utilities/functions';
// import {useTranslation} from "react-i18next";

const ScheduleView = (props) => {

    // i18n
    // const {t} = useTranslation('raw');

    // calculate date of next monday1 and monday2 according to CentreLink weeks
    // console.log('centrelInkMOnd1: ', centrelinkMon1);
    // const closestMonday = new Date();
    // closestMonday.setDate(closestMonday.getDate() + ((7 - closestMonday.getDay()) % 7 + 1) % 7);
    // const daysFromW1 = (closestMonday-centrelinkMon1)/86400/1000 % 14;
    // const cLWeek = daysFromW1 < 2 ? 1 : 2;
    // const prevMonday = getPrevMonday(new Date());
    // const closestMondayString = new Date(prevMonday).toDateString();
    // const cLWeek = calculateCentrelinkWeek(prevMonday, centrelinkMon1);

    // get data from props
    const data = props.data;
    console.log('[ScheduleView] - permBookingData: ', data);

    // filtering now happening serverside, data passed into this component should be the first non-empty booking schedule for each child/session/centre combo
    let activeSchedules;
    try {
        activeSchedules = [...data];
    } catch (err) {
        console.log('err: ', err);
        activeSchedules = [];
    }

    // filter out closed schedules or schedules which haven't yet started
    // const activeSchedules = data.filter(obj => {
    //     const endDate = obj.end_date ? new Date(obj.end_date).getTime() : null;
    //     const startDate = new Date(obj.start_date).getTime();
    //     const today = new Date().getTime();
    //     return (startDate <= today && (endDate > today || !endDate));
    // });

    // console.log('data: ', data);
    // console.log('activeSchedules: ', activeSchedules);
    const centresRefData = props.centres;
    const childrenRefData = props.children;

    // transform permBookings data structure
    const centres = new Set(activeSchedules.map(obj => obj.centre_id));
    const sessions = new Set(activeSchedules.map(obj => obj.session).sort().reverse());
    // const sessions = ['bsc', 'asc']
    // const startDateEndDates = new Set(activeSchedules.map(obj => obj.start_date?.slice(0,10) + ':' + (obj.end_date?.slice(0,10) ?? 'no end date')));
    
    
    // console logs
    // console.log('centresRef: ', centresRefData);


    const scheduleObj = {};

    // iterate through each centre there's a booking for
    centres.forEach(centre => {
        scheduleObj[centre] = {};

        const filteredCentre = activeSchedules.filter(obj => obj.centre_id === centre);
        // console.log('filteredCentre: ', filteredCentre); 

        // iterate through each session within this centre
        sessions.forEach(session => {

            scheduleObj[centre][session] = [];

            const filteredSession = filteredCentre.filter(obj => obj.session === session);
            // console.log('session: ', session);
            // console.log('filteredSession: ', filteredSession); 

            const noEndDateStr = 'no end date';
            const startDateEndDates = new Set(filteredSession.map(obj => obj.start_date?.slice(0,10) + ':' + (obj.end_date?.slice(0,10) ?? noEndDateStr)));
            // console.log('startDateEndDates: ', startDateEndDates);

            // loop through each startdate/enddate combo within session
            startDateEndDates.forEach(startEndDate => {
                
                const datesSplit = startEndDate?.split(':');
                const startDate = datesSplit?.[0];
                const endDate = datesSplit?.[1]; //=== 'no end date' ? undefined : datesSplit?.[1];
                // console.log('startDate: ', startDate);
                // console.log('endDate ', endDate);
                const filteredStartEndDate = filteredSession.filter(obj => obj.start_date?.slice(0,10) === startDate && (obj.end_date?.slice(0,10) ?? noEndDateStr) === endDate);
                // console.log('filteredStartEndDate: ', filteredStartEndDate);
                
                // get each unique combination of days booked for given session and centre
                const childrenDays = [];
                filteredStartEndDate.forEach(obj => {
                    let dayArray;
                    if (obj.mon_1 === obj.mon_2 && obj.tue_1 === obj.tue_2 && obj.wed_1 === obj.wed_2 && obj.thu_1 === obj.thu_2 && obj.fri_1 === obj.fri_2 ) {
                        dayArray = [obj.mon_1, obj.tue_1, obj.wed_1, obj.thu_1, obj.fri_1];
                    } else {
                        dayArray = [obj.mon_1, obj.tue_1, obj.wed_1, obj.thu_1, obj.fri_1, obj.mon_2, obj.tue_2, obj.wed_2, obj.thu_2, obj.fri_2];
                    }
                    // console.log('dayArray: ', dayArray);
    
                    // check if this schedule already in childrenDays - if yes, add child to existing array, otherwise add new entry for schedule
                    let recorded = false;
                    childrenDays.forEach(schedule => {
                        if (arraysMatch(schedule.days, dayArray)) {
                            schedule.children.push(obj.child_id);
                            recorded = true;
                        }
                    });
                    if (!recorded) {
                        childrenDays.push({children: [obj.child_id], days: dayArray});
                    }
                });

                scheduleObj[centre][session].push({startDateEndDate: startEndDate, schedules: childrenDays})
            });


        })
        
    });

    // console.log('scheduleObj: ', scheduleObj);

    // determine whether fortnightly schedule view is needed


    // render schedule view from transformed permanentBookings data
    const scheduleView = Object.keys(scheduleObj).map(centre => {
        const centreData = scheduleObj[centre];
        const centreName = centresRefData?.filter(obj => obj.centre_id === +centre)?.[0]?.centre_name;
        // console.log('centre: ', typeof centre);      
        // console.log('centreName: ', centreName);      
        const centreSessions = Object.keys(centreData);
        // console.log('centreSessions: ', centreSessions);
        // return false;
        return (
            <div className={classes.CentreRow} key={centre}>
                <h6 className={classes.CentreTitle}>{centreName}</h6>
                {/* <span>Note: {closestMondayString} corresponds to Centrelink week {cLWeek}</span> */}
                {centreSessions.map(session => {
                    
                    const centreSessionData = centreData[session];
                    // console.log('session: ', session);
                    // console.log('centreSessionData: ', centreSessionData);
                    return (

                        // loop through each session/startEndDate combo
                        centreSessionData.map(sessionObj => {

                            // format start and end dates of schedule
                            const datesSplit = sessionObj?.startDateEndDate?.split(':');
                            const startDate = new Date(datesSplit?.[0]).toDateString("en-GB", {timeZone: timezone});
                            let endDate = new Date(datesSplit?.[1]).toDateString("en-GB", {timeZone: timezone}); 
                            if (endDate === 'Invalid Date') endDate = 'No End Date';

                            let maxScheduleLength = 0;
                            centreSessionData.forEach(obj => obj.schedules.forEach(obj2 => maxScheduleLength = Math.max(maxScheduleLength, obj2.days.length)));
                            const scheduleType = (maxScheduleLength === 5 ? 'week' : 'fortnight');
                            // console.log('scheduleType: ', scheduleType);
                            const header = (scheduleType === 'week')
                            ? <thead><tr><th>Children</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th></tr></thead>
                            : <thead><tr><th>Children</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th></tr></thead>
                            return (
                                <div  key={session + sessionObj.startDateEndDate}>
                                        <div className={classes.SessionRow}>
                                        <h6 className={classes.SessionTitle}>{raw[session] ?? session}</h6>
                                        <div style={{width: '100%', textAlign: 'center', fontWeight: 500}} ><i>Starting: {startDate} &nbsp; Ending: {endDate}</i></div>
                                        <table className="table table-hover">
                                            {header}
                                            <tbody>
                                                {sessionObj.schedules.map(rowObj => {
                                                    const childNames = childrenRefData?.filter(obj => rowObj.children.includes(obj.child_id))?.map(obj => obj.first_name)?.join(', ');
                                                    if (scheduleType === 'week') {
                                                        return (
                                                            <tr key={centre + session + sessionObj.startDateEndDate + rowObj.children}>
                                                                <td>{childNames}</td>
                                                                <td className={rowObj.days[0] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[1] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[2] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[3] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[4] ? classes.TrueFill : null}></td>
                                                            </tr>
                                                        );
                                                    } else {
                                                        return (
                                                            <tr key={centre + session + sessionObj.startDateEndDate + rowObj.children}>
                                                                <td>{childNames}</td>
                                                                <td className={rowObj.days[0] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[1] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[2] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[3] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[4] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[5] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[6] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[7] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[8] ? classes.TrueFill : null}></td>
                                                                <td className={rowObj.days[9] ? classes.TrueFill : null}></td>
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                        </div>
                                </div>
    
                            );
                        })
                    );
                })}
            </div>
        );
    })


    return (

        <div className={classes.ScheduleView}>
            {scheduleView}
        </div>

    );


};

  
const arraysMatch = (arr1, arr2) => {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};

export default ScheduleView;