import React, { 
    // useState 
} from 'react'
import MaterialCard from '../../../UI/MaterialCard/MaterialCard';
// import BSTable from '../../../UI/BSTable/BSTable';
// import MaterialList from '../../../UI/MaterialList/MaterialList';
import classes from './Entitlements.module.css';
 
const Entitlements = (props) =>  {

    // get data from props
    const data = props.entitlementsData;
    const centresData = props.centresData;

    // console logs
    console.log('[Entitlements.js] - data: ', data);

    // only render the latest entitlement, but the two latest will be compared elsewhere to notify parent of change to entitlements
    let content;
    if (data && data.length > 0 && centresData?.length > 0) {

        const displayData = data.filter(obj => +obj.rank === 1);

        // add centre name to data
        for (const row of data) {
            const centreName = centresData.filter(obj => obj.centre_id === row?.centre_id)?.[0]?.centre_name;
            row.centre_name = centreName;
        }

        // content = <BSTable data={displayData} table='view_latest_entitlements' />
        // content = <MaterialList
        //     list={displayData} 
        //     primaryAction={() => false}
        //     table='view_latest_entitlements' primaryField='child' secondaryFields={['rebate_pct', 'header_status', 'enrolment_status', 'status_last_updated' ]} 
        // />    
        content = <MaterialCard
            data={displayData} 
            titleField='child'
            subtitleField='status_last_updated'
            fields={[{field: 'centre_name', label: 'Centre'}, {field: 'header_status', label: 'Status'}, {field: 'rebate_pct_100', label: 'Subsidy Rate'}, {field: 'hours_cap', label: 'Eligible Hours'}]}
            table='view_latest_entitlements' 
            // expandable
        />    
    }

    // determine statuses
    const statuses = data.map(obj => obj.header_status).filter((x,i,a) => a.indexOf(x) === i);
    console.log('statuses: ', statuses);


    return (
      <div className={classes.Entitlements}>
        <p className={[classes.InfoText, classes.RedText].join(' ')}>Please allow up to 24 hours for your CCS Status to update</p>
        {content}
        <hr />
        { (statuses.includes('PENDIN') || statuses.includes('CEASED')) && <p className={classes.WarningText}>Attention: ACTION REQUIRED</p>}
        {statuses.includes('PENDIN') && <p className={classes.WarningText}>Your current CCS status is "Pending Confirmation". This means that YOU must go to your mygov app and confirm your child's enrolment with us.</p>}
        {statuses.includes('CEASED') && <p className={classes.WarningText}>Your current CCS status is "Ceased". This means that you must contact us to have your enrolment reactivated if you would like to continue attending sessions in future.</p>}
      </div>
    );
}

export default Entitlements;