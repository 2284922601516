import {React, useState, useEffect, useCallback, Fragment } from 'react';
import { Form } from 'react-formio';
// import Table from 'react-bootstrap/Table';
import axios from 'axios';
import classes from './Approvals.module.css';
// import Spinner from '../../../UI/Spinner/Spinner';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Modal from '../../../UI/Modal/Modal';
// import TopDrawer from '../../../UI/TopDrawer/TopDrawer';

import { useHistory, useLocation } from "react-router-dom";
import { AWS_API, formioAPI, cognitoUserpoolId, cognitoClientId } from '../../../utilities/globalVariables';
import { bootstrapTableConfig, formioConfig, 
    //  autoApprovedForms, 
    // enrolmentArrangementTypes, reasonsForPEA,enrolmentNoticeTypes 
} from '../../../utilities/globalObjects';
import { scrollPageToTop, handleAwsApiError, handleBannerText, handlePrompt, humanize} from '../../../utilities/functions';
import { raw } from '../../../translations/en/raw';
import Banner from '../../../UI/Banner/Banner';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import BSTable from '../../../UI/BSTable/BSTable';
import CentreApprovals from './CentreApprovals/CentreApprovals';
import EnrolConfigForm from '../AdminMgmt/Enrolments/EnrolConfigForm/EnrolConfigForm';
// import DirectDebitStatus from '../../Misc/DirectDebitStatus/DirectDebitStatus';
import Prompt from '../../../UI/Prompt/Prompt';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import TertiaryNavbar from '../../../UI/Navigation/TertiaryNavbar/TertiaryNavbar';



const Approvals = (props) => {

    // UI state
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [showFormType, setShowFormType] = useState();
    const [bannerText, setBannerText] = useState('');    
    const [mode, setMode] = useState('forms');  
    const [prompt, setPrompt] = useState({});    
    
    // data state
    const [selectedFormRow, setSelectedFormRow] = useState();
    const [selectedFormObj, setSelectedFormObj] = useState();
    const [pendingFormCounts, setPendingFormCounts] = useState();
    const [pendingForms, setPendingForms] = useState();
    const [directDebitData] = useState();
    const [loadingCellUpdate, setLoadingCellUpdate] = useState();
    const [selectedTag, setSelectedTag] = useState('no_tag');
    // const [alternativeBillingArrangementData] = useState();

    // const [formStartDate, setFormStartDate] = useState();
    // const [formEndDate, setFormEndDate] = useState();
    // const [formArrangementType, setFormArrangementType] = useState();

    // summary data state (for notifications only)
    const [countPendingChildApprovals, setCountPendingChildApprovals] = useState();

    // user input data to go along with form
    const [userInputFormData, setUserInputFormData] = useState({})

    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [selected, setSelected] = useState([]);      

    

    // console logs
    console.log('selected: ', selected);
    console.log('userInputFormData: ', userInputFormData);
    console.log('directDebitData: ', directDebitData);
    console.log('countPendingChildApprovals: ', countPendingChildApprovals);
    
    // react router history instance to navigate user to other pages
    let history = useHistory();
    let location = useLocation();

    // get data from props
    const setSublinks = props.setSublinks;
    const setNotifications = props.setNotifications;    

    // set leftpane sublinks
    useEffect(() => {

        // object to store links to pass up to App.js and render through Layout.js
        const newSublinks = [];        
        const newNots = [];

        // forms pending approval
        if (pendingFormCounts) {
            
            const formKeys = Object.keys(pendingFormCounts);
    
            // pending form buttons
            if (formKeys?.length > 0) {
                formKeys
                .sort((a,b) => {
                    if (a.includes('approved') && !b.includes('approved')) {return 1}
                    if (!a.includes('approved') && b.includes('approved')) {return -1}
                    else {return 0}                    
                })
                .forEach(key => {
                    
                    // set badges based on form type, treating approved forms differently
                    let badge;
                    if (!key.includes('approved')) {
                        badge = pendingFormCounts[key];
                        
                        // only add notifications for non approved forms
                        newNots.push({title:  raw[key] ?? key, description: pendingFormCounts[key] + ' form(s) pending approval', icon: 'form', type: 'action', onClick: () => history.replace("/approvals")});
                    } 

                    newSublinks.push({title: raw[key] ?? key, badge: badge, onClick: () => {setMode('forms'); setShowFormType(key); setSelectedFormObj(); setSelected([]);}})

                });
            } else {
                newSublinks.push({title: '-', onClick: () => false});
            }
            
            
        }
        
        // children pending approval (i.e. suspend-bookings from child_approvals)
        const countRollAutoAddPending = countPendingChildApprovals?.filter(obj => obj.approval_type === 'suspend-bookings')?.[0]?.num_pending;
        newSublinks.push({title: 'Child Approvals', badge: countRollAutoAddPending, onClick: () => setMode('children')})
        if (countRollAutoAddPending > 0) {
            newNots.push({title:  'Child Approvals', description: countRollAutoAddPending + ' child approval(s) pending approval', icon: 'form', type: 'action', onClick: () => history.replace("/approvals")});
        }
        
        // bookings pending approval (i.e. booking-approval-required from child_approvals)
        const countBookingsPendingApproval = countPendingChildApprovals?.filter(obj => obj.approval_type === 'individual-bookings')?.[0]?.num_pending;
        newSublinks.push({title: 'Booking Approvals', badge: countBookingsPendingApproval, onClick: () => setMode('bookings')})
        if (countBookingsPendingApproval > 0) {
            newNots.push({title:  'Booking Approvals', description: countBookingsPendingApproval + ' booking(s) pending approval', icon: 'form', type: 'action', onClick: () => history.replace("/approvals")});
        }

        setSublinks(newSublinks);
        setNotifications(newNots);

    }, [setSublinks, pendingFormCounts, history, setNotifications, countPendingChildApprovals])    
    
    // generic function to get data and save to state
    const getMasterData = useCallback(async (route, params, stateFn) => {
        
        // firstCall ? setLoading(true) : void(0);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: params
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + route, config);
            console.log('data fetch res: ', res.data);
            stateFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            


        // lastCall ? setLoading(false) : void(0);

    }, [history, props.token, location]);  
    
    const updateChildApprovalNotifications = useCallback(() => {
        getMasterData('master-data/fetch', {table: 'view_count_pending_child_approvals'}, setCountPendingChildApprovals)
    }, [getMasterData]);

    // effect to get count of child approvals pending
    useEffect(() => {
        updateChildApprovalNotifications()
    }, [updateChildApprovalNotifications])
    
    
    
    // async function to get form submission data pending approval
    const getAllPendingForms = useCallback(async () => {
        
        setLoading(true);

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: headers, 
            params: {table: 'view_forms_needing_approval'} // can specify column and condition here, but for now let's just get all pending forms and show each type as a notification
        };

        // get all submitted form IDs that aren't "auto-approved"
        let newPendingForms;
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('response: ', res.data);
            // newPendingForms = res.data.filter(obj => obj.form_id && !autoApprovedForms?.includes(obj.form_type)); // filter out forms we don't want to show in approvals section
            
            // view_forms_needing_approval now filtering out auto-approved forms already to bring less data into app (08.05.23)
            newPendingForms = res.data.filter(obj => obj.form_id); 
            setPendingForms(newPendingForms);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
            setLoading(false);
            return false;
        }     

        console.log('newPendingForms: ', newPendingForms);

        // create pending forms notification object
        const newPendingFormCounts = newPendingForms.reduce((agg, curr) => {
            if (agg[curr.form_type]) {
               agg[curr.form_type] += 1
            } else {
               agg[curr.form_type] = 1
            }
            return agg
         }, {})
         
        console.log('newPendingFormCounts: ', newPendingFormCounts);
        setPendingFormCounts(newPendingFormCounts);  
        
        setLoading(false);

    }, [props.token, history, location]);


    // call function to get form ids pending approval on component render
    useEffect(() => {
        getAllPendingForms();

    }, [getAllPendingForms]);        

    const updateData = async (oldValue, newValue, row, column) => {
        
        setLoadingCellUpdate(true);

        // currently only handling updating of form_submissions in this component (via view_forms_needing_approval, data actually being shown)
        const selectedTable = 'view_forms_needing_approval';
        const config = bootstrapTableConfig[selectedTable];
        const updateTable = config?.sourceTable;
        
        // get primary keys
        const primaryKeys = config?.primaryKeys;
        if (!primaryKeys) {
            setError('Primary keys not found for selected table: ' + selectedTable);
            setLoadingCellUpdate(false);
            return false
        }        

        // construct primary keys object
        const primaryKeysObj = {};
        primaryKeys.forEach(key => primaryKeysObj[key] = row[key]);


        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        const updateCol = column.dataField;
        const postData = {
            table: updateTable,
            updateObj: {[updateCol]: newValue},
            primaryKeysObj: primaryKeysObj
        };
        console.log('posting: ', postData);

        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'master-data/update', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Data Updated Successfully`);
            
            // update data
            getAllPendingForms();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoadingCellUpdate(false);        
    }      


    // function to approve enrolment and write master data to RDS
    const postMasterData = async (submissionObject) => {
        
        // first update form in S3 in case any data within was updated
        await updateFormSubmission(submissionObject.data, true);
        
        setLoading(true);

        
        console.log('submissionObject: ', submissionObject);

        // check form type being approved and send to the appropriate lambda function to write the data
        if (formioConfig?.[submissionObject.formType]?.postToRoute === 'initial-master-data/write') {

            if (!userInputFormData.startDate || !userInputFormData.arrangementType) {
                alert('Please select an enrolment start date and arrangement type');
                setLoading(false);
                return false;
            }
            
            // create data object for final lambda function and headers for authorising with lambda authoriser on API Gateway
            const postData = {
                // submissionData: submissionObject.data,
                // formId: submissionObject.formId,
                // formType: submissionObject.formType,
                ...submissionObject,
                userInputFormData: userInputFormData
            };
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            console.log('approving enrolment, posting data: ', postData);

            try {
                const res = await axios.post(AWS_API + '/initial-master-data/write', {data: postData}, headers);
                console.log('postMasterData response: ', res.data);
                
                // update pending forms and recalculate notifications etc
                getAllPendingForms(); // this will trigger loading again, so don't setLoading = false in this path
                updateChildApprovalNotifications();
                setSelectedFormObj();
                setUserInputFormData({});
                setSelected([]);
                handleBannerText(setBannerText, 'Enrolment Approved');
                // alert('enrolment approved!');
    
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error while writing master data, transaction aborted');
                setLoading(false);
            }

        } else {

            // UPDATE PERM BOOKINGS LOGIC NOW HAPPENING SERVER SIDE IN SPECIFIC ROUTE, JUST POSTING FORM DATA TO THAT ROUTE
            const innerHeaders = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
            const headers = {headers: innerHeaders};
            const postData = { submissionObject: submissionObject};            
            try {
                const res = await axios.post(AWS_API + 'master-data/update-permanent-bookings', {postData: postData}, headers);
                console.log('postMasterData response: ', res.data);
                handleBannerText(setBannerText, 'Form Approved');
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while attempting to approve form');
                setLoading(false);
            }              

            // update pending forms and recalculate notifications etc
            getAllPendingForms(); // this will trigger loading again, so don't setLoading = false in this path
            setSelectedFormObj();



        }

    }



    // function to intercept form submission data and save to S3 when submit button pressed and CustomSubmit event fired - used for admin to make overwrites to user submitted form
    const updateFormSubmission = async (submission, middleware) => {
        setLoading(true);
        console.log('updateFormSubmission input param: ', submission);

        // get old submission object to take ID for form submission updating
        // const oldSubmissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};

        const centreId = selectedFormRow.centre_id;

        // create new object with correct ID and new submission data
        const newSubmissionObject = {
            ...selectedFormObj,
            data: submission,
            centreId: centreId,
        };

        console.log('new submission object: ', newSubmissionObject);
        
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        try {
            const res = await axios.post(AWS_API + 'form-submissions/update', {postData: newSubmissionObject}, headers);
            console.log('response from postSubmission to S3 via form-submissions/udpate: ', res.data);
            
            if (!middleware) {
                
                // update data and show banner
                getAllPendingForms();
                handleBannerText(setBannerText, 'Form Submission Updated');
            }

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error while updating form submission');
        }
        
        setLoading(false);
    }

    // console.log('selected form is: ', selectedForm);


    // function to get submission json data from S3 for selected form
    const getFormSubmission = async (formObj) => {

        console.log('[getFormSubmission] - formObj: ', formObj);
        const formId = formObj.form_id;
        const formType = formObj.form_type.replace('pending-', '').replace('approved-', ''); // remove pending- prefix that may be added in view_pending_forms_obj to forms approved for pending enrolment but still needing centrelink submission
        const submittedBy = formObj.submitted_by;
        const centreId = formObj.centre_id;
        
        console.log('get form submission for id, type, submittedBy, centreId: ', formId, formType, submittedBy, centreId);
        
        setLoading(true);

        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        const postData = {ids: [formId], centreId: centreId, formType: formType, submittedBy: submittedBy};

        // get submission json data for selected form ID
        try {
            const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
            console.log('response from form-submissions/fetch: ', res.data);
            setSelectedFormObj(res.data.formSubmissions[0]);        
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
        }
        setLoading(false);

    }

    console.log('selectedFormObj: ', selectedFormObj);
    console.log('pendingForms: ', pendingForms);


    // initialise show form type to type with highest pending approvals
    useEffect(() => {

        if (pendingFormCounts) {

            // console.log('pendingFormCounts: ', pendingFormCounts);
            // filter out approved forms and default to type with most forms pending
            const mostPending = Object.keys(pendingFormCounts).filter(formType => !formType.includes('approved')).sort((a,b) => pendingFormCounts[b] - pendingFormCounts[a])?.[0];
            
            setShowFormType(prevFormType => prevFormType ? prevFormType : mostPending);

        }

    }, [pendingFormCounts])

    // temporarily removing direct debit fetch due to volume constraints
    // if pending enrolment forms present, call server to check accounts have signed up for direct debit
    // useEffect(() => {

    //     // if there are pending enrolment forms, get direct debit status
    //     // const pendingEnrolmentForms = pendingForms?.filter(obj => obj.form_type === 'enrol');
    //     if (pendingForms?.length > 0) {

    //         // fetch direct debit mandates for each of these emails
    //         const emails = pendingForms.map(obj => obj.submitted_by).filter((x, i, a) => a.indexOf(x) === i);
    //         // emails.push('kendall.macmillan@citras.io');
    //         console.log('getting direct debit status for pendingEnrolmentForms: ', emails);
    //         getMasterData('master-data/direct-debit-status', {emails: JSON.stringify(emails)}, setDirectDebitData);
    //         getMasterData('master-data/fetch', {table: 'view_alternative_billing_arrangements'}, setAlternativeBillingArrangementData);
    //     }

    // }, [pendingForms, getMasterData])


    // populate enrolment form with selected submission data if form has been selected
    let populatedForm = null;
    if (selectedFormObj && !loading) {
        // const submissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};
        // console.log('submissionObject: ', submissionObject);
        populatedForm = (
            <div>
                {
                (selectedFormObj.formType === 'enrol' || selectedFormObj.formType === 'adminEnrol' || selectedFormObj.formType === 'enrolNewChild' || selectedFormObj.formType === 'bulkEnrol') && 
                <EnrolConfigForm userInputFormData={userInputFormData} setUserInputFormData={setUserInputFormData} />
                }   
                <br/>
                <button className='btn btn-block btn-success' onClick={() => postMasterData(selectedFormObj)} >Approve Form</button>
                <hr />
                <Form 
                    src={formioAPI + (formioConfig?.[selectedFormObj.formType]?.useForm ?? selectedFormObj.formType)} 
                    submission={selectedFormObj}
                    onNextTab={scrollPageToTop}
                    onCustomSubmit={updateFormSubmission} 
                />
                <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>Note: This submit button will update the original form submission - Intended use is to correct any mistakes in user's form</p>
            </div>
        );
    }

    let content;
    if (pendingForms?.length > 0 && pendingFormCounts) {
        content = Object.keys(pendingFormCounts).filter(key => showFormType === key).map(key => { 
            
            // get forms of selected type
            const formsOfSelectedType = pendingForms.filter(obj => obj.form_type === key);

            // if a form has been selected, hide the others in the table to make viewing the form easier
            const filteredForms = selectedFormObj ? formsOfSelectedType.filter(obj => obj.form_id === selectedFormObj.formId) : formsOfSelectedType;
            console.log('[Approvals.js] - filteredForms: ', filteredForms);

            // get any added tags in form type
            const tags = filteredForms?.map(obj => obj?.tag)?.filter(el => el)?.filter((x,i,a) => a.indexOf(x) === i);
            console.log('tags: ', tags);

            const tagFilteredForms = filteredForms.filter(obj => obj?.tag === selectedTag || selectedTag === '*' || (selectedTag === 'no_tag' && (!obj?.tag || obj?.tag === '')));

            // get details for each form in current form type
            // filteredForms.forEach((obj) => {
            //     // set direct debit icon based on whether form submitter has signed up
            //     obj.direct_debit = <DirectDebitStatus email={obj.submitted_by} directDebitData={directDebitData} alternativeBillingArrangementData={alternativeBillingArrangementData}/>;
            // });

            // tag counts
            const tagCountsAll = filteredForms?.length;
            const tagLabelAll = `All (${tagCountsAll})`
            const tagCountsNone = filteredForms?.filter(obj => !obj?.tag || obj?.tag === '')?.length;
            const tagLabelNone = `New / Unsorted (${tagCountsNone})`


            // set tab config based on whether any tags exist
            let tabConfig = [{label: tagLabelAll, value: '*'}];
            if (tags?.length > 0) {
                tabConfig = [
                    
                    {label: tagLabelNone, value: 'no_tag'}, 
                ];     
                tags.forEach(tag => tabConfig.push({value: tag, label: `${humanize(tag)} (${filteredForms?.filter(obj => obj?.tag === tag)?.length})`}));
                tabConfig.push({label: tagLabelAll, value: '*'});
                // tabConfig.push({label: 'All', value: '*'});
            } else if (selectedTag !== '*') {
                // if no tags, set default to all
                setSelectedTag('*')
            }



            return (
                <Fragment>
                    <TertiaryNavbar tab={selectedTag} updateStateFn={setSelectedTag} config={tabConfig} />
                    <BSTable 
                        selected={selected} 
                        setSelected={setSelected} 
                        table={'view_forms_needing_approval'} 
                        data={tagFilteredForms} 
                        paginationConfig={{sizePerPage: 25}}
                        enableEdit
                        updateFunction={updateData}
                    />                     
                </Fragment>
            );
        });
    } else {
        content = <p><i>No new form approvals.</i></p>
    }

    const handleViewSelectedForm = (selected) => {
        if (selected?.length !== 1) {
            alert('Please select just one form');
            return false;
        }

        const row = pendingForms?.filter(obj => obj.form_id === selected[0])?.[0];
        if (!row) {
            alert('No form found for corresponding ID');
        } else {
            getFormSubmission(row); 
            setSelectedFormRow(row);
        }

    }

    const rejectForm = async () => {

        setLoading(true);
        
        if (selected?.length === 0) {
            alert('Please select at least one form to mark as rejected');
            return false;
        }  

        console.log('selected: ', selected);

        const postData = {
            formIds: selected,
        };

        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        try {
            const res = await axios.post(AWS_API + '/master-data/reject-forms', {postData: postData}, headers);
            console.log('postMasterData response: ', res.data);
            
            // update pending forms and recalculate notifications etc
            getAllPendingForms(); // this will trigger loading again, so don't setLoading = false in this path
            updateChildApprovalNotifications();
            setSelectedFormObj();
            setUserInputFormData({});
            setSelected([]);
            handleBannerText(setBannerText, 'Form rejected');
            // alert('enrolment approved!');

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error while writing master data, transaction aborted');
            setLoading(false);
        }              
    }

    let backdropSpinner;
    if (loadingCellUpdate) {
        backdropSpinner = <BackdropSpinner spinner='small-light' />;
    } 
    

    let approvalsContent = <div style={{paddingTop: '15vh'}}><SpinnerDark /></div>
    if (!loading) {

        // regular form approvals
        if (mode === 'forms') {
            
            approvalsContent = (
                <Aux>
                    <Prompt data={prompt} setData={setPrompt} />
                    <Modal show={error} modalClosed={() => setError(false)}>
                        <h3>Oops, something went wrong!</h3>
                        <hr/>
                        <p>{error}</p>
                    </Modal>
                    <Banner show={bannerText}>{bannerText}</Banner>  
                    {backdropSpinner}
    
                    {/* <div className={classes.Approvals}> */}
                        {/* <div style={{'position': 'absolute', 'right': 20, 'top': 20}}> */}
                        {pendingForms?.length > 0 && <Fragment>
                            <h3>Approvals - {raw?.[showFormType] ?? showFormType}</h3>
                            <div className={classes.HeaderButtonRowRight}>
                                <button className="btn btn-success btn-sm" onClick={() => handleViewSelectedForm(selected)}>View Selected</button>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setSelectedFormObj();}}>View All</button>
                                <button className="btn btn-danger btn-sm" onClick={() => handlePrompt('reject selected forms', rejectForm, setPrompt)}>Reject</button>
                            </div>                              
                            <hr />
                        </Fragment>}
                        {!showFormType && <p><i>Please select a form type from pending forms on the left</i></p>}
                        {content}
                        {/* {topDrawers} */}
                    {populatedForm}   
                    {/* </div>       */}
                                       
    
                </Aux>
            ); 
        } else if (mode === 'children') {
            approvalsContent = <CentreApprovals mode='child-approvals' token={props.token} updateNotifications={updateChildApprovalNotifications} />
        } else if (mode === 'bookings') {
            approvalsContent = <CentreApprovals mode='booking-approvals' token={props.token} updateNotifications={updateChildApprovalNotifications} />
        }
    }

    return (
        <div className={classes.Approvals}>
            {approvalsContent}
        </div>
    );
        
}

export default Approvals;