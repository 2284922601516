import {React, useState, useCallback, 
    // useEffect, 
    Fragment,
    useEffect} from 'react';
import classes from './Events.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import BSTable from '../../../../UI/BSTable/BSTable';
// import { bootstrapTableConfig } from '../../../../utilities/globalObjects';

// import Banner from '../../../../UI/Banner/Banner';

const Events = (props) => {

    // UI state
    // const [loadingServices, setLoadingServices] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    // const [bannerText, setBannerText] = useState('');      
    
    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);     

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [dataSource, setDataSource] = useState();
   
    // data state
    const [eventsData, setEventsData] = useState();
    const [detailData, setDetailData] = useState();
    // const [formData, setFormData] = useState();
    // const [supportingDocsFormData, setSupportingDocsFormData] = useState();
    // const [files, setFiles] = useState([]);
    // const [dataSource, setDataSource] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory();   
    const location = useLocation();
    
    // data from props
    const centresData = props.centres;

    // console logs
    console.log('eventsData: ', eventsData);
    console.log('dataSource: ', dataSource);
    
    // function to get data
    const getData = useCallback(async (storeDataFn, serviceCRN, routeToInvoke, additionalData) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: routeToInvoke, serviceCRN: serviceCRN, ...additionalData}
        };
        console.log('config.params: ', config.params);
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            storeDataFn(res?.data?.length > 0 ? res.data : [{'no data found': 'no data found'}]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        setLoading(false);


    }, [history, props, location]); 
  

    // function to save selected centre and get enrolments within
    const handleSelectCentre = (serviceCRN) => {
        setSelectedServiceCRN(serviceCRN);
        setDataSource('read-event-management');
        getData(setEventsData, serviceCRN, 'read-event-management');
        
    }    
    
    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? ''} onChange={(e) => handleSelectCentre(e.target.value)}>
            <option disabled value=''>Select Service/Provider</option>
            <option  value='provider'>Provider</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );
    
    const getDetailDataFromEventsData = useCallback(() => {
        
        // get unique events
        const eventIds = eventsData.map(obj => obj.eventID);
        
        // get some detail for each unique event
        const newDetailData = [];
        for (const id of eventIds) {
            const data = eventsData.filter(obj => obj.eventID === id)[0];

            // get special absences detail
            const specialAbsencesArray = data?.SpecialAbsences?.results;
            if (specialAbsencesArray?.length > 0) {
                
                for (const el of specialAbsencesArray) {
                    newDetailData.push({
                        eventID: id,
                        type: "Special Absence",
                        child: el.childFirstName + ' ' + el.childLastName,
                        specialAbsencesAvailable: el.specialAbsencesAvailable,
                        specialAbsencesConsumed: el.specialAbsencesConsumed
                    });
                }
            }
        }

        // set state
        setDetailData(newDetailData);

    },[eventsData]);

    // whenever eventsdata changes, take the inner data and create detailed data for table expansion
    useEffect(() => {
        if (eventsData) {
            getDetailDataFromEventsData();
        }
    }, [eventsData, getDetailDataFromEventsData])
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                {eventsData && 
                    <BSTable 
                    selected={selected} 
                    setSelected={setSelected} 
                    table={dataSource} 
                    data={eventsData} 
                    getDetailData={() => {return false}} 
                    detailData={(!detailData || detailData?.length === 0 ) ? [{'no drilldown data specified': 'no drilldown data specified'}] : detailData} 
                    expanded={expanded} 
                    setExpanded={setExpanded}
                    /> 
                }

            </Fragment>
        );
    }

    return (
        <Fragment>
            {/* <Banner show={bannerText}>{bannerText}</Banner>                 */}
            <div className={classes.Events}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <h3>Events</h3>
                {/* <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                    <button className="btn CustomButtonPrimary btn-sm" onClick={() => fetchAltPaymentArrangements()}>View Alternative Payment Arrangements Last 60 Days</button>
                </div>                 */}
                <hr />
                <p><i>Select a service to view events</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                {/* <div className={classes.Row}>
                    <button className="btn btn-warning btn-sm" onClick={() => setShowSupportingDocs(true)}>Upload Supporting Documents</button>
                </div>
                <hr /> */}
                {content}
            </div>
        </Fragment>
    )
}

export default Events;