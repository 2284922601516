import { Fragment, useEffect } from "react";
import { formioAPI } from "../../../../../utilities/globalVariables";
import { Form } from 'react-formio';
import { useState } from "react";
import Select from 'react-select'
import classes from './UpdateSchedule.module.css';

const UpdateSchedule = (props) => {

    // data state
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedCentre, setSelectedCentre] = useState();

    // get data from props
    const childrenData = props.childrenData;
    const centresData = props.centresData;
    const postSubmission = props.postSubmission;
    const alreadySelectedChildren = props.alreadySelectedChildren;

    // console
    console.log('[UpdateSchedule] - selectedChildren: ', selectedChildren);
    
    // create child options array
    let addChildrenForm;
    if (childrenData) {
        // create options for multi select
        const childOptions = [];
        childrenData.forEach(obj => {childOptions.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})})
        addChildrenForm = (
                <div> 
                    <div  className="form-group" >
                        <label className={classes.Label}>Select Children</label>
                        <Select isMulti options={childOptions} onChange={setSelectedChildren} value={selectedChildren} ></Select>    
                    </div>  
                </div>         
        );

    }    

    // upon component load, add already ticked children to selected children
    useEffect(() => {

        // pre-selected children
        if (alreadySelectedChildren && childrenData) {

            // get value and label for selectedChildren object
            const newSelectedChildren = [];
            const filteredChildrenData = childrenData.filter(obj => alreadySelectedChildren.includes(obj.child_id));
            // console.log('filteredChildrenData: ', filteredChildrenData);
            filteredChildrenData.forEach(obj => {newSelectedChildren.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})});
            setSelectedChildren(newSelectedChildren);
        }


    }, [alreadySelectedChildren, childrenData]);

    // if selectedChildren array has > 0 selected children, look for a shared home centre - otherwise remove home centre
    let selectedHomeCentreIds = null;
    const selectedChildIds = selectedChildren.map(obj => obj.value);
    if (selectedChildIds.length > 0) {
        selectedHomeCentreIds = childrenData.filter(obj => selectedChildIds.includes(obj.child_id)).map(obj => obj.home_centre_id).filter((x,i,a) => a.indexOf(x) === i);
        console.log('selectedHomeCentreIds: ', selectedHomeCentreIds);
        
        // if there's only unique centre id for all children, add it to lookup array to allow booking, otherwise user will have to select only children who share a home centre
        if (selectedHomeCentreIds.length === 1) {
            const homeCentreId = selectedHomeCentreIds[0];
            if (selectedCentre !== homeCentreId) setSelectedCentre(homeCentreId);
            
        } else {
            if (selectedCentre) setSelectedCentre('');
        }
    } else {
        if (selectedCentre) setSelectedCentre('');
    }    

    const clear = () => {
        setSelectedCentre();
        setSelectedChildren([]);
    }


    // function called when user tried to submit updateBookingSchedule form - checks validity to determine whether to post or not
    const checkFormValidity = (submission) => {
        console.log('checking form validity: ', submission);
        let isValid = true;
        let msg = 'Form invalid, if nothing is highlighted in red please refresh the page and try again';
        if (!submission.centreId || submission.centreId === "") {
            isValid = false;
            msg = 'No centre found - please ensure selected children have the same home centre'
        } 
        if (!submission.childIds || submission.childIds.length <= 0 ) {
            isValid = false;
            msg = 'No children found - please ensure you have selected 1 or more children'
        }

        if (!submission.startDate || !(Date.parse(submission.startDate)) || new Date(submission?.startDate).getTime() < new Date().getTime()) {
            isValid = false;
            msg = 'Please select a start date in the future'
            console.log('submission.startDate', submission.startDate);
            console.log('Date.parse(submission.startDate)', Date.parse(submission.startDate));
        }
        
        if (!isValid) {
            alert(msg);
            return false
        } else {
            console.log('form valid, posting now');
            postSubmission(submission, clear);
        }
    }
        
    console.log('selectedChildIds: ', selectedChildIds);

    // create array showing only child's home centre
    const centreOptions = centresData.filter(obj => obj.centre_id === selectedCentre).map(obj => {
        return (
            <option  value={obj.centre_id} key={obj.centre_id} >{obj.centre_name}</option>
        );
    });
    const centreSelector = <div className="form-group">
        <label>Centre</label>
        <select className="form-control" disabled id="centreOptions" value={selectedCentre} onChange={(e) => {setSelectedCentre(+e.target.value)}}>
            {centreOptions}
        </select>
    </div>    

    return (
        <Fragment>
            <div className="form-group">
                {addChildrenForm}
            </div>   
            {centreSelector}           
            <Form 
                src={formioAPI + 'updateBookingSchedule'} 
                submission={{data: {childIds: selectedChildIds, centreId: selectedCentre}}}
                // onChange={(submission) => console.log('change: ', submission)}
                onCustomSubmit={checkFormValidity} 
                onChange={(submission) => {console.log('submission: ', submission)}}
                // onError={() => console.log('error found')}
            />
        </Fragment>
    );
}

export default UpdateSchedule;