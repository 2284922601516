import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/oshcplus3-final-transparent.png';
import { envName } from '../../utilities/globalVariables';
import classes from '../Logo/Logo.module.css';

const logo = (props) => (
    <NavLink to="/">
        <div className={classes.Logo}>
                <img src={Logo} alt="ao-logo"/>
                {['dev', 'test'].includes(envName) && <div className={classes.EnvWarning}>{envName.toUpperCase()}</div>}
                {/* <h6>&nbsp;&nbsp;OSHC+</h6>   */}
        </div>
            
    </NavLink>
);

export default logo;