import {React, useState, useCallback, 
    // useEffect, 
    Fragment,
    useEffect} from 'react';
import classes from './CentreApprovals.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import BSTable from '../../../../UI/BSTable/BSTable';

import Banner from '../../../../UI/Banner/Banner';

const CentreApprovals = (props) => {

    
    
    // UI state
    // const [loadingServices, setLoadingServices] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');      
    
    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);     
    
    // user input state
    // const [selectedCentreId, setSelectedCentreId] = useState();

    // data state
    const [data, setData] = useState();
    const [detailData, setDetailData] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory(); 
    const location = useLocation();  
    
    // data from props
    const updateNotifications = props.updateNotifications;
    const mode = props.mode;
    
    
    // set constants based on mode requested
    let title, dataSource, postApprovalRoute;
    if (mode === 'child-approvals') {
        title = 'Child Approvals';
        dataSource = 'view_child_approvals';
        postApprovalRoute = 'master-data/record-child-approval';
    } else if (mode === 'booking-approvals') {
        title = 'Booking Approvals';
        dataSource = 'view_bookings_pending_approval';
        postApprovalRoute = 'master-data/record-booking-approval';

    }
    
    // const centresData = props.centres;

    // console logs
    console.log('data: ', data);
    // console.log('selectedCentreId: ', selectedCentreId);
    console.log('expanded: ', expanded);
    
    // function to get data
    const getData = useCallback(async (params, storeDataFn, noLoadStart, noLoadEnd) => {
        
        !noLoadStart ? setLoading(true) : void(0);
        
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: params
            // params: {table: tableName}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            storeDataFn(res?.data?.length > 0 ? res.data : [{'no data found': 'no data found'}]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            
        
        !noLoadEnd ? setLoading(false) : void(0);
        

    }, [history, props, location]); 

    // function to submit approval event
    const submitApproval = async (approvalStatus) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            ids: selected,
            approvalStatus: approvalStatus
        };

        try {
            const res = await axios.post(AWS_API + postApprovalRoute, {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `${approvalStatus} recorded`);
            getData({table: dataSource}, setData);
            updateNotifications();
            setSelected([]);    
            // getData({table: 'child_approvals', conditionCol: 'centre_id', conditionValue: selectedCentreId}, setData);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording approval event ' + approvalStatus);
            console.log('error whilst recording roll event: ', err);
            setLoading(false);
        }    
    }      

    // function to save selected centre and get enrolments within
    // const handleSelectCentre = (centreId) => {
    //     setSelectedCentreId(centreId);
    //     setDataSource('child_approvals');
    //     const params =  {table: 'child_approvals', conditionCol: 'centre_id', conditionValue: centreId}
    //     getData(params, setData);
        
    // }    
    
    // get data on component load
    useEffect(() => {
        const params =  {table: dataSource}
        getData(params, setData);

    }, [getData, dataSource])

    // function to get drilldown data to display upon clicking a row in table
    const getDetailData = (idKey, id) => {
        console.log('id: ', id);

        // get child id for whom to get detail data

        const childId = data.filter(obj => obj[idKey] === id)[0].child_id;
        console.log('childId to get dt dta: ', childId);

        // get detail data
        const params =  {table: 'view_centre_coord_child_details', conditionCol: 'child_id', conditionValue: childId}
        getData(params, setDetailData, true, true);
        
    }
    
    const clearDetailData = () => {
        setDetailData();
    }

    const dynamicClassFunction = (cell, row, rowIndex, colIndex) => {
        // console.log('rowIndex: ', rowIndex);
        // console.log('colIndex: ', colIndex);
        if (cell === 'approved') {
            return 'global-goodToGo'
        } else if (cell === 'rejected') {
            return 'global-danger'
        }
    }    

    // render service choices
    // const services = (
    //     <select className='form-control' value={selectedCentreId ?? ''} onChange={(e) => handleSelectCentre(e.target.value)}>
    //         <option disabled value=''>Select Centre</option>
    //         {centresData.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
    //     </select>        
    // );  
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                {data && 
                    <BSTable 
                    selected={selected} 
                    setSelected={setSelected} 
                    dynamicClassFunction={dynamicClassFunction} 
                    table={dataSource} 
                    data={data} 
                    getDetailData={getDetailData} 
                    detailData={detailData} 
                    detailTable='view_centre_coord_child_details'
                    expanded={expanded} 
                    setExpanded={setExpanded}
                    clearDetailData={clearDetailData}
                    paginationConfig={{sizePerPage: 25}}
                    /> 
                }

            </Fragment>
        );
    }

    
    return (
        <Fragment>
            <Banner show={bannerText}>{bannerText}</Banner>                
            <div className={classes.CentreApprovals}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <h3>{title}</h3>
                {/* <div style={{'position': 'absolute', 'right': 0, 'top': 0}}> */}
                <div className={classes.HeaderButtonRow}>
                    <button className="btn btn-success btn-sm" onClick={() => submitApproval('approved')}>Approve</button>
                    <button className="btn btn-danger btn-sm" onClick={() => submitApproval('rejected')}>Reject</button>
                </div>                
                <hr />
                {/* <p><i>Select a service to view pending approvals</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr /> */}
                {content}
            </div>
        </Fragment>
    )
}

export default CentreApprovals;