import {React, useState, useCallback, 
    // useEffect, 
    Fragment} from 'react';
import classes from './ReturnFees.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError,
    //  handleBannerText 
    } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
// import Banner from '../../../../UI/Banner/Banner';
import BSTable from '../../../../UI/BSTable/BSTable';

const ReturnFees = (props) => {

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    // const [bannerText, setBannerText] = useState('');           
    
    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    
    // data state
    const [returnFeesData, setReturnFeesData] = useState();

    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);          
    
    
    // react router history instance to navigate user to other pages
    let history = useHistory();       
    const location = useLocation();

    // console logs
    console.log('selectedServiceCRN: ', selectedServiceCRN);
    console.log('returnFeesData: ', returnFeesData);


    // get data from props
    const centresData = props.centres;    

    const getReturnFeesData = useCallback(async (serviceCRN) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: 'read-return-fee-reduction', serviceCRN: serviceCRN}
        };
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setReturnFeesData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        setLoading(false);

    }, [history, props, location]);    

    // function to save selected centre and get enrolments within
    const handleSelectCentre = (serviceCRN) => {
        setSelectedServiceCRN(serviceCRN);
        getReturnFeesData(serviceCRN);

    }

    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? ''} onChange={(e) => handleSelectCentre(e.target.value)}>
            <option disabled value=''>Select Centre</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );
    

    let content = <SpinnerDark/>;
    if (!loading) {
        content = (
            <Fragment>
                {returnFeesData && 
                    <BSTable 
                    selected={selected} 
                    setSelected={setSelected} 
                    table={'read-return-fee-reduction'} 
                    data={returnFeesData} 
                    getDetailData={() => {return false}} 
                    detailData={[{'no drilldown data specified': 'no drilldown data specified'}]} 
                    expanded={expanded} 
                    setExpanded={setExpanded}
                    /> 
                }

            </Fragment>
        );
    }


    return (
        <Fragment>
            {/* <Banner show={bannerText}>{bannerText}</Banner>                 */}
            <div className={classes.ReturnFees}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>       
                <h3>Return Fees</h3>
                <hr />
                <p><i>Select a service to view return fee reductions processed</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                {content}
            </div>
        </Fragment>
    )
}

export default ReturnFees;