import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import { humanize } from '../../../utilities/functions';

const navigationItems = (props) => {

    let roleSpecificLinks = null;
    let infoItems = null;
    let logout = null;
    
    // rename logout on kiosk to stop parents clicking it all the time
    let logoutName = 'Logout';
    if (props.role === 'kiosk') logoutName = 'Admin Only'
  
    if (['director'].includes(props.role)) {
      roleSpecificLinks = (
        <Aux>
          <NavigationItem link="/filing">Cabinet</NavigationItem>
          {/* <NavigationItem link="/forms">Forms</NavigationItem> */}
          <NavigationItem link="/roll">Roll</NavigationItem>
          <NavigationItem link="/approvals">Approval Centre</NavigationItem>
          <NavigationItem link="/centrelink">Billing</NavigationItem>
          <NavigationItem link="/masterData">Data</NavigationItem>
          <NavigationItem link="/mgmt">Admin</NavigationItem>
          <NavigationItem link="/communications">Communications</NavigationItem>
        </Aux>
      );
    } else if (props.role === 'headOffice') {
        roleSpecificLinks = (
          <Aux>
            <NavigationItem link="/filing">Cabinet</NavigationItem>
            <NavigationItem link="/roll">Roll</NavigationItem>
            <NavigationItem link="/approvals">Approval Centre</NavigationItem>
            <NavigationItem link="/masterData">Data</NavigationItem>
          </Aux>
        );

        
    } else if (props.role === 'centreAdmin') {
        roleSpecificLinks = (
            <Aux>
          {/* <NavigationItem link="/approvals">Approvals</NavigationItem> */}
          <NavigationItem link="/filing">Cabinet</NavigationItem>
          <NavigationItem link="/centreMgmt">Centre MGMT</NavigationItem>
          <NavigationItem link="/roll">Roll</NavigationItem>
          <NavigationItem link="/masterData">Data</NavigationItem>
        </Aux>
      );    
    } else if (props.role === 'accountOwner') {
        roleSpecificLinks = (
            <Aux>
          <NavigationItem link="/account/calendar">Home</NavigationItem>
          <NavigationItem link="/enrol">New Enrolment</NavigationItem>
          <NavigationItem link="/preferences">My Preferences</NavigationItem>
          <NavigationItem link="/account/contact-us">Contact Us</NavigationItem>
          {/* <NavigationItem link="/checkin">Check-in</NavigationItem> */}
        </Aux>
      );    
    } else if (props.role === 'kiosk') {
      roleSpecificLinks = (
        <Aux>
          <NavigationItem link="/">Home</NavigationItem>
        </Aux>
      );    
    }
  
    if (props.role) {
      infoItems = (
        <Aux>
          <NavigationItem infoOnly>{humanize(props.role)}</NavigationItem>
          <NavigationItem infoOnly >{props.email}</NavigationItem>
          
        </Aux>
      );

      logout = <NavigationItem  infoOnly clicked={props.signout}>{logoutName}</NavigationItem>;
    }

    return (
    <ul className={classes.NavigationItems}>
          {infoItems}
          {roleSpecificLinks}
          {logout}

    </ul>

    );

}

export default navigationItems;