import {React, useState, useCallback, 
    // useEffect, 
    Fragment} from 'react';
import classes from './Personnel.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import { ccsMimeTypeTranslation } from '../../../../utilities/globalObjects';
import PersonnelForm from './PersonnelForm/PersonnelForm';
import SupportingEvidence from '../SupportingEvidence/SupportingEvidence';

const Personnel = (props) => {

    // UI state
    // const [loadingServices, setLoadingServices] = useState(false);
    const [loadingPersonnel, setLoadingPersonnel] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    // const [editing, setEditing] = useState();
    const [showForm, setShowForm] = useState();

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [addPersonData, setAddPersonData] = useState();
    const [updatePersonData, setUpdatePersonData] = useState();
    const [files, setFiles] = useState();
    const [selectedDocType, setSelectedDocType] = useState();
    const [editingProfile, setEditingProfile] = useState();

    // supporting evidence API call state
    const [showSupportingEvidenceFormFor, setShowSupportingEvidenceFormFor] = useState();

    // data state
    // const [centresData, setCentresData] = useState([]);
    const [personnelData, setPersonnelData] = useState([]);
    
    // react router history instance to navigate user to other pages
    let history = useHistory();   
    const location = useLocation();
    
    // data from props
    const centresData = props.centres;

    // console logs
    console.log('selectedServiceCRN: ', selectedServiceCRN);
    console.log('personnelData: ', personnelData);
    console.log('addPersonData: ', addPersonData);
    console.log('files: ', files);
    

    // const getCentresData = useCallback(async () => {
        
    //     setLoadingServices(true);
    
    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: {table: 'centres'}
    //     };
        
    //     // call lambda function to get master data
    //     try {
    //         const res = await axios.get(AWS_API + 'master-data/fetch', config);
    //         console.log('data fetch res: ', res.data);
    //         setCentresData(res.data.filter(obj => obj.service_crn));
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //     }            

    //     setLoadingServices(false);


    // }, [history, props]); 
    
    // function to get personnel data
    const getPersonnelData = useCallback(async (serviceCRN, personId) => {
        
        setLoadingPersonnel(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {serviceCRN: serviceCRN, personId: personId} // read-personnel CCS endpoint needs either provider or service but not both
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/read-personnel', config);
            console.log('data fetch res: ', res.data);
            setPersonnelData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoadingPersonnel(false);


    }, [history, props, location]); 

    const submitAddPerson = async (formData, mode) => {

        setShowForm(false);
        setLoadingPersonnel(true);

        console.log('addPersonnel API call mode: ', mode);
        console.log('submitting formData: ', formData);

        // handle documents
        const documents = [];
        if (files) {
            for (const documentType of Object.keys(files)) {
                const file = files[documentType];
                const fileContent = await getBase64(file);
                const fileContentNoHeader = fileContent.split(',')[1];
                documents.push({documentType: documentType, MIMEType: ccsMimeTypeTranslation[file.type], fileName: file.name, fileContent: fileContentNoHeader})
            }
        } 
        // const base64File = await getBase64(files.policeCheck);
        console.log('documents: ', documents);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            personnelData: formData,
            documents: documents,
            mode: mode,
        };

        console.log('postData: ', postData);

        try {
            const res = await axios.post(AWS_API + 'ccs/add-personnel', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Personnel Action Successful`);
            
            // clean up state
            setUpdatePersonData();
            setAddPersonData();
            setFiles();
            setSelectedDocType();

            // fetch newly updated data
            getPersonnelData(selectedServiceCRN);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while adding personnel');
            setLoadingPersonnel(false);
        }            
    }    

    
    // function to save selected centre and get enrolments within
    const handleSelectCentre = (serviceCRN) => {
        setSelectedServiceCRN(serviceCRN);
        getPersonnelData(serviceCRN);
    }    
    
    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? 'default'} onChange={(e) => handleSelectCentre(e.target.value)}>
            <option disabled value='default'>Select Service/Provider</option>
            <option  value='provider'>Provider</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );   
    
    // get service profile data when service selected
    // useEffect(() => {
    //     getPersonnelData();
    // }, [selectedServiceCRN, getPersonnelData])    
    // // render service choices
    // const services = centresData.map(obj => {
    //     return (
    //         <button key={obj.service_crn} className="btn btn-sm btn-secondary" onClick={() => setSelectedServiceCRN(obj.service_crn)}>Service - {obj.centre_name}</button>
    //     );
    // });
    // services.unshift(<button className="btn btn-sm btn-dark" onClick={() => setSelectedServiceCRN()}>Provider</button>)



    const handleEditProfile = (personId) => {
        // update editing profile state to show which personnel record we want to edit
        setEditingProfile(personId);

        // populate form data with this users details to edit
        const personData = personnelData.filter(obj => obj.personID === personId)[0];
        const fieldsToKeep = ['personID', 'dateOfBirth', 'phone', 'email', 'firstName', 'lastName'];
        const newUpdatePersonData = Object.fromEntries(Object.entries(personData).filter(([key]) => fieldsToKeep.includes(key)));
        
        // roles
        newUpdatePersonData.role = personData.Roles.results[0].type;
        newUpdatePersonData.position = personData.Roles.results[0].position;
        newUpdatePersonData.startDate = personData.Roles.results[0].startDate;
        newUpdatePersonData.service = personData?.serviceID;
        
        // wwcc
        newUpdatePersonData.wwccCardNumber = personData?.WWCC?.results[0]?.cardNumber;
        newUpdatePersonData.wwccExpiryDate = personData?.WWCC?.results[0]?.expiryDate;
        newUpdatePersonData.wwccIssuingState = personData?.WWCC?.results[0]?.issuingState;


        setUpdatePersonData(newUpdatePersonData);
        setFiles();
        setSelectedDocType();
        setShowForm(true);
    }

    let personnel = <SpinnerDark />;
    if (!loadingPersonnel) {
        // personnel = <h5>personnel</h5>
        personnel = personnelData.map(obj => {

            

            return (
            <div className={classes.Person}>
                    <h6>Personal Details</h6>
                    <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                        <button className="btn btn-light btn" onClick={() => setShowSupportingEvidenceFormFor(obj.personID)}>Upload Supporting Evidence</button>
                        <button className="btn btn-dark btn" onClick={() => getPersonnelData(selectedServiceCRN, obj.personID)}>Query User</button>
                        <button className="btn CustomButtonPrimary btn" onClick={() => handleEditProfile(obj.personID)}>Edit Profile</button>
                    </div>
                    <div className={classes.Row}> <span className={classes.Item}>Person ID</span> <div className={classes.Item}>{obj.personID}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>First Name</span> <div className={classes.Item}>{obj.firstName}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Last Name</span> <div className={classes.Item}>{obj.lastName}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Date of Birth</span> <div className={classes.Item}>{obj.dateOfBirth}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Phone</span> <div className={classes.Item}>{obj.phone}</div></div>
                    <div className={classes.Row}> <span className={classes.Item}>Email</span> <div className={classes.Item}>{obj.email}</div></div>
                    <br/>
                    <hr />
                    {/* <div className={classes.Row}> <span className={classes.Item}>Roles in Selected Provider/Service</span> </div> */}
                    <h6>Roles in Selected Provider/Service</h6>
                    {
                        obj?.Roles?.results?.map(el => {
                            return (
                                <Fragment>
                                    
                                    <div className={classes.Row}><span className={classes.Item}>Start Date</span> <div className={classes.Item}>{el.startDate}</div></div>
                                    <div className={classes.Row}><span className={classes.Item}>Role</span> <div className={classes.Item}>{el.type}</div></div>
                                    <div className={classes.Row}><span className={classes.Item}>Position</span> <div className={classes.Item}>{el.positionText}</div></div>
                                </Fragment>
                            );  
                        })
                    }
                    <br/>
                    <hr />
                    {/* <div className={classes.Row}> <span className={classes.Item}>WWCC Details</span> </div> */}
                    <h6>WWCC Details</h6>
                    {/* <span className={classes.Item}>Provider Roles</span> */}
                    {
                        obj?.WWCC?.results?.map(el => {
                            return (
                                <Fragment>
                                    
                                    <div className={classes.Row}><span className={classes.Item}>Card Number</span> <div className={classes.Item}>{el.cardNumber}</div></div>
                                    <div className={classes.Row}><span className={classes.Item}>Issuing State</span> <div className={classes.Item}>{el.issuingState}</div></div>
                                    <div className={classes.Row}><span className={classes.Item}>Expiry Date</span> <div className={classes.Item}>{el.expiryDate}</div></div>
                                </Fragment>
                            );  
                        })
                    }

            </div>
            );
        });
    }



    const handleUpload = (target, fileType) => {
        if (target.files) {
          if (target.files.length !== 0) {
            const newFile = target.files[0];
            setFiles({
                ...files, 
                [fileType]: newFile
            });
          }
        }
    };    




    function getBase64(file) {
        return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }   
    
    const handleAddPersonnel = () => {
        setEditingProfile();
        setShowForm(true);
    }

    const onSupportingEvidenceSuccess = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setShowSupportingEvidenceFormFor();
        handleBannerText(setBannerText, 'Supporting evidence successfully uploaded');
        
    }
    const onSupportingEvidencFailure = (err) => {
        setShowSupportingEvidenceFormFor();
        setError(handleAwsApiError(err, history, location) ?? 'Error encountered while uploading documents');
    }
    
    return (
        <Fragment>
            <Banner show={bannerText}>{bannerText}</Banner>                
            <div className={classes.Personnel}>
                <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                    <button className="btn btn-success btn-sm" onClick={handleAddPersonnel}>Add Personnel</button>
                </div>
                <Modal show={showForm} modalClosed={() => setShowForm(false)} large>
                    
                    <PersonnelForm 
                        formData={editingProfile ? updatePersonData : addPersonData}
                        setFormData={editingProfile ? setUpdatePersonData : setAddPersonData}
                        centresData={centresData} 
                        mode={editingProfile ? 'update' : 'add'}
                        submitForm={submitAddPerson}
                        selectedDocType={selectedDocType}
                        setSelectedDocType={setSelectedDocType}
                        handleUpload={handleUpload}
                        />
                </Modal>    
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <Modal show={showSupportingEvidenceFormFor} modalClosed={() => setShowSupportingEvidenceFormFor(false)}>
                    {showSupportingEvidenceFormFor && <SupportingEvidence token={props.token} onSuccess={onSupportingEvidenceSuccess} onFailure={onSupportingEvidencFailure} documentsFor='person' id={showSupportingEvidenceFormFor}/>}
                </Modal>    

                <h3>Personnel</h3>
                <hr />
                <p><i>Select a service to filter personnel</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                {personnel}

            </div>
        </Fragment>
    )
}

export default Personnel;