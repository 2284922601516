import {React, useState, useCallback, 
    // useEffect, 
    Fragment,
    useEffect} from 'react';
import classes from './Settings.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId, public_s3_url} from '../../../../utilities/globalVariables';
import { handleAwsApiError, updateArrayOfObjectsInState,
     handleBannerText, 
     handlePrompt,
     humanize
    } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import SettingsInput from '../../../../UI/SettingsInput/SettingsInput';
import Banner from '../../../../UI/Banner/Banner';
import { raw } from '../../../../translations/en/raw';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import Select from 'react-select'
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import {MdDeleteForever} from "react-icons/md";
import Prompt from '../../../../UI/Prompt/Prompt';
 
const Settings = (props) => {

    // UI state
    const [loadingVariables, setLoadingVariables] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    const [prompt, setPrompt] = useState({});     
    
    // user input state
    const [selectedCentreId, setSelectedCentreId] = useState();
    // const [selectedBusId, setSelectedBusId] = useState();
    const [selectedBusListId, setSelectedBusListId] = useState();
    const [addingSchool, setAddingSchool] = useState();
    const [addingNewBusList, setAddingNewBusList] = useState();
    const [addingNewCentre, setAddingNewCentre] = useState();
    const [selectedSchoolObj, setSelectedSchoolObj] = useState();
    const [newBusListFormData, setNewBusListFormData] = useState();
    const [newCentreFormData, setNewCentreFormData] = useState();
    
    // data state
    const [capacityData, setCapacityData] = useState([]);
    const [busListSessionConfigData, setBusListSessionConfigData] = useState([]);
    const [variablesData, setVariablesData] = useState([]);
    const [busListsData, setBusListsData] = useState([]);
    const [busListSchoolsData, setBusListSchoolsData] = useState([]);
    const [sessionHoursType, setSessionHoursType] = useState('ccs');
    const [sessionHoursData, setSessionHoursData] = useState([]);
    const [centreSessionHoursObj, setCentreSessionHoursObj] = useState([]);

    // ref data state
    const [allSchoolsData, setAllSchoolsData] = useState();    

    
    // react router history instance to navigate user to other pages
    let history = useHistory();  
    const location = useLocation();     

    // console logs
    // console.log('selectedCentreId: ', selectedCentreId);
    console.log('sessionHoursType: ', sessionHoursType);
    console.log('centreSessionHoursObj: ', centreSessionHoursObj);
    // console.log('selectedBusId: ', selectedBusId);
    // console.log('sessionHoursData: ', sessionHoursData);
    // console.log('variablesData: ', variablesData);
    // console.log('capacityData: ', capacityData);
    // console.log('busListSessionConfigData: ', busListSessionConfigData);
    // console.log('schoolNameData: ', schoolNameData);
    // console.log('allSchoolsData: ', allSchoolsData);
    // console.log('selectedSchoolObj: ', selectedSchoolObj);
    // console.log('newCentreFormData: ', newCentreFormData);
    
    
    // get data from props
    const centresData = props.centres;   
    const getCentresData = props.getCentresData;
    // const allCentresAndBuses = props.allCentresAndBuses;   
    // console.log('centresData: ', centresData);
    // console.log('allCentresAndBuses: ', allCentresAndBuses);

    // get school id - name mappings from public s3 bucket
    useEffect(() => {
        const fetchSchoolNameData = async (ids) => {
            try {
                const res = await axios.get(public_s3_url + 'schools.json');
                setAllSchoolsData(res.data);
            } catch (err) {
                console.log('error while fetching school names: ', err);
            }           
        }

        fetchSchoolNameData();

    }, [busListSchoolsData])    


    const getData = useCallback(async (params, storeDataFn, loadingFn) => {
        
        loadingFn ? loadingFn(true) : void(0);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: params
            // params: {table: tableName}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            // storeDataFn(res?.data?.length > 0 ? res.data : [{'no data found': 'no data found'}]);
            storeDataFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        loadingFn ? loadingFn(false) : void(0);


    }, [history, props, location]);   

    // get provider settings data on function load
    useEffect(() => {

        const params =  {table: 'variables'}
        getData(params, setVariablesData, setLoadingVariables);

        // set data source based on type of hours being updated
        const dataSource = (sessionHoursType === 'ccs') ? 'view_pricing_session_hours' : 'view_pricing_session_operational_hours';
        getData({table: dataSource}, setSessionHoursData);

    }, [getData, sessionHoursType]);

    // centre change use effect actions
    useEffect(() => {

        if (selectedCentreId) {
            
            // put session hours data into state for selected centre
            // const centreSessionHoursData = sessionHoursData?.filter(obj => obj.centre_id === 0 || obj.centre_id === +centreId);
            const newCentreSessionHoursObj = {
                'current': {},
                'next': {}
            };
            
            // add current session hour values
            const sessions = ['bsc', 'asc', 'vac'];
            for (const session of sessions) {
                const specificCurrent = sessionHoursData?.filter(obj => obj.active_flag && obj?.centre_id === +selectedCentreId && obj.session === session)?.[0];
                const defaultCurrent = sessionHoursData?.filter(obj => obj.active_flag && obj?.centre_id === 0 && obj.session === session)?.[0];
                const recordToUse = specificCurrent ?? defaultCurrent;
                newCentreSessionHoursObj.current[session] = {
                    startTime: recordToUse?.start_time,
                    endTime: recordToUse?.end_time,
                    startDate: recordToUse?.start_date?.substring(0,10),
                    endDate: recordToUse?.end_date?.substring(0,10),
                }
            }
            
            // add next session hour values
            for (const session of sessions) {
                const specificNext = sessionHoursData?.filter(obj => !obj.active_flag && obj?.centre_id === +selectedCentreId && obj.session === session)?.[0];
                const defaultNext = sessionHoursData?.filter(obj => !obj.active_flag && obj?.centre_id === 0 && obj.session === session)?.[0];
                const recordToUse = specificNext ?? defaultNext;
                // console.log(`session ${session}, specificNext ${specificNext}`)
                newCentreSessionHoursObj.next[session] = {
                    startTime: recordToUse?.start_time,
                    endTime: recordToUse?.end_time,
                    startDate: recordToUse?.start_date?.substring(0,10),
                    endDate: recordToUse?.end_date?.substring(0,10),
                }
                // console.log('newCentreSessionHoursObj.next: ', newCentreSessionHoursObj.next);
            }
    
            setCentreSessionHoursObj(newCentreSessionHoursObj);
        }

    }, [selectedCentreId, sessionHoursType, sessionHoursData])

    // function to save selected centre and get enrolments within
    const handleSelectCentre = (centreId) => {

        // reset selected data settings
        // setSelectedBusId();
        setSelectedBusListId();

        // get bus ID
        // const centreName = centresData.filter(obj => +obj.centre_id === +centreId && obj.location_type === 'centre')[0]?.centre_name;
        // const busId = allCentresAndBuses.filter(obj => obj.centre_name === centreName && obj.location_type === 'bus')[0]?.centre_id;
        
        // store centre and bus IDs
        setSelectedCentreId(centreId);
        // setSelectedBusId(busId);

        getData({table: 'centre_capacities', conditionCol: 'centre_id', conditionValue: centreId}, setCapacityData, setLoading);
        
        // get bus lists and bus school mappings
        getData({table: 'bus_lists', conditionCol: 'centre_id',  conditionValue: centreId}, setBusListsData, setLoading);
        getData({table: 'view_bus_list_schools_map', conditionCol: 'centre_id',  conditionValue: centreId}, setBusListSchoolsData, setLoading);



    }
    
    // run whenever selected bus list ID changes
    useEffect(() => {
        
        if (selectedBusListId) {
            getData({table: 'view_bus_list_session_config', conditionCols: JSON.stringify(['centre_id', 'bus_list_id']), conditionValues: JSON.stringify([selectedCentreId, selectedBusListId])}, setBusListSessionConfigData, setLoading);        
        }

    }, [selectedBusListId, selectedCentreId, getData])

    const handleUpdateSettings = async (table, rows, updateCol, loadingFn) => {

        loadingFn(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            table: table,
            rows: rows,
            updateCol: updateCol
        };

        try {
            const res = await axios.post(AWS_API + 'master-data/update-multiple-rows', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Settings Updated Successfully`);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
            console.log('error whilst recording roll event: ', err);
        }    
        loadingFn(false);
    }

    const postMasterData = async (route, postData, onSuccessFn, onErrorFn) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        
        try {
            const res = await axios.post(AWS_API + route, postData, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            handleBannerText(setBannerText, `Request Completed Successfully`);
            
            
        } catch (err) {
            onErrorFn ? onErrorFn() : void(0);
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoading(false);
    }         



    // render service choices
    const services = (
        <select className='form-control' value={selectedCentreId ?? ''} onChange={(e) => handleSelectCentre(e.target.value)}>
            <option disabled value=''>Select Centre</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
        </select>        
    );

    // render provider level settings
    let providerSettings = <SpinnerDark />;
    if (!loadingVariables) {
        providerSettings = (
            <Fragment>
                {variablesData.map(obj => {
                    // console.log(`value = ${obj.value}, type of value = ${typeof obj.value}`);
                    // optional disabled for now
                    const disabled = (obj.variable === 'timezone');
                    return <SettingsInput key={obj.variable} disabled={disabled} type="text" label={raw[obj.variable] ?? obj.variable} value={obj.value} changed={(updateValue) => updateArrayOfObjectsInState(updateValue, 'value', ['variable'], [obj.variable], variablesData, setVariablesData )}/>
                })}
                <button className='btn btn-success' onClick={() => handleUpdateSettings('variables', variablesData, 'value', setLoadingVariables)}>Update Settings</button>

            </Fragment>
        );
        
    }
    
    // render service level settings
    let centreCapacityForm;
    if (capacityData?.length > 0) {
        centreCapacityForm = (
            <div className={classes.ServiceSettingsSubContainer}>
                <h5>Session Capacity</h5>
                <hr/>                
                {capacityData.map(obj => {
                    // console.log(`value = ${obj.value}, type of value = ${typeof obj.value}`);
                    return <SettingsInput key={obj.session} type="text" label={raw[obj.session]} value={obj.max_capacity} changed={(updateValue) => updateArrayOfObjectsInState(updateValue, 'max_capacity', ['session'], [obj.session], capacityData, setCapacityData)}/>
                })}
                {capacityData.length > 0 && <button className='btn btn-success' onClick={() => handleUpdateSettings('centre_capacities', capacityData, 'max_capacity', setLoading)}>Update Settings</button>}

            </div>
        );
    }

    let busCapacityForm;
    if (busListSessionConfigData?.length > 0) {
        busCapacityForm = (
            <div>
                <br/>
                <h6>Session Config</h6>
                <hr/>    
                <span><b>Operations</b></span>            
                {busListSessionConfigData.map(obj => {
                    console.log(`value = ${obj.operational}, type of value = ${typeof obj.operational}`);
                    return <SettingsInput key={obj.session} type="checkbox" label={raw[obj.session]} value={obj.operational} changed={(updateValue) => updateArrayOfObjectsInState(updateValue, 'operational', ['session'], [obj.session], busListSessionConfigData, setBusListSessionConfigData)}/>
                })}
                <span><b>Capacity</b></span>      
                {busListSessionConfigData.map(obj => {
                    // console.log(`value = ${obj.value}, type of value = ${typeof obj.value}`);
                    return <SettingsInput key={obj.session} type="text" label={raw[obj.session]} value={obj.max_capacity} changed={(updateValue) => updateArrayOfObjectsInState(updateValue, 'max_capacity', ['session'], [obj.session], busListSessionConfigData, setBusListSessionConfigData)}/>
                })}
                <button className='btn btn-success btn-block' onClick={() => handleEditBusLists({method: 'update-bus-list-session-config', updatedRows: busListSessionConfigData})}>Update Capacity and Operations</button>

            </div>
        );
        
    }

    const handleUpdateSessionHours = (currentOrNext, session, key, value) => {
        const newCentreSessionHoursObj = {
            ...centreSessionHoursObj,
            [currentOrNext]: {
                ...centreSessionHoursObj[currentOrNext],
                [session]: {
                    ...centreSessionHoursObj[currentOrNext][session],
                    [key]: value
                }
            }
        }
        setCentreSessionHoursObj(newCentreSessionHoursObj);
    }

    let centreSessionHoursForm;
    if (centreSessionHoursObj) {
        centreSessionHoursForm = (
            <div className={classes.ServiceSettingsSubContainer}>
                <h5>Session Hours</h5>
                <hr/>                
                <div className={classes.HeaderButtonRowRight}>
                    <select style={{width: '200px'}} className='form-control' onChange={(e) => setSessionHoursType(e.target.value)}>
                        <option value='ccs'>CCS</option>
                        <option value='operational'>Operational</option>
                    </select>
                </div>
                {Object.keys(centreSessionHoursObj).map(scheduleType => {
                    const sessionsObj = centreSessionHoursObj[scheduleType];
                    return (
                        <div className={classes.ServiceSettingsSubSubContainer}>
                            <h6>{humanize(scheduleType)} Hours</h6>
                            {Object.keys(sessionsObj).map(session => {
                                const sessionConfig = sessionsObj[session];
                                return (
                                    <Fragment>
                                        <div className={classes.Subtitle}>{raw[session]}</div>
                                        <div className={['form-group', classes.Row].join(' ')}>
                                            {Object.keys(sessionConfig).map(item => {
                                                return (
                                                    <div>
                                                    <label className={classes.Label}>{humanize(item)}</label>
                                                    <input className='form-control' value={sessionConfig[item] ?? ''} onChange={(e) => handleUpdateSessionHours(scheduleType, session, item, e.target.value)}/>
                                                </div>
                                                )
                                            })}
                                        </div>
                                        <hr/>
                                    </Fragment>

                                )
                            })}
                            <button 
                                className='btn btn-success btn-block' 
                                onClick={() => handlePrompt(
                                    'update session times, acknowledging that if you change a time for a past week it will trigger session report amendments', 
                                    () => handleEditBusLists({method: 'update-session-hours', scheduleType: scheduleType, hoursType: sessionHoursType, centreId: selectedCentreId, updatedRows: centreSessionHoursObj?.[scheduleType]}),
                                    setPrompt
                                )}
                            >Update {humanize(scheduleType)} Session Hours</button>
                        </div>
                )
                })}
            </div>
        );
    }    

      

    const busListsSelector = (
        <select className='form-control' value={selectedBusListId ?? ''} onChange={(e) => setSelectedBusListId(+e.target.value)}>
            <option disabled value=''>Select Bus List</option>
            {busListsData.filter(obj => obj.bus_list_id).map(obj => <option key={obj.bus_list_id} value={obj.bus_list_id}>{obj.bus_list_name}</option>)}
        </select>        
    );       

    let busListSchoolsForm;
    if (selectedBusListId && busListsData?.length > 0) {
        const busSchools = busListSchoolsData.filter(obj => obj.bus_list_id === selectedBusListId);
        busListSchoolsForm = busSchools.map(obj => {
            return (
                <div className={classes.Pair} key={obj.school_acara_id}>
                    <div className={classes.Key}>#{obj.school_acara_id}</div>
                    <div className={classes.Value}>{allSchoolsData.filter(obj2 => +obj2.ACARAId === +obj.school_acara_id)?.[0]?.schoolName}</div>
                    <div className={classes.HoverPointer} onClick={() => handlePrompt(`remove school ${obj.school_acara_id} from this bus list`, () => handleEditBusLists({method: 'remove-school-from-bus-list', removeSchoolId: obj.school_acara_id}), setPrompt)}><MdDeleteForever size={'2.4rem'} color={'darkred'} />    </div>
                    {/* <div className={classes.HoverPointer} onClick={() => handleEditBusLists({method: 'remove-school-from-bus-list', removeSchoolId: obj.school_acara_id})}><MdDeleteForever size={'2.4rem'} color={'darkred'} />    </div> */}
                </div>
            );
        });
    }


    // let busListCapacityForm;
    // if (selectedBusListId && busListSessionConfigData?.length > 0) {
    //     busListCapacityForm = (

    //     );
    // }
    
    let schoolOptions = [];
    if (allSchoolsData?.length > 0) {
        schoolOptions = allSchoolsData.map(obj => ({label: obj.schoolName, value: obj.ACARAId}))
    }

    const handleEditBusLists = async (config) => {

        // close modals
        setAddingNewBusList(false);        
        setAddingNewCentre(false);
        
        const postData = {
            postData: {
                method: config.method,
                busListName: config?.busListName,
                schoolId: config.removeSchoolId ?? selectedSchoolObj?.value, 
                centreId: selectedCentreId,
                busListId: selectedBusListId,
                updatedRows: config?.updatedRows,
                newData: config?.newData,
                scheduleType: config?.scheduleType, // either current schedule or next upcoming schedule
                hoursType: config?.hoursType // will be either ccs hours or operational hours of a centre
            }
        };

        await postMasterData( 'master-data/manage-services', postData, () => {
            getData({table: 'bus_lists', conditionCol: 'centre_id',  conditionValue: selectedCentreId}, setBusListsData, setLoading);
            getData({table: 'view_bus_list_schools_map', conditionCol: 'centre_id',  conditionValue: selectedCentreId}, setBusListSchoolsData, setLoading);
            getData({table: 'view_bus_list_session_config', conditionCols: JSON.stringify(['centre_id', 'bus_list_id']), conditionValues: JSON.stringify([selectedCentreId, selectedBusListId])}, setBusListSessionConfigData, setLoading); 
            setSelectedSchoolObj();
            setNewBusListFormData();
            setNewCentreFormData();
            setAddingSchool();
            
            if (config.method === 'add-new-centre') {
                getCentresData();
                getData({table: 'centre_capacities', conditionCol: 'centre_id', conditionValue: selectedCentreId}, setCapacityData, setLoading);           
            } else if (config.method === 'update-session-hours') {

                // set data source based on type of hours being updated
                const dataSource = (sessionHoursType === 'ccs') ? 'view_pricing_session_hours' : 'view_pricing_session_operational_hours';
                getData({table: dataSource}, setSessionHoursData);
            }


        });

    }
    
    const customFilterOption = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };    

    return (
        <Fragment>
            <Prompt data={prompt} setData={setPrompt} />
            <Banner show={bannerText}>{bannerText}</Banner>       
            {loading && <BackdropSpinner spinner='small-light' />}            
            <div className={classes.Settings}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>       
                <Modal show={addingNewBusList} modalClosed={() => setAddingNewBusList(false)}>
                    <h3>Add New Bus List</h3>
                    <hr/>
                    <ParamsForm 
                        data={newBusListFormData} 
                        setData={setNewBusListFormData} 
                        config={[
                            {name: 'busListName', type: 'text'},
                            {name: 'capacityBSC', type: 'number'},
                            {name: 'capacityASC', type: 'number'},
                            {name: 'capacityVAC', type: 'number'},
                            {name: 'operationalBSC', type: 'checkbox'},
                            {name: 'operationalASC', type: 'checkbox'},
                            {name: 'operationalVAC', type: 'checkbox'},
                        ]}
                    />
                    <button className="btn btn-success btn-block" onClick={() => handleEditBusLists({method: 'add-new-bus-list', newData: newBusListFormData})}>Submit</button>
                </Modal>       
                <Modal tall show={addingNewCentre} modalClosed={() => setAddingNewCentre(false)}>
                    <h3>Add New Centre</h3>
                    <hr/>
                    <ParamsForm 
                        data={newCentreFormData} 
                        setData={setNewCentreFormData} 
                        config={[
                            {name: 'centreName', type: 'text'},
                            {name: 'CRN', type: 'text'},
                            {name: 'capacityBSC', type: 'number'},
                            {name: 'capacityASC', type: 'number'},
                            {name: 'capacityVAC', type: 'number'},
                            {name: 'addressStreetLine1', type: 'text'},
                            {name: 'addressStreetLine2', type: 'text'},
                            {name: 'addressSuburb', type: 'text'},
                            {name: 'addressState', type: 'text'},
                            {name: 'addressPostcode', type: 'text'},
                            {name: 'phoneNumber', type: 'text'},
                            {name: 'email', type: 'text'},
                            {name: 'locationWithinSchool', type: 'text'},
                            {name: 'displayAddress', type: 'text'},
                        ]}
                    />
                    <button className="btn btn-success btn-block" onClick={() => handleEditBusLists({method: 'add-new-centre', newData: newCentreFormData})}>Submit</button>
                </Modal>       
                <div className={classes.ServiceSettingsSubContainer}>
                    <h4>Provider Settings</h4>
                    <hr />
                    {providerSettings}
                </div>
                <hr/>
                <div className={classes.ServiceSettingsSubContainer}>
                    <div className={classes.HeaderButtonRowRight}>
                        <button  className='btn btn-success' onClick={() => setAddingNewCentre(true)}>Add New Centre</button>
                    </div>                      
                    <h4>Service Settings</h4>
                    <hr />
                    <p><i>Select a service to view settings</i></p>
                    <div className={classes.Row}>
                        {services}
                    </div>
                </div>
                {/* <div className={classes.ServiceSettingsContainer}> */}

                    {centreCapacityForm}

                    {centreSessionHoursForm}

                    {selectedCentreId && <div className={classes.ServiceSettingsSubContainer}>
                        <div className={classes.HeaderButtonRowRight}>
                            <button  className='btn btn-success' onClick={() => setAddingNewBusList(true)}>New Bus List</button>
                        </div>                        
                        <h5>Bus Lists</h5>
                        <hr/>     
                        {selectedCentreId && <Fragment>

                            {busListsSelector}
                            {selectedBusListId && <Fragment>
                                <br/>
                                {busCapacityForm}
                                <br/>
                                <br/>
                                <h6>Schools Currently on Bus List</h6>
                                <hr/>
                                {busListSchoolsForm}
                                <hr/>
                                <div className={classes.PairFlexContainer}>
                                    {addingSchool && <div className={classes.PairFlexBasis}>
                                        <Select 
                                            filterOption={customFilterOption} 
                                            options={schoolOptions} 
                                            onChange={setSelectedSchoolObj} 
                                            value={selectedSchoolObj} 
                                        ></Select></div>}
                                    
                                    {selectedBusListId && !addingSchool && <div className={classes.PairFlexBasis}><button className='btn btn-block btn-secondary' onClick={() => setAddingSchool(true)}>Add New School to Bus List</button></div>}
                                    {selectedBusListId && addingSchool && <div className={classes.PairFlexBasis}><button className='btn btn-block btn-secondary' onClick={() => handleEditBusLists({method: 'add-school-to-bus-list'})}>Submit School</button></div>}

                                </div>
                            </Fragment>}
                        </Fragment>}
                    </div>}
                {/* </div> */}
            </div>
        </Fragment>
    )
}

export default Settings;