import classes from './InitialIcon.module.css';

const InitialIcon = (props) => {

    // get data from props
    const initials = props.initials;
    const size = props.size;
    // const selected = props.selected;

    let outerStyle = {};
    let innerStyle = { color: 'white', fontSize: 20};
    if (size === 'large') {
        outerStyle = {height: '150px', width: '150px', margin: 'auto'};
        innerStyle = {color: 'white', fontSize: 80}
    }

    let classList = [classes.InitialIcon];
    // if (selected) {
    //     classList.push(classes.SelectedImage);
    // }

    return (
      <div className={classList.join(' ')} style={outerStyle}>
        <span style={innerStyle}>{initials}</span>
      </div>
    );
  };

export default InitialIcon;