import {React, useState, Fragment} from 'react';
import classes from './FileUpload.module.css';
// import axios from 'axios';
// import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../utilities/globalVariables';
// import SpinnerDark from '../../UI/SpinnerDark/SpinnerDark';
import { ccsMimeTypeTranslation } from '../../utilities/globalObjects';

const FileUpload = (props) => {

    // supporting evidence API call state
    const [supportingEvidenceFiles, setSupportingEvidenceFiles] = useState();
    const [supportingEvidenceNum, setSupportingEvidenceNum] = useState(1);
    const [supportingEvidenceDocTypes, setSupportingEvidenceDocTypes] = useState({});

    // ui state
    // const [loading, setLoading] = useState();

    // props
    const files = props.files;
    const setFiles = props.setFiles;
    const supportingDocumentTypes = props.docTypes; 
    const useJSMimesNotCCSMimes = props.useJSMimesNotCCSMimes;

    // react router history instance to navigate user to other pages
    // let history = useHistory();       

    // console logs
    // console.log('supportingEvidenceFiles: ', supportingEvidenceFiles);
    // console.log('supportingEvidenceNum: ', supportingEvidenceNum);
    // console.log('supportingEvidenceDocTypes: ', supportingEvidenceDocTypes);



    // const postSupportingEvidence = async (formData, mode) => {

    //     setLoading(true);

    //     // data from props
    //     const id = props.id;
    //     const documentsFor = props.documentsFor;

    //     // handle documents
    //     const documents = [];
    //     if (supportingEvidenceFiles) {
    //         for (const value of Object.values(supportingEvidenceFiles)) {
    //             console.log('value: ', value);
    //             const docType = value.docType;
    //             const file = value.file;
    //             const fileContent = await getBase64(file);
    //             const fileContentNoHeader = fileContent.split(',')[1];
    //             documents.push({documentType: docType, MIMEType: ccsMimeTypeTranslation[file.type], fileName: file.name, fileContent: fileContentNoHeader, ID: id})
    //         }
    //     } 
    //     console.log('documents: ', documents);

    //     const headers = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //     };
    //     const postData = {
    //         documents: documents,
    //         documentsFor: documentsFor,
    //     };

    //     console.log('postData: ', postData);

    //     try {
    //         const res = await axios.post(AWS_API + 'ccs/upload-supporting-documents', {postData: postData}, headers);
    //         console.log('response: ', res.data);
    //         props.onSuccess();
    //     } catch (err) {
    //         props.onFailure(err);
    //     }            
    //     setLoading(false);
    // }    



    const handleSupportingEvidenceUpload = async (target, fileNum) => {
        
        const fileType = supportingEvidenceDocTypes[fileNum];
        let newFile;

        if (target.files) {
            if (target.files.length !== 0) {
                newFile = target.files[0];
                setSupportingEvidenceFiles({
                  ...supportingEvidenceFiles, 
                  [fileNum]: {
                      file: newFile,
                      docType: fileType
                  }
              });
            }
          }        

        // handle documents
        const fileContent = await getBase64(newFile);
        const fileContentNoHeader = fileContent.split(',')[1];
        // documents.push({documentType: docType, MIMEType: ccsMimeTypeTranslation[file.type], fileName: file.name, fileContent: fileContentNoHeader, ID: id})
        const newFiles = [
            ...files,
            {documentType: fileType, MIMEType: useJSMimesNotCCSMimes ? newFile.type : ccsMimeTypeTranslation[newFile.type], fileName: newFile.name, fileContent: fileContentNoHeader}
        ];
        // console.log('newFiles: ', newFiles);
        setFiles(newFiles)          
    }

    function getBase64(file) {
        return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }   

    // render supporting evidence inputs
    let supportingEvidenceForm;
    const documentOptions = Object.keys(supportingDocumentTypes).map(type => <option key={type} value={type}>{supportingDocumentTypes[type]}</option>);
    // console.log('doc optinos: ', documentOptions);
    supportingEvidenceForm = [];
    for (let i=0; i<supportingEvidenceNum; i++) {
        supportingEvidenceForm.push(
            <div key={i} > 
                <div className={classes.Item}>File Upload</div> 
                <div  className={classes.Row}> 
                    <select className='form-control' value={supportingEvidenceDocTypes[i] ?? 'default'} onChange={(e) => setSupportingEvidenceDocTypes({...supportingEvidenceDocTypes, [i]: e.target.value})}>
                        <option disabled value="default">Select Document Type</option>
                        {documentOptions}
                    </select>
                    {supportingEvidenceDocTypes[i] && <input type='file' className='form-control' onChange={(e) => handleSupportingEvidenceUpload(e.target, i)}/>}
                </div>  
                <br/>
            </div>  

        );
    }
    
    // let content = <SpinnerDark />;
    // if (!loading) {
    const    content = (
            <Fragment>
                <div className={classes.FileUpload}>
                    {supportingEvidenceForm}
                    <br />
                    <div style={{textAlign: 'center'}}>
                        <button className={classes.RoundButton} onClick={() => setSupportingEvidenceNum(supportingEvidenceNum + 1)}>+</button>
                        <br />
                        <hr/>
                    </div>
                </div>
            </Fragment>
        );
    // }
    
    return content
}

export default FileUpload;