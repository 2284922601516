import SmallSpinnerDark from "../../../../UI/SmallSpinnerDark/SmallSpinnerDark";
import Select from 'react-select'
import classes from './SelectAccount.module.css';

const SelectAccount = (props) => {

    // props
    const selectedAccount = props.selectedAccount;
    const setSelectedAccount = props.setSelectedAccount;
    const accountData = props.accountData;

    
    console.log('accountdata: ', accountData);

    // create select options for each account with various search options
    // const accountOptions = accountData?.sort((a,b) => +a.account_id - +b.account_id)
    // ?.filter(obj => obj.primary_name || obj.primary_email)?.map(obj => ({value: obj.account_id, label: '#' + obj.account_id + ' - ' + obj.primary_name + ' - ' + obj.primary_email + ' - ' + obj.preferred_contact + ' - ' + obj.additional_contact + ' - ' + obj.centre_name  + ' - ' + obj.children_names}));

    // use new function which will capture all unqiue child names
    let accountOptions2 = [];
    try {
        accountOptions2 = createLabels(accountData);
    } catch (err) {
        console.log('error creating account options: ', err);
    }
    
    // const accountOptions2 = accountData
    // ?.reduce((acc, obj) => {
    //   const existingOption = acc.find(opt => opt.value === obj.account_id);
    //   if (existingOption) {
    //     existingOption.label += ` & ${obj.centre_name}`;
    //   } else if (obj.primary_name || obj.primary_email) {
    //     acc.push({
    //       value: obj.account_id,
    //       label: `#${obj.account_id} - ${obj.primary_name} - ${obj.primary_email} - ${obj.preferred_contact} - ${obj.additional_contact} - ${obj.children_names} - ${obj.centre_name}`,
    //     });
    //   }
    //   return acc;
    // }, [])
    // ?.sort((a, b) => +a.value - +b.value);

    // console.log('accountOptions len: ', accountOptions?.length);
    // console.log('accountOptions2 len: ', accountOptions2?.length);

    let select = <SmallSpinnerDark />;
    if (accountData) {
        select = <Select options={accountOptions2} onChange={setSelectedAccount} value={selectedAccount} ></Select>;
    }

    return (
        <div  className={classes.SelectContainer}> 
            <h6 className={classes.Item}>Select Account</h6> 
            <hr/>
            <div style={{display: 'flex', flexFlow: 'row wrap', gap: '10px'}}>
                <div style={{ flex: '3 250px'}}>
                    {select}
                </div>


            </div>

        </div>
    );
}

export default SelectAccount;


const createLabels = (accountData) => {

    // initialise objects to store unique child and centre names
	let childNames = {}, centreNames = {};

    // loop through each account and collect unique centre and child names
    accountData.forEach(obj => {
      const accountId = obj?.account_id;
      	if (!childNames?.[accountId]) childNames[accountId] = [];
      	if (!centreNames?.[accountId]) centreNames[accountId] = [];
      
    	const childrenNames = obj?.children_names?.split(',') ?? obj?.children_names;
      	for (const name of childrenNames) {
        	const trimmedName = name.trim();
          if (!childNames?.[accountId].includes(trimmedName)) childNames?.[accountId].push(trimmedName)
        }
      	const centreName = obj?.centre_name;
      	if (!centreNames?.[accountId].includes(centreName)) centreNames?.[accountId].push(centreName)
    });
    // console.log('childNames: ', childNames);
    // console.log('centreNames: ', centreNames);
  
    // create unique account ID label 
    const accountOptions2 = accountData?.reduce((acc, obj) => {

        const accountId = obj?.account_id;
        const existingOption = acc.find(opt => opt.value === accountId);
        if (!existingOption) {
        const acctCentreNames = centreNames?.[accountId].sort()
        const acctChildNames = childNames?.[accountId].sort();

        acc.push({
            value: accountId,
            label: `#${accountId} - ${obj.primary_name} - ${obj.primary_email} - ${obj.preferred_contact} - ${obj.additional_contact} - ${acctChildNames.join(', ')} - ${acctCentreNames.join(' & ')}`,
            });
        };
        
        return acc;

 	}, [])?.sort((a, b) => +a.value - +b.value);

	return accountOptions2

}