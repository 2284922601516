// LOCAL 
// export const cognitoTokenAPI = 'https://active-oosh.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
// export const cognitoClientId = '1st5mmvdqm28gb6hj2n0kp2sc1';
// export const cognitoRedirectURL = 'http://localhost:3000';
// export const cognitoUserpoolId = 'ap-southeast-2_0gkoOPR01';
// export const cognitoURL = 'https://active-oosh.auth.ap-southeast-2.amazoncognito.com/login?client_id=1st5mmvdqm28gb6hj2n0kp2sc1&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
// export const AWS_API = 'https://69japvxa71.execute-api.ap-southeast-2.amazonaws.com/';
// export const public_s3_url = 'https://aosh-reference-data.s3.ap-southeast-2.amazonaws.com/';
// export const envName = 'dev';
// export const formioAPI = 'https://oshcplus-prod.form.io/';

// export const formioAPI = 'https://rgpmmogmwzdplxi.form.io/'


// DEV
// export const cognitoTokenAPI = 'https://activeoosh-prod.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
// export const cognitoClientId = '493vrcj9b2aurp6cvem001djch';
// export const cognitoRedirectURL = 'https://ao-dev.citras.io';
// export const cognitoUserpoolId = 'ap-southeast-2_O7UU5f1dU';
// export const cognitoURL = 'https://activeoosh-prod.auth.ap-southeast-2.amazoncognito.com/login?client_id=493vrcj9b2aurp6cvem001djch&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
// export const AWS_API = 'https://69japvxa71.execute-api.ap-southeast-2.amazonaws.com/dev/';
// export const public_s3_url = 'https://aosh-reference-data.s3.ap-southeast-2.amazonaws.com/'
// export const formioAPI = 'https://oshcplus-prod.form.io/';


// TEST
export const cognitoTokenAPI = 'https://active-oosh-dev.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
export const cognitoClientId = 'd7rvue31kind7qf6mq6bbs9ck';
export const cognitoRedirectURL = 'https://ao-test.citras.io';
export const cognitoUserpoolId = 'ap-southeast-2_GA3zqcOHT';
export const cognitoURL = 'https://active-oosh-dev.auth.ap-southeast-2.amazoncognito.com/login?client_id=' + cognitoClientId +  '&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
export const AWS_API = 'https://7566gnyru1.execute-api.ap-southeast-2.amazonaws.com/';
export const public_s3_url = 'https://aosh-reference-data-dev.s3.ap-southeast-2.amazonaws.com/'
export const formioAPI = 'https://oshcplus-prod.form.io/';
export const envName = 'test';

// export const formioAPI = 'https://rgpmmogmwzdplxi.form.io/'

// PROD
// export const cognitoTokenAPI = 'https://active-oosh-prod.auth.ap-southeast-2.amazoncognito.com/oauth2/token';
// export const cognitoClientId = '237fjjvunjh9g5kib8u9k122aj';
// export const cognitoRedirectURL = 'https://app.oshcplus.com.au';
// export const cognitoUserpoolId = 'ap-southeast-2_IuGbQ3LY1';
// export const cognitoURL = 'https://active-oosh-prod.auth.ap-southeast-2.amazoncognito.com/login?client_id=' + cognitoClientId +  '&response_type=code&scope=email+openid+phone&redirect_uri=' + cognitoRedirectURL;
// export const AWS_API = 'https://48v0urzplk.execute-api.ap-southeast-2.amazonaws.com/';
// export const public_s3_url = 'https://aosh-reference-data-prod.s3.ap-southeast-2.amazonaws.com/'
// export const formioAPI = 'https://oshcplus-prod.form.io/';
// export const envName = 'prod';

// generic variables for both localhost and dev/prod
export const cognitoGrantTypeAuth = 'authorization_code';
export const cognitoGrantTypeRefresh = 'refresh_token';
export const bscStartTime = 6.5;
export const ascStartTime = 15;
export const vacStartTime = 7;
export const centrelinkMon1 = new Date('2021-11-1');
export const bscSessionLength = 2.5;
export const ascSessionLength = 3.5;
export const vacSessionLength = 11;
export const timezone = 'Australia/Sydney';
export const gocardlessDashboardURL = 'https://manage.gocardless.com/customers'
export const prodURL = 'https://app.oshcplus.com.au'
export const eventInformationLink = 'https://bit.ly/AOPermissions';
export const eventTermsConditionsLink = 'https://drive.google.com/file/d/1_7cAE7LTj2NunNq449P3mFg92CBS12fO/view?usp=sharing';
export const tinyAPIKey = '9vbps1a7wqa12sbjidx22ldo7ttc368m7kju48em9j1j7d25';

// citra formio account
// export const formioAPI = 'https://rgpmmogmwzdplxi.form.io/'

// oshc+ formio account

