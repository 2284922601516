import {React, useState, useEffect} from 'react';
import classes from './Roll.module.css';
import AdminRoll from '../AdminMgmt/AdminRoll/AdminRoll';
import DailyRoll from './DailyRoll/DailyRoll';


const Roll = (props) => {

    const [showContent, setShowContent] = useState('daily');
    
    // get data from props
    const setSublinks = props.setSublinks;
    const isMobile = props.isMobile;
    const role = props.role;

    // set leftpane sublinks
    useEffect(() => {
        setSublinks([
            {title: 'Daily', icon: 'schedule',  onClick: () => setShowContent('daily')},
            {title: 'Weekly', icon: 'calendar',  onClick: () => setShowContent('weekly')},
        ]);
    }, [setSublinks, setShowContent])    


    let content;
    if (showContent === 'daily') {
        content = <DailyRoll isMobile={isMobile} setSublinks={setSublinks} token={props.token}/>
    } else if (showContent === 'weekly') {
        content = <AdminRoll token={props.token} role={role}/>
    } 


    return (
        <div className={classes.Roll}>
            {content}
        </div>
    );
}

export default Roll;