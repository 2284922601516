import {React, Fragment} from 'react';
import classes from './PersonnelForm.module.css';
import { personnelPositions, personnelRoleTypes, ausStates, supportingDocumentTypes } from '../../../../../utilities/globalObjects';

const PersonnelForm = (props) => {

    const formData = props.formData;
    const setFormData = props.setFormData;
    const centresData = props.centresData;
    const selectedDocType = props.selectedDocType;
    const setSelectedDocType = props.setSelectedDocType;
    const handleUpload = props.handleUpload;
    const submitForm = props.submitForm;
    const mode = props.mode;

    console.log('mode: ', mode);
    console.log('formData: ', formData);

    const roleOptions = Object.keys(personnelRoleTypes).map(role => <option key={role} value={role}>{personnelRoleTypes[role]}</option>);
    const positionOptions = Object.keys(personnelPositions).map(position => <option key={position} value={position}>{personnelPositions[position]}</option>);
    const documentOptions = Object.keys(supportingDocumentTypes).map(type => <option key={type} value={type}>{supportingDocumentTypes[type]}</option>);
    const centreOptions = centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>);
    const stateOptions = ausStates.map(state => <option key={state} value={state}>{state}</option>);

    // mode specific inputs
    const declarations = (
        <Fragment>
            <br/>
            <span><b>Declarations</b></span>
            <div className="form-check"><input className="form-check-input" type="checkbox"  checked={formData?.declarationWWCC ?? false} onChange={(e) => setFormData({...formData, declarationWWCC: e.target.checked})}/> <label className="form-check-label" >Declare WWCC </label></div>            
            <div className="form-check"><input className="form-check-input" type="checkbox"  checked={formData?.declarationpoliceCheck ?? false} onChange={(e) => setFormData({...formData, declarationpoliceCheck: e.target.checked})}/> <label className="form-check-label" >Declare police </label></div>            
            <div className="form-check"><input className="form-check-input" type="checkbox"  checked={formData?.declarationAFSA ?? false} onChange={(e) => setFormData({...formData, declarationAFSA: e.target.checked})}/> <label className="form-check-label" >Declare AFSA </label></div>            
            <div className="form-check"><input className="form-check-input" type="checkbox"  checked={formData?.declarationASIC ?? false} onChange={(e) => setFormData({...formData, declarationASIC: e.target.checked})}/> <label className="form-check-label" >Declare ASIC </label></div>            
            <div className="form-check"><input className="form-check-input" type="checkbox"  checked={formData?.declarationadverseEvents ?? false} onChange={(e) => setFormData({...formData, declarationadverseEvents: e.target.checked})}/> <label className="form-check-label" >Declare adverse events </label></div>            
        </Fragment>
    );

    let title, id ;
    if (mode === 'add') {
        title = 'Add New Personnel';
        id = <div className={classes.Row}> <span className={classes.Item}>PRODA RA Number</span> <input type='text' className='form-control' value={formData?.PRODARANumber ?? ''} onChange={(e) => setFormData({...formData, PRODARANumber: e.target.value})}/></div>
    } else if (mode === 'update') {
        title = 'Update Personnel Record';
        id = <div className={classes.Row}> <span className={classes.Item}>Person ID</span> <input type='text' className='form-control' value={formData?.personID ?? ''} onChange={(e) => setFormData({...formData, personID: e.target.value})}/></div>
    }

    const form = (
        <Fragment>
            <h3>{title}</h3>
            <br/>
            <div className={classes.Row}> <span className={classes.Item}>Start Date</span> <input type='date' className='form-control' value={formData?.startDate ?? ''} onChange={(e) => setFormData({...formData, startDate: e.target.value})}/></div>
            {id}
            <div className={classes.Row}> <span className={classes.Item}>First Name</span> <input type='text' className='form-control' value={formData?.firstName ?? ''} onChange={(e) => setFormData({...formData, firstName: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Last Name</span> <input type='text' className='form-control' value={formData?.lastName ?? ''} onChange={(e) => setFormData({...formData, lastName: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Date of Birth</span> <input type='date' className='form-control' value={formData?.dateOfBirth ?? ''} onChange={(e) => setFormData({...formData, dateOfBirth: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Email</span> <input type='email' className='form-control' value={formData?.email ?? ''} onChange={(e) => setFormData({...formData, email: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Phone</span> <input type='tel' className='form-control' value={formData?.phone ?? ''} onChange={(e) => setFormData({...formData, phone: e.target.value})}/></div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Role</span> 
                <select className='form-control' value={formData?.role ?? 'default'} onChange={(e) => setFormData({...formData, role: e.target.value})}>
                    <option disabled value='default'>Select Role</option>
                    {roleOptions}
                </select>
            </div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Position</span> 
                <select className='form-control' value={formData?.position ?? 'default'} onChange={(e) => setFormData({...formData, position: e.target.value})}>
                    <option disabled value='default'>Select Position</option>
                    {positionOptions}
                </select>
            </div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Service (Optional)</span> 
                <select className='form-control' value={formData?.service ?? ''} onChange={(e) => setFormData({...formData, service: e.target.value})}>
                    <option disabled value=''>Select Centre</option>
                    {centreOptions}
                </select>
            </div>
            <br />
            <h6>WWCC</h6>
            <div className={classes.Row}> <span className={classes.Item}>Card Number</span> <input type='text' className='form-control' value={formData?.wwccCardNumber ?? ''} onChange={(e) => setFormData({...formData, wwccCardNumber: e.target.value})}/></div>
            <div className={classes.Row}> <span className={classes.Item}>Expiry Date</span> <input type='date' className='form-control' value={formData?.wwccExpiryDate ?? ''} onChange={(e) => setFormData({...formData, wwccExpiryDate: e.target.value})}/></div>
            <div className={classes.Row}> 
                <span className={classes.Item}>Issuing State</span> 
                <select className='form-control' value={formData?.wwccIssuingState ?? 'default'} onChange={(e) => setFormData({...formData, wwccIssuingState: e.target.value})}>
                    <option disabled value='default'>Select State</option>
                    {stateOptions}
                </select>
            </div>
            <br/>
            <div className={classes.Row}> 
                <span className={classes.Item}>Supporting Documents</span> 
                <select className='form-control' value={selectedDocType ?? 'default'} onChange={(e) => setSelectedDocType(e.target.value)}>
                    <option disabled value="default">Select Document Type</option>
                    {documentOptions}
                </select>
                {selectedDocType && <input type='file' className='form-control' onChange={(e) => handleUpload(e.target, selectedDocType)}/>}
            </div>
            {declarations}
            <br/>
            <button className="btn btn-success btn-block" onClick={() => submitForm(formData, mode)}>Submit</button>
        </Fragment>
    );
    
    
    return (
        <div className={classes.PersonnelForm}>
            {form}
        </div>
    );
}

export default PersonnelForm;