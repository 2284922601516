import { useState, useEffect, useCallback, Fragment } from 'react';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId, timezone } from '../../../../utilities/globalVariables';
import { handleAwsApiError, getPrevMonday, handleBannerText, incrementDate, handlePrompt, humanize, countDigits, removeBracketExpressionFromString, formatDateString } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import classes from './AdminRoll.module.css';
import Banner from '../../../../UI/Banner/Banner';
import {  sessionTypes } from '../../../../utilities/globalObjects';
import { raw } from '../../../../translations/en/raw';
import BSTable from '../../../../UI/BSTable/BSTable';
import AddChildren from './AddChildren/AddChildren';
import SmallSpinnerDark from '../../../../UI/SmallSpinnerDark/SmallSpinnerDark';
import AddCheckins from './AddCheckins/AddCheckins';
import Prompt from '../../../../UI/Prompt/Prompt';
import OptionModal from '../../../../UI/OptionModal/OptionModal';

const AdminRoll = (props) => {

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [bannerText, setBannerText] = useState();    
    const [showAddChildren, setShowAddChildren] = useState();
    const [showAddCheckins, setShowAddCheckins] = useState();
    // const [rollDisplayMode, setRollDisplayMode] = useState('bookings');
    
    // data state
    const [centresData, setCentresData] = useState([]);
    const [upcomingSessions, setUpcomingSessions] = useState();
    const [childrenData, setChildrenData] = useState();
    const [pendingBookings, setPendingBookings] = useState();
    const [centreCapacitiesData, setCentreCapacitiesData] = useState();
    const [centreSessionData, setCentreSessionData] = useState();
    
    // filter state
    const [selectedCentreId, setSelectedCentreId] = useState();
    const [selectedSession, setSelectedSession] = useState('bsc');
    const [selectedDates, setSelectedDates] = useState([]);
    // const [permissionSlipsData, setPermissionSlipsData] = useState();
    // const [showPermissionSlip, setShowPermissionSlip] = useState();
    
    // cell click options
    const [clickedCellObj, setClickedCellObj] = useState();

    const [prompt, setPrompt] = useState({});      
    // table state passed to child component (held in parent to prevent re-rendering issues)
    // const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);          

    // set week commencing/starting dates
    const [displayDate, setDisplayDate] = useState(new Date(getPrevMonday(new Date())));

    // constants
    const dayLabels = ['MON', 'TUE', 'WED', 'THU', 'FRI'];

    // data from props
    const role = props.role;

    // console logs
    // console.log('[AdminRoll] - role: ', role);
    // console.log('[AdminRoll] - clickedCellObj: ', clickedCellObj);
    // console.log('[AdminRoll] - selected: ', selected);
    // console.log('[AdminRoll] - selectedSession: ', selectedSession);
    // console.log('upcomingSessions: ', upcomingSessions);
    // console.log('centreCapacitiesData: ', centreCapacitiesData);
    // console.log('childrenData: ', childrenData);
    // console.log('selectedCentreId: ', selectedCentreId);

    // create react-router history object to navigate user to other pages
    const history = useHistory(); 
    let location = useLocation();       


    // generic function to get data and save to state
    const getMasterData = useCallback(async (tableName, stateFn, firstCall, lastCall, conditionCol, conditionValue, multiFilters) => {
        
        console.log(`fetching data for table ${tableName}`);

        firstCall ? setLoading(true) : void(0);

        if (tableName) {
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            
            let params = {};
            if (multiFilters) {
                params = {table: tableName, conditionCols: JSON.stringify(multiFilters?.columns), conditionValues: JSON.stringify(multiFilters?.values)}
            } else {
                params = {table: tableName, conditionCol: conditionCol, conditionValue: conditionValue};
            }
            console.log('[getMasterData] - params: ', params);
            
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: params
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log('data fetch res: ', res.data);
                stateFn(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }            

        }

        lastCall ? setLoading(false) : void(0);
    }, [history, props, location]);        

    // get centres data
    useEffect(() => {
        getMasterData('centres', setCentresData, true, true);
    }, [getMasterData])

    // if centres data length is 1, set that as selected centre
    useEffect(() => {
        if (centresData?.length === 1) {
            setSelectedCentreId(centresData?.[0]?.centre_id);
        }
    }, [centresData])    


    // reset selection when centre or session changes
    useEffect(() => {
        setSelected([]);
    }, [selectedCentreId, selectedSession])
    


    // render service choices
    const services = (
        <select  className='form-control' value={selectedCentreId ?? ''} onChange={(e) => setSelectedCentreId(e.target.value)}>
            <option disabled value=''>Select Service/Provider</option>
            {centresData?.filter(obj => obj.service_crn)?.sort((a,b) => {
                if ( a.centre_name < b.centre_name ) return -1;
                if ( a.centre_name > b.centre_name ) return 1;
                return 0;
            })?.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
        </select>        
    );    
    
    // render session choices
    let sessions;
    if (selectedCentreId) sessions = <SmallSpinnerDark />;
    if (centreCapacitiesData) {
        sessions = (
            <select className='form-control' value={selectedSession ?? ''} onChange={(e) => setSelectedSession(e.target.value)}>
                <option disabled value=''>Select Session</option>
                    {
                        sessionTypes.map(session => {
                            const sessionCapacity = centreCapacitiesData.filter(obj => +obj.centre_id === +selectedCentreId && obj.session === session)[0]?.max_capacity;
                            return  <option key={session} value={session}>{raw[session] + ' (' + sessionCapacity + ')'}</option>
                        })
                    }
            </select>        
        );    

    }






  
    // fetch upcoming roll data for selected centre
    useEffect(() => {

        if (selectedCentreId && displayDate) {

            // getMasterData('view_bus_and_roll_summary', setUpcomingSessions, true, false, 'corresponding_centre_id', selectedCentreId);
            getMasterData('view_bus_and_roll_summary', setUpcomingSessions, true, false, null, null, {columns: ['corresponding_centre_id', 'week_commencing'], values: [selectedCentreId, displayDate.toLocaleDateString("sv-SW", {timeZone: timezone})]});
            getMasterData('view_pending_bookings', setPendingBookings, false, false, 'centre_id', selectedCentreId);
            getMasterData('centre_capacities', setCentreCapacitiesData, false, false, 'centre_id', selectedCentreId);
            getMasterData('view_child_enrolments', setChildrenData, false, false, 'centre_id', selectedCentreId);
            getMasterData('view_centre_sessions_calendar', setCentreSessionData, false, true, 'centre_id', selectedCentreId);
        }

    }, [getMasterData, selectedCentreId, displayDate]);
  


    const transformRollDataToScheduleView = (rollData, pendingBookings) => {

        const formattedData = [];

        // set date
        let prevMonday = getPrevMonday(displayDate);
        const currentDate = new Date().toLocaleDateString('sv-SW');
        // console.log('currentDate: ', currentDate);
        // let weekEnding = new Date(prevMonday)
        // weekEnding.setDate(weekEnding.getDate() + 7);
        // console.log('prevMonday: ', prevMonday);
        // console.log('weekEnding: ', weekEnding);
        
        // apply filters
        const filteredData = rollData.filter(obj => obj.session === selectedSession);
        const filteredPendingData = pendingBookings.filter(obj => obj.session === selectedSession);
        // console.log('[transformRollDataToScheduleView] - rollData: ', filteredData);
        
        // get row keys (unique children)
        const uniqueChildrenIds = filteredData.map(obj => obj.child_id).concat(filteredPendingData.map(obj => obj.child_id)).filter((v, i, a) => a.indexOf(v) === i);
        // console.log('[transformRollDataToScheduleView] - uniqueChildrenIds: ', uniqueChildrenIds);
        
        // for each child, populate two-week view of roll bookings
        let emptyRow = {name: '', childId: ''};
        for (const childId of uniqueChildrenIds) {
            const childSessions = filteredData.filter(obj => obj.child_id === childId);
            const pendingChildSessions = filteredPendingData.filter(obj => obj.child_id === childId);
            const childrenData = childSessions[0] ?? pendingChildSessions[0];
            // console.log('[transformRollDataToScheduleView] - childSessions: ', childSessions);

            const childRow = {
                name: childrenData.surname + ', ' + childrenData.first_name + ' (Acct: ' + childrenData.guardian_first_name + ')',
                childId: childId    
            }; 

            // loop through each day in 2-week view and populate 
            // const dayLabels = ['MON-1', 'TUE-1', 'WED-1', 'THU-1', 'FRI-1', 'SA1', 'SU1', 'MON-2', 'TUE-2', 'WED-2', 'THU-2', 'FRI-2', 'SA2', 'SU2'];
            
            dayLabels.forEach((day, i) => {
                let date = new Date(prevMonday);
                date.setDate(date.getDate() + i);
                const isoDate = date.toLocaleDateString('sv-SW');
                const dateString = date.toDateString()?.toUpperCase();
                // console.log('isoDate: ', isoDate);

                const afterTodayFlag = new Date(isoDate).getTime() > new Date(currentDate).getTime();
                // console.log('afterTodayFlag: ', afterTodayFlag);

                // populate columns of empty row to use if no children on roll for this week
                if (emptyRow[dateString] !== '') {
                    emptyRow[dateString] = '';
                }

                // check if session exists for this child, if so add to data structure
                const matchingSession = childSessions.filter(obj => obj.date.slice(0,10) === isoDate && obj.location_type === 'centre')[0];
                const matchingBusBooking = childSessions.filter(obj => obj.date.slice(0,10) === isoDate && obj.location_type === 'bus')?.[0];
                const status = matchingSession?.status;
                const createdFrom = matchingSession?.created_from;
                const statusChangedById = matchingSession?.status_changed_by;
                const statusChangedBy = matchingSession?.status_changed_by_account_type;
                // console.log('matchingSession: ', matchingSession);


                // add information to roll data object depending on current date
                let sessionExists = '';
                if (afterTodayFlag) {

                    // if child absent show that, otherwise show status
                    if (status === 'absent') {
                        sessionExists = 'Absent';
                    } else {
                        
                        sessionExists = matchingSession ? humanize(status) : '';
                        
                    }                    
    
                    // if no roll session, check if pending booking exists yet to be written to roll or child on waitlist
                    if (!sessionExists) {

                        const pendingSession = pendingChildSessions.filter(obj => obj?.date?.slice(0,10) === isoDate)?.[0];
                        let pendingSessionBookingType = pendingSession?.booking_type;
                        if (pendingSessionBookingType === 'waitlist') pendingSessionBookingType += ` ${pendingSession?.rank}`;
                        sessionExists = pendingSessionBookingType ? humanize(pendingSessionBookingType) : '';
                    }                    
    
                    
                } else {
                    
                    // if matching session found, add check in/out times
                    if (matchingSession) {
                        const checkedInTD1 = matchingSession?.checked_in1 ? new Date(matchingSession?.checked_in1).toLocaleTimeString().slice(0,5): '__';
                        const checkedInTD2 = matchingSession?.checked_in2 ? new Date(matchingSession?.checked_in2).toLocaleTimeString().slice(0,5): '';
                        const checkedOutTD1 = matchingSession?.checked_out1 ? new Date(matchingSession?.checked_out1).toLocaleTimeString().slice(0,5): '__';
                        const checkedOutTD2 = matchingSession?.checked_out2 ? new Date(matchingSession?.checked_out2).toLocaleTimeString().slice(0,5): '';
                        
                        // if child absent show that, otherwise show check in /out times
                        if (status === 'absent') {
                            sessionExists = 'Absent';
                        } else {
                            sessionExists = 'In: ' +  checkedInTD1 + ' Out: ' + checkedOutTD1;
    
                            // add check in/out 2s if present
                            if (matchingSession?.checked_in2) {
                                sessionExists = sessionExists + ' | In2: ' + checkedInTD2;
                            }
                            if (matchingSession?.checked_out2) {
                                sessionExists = sessionExists + ' Out2: ' + checkedOutTD2;
                            }
                        }
                    } else {

                        // if no roll session today/in the past, check for waitlist session (mostly just relevant for current day last min waitlists)
                        if (!sessionExists) {

                            const pendingSession = pendingChildSessions.filter(obj => obj?.date?.slice(0,10) === isoDate)?.[0];
                            let pendingSessionBookingType = pendingSession?.booking_type;
                            if (pendingSessionBookingType === 'waitlist') pendingSessionBookingType += ` ${pendingSession?.rank}`;
                            sessionExists = pendingSessionBookingType ? humanize(pendingSessionBookingType) : '';
                        }   
                    }
                    
                } 
                
                if (matchingBusBooking) sessionExists = sessionExists + ' BUS'; 
                
                // set status/created by symbols
                let statusChangedBySymbol;
                if (statusChangedBy === 'accountOwner') {
                    statusChangedBySymbol = 'G';
                } else if (statusChangedById === 1 || ['centreAdmin', 'headOffice', 'director'].includes(statusChangedBy)) {
                    statusChangedBySymbol = 'S';
                } 
                // else if (statusChangedBy) {
                //     statusChangedBySymbol = statusChangedBy.slice(0,1).toUpperCase();
                // } 

                // let createdFromSymbol = createdFrom?.slice(0,1)?.toUpperCase();
                let createdFromSymbol = '';
                // created from guardian
                if (['perm_bookings', 'casual_bookings'].includes(createdFrom)) {
                    createdFromSymbol =  'G';    
        
                // otherwise created from admin roll add, i.e. staff            
                } else if (createdFrom) {
                    createdFromSymbol =  'S';    
                }                
                
                // add symbol to each record
                if (createdFromSymbol && statusChangedBySymbol) {
                    const statusCreatedSymbol = ' (' + createdFromSymbol + ', ' + statusChangedBySymbol + ')';    
                    sessionExists += statusCreatedSymbol
                } else if (createdFromSymbol) {
                    const statusCreatedSymbol = ' (' + createdFromSymbol + ')';    
                    sessionExists += statusCreatedSymbol
                }

                // add information to roll data object depending on display mode 
                // let sessionExists = '';
                // if (rollDisplayMode === 'bookings') {

                //     sessionExists = matchingSession ? 'S: ' + matchingSession?.status : '';
                //     const matchingBusBooking = childSessions.filter(obj => obj.date.slice(0,10) === isoDate && obj.location_type === 'bus')[0]
                //     if (matchingBusBooking) sessionExists = sessionExists + ' B: ' + matchingBusBooking?.status;
    
    
                //     // if no roll session, check if pending booking exists yet to be written to roll
                //     if (!sessionExists) {
                //         sessionExists = pendingChildSessions.filter(obj => obj.date.slice(0,10) === isoDate).length > 0 ? 'pending' : '';
                //     }
    
                //     // console.log('childSessions: ', childSessions);
    
                    
                // } else if (rollDisplayMode === 'check-ins') {
                    
                //     // if matching session found, add check in/out times
                //     if (matchingSession) {
                //         const checkedInTD1 = matchingSession?.checked_in1 ? new Date(matchingSession?.checked_in1).toLocaleTimeString().slice(0,5): '___';
                //         const checkedInTD2 = matchingSession?.checked_in2 ? new Date(matchingSession?.checked_in2).toLocaleTimeString().slice(0,5): '';
                //         const checkedOutTD1 = matchingSession?.checked_out1 ? new Date(matchingSession?.checked_out1).toLocaleTimeString().slice(0,5): '___';
                //         const checkedOutTD2 = matchingSession?.checked_out2 ? new Date(matchingSession?.checked_out2).toLocaleTimeString().slice(0,5): '';
                        
                //         // if child absent show that, otherwise show check in /out times
                //         if (matchingSession?.status === 'absent') {
                //             sessionExists = 'absent';
                //         } else {
                //             sessionExists = 'In: ' +  checkedInTD1 + ' Out: ' + checkedOutTD1;
    
                //             // add check in/out 2s if present
                //             if (matchingSession?.checked_in2) {
                //                 sessionExists = sessionExists + ' | In2: ' + checkedInTD2;
                //             }
                //             if (matchingSession?.checked_out2) {
                //                 sessionExists = sessionExists + ' Out2: ' + checkedOutTD2;
                //             }
                //         }
                //     }
                    
                // }
                
                // childRow[day] = sessionExists
                childRow[dateString] = sessionExists



            });

            // if child has any sessions in this week, add to formatted data
            const sessionCount = Object.values(childRow)?.filter(el => el)?.length - 2; // subtracting 2 as two of the values are name and child ID
            // console.log('sessionCount: ', sessionCount);
            if (sessionCount > 0) {
                formattedData.push(childRow)
            }

            

        }

        if (formattedData?.length === 0) {
            formattedData.push(emptyRow);    
        }

        return formattedData;
        
    }

    // create dynamic class function to style table
    const dynamicClassFunction = (cell, row, rowIndex, colIndex) => {

        // console.log('colIndex: ', colIndex);
        // console.log('cell: ', cell);
        console.log('row: ', row);
        // console.log('rowIndex: ', rowIndex);
        // console.log('colIndex: ', colIndex);

        // check date of cell
        const time = new Date(Object.keys(row)[colIndex + 1]?.slice(0,15)).getTime();
        let currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        const warningCutoffTime = currentDate.getTime();
        
        // first format check-in/out columns
        

        // before today
        if (colIndex !== 0 && time < warningCutoffTime && cell?.length > 0) {

            if (cell?.includes('Absent') ) {
                return classes.SuccessBackground2
            } else if (countDigits(cell) <= 4 )  {
                return classes.WarningBackground
            } else if (countDigits(cell) > 4 )  {
                return classes.SuccessBackground
            } 

        // current day
        } else if (colIndex !== 0 && time < warningCutoffTime + (24 * 60 * 60 * 1000) && cell?.length > 0) {
            if (cell) {
                
                // if (containsNumber(cell) )  {
                //     return classes.SuccessBackground
                // } else 
                if (cell?.includes('Absent') ) {
                    return classes.SuccessBackground2
                // } else if (cell.includes('BUS')) {
                //     return classes.SuccessBackground2
                } else if (cell.includes('Confirmed')) {
                    return classes.SuccessBackground3
                } else if (cell === 'Pending') {
                    return classes.PendingBackground
                } else if (cell.includes('Waitlist')) {
                    return classes.WaitlistBackground
                } else if (countDigits(cell) >= 4 ) {
                    return classes.SuccessBackground
                } else {
                    return false

                }
            }       
            
        // after today
        } else if (colIndex !== 0) {

            // now format future day columns
            if (cell) {
                
                if (cell?.includes('Absent') ) {
                    return classes.SuccessBackground2
                // } else if (cell.includes('BUS')) {
                //     return classes.SuccessBackground2
                // } else if (cell.includes('Confirmed')) {
                //     return classes.SuccessBackground3
                } else if (cell === 'Pending') {
                    return classes.PendingBackground
                } else if (cell.includes('Waitlist')) {
                    return classes.WaitlistBackground                    
                } else {
                    return false
                }
                // else {
                //     return classes.SuccessBackground
                // }
            }
        }

    }    

    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        setShowAddChildren(false);
        setShowAddCheckins(false);

        try {
            const res = await axios.post(AWS_API + route, {postData: postData}, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            
            // default cleanup options
            setSelected([]);
            setSelectedDates([]);
            handleBannerText(setBannerText, `Action Completed`);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
            // setLoading(false);
        }    

        getMasterData('view_bus_and_roll_summary', setUpcomingSessions, true, true, null, null, {columns: ['corresponding_centre_id', 'week_commencing'], values: [selectedCentreId, displayDate.toLocaleDateString("sv-SW", {timeZone: timezone})]});
        getMasterData('view_pending_bookings', setPendingBookings, false, false, 'centre_id', selectedCentreId);
        setLoading(false);
    }      

    const writeBlockBooking = (bookingData, onSuccessFn) => {
        console.log('writing block booking for data: ', bookingData);
        postMasterData('master-data/add-roll-sessions-block', {...bookingData}, onSuccessFn)
    } 

    const updateCheckins = (checkinData, onSuccessFn) => {
        console.log('updating checkins/outs for data: ', checkinData);
        postMasterData('master-data/update-checkins-block', {...checkinData}, onSuccessFn)
    } 


    // reformat data any time data or filters change (i.e. on any re-render/state change)
    let formattedData = (upcomingSessions && pendingBookings) ? transformRollDataToScheduleView(upcomingSessions, pendingBookings) : null;
    if (formattedData?.length > 0) {
        formattedData = formattedData.sort((a,b) => {
            if ( a.name < b.name ) return -1;
            if ( a.name > b.name ) return 1;
            return 0;
        })
    }

    console.log('formattedData: ', formattedData);
    
    // get number of sessions each day in weekly view
    if (formattedData?.length > 0) {

        const keys = Object?.keys(formattedData?.[0] ?? {});
        const sessionCounts = {};
        dayLabels.forEach(day => {
            // console.log('day: ', day);
            const key = keys.filter(key => key.toLowerCase().includes(day.toLowerCase()))?.[0];
            // console.log('matching key: ', key);
            
            const countSessions = formattedData?.filter(obj => obj[key])?.length ?? 0;
            const countAbsent = formattedData?.filter(obj => obj[key]?.includes('Absent'))?.length ?? 0;
            const countPending = formattedData?.filter(obj => obj[key]?.includes('Pending'))?.length ?? 0;
            const countWaitlist = formattedData?.filter(obj => obj[key]?.includes('Waitlist'))?.length ?? 0;
            const countConfirmed = countSessions - countAbsent - countPending - countWaitlist;
            // console.log('matchingSessions: ', countSessions);
            
            sessionCounts[key] = {'total': countSessions, 'absent': countAbsent, 'pending': countPending, 'waitlist': countWaitlist, 'confirmed': countConfirmed};
        })
        // console.log('sessionCounts: ', sessionCounts);
        
        // stick number into column header
        formattedData.forEach(obj => {
            
            Object.keys(sessionCounts).forEach(key => {
                // const newKeyName = key + ' (' + sessionCounts[key] + ')';
                const newKeyName = key 
                + `\nConfirmed - ${sessionCounts[key]['confirmed']}`
                + `\nAbsent - ${sessionCounts[key]['absent']}`
                + `\nPending - ${sessionCounts[key]['pending']}`
                + `\nWaitlist - ${sessionCounts[key]['waitlist']}`
                obj[newKeyName] = obj[key];
                delete obj[key];
            })
    
        })
        
    }
    // console.log('formattedData: ', formattedData);
    




    const handleRollClickAction = (action) => {

        try {

            // pull out useful values from cell clicked
            const childId = clickedCellObj?.childId;
            const date = clickedCellObj?.date;
            const session = selectedSession;
            const centreId = selectedCentreId;

            if (!childId || !date || !session || !centreId) {
                setError(`Missing child ID ${childId}, date ${date}, centre ${centreId} or session ${session}`);
                setClickedCellObj();
                return false;
            }

            if (['remove'].includes(action)) {


                handlePrompt(`remove session`, () => {

                    const postData = {centreId: selectedCentreId, date: date, session: selectedSession, childIds: selected, action: action};
                    console.log('postData: ', postData);
                    postMasterData('master-data/update-roll-session', postData);                

                }, setPrompt);
                
            } else if (['mark-absent'].includes(action)) {

                // invoke daily roll absent marking function to utilize the same absent/cancellation logic based on cancellation windows

                // get roll ID, required to utilise daily roll marking function 
                const updateRollEntriesAllFields = upcomingSessions.filter(obj => new Date(obj.date)?.toISOString()?.slice(0,10) === date && selected.includes(+obj.child_id) && +obj.centre_id === +selectedCentreId && obj.session === selectedSession);
                const updateRollEntries = updateRollEntriesAllFields.map(({id, centre_id, child_id}) => ({id, centre_id, child_id}))                

                // throw error if not exactly one matching session found
                if (!(updateRollEntries?.length > 0)) {
                    const msg = `No roll session found for child IDs ${selected}, centre ID ${selectedCentreId}, session ${selectedSession} on date ${date}`;
                    setError(msg);
                    return false;
                }

                const postData = {
                    rollEntries: updateRollEntries,
                    updateType: 'absent'
                };

                handlePrompt(`mark session as absent`, () => postMasterData('kiosk/update-roll', postData), setPrompt);

            } else if (['edit-in-out-times', 'add-session'].includes(action)) {

                // ensure preopulated children / dates includes current clicked on child and date, including any previously selected children
                

                
                // add selected date
                setSelectedDates([date]);

                // open correct modal
                if (action === 'edit-in-out-times') {

                    setShowAddCheckins(true);
                } else if (action === 'add-session') {

                    setShowAddChildren(true);
                }

            }

        } catch (e) {
            setError(e);
        } finally {
            setClickedCellObj();
        }


    }

    
    const onCellClick = (e, column, columnIndex, row, rowIndex) => {
        // console.log('e: ', e);
        // console.log('column: ', column);
        // console.log('columnIndex: ', columnIndex);
        // console.log('row: ', row);
        // console.log('rowIndex: ', rowIndex);
        const dataField = column?.dataField;
        const dataFieldDate = dataField?.slice(0,15);
        console.log('dataField: ', dataField);

        // if clicked cell wasn't a session, do nothing
        if (!['MON', 'TUE', 'WED', 'THU', 'FRI'].includes(dataField?.slice(0,3))) {
            console.log('Non-day clicked, doing nothing')
            return false;
        }

        const childId = +row?.childId;
        const date = new Date(dataFieldDate + " UTC")?.toISOString()?.slice(0,10);
        
        // ensure clicked child is selected 
        if (!selected?.includes(childId)) {
            const newSelected = [...selected];
            newSelected.push(childId);
            setSelected(newSelected);
        }        
        

        setClickedCellObj({
            dateString: formatDateString(date), 
            childId: childId,
            date: date,
            childName: removeBracketExpressionFromString(row?.name),
            currentValue: row?.[dataField],
            row: row,
            column: column
        })
    }    


    // override edit options for centreAdmin
    // let overrideEditOptions;
    let onClickOptions =  [
        {value: 'add-session', label: 'Add Session', className: 'btn btn-sm btn-block CustomButtonAmber'},
        {value: 'mark-absent', label: 'Cancel/Mark as Absent', className: 'btn btn-sm btn-block CustomButtonAbsent'},
        {value: 'edit-in-out-times', label: 'Edit Sign In/Out Times', className: 'btn btn-sm btn-block CustomButtonSignIn'},
        {value: 'remove', label: 'Remove Session', className: 'btn btn-sm btn-block CustomButtonDrill'},
    ];
    if (role === 'centreAdmin') {
        // overrideEditOptions = removeDisallowedOptions(bootstrapTableConfig?.[tableName]?.editableFields, ['remove']);
        onClickOptions = onClickOptions.filter(obj => !['remove'].includes(obj.value));
    }
            
            
            
            
    let table;
    const tableName = 'calculated-admin-roll-view';
    if ((selectedCentreId && selectedSession)) {
        table = (
            <Fragment>
                {formattedData && formattedData.length > 0 && 
                    <BSTable 
                    selected={selected} 
                    setSelected={setSelected} 
                    table={tableName} 
                    data={formattedData} 
                    dynamicClassFunction={dynamicClassFunction}
                    stickyHeader={'AdminRoll'}
                    onCellClick={onCellClick}
                    horizontalScroll
                    // enableEdit 
                    // updateFunction={handleRollClickAction}
                    // editOnBlur
                    // overrideEditOptions={overrideEditOptions}
                    // dynamicHeaderClassFunction={dynamicHeaderClassFunction}
                    // getDetailData={() => {return false}} 
                    // detailData={[{'no drilldown data specified': 'no drilldown data specified'}]} 
                    // expanded={} 
                    // setExpanded={() => {return false}}
                    /> 
                }

            </Fragment>
        );

    }
    if (loading) table = <SpinnerDark />;

    
    // calculate end of week date for display purposes
    let endOfWeek = new Date(displayDate);
    endOfWeek.setDate(endOfWeek.getDate() + 4);

    
    return (
        <div className={classes.AdminRoll}>
            <OptionModal show={clickedCellObj} modalClosed={() => {setSelected([]); setClickedCellObj()}} >
                {clickedCellObj && <div style={{display: 'block'}}>
                    <div className={classes.OptionModalTitle}>{clickedCellObj?.dateString} - {raw[selectedSession] ?? selectedSession}</div>
                    <div className={classes.OptionModalSubtitle}>{selected?.length > 1 ? `${selected?.length} Children` : clickedCellObj?.childName}</div>
                    <div className={classes.OptionModalSubtitle2}>{selected?.length > 1 ? '' : clickedCellObj?.currentValue}</div>
                    <hr/>
                    {onClickOptions.map(obj => <button key={obj.value} className={obj.className} onClick={() => handleRollClickAction(obj.value)}>{obj.label}</button>)}
                </div>}
            </OptionModal>
            <Banner show={bannerText}>{bannerText}</Banner>    
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>  
            <Modal show={showAddChildren} large modalClosed={() => setShowAddChildren(false)}>
                <AddChildren alreadyTickedChildren={selected} centreId={selectedCentreId} centresData={centresData} centreSessionData={centreSessionData} session={selectedSession} dates={selectedDates} childrenData={childrenData}  writeBlockBooking={writeBlockBooking}/>
            </Modal>  
            <Modal show={showAddCheckins} large  modalClosed={() => setShowAddCheckins(false)}>
                <AddCheckins alreadyTickedChildren={selected} centreId={selectedCentreId} childrenData={childrenData} session={selectedSession} dates={selectedDates}  updateCheckins={updateCheckins}/>
            </Modal>  
            <Prompt data={prompt} setData={setPrompt} />
            {/* <br/> */}
            <div className={classes.HeaderRow}>
                <h3>Weekly Roll</h3>
                <div className={classes.Row}>
                    <button className='btn btn-secondary btn-sm' onClick={() => {setSelected([]); incrementDate(displayDate, -7, setDisplayDate)}}>◀</button>
                    <h6 className={classes.DateText}>{displayDate.toDateString("en-GB", {timeZone: timezone})} - {endOfWeek.toDateString("en-GB", {timeZone: timezone})}</h6>
                    <button className='btn btn-secondary btn-sm' onClick={() => {setSelected([]); incrementDate(displayDate, +7, setDisplayDate)}}>▶</button>            
                </div>
                <div>
                    {/* <button className="btn btn-warning btn-sm" onClick={() => setShowAddCheckins(true)}>Update Check In/Outs</button>  */}
                    {/* <span> </span> */}
                    {/* {['headOffice', 'director'].includes(role) && <button className="btn btn-success btn" onClick={() => setShowAddChildren(true)}>Add Children</button>} */}
                    {<button className="btn btn-warning btn" onClick={() => setShowAddChildren(true)}>Add Children</button>}
                </div>
            </div>             
            {/* <div className={classes.HeaderButtonRow}>
            </div>               */}
            <hr />
            <div className={classes.StickyHeader}>
                <p><i>Select a service/session to view roll</i></p>
                <div className={classes.Row}>
                    {services}
                    {sessions}
                </div>
                <hr />  
                <span style={{color: 'darkred', fontWeight: 500, fontStyle: 'italic'}}>Tap on a session to edit</span>
            </div>
            <div style={{position: 'relative'}}>
                {/* {selectedCentreId && selectedSession && <div className={classes.HeaderRowRight}>
                    <button className="btn btn-secondary btn-sm" onClick={() => setRollDisplayMode('bookings')}>Bookings</button>
                    <button className="btn btn-secondary btn-sm" onClick={() => setRollDisplayMode('check-ins')}>Check-Ins</button>
                </div>  }            */}
                {table}
            </div>          

        </div>
    );
}

export default AdminRoll;

// const removeDisallowedOptions = (editableFields, disallowedValues) => {
//     // Create a deep copy of the editableFields array
//     const newEditableFields = JSON.parse(JSON.stringify(editableFields));
  
//     // Loop through each object in the editableFields array
//     newEditableFields.forEach((editableField) => {
//       // Check if the dataType is 'SELECT'
//       if (editableField.dataType === 'SELECT') {
//         // Loop through each option in the options array
//         editableField.options = editableField.options.filter((option) => {
//           // Check if the value of the option is included in the disallowedValues array
//           return !disallowedValues.includes(option.value);
//         });
//       }
//     });
  
//     // Return the modified editableFields array
//     return newEditableFields;
//   };