import {React, useState, useCallback, 
    // useEffect, 
    Fragment} from 'react';
import classes from './Debt.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import BSTable from '../../../../UI/BSTable/BSTable';
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import FileUpload from '../../../../UI/FileUpload/FileUpload';
import { debtDocTypes, bootstrapTableConfig } from '../../../../utilities/globalObjects';

import Banner from '../../../../UI/Banner/Banner';

const Debt = (props) => {

    // UI state
    // const [loadingServices, setLoadingServices] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showAltPayment, setShowAltPayment] = useState();
    const [showSupportingDocs, setShowSupportingDocs] = useState();
    const [bannerText, setBannerText] = useState('');      
    
    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);     

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [dataSource, setDataSource] = useState();
   
    // data state
    const [debtData, setDebtData] = useState();
    const [formData, setFormData] = useState();
    // const [supportingDocsFormData, setSupportingDocsFormData] = useState();
    const [files, setFiles] = useState([]);
    // const [dataSource, setDataSource] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory();  
    const location = useLocation();     
    
    // data from props
    const centresData = props.centres;

    // console logs
    console.log('debtData: ', debtData);
    console.log('dataSource: ', dataSource);
    
    // function to get data
    const getData = useCallback(async (storeDataFn, serviceCRN, routeToInvoke, additionalData) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: routeToInvoke, serviceCRN: serviceCRN, ...additionalData}
        };
        console.log('config.params: ', config.params);
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            storeDataFn(res?.data?.length > 0 ? res.data : [{'no data found': 'no data found'}]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        setLoading(false);


    }, [history, props, location]); 

    const postCCSGenericWrite = async (data, routeToInvoke) => {

        console.log('posting data: ', data);
        
        setLoading(true);
        
        // close modals
        setShowAltPayment(false);
        setShowSupportingDocs(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            data: data,
            routeToInvoke: routeToInvoke,
        };
        
        try {
            const res = await axios.post(AWS_API + 'ccs/generic-write', {postData: postData}, headers);
            console.log('res: ', res?.data);
            handleBannerText(setBannerText, `Request submitted`);
            setFormData();
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting CCS data');
            console.log('error whilst posting to generic write CCS: ', err);
        }    

        // clear data
        
        setLoading(false);
    }      

    // function to save selected centre and get enrolments within
    const handleSelectCentre = (serviceCRN) => {
        setSelectedServiceCRN(serviceCRN);
        setDataSource('read-debt');
        getData(setDebtData, serviceCRN, 'read-debt');
        
    }    
    
    const fetchAltPaymentArrangements = (serviceCRN) => {
        
        // create date object for API call 
        const dateToday = new Date().toLocaleDateString('sv-SW');
        let date60DaysAgo = new Date();
        date60DaysAgo.setDate(date60DaysAgo.getDate() - 60);
        const date60DaysAgoString = date60DaysAgo.toLocaleDateString('sv-SW');
        console.log('dateToday, date60: ', dateToday, date60DaysAgoString);
        
        // call API
        setDataSource('read-debt-alternative-arrangement');
        getData(setDebtData, serviceCRN, 'read-debt-alternative-arrangement', {dateSubmittedBefore: dateToday, dateSubmittedAfter: date60DaysAgoString});
    }

    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? ''} onChange={(e) => handleSelectCentre(e.target.value)}>
            <option disabled value=''>Select Service/Provider</option>
            <option  value='provider'>Provider</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );  
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                {debtData && 
                    <BSTable 
                    selected={selected} 
                    setSelected={setSelected} 
                    table={dataSource} 
                    data={debtData} 
                    getDetailData={() => {return false}} 
                    detailData={[{'no drilldown data specified': 'no drilldown data specified'}]} 
                    expanded={expanded} 
                    setExpanded={setExpanded}
                    /> 
                }

            </Fragment>
        );
    }

    let altPaymentForm;
    if (debtData) {
        altPaymentForm = (
            <Fragment>
                <ParamsForm 
                    data={formData} 
                    setData={setFormData} 
                    config={[
                        {name: 'paymentArrangementType', type: 'text'},
                        {name: 'paymentArrangementStartDate', type: 'date'},
                        {name: 'evenAmountsPaymentPeriod', type: 'text'},
                    ]}
                />
                <button className="btn btn-success btn-block" onClick={() => postCCSGenericWrite(formData, 'submit-alternative-payment-arrangement')}>Submit</button>

            </Fragment>
        );
    }

    let supportingDocsForm;
    if (debtData) {
        const idKey = bootstrapTableConfig[dataSource].uniqueSelectId
        const id = debtData.filter(obj => selected.includes(obj[idKey]))[0]?.alternativePaymentArrangementID;
        supportingDocsForm = (
            <Fragment>
                <h3>Upload Supporting Documents</h3>
                <hr/>
                <ParamsForm 
                    data={() => false}
                    setData={() => false}
                    config={[
                        {name: 'alternativePaymentArrangementID', type: 'text', value: id}, 
                    ]}
                />                    
                <FileUpload setFiles={setFiles} files={files} docTypes={debtDocTypes} />
                <button className='btn btn-block btn-success' onClick={() => postCCSGenericWrite({ID: id, SupportingDocuments: [...files]}, 'upload-supporting-docs-alt-payment')} >Submit</button>
        </Fragment>     
        );
    }


    
    return (
        <Fragment>
            <Banner show={bannerText}>{bannerText}</Banner>                
            <div className={classes.Debt}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <Modal show={showAltPayment} modalClosed={() => setShowAltPayment(false)}>
                    <h3>Alternative Payment Request</h3>
                    <hr/>
                    <p>{altPaymentForm}</p>
                </Modal>   
                <Modal show={showSupportingDocs} modalClosed={() => setShowSupportingDocs(false)}>
                    {supportingDocsForm}
                </Modal>   

                <h3>Debt</h3>
                <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                    <button className="btn CustomButtonPrimary btn-sm" onClick={() => fetchAltPaymentArrangements()}>View Alternative Payment Arrangements Last 60 Days</button>
                    <button className="btn btn-warning btn-sm" onClick={() => setShowAltPayment(true)}>Request Alternative Payment Arrangement</button>
                </div>                
                <hr />
                <p><i>Select a service to view debt</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                {dataSource === 'read-debt-alternative-arrangement' && 
                    <Fragment>
                        <div className={classes.Row}>
                            <button className="btn btn-warning btn-sm" onClick={() => setShowSupportingDocs(true)}>Upload Supporting Documents</button>
                        </div>
                        <hr />
                    </Fragment>
                }
                {content}
            </div>
        </Fragment>
    )
}

export default Debt;