import {React, useState, useCallback, useEffect, Fragment} from 'react';
import classes from './ServiceProfiles.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import SupportingEvidence from '../SupportingEvidence/SupportingEvidence';
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';

const ServiceProfiles = (props) => {

    // UI state
    // const [loadingServices, setLoadingServices] = useState(false);
    const [loadingProfiles, setLoadingProfiles] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    const [editing, setEditing] = useState();

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [updatedServiceData, setUpdatedServiceData] = useState();
    
    // data state
    // const [data, setData] = useState([]);
    const [serviceProfileData, setServiceProfileData] = useState([]);
    const [ACCSPercentageData ,setACCSPercentageData] = useState();

    // supporting evidence API call state
    const [showSupportingEvidenceFormFor, setShowSupportingEvidenceFormFor] = useState();    

    // care provided & vacancy state
    const [showUpdateCareVacancy, setShowUpdateCareVacancy] = useState();
    const [careVacancyData, setCareVacancyData] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory();    
    const location = useLocation();   

    // get data from props
    const centresData = props.centres;

    // console logs
    console.log('centresData: ', centresData);
    console.log('serviceProfileData: ', serviceProfileData);
    console.log('updatedServiceData: ', updatedServiceData);
    console.log('ACCSPercentageData: ', ACCSPercentageData);
    console.log('careVacancyData: ', careVacancyData);

    // function to enable editing mode
    const enableEditing = () => {


        // initalise new object with old values as defaults
        const initialUpdatedServiceObj = {
            physicalAddress: {
                ...serviceProfileData[0].Address.results.filter(obj => obj.type === 'ZPHYSICAL')[0],
            },
            postalAddress: {
                ...serviceProfileData[0].Address.results.filter(obj => obj.type === 'ZPOSTAL')[0],

            },
            serviceName: serviceProfileData[0].ServiceName.results[0].name
        }

        console.log('initial: ', initialUpdatedServiceObj);

        setUpdatedServiceData(initialUpdatedServiceObj);
        setEditing(!editing);
        
    }

    // const getData = useCallback(async () => {
        
    //     setLoadingServices(true);
    
    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: {table: 'centres'}
    //     };
        
    //     // call lambda function to get master data
    //     try {
    //         const res = await axios.get(AWS_API + 'master-data/fetch', config);
    //         console.log('data fetch res: ', res.data);
    //         setData(res.data ?? []);
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //     }            

    //     setLoadingServices(false);


    // }, [history, props]);    

    const getServiceProfileData = useCallback(async (serviceCRN) => {
        
        setLoadingProfiles(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {serviceCRN: serviceCRN}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/read-service-profile', config);
            console.log('data fetch res: ', res.data);
            setServiceProfileData(res.data ?? []);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoadingProfiles(false);


    }, [history, props, location]);

    const getACCSPercentageData = useCallback(async (serviceCRN) => {
        
        setLoadingProfiles(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: 'read-accs-percentage', serviceCRN: serviceCRN}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setACCSPercentageData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoadingProfiles(false);


    }, [history, props, location]);

    // function to update provider profile data
    const updateProfile = useCallback(async () => {
        
        setLoadingProfiles(true);

        // get the CCS start date to use as the start date in service profile update call
        const ccsStartDate = serviceProfileData[0].CCSApproval.results[0].startDate;
        
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            updatedServiceData: updatedServiceData,
            ccsStartDate: ccsStartDate,
            serviceCRN: selectedServiceCRN
        };
        try {
            const res = await axios.post(AWS_API + 'ccs/update-service-profile', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Profile updated`);
            getServiceProfileData(selectedServiceCRN);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating provider profile');
            setLoadingProfiles(false);
        }           


    }, [history, props, getServiceProfileData, updatedServiceData, serviceProfileData, selectedServiceCRN, location]); 


    const onSupportingEvidenceSuccess = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setShowSupportingEvidenceFormFor();
        handleBannerText(setBannerText, 'Supporting evidence successfully uploaded');
        
    }
    const onSupportingEvidencFailure = (err) => {
        setShowSupportingEvidenceFormFor();
        setError(handleAwsApiError(err, history, location) ?? 'Error encountered while uploading documents');
    }    

    // // get initial data
    // useEffect(() => {
    //     getData();
    // }, [getData])

    // get service profile data when service selected
    useEffect(() => {
        if (selectedServiceCRN) {
            getServiceProfileData(selectedServiceCRN);
        }
    }, [selectedServiceCRN, getServiceProfileData])


    const postCCSGenericWrite = async (data, routeToInvoke) => {

        console.log('posting return fee reduction with data: ', data);
        
        setLoadingProfiles(true);
        
        // close modals
        setShowUpdateCareVacancy(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            data: data,
            routeToInvoke: routeToInvoke,
        };
        
        // remove fields shown to user for validation but not to be submitted
        if (postData.data.childName) {
            delete postData.data.childName;
        }

        // stringify fields which needed to be integers to add correctly and show on form
        if (postData.data.totalFeeReductionAmountForWeek) {
            postData.data.totalFeeReductionAmountForWeek = postData.data.totalFeeReductionAmountForWeek.toString();
        }

        console.log('postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'ccs/generic-write', {postData: postData}, headers);
            console.log('res: ', res?.data);
            handleBannerText(setBannerText, `Data submitted`);
            // setCareVacancyData();
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting CCS data');
            console.log('error whilst posting to generic write CCS: ', err);
        }    

        // clear data
        
        setLoadingProfiles(false);
    }    

    // show inputs for editable fields if we're in editing mode
    let inputs;
    // let editableFields = ['serviceName', ''];
    if (editing) {
        inputs = (
            <Fragment>
                <hr />
                <h4>Update Profile Details</h4>
                <br/>
                <div className={classes.Row}> 
                    <span className={classes.Item}>Service Name</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.serviceName ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, serviceName: e.target.value})}}/>
                </div>  
                <br/>
                <h5>Physical Address</h5>
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 1</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.streetLine1 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, streetLine1: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 2</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.streetLine2 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, streetLine2: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Suburb</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.suburb ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, suburb: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>State</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.state ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, state: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Postcode</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.physicalAddress?.postcode ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, physicalAddress: {...updatedServiceData.physicalAddress, postcode: e.target.value}})}}/>
                </div>  
                <br/>
                <h5>Postal Address</h5>
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 1</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.streetLine1 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, streetLine1: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Street Line 2</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.streetLine2 ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, streetLine2: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Suburb</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.suburb ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, suburb: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>State</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.state ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, state: e.target.value}})}}/>
                </div>  
                <div className={classes.Row}> 
                    <span className={classes.Item}>Postcode</span> 
                    <input className="form-control" type="text" value={updatedServiceData?.postalAddress?.postcode ?? ''} onChange={(e) => {setUpdatedServiceData({...updatedServiceData, postalAddress: {...updatedServiceData.postalAddress, postcode: e.target.value}})}}/>
                </div>  
                <p> </p> 
                <button className='btn btn-success btn-block btn-sm' onClick={updateProfile}>Submit</button>             
            </Fragment>
        );
    }

    // let services = <SpinnerDark />;
    // if (!loadingServices) {
    // const serviceData = data.filter(obj => obj.service_crn);
    // const services = centresData.map(obj => {
    //     return (
    //         <button key={obj.service_crn} className="btn btn-sm btn-secondary" onClick={() => setSelectedServiceCRN(obj.service_crn)}>{obj.centre_name}</button>
    //     );
    // })
    // }

    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? ''} onChange={(e) => setSelectedServiceCRN(e.target.value)}>
            <option disabled value=''>Select Centre</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );    

    let ACCSPercentage;
    if (ACCSPercentageData) {
        ACCSPercentage = (
            <Fragment>
                <br/>
                <hr/>
                <h6>ACCS Percentage Details</h6>                
                <div className={classes.Row}> <span className={classes.Item}>ACCS Percentage Cap</span> <div>{ACCSPercentageData?.ACCSPercentage?.ACCSPercentageCap}</div> </div>
                <div className={classes.Row}> <span className={classes.Item}>Number of Children at Risk</span> <div>{ACCSPercentageData?.ACCSPercentage?.numberOfChildAtRisk}</div> </div>
                <div className={classes.Row}> <span className={classes.Item}>Percentage of Children at Risk</span> <div>{ACCSPercentageData?.ACCSPercentage?.percentageOfChildAtRisk}</div> </div>
                <div className={classes.Row}> <span className={classes.Item}>Certificate or Aplication To Be Submitted</span> <div>{ACCSPercentageData?.ACCSPercentage?.certificateOrApplicationToBeSubmitted}</div> </div>
                <br/>

            </Fragment>
        );
    }

    let profile = <SpinnerDark />;
    if (!loadingProfiles) {
        profile = serviceProfileData.map(obj => {
            return (
            <div key={obj.serviceID} className={classes.ServiceProfile}>
                    <h5>{obj.ServiceName.results[0].name}</h5>
                    <br />
                    <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                        <button className="btn CustomButtonPrimary" onClick={() => setShowUpdateCareVacancy(true)}>Update Care/Vacancy</button>
                        <button className="btn btn-light" onClick={() => getACCSPercentageData(obj.serviceID)}>Query ACCS %</button>
                        <button className="btn CustomButtonPrimary" onClick={enableEditing}>Edit Profile</button>
                        <button className="btn btn-light" onClick={() => setShowSupportingEvidenceFormFor(obj.serviceID)}>Upload Supporting Evidence</button>
                    </div>
                    <div className={classes.Row}> <span className={classes.Item}>Service ID</span> <div>{obj.serviceID}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>CCS Approval Start Date</span> <div>{obj.CCSApproval.results[0].startDate}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>CCS Approval End Date</span> <div>{obj.CCSApproval.results[0].endDate}</div> </div>    
                    <br/>
                    <hr/>
                    <h6>Physical Address</h6>                
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 1</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].streetLine1}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 2</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].streetLine2}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Suburb</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].suburb}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>State</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].state}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Postcode</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPHYSICAL')[0].postcode}</div> </div>
                    <br/>
                    <hr/>
                    <h6>Postal Address</h6>                
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 1</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].streetLine1}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Street Line 2</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].streetLine2}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Suburb</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].suburb}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>State</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].state}</div> </div>
                    <div className={classes.Row}> <span className={classes.Item}>Postcode</span> <div>{obj.Address.results.filter(obj => obj.type==='ZPOSTAL')[0].postcode}</div> </div>
                    {ACCSPercentage}
                    {inputs}
            </div>
            );
        });
    }

    return (
        <Fragment>
            <Banner show={bannerText}>{bannerText}</Banner>                
            <div className={classes.ServiceProfiles}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <Modal show={showSupportingEvidenceFormFor} modalClosed={() => setShowSupportingEvidenceFormFor(false)}>
                    {showSupportingEvidenceFormFor && <SupportingEvidence token={props.token} onSuccess={onSupportingEvidenceSuccess} onFailure={onSupportingEvidencFailure} documentsFor='service' id={showSupportingEvidenceFormFor}/>}
                </Modal>               
                <Modal show={showUpdateCareVacancy} modalClosed={() => setShowUpdateCareVacancy(false)}>
                    <h3>Update Care Provided and Vacancy</h3>
                    <hr/>
                    <ParamsForm 
                        data={careVacancyData} 
                        setData={setCareVacancyData} 
                        config={[
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'email', type: 'text'},
                            {name: 'phone', type: 'text'},
                            {name: 'mobile', type: 'text'},
                            {name: 'website', type: 'text'},
                            // {name: 'mondayOpen', type: 'text'},
                            // {name: 'mondayClose', type: 'text'},

                        ]}
                    />
                    <button className="btn btn-success btn-block" onClick={() => postCCSGenericWrite(careVacancyData, 'update-care-vacancy')}>Submit</button>
                </Modal>               
                <h3>Service Profiles</h3>
                <hr />
                <p><i>Select a service to view profile</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                {profile}

            </div>
        </Fragment>
    )
}

export default ServiceProfiles;