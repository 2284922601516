import {React, useState, useCallback, 
    // useEffect, 
    Fragment} from 'react';
import classes from './Messages.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, downloadPDFFromBase64 } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import BSTable from '../../../../UI/BSTable/BSTable';
import { bootstrapTableConfig } from '../../../../utilities/globalObjects';

// import Banner from '../../../../UI/Banner/Banner';

const Messages = (props) => {

    // UI state
    // const [loadingServices, setLoadingServices] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    // const [bannerText, setBannerText] = useState('');      
    
    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);     

    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
   
    // data state
    const [data, setData] = useState();
    const [dataSource, setDataSource] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory();  
    const location = useLocation();     
    
    // data from props
    const centresData = props.centres;

    // console logs
    console.log('data: ', data);
    
    // function to get data
    const getData = useCallback(async (serviceCRN, routeToInvoke, additionalData) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: routeToInvoke, serviceCRN: serviceCRN, ...additionalData}
        };
        console.log('config.params: ', config.params);
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setData(res?.data?.length > 0 ? res.data : [{'no data found': 'no data found'}]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        setLoading(false);


    }, [history, props, location]); 

    const getCorrespondencePDF = useCallback(async (serviceCRN, routeToInvoke, messageLink, subject) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: routeToInvoke, serviceCRN: serviceCRN, messageLink: messageLink}
        };
        console.log('config.params: ', config.params);
        
        // call lambda function to get certificate data
        let pdfBase64;        
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            const row = res.data[0];
            pdfBase64 = row.attachmentContent;
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }       
        
        
        downloadPDFFromBase64(pdfBase64, subject.replaceAll(' ', '_'));        

        setLoading(false);


    }, [history, props, location]);     

    const handleMessageType = (routeToInvoke) => {

        // exit if no service/provider selected
        if (!selectedServiceCRN) {
            alert('Please select a service or provider');
            return false;
        }

        // call get data function with route type 
        setDataSource(routeToInvoke);
        getData(selectedServiceCRN, routeToInvoke)

    }

    const handleGetCorrespondence = (selectedIds) => {
        
        if (selectedIds.length !== 1) {
            alert('Please select one row');
            return false;
        }

        // get selected row
        const id = selectedIds[0];
        const idKey = bootstrapTableConfig['read-messages-correspondences'].uniqueSelectId;
        const row = data.filter(obj => obj[idKey] === id)[0];
        console.log('row: ', row);


        // call get data function passing in id of correspondence message to get
        getCorrespondencePDF(selectedServiceCRN, 'read-messages-correspondence', row.link, row.subject)
    }
    
    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? ''} onChange={(e) => setSelectedServiceCRN(e.target.value)}>
            <option disabled value=''>Select Service/Provider</option>
            <option  value='provider'>Provider</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );  
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                {data && 
                    <BSTable 
                    selected={selected} 
                    setSelected={setSelected} 
                    table={dataSource} 
                    data={data} 
                    getDetailData={() => {return false}} 
                    detailData={[{'no drilldown data specified': 'no drilldown data specified'}]} 
                    expanded={expanded} 
                    setExpanded={setExpanded}
                    /> 
                }

            </Fragment>
        );
    }


    
    return (
        <Fragment>
            {/* <Banner show={bannerText}>{bannerText}</Banner>                 */}
            <div className={classes.Messages}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>    
                <h3>Messages</h3>
                <hr />
                <p><i>Select a service to view notifications/correspondence</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                <div className={classes.Row}>
                    <button className="btn CustomButtonPrimary btn-sm" onClick={() => handleMessageType('read-messages-notifications')}>Get Notifications</button>
                    <button className="btn CustomButtonPrimary btn-sm" onClick={() => handleMessageType('read-messages-correspondences')}>Get Correspondence List</button>
                    <button className="btn btn-warning btn-sm" onClick={() => handleGetCorrespondence(selected)}>Download Correspondence</button>
                </div>
                <hr />
                {content}

            </div>
        </Fragment>
    )
}

export default Messages;