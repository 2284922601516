import { React, useState, useEffect, useCallback } from 'react';
import { scrollPageToTop, handleBannerText, handleAwsApiError } from '../../../../utilities/functions';
import { formioAPI, AWS_API, cognitoClientId, cognitoUserpoolId
    , public_s3_url
 } from '../../../../utilities/globalVariables';
import { useHistory, useLocation } from "react-router-dom";
import classes from './Enrol.module.css';
import Modal from '../../../../UI/Modal/Modal';
import axios from 'axios';
import Formio from 'formiojs/Formio';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
// import Spinner from '../../../../UI/Spinner/Spinner';
import { raw } from '../../../../translations/en/raw';
import Banner from '../../../../UI/Banner/Banner';
// import { formioConfig } from '../../../../utilities/globalObjects';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
// import SimpleFormio from '../../../Misc/SimpleFormio/SimpleFormio';
import WelcomePopup from './WelcomePopup/WelcomePopup';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import {cloneDeep} from 'lodash';

// import { cognitoURL } from '../../../utilities/globalVariables';
// import { Form } from 'react-formio';

const Enrol = (props) => {

    // data state
    const [draftSubmission, setDraftSubmission] = useState();
    const [submittedCentreId, setSubmittedCentreId] = useState();
    const [pendingEnrolmentFormObjs, setPendingEnrolmentFormObjs] = useState([]);
    const [completedEnrolmentFormObjs, setCompletedEnrolmentFormObjs] = useState([]);
    const [failedEnrolmentFormObjs, setFailedEnrolmentFormObjs] = useState([]);
    // const [pendingBulkEnrolForms, setPendingBulkEnrolForms] = useState();
    // const [completedEnrolmentForms, setCompletedEnrolmentForms] = useState();
    // const [sampleObject, setSampleObject] = useState();
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [loadingBackdrop, setLoadingBackdrop] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();
    const [showInvalidModal, setShowInvalidModal] = useState(false);
    const [missingFields, setMissingFields] = useState();
    const [formToShow] = useState('enrol');
    const [actualFormType, setActualFormType] = useState();
    const [editingFormDetails, setEditingFormDetails] = useState();
    const [showFeedbackModal, setShowFeedbackModal] = useState();
    
    // form help state
    // const [hasFailedValidation, setHasFailedValidation] = useState();
    // const [helpCode, setHelpCode] = useState();

    // console logs
    // console.log('draftSubmission: ', draftSubmission);
    // console.log('editingFormDetails: ', editingFormDetails);
    // console.log('completedEnrolmentForms: ', completedEnrolmentForms);
    console.log('completedEnrolmentFormObjs: ', completedEnrolmentFormObjs);

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    let location = useLocation();    

    // get data from props
    const setSublinks = props.setSublinks;

    const getSampleEnrolData = useCallback( async () => {
        try {
            const res = await axios.get(public_s3_url + 'enrolTestSubmission.json');
            console.log('response from s3 reference data: ', res.data);
            const newSampleData = {
                ...res.data,
            };
            // update email to signed in user so they can use this sample data and update secondary guardian email to a random email address so RDS unique constraint isn't triggered
            newSampleData.data.primaryGuardian.email = props.email;
            newSampleData.data.secondaryGuardian.email = props.email + '.au';
            setDraftSubmission(res.data); 
            // console.log('sampleObject: ', res.data.formSubmissions[0]);         
        } catch (err) {
            console.log('error: ', err);
        }
    }, [props.email]);

    // function to manually save draft, this one won't get overwrited on any form change event which can be a little buggy
    const manuallySaveDraft = useCallback (() => {
        const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
        if (localStorage.getItem(draftName)) {
            const autoSavedDraft = localStorage.getItem(draftName);
            const manualSaveKey = draftName + '-manualSave';
            localStorage.setItem(manualSaveKey, autoSavedDraft);
            console.log('manually saved submission to: ', manualSaveKey);
        } else {
            alert('no form changes detected yet, saving will be possible after beginning to fill out the form');
        }    
    }, [formToShow]);

    // function to handle fetching of bulk enrol forms and optionally pre-populating form data form a previously submitted form
    // const handleFetchBulkEnrolForm = useCallback(async (formData, formToShow, prevFormDetails) => {
        

    //     setLoadingBackdrop(true);
        
    //     // pull data out of form submissions row
    //     const formId = formData.form_id;
    //     const formType = formData.form_type;
    //     const centreId = formData.centre_id;

    //     console.log('fetching form: ', formId, formType);

    //     // get optional params passed in
    //     const showFormType = formToShow ?? formioConfig?.[formType]?.useForm;


    //     // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
    //     const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
        
    //     const postData = {ids: [formId], formType: formType, centreId: centreId, submittedBy: props.email};
        
    //     // get actual submission for found form_id from S3
    //     try {
    //         const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
    //         console.log('[handleFetchBulkEnrolForm] - form fetch res: ', res.data);
            
            
    //         // if previous form submission found, populate guardian data with that to save having to enter it twice
    //         const prepopulatedData = {...res.data.formSubmissions[0]};
    //         const ccsEnrolmentId = prepopulatedData?.ccsEnrolmentId;
    //         if (prevFormDetails) {
    //             // update postData for previous form to fetch
    //             postData.ids = [prevFormDetails.formId];
    //             postData.formType = prevFormDetails.formType;
    //             const prevFormRes = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
    //             const prevForm = prevFormRes?.data?.formSubmissions?.[0]?.data;
    //             console.log('prevFormRes: ', prevFormRes);
    //             console.log('prevForm: ', prevForm);
    //             if (prevForm) {
    //                 prepopulatedData.data.primaryGuardian = {...prevForm?.primaryGuardian};
    //                 prepopulatedData.data.secondaryGuardian = {...prevForm?.secondaryGuardian};
    //             }
    //         }
            
            
    //         // get form submission data and save in state and other important data attached to form we want to preserve in posted submission (i.e. ccsEnrolment ID for bulk enrol forms migrated from another system)
    //         // console.log('prepopulatedData: ', prepopulatedData);
    //         setFormToShow(showFormType);
    //         setActualFormType(formType);
    //         setEditingFormDetails({formId: formId, ccsEnrolmentId: ccsEnrolmentId});
    //         console.log('set draft submission to :', prepopulatedData); 
            
    //         // add draft submission with timeout so form loads first
    //         // setTimeout(() => {
    //         //     console.log('set draft submission');
    //         //     setDraftSubmission(prepopulatedData);       
    //         // }, draftSubmissionDelay);
            
    //         setDraftSubmission(prepopulatedData);       

    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
    //     }                

    //     setLoadingBackdrop(false);


    // }, [history, props.token, location, props.email]);        


    // set leftpane sublinks
    useEffect(() => {

        // object to store links to pass up to App.js and render through Layout.js
        let newSublinks = [];

        // render a button for each form requiring action
        if (pendingEnrolmentFormObjs?.length > 0) {
            pendingEnrolmentFormObjs.forEach((obj, i) => {

                // create deep clone of object to put into form submission without changing original object if this form is loaded
                const deepCloneObj = cloneDeep(obj);
                
                // only show link to first completed form on mobile to prevent too many icons
                let icon, shortName;
                if (i === 0) icon = 'import'; shortName = 'Migrated #' + obj?.formId;
                newSublinks.push( {header: 'Migrated Enrolment Data', title: 'Migrated Form #' + obj?.formId, icon: icon, shortName: shortName, type: 'action',  onClick: () => {setEditingFormDetails({formId: deepCloneObj?.formId}); setActualFormType('bulkEnrol'); setDraftSubmission(deepCloneObj);}}) 
            });
        }        
        
        
        // render a button for each completed enrolment form if user wants to start from a "template"
        if (completedEnrolmentFormObjs?.length > 0) {
            completedEnrolmentFormObjs.forEach((obj, i) => {

                // create deep clone of object to put into form submission without changing original object if this form is loaded
                const deepCloneObj = cloneDeep(obj);
                
                // only show link to first completed form on mobile to prevent too many icons
                let icon, shortName;
                if (i === 0) icon = 'import'; shortName = 'Completed #' + obj?.formId; 
                newSublinks.push( {header: 'Load From Completed Form', icon: icon, shortName: shortName, title: 'Completed Form #' + obj?.formId, onClick: () => {setEditingFormDetails({}); setActualFormType(); setDraftSubmission(deepCloneObj);}}) 
            });
        }
        
        // render a button for each failed enrolment form if user wants to start from a "template", currenlty only pulling most recent failure
        if (failedEnrolmentFormObjs?.length > 0) {
            failedEnrolmentFormObjs.forEach((obj, i) => {

                // create deep clone of object to put into form submission without changing original object if this form is loaded
                const deepCloneObj = cloneDeep(obj);
                
                // only show link to first completed form on mobile to prevent too many icons
                let icon, shortName;
                if (i === 0) icon = 'import'; shortName = 'Failed #' + obj?.formId; 
                newSublinks.push( {header: 'Last Failed Submission', icon: icon, shortName: shortName, title: 'Failed Submission #' + obj?.failedFormId, onClick: () => {setEditingFormDetails({}); setActualFormType(); setDraftSubmission(deepCloneObj);}}) 
            });
        }

        // newSublinks.push({title: 'Insert Sample Data', icon: 'calendar',  onClick: () => getSampleEnrolData()})
        newSublinks.push({header: 'Save/Load', title: 'Save Draft', icon: 'save', shortName: 'Save', onClick: () => manuallySaveDraft()})
        
        
        // check for previously manually saved draft submission, and if it exists render a button to load it
        const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
        const manualDraftName = draftName + '-manualSave';
        if (localStorage.getItem(manualDraftName)) {
            newSublinks.push( {header: 'Save/Load', title: 'Load Saved Draft', icon: 'load', shortName: 'Load Saved',  onClick: () => setDraftSubmission(JSON.parse(localStorage.getItem(manualDraftName)))}) 
        }
        
        if (localStorage.getItem(draftName)) {
            // const draft = JSON.parse(localStorage.getItem(draftName));
            // console.log('found draft submission: ', draft);
            newSublinks.push( {header: 'Save/Load', title: 'Load Auto-Saved Draft', icon: 'load', shortName: 'Load Autosaved',  onClick: () => setDraftSubmission(JSON.parse(localStorage.getItem(draftName)))}) 
        }
        
        setSublinks(newSublinks);

    }, [setSublinks, formToShow, getSampleEnrolData, manuallySaveDraft, pendingEnrolmentFormObjs, completedEnrolmentFormObjs, failedEnrolmentFormObjs])    


    // function to call API to get data
    const fetchData = useCallback(async (tableName) => {
        
        // if token found, call lambda function to account master data
        if (props.token) {
            
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {tableName: tableName}
            };
            try {
                const res = await axios.get(AWS_API + 'account-owner-data/fetch', config);
                // console.log('response: ', res.data);
                return res.data;
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
            }   
        } else {
            console.log('no token, redirecting to auth');
            history.replace('/auth');
        }
    }, [history, props.token, location]);  
    


    // const manuallyInsertFormData = () => {
    //     setDraftSubmission({...draftSubmission});
    // }

    // effect to get enrolment submissions for signed in user to determine which form to show them and what data to pre-populate
    useEffect(() => {

        // loading true before calling data fetch functions
        setLoadingBackdrop(true); 
          
        // anon async function to await the data response and put into state
        (async () => {

            // look for original enrolment form submitted by signed in user recorded in RDS table form_submissions
            const foundSubmissions = await fetchData('form_submissions');
            console.log('[Enrol.js useEffect] - foundSubmissions: ', foundSubmissions);
            
            const foundPendingBulkEnrol = foundSubmissions?.filter(obj => obj.form_type === 'bulkEnrol' && obj.status !== 'submitted');
            const pendingFormIds = foundPendingBulkEnrol?.map(obj => +obj.form_id).filter((x, i, a) => a.indexOf(x) === i);
            console.log('[Enrol.js useEffect] - foundPendingBulkEnrol: ', foundPendingBulkEnrol);
            // setPendingBulkEnrolForms(foundPendingBulkEnrol);
            
            // ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------
            // fetch each pending form to load as template if required
            const pendingFormIdsInState = pendingEnrolmentFormObjs?.map(obj => +obj.formId);
            console.log('[Enrol.js useEffect] - pendingFormIdsInState: ', pendingFormIdsInState);
            console.log('[Enrol.js useEffect] - pendingFormIds: ', pendingFormIds);
            const newPendingEnrolmentFormObjs = [...pendingEnrolmentFormObjs];
            for (const formId of pendingFormIds) {   
                
                // check we don't already have form JSON in state first
                if (!pendingFormIdsInState.includes(formId)) {
                    
                    const form = foundPendingBulkEnrol.filter(obj => obj.form_id === formId)?.[0];
                    const originalFormId = form.form_id;
                    const originalFormType = form.form_type;
                    const centreId = form.centre_id;
                    
                    // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
                    const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
                    
                    const postData = {ids: [originalFormId], centreId: centreId, formType: originalFormType, submittedBy: props.email};
                    
                    // get actual submission for found form_id from S3
                    try {
                        const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
                        console.log('[Enrol.js useEffect] - pendingBulkEnrol fetch: ', res.data);
                        
                        // create object with pre-filled data and remove parts that need to be filled in for the new child being enrolled
                        const formSubmission = res.data?.formSubmissions?.[0];
                        newPendingEnrolmentFormObjs.push(formSubmission);
                        
                        
                    } catch (err) {
                        setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                    }   
                }
            }
            // ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------
            
            
            const foundOriginalEnrolments = foundSubmissions.filter(obj => (obj.form_type === 'enrol' || obj.form_type === 'bulkEnrol') && obj.status === 'submitted').sort((a,b) => new Date(b.submitted_at).getTime() - new Date(a.submitted_at).getTime());
            const completedFormIds = foundOriginalEnrolments.map(obj => +obj.form_id).filter((x, i, a) => a.indexOf(x) === i);
            console.log('[Enrol.js useEffect] - foundOriginalEnrolment: ', foundOriginalEnrolments);
            // setCompletedEnrolmentForms(foundOriginalEnrolments);
            
            // ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------
            // fetch each completed form to load as template if required
            const formIdsInState = completedEnrolmentFormObjs?.filter(obj => obj?.formId)?.map(obj => +obj?.formId);
            console.log('[Enrol.js useEffect] - formIdsInState: ', formIdsInState);
            console.log('[Enrol.js useEffect] - completedFormIds: ', completedFormIds);
            const newCompletedEnrolmentFormObjs = [...completedEnrolmentFormObjs]
            for (const formId of completedFormIds) {   
                
                console.log('fetching form JSON for form: ', formId);
                
                // check we don't already have form JSON in state first
                if (!formIdsInState.includes(formId)) {
                    
                    const form = foundOriginalEnrolments.filter(obj => obj.form_id === formId)?.[0];
                    const originalFormId = form.form_id;
                    const originalFormType = form.form_type;
                    const centreId = form.centre_id;
                    
                    // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
                    const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
                    
                    const postData = {ids: [originalFormId], centreId: centreId, formType: originalFormType, submittedBy: props.email};
                    
                    // get actual submission for found form_id from S3
                    try {
                        const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
                        console.log('[Enrol.js useEffect] - completed forms fetch: ', res.data);
                        
                        // create object with pre-filled data and remove parts that need to be filled in for the new child being enrolled
                        const formSubmission = res.data?.formSubmissions?.[0];
                        newCompletedEnrolmentFormObjs.push(formSubmission ?? {formId: formId});
                        
    
                    } catch (err) {
                        setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                    }   
                }
            }
            // ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------
            
            
            
            // ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------
            // finally check for latest failed submission in case user needs to load form from that template
            const failedSubmissions = await fetchData('failed_form_submissions');
            console.log('[Enrol.js useEffect] - failedSubmissions: ', failedSubmissions);      
            const newFailedEnrolmentFormObjs = [...failedEnrolmentFormObjs]
            if (failedSubmissions?.length > 0) {
                const mostRecentFailedSubmission = failedSubmissions.sort((a,b) => new Date(b.submitted_at).getTime() - new Date(a.submitted_at).getTime())?.[0];
                console.log('mostRecentFailedSubmission: ', mostRecentFailedSubmission)
                const failedFormIdsInState = failedEnrolmentFormObjs.map(obj => +obj.formId);
                console.log('[Enrol.js useEffect] - failedFormIdsInState: ', failedFormIdsInState);

                if (!failedFormIdsInState?.length > 0) {
                    
                    const originalFormId = mostRecentFailedSubmission.form_id;
                    const originalFormType = mostRecentFailedSubmission.form_type;
                    
                    // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
                    const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
                    
                    const postData = {ids: [originalFormId], formType: originalFormType, submittedBy: props.email, failedSubmission: true};
                    
                    // get actual submission for found form_id from S3
                    try {
                        const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
                        console.log('[Enrol.js useEffect] - failed forms fetch: ', res.data);
                        
                        // create object with pre-filled data and remove parts that need to be filled in for the new child being enrolled
                        const formSubmission = res.data?.formSubmissions?.[0];
                        newFailedEnrolmentFormObjs.push(formSubmission);
                        
    
                    } catch (err) {
                        setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                    }   
                }    
            }
            // ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------
            
            // update state if forms have been added
            if (newCompletedEnrolmentFormObjs?.length > completedEnrolmentFormObjs?.length) {
                setCompletedEnrolmentFormObjs(newCompletedEnrolmentFormObjs);
            }
            if (newPendingEnrolmentFormObjs?.length > pendingEnrolmentFormObjs?.length) {
                setPendingEnrolmentFormObjs(newPendingEnrolmentFormObjs);
            }
            if (newFailedEnrolmentFormObjs?.length > failedEnrolmentFormObjs?.length) {
                setFailedEnrolmentFormObjs(newFailedEnrolmentFormObjs);
            }
            // auto-populate form with either bulkEnrol form or template from previously filled out form
            // if (foundPendingBulkEnrol?.length > 0) {

            //     console.log('found pending bulk enrol forms, load up first of those');
            //     const obj = foundPendingBulkEnrol?.[0];
                
            //     // check if one of these forms has already been submitted
            //     if (foundOriginalEnrolments?.length > 0) {
            //         // previous form submission found, to be submitted form must be complete so use that data for guardian data 
            //         const prevForm = foundOriginalEnrolments[0];
            //         console.log('fetching bulkEnrol form but prepopulating guardian data with most recent user-submitted form: ', prevForm);
                    
            //         // trialling only using enrol form for all enrolments
            //         handleFetchBulkEnrolForm(obj, 'enrol', {formId: prevForm.form_id, formType: prevForm.form_type});
            //         // handleFetchBulkEnrolForm(obj, 'enrolNewChild', {formId: prevForm.form_id, formType: prevForm.form_type});
            //     } else {
            //         // no other enrolment found, so just fetch the bulk enrol form and populate form with that 
            //         console.log('no previous user-submitted form found, fetching bulkEnrol and displaying form data as migrated from other system');
            //         handleFetchBulkEnrolForm(obj);
            //     }
                
                
            // } else if (foundOriginalEnrolments?.length > 0) {
                
                // trialling only using enrol form for all enrolments
                // setFormToShow('enrol');
                // // setFormToShow('enrolNewChild');
                
                // const form = foundOriginalEnrolments[0];
                // const originalFormId = form.form_id;
                // const originalFormType = form.form_type;
                // const centreId = form.centre_id;

                // // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
                // const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
                
                // const postData = {ids: [originalFormId], centreId: centreId, formType: originalFormType, submittedBy: props.email};
                
                // // get actual submission for found form_id from S3
                // try {
                //     const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
                //     console.log('response from form-submissions/fetch: ', res.data);
                    
                //     // create object with pre-filled data and remove parts that need to be filled in for the new child being enrolled
                //     const prepopulatedData = {...res.data.formSubmissions[0]};
                //     const newChild1CRN = res.data.formSubmissions[0].data.child.crn;
                //     setChild1CRN(newChild1CRN);
                //     console.log('child1CRN: ', newChild1CRN);
                //     console.log('prepopulated data: ', prepopulatedData);
                //     delete prepopulatedData.data.primaryGuardian.relationshipToChild;
                //     delete prepopulatedData.data.primaryGuardian.livesWithChild;
                //     delete prepopulatedData.data.primaryGuardian.authorisation;
                //     delete prepopulatedData.data.secondaryGuardian.relationshipToChild;
                //     delete prepopulatedData.data.secondaryGuardian.authorisation;
                //     delete prepopulatedData.data.child;
                //     delete prepopulatedData.data.confirmedTCs;
                //     delete prepopulatedData.data.medical;
                //     delete prepopulatedData.data.documents;
                //     delete prepopulatedData.data.other.courtOrders1;
                //     delete prepopulatedData.data.other.courtOrders2;
                //     delete prepopulatedData.data.other.immunisationsUpToDate;
                //     delete prepopulatedData.data.other.photoPermissionsExternal;
                //     delete prepopulatedData.data.other.photoPermissionsInternal;
                //     delete prepopulatedData.data.other.groupAuthTransport;
                //     delete prepopulatedData.data.other.groupAuthEvac;
                //     delete prepopulatedData.data.signature;
                //     console.log('prepopulated data after deletion: ', prepopulatedData);

                //     // add draft submission with timeout so form loads first
                //     // setTimeout(() => {
                //     //     console.log('set draft submission');
                //     //     setDraftSubmission(prepopulatedData);       
                //     // }, draftSubmissionDelay);

                // } catch (err) {
                //     setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                // } finally {
                //     setLoading(false);
                // }                

                
            // } else {
            //     setFormToShow('enrol');
            //     setLoading(false);
            // }
            setLoadingBackdrop(false);
        })();

        // upon useEffect completion, set loading to false
        // return () => {
        //     setLoading(false);
        // }    

    }, [fetchData, history, props.token, props.email, location, completedEnrolmentFormObjs, pendingEnrolmentFormObjs, failedEnrolmentFormObjs]);    

    // console.log('draftSubmission: ', draftSubmission);

    // function to intercept submission and send data to S3 instead of form.io servers
    const postSubmission = useCallback(async (submission) => {
        // console.log('posting submission: ', submission);
        // return false;

        setLoading(true);

        // if user is enrolling an additional child, check that CRN isn't the same as the first child enrolled's CRN
        const newChildCRN = submission?.child?.crn;
        const newCentreId = submission?.child?.centre;
        const existingChildCRNCentres = completedEnrolmentFormObjs.map(obj => ({crn: obj?.data?.child?.crn, centreId: obj?.centreId}));
        console.log('existingChildCRNCentres: ', existingChildCRNCentres);
        let crnCentreComboExists;
        for (const obj of existingChildCRNCentres) {
            console.log('[postSubmission] - crn/centre check: ', obj.crn, obj.centreId);
            if (newChildCRN && newChildCRN !== '' && obj.crn === newChildCRN && obj.centreId === newCentreId) {
                crnCentreComboExists = true;
            }
        };

        // only carry on if crn/centre not already enrolled
        if (crnCentreComboExists) {
            setLoading(false);
            setError(`An enrolment form has already been submitted for the given child's CRN of ${newChildCRN} and the selected centre. Please check the centre/child you are attempting to enrol with and try again, or call us for further help. Click "Load Auto-Saved Draft" to continue filling out your form.`);
            setDraftSubmission(draftSubmission);
            
        } else {
            let originalFormMetadata;
            if (editingFormDetails?.formId) {
                originalFormMetadata = Object.assign({}, editingFormDetails);
                delete originalFormMetadata.data;
                
            }            
            
            // first record this submission in RDS, generate ID and use this to save in S3
            // let formId = null;
            console.log('posting submission: ', submission);
            
            // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            const postData = {...originalFormMetadata, data: submission, formType: actualFormType ?? formToShow, centreId: submission.child.centre};
            
            try {
                const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
                console.log('response from postSubmission to S3: ', res.data);
                // formId = res.data.formWrittenId;
            } catch (err) {
                setLoading(false);
                setDraftSubmission(draftSubmission);
                setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
                return false;
            }
    
            // save submitted centreId into state to use in feedback form
            setSubmittedCentreId(submission.child.centre);
            
            // show success banner and then redirec to accountOwner home
            handleBannerText(setBannerText, 'Enrolment Submitted');
            setLoading(false);
            setShowFeedbackModal(true);

        }

            

    }, [history, props.token, actualFormType, draftSubmission, location, editingFormDetails, formToShow, completedEnrolmentFormObjs]);


    console.log('draftSubmission: ', draftSubmission);

    // render form.io form using SDK on component render or submission change and attach listeners to deal with custom submission
    useEffect(() => {

        setLoading(true);

        if (formToShow) {
            Formio.createForm(document.getElementById('formio'), formioAPI + formToShow )
            .then((form) => {
        
                

        
                // function to handle custom submission, check for validity and either warn user or submit form
                const customSubmit = (submission) => {


                    

                    const isValid = form.checkValidity(null, true, null, false);
                    console.log('isValid: ', isValid);  
                    
                    // if form is invalid, figure out which fields haven't been touched and alert user, otherwise submit form
                    if (!isValid) {

                        // form is invalid, post a copy to the help folder in case staff assistance needed
                        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
                        const postData = {data: submission, formType: formToShow};
                        axios.post(AWS_API + 'form-submissions/help', {postData: postData}, headers);

                        form.setPristine(false);
                        
                        // initalise array to add empty fields if form fails validation
                        const newMissingFields = [];
    
                        // iterate through components and check any that are visible, required and have no value given - these have likely been missed by user
                        form.everyComponent((component) => {
                            const required = component.component.validate.required;
                            const value = component['_data'][component.component.key];
                            const visible = component['_visible'];
                            const valueType = typeof value;
                            let valueConstruct
                            if (valueType === 'object') {valueConstruct = value.constructor}

                            const path = component.path;
                            const label = component.component.label;
                            const parentLabel = component.parent.label;
                            const parentTitle = component.parent.component.title;
                            const tab = component.tab;                            

                            if (component.component.label.toLowerCase().includes('practice address')) {
                                console.log('component including address: ', component);
                                console.log('label: ', label);
                                console.log('visible: ', visible);
                                console.log('required: ', required);
                                console.log('value: ', value);
                                console.log('valueType: ', valueType);
                                console.log('valueConstruct: ', valueConstruct);                                
                                console.log('object keys len: ', Object.keys(value).length);                                
                            }
                                // console.log('every comp: ', component);
                                
                            if (required && visible && (!value || (valueConstruct === Object && Object.keys(value).length === 0))) {
                                

                                
                                // construct display label with best info we've got 
                                let displayLabel = path;
                                if (tab) {
                                    displayLabel = component.parent.component.components[tab].label;
                                } else if (parentTitle) {
                                    displayLabel = parentTitle
                                } else if (parentLabel) {
                                    displayLabel = parentLabel;
                                } 
                                
                                console.log('missing value for: ', displayLabel);
                                console.log('component: ', component);
                                console.log('tab: ', tab);
                                console.log('parentLabel: ', parentLabel);
                                console.log('parentTitle: ', parentTitle);
                                
                                newMissingFields.push(displayLabel + ' - ' + label);
                            }
                        });
                        
                        // set state to show empty fields to user in modal 
                        if (newMissingFields.length <= 0) newMissingFields.push("Apologies, we can't find the missing field - please look through the form for the red error message");
                        setMissingFields(newMissingFields);
                        setShowInvalidModal(true);
                        // setHasFailedValidation(true);
                    } else {
                       
                        // form passed validation, submit
                        postSubmission(submission);
                    }
                }
        
                // add listener with custom functions
                form.on('CustomSubmit', (submission) => customSubmit(submission));
                form.on('NextTab', scrollPageToTop);
                
                // set on change handler to write changes to local storage so user can restore from saved drafts based on which form they're filling out
                const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
                form.on('initialized', () => {
                    setTimeout(() => {
                        form.on('change', (submission) =>{ 
                            localStorage.setItem(draftName, JSON.stringify({data: submission.data}));
                            console.log('changed submission: ', submission.data);
                        });
                    }, 1000);
                    
                });    

                // if a draft has been restored, populate form with draft submission
                if (draftSubmission || formToShow === 'enrolNewChild') {
                    form.ready.then(() => form.setSubmission(draftSubmission));
                    console.log('inserted draft submission into form');
                    
                    // if no draft submission, set form submission with users email
                } else {
                    form.ready.then(() => form.setSubmission({data: {primaryGuardian: {email: props.email}}}));
                    console.log('Set blank form with email of user');
                }                
    
                // wide array of other methods that can be used here, i.e. form.everyComponent
                // full list in docs here: https://help.form.io/developers/form-renderer 
            })
        }


        // setTimeout(() => {
            setLoading(false);
        // }, 1000);

    }, [postSubmission, props.email, props.token, draftSubmission, formToShow])

    // console.log('sampleObject: ', sampleObject);



    // help function for those who can't get form to submit
    // const submitFormForHelp = async () => {

        
    //     const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
    //     if (localStorage.getItem(draftName)) {
    //         setLoading(true);
    //         const draft = JSON.parse(localStorage.getItem(draftName));
    //         console.log('found draft submission to post for help: ', draft);
            
    //         // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
    //         const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
    //         const postData = {data: draft, formType: formToShow};
            
    //         try {
    //             const res = await axios.post(AWS_API + 'form-submissions/help', {postData: postData}, headers);
    //             console.log('response from postSubmission to S3: ', res.data);
    //             // formId = res.data.formWrittenId;
    //             setLoading(false);
    //             handleBannerText(setBannerText, 'Form submitted for help, please phone the team to discuss further.')
    //             setDraftSubmission(draft)
    //         } catch (err) {
    //             setLoading(false);
    //             setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
    //             setDraftSubmission(draft)
    //             return false;
    //         }


    //     } else {
    //         alert('No submission found to send for help');
    //     }
        
    // }

    // check for previously saved draft submission, and if it exists render a button to load it
    // let restoreDraft, restoreDraftManualSave;
    // const draftName = (formToShow === 'enrolNewChild') ? 'draftNewChildEnrolment' : 'draftEnrolmentForm';
    // if (localStorage.getItem(draftName)) {
    //     // const draft = JSON.parse(localStorage.getItem(draftName));
    //     // console.log('found draft submission: ', draft);
    //     restoreDraft =  <button className="btn btn-outline-warning btn-block" onClick={() => setDraftSubmission(JSON.parse(localStorage.getItem(draftName)))}>Restore Auto-Saved Draft</button>;
    // }

    // // check for previously manually saved draft submission, and if it exists render a button to load it
    // const manualDraftName = draftName + '-manualSave';
    // if (localStorage.getItem(manualDraftName)) {
    //     restoreDraftManualSave =  <button className="btn CustomButtonPrimary btn-block" onClick={() => setDraftSubmission(JSON.parse(localStorage.getItem(manualDraftName)))}>Restore Saved Draft</button>;
    // }

    // // render a button for each form requiring action
    // let pendingBulkEnrolButtons;
    // if (pendingBulkEnrolForms?.length > 0) {
    //     pendingBulkEnrolButtons = pendingBulkEnrolForms.map((obj, i) => 
    //     <button key={obj.form_id} className='btn btn-danger btn-block' onClick={() => handleFetchBulkEnrolForm(obj)}>Pending Enrolment #{obj.form_id}</button>)
    // }

    
    let displayMissingFields = null;
    if (missingFields) {
        displayMissingFields = missingFields.map(el => <li key={el}>{el}</li>);
    }



    let content = (
        <Aux >
            {/* Invalid form submission error to direct user to missing fields */}
            <Modal show={showInvalidModal} modalClosed={() => setShowInvalidModal(false)}>
                <h5>Sorry, it looks like there's something missing!</h5>
                <hr />
                    <h6>The below required fields appear to be empty:</h6>
                <div style={{textAlign: 'left'}}> 
                    <ul>{displayMissingFields}</ul>
                </div>
                    <p>If you look through the form, you should see any missing information highlighted in <span style={{color: 'red', fontWeight: 'bold'}}> red.</span></p>
            </Modal>

            {/* Error modal to show user any data call errors */}
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Modal tall show={showFeedbackModal} modalClosed={() => {history.replace('/account')}}>
                {showFeedbackModal && <WelcomePopup token={props.token} submittedCentreId={submittedCentreId} />}
            </Modal>                 
            {loadingBackdrop && <BackdropSpinner spinner='small-light' />}
            <Banner show={bannerText}>{bannerText}</Banner>        

            <div className={classes.FormColumn} >
                <div className={classes.FormTitle}>
                    <h3 style={{textTransform: 'capitalize'}}>{raw[formToShow]} </h3>
                    <hr />
                    {(formToShow === 'enrol' || formToShow === 'enrolNewChild') && <p style={{color: 'red'}}>
                        <i>
                            Note - If you've filled out an enrolment form in OSHC+ before, you can use that as a starting point by selecting one of the forms from the "Load From Completed Form" section on the left (or below on mobile).
                        </i>
                    </p>}
                    {actualFormType === 'bulkEnrol' && <span style={{color: 'red'}}><i>Existing form data migrated from QikKids - Please read carefully and update details before submitting.</i></span>}
                </div>
                <div className={classes.Form} id="formio"></div>
            </div>

            {/* {hasFailedValidation && 
            <div>
                <hr/>
                <p style={{color: 'red'}}>If you can't get your form to submit, there should be a red warning in the missing area. If you've saved, refreshed the page and re-loaded your draft submission and still can't find the issue, give us a call for further help</p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'stretch'}}>
                    <input className='form-control' value={helpCode} onChange={(e) => setHelpCode(e.target.value)}/>
                    {helpCode === 'help' && <button className='btn btn-block btn-danger' onClick={submitFormForHelp}>Send form for help</button>}
                </div>
            </div>} */}

            
       
        </Aux>
    );

    // console.log('helpCode: ', helpCode)

    if (loading) {
        content = <div style={{paddingTop: '15vh'}}><SpinnerDark /></div>
    }

    return content
}

export default Enrol;