import {React, useState, Fragment} from 'react';
import classes from './SupportingEvidence.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
// import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
// import { useHistory, useLocation } from "react-router-dom";
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import { ccsMimeTypeTranslation, supportingDocumentTypes } from '../../../../utilities/globalObjects';

const SupportingEvidence = (props) => {

    // supporting evidence API call state
    const [supportingEvidenceFiles, setSupportingEvidenceFiles] = useState();
    const [supportingEvidenceNum, setSupportingEvidenceNum] = useState(1);
    const [supportingEvidenceDocTypes, setSupportingEvidenceDocTypes] = useState({});

    // ui state
    const [loading, setLoading] = useState();

    // react router history instance to navigate user to other pages
    // let history = useHistory();       

    // console logs
    console.log('supportingEvidenceFiles: ', supportingEvidenceFiles);
    console.log('supportingEvidenceNum: ', supportingEvidenceNum);
    console.log('supportingEvidenceDocTypes: ', supportingEvidenceDocTypes);



    const postSupportingEvidence = async (formData, mode) => {

        setLoading(true);

        // data from props
        const id = props.id;
        const documentsFor = props.documentsFor;

        // handle documents
        const documents = [];
        if (supportingEvidenceFiles) {
            for (const value of Object.values(supportingEvidenceFiles)) {
                console.log('value: ', value);
                const docType = value.docType;
                const file = value.file;
                const fileContent = await getBase64(file);
                const fileContentNoHeader = fileContent.split(',')[1];
                documents.push({documentType: docType, MIMEType: ccsMimeTypeTranslation[file.type], fileName: file.name, fileContent: fileContentNoHeader, ID: id})
            }
        } 
        console.log('documents: ', documents);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            documents: documents,
            documentsFor: documentsFor,
        };

        console.log('postData: ', postData);

        try {
            const res = await axios.post(AWS_API + 'ccs/upload-supporting-documents', {postData: postData}, headers);
            console.log('response: ', res.data);
            props.onSuccess();
        } catch (err) {
            props.onFailure(err);
        }            
        setLoading(false);
    }    



    const handleSupportingEvidenceUpload = (target, fileNum) => {
        
        const fileType = supportingEvidenceDocTypes[fileNum];

        if (target.files) {
            if (target.files.length !== 0) {
              const newFile = target.files[0];
              setSupportingEvidenceFiles({
                  ...supportingEvidenceFiles, 
                  [fileNum]: {
                      file: newFile,
                      docType: fileType
                  }
              });
            }
          }        
    }

    function getBase64(file) {
        return new Promise(function(resolve, reject) {
            var reader = new FileReader();
            reader.onload = function() { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }   

    // render supporting evidence inputs
    let supportingEvidenceForm;
    const documentOptions = Object.keys(supportingDocumentTypes).map(type => <option key={type} value={type}>{supportingDocumentTypes[type]}</option>);
    console.log('doc optinos: ', documentOptions);
    supportingEvidenceForm = [];
    for (let i=0; i<supportingEvidenceNum; i++) {
        supportingEvidenceForm.push(
            <div className={classes.Row}> 
                <span className={classes.Item}>Supporting Evidence</span> 
                <select className='form-control' value={supportingEvidenceDocTypes[i] ?? 'default'} onChange={(e) => setSupportingEvidenceDocTypes({...supportingEvidenceDocTypes, [i]: e.target.value})}>
                    <option disabled value="default">Select Document Type</option>
                    {documentOptions}
                </select>
                {supportingEvidenceDocTypes[i] && <input type='file' className='form-control' onChange={(e) => handleSupportingEvidenceUpload(e.target, i)}/>}
            </div>  

        );
    }
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <Fragment>
                <div className={classes.SupportingEvidence}>
                    {supportingEvidenceForm}
                    <br />
                    <button className={classes.RoundButton} onClick={() => setSupportingEvidenceNum(supportingEvidenceNum + 1)}>+</button>
                    <br />
                    <br />
                    <button className='btn btn-block btn-success' onClick={postSupportingEvidence}>Submit Evidence</button>
                </div>
            </Fragment>
        );
    }
    
    return content
}

export default SupportingEvidence;