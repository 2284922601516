import classes from './Dashboard.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import {IoRefreshOutline} from "react-icons/io5";
import Banner from '../../../../UI/Banner/Banner';

const Dashboard = (props) => {

    const [qsUrl, setQsUrl] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [lastRefreshTime, setLastRefreshTime] = useState();
    const [bannerText, setBannerText] = useState();

    const location = useLocation();
    const history = useHistory();

    const openDashboard = useCallback(async (open) => {
        setLoading(true);
        setDisableButton(true);
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        try {
            // get the dashboard link
            const res = await axios.get(AWS_API + 'dashboard/fetch', config);
            console.log('res: ', res);
            const url = res.data;
            if (url){
                if (open){
                    // open dashboard in a new tab
                    window.open(url, '_blank');

                    // get a new link for the embedded dashboard
                    openDashboard(false);
                }
                setQsUrl(url);
            }
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
            setLoading(false);
        }

        setLoading(false);

    }, [history, location, props.token]);

    const getLastRefreshTime = useCallback(async () => {
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        
        try {
            const res = await axios.get(AWS_API + 'dashboard/get-last-refresh-time', config);
            console.log('timeRes ', res);
            setLastRefreshTime(res.data.date);
        } catch (err){
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
        }
    }, [history, location, props.token]);

    useEffect(() => {
        getLastRefreshTime();
        openDashboard(false);
    }, [openDashboard, getLastRefreshTime]);

    const handleClick = () => {
        setLoading(true);
        setTimeout(() => {
            openDashboard(true);
            setLoading(false);
        }, 3000);
    }

    const refreshData = async() => {
        setLoading(true);
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        try {
            const res = await axios.post(AWS_API + 'dashboard/refresh', {postData: ''}, headers);

            console.log('response from refresh: ', res.data);       
            handleBannerText(setBannerText, `Started Refresh`);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission in RDS');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.Dashboard}>
            {loading && <BackdropSpinner spinner='small-light'/>}
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Banner show={bannerText}>{bannerText}</Banner> 

            <h3>Dashboard</h3>
            <hr/>
            <div className={classes.HeaderButtonRowLeft}>
                <button className='btn btn-success btn-sm' onClick={handleClick} disabled={disableButton}>Open Dashboard in New Tab</button>
                <button className='btn btn-sm btn-secondary' onClick={refreshData}><IoRefreshOutline size={'1rem'} color={'white'} /></button>            </div>
            <br/>
            {lastRefreshTime ? <i>Data Last Refreshed: {lastRefreshTime}</i> : <div/>}
            {qsUrl && <iframe width="100%" height="3000px" src={qsUrl} title="QuickSight Dashboard" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen onLoad={() => setDisableButton(false)}></iframe>}
        </div>
    );
};

export default Dashboard;