import React from 'react';
import classes from './SettingsInput.module.css';
import { Fragment } from 'react';

const SettingsInput = (props) => {

    const type = props.type;

    let input = <Fragment>
        <label className={classes.Label}>{props.label}</label>
        <input disabled={props.disabled} value={props.value} onChange={(e) => props.changed(e.target.value)} className={[classes.Input, "form-control"].join(' ')} type={props.type}/>
    </Fragment> 

    if (type === 'checkbox') {
        input = <Fragment>
            <label className={classes.Label}>{props.label}</label>
            <input disabled={props.disabled} checked={props.value} onChange={(e) => props.changed(e.target.checked)} className={[classes.Input].join(' ')} type={props.type}/>
        </Fragment>
    }
   
    return (
        <div className={classes.Row} >
            {input}
        </div> 

    );
};

export default SettingsInput;