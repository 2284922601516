import { useState, useEffect, useCallback } from 'react';
// import classes from './Cognito.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBanner, handlePrompt } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import Prompt from '../../../../UI/Prompt/Prompt';

const Cognito = (props) => {

    // hoc state
    const [loading, setLoading] = useState(false);
    const [loadingBackdrop, setLoadingBackdrop] = useState(false);
    const [error, setError] = useState();
    const [showBanner, setShowBanner] = useState(false); 
    const [bannerText, setBannerText] = useState('');      
    const [prompt, setPrompt] = useState({});   

    // data state
    const [allUserAccounts, setAllUserAccounts] = useState();
    const [cognitoGroups, setCognitoGroups] = useState();
    const [staffDetails, setStaffDetails] = useState();
    const [staffCentres, setStaffCentres] = useState();
    // const [users, setUsers] = useState();

    // UI state
    const [formData, setFormData] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();  
    const location = useLocation();

    // get data from props
    const centres = props.centres;

    // console logs
    console.log('allUserAccounts: ', allUserAccounts);
    console.log('cognitoGroups: ', cognitoGroups);
    console.log('formData: ', formData);
    console.log('staffDetails: ', staffDetails);
    console.log('staffCentres: ', staffCentres);
    console.log('centres: ', centres);
    
    // on component load get users and groups from cognito
    const getMasterData = useCallback(async () => {
        setLoading(true);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {userpoolId: cognitoUserpoolId}
        };
        
        // get all_user_accounts
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch-all-user-cognito', config);
            setAllUserAccounts(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching all user accounts');
            setLoading(false);
        }    
        // get cognito groups
        // config.params = {userpoolId: cognitoUserpoolId}
        try {
            const res = await axios.get(AWS_API + 'cognito/list-groups', config);
            setCognitoGroups(res.data.groups);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while getting Cognito groups');
        } finally {
            setLoading(false);
        }
        
    }, [props.token, history, location]);


    useEffect(() => {

        getMasterData();
    }, [getMasterData]);

    const getStaffDetails = useCallback(async (staffId) => {

        setLoadingBackdrop(true);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'staff_details', conditionCol: 'person_id', conditionValue: staffId}
        };
        
        // get all_user_accounts
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            setStaffDetails(res.data?.filter(obj => obj.person_id));
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching all user accounts');
        } 

        // update config
        config.params = {table: 'staff_centres', conditionCol: 'person_id', conditionValue: staffId}
        
        // get all_user_accounts
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            setStaffCentres(res.data?.filter(obj => obj.person_id));
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching all user accounts');
        } 
           
        setLoadingBackdrop(false);

    }, [props.token, history, location]);


    // when selected email changes, get staff detail for selected user if exists
    useEffect(() => {

        if (formData?.email) {
            const staffId = allUserAccounts?.filter(obj => obj.email === formData?.email)?.[0]?.person_id;
            console.log("Staff ID: ", staffId);
            if (staffId){
                getStaffDetails(staffId);
            }
            
            // reset form data to clear any data from a previously selected email
            setFormData({email: formData?.email});
        }
    }, [formData?.email, getStaffDetails, allUserAccounts]);



    // function to send username and new group to update a user's cognito group
    const updateGroups = async () => {

        setLoading(true);

        // get username from email
        const email = formData?.email;
        const username = allUserAccounts.filter(obj => obj.email === email)[0].username;

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            ...formData,
            username: username,
            userpoolId: cognitoUserpoolId,
            email: email
        };
        try {
            const res = await axios.post(AWS_API + 'cognito/update-group', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBanner(setShowBanner, setBannerText, 'Group updated')
            getMasterData();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
            setLoading(false);
        }     
        
        setLoading(false);
    }

    // function to send username and new group to update a user's cognito group
    const deleteCognitoUser = async () => {

        setLoading(true);

        // get username from email
        const email = formData?.email;

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            userpoolId: cognitoUserpoolId,
            email: email
        };
        try {
            const res = await axios.post(AWS_API + 'cognito/delete-cognito-user', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBanner(setShowBanner, setBannerText, 'Account reset')
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
        }     
        
        setLoading(false);
    }       

    // if staff details changes, pre-populate form data with any found staff details
    useEffect(() => {

        // if staff centres found, get necessary fields
        let linkedCentres;
        if (staffCentres?.length > 0) {
            linkedCentres = staffCentres?.map(obj => obj.centre_id);
            console.log('linkedCentres: ', linkedCentres);
        }     

        setFormData(prevData => ({
            ...prevData,
            linked_centres: linkedCentres
        }));

    }, [staffCentres, staffDetails, setFormData]);    


    // UI to show user accounts for which to change group
    let ui;
    if (allUserAccounts && cognitoGroups) {
        
        // if email has been selected, get old group for that user
        let oldGroup;
        if (formData?.email) {
            const email = formData?.email;
            oldGroup = allUserAccounts.filter(obj => obj.email === email)[0].account_type;
        }

        // get email options
        const emailOptions = [];
        allUserAccounts.forEach(obj => {emailOptions.push({value: obj.email, label: obj.email})})     
        
        // get cognito group options
        const cognitoGroupOptions = [];
        cognitoGroups.forEach(el => {cognitoGroupOptions.push({value: el, label: el})})  
        
        // get centre options
        const centreOptions = [];
        centres.forEach(obj => {centreOptions.push({value: obj.centre_id, label: obj.centre_name})})     
        
        // if staff detail found, get necessary fields
        let firstName, surname;
        if (staffDetails?.length > 0) {
            firstName = staffDetails?.[0]?.first_name;
            surname = staffDetails?.[0]?.surname;
        }        

        // generate html for UI
        ui = (
            <div>
                <h5>Update Cognito Groups</h5>
                <br />

                <ParamsForm 
                    data={formData} 
                    setData={setFormData} 
                    config={[
                        {name: 'email', type: 'react-select', options: emailOptions, valueProperty: 'value'},
                        {name: 'old_group', type: 'select', value: oldGroup, options: [{value: oldGroup, label: oldGroup}]},
                        {name: 'new_group', type: 'react-select', options: cognitoGroupOptions, valueProperty: 'value'},
                        {name: 'first_name', type: 'text', value: firstName}, 
                        {name: 'surname', type: 'text', value: surname},
                        {name: 'linked_centres', type: 'react-select', isMulti: true, options: centreOptions},
                    ]}
                    />
                <button className='btn btn-success btn-block'onClick={updateGroups}>Update Group</button>
                <button className='btn btn-danger btn-block'onClick={() => handlePrompt(`reset user's login details`, deleteCognitoUser, setPrompt, 'Note: This action cannot be undone')}>Reset User</button>
            </div>
        ); 

    }
    
    let content = <SpinnerDark />;
    if (!loading) {
        content = (
            <div>
                {/* <button className="btn btn-outline-secondary" onClick={test}>Test</button> */}
                {ui}
            </div>
        ); 
    }
    

    return (
        <div>
            {loadingBackdrop && <BackdropSpinner spinner='small-light' />}
            <Prompt data={prompt} setData={setPrompt} />
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Banner show={showBanner}>{bannerText}</Banner>                
            {content}
            {/* <button className='btn btn-outline-warning btn-block' onClick={test}>Test </button> */}

        </div>
    );
}

export default Cognito;


// ```

//  <div className={classes.Row}>
// <span className={classes.Label}>Email</span>
// <div className={classes.Select}>
//     <Select options={emailOptions} onChange={setSelectedEmail} value={selectedEmail}></Select>   

// </div>
// <select defaultValue="Select Email" className={[classes.Select, 'form-control'].join(' ')} value={selectedEmail} onChange={(e) => setSelectedEmail(e.target.value)} >
//     {[<option key="default" disabled>Select Email</option>].concat(allUserAccounts.map(obj => <option key={obj.email} value={obj.email} >{obj.email}</option>))}
// </select>
// </div>
// <div className={classes.Row}>
// <span className={classes.Label}>Old Group</span>
// <select className={[classes.Select, 'form-control'].join(' ')} value={oldGroup} disabled >
//     <option value={oldGroup} >{oldGroup}</option>
// </select>
// </div>
// <div className={classes.Row}>
// <span className={classes.Label}>New Group</span>
// <select defaultValue="Select Group" className={[classes.Select, 'form-control'].join(' ')} value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)} >
//     {[<option key="default" disabled>Select Group</option>].concat(cognitoGroups.map(el => <option key={el} value={el} >{el}</option>))}
// </select>
// </div> 
// ```