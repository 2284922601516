import classes from './AccountBalance.module.css';
import { formatCurrency } from '../../../../../utilities/functions';
import SmallSpinnerDark from '../../../../../UI/SmallSpinnerDark/SmallSpinnerDark';
import { Fragment } from 'react';

const AccountBalance = (props) => {

    // data from props
    const selectedAccountObj = props.selectedAccountObj;
    const parentFlag = props.parentFlag;
    
    // console.log('parentFlag: ', parentFlag);

    const accountBalance = selectedAccountObj?.outstanding_balance;
    const overdueBalance = selectedAccountObj?.overdue_balance;
    const lastUpdatedDate = new Date(selectedAccountObj?.balances_updated_at).toLocaleDateString();
    const lastUpdatedTime = new Date(selectedAccountObj?.balances_updated_at).toLocaleTimeString();

    let currencyClass, label;
    if (accountBalance >= 0) {
        currencyClass = classes.PositiveBalance
        label = ' Credit'
    } else {
        currencyClass = classes.NegativeBalance
        label = ' Owing'
    }
    let currencyClassOverdue, overdueLabel;
    if (overdueBalance >= 0) {
        currencyClassOverdue = classes.PositiveBalance
        overdueLabel = ''
    } else {
        currencyClassOverdue = classes.NegativeBalance
        overdueLabel = ' Owing'
    }

    let content1 = <div style={{padding: '15px'}}><SmallSpinnerDark /></div>;
    if (accountBalance) {
        content1 = <Fragment>
                <div className={currencyClass}>{formatCurrency(accountBalance)} {label}</div>
                <div className={classes.LastUpdated}>Updated: {lastUpdatedDate} {lastUpdatedTime}</div>            
        </Fragment>
    }

    let content2 = <div style={{padding: '15px'}}><SmallSpinnerDark /></div>;
    if (accountBalance) {
        content2 = <Fragment>
                <div className={currencyClassOverdue}>{formatCurrency(overdueBalance)} {overdueLabel}</div>
                <div className={classes.LastUpdated}>Updated: {lastUpdatedDate} {lastUpdatedTime}</div>         
        </Fragment>
    }

    return (
        <div className={classes.AccountBalanceContainer}>
            <div className={classes.AccountBalance}>
                <div>Account Balance</div>
                {content1}
            </div>
            {!parentFlag && <div className={classes.AccountBalance}>
                <div>Overdue Balance</div>
                {content2}
            </div>}

        </div>

    );
}

export default AccountBalance;