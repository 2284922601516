import {React} from 'react';
import { enrolmentArrangementTypes, enrolmentNoticeTypes, reasonsForPEA } from '../../../../../utilities/globalObjects';
import classes from './EnrolConfigForm.module.css';
import DatePicker from "react-multi-date-picker";
import { datePickerObjToISOFormat } from '../../../../../utilities/functions';

const EnrolConfigForm = (props) => {

    // data from props
    const userInputFormData = props.userInputFormData;
    const setUserInputFormData = props.setUserInputFormData;

    const form = (
        <div className={classes.InnerContainer}>
            <h5>Centrelink Enrolment Options</h5>
            <hr/>
            {/* <div className={classes.Row}>
                <span className={classes.Item}><b>Enrolment Start Date</b></span> 
                <input type='date' className='form-control' value={userInputFormData.startDate ?? ''} onChange={(e) => setUserInputFormData({...userInputFormData, startDate: e.target.value})}/> 
            </div> */}
            <div className={classes.Row}>
                <span className={classes.Item}><b>Enrolment Start Date</b></span> 
                <div className={classes.BlockDatePicker}>
                    <DatePicker 
                    inputClass="form-control" 
                    placeholder="Select Date" 
                    value={userInputFormData.startDate ?? ''}
                    fixMainPosition={true} 
                    format='YYYY-MM-DD' 
                    multiple={false} 
                    onChange={(e) => {
                        const newDate = datePickerObjToISOFormat(e);
                        setUserInputFormData({...userInputFormData, startDate: newDate})
                    }}  
                    // onChange={(e) => {setSelectedDates(e);}}
                    />
                </div>
            </div>
            {/* <div className={classes.Row}>
                <span className={classes.Item}><b>Enrolment End Date</b></span> 
                <input type='date' className='form-control' value={userInputFormData.endDate ?? ''} onChange={(e) => setUserInputFormData({...userInputFormData, endDate: e.target.value})}/> 
            </div> */}
            <div className={classes.Row}>
                <span className={classes.Item}><b>Enrolment End Date</b></span> 
                <div className={classes.BlockDatePicker}>
                    <input type='text' className='form-control' value={userInputFormData.endDate ?? ''} onChange={(e) => setUserInputFormData({...userInputFormData, endDate: e.target.value})}/> 
                    {/* <DatePicker 
                    inputClass="form-control" 
                    placeholder="Select Date" 
                    value={userInputFormData.endDate ?? ''}
                    fixMainPosition={true} 
                    format='YYYY-MM-DD' 
                    multiple={false} 
                    onChange={(e) => {
                        const newDate = datePickerObjToISOFormat(e);
                        setUserInputFormData({...userInputFormData, endDate: newDate})
                    }}  
                    // onChange={(e) => {setSelectedDates(e);}}
                    /> */}
                </div>
            </div>
            <div className={classes.Row}>
                <span className={classes.Item}><b>Arrangement Type</b></span> 
                <select className='form-control' value={userInputFormData.arrangementType ?? 'default'} onChange={(e) => setUserInputFormData({...userInputFormData, arrangementType: e.target.value})}>
                    <option disabled value='default'>Select Arrangement Type</option>
                    {enrolmentArrangementTypes.map(type => <option key={type} value={type}>{type}</option>)}
                </select>
            </div>
            <div className={classes.Row}>
                <span className={classes.Item}><b>Notice Type</b></span> 
                <select className='form-control' value={userInputFormData.noticeType ?? 'default'} onChange={(e) => setUserInputFormData({...userInputFormData, noticeType: e.target.value})}>
                    <option disabled value='default'>Select Notice Type</option>
                    {enrolmentNoticeTypes.map(obj => <option key={obj.value} value={obj.value}>{obj.label}</option>)}
                </select>
            </div>
            {userInputFormData.arrangementType === 'PEA' &&     
            <div className={classes.Row}>
                <span className={classes.Item}><b>Reason For PEA</b></span> 
                <select className='form-control' value={userInputFormData.reasonForPEA ?? 'default'} onChange={(e) => setUserInputFormData({...userInputFormData, reasonForPEA: e.target.value})}>
                    <option disabled value='default'>Select Reason for PEA</option>
                    {reasonsForPEA.map(reason => <option key={reason} value={reason}>{reason}</option>)}
                </select>
            </div>}
            <div className={classes.Row}>
                <span className={classes.Item}><b>Late Submission Reason</b></span> 
                <input type='text' className='form-control' value={userInputFormData.lateSubmissionReason ?? ''} onChange={(e) => setUserInputFormData({...userInputFormData, lateSubmissionReason: e.target.value})}/> 
            </div>
            <div className={classes.Row}>
                <span className={classes.Item}><b>CCS Enrolment ID</b></span> 
                <input type='text' className='form-control' value={userInputFormData.ccsEnrolmentId ?? ''} onChange={(e) => setUserInputFormData({...userInputFormData, ccsEnrolmentId: e.target.value})}/> 
            </div>
            {/* temporarily hiding this button until we get to another centre with no CRN */}
            <br />
            <div className="form-check" >
                <input checked={userInputFormData.generatePendingEnrolment} onChange={(e) => setUserInputFormData({...userInputFormData, generatePendingEnrolment: e.target.checked})} className="form-check-input" type="checkbox" id="flexCheckDefault"/>
                <label >Do not submit enrolment to Centrelink, instead generate a "pending" CCS enrolment ID</label>
            </div>                                     
        </div>        
    );    
    
    return (
        <div className={classes.EnrolConfigForm}>
            {form}
        </div>
    );
}

export default EnrolConfigForm;