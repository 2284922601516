import { Fragment, useEffect, useState } from 'react';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import classes from './ChildSummary.module.css';
import InitialIcon from '../../../Kiosk/InitialIcon/InitialIcon';
import { raw } from '../../../../translations/en/raw';
import { arrayUniqueByKey, humanize, isBirthday } from '../../../../utilities/functions';

// icons
import {AiOutlineWarning} from "react-icons/ai";
import {MdNoFood} from "react-icons/md";
import {FaNotesMedical} from "react-icons/fa";
import {FiCameraOff} from "react-icons/fi";
import u7icon from '../../../../assets/images/u7-icon-v2.png'
import {BsPencilSquare} from "react-icons/bs";
import {GrDocumentMissing} from "react-icons/gr";
// import {FaBirthdayCake} from "react-icons/fa";
import birthdayIcon from '../../../../assets/images/birthday.png';
import kindyIcon from '../../../../assets/images/kindy.png';
import { public_s3_url } from '../../../../utilities/globalVariables';
import axios from 'axios';

// set icon style
const iconSize = '1.2rem';
const iconColor = 'black'


const ChildSummary = (props) => {

    // state
    const [showEmergencyContacts, setShowEmergencyContacts] = useState();
    const [addingNote, setAddingNote] = useState();
    const [addingNoteType, setAddingNoteType] = useState();
    const [newRollNote, setNewRollNote] = useState();

    // filter state
    const [selectedCentreId, setSelectedCentreId] = useState();    

    // ref data state
    const [schoolNameData, setSchoolNameData] = useState([]);    

    // data needed

    // roll data? - 
    // emergency contact data
    // child flags
    // recurring notes?



    // data from props
    const parentComponent = props.parentComponent;
    const childSummaryData = props.childSummaryData;
    const childRollRecord = props.childRollRecord;
    const emergencyContactsData = props.emergencyContactsData;
    const childFlagsData = props.childFlagsData;
    const childImg = props.childImg;
    const handleSubmitRollNote = props.handleSubmitRollNote;

    // optional data from cabinet
    const childEnrolmentsData = props.childEnrolmentsData;
    const childBusListOverridesData = props.childBusListOverridesData;

    
    // console 
    console.log('childSummaryData: ', childSummaryData)
    // console.log('childRollRecord: ', childRollRecord)
    console.log('childBusListOverridesData: ', childBusListOverridesData)
    // console.log('childEnrolmentsData: ', childEnrolmentsData)
    // console.log('selectedCentreId: ', selectedCentreId)
    

    // config based on parent component
    let addNoteTypes = [];
    if (parentComponent === 'roll') {
        addNoteTypes = ['session', 'recurring_roll']
    } else if (parentComponent === 'cabinet') {
        addNoteTypes = ['recurring_roll']
    }

    // put editable values into state
    useEffect(() => {

        if (addingNoteType === 'session' && childRollRecord?.notes) {
            setNewRollNote(childRollRecord?.notes);

        } else if (addingNoteType === 'recurring_roll' && childSummaryData?.[0]?.recurring_roll_note) {

            setNewRollNote(childSummaryData?.[0]?.recurring_roll_note);
        }

    }, [childSummaryData, addingNoteType, childRollRecord]);

    // if centres data length is 1, set that as selected centre
    useEffect(() => {

        if (childEnrolmentsData) {
            const uniqueCentreIds = childEnrolmentsData.map(obj => obj.centre_id).filter((x,i,a) => a.indexOf(x) === i);
            if (uniqueCentreIds?.length === 1) {
                setSelectedCentreId(uniqueCentreIds?.[0]);
            }
        }

    }, [childEnrolmentsData])    
    
    // get school id - name mappings from public s3 bucket
    useEffect(() => {
        const fetchSchoolNameData = async (ids) => {
            try {
                let schoolLookupObj = {};
                const res = await axios.get(public_s3_url + 'schools.json');
                // console.log('res: ', res.data);
                const filteredSchools = res.data.filter(obj => ids.includes(+obj.ACARAId))
                // console.log('filteredSchools: ', filteredSchools);
                filteredSchools.forEach(obj => schoolLookupObj[obj.ACARAId] =  obj.schoolName);
                setSchoolNameData(schoolLookupObj);
            } catch (err) {
                console.log('error while fetching school names: ', err);
            }           
        }

        if (childSummaryData?.length > 0) {
            const schoolIds = childSummaryData.map(obj => obj.school_child_attends).filter((x, i, a) => a.indexOf(x) === i);
            fetchSchoolNameData(schoolIds);
        }

    }, [childSummaryData])    

    
    // -----------------------------------------------------------------------------------------------------------------------------
    // data structuring 
    
    // roll session
    const rollId = childRollRecord?.id; // optional, will be passed in from SessionSignIns but not from Cabinet
    const rollSessionNote = childRollRecord?.notes;
    // console.log('rollId: ', rollId);
    
    // general
    const emptyValue = 'None';
    const childSummaryRecord = childSummaryData?.[0];
    
    // guardian
    const primaryGuardianName = childSummaryRecord?.guardian_first_name ? childSummaryRecord?.guardian_first_name + ' ' + childSummaryRecord?.guardian_surname : emptyValue;
    const secondaryGuardianName = childSummaryRecord?.guardian2_first_name ? childSummaryRecord?.guardian2_first_name + ' ' + childSummaryRecord?.guardian2_surname : emptyValue;
    
    // -----------------------------------------------------------------------------------------------------------------------------
    // child
    const childId = childSummaryRecord?.child_id;
    const childName = childSummaryRecord?.first_name ? childSummaryRecord?.first_name + ' ' + childSummaryRecord?.surname : emptyValue;
    const childAgeString = calculateAge(childSummaryRecord?.dob);
    const childAge = +childAgeString?.split(' ')?.[0];
    
    // get school of child
    const schoolChildAttends = childSummaryRecord?.school_child_attends;
    const childSchool = schoolNameData?.[schoolChildAttends] ?? schoolChildAttends;
    console.log('schoolChildAttends: ', schoolChildAttends);
    console.log('childSchool: ', childSchool);
    
    // check if child's birthday
    const birthdayFlag = isBirthday(childSummaryRecord?.dob, new Date());          
    const childAddress = childSummaryRecord?.child_address;
    // console.log('childAge: ', childAge);
    
    // child permissions
    const childPermissionsArray = [
        {key: 'auth_transport', value: childSummaryRecord?.auth_transport},
        {key: 'photo_permissions_internal', value: childSummaryRecord?.photo_permissions_internal},
        {key: 'photo_permissions_external', value: childSummaryRecord?.photo_permissions_external},
        {key: 'auth_evacuation', value: childSummaryRecord?.auth_evacuation},
        {key: 'auth_sunscreen', value: childSummaryRecord?.auth_sunscreen},
        {key: 'auth_panadol', value: childSummaryRecord?.auth_panadol},
        {key: 'auth_ambulance', value: childSummaryRecord?.auth_ambulance},
        {key: 'auth_first_aid', value: 	    childSummaryRecord?.auth_first_aid},
    ]; 
    const childPermissionsTrue = childPermissionsArray.filter(obj => obj.value).map(obj => obj.key);
    const childPermissionsFalse = childPermissionsArray.filter(obj => !obj.value).map(obj => obj.key);
    const childPermissionsTrueString = childPermissionsTrue.map(el => (raw[el] ?? el) + ': YES' ).join(', ');
    const childPermissionsFalseString = childPermissionsFalse.map(el => (raw[el] ?? el) + ': NO').join(', ');
    
    // repeating notes
    const recurringRollNote = childSummaryRecord?.recurring_roll_note; // if recurring roll note ID exists for child will be popualted, otherwise empty
    const recurringRollNoteId = childSummaryRecord?.recurring_roll_note_id; // if recurring roll note ID exists for child will be popualted, otherwise empty
    
    // conditions
    const diagnosedCondition = childSummaryRecord?.diagnosed_condition_detail;
    const dietaryRestrictions = childSummaryRecord?.dietary_restrictions_detail;
    
    // court orders
    const courtOrders1 = childSummaryRecord?.court_orders_1;
    const courtOrders2 = childSummaryRecord?.court_orders_2;
    const courtOrdersArray = [
        {key: 'court_orders_1', value: courtOrders1},
        {key: 'court_orders_2', value: courtOrders2},
    ];
    const courtOrdersString = courtOrdersArray.filter(obj => obj.value).map(obj => raw[obj.key] ?? obj.key).join(', ');
    
    
    // flags
    const childFlagsObj = childFlagsData?.filter(obj => obj.id)?.map(obj =>  (raw[obj.flag_type] ?? obj.flag_type) + ' - ' + obj.comment);
    const childFlagsStr = childFlagsObj?.join(', ')
    
    
    // child image
    let childImageOrInitial;
    if (childImg) {
        childImageOrInitial = <img alt="child's face" src={'data:image/jpg;base64,' + childImg} style={{width: '250px', borderRadius: '50%'}}/>
    } else if (childSummaryRecord) {
        const initial1 = childSummaryRecord?.first_name.slice(0,1);
        const initial2 = childSummaryRecord?.surname.slice(0,1);
        const initials = (initial1 + initial2).toUpperCase();        
        childImageOrInitial = <div style={{marginTop: '25px'}}>
            <InitialIcon initials={initials} size='large'/>
        </div>
    }   

    // -----------------------------------------------------------------------------------------------------------------------------
    // optional child centre configuration (i.e. bus list overrides)




    // render centre choices
    let centreSelector;
    if (childEnrolmentsData?.length > 0) {

        centreSelector = (
            <select  className='form-control' value={selectedCentreId ?? ''} onChange={(e) => setSelectedCentreId(e.target.value)}>
                <option disabled value=''>Select Centre</option>
                {arrayUniqueByKey('centre_id', childEnrolmentsData).map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
            </select>        
        );        
    }


    // -----------------------------------------------------------------------------------------------------------------------------
    
    
    
    // -----------------------------------------------------------------------------------------------------------------------------
    // dynamic classes
    
    const diagnosedConditionClasses = [classes.DetailTableValue];
    if (diagnosedCondition) {
        diagnosedConditionClasses.push(classes.WarningText);
    }
    const dietaryRestrictionsClasses = [classes.DetailTableValue];
    if (dietaryRestrictions) {
        dietaryRestrictionsClasses.push(classes.WarningText);
    }
    const courtOrderClasses = [classes.DetailTableValue];
    if (courtOrders1 || courtOrders2) {
        courtOrderClasses.push(classes.WarningText);
    }
    const childFlagsClasses = [classes.DetailTableValue];
    if (childFlagsObj?.length > 0) {
        childFlagsClasses.push(classes.WarningText);
    }
    

    // -----------------------------------------------------------------------------------------------------------------------------
    
    // console logs
    // console.log('[ChildSummary.js] - childSummaryData: ', childSummaryData);
    // console.log('[ChildSummary.js] - emergencyContactsData: ', emergencyContactsData);
    // console.log('[ChildSummary.js] - childFlagsData: ', childFlagsData);

    // render emergency contacts
    let emergencyContactsJSX = <p>No Emergency Contacts Found</p>;
    if (emergencyContactsData?.length > 0) {
        
        emergencyContactsJSX = emergencyContactsData.map((obj, i) => {
            const name = obj?.first_name ? obj?.first_name + ' ' + obj?.surname : emptyValue;
            return (
                <div key={obj.person_id} style={{marginTop: '10px'}}>
                        <h6>Emergency Contact {i + 1}</h6>
                        <table className={classes.Table}>
                            <tbody>
                                <tr>
                                    <td className={classes.TableKey}>Name: </td>
                                    <td className={classes.TableValue}>{name ?? emptyValue}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Relationship: </td>
                                    <td className={classes.TableValue}>{obj?.relationship ?? emptyValue}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Mobile: </td>
                                    <td className={classes.TableValue}>{obj?.contact_number ?? emptyValue}</td>
                                </tr>
                            </tbody>
                        </table>          
                        <hr/>          
                </div>                
            );
        })
    }

        // if not in kiosk view, render necessary flags/symbols
        let symbols;
        if (['roll', 'cabinet'].includes(parentComponent)) {
            symbols = 
                <Fragment>

                    {/* birthday symbol */}
                    {birthdayFlag &&  <div>
                        {/* <FaBirthdayCake size={iconSize} color={iconColor} />     */}
                        <img alt="birthday-icon" src={birthdayIcon} style={{height: iconSize}} />  
                    </div>}      

                    {/* kindy flag */}
                    {childSummaryRecord?.current_school_year?.includes('kindy') && <div>
                        <img alt="kindy-icon" src={kindyIcon} style={{height: '1.35rem'}} />  
                    </div>}                    

                    {/* notes symbol */}
                    {(childRollRecord?.notes || childSummaryRecord?.recurring_roll_note) &&  <div>
                        <BsPencilSquare size={iconSize} color={iconColor} />    
                    </div>}      

                    {/* at risk symbol - currently court orders in children_medical, or at_risk flag in child_flags */}
                    {childSummaryRecord?.court_orders_1 || childSummaryRecord?.court_orders_2 || childFlagsObj?.length > 0
                    ? <div>
                        <AiOutlineWarning size={iconSize} color={iconColor} />    
                    </div> : null}

                    {/* dietary restrictions symbol */}
                    {childSummaryRecord?.dietary_restrictions ? <div >
                        <MdNoFood size={iconSize} color={iconColor} />    
                    </div> : null}

                    {/* medical condition symbol */}
                    {childSummaryRecord?.diagnosed_condition || !childSummaryRecord?.auth_sunscreen || !childSummaryRecord?.auth_panadol || !childSummaryRecord?.auth_first_aid || !childSummaryRecord?.auth_ambulance
                    ? <div >
                        <FaNotesMedical size={iconSize} color={iconColor} />    
                    </div> : null}

                    {/* photo permissions missing symbol */}
                    {(!childSummaryRecord?.photo_permissions_internal || !childSummaryRecord?.photo_permissions_external) ? <div >
                        <FiCameraOff size={iconSize} color={iconColor} />    
                    </div>  : null}  

                    {/* child < 7 symbol */}
                    {(childAge < 7) ? <div >
                        <img alt="under-7-icon" src={u7icon} style={{height: iconSize}} />   
                    </div>  : null}                  

                    {/* account incomplete symbol */}
                    {childSummaryRecord?.account_incomplete ? <div >
                        <GrDocumentMissing size={iconSize} color={iconColor} />    
                    </div>  : null}                  
                </Fragment>
        }    


    let content = <SpinnerDark/>;
    if (childSummaryRecord) {
        content = (
            <Fragment>

                {/* add notes buttons */}
                {addNoteTypes?.length > 0 && <div className={classes.HeaderButtonRowRight}>
                    {addingNoteType && <div style={{width: '100%', textAlign: 'right'}}><button className='btn btn-sm btn-warning' onClick={() => {setAddingNote(false); setAddingNoteType(false)}}>Cancel Note Addition</button></div>}
                        {!addingNote && <div style={{width: '100%', textAlign: 'right'}}><button className='btn btn-sm btn-warning' onClick={() => setAddingNote(true)}>Add Note</button></div>}
                        {addingNote && !addingNoteType && <div style={{width: '100%', textAlign: 'right', display: 'flex', gap: '2px', justifyContent: 'flex-end'}}>
                            <button className='btn btn-sm btn-warning' onClick={() => {setAddingNote(false); setAddingNoteType(false)}}>Cancel Note Addition</button>
                            {addNoteTypes.map(type => <button key={type} className='btn btn-sm btn-secondary' onClick={() => setAddingNoteType(type)}>{raw[type] ?? humanize(type)}</button>)}
                        </div>}
                </div>}
                {/* end of add notes buttons */}



                <h5>{childSummaryRecord?.first_name + ' ' + childSummaryRecord?.surname}</h5>
                <div className={classes.FlexRowContainer}>
                    <div className={[classes.FlexRowItem, classes.ImageContainer].join(' ')}>{childImageOrInitial}</div>
                    <div className={classes.FlexRowItem}>

                        {/* add note text area */}
                        {addingNoteType && <Fragment>
                            <h6>New {raw[addingNoteType] ?? humanize(addingNoteType)} Note:</h6>
                            {<textarea value={newRollNote} onChange={(e) => setNewRollNote(e.target.value)} rows={4} style={{width: '100%'}} />}
                            <button className='btn btn-block btn-success' onClick={() => handleSubmitRollNote(childId, newRollNote, addingNoteType, rollId, recurringRollNoteId)}>Submit</button>
                            <hr/>
                        </Fragment>}
                        {/* end of add note text area */}

                        <h6>Guardian 1 Contact</h6>
                        <table className={classes.Table}>
                            <tbody>
                                <tr>
                                    <td className={classes.TableKey}>Name: </td>
                                    <td className={classes.TableValue}>{primaryGuardianName ?? emptyValue}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Relationship: </td>
                                    <td className={classes.TableValue}>{childSummaryRecord?.guardian_relationship ?? emptyValue}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Mobile: </td>
                                    <td className={classes.TableValue}>{childSummaryRecord?.preferred_contact ?? emptyValue}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Email: </td>
                                    <td className={classes.TableValue}>{childSummaryRecord?.guardian_email ?? emptyValue}</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr/>
                        <h6>Guardian 2 Contact</h6>
                        <table className={classes.Table}>
                            <tbody>
                                <tr>
                                    <td className={classes.TableKey}>Name: </td>
                                    <td className={classes.TableValue}>{secondaryGuardianName}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Relationship: </td>
                                    <td className={classes.TableValue}>{(secondaryGuardianName !== emptyValue && childSummaryRecord?.guardian2_relationship) ? childSummaryRecord?.guardian2_relationship : ''}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Mobile: </td>
                                    <td className={classes.TableValue}>{(secondaryGuardianName !== emptyValue && childSummaryRecord?.guardian2_preferred_contact) ? childSummaryRecord?.guardian2_preferred_contact : ''}</td>
                                </tr>
                                <tr>
                                    <td className={classes.TableKey}>Email: </td>
                                    <td className={classes.TableValue}>{(secondaryGuardianName !== emptyValue && childSummaryRecord?.guardian2_email) ? childSummaryRecord?.guardian2_email : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <button className='btn btn-block btn-light' onClick={() => setShowEmergencyContacts(!showEmergencyContacts)}>{showEmergencyContacts ? 'Hide ' : 'Show '} Emergency Contacts</button>
                        {showEmergencyContacts && emergencyContactsJSX}
                    </div>
                </div>

                {['roll', 'cabinet'].includes(parentComponent) && <div className={classes.ChildSummaryDetail}>
                    <div className={classes.SymbolsContainer}>
                        {symbols}
                    </div>
                    <h5>Child Summary</h5>
                    <hr/>
                    <table className={classes.Table}>
                        <tbody>
                            <tr>
                                <td className={classes.DetailTableKey}>Name: </td>
                                <td className={classes.DetailTableValue}>{childName ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Age: </td>
                                <td className={classes.DetailTableValue}>{childAgeString ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>School: </td>
                                <td className={classes.DetailTableValue}>{childSchool ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Address: </td>
                                <td className={classes.DetailTableValue}>{childAddress ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Permissions: </td>
                                <td className={[classes.DetailTableValue].join(' ')}>{childPermissionsTrueString ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}></td>
                                <td className={[classes.DetailTableValue, classes.WarningText].join(' ')}>{childPermissionsFalseString ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Diagnosed Condition: </td>
                                <td className={diagnosedConditionClasses.join(' ')}>{diagnosedCondition ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Dietary Restrictions: </td>
                                <td className={dietaryRestrictionsClasses.join(' ')}>{dietaryRestrictions ?? emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Court Orders: </td>
                                <td className={courtOrderClasses.join(' ')}>{(courtOrdersString && courtOrdersString !== '') ? courtOrdersString : emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Child Flags: </td>
                                <td className={childFlagsClasses.join(' ')}>{(childFlagsStr && childFlagsStr !== '') ? childFlagsStr : emptyValue}</td>
                            </tr>
                            <tr>
                                <td className={classes.DetailTableKey}>Repeating Notes: </td>
                                <td className={classes.DetailTableValue}>{recurringRollNote ?? emptyValue}</td>
                            </tr>
                            {childRollRecord && <tr>
                                <td className={classes.DetailTableKey}>Session Notes: </td>
                                <td className={classes.DetailTableValue}>{rollSessionNote ?? emptyValue}</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>}
                <br/>
                <br/>
                <br/>
                {/* hiding while under construction */}
                {['cabinet'].includes(parentComponent) && false && <div className={classes.ChildSummaryDetail}>
                    <h5>Cabinet Configuration</h5>
                    <hr/>
                    {centreSelector}
                    <br/>
                    <h6>Bus List Preferences</h6>
                </div>}

            </Fragment>
        );
    }

    return (
        <div className={classes.ChildSummary}>
            {content}
        </div>
    )
}

export default ChildSummary;



const calculateAge = dob => {
    const birthDate = new Date(dob);
    const now = new Date();
  
    let yearsDiff = now.getFullYear() - birthDate.getFullYear();
    let monthsDiff = now.getMonth() - birthDate.getMonth();
    let daysDiff = now.getDate() - birthDate.getDate();
    
    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      yearsDiff--;
      monthsDiff += 12;
    }
  
    if (daysDiff < 0) {
      monthsDiff--;
      const prevMonthDate = new Date(now.getFullYear(), now.getMonth() - 1, birthDate.getDate());
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      daysDiff = Math.floor((now - prevMonthDate) / millisecondsPerDay);
    }
  
    let ageString = "";
  
    if (yearsDiff > 0) {
      ageString += yearsDiff === 1 ? "1 year" : `${yearsDiff} years`;
    }
  
    if (yearsDiff > 0 && monthsDiff >= 0) {
      ageString += ", ";
    }
  
    if (monthsDiff >= 0) {
      ageString += monthsDiff === 1 ? "1 month" : `${monthsDiff} months`;
    }
  
    const dateString = birthDate.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });
  
    return `${ageString} (${dateString})`;
  };
  

// const calculateAge = dob => {
//     const birthDate = new Date(dob);
//     const now = new Date();
    
//     let yearsDiff = now.getFullYear() - birthDate.getFullYear();
//     let monthsDiff = now.getMonth() - birthDate.getMonth();
    
//     let ageString = "";
    
//     if (monthsDiff < 0) {
//       yearsDiff--;
//       monthsDiff += 12;
//     }
    
//     if (yearsDiff > 0) {
//       ageString += yearsDiff === 1 ? "1 year" : `${yearsDiff} years`;
//     }
    
//     if (monthsDiff >= 0) {
//       const separator = yearsDiff > 0 ? ", " : "";
//       ageString += `${separator}${monthsDiff === 1 ? "1 month" : `${monthsDiff} months`}`;
//     }
    
//     const dateString = birthDate.toLocaleDateString("en-GB", {day: "2-digit", month: "2-digit", year: "numeric"});
    
//     return `${ageString} (${dateString})`;
//   };
  

//   const calculateAge = dob => {
//     const birthDate = new Date(dob);
//     const now = new Date();
    
//     let yearsDiff = now.getFullYear() - birthDate.getFullYear();
//     let monthsDiff = now.getMonth() - birthDate.getMonth();
    
//     let ageString = "";
    
//     if (monthsDiff < 0) {
//       yearsDiff--;
//       monthsDiff += 12;
//     }
    
//     if (yearsDiff > 0) {
//       ageString += yearsDiff === 1 ? "1 year" : `${yearsDiff} years`;
//     }
    
//     if (monthsDiff > 0 || (monthsDiff === 0 && yearsDiff === 0)) {
//       const separator = yearsDiff > 0 ? ", " : "";
//       ageString += `${separator}${monthsDiff === 1 ? "1 month" : `${monthsDiff} months`}`;
//     }
    
//     const dateString = birthDate.toLocaleDateString("en-US", {day: "2-digit", month: "2-digit", year: "numeric"});
    
//     return `${ageString} (${dateString})`;
//   };
  
