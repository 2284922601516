import { useState } from 'react';
import { onEnterKeyPress } from '../../../utilities/functions';

const AdminPin = (props) => {
    const [pin, setPin] = useState('');
    // console.log('pin: ', pin);

    return (
        <div className="form-group">

            <h3>Staff Sign-In</h3>
            <hr />
            <h6>Enter your staff PIN to continue</h6>
            <p>Please find a staff member to assist you if you'd like to register to use the kiosk</p>
            {/* <hr /> */}
            <input style={{fontSize: '1.3rem', marginBottom: '10px'}} autoFocus className="form-control" name="pincode" type="password" inputMode="numeric" maxLength="4" 
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                onKeyUp={(e) => onEnterKeyPress(e, [props.checkPin, setPin], [pin, ''])}
            />
            <button className="btn btn-block btn-success" onClick={() =>{props.checkPin(pin); setPin('');}}>Enter</button>
        </div>
    );
}

export default AdminPin;