import {React, useState, useCallback, 
    // useEffect, 
    Fragment} from 'react';
import classes from './Enrolments.module.css';
import axios from 'axios';
import { AWS_API, formioAPI, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText, humanize, scrollPageToTop } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import { Form } from 'react-formio';
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import FileUpload from '../../../../UI/FileUpload/FileUpload';
import { ACCSDocTypes, ACCSExceptionalCircumstanceReasons, ACCSExtensionReasons, ACCSRiskReasons, ACCSStateTerritoryBodyTypes } from '../../../../utilities/globalObjects';
import BSTable from '../../../../UI/BSTable/BSTable';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
// import Prompt from '../../../../UI/Prompt/Prompt';


const Enrolments = (props) => {

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           
    const [expandSessions, setExpandSessions] = useState();
    const [showEnrolmentHistoryId, setShowEnrolmentHistoryId] = useState();
    // const [prompt, setPrompt] = useState({});
    
    // user input state
    const [selectedServiceCRN, setSelectedServiceCRN] = useState();
    const [selectedEnrolmentId, setSelectedEnrolmentId] = useState();
    const [viewingFormEnrolmentId, setViewingFormEnrolmentId] = useState();
    
    // data state
    const [enrolmentsData, setEnrolmentsData] = useState([]);
    const [enrolmentForm, setEnrolmentForm] = useState();
    
    // user input data to go along with form
    // const [userInputFormData, setUserInputFormData] = useState({})    

    // accs state
    const [ACCSLoading, setACCSLoading] = useState();
    const [showACCSModal, setShowACCSModal] = useState();
    const [modeACCS, setModeACCS] = useState('submit-accs-certificate');
    const [dataACCS, setDataACCS] = useState();
    const [filesACCS, setFilesACCS] = useState([]);
    const [childACCSCertificateData, setChildACCSCertificateData] = useState();
    const [childACCSDeterminationData, setChildACCSDeterminationData] = useState();

    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [selected, setSelected] = useState([]);      

    
    
    // react router history instance to navigate user to other pages
    let history = useHistory();     
    const location = useLocation();  

    // console logs
    console.log('selectedServiceCRN: ', selectedServiceCRN);
    console.log('enrolmentsData: ', enrolmentsData);
    // console.log('enrolmentForm: ', enrolmentForm);
    console.log('dataACCS: ', dataACCS);
    console.log('childACCSCertificateData: ', childACCSCertificateData);
    // console.log('childACCSDeterminationData: ', childACCSDeterminationData);
    // console.log('viewingFormEnrolmentId: ', viewingFormEnrolmentId);
    console.log('filesACCS: ', filesACCS);
    // console.log('modeACCS: ', modeACCS);
    console.log('selected: ', selected);



    // get data from props
    const centresData = props.centres;    


    const getEnrolments = useCallback(async (serviceCRN, ccsEnrolmentId) => {
        
        setLoading(true);

        // make sure service crn was passed in 
        if (!serviceCRN) {
            alert('No service selected');
            setLoading(false);
            return false;
        }
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {serviceCRN: serviceCRN, ccsEnrolmentId: ccsEnrolmentId}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/read-enrolments', config);
            console.log('data fetch res: ', res.data);
            setEnrolmentsData(res.data ?? []);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);


    }, [history, props, location]);

    const fetchEnrolmentForm = useCallback(async (ccsEnrolmentId) => {
    
        setLoading(true);

        // save ID in state so we have the child details for whom we're viewing the enrolment form
        setViewingFormEnrolmentId(ccsEnrolmentId);
        console.log('fetching form for ID: ', ccsEnrolmentId);
        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};

        const postData = {ccsEnrolmentId: ccsEnrolmentId};

        // get submission json data for selected form ID
        try {
            const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
            console.log('response from form-submissions/fetch: ', res.data);
            setEnrolmentForm(res.data.formSubmissions[0]);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
        }

        setLoading(false);

    }, [history, props, location]);

    // function to intercept form submission data and save to S3 when submit button pressed and CustomSubmit event fired - used for admin to make overwrites to user submitted form
    // const updateFormSubmission = async (submission) => {
    //     setLoading(true);
    //     console.log('updateFormSubmission input param: ', submission);

    //     // get old submission object to take ID for form submission updating
    //     // const oldSubmissionObject = {...pendingSubmissions.filter(obj => obj.formId === selectedForm)[0]};

    //     // create new object with correct ID and new submission data
    //     const newSubmissionObject = {
    //         ...enrolmentForm,
    //         data: submission
    //     };

    //     console.log('new submission object: ', newSubmissionObject);
        
    //     const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
    //     try {
    //         const res = await axios.post(AWS_API + 'form-submissions/update', {postData: newSubmissionObject}, headers);
    //         console.log('response from postSubmission to S3 via form-submissions/udpate: ', res.data);
    //         handleBannerText(setBannerText, 'Form Updated');
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error while updating form submission');
    //     }
        
    //     setEnrolmentForm();
    //     setLoading(false);
        
    // }

    // function to post updated enrolment to aosh-app-cl-calls to submit enrolment with 200D instead of 200A (varying enrolment if some details have changed)
    const postUpdatedEnrolment = async (currentEnrolmentData, submission) => {

        setLoading(true);
        console.log('new submission to post with 200D: ', submission);

        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        const postData = {
            currentEnrolmentData: currentEnrolmentData,
            submission: submission,
        }
        
        try {
            const res = await axios.post(AWS_API + 'ccs/update-enrolment', {postData: postData}, headers);
            console.log('response from postSubmission to S3 via ccs/udpate-enrolment: ', res.data);
            setEnrolmentForm();
            setViewingFormEnrolmentId();
            getEnrolments(selectedServiceCRN, currentEnrolmentData.enrolmentID);
            setExpandSessions();
            handleBannerText(setBannerText, 'Enrolment 200D Submitted');
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error while updating form submission');
        }
        
        setLoading(false);
        
    }

    // function to submit new ACCS certificate for child
    // const postACCSData = async (routeToInvoke) => {
        
    //     setShowACCSModal(false);
    //     setLoading(true);

    //     const data = {
    //         ...dataACCS,
    //         SupportingDocuments: [...filesACCS]
    //     }
    //     console.log('submitting ACCS certificate for child: ', data.childFirstName);

    //     const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

    //     const postData = {
    //         routeToInvoke: routeToInvoke,
    //         data: data
    //     }
        
    //     try {
    //         const res = await axios.post(AWS_API + 'ccs/generic-write', {postData: postData}, headers);
    //         console.log(`response from generic-write call for route ${postData.routeToInvoke}: `, res.data);
    //         setDataACCS();
    //         handleBannerText(setBannerText, 'ACCS Submission Successful');
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error while updating form submission');
    //     }
        
    //     resetACCSData();            
    //     setLoading(false);
    // }

    const postCCSGenericWrite = async (data, routeToInvoke) => {

        console.log('posting data: ', data);
        
        setLoading(true);
        
        // close modals
        setShowACCSModal(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            data: data,
            routeToInvoke: routeToInvoke,
        };
        
        try {
            const res = await axios.post(AWS_API + 'ccs/generic-write', {postData: postData}, headers);
            console.log('res: ', res?.data);
            setDataACCS();
            handleBannerText(setBannerText, 'ACCS Submission Successful');
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting CCS data');
            console.log('error whilst posting to generic write CCS: ', err);
        }    

        // clear data
        resetACCSData();   


        setLoading(false);
    }         
    
    const handlePostACCSFormData = (route) => {
        const data = {
            ...dataACCS
        }

        if (filesACCS?.length > 0) {
            data.SupportingDocuments = [...filesACCS];
        }

        postCCSGenericWrite(data, route);
    }

    // const handleAdviceChildNoLongerAtRisk = () => {
        
    //     if (!childACCSDeterminationData) {
    //         alert('Child does not currently have a determination');
    //     }
        
        
    //     const data = {
    //         determinationID: childACCSDeterminationData[0]?.determinationID
    //     };
    //     console.log('posting data: ', data);
    //     postCCSGenericWrite(data, 'advise-child-no-longer-at-risk');
    // }

    const getACCSData = useCallback(async (serviceCRN, childCRN, childDateOfBirth) => {
        
        setACCSLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {routeToInvoke: 'read-accs-certificate', serviceCRN: serviceCRN, childCRN: childCRN, childDateOfBirth: childDateOfBirth}
        };
        
        // call lambda function to get certificate data
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setChildACCSCertificateData(res.data);
        } catch (err) {
            setShowACCSModal(false);
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching certificate data');
        }            

        // call lambda function to get determination data
        config.params.routeToInvoke = 'read-accs-determination';
        try {
            const res = await axios.get(AWS_API + 'ccs/generic-read', config);
            console.log('data fetch res: ', res.data);
            setChildACCSDeterminationData(res.data);
        } catch (err) {
            setShowACCSModal(false);
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching determination data');
        }            

        setACCSLoading(false);


    }, [history, props, location]);    

    // function to save selected centre and get enrolments within
    const handleSelectCentre = (serviceCRN) => {
        setSelectedServiceCRN(serviceCRN);
        setEnrolmentForm();
        getEnrolments(serviceCRN);
        setViewingFormEnrolmentId();

    }

    // function to call read-enrolment for specified id
    // const getSingleEnrolmentData = (id) => {

    //     if (selectedServiceCRN) {
    //         getEnrolments(selectedServiceCRN, id);
    //     }
    // }

    // render service choices
    const services = (
        <select className='form-control' value={selectedServiceCRN ?? ''} onChange={(e) => {handleSelectCentre(e.target.value); setSelected([]);}}>
            <option disabled value=''>Select Centre</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.service_crn}>{obj.centre_name}</option>)}
        </select>        
    );

    // function to handle which enrolment sessions to show the expanded detail for (and collapse detail when clicked again)
    const handleSessionExpand = (enrolmentId) => {
        if (expandSessions === enrolmentId) {
            setExpandSessions();
        } else {
            setExpandSessions(enrolmentId);
        }
    }

    // function to expand/collapse enrolment history view
    const handleExpandHistory = (id) => {
        
        if (showEnrolmentHistoryId) {
            setShowEnrolmentHistoryId();
            
        } else {
            setShowEnrolmentHistoryId(id);
            
        }


    }

    // run when opening ACCS modal for a child
    const handleOpenACCSModal = async (ccsEnrolmentId) => {
        setShowACCSModal(true); 
        setSelectedEnrolmentId(ccsEnrolmentId);

        // get child crn and date of birth to fetch cert data with
        const childData = enrolmentsData.filter(obj => obj.enrolmentID === ccsEnrolmentId)[0];
        const childCRN = childData?.childCRN;
        const childDateOfBirth = childData?.childDateOfBirth;
        getACCSData(selectedServiceCRN, childCRN, childDateOfBirth);
    }

    const enrolmentHistory = {};
    const renderedEnrolments = [];
    const enrolmentTableData = [];
    // if we've got enrolments data, start rendering UI to display it
    if (enrolmentsData) {
        
        // get unqiue enrolment IDs
        let uniqueEnrolmentIds = enrolmentsData.map(obj => obj.enrolmentID).filter((v, i, a) => a.indexOf(v) === i);

        // if expanded history for a child, only show that child in UI to make it clearer
        if (showEnrolmentHistoryId) {
           uniqueEnrolmentIds = uniqueEnrolmentIds.filter(id => id === showEnrolmentHistoryId); 
        }

        // for each ID, show one div with optional history of past enrolment notices
        uniqueEnrolmentIds.forEach(id => {

            // get enrolment data for this child
            const enrolmentData = enrolmentsData.filter(obj => obj.enrolmentID === id);
            const numberOfEnrolmentEntries = enrolmentData.length;
            console.log(`enrolmentData for child ${id} in loop of ${numberOfEnrolmentEntries}: `, enrolmentData);
            
            // add data to table 

            // find latest enrolment status and pull out of nested structure into flat table record
            const record = {...enrolmentData[0]}
            const sortedStatuses = record?.Statuses?.results?.sort((a,b) => new Date(b?.timeStamp).getTime() - new Date(a?.timeStamp).getTime());
            const latestStatus = sortedStatuses?.[0]?.status;
            record.enrolmentStatus = latestStatus;
            // console.log('pushing record to enrolment data: ', record)
            enrolmentTableData.push(record);
            
            // loop through enrolments and add them to either main UI or optional history
            enrolmentData.forEach((obj, i) => {
                
                // create div with session details inside

                // set class based on either main UI or history
                let classList;
                if (i === 0) {
                    classList = [classes.Enrolment];
                } else {
                    classList = [classes.Enrolment, classes.EnrolmentHistory];
                }

                // populate session detail section of enrolment div
                let sessionDetail;
                if (expandSessions === obj.enrolmentID) {
                    sessionDetail = obj.Sessions?.results?.map((session, i)=> {
                        return (
                            <Fragment key={i}>
                                <hr/>
                                <h6>Session {i+1}</h6>
                                <div className={classes.Row}> <span className={classes.Item}>Session Type</span> <div>{session.sessionType}</div> </div>
                                <div className={classes.Row}> <span className={classes.Item}>CL Week</span> <div>{session.cycleWeekNumber}</div> </div>
                                <div className={classes.Row}> <span className={classes.Item}>Session Day</span> <div>{session.sessionDay}</div> </div>
                                <div className={classes.Row}> <span className={classes.Item}>Start Time</span> <div>{session.startTime}</div> </div>
                                <div className={classes.Row}> <span className={classes.Item}>End Time</span> <div>{session.endTime}</div> </div>
                                <div className={classes.Row}> <span className={classes.Item}>Standard Amount</span> <div>${session.standardAmount}</div> </div>
                            </Fragment>
                        );
                    });
                }
                    // sessionDetail = <h6>Click to expand sessions detail</h6>
                   
                

                // populate main enrolment div
                const enrolmentDiv = (
                    <div key={obj.enrolmentID} className={classList.join(' ')}>
                            <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                                {/* <button className="btn btn-light btn-sm" onClick={() => {handleOpenACCSModal(obj.enrolmentID)}} >ACCS</button> */}
                                {/* <button className="btn CustomButtonPrimary btn-sm" onClick={() => getSingleEnrolmentData(obj.enrolmentID)}>Query Enrolment</button> */}
                                {/* <button className="btn btn-light btn-sm" onClick={() => fetchEnrolmentForm(obj.enrolmentID)} >Get Enrolment Form</button> */}
                            </div>
                            <div style={{'position': 'absolute', 'left': 0, 'bottom': 0}}>
                                {i === 0 && numberOfEnrolmentEntries > 1  ? 
                                <button className="btn btn-success btn-sm" onClick={() => handleExpandHistory(obj.enrolmentID)}>Expand Enrolment History</button> 
                                : null}
                                <button className="btn btn-light btn-sm" onClick={() => handleSessionExpand(obj.enrolmentID)} >Expand Session Detail</button>
                            </div>
                            <br />
                            <h5>{obj.childFirstName + ' ' + obj.childLastName}</h5>
                            <br />

                            {Object.keys(obj).map(key => {
                                // console.log(`type for key: ${key} = ${typeof obj[key]}`)
                                if (typeof obj[key] !== 'object') {
                                    return (
                                        <div className={classes.Row}> <span className={classes.Item}>{humanize(key)}</span> <div>{obj[key]}</div> </div>
                                        )
                                    } else if (key === 'Statuses') {
                                        return (
                                            <Fragment>
                                                <br />
                                                <hr/>
                                                <h5>Enrolment Status History</h5>
                                                {obj[key]?.results?.map(innerObj => {
                                                    return (
                                                        <Fragment>
                                                            <div className={classes.Row}> <span className={classes.Item}>Status</span> <div>{innerObj.status}</div> </div>
                                                            <div className={classes.Row}> <span className={classes.Item}>Timestamp</span> <div>{innerObj.timeStamp}</div> </div>
                                                            <hr/>
                                                        </Fragment>

                                                    )
                                                })}
                                        </Fragment>
                                    )
                                } else {
                                    return null
                                }
                            })}

                            {/* <div className={classes.Row}> <span className={classes.Item}>CRN</span> <div>{obj.childCRN}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Date of Birth</span> <div>{obj.childDateOfBirth}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Enrolment ID</span> <div>{obj.enrolmentID}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Status</span> <div>{obj.status}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Arrangement Type</span> <div>{obj.arrangementType}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Arrangement Start Date</span> <div>{obj.arrangementStartDate}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Arrangement End Date</span> <div>{obj.arrangementEndDate}</div> </div>
                            <div className={classes.Row}> <span className={classes.Item}>Service ID</span> <div>{obj.serviceID}</div> </div> */}
                            <br />
                            <div className={classes.HoverPointer} onClick={() => handleSessionExpand(obj.enrolmentID)}>{sessionDetail}</div>
                            <hr />
                            
                    </div>
                );


                // now only showing enrolment "card" for selected enrolment from table
                if (obj.enrolmentID === selectedEnrolmentId) {

                    // add div to either main UI or history
                    if (i === 0) {
                        renderedEnrolments.push(enrolmentDiv);
                    } else if (i === 1) {
                        enrolmentHistory[obj.enrolmentID] = [];                  
                        enrolmentHistory[obj.enrolmentID].push(enrolmentDiv);
                    } else {
                        enrolmentHistory[obj.enrolmentID].push(enrolmentDiv);
                    }
                }



            });

        })

    }
    console.log('enrolmentTableData: ', enrolmentTableData);

    // wipe state clean when closing modal to prevent lingering files etc from another child
    const resetACCSData = () => {
        setDataACCS();
        setFilesACCS([]);
        
        // setSelectedEnrolmentId();
    }


    console.log('enrolment history: ', enrolmentHistory);

    let enrolmentsContent = <SpinnerDark />;
    if (!loading && renderedEnrolments) {
        enrolmentsContent = 
        <Fragment>
            {renderedEnrolments}
            {enrolmentHistory?.[showEnrolmentHistoryId]}

        </Fragment>
    }

    let form;
    if (enrolmentForm && !loading) {
        const childsEnrolmentData = enrolmentsData.filter(obj => obj.enrolmentID === viewingFormEnrolmentId)[0];
        console.log('childsEnrolmentData: ', childsEnrolmentData);
        const startDate = childsEnrolmentData.arrangementStartDate;

        form = (
            <Fragment>
                <h6>Enrolment Form - {enrolmentForm.data.child.firstName + ' ' + enrolmentForm.data.child.surname}</h6>
                <div className={classes.Row}>
                    <span className={classes.Item}><b>Start Date</b></span>
                    <input disabled type='date' className='form-control' value={startDate}/>
                    <button className='btn btn-block btn-outline-success' onClick={() => postUpdatedEnrolment(childsEnrolmentData, enrolmentForm)} >Update Enrolment</button>
                </div>

                {/* #TODO - add in enrolconfigform here if needed to update enrolment in this section */}
                {/* <EnrolConfigForm userInputFormData={userInputFormData} setUserInputFormData={setUserInputFormData} /> */}
                {/* <button className='btn btn-block btn-outline-success' onClick={() => postMasterData(selectedFormObj)} >Approve Form</button> */}
                <hr />
                <Form 
                    src={formioAPI + enrolmentForm.formType} 
                    submission={enrolmentForm}
                    onNextTab={scrollPageToTop}
                    onCustomSubmit={() => alert('To update forms please visit the filing cabinet/form viewer')} 
                />
                 {/* <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>Note: This submit button will update the original form submission - Intended use is to correct any mistakes in user's form</p> */}
                <hr />
            </Fragment>
        );
    }

    //-------------------
    // render content in ACCS modal depending on mode selected within modal


    
    let accsModalContent = <SpinnerDark />; 
    if (!ACCSLoading) {
        
        // get certificate ID if child already has an ACCS
        let childACCSCertID, childACCSCertStartDate, certStatus, certRecordStatus, certLastUpdated, certSubmitted;
        if (childACCSCertificateData) {

            // get latest certificate/submission
            const latestCert = childACCSCertificateData.sort((a,b) => new Date(b.submissionDateTime).getTime() - new Date(a.submissionDateTime))?.[0];
            console.log('latestCert: ', latestCert);
            
            childACCSCertID = latestCert?.certificateID ?? 'None found';
            childACCSCertStartDate = latestCert?.certificateStartDate;
            certStatus = latestCert?.status;
            certRecordStatus = latestCert?.recordStatus ;
            certSubmitted = latestCert?.submissionDateTime ;
            certLastUpdated = latestCert?.lastUpdatedDateTime ;
        }
        
        // get determination ID if child already has a determination
        let childACCSDetermID, childACCSDetermStartDate, detStatus, detRecordStatus, detLastUpdated, detSubmitted;
        if (childACCSDeterminationData) {
            
            // get latest determination/submission
            const latestDet = childACCSDeterminationData.sort((a,b) => new Date(b.dateDeterminationSubmitted).getTime() - new Date(a.dateDeterminationSubmitted))?.[0];
            console.log('latestCert: ', latestDet);

            childACCSDetermID = latestDet?.determinationID ?? 'None found';
            childACCSDetermStartDate = latestDet?.determinationStartDate;
            detStatus = latestDet?.status;
            detRecordStatus = latestDet?.recordStatus ;
            detSubmitted = latestDet?.dateDeterminationSubmitted ;
            detLastUpdated = latestDet?.lastUpdatedDateTime ;
        }

        const accsSummary = (
            <Fragment>
                <div className={classes.ACCSSummary}> 
                    <div>
                        <div className={classes.Row}> <span className={classes.Item}>Certificate ID</span> <div>{childACCSCertID}</div> </div>
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Start Date</span> <div>{childACCSCertStartDate}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Submitted</span> <div>{certSubmitted}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Status</span> <div>{certStatus}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Record Status</span> <div>{certRecordStatus}</div> </div>}
                        {childACCSCertID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Certificate Last Updated</span> <div>{certLastUpdated}</div> </div>}
                    </div>
                    <div>
                        <div className={classes.Row}> <span className={classes.Item}>Determination ID</span> <div>{childACCSDetermID}</div> </div>
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Start Date</span> <div>{childACCSDetermStartDate}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Submitted</span> <div>{detSubmitted}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Status</span> <div>{detStatus}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Record Status</span> <div>{detRecordStatus}</div> </div>}
                        {childACCSDetermID !== 'None found' && <div className={classes.Row}> <span className={classes.Item}>Determination Last Updated</span> <div>{detLastUpdated}</div> </div>}
                    </div>
                    </div>                
            </Fragment>
        );

        if (modeACCS === 'submit-accs-certificate') {
            accsModalContent = (
                <Fragment>
                    <h3>Submit ACCS Certificate</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'childFirstName', type: 'text', value: enrolmentsData.filter(obj => obj.enrolmentID === selectedEnrolmentId)[0]?.childFirstName}, 
                            {name: 'enrolmentID', type: 'text', value: enrolmentsData.filter(obj => obj.enrolmentID === selectedEnrolmentId)[0]?.enrolmentID}, 
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'certificateStartDate', type: 'date'}, 
                            {name: 'weeksAtRisk', type: 'number'},
                            {name: 'riskReason',  type: 'react-select', options: ACCSRiskReasons, valueProperty: 'value'},
                            {name: 'exceptionalCircumstanceReason', type: 'react-select',  options: ACCSExceptionalCircumstanceReasons, valueProperty: 'value'},
                            {name: 'exceptionalCircumstanceText', type: 'text'},
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                            {name: 'isEvidenceHeld', type: 'checkbox'},
                        ]}
                        />
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>

                </Fragment>      
            );      
        } else if (modeACCS === 'update-state-territory-accs') {
            
            accsModalContent = (
                <Fragment>
                    <h3>Update State/Territory Body</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'certificateID', type: 'text', value: childACCSCertificateData[0]?.certificateID ?? 'No certificate found'}, 
                            {name: 'certificateStartDate', type: 'text', value: childACCSCertificateData[0]?.certificateStartDate ?? 'No certificate found'}, 
                            {name: 'childFirstName', type: 'text', value: enrolmentsData.filter(obj => obj.enrolmentID === selectedEnrolmentId)[0]?.childFirstName}, 
                            {name: 'enrolmentID', type: 'text', value: enrolmentsData.filter(obj => obj.enrolmentID === selectedEnrolmentId)[0]?.enrolmentID}, 
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'dateNoticeGiven', type: 'date'}, 
                            {name: 'bodyType', type: 'react-select',  options: ACCSStateTerritoryBodyTypes, valueProperty: 'value'},
                            {name: 'organisationName', type: 'text'},
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                            {name: 'isNotifiedByStateTerritory', type: 'checkbox'}, 
                            {name: 'isNoticeToStateTerritory', type: 'checkbox'},
                        ]}
                        />
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>

                </Fragment>      
            );   
            
        } else if (modeACCS === 'apply-for-accs-determination') {
            
            accsModalContent = (
                <Fragment>
                    <h3>Apply for Determination</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'certificateID', type: 'text', value: childACCSCertificateData[0]?.certificateID ?? 'No certificate found'}, 
                            {name: 'certificateStartDate', type: 'text', value: childACCSCertificateData[0]?.certificateStartDate ?? 'No certificate found'}, 
                            {name: 'childFirstName', type: 'text', value: enrolmentsData.filter(obj => obj.enrolmentID === selectedEnrolmentId)[0]?.childFirstName}, 
                            {name: 'enrolmentID', type: 'text', value: enrolmentsData.filter(obj => obj.enrolmentID === selectedEnrolmentId)[0]?.enrolmentID}, 
                            {name: 'serviceID', type: 'text', value: selectedServiceCRN}, 
                            {name: 'determinationStartDate', type: 'date'}, 
                            {name: 'indicativeRiskToDate', type: 'date'}, 
                            {name: 'weeksAtRisk', type: 'number'},
                            {name: 'additionalInfo', type: 'text'},
                            {name: 'exceptionalCircumstanceReason', type: 'react-select',  options: ACCSExceptionalCircumstanceReasons, valueProperty: 'value'},
                            {name: 'exceptionalCircumstanceText', type: 'text'},
                            {name: 'extensionReason', type: 'react-select',  options: ACCSExtensionReasons, valueProperty: 'value'},
                            {name: 'dateNoticeGiven', type: 'date'}, 
                            {name: 'bodyType', type: 'react-select',  options: ACCSStateTerritoryBodyTypes, valueProperty: 'value'},
                            {name: 'organisationName', type: 'text'},
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                            {name: 'isNotifiedByStateTerritory', type: 'checkbox'}, 
                            {name: 'isNoticeToStateTerritory', type: 'checkbox'},
                        ]}
                    />
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>

                </Fragment>      
            );   
            
        } else if (modeACCS === 'upload-supporting-docs-accs') {
            accsModalContent = (
                <Fragment>
                    <h3>Upload Supporting Documents</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'ID', type: 'text'}, 
                        ]}
                    />                    
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>
                </Fragment>      
            );             
        } else if (modeACCS === 'advise-child-no-longer-at-risk') {
            accsModalContent = (
                <Fragment>
                    <h3>Upload Supporting Documents</h3>
                    {accsSummary}
                    <hr/>
                    <ParamsForm 
                        data={dataACCS}
                        setData={setDataACCS}
                        config={[
                            {name: 'determinationID', type: 'text', value: childACCSDetermID}, 
                            {name: 'dateNoLongerAtRisk', type: 'date'}, 
                            {name: 'noLongerAtRiskReason', type: 'text'}, 
                            {name: 'recordToSupportNoLongerAtRisk', type: 'checkbox'}, 
                            {name: 'isDeclarationGiven', type: 'checkbox'}, 
                        ]}
                    />                    
                    <FileUpload setFiles={setFilesACCS} files={filesACCS} docTypes={ACCSDocTypes} />
                    <button className='btn btn-block btn-success' onClick={() => handlePostACCSFormData(modeACCS)} >Submit</button>
                </Fragment>      
            );             
        }
    }
    

    // END OF ACCS MDOAL CONTENT RENDERING
    //-------------------


    return (
        <Fragment>
            {/* <Prompt data={prompt} setData={setPrompt} /> */}
            <Banner show={bannerText}>{bannerText}</Banner>  
            {loading && <BackdropSpinner spinner='small-light' />}              
            <div className={classes.Enrolments}>
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>       
                <Modal large show={showACCSModal} modalClosed={() => {setShowACCSModal(false); resetACCSData();}}>
                    {showACCSModal && 
                        <Fragment>
                            <div style={{'position': 'absolute', 'right': 0, 'top': 0}}>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setModeACCS('advise-child-no-longer-at-risk'); resetACCSData();}}>Advise Not At Risk</button>
                                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setModeACCS('upload-supporting-docs-accs'); resetACCSData();}}>Upload Docs</button>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setModeACCS('update-state-territory-accs'); resetACCSData();}}>Update State</button>
                                <button className="btn CustomButtonPrimary btn-sm" onClick={() => {setModeACCS('apply-for-accs-determination'); resetACCSData();}}>Apply For Determination</button>
                                <button className="btn btn-secondary btn-sm" onClick={() => {setModeACCS('submit-accs-certificate'); resetACCSData();}}>Submit Certificate</button>
                            </div>                            
                            {accsModalContent}
                        </Fragment>
                    }
                </Modal>                             
                <h3>Enrolments</h3>
                <hr />
                <p><i>Select a service to view enrolments</i></p>
                <div className={classes.Row}>
                    {services}
                </div>
                <hr />
                <div className={classes.HeaderButtonRowRight}>
                    <button className="btn btn-success btn-sm" onClick={() => {selected?.length !== 1 ? alert('Please select just one enrolment') : handleOpenACCSModal(selected?.[0])}} >ACCS</button>
                    <button className="btn btn-success btn-sm" onClick={() => {selected?.length !== 1 ? alert('Please select just one enrolment') : setSelectedEnrolmentId(selected?.[0])}}>View Details</button> 
                    <button className="btn btn-success btn-sm" onClick={() => {selected?.length !== 1 ? alert('Please select just one enrolment') : fetchEnrolmentForm(selected?.[0])}}>Get Enrolment Form</button> 
                </div>                
                {enrolmentTableData?.length > 0 && <BSTable 
                        selected={selected} 
                        setSelected={setSelected} 
                        table={'read-enrolments'} 
                        data={enrolmentTableData} 
                        paginationConfig={{sizePerPage: 25}}
                    /> }
                {form}
                {enrolmentsContent}
            </div>
        </Fragment>
    )
}

export default Enrolments;