import React, { 
    useState , Fragment
} from 'react'
import classes from './Statements.module.css';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../utilities/globalVariables';
import { handleAwsApiError, openPDFInNewTabFromBase64 } from '../../../utilities/functions';
import axios from 'axios';
// import BSTable from '../../../UI/BSTable/BSTable';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import { useLocation } from 'react-router-dom';
import MaterialList from '../../../UI/MaterialList/MaterialList';
import AccountBalance from '../../Admin/Cabinet/AccountMgmt/AccountBalance/AccountBalance';
import Modal from '../../../UI/Modal/Modal';
import { stripeProducts } from '../../../utilities/globalObjects';
 
const Statements = (props) =>  {

    // UI state
    const [loading, setLoading] = useState();
    const [showPaymentForm, setShowPaymentForm] = useState();

    // table state passed to child component (held in parent to prevent re-rendering issues)
    // const [expanded, setExpanded] = useState([]);
    // const [selected, setSelected] = useState([]);      
    
    // get data from props
    const invoices = props.invoicesData;
    const setError = props.setError;
    const history = props.history;
    const accountData = props.accountData;

    let location = useLocation();

    // sort data
    const sortedInvoies = invoices.sort((a,b) => new Date(b.period_start).getTime() - new Date(a.period_start).getTime());

    // console logs
    console.log('sortedInvoies: ', sortedInvoies);
    console.log('accountData: ', accountData);
    // console.log('selected: ', selected);

    const getStatementPdf = async (invoiceId) => {
        
        setLoading(true);
    
        const selectedInvoice = invoices.filter(obj => obj.invoice_id === invoiceId)[0];
        const periodEnd = selectedInvoice.period_end.slice(0,10);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {periodEnd: periodEnd, invoiceId: invoiceId}
        };

        console.log('fetching pdf with params: ', config.params);
        
        // call lambda function to get master data
        let pdfBase64;
        try {
            const windowReference = window.open();
            const res = await axios.get(AWS_API + 'form-submissions/statement-of-entitlement', config);
            console.log('data fetch res: ', res.data);
            pdfBase64 = res.data.pdfBase64;

            openPDFInNewTabFromBase64(pdfBase64, windowReference);
            // fileName = res.data.fileName;

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        // const linkSource = `data:application/pdf;base64,${pdfBase64}`;
        // const downloadLink = document.createElement("a");
    
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();        
        // downloadPDFFromBase64(pdfBase64, fileName);
        

        setLoading(false);
        
        
    } 
    
    const getOnlineInvoiceUrl = async () => {
        
        setLoading(true);
        
        // get most recent invoice with amount owing
        const mostRecentInvoice = sortedInvoies.filter(obj => obj.status === 'AUTHORISED')?.[0];
        
        if (!mostRecentInvoice) {
            alert('You are unable to perform this action until you have a balance owing');
            setLoading(false);
            return false;
        }
        
        console.log('mostRecentInvoice: ', mostRecentInvoice);
        const invoiceId = mostRecentInvoice?.invoice_id;
        
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {invoiceId: invoiceId}
        };
        
        try {
            const windowReference = window.open();
            const res = await axios.get(AWS_API + 'account-owner-data/online-invoice-url', config);
            console.log('data fetch res: ', res.data);
            const url = res.data.url;
            if (url) {
                // window.open(url,'_newtab');
                // window.open(url);
                windowReference.location = url;
            } else {
                alert('Something went wrong, please contact us to investigate')
            }
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered');
        }         
        
        setLoading(false);
    }
    
    const getStripePaymentLink = async (type, paymentType) => {
        
        setLoading(true);

        // close modals
        setShowPaymentForm();
        
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {type: type, paymentType: paymentType}
        };
        
        try {
            const windowReference = window.open();
            const res = await axios.get(AWS_API + 'account-owner-data/get-partial-payment-url', config);
            console.log('data fetch res: ', res.data);
            const url = res.data.url;
            if (url) {
                // window.open(url,'_newtab');
                // window.open(url,'_blank');
                windowReference.location = url;
            } else {
                alert('Something went wrong, please contact us to investigate')
            }
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered');
        }         
        
        setLoading(false);
    }

    let content = <SpinnerDark/>;
    if (!loading) {
        content = (
            <Fragment>
                <Modal tall show={showPaymentForm} modalClosed={() => setShowPaymentForm()}>
                    <h6>Choose Payment Type</h6>
                    <hr/>
                    <p className={[classes.InfoText].join(' ')}>Please note all payments will be drawn on the same day each week. (For example: If you initiate your plan on a Tuesday, all future weekly payments will be drawn on a Tuesday) You can cancel a payment plan at any time</p>
                    {stripeProducts.filter(obj => !obj?.type?.toLocaleLowerCase()?.includes('paywhatyouwant')).map(obj => <Fragment><button key={obj.type} className='btn btn-block btn-secondary' onClick={() => getStripePaymentLink(obj.type, obj.paymentType)}>{obj.label}</button><br/></Fragment>)}
                </Modal>                
                <p className={[classes.InfoText, classes.RedText].join(' ')}>Please allow up to 24 hours for your account balance to update</p>
                <AccountBalance parentFlag selectedAccountObj={accountData} />

                <br/>
                {/* <p className={[classes.InfoText].join(' ')}>To pay your bill, you will need to open your emails and view your most recent invoice from us and select the ‘’Pay Now’’ option</p> */}
                {/* <p className={[classes.InfoText].join(' ')}>Already signed up to Direct Debit? No need for further action. Direct debits are drawn every Thursday after statements are issued. Please ensure the Account Balance (above) is available</p> */}
                <h6>Payment Options</h6>
                <br/>
                <button onClick={() => getOnlineInvoiceUrl()} className="btn btn-block btn-secondary">Set Up Direct Debit</button>
                <p className={[classes.InfoText].join(' ')}>Select this option to simply fill in your bank details to have your payments automatically drawn each fortnight on a Thursday. Last year parents saved an average of $13.47/fortnight by selecting this option.</p>
                <br/>
                <button onClick={() => getOnlineInvoiceUrl()} className="btn btn-block btn-secondary">Pay Total Outstanding Account Balance</button>
                <p className={[classes.InfoText].join(' ')}>Select this option to pay the total outstanding balance on your account using a credit/debit card</p>
                <br/>
                <button onClick={() => getStripePaymentLink(stripeProducts?.filter(obj => obj?.type?.toLocaleLowerCase()?.includes('paywhatyouwant'))?.[0]?.type, stripeProducts?.filter(obj => obj?.type?.toLocaleLowerCase()?.includes('paywhatyouwant'))?.[0]?.paymentType)} className="btn btn-block btn-secondary">Make a Payment on My Account</button>
                <p className={[classes.InfoText].join(' ')}>Select this option to either make a partial payment on your account or to put a credit on your account in advance using a credit/debit card</p>
                <br/>
                <button onClick={() => setShowPaymentForm(true)} className="btn btn-block btn-secondary">Start a Weekly Payment Plan</button>
                <p className={[classes.InfoText].join(' ')}>Select this option to start a scheduled weekly payment amount of your choice on your account using a credit/debit card</p>
                {/* <p className={[classes.InfoText].join(' ')}><a target="_blank" rel="noreferrer" href="https://www.activeoosh.com.au/account-statement-enquiry">Click here to enquire about a partial payment or payment plan</a></p> */}
                <br/>
                <h6>Statements</h6>
                <hr/>        
                <p><i>Tap a statement below to view/download</i></p>                
                {invoices?.length > 0 && 
                    // <BSTable 
                    // selected={selected} 
                    // setSelected={setSelected} 
                    // table={'view_invoices'} 
                    // data={invoices} 
                    // getDetailData={() => {return false}} 
                    // detailData={[{'no drilldown data specified': 'no drilldown data specified'}]} 
                    // expanded={expanded} 
                    // setExpanded={setExpanded}
                    // /> 
                    <Fragment>

                        <MaterialList
                            list={sortedInvoies} 
                            primaryAction={getStatementPdf}
                            table='view_invoices' primaryField='Statement' secondaryFields={['period_start', 'period_end' ]} 
                        />                    
                    </Fragment>
                }
                {(!invoices || invoices?.length === 0) && <p><i>No statements found</i></p>}
                
            </Fragment>
        );
    }
    
    
    return (
        <div className={classes.Statements}>

            {content}
            

      </div>
    );
}

export default Statements;