import React, { useEffect, useState } from 'react';
import { raw } from '../../translations/en/raw';
import { datePickerObjToISOFormat, humanize } from '../../utilities/functions';
import classes from './ParamsForm.module.css';
import Select from 'react-select'
import DatePicker from "react-multi-date-picker";

const ParamsForm = (props) => {

    // state
    const [internalData, setInternalData] = useState();
    
    // props
    const data = props.data;
    const setData = props.setData;
    const config = props.config;
    

    // console logs
    // console.log('[ParamsForm]-  internalData: ', internalData)
    // console.log('[ParamsForm]-  data: ', data)

    const handleValidation = (value, validationRules) => {

        let validationPassed = true;

        if (validationRules.onlyNumbers) {
            // validationPassed = validationPassed && /^\d+$/.test(value);
            // validationPassed = validationPassed && /[0-9 ]+/.test(value);
            // validationPassed = validationPassed && /^(\s*|\d+)$/.test(value);
            validationPassed = validationPassed && /^[0-9 ]*$/.test(value);
        }

        // console.log('checking validation for: ', value);
        // console.log('validation rules: ', validationRules);
        return validationPassed;
    }

    // console logs
    // console.log('config: ', config);
    // console.log('data: ', data);
    // console.log('internal data: ', internalData);
    
    // initialise data object with specified values 
    useEffect(() => {
        // if (!internalData) {
    
            let newData = {...data};
            for (const obj of config) {
                if (obj.value) {
                    newData = {
                        ...newData,
                        [obj.name]: obj.value
                    };
                }
            }
            // console.log('initalised internal data');
            setInternalData(newData);
        // }

    }, [config, data])

    let rows = [];
    for (const obj of config) {

        // render input as per type specified
        let input;
        const type = obj.type;
        const disabled = obj.disabled;
        const warnOnEmpty = obj.warnOnEmpty;
        const showWarning = obj.showWarning;
        
        // render value depending on whether specified or not
        let value;
        const specifiedValue = obj.value;
        if (specifiedValue) {
            value = specifiedValue;
        } else {
            value = data?.[obj.name] ?? '';
        }

        // console.log('testing: ', obj.name, type, warnOnEmpty, value);

        // deal with dates
        if (type === 'date') {
            value = value.slice(0,10);
        }

        if (type === 'checkbox') {
            input = (
                <input 
                    type={type} 
                    className='form-check-input' 
                    checked={value} 
                    onChange={(e) => {
                        const newData = {...internalData, [obj.name] : e.target.checked};
                        setData(newData);
                        setInternalData(newData);
                    }}
                    disabled={(specifiedValue || disabled) ? true : false}

                />                 
            );
        } else if (type === 'select') {
            const options = obj.options;
            input = (
                <select
                    className='form-control' 
                    value={value} 
                    onChange={(e) => {
                        const newData = {...internalData, [obj.name] : e.target.value};
                        setData(newData);
                        setInternalData(newData);
                    }}   
                    disabled={(specifiedValue || disabled) ? true : false}             
                >
                    {options.map(op => <option key={op.value} value={op.value}>{op.label}</option>)}
                </select>


            );

        } else if (type === 'react-select') {
            // const options = obj.options;
            // override value
            const valueProperty = obj?.valueProperty ?? 'value';
            // console.log('value: ', value);

            // single
            let reactSelectValue;
            if (obj.isMulti) {
                reactSelectValue = obj?.options?.filter(op => value.includes(op[valueProperty]));

            } else {
                reactSelectValue = obj?.options?.filter(op => op[valueProperty]?.toString() === value?.toString())?.[0] ?? {};
            }

            // console.log('reactSelectValue: ', reactSelectValue);
            // console.log('name: ', obj.name);
            // console.log('allowOverride: ', obj.allowOverride);
            input = (
                <div style={{width: '100%'}}>
                    <Select
                        value={reactSelectValue} 
                        onChange={(e) => {
                            console.log('e: ', e);
                            
                            let newData;
                            if (e?.length >= 1) {
                                
                                // multi select
                                const newValue = e.map(obj => obj[valueProperty]);
                                newData = {...internalData, [obj.name] : newValue};

                            } else {
    
                                // single select
                                const newValue = e[valueProperty];
                                newData = {...internalData, [obj.name] : newValue};

                            }

                            setData(newData);
                            setInternalData(newData);
                        }}                
                        options={obj.options}
                        disabled={(specifiedValue || disabled) ? true : false}
                        isMulti={obj.isMulti}
                    >
                    </Select>
                </div>

                // <Select isMulti options={childOptions} onChange={setSelectedChildren} value={selectedChildren} ></Select>    


            );

        } else if (type === 'date-picker') {

            input = (
                <div style={{width: '100%'}}>
                    <DatePicker 
                    inputClass="form-control" 
                    placeholder="Select Date" 
                    value={value} 
                    fixMainPosition={true} 
                    format='YYYY-MM-DD' 
                    multiple 
                    onChange={(e) => {

                        const dates = [];
                        for (const dateObj of e) {
                            console.log('date picker test, e: ', dateObj);
                            console.log('date picker test, date of e: ', datePickerObjToISOFormat(dateObj));
                            const newDate = datePickerObjToISOFormat(dateObj);
                            dates.push(newDate)
                        }

                        const newData = {...internalData, [obj.name] : dates};
                        setData(newData);
                        setInternalData(newData);
                    }}  
                    // onChange={(e) => {setSelectedDates(e);}}
                    />
                </div>
            );

        } else if (type === 'date-picker-month') {

            input = (
                <div className={classes.BlockDatePicker}>
                    <DatePicker 
                    inputClass="form-control" 
                    onlyMonthPicker={true}
                    placeholder="Select Date" 
                    value={value} 
                    fixMainPosition={true} 
                    format='YYYY-MM-DD' 
                    multiple={false} 
                    onChange={(e) => {
                        console.log('date picker test, e: ', e);
                        console.log('date picker test, date of e: ', datePickerObjToISOFormat(e));
                        const newDate = datePickerObjToISOFormat(e);

                        const newData = {...internalData, [obj.name] : newDate};
                        setData(newData);
                        setInternalData(newData);
                    }}  
                    // onChange={(e) => {setSelectedDates(e);}}
                    />
                </div>
            );

        } else if (type === 'textarea') {
            input = (
                <textarea 
                    className='form-control' 
                    value={value} 
                    onChange={(e) => {
                        
                        // check validation rules
                        let validationPassed = true;
                        if (obj.validation) {
                            validationPassed = handleValidation(e.target.value, obj.validation);

                        }
                        
                        // on validation failure
                        if (validationPassed) {
                            const newData = {...internalData, [obj.name] : e.target.value};
                            setData(newData);
                            setInternalData(newData);
                        } else {
                            return false;
                            
                        }
                        
                    }}
                    disabled={(specifiedValue || disabled) ? true : false}
                    
                    /> 
                    );            
        } else {
            input = (
                <input 
                    type={type} 
                    className='form-control' 
                    value={value} 
                    onChange={(e) => {
                        
                        // check validation rules
                        let validationPassed = true;
                        if (obj.validation) {
                            validationPassed = handleValidation(e.target.value, obj.validation);

                        }
                        
                        // on validation failure
                        if (validationPassed) {
                            const newData = {...internalData, [obj.name] : e.target.value};
                            setData(newData);
                            setInternalData(newData);
                        } else {
                            return false;
                            
                        }
                        
                    }}
                    disabled={(specifiedValue || disabled) ? true : false}
                    
                    
                    /> 
                    );
        }


        let classList = [classes.Row];
        let labelClasses = [classes.Label];
        if (type === 'checkbox') classList = [classes.CheckboxRow, 'form-check'];
        if ( (warnOnEmpty && (!value || value === '')) || showWarning) {
            classList.push(classes.Warning);
            labelClasses.push(classes.WarningText);
        }

        
        if (type === 'checkbox') {
            rows.push(
                <div key={obj.name} className={classList.join(' ')}>
                    {input}
                    <span className={labelClasses.join(' ')}>{raw[obj.name] ?? humanize(obj.name) ?? obj.name}</span>
                </div>
            );

        } else {
            rows.push(
                <div key={obj.name} className={classList.join(' ')}>
                    <span className={labelClasses.join(' ')}>{raw[obj.name] ?? humanize(obj.name) ?? obj.name}</span>
                    {input}
                </div>
            );

        }
    }


    return (
        <div key="container" className={classes.ParamsForm}>
            {rows}
        </div>
        

    );
};

export default ParamsForm;