import React, { useState } from 'react'
// import BSTable from '../../../UI/BSTable/BSTable';
import classes from './MaterialCard.module.css';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import   { red } from '@mui/material/colors';
import { bootstrapTableConfig, bootstrapTableDateTimeFields } from '../../utilities/globalObjects';
import { timezone } from '../../utilities/globalVariables';

import {IoIosArrowDown} from "react-icons/io";

const MaterialCard = (props) =>  {

    // state
    const [expanded, setExpanded] = useState([]);

    // get data from props
    const data = props.data;
    const titleField = props.titleField;
    const subtitleField = props.subtitleField;
    const fields = props.fields;
    const table = props.table;
    const expandable = props.expandable;

    // get unique id field for table
    const tableConfig = bootstrapTableConfig[table];
    const uniqueSelectId = tableConfig?.uniqueSelectId;   
    
    // console logs
    // console.log('expanded: ', expanded);
    
    const ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    }));
    
    
      const handleExpandClick = (id) => {
        
        setExpanded(prevExpanded => {
            if (prevExpanded.includes(id)) {
                return [];
            } else {
                return [id]
            }
        });
      };
    
      let content = (
        data.map(obj => {

            const initial1 = obj?.first_name.slice(0,1);
            const initial2 = obj?.surname.slice(0,1);
            const initials = (initial1 + initial2).toUpperCase();

            const id = obj[uniqueSelectId];

            // conversions
            let subtitle =  obj[subtitleField];
            if (bootstrapTableDateTimeFields.includes(subtitleField) && subtitle) {
                subtitle = new Date(subtitle).toLocaleString("sv-SW", {timeZone: timezone});
            }

            return (
                <div key={id} className={classes.MaterialCard}>
                    <Card >
                    <CardHeader
                        avatar={
                        // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <Avatar sx={{ bgcolor: '#2786A8' }} aria-label="recipe">
                            {initials}
                        </Avatar>
                        }
                        action={
                        <IconButton aria-label="settings">
                        </IconButton>
                        }
                        title={obj[titleField]}
                        subheader={subtitle}
                    />
                    <CardContent>
                        {fields?.map(fieldObj => {
                            const field = fieldObj.field;
                            const label = fieldObj.label;
                            const suffix = fieldObj.suffix ?? '';
                            return (
                                <div key={label} className={classes.Row}>
                                    <div className={classes.Label}><Typography variant="subtitle2" color="text.secondary" gutterBottom> {label}</Typography></div>
                                    <div className={classes.Value}><Typography variant="subtitle2"  component="div" gutterBottom> {obj[field]}{suffix}</Typography></div>
                                </div>

                            )
                        })}
                    </CardContent>
                    {expandable && <CardActions disableSpacing>
                        <IconButton aria-label="add to favorites"></IconButton>
                        <IconButton aria-label="share"></IconButton>
                        <ExpandMore
                        expand={expanded.includes(id)}
                        onClick={() => handleExpandClick(id)}
                        aria-expanded={expanded.includes(id)}
                        aria-label="show more"
                        >
                            <IoIosArrowDown size={'1.2rem'} color={'darkgrey'} />
                        </ExpandMore>
                    </CardActions>}
                    {expandable && <Collapse in={expanded.includes(id)} timeout="auto" unmountOnExit>
                        <CardContent>
                        <Typography paragraph>
                            Extra Info
                        </Typography>
                        </CardContent>
                    </Collapse>}
                    </Card>

                </div>


            )
        })
      );


    return (
      <div className={classes.Container}>
        {content}
      </div>
    );
}

export default MaterialCard;