import React, { 
    useEffect,
    useState 
} from 'react'
import classes from './AddCheckins.module.css';
import DatePicker from "react-multi-date-picker";
import Select from 'react-select'
import { sessionTypes } from '../../../../../utilities/globalObjects';
import { raw } from '../../../../../translations/en/raw';
import { datePickerObjToISOFormat } from '../../../../../utilities/functions';

const AddCheckins = (props) =>  {


    // get data from props
    const childrenData = props.childrenData;
    const alreadyTickedChildren = props.alreadyTickedChildren;
    const centreId = props.centreId;
    const dates = props.dates;
    const session = props.session;
    // const addingFromRollFlag = props.addingFromRollFlag;    

    // defaults


    // casual booking form state
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedSession, setSelectedSession] = useState([]);    
    const [selectedCheckedInTime, setSelectedCheckedInTime] = useState();    
    const [selectedCheckedOutTime, setSelectedCheckedOutTime] = useState();    
    const [selectedCheckedIn2Time, setSelectedCheckedIn2Time] = useState();    
    const [selectedCheckedOut2Time, setSelectedCheckedOut2Time] = useState();    
    const [overrideMarkedCheck, setOverrideMarkedCheck] = useState();    


    // upon component load, add already ticked children to selected children
    useEffect(() => {

        // pre-selected children
        console.log('running add already selected chilren useEffect');
        if (alreadyTickedChildren && childrenData) {

            // get value and label for selectedChildren object
            const newSelectedChildren = [];
            const filteredChildrenData = childrenData.filter(obj => alreadyTickedChildren.includes(obj.child_id));
            console.log('filteredChildrenData: ', filteredChildrenData);
            filteredChildrenData.forEach(obj => {newSelectedChildren.push({value: obj.child_id, label: obj.child_name + ' (Acct: ' + obj.account_holder + ')'})});
            setSelectedChildren(newSelectedChildren);
        }

        // pre-selected dates
        if (dates) {
            const dateObjs = dates.map(date => new Date(date))
            setSelectedDates(dateObjs);
        }
        
        // pre-selected sessions
        if (session) {
            const newSession = {value: session, label: raw[session]}
            // console.log('newSessions: ', newSessions);
            setSelectedSession(newSession);
        }           


    }, [alreadyTickedChildren, childrenData, dates, session]);

    // whenever selected session changes, set default times
    useEffect(() => {
        if (selectedSession.value === 'asc') {
            setSelectedCheckedInTime('15:00')
            setSelectedCheckedOutTime('18:30')
        } else if (selectedSession.value === 'bsc') {
            setSelectedCheckedInTime('06:30')
            setSelectedCheckedOutTime('09:00')
            
        } else if (selectedSession.value === 'vac') {
            setSelectedCheckedInTime('07:00')
            setSelectedCheckedOutTime('18:00')

        }

    }, [selectedSession])

    // console logs
    // console.log('childrenData: ', childrenData);
    // console.log('selectedChildren: ', selectedChildren);
    // console.log('alreadyTickedChildren: ', alreadyTickedChildren);
    // console.log('selectedDates: ', selectedDates);
    // console.log('selectedSession: ',selectedSession);

    const submitAddChildrenForm = () => {

        if (!selectedChildren || !selectedSession || !selectedDates || !centreId) {
            alert('please select centre, children, dates and sessions');
            return false;
        }

        // convert date from DateObj in DatePicker to YYYY-MM-DD format needed in lambda
        const datesToSubmit = [];
        for (const date of selectedDates) {
            datesToSubmit.push(datePickerObjToISOFormat(date));
        }              

        const childIds = selectedChildren.map(obj => obj.value);
        const session = selectedSession.value;

        const checkinData = {
            centreId: centreId,
            dates: datesToSubmit, 
            childIds: childIds, 
            session: session, 
            checkedIn1: selectedCheckedInTime,
            checkedOut1: selectedCheckedOutTime,
            checkedIn2: selectedCheckedIn2Time,
            checkedOut2: selectedCheckedOut2Time,
            overrideMarkedCheck: overrideMarkedCheck
        }

        console.log('[AddCheckins.js] - posting booking data: ', checkinData);

        props.updateCheckins(checkinData, clearForm);
    

    }


    const clearForm = () => {
        // restore form defaults
        setSelectedChildren([]);
        setSelectedDates([]);
        setSelectedSession([]);
        setSelectedCheckedInTime();
        setSelectedCheckedOutTime();
        setSelectedCheckedIn2Time();
        setSelectedCheckedOut2Time();
        setOverrideMarkedCheck();
    }
    
    // add children to roll form
    let addChildrenForm;
    if (props.childrenData) {
        // create options for multi select
        const childOptions = [];
        const sessionOptions = [];
        sessionTypes.forEach(el => {sessionOptions.push({value: el, label: raw[el]})})
        childrenData.forEach(obj => {childOptions.push({value: obj.child_id, label: obj.child_name + ' (Acct: ' + obj.account_holder + ')'})})
        addChildrenForm = (
                <div> 
                    <div  className="form-group" >
                        <label className={classes.Label}>Select Children</label>
                        <Select isMulti options={childOptions} onChange={setSelectedChildren} value={selectedChildren} ></Select>    
                    </div>  
                    <div className="form-group">
                        <label className={classes.Label}>Select Dates</label> <br/>
                        <DatePicker inputClass="form-control" placeholder="Select Date" value={selectedDates} fixMainPosition={true} format='YYYY-MM-DD' multiple onChange={(e) => {setSelectedDates(e);}}/>
                    </div>
                    <div className="form-group" >
                        <label className={classes.Label}>Select Session</label>
                        <Select options={sessionOptions} onChange={setSelectedSession} value={selectedSession}></Select>    
                    </div> 
                    <div className={classes.Row}>
                        <div className="form-group" >
                            <label className={classes.Label}>Checked In</label>
                            <input className="form-control" type="time" value={selectedCheckedInTime ?? ''} onChange={(e) => setSelectedCheckedInTime(e.target.value)}  />    
                        </div> 
                        <div className="form-group" >
                            <label className={classes.Label}>Checked Out</label>
                            <input className="form-control" type="time" value={selectedCheckedOutTime ?? ''} onChange={(e) => setSelectedCheckedOutTime(e.target.value)}/>    
                        </div> 
                        <div className="form-group" >
                            <label className={classes.Label}>Checked In 2</label>
                            <input className="form-control" type="time" value={selectedCheckedIn2Time ?? ''} onChange={(e) => setSelectedCheckedIn2Time(e.target.value)} />    
                        </div> 
                        <div className="form-group" >
                            <label className={classes.Label}>Checked Out 2</label>
                            <input className="form-control" type="time" value={selectedCheckedOut2Time ?? ''} onChange={(e) => setSelectedCheckedOut2Time(e.target.value)} />    
                        </div> 
                        <div className="form-check" >
                        <input checked={overrideMarkedCheck} onChange={(e) => setOverrideMarkedCheck(e.target.checked)} className="form-check-input" type="checkbox" id="flexCheckDefault"/>
                        <label >I understand child has been marked in/out already and would like to override those values or add new ones</label>
                    </div>                         
                
                    </div>
                     
                    <button className='btn btn-success btn-block' onClick={submitAddChildrenForm} >Submit</button>
                </div>         
        );

    }


    return (
      <div className={classes.AddCheckins}>
        <h3>Override Check-in/Check-outs</h3>
        <hr />
        {addChildrenForm}
      </div>
    );
}

export default AddCheckins;