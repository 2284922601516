import { Fragment, React, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import classes from './MasterData.module.css';
import axios from 'axios';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
// import MasterDataTable from '../../../UI/MasterDataTable/MasterDataTable';
// import Aux from '../../../hoc/Auxiliary/Auxiliary';

import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
// import { masterDataPrimaryKeys } from '../../../utilities/globalObjects';
import { 
    arrayUniqueByKey,
    // arrayObjsToCsv,
     handleAwsApiError, handleBannerText, humanize } from '../../../utilities/functions';
import Modal from '../../../UI/Modal/Modal';
import Banner from '../../../UI/Banner/Banner';
import BSTable from '../../../UI/BSTable/BSTable';
import { bootstrapTableConfig } from '../../../utilities/globalObjects';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import ParamsForm from '../../../UI/ParamsForm/ParamsForm';
import { raw } from '../../../translations/en/raw';
import Select from 'react-select';
import DatePicker from "react-multi-date-picker";

// const tablesToShow = [
//     'groups','centres','centre_capacities','accounts','account_preferences', 'all_user_accounts','all_contacts','guardians',
// 'casual_bookings', 'view_permanent_bookings', 'roll','roll_event_log','view_session_report_validation',
// 'centre_sessions_calendar','variables',
// 'children', 'child_flags', 'child_enrolments','children_medical','emergency_contacts', 'addresses',
// 'excursions','excursion_detail','pricing_discounts','pricing_rates','pricing_session_hours','view_latest_entitlements',
// 'form_approvals','form_submissions','view_documents_metadata',
// 'idp_group_access','role_access',
// 'kiosks','kiosk_admins','user_roles','staff_details', 'staff_centres',

// ];


const MasterData = (props) => {
    
    // data state
    const [data, setData] = useState();
    const [selectOptionsData, setSelectOptionsData] = useState();
    const [selectedTable, setSelectedTable] = useState();
    const [newEntryData, setNewEntryData] = useState();
    const [centresList, setCentresList] = useState([]);
    const [filterDates, setFilterDates] = useState();
    const [filterCentres, setFilterCentres] = useState();
    const [strFilterDates, setStrFilterDates] = useState();
    const [filters, setFilters] = useState();
    
    // UI state
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingAddEntry, setLoadingAddEntry] = useState(false);
    const [loadingCellUpdate, setLoadingCellUpdate] = useState();
    const [bannerText, setBannerText] = useState('');
    const [showInsertRowModal, setShowInsertRowModal] = useState();
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    
    // Bootstrap table state
    const [selected, setSelected] = useState([]);     
    
    // react router history instance to navigate user to other pages
    let history = useHistory();    
    const location = useLocation();

    // configuration options needed throughout component
    const insertRowConfig = bootstrapTableConfig[selectedTable]?.insertRow;
    
    // console logs
    // console.log('[MasterData.js] - selectOptionsData: ', selectOptionsData);
    // console.log('[MasterData.js] - insertRowConfig: ', insertRowConfig);
    // console.log('[MasterData.js] - newEntryData: ', newEntryData);
    // console.log('[MasterData.js] - data: ', data);
    // console.log('[MasterData.js] - selected: ', selected);
    console.log('filters: ', filters);    

    // get data from props
    const setSublinks = props.setSublinks;
    const role = props.role;
    // console.log('[MasterData.js] - role: ', role);
    
    let tablesToShow = [ 'child_approvals', 'casual_bookings','centre_capacities','centre_sessions_calendar','centres','child_enrolments','excursions','kiosk_admins','kiosks','staff_centres','view_emergency_contacts', 'view_permanent_bookings','view_staff_centres_details', 'view_primary_contacts_info', 'view_roll_sessions_contacts'];
    if (['director', 'headOffice'].includes(role)) {
        tablesToShow = [...tablesToShow, 'general_actions_required_log', 'view_account_debt_and_attendance', 'view_non_confirmed_enrolments', 'view_general_communications_log', 'account_payments', 'view_quicksight_attendance_data_filtered', 'view_pricing_session_hours', 'view_pricing_session_operational_hours', 'view_accounts_in_debt', 'view_kiosk_admins', 'all_user_account_email_changes', 'view_account_activity_monitoring', 'view_accounts', 'account_preference_codes', 'account_refs', 'account_preferences','accounts','addresses','all_contacts','all_user_accounts','casual_bookings','centre_capacities','centre_sessions_calendar','centres','child_enrolments','child_flags','children','children_medical','emergency_contacts','excursions','form_approvals','form_submissions','groups','guardians','idp_group_access','kiosk_admins','kiosks','pricing_discounts','pricing_rates','pricing_session_hours','role_access','staff_centres','staff_details','user_roles','variables','view_accounts_create_contact_xero', 'view_documents_metadata','view_latest_entitlements','view_permanent_bookings','view_session_report_validation', 'view_staff_centres_details'];
        
    } 

    // sort and make unique tables to show
    tablesToShow = tablesToShow.filter((x,i,a) => a.indexOf(x) === i).sort((a,b) => {
        if ( a < b ) return -1;
        if ( a > b ) return 1;
        return 0;
    });

    // set leftpane sublinks
    useEffect(() => {
        if (setSublinks) setSublinks();
    }, [setSublinks])    

    // const getMasterData = async (tableName) => {
    //     setLoading(true);

    //     // clear any previously applied filters now we're fetching a new table
    //     // setColumnFilters({});

    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: {table: tableName}
    //     };
        
    //     // call lambda function to get master data
    //     try {
    //         const res = await axios.get(AWS_API + 'master-data/fetch', config);
    //         setData(res.data);
    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //     }            

    //     setLoading(false);
    // }

    // generic function to get data and save to state
    const getMasterData = useCallback(async (tableName, stateFn, conditionCol, conditionValue, multiFilters) => {
        
        console.log(`fetching data for table ${tableName}`);

        setLoading(true);

        if (tableName) {
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            
            let params = {};
            // if (multiFilters) {
            //     params = {table: tableName, conditionCols: JSON.stringify(multiFilters?.columns), conditionValues: JSON.stringify(multiFilters?.values)}
            // } else {
            //     params = {table: tableName, conditionCol: conditionCol, conditionValue: conditionValue};
            // }
            if (multiFilters) {
                params = {table: tableName, conditionCols: JSON.stringify(multiFilters?.columns), conditionValues: JSON.stringify(multiFilters?.values)}
            } else {
                params = {table: tableName, conditionCol: conditionCol, conditionValue: conditionValue};
            }
            console.log('[getMasterData] - params: ', params);
            
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: params
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log('data fetch res: ', res.data);
                stateFn(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }            

        }

        setLoading(false);
    }, [history, props, location]);
    
    useEffect(() => {
        getMasterData('centres', setCentresList, null, null, null);
    }, [getMasterData]);

    const getSelectOptions = useCallback(async (optionsArray) => {
        
        
        setLoadingAddEntry(true);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {}
        };

        // loop through each table and collect data for select options
        let newSelectOptionsData = {};
        for (const options of optionsArray) {
            
            // pull values out of options config object
            const table = options?.name;
            const value = options?.value;
            const labels = options?.labels;
            const condition = options?.conditions?.[0]; // currently just taking the first condition, could extend to do multiple in future
            
            // set config params to pass to lambda
            config.params.table = table;
            if (condition) {
                config.params.conditionCol = condition.col;
                config.params.conditionValue = condition.value;

            }

            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                const data = res.data;

                // map data into array of objects with value and label 
                const options = data.map(obj => {
                    const label = labels.map(label => obj[label]).join(' ');
                    return ({value: obj[value], label: label})
                })

                // console.log('options: ', options);
                const uniqueOptions = arrayUniqueByKey('value', options);
                // console.log('uniqueOptions: ', uniqueOptions);


                newSelectOptionsData[table] = uniqueOptions.sort((a,b) => a.value - b.value);
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }            
        }

        setSelectOptionsData(newSelectOptionsData);

        setLoadingAddEntry(false);
    }, [history, location, props.token]);

    const updateData = async (oldValue, newValue, row, column) => {
        
        setLoadingCellUpdate(true);
        
        // get primary keys
        const primaryKeys = bootstrapTableConfig[selectedTable]?.primaryKeys;
        if (!primaryKeys) {
            setError('Primary keys not found for selected table: ' + selectedTable);
            setLoadingCellUpdate(false);
            return false
        }        

        // construct primary keys object
        const primaryKeysObj = {};
        primaryKeys.forEach(key => primaryKeysObj[key] = row[key]);


        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        const updateCol = column.dataField;
        const postData = {
            table: selectedTable,
            updateObj: {[updateCol]: newValue},
            primaryKeysObj: primaryKeysObj
        };
        console.log('posting: ', postData);

        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'master-data/update', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Data Updated Successfully`);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoadingCellUpdate(false);        
    }   

    // any time insert entry modal is showing, check insertEntry config, get any necessary select options
    useEffect(() => {
        
        // only run if showInsertRowModal true
        if (!showInsertRowModal) {
            return false
        }

        // determine if select options need fetching
        const selectOptionsConfig = insertRowConfig.filter(obj => obj?.options?.source === 'database')?.map(obj => obj?.options); //?.map(obj => obj?.options?.name);
        console.log('selectOptionsConfig: ', selectOptionsConfig);

        // fetch select options if needed
        if (selectOptionsConfig?.length > 0) {
            getSelectOptions(selectOptionsConfig);
        }


    }, [showInsertRowModal, getSelectOptions, insertRowConfig])  
    
    const insertRow = async (table, row) => {
        
        // close modal
        setLoadingAddEntry(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            table: table,
            rows: [row], // function allows for multiple rows, but we're just inserting one at a time here
        };

        try {
            const res = await axios.post(AWS_API + 'master-data/insert', {postData: postData}, headers);
            console.log('response: ', res.data);
            getMasterData(selectedTable, setData, null, null, filters);
            handleBannerText(setBannerText, 'Entry Added Successfully')
            setNewEntryData();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
        }     
        
        setShowInsertRowModal(false);
        setLoadingAddEntry(false);
    }


    // if showInsertRowModal is true, populate form
    let insertRowForm = <SpinnerDark />;
    if (showInsertRowModal && !loadingAddEntry && insertRowConfig) {

        // for each row in config, render all the columns with the specified data type
        let paramsFormConfig = [];
        for (const row of insertRowConfig) {
            const dataType = row?.dataType;
            const columns = row?.columns;
            const selectOptionsConfig = row?.options;
            const selectOptionsSource = selectOptionsConfig?.source;
            const selectOptionsName = selectOptionsConfig?.name;

            console.log('selectOptionsConfig: ', selectOptionsConfig);
            console.log('selectOptionsName: ', selectOptionsName);

            // get select options depending on source
            let selectOptions;
            if (selectOptionsSource === 'database') {
                
                // if any inputs need database select options, make sure we have these before going any further
                if (!selectOptionsData) return false;

                selectOptions = selectOptionsData?.[selectOptionsName];
            } else if (selectOptionsSource === 'hardcoded') {
                selectOptions = selectOptionsConfig?.list;
            } else if (selectOptionsSource === 'globalObjectsArray') {
                const array = selectOptionsConfig?.array;
                console.log('array: ', array);
                selectOptions = array.map(el => ({value: el, label: raw[el] ?? el}))
            }

            for (const col of columns) {
                paramsFormConfig.push({name: col, type: dataType, options: selectOptions, valueProperty: 'value'});
            }
        }

        console.log('paramsFormConfig: ', paramsFormConfig);

        insertRowForm = (
            <Fragment>
                <ParamsForm 
                    data={newEntryData} 
                    setData={setNewEntryData} 
                    config={paramsFormConfig}
                    />
                <button className='btn btn-block btn-success' onClick={() => insertRow(selectedTable, newEntryData)}>Submit</button>
            </Fragment>
        );
    }



    // let table = <MasterDataTable handleBanner={(text) => handleBanner(setShowBanner, setBannerText, text)} updateAllowed insertAllowed 
    // // deleteAllowed='delete' 
    // token={props.token} updateCompleted={getMasterData} setLoading={setLoading} setError={setError} data={data} table={selectedTable}/>;

    let table;
    if (data) {

        table = (
            <BSTable
            selected={selected} 
            setSelected={setSelected} 
            table={selectedTable} 
            // detailTable={dataMap[showContent]?.detailData} 
            data={data}
            enableEdit 
            updateFunction={updateData}
            // getDetailData={getDetailData}
            // detailData={detailData} expanded={expanded} setExpanded={setExpanded}
            // clearDetailData={clearDetailData}
            
        />         
        );
    } 

    // if (data) {
        // table = <MasterDataTable submit={updateMasterData} data={data}/>;       
        
        // table = <MasterDataTable showFilter={showFilter} updateFilterValue={updateFilterValue} columnToFilter={columnToFilter} filters={columnFilterOptions} headerClicked={openFilterPane} clicked={clicked} changed={setUpdateValue} submit={updateMasterData} updateRow={updateRow} updateCol={updateCol} updateValue={updateValue} data={filteredData}/>;       
    // }
    // console.log('filtered data: ', filteredData);

    let masterDataContent = <SpinnerDark />;
    if (!loading) {
        masterDataContent = table;
    }

    let backdropSpinner;
    if (loadingCellUpdate) {
        backdropSpinner = <BackdropSpinner spinner='small-light' />;
    } 

    


    const buttons = tablesToShow
    // .filter(table => bootstrapTableConfig[table])
    .map(table => 
        <button key={table} className={['btn', 'btn-sm', 'CustomButtonPrimary', classes.Button].join(' ')} onClick={() => {setNewEntryData(); setSelected([]); setSelectedTable(table); getMasterData(table, setData, null, null, filters)}}>
            {humanize(table)}
        </button>
    )

    const handleDateChange = (date) => {
        // Add date change
        console.log("date: ", date);
        setFilterDates(date);
        setStrFilterDates(date.format("YYYY-MM-DD"));
    };

    const handleApplyFilters = () => {
        // Apply filters
        const filtersToSet = {columns: [], values: []};
        // add date filter
        if (strFilterDates){
            filtersToSet.columns = [...filtersToSet.columns, 'date'];
            filtersToSet.values = [...filtersToSet.values, strFilterDates]
        }
        // add centre filter
        if (filterCentres){
            filtersToSet.columns = [...filtersToSet.columns, 'centre_id'];
            filtersToSet.values = [...filtersToSet.values, filterCentres.value]
        }
        setFilters(filtersToSet);
        setShowFiltersModal(false);
    };

    const handleRemoveFilters = () => {
        // remove all filters
        setFilters();
        setFilterCentres();
        setFilterDates();
        setStrFilterDates();
        setShowFiltersModal(false);
    }

    console.log("centresList: ", centresList);
    let filtersForm = <SpinnerDark/>;
    if (showFiltersModal){
        // create centre list
        const centres = centresList.map(obj => {
            return {
                value: obj.centre_id,
                label: obj.location_type === "centre" ? `${obj.centre_name} (Centre)` : `${obj.centre_name} (Bus)`
            };
        }).sort((a,b) => a.label.localeCompare(b.label));
        console.log("centres: ", centres);
        filtersForm = (
            <div>
                <h5>Date</h5>
                <br/>
                {/* <DatePicker multiple format="YYYY-MM-DD" value={filterDates} onChange={handleDateChange} inputClass='form-control'/> */}
                <DatePicker multiple={false} format="YYYY-MM-DD" value={filterDates} onChange={handleDateChange} inputClass='form-control'/>
                <br/>
                <br/>
                <h5>Centre</h5>
                <br/>
                {/* <Select isMulti options={centres} value={filterCentres} onChange={setFilterCentres}/> */}
                <Select options={centres} value={filterCentres} onChange={setFilterCentres}/>
                <br/>
                <button className='btn btn-danger btn-block' onClick={handleRemoveFilters}>Remove Filters</button>
                <button className='btn btn-success btn-block' onClick={handleApplyFilters}>Apply Filters</button>
            </div>
        )
    }

    return (
        <div  className={classes.MasterData}> 
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Modal large tall show={showInsertRowModal} modalClosed={() => setShowInsertRowModal(false)}>
                <h3>Add Entry to {selectedTable}</h3>
                <hr/>
                <div>{insertRowForm}</div>
            </Modal>
            <Modal show={showFiltersModal} modalClosed={() => setShowFiltersModal(false)}>
                <h3>Filters</h3>
                <hr/>
                <div>{filtersForm}</div>
            </Modal>
            <Banner show={bannerText}>{bannerText}</Banner>
            {backdropSpinner}
            <div className={classes.HeaderButtonRowRight}>
                {insertRowConfig &&<button className='btn btn-success btn-sm' onClick={() => setShowInsertRowModal(true)}>Add Entry</button>}
            </div>
            <h3>{selectedTable ? humanize(selectedTable) : 'Select Table'}</h3>
            <hr/>
            <button className='btn btn-warning' onClick={() => setShowFiltersModal(true)}>Apply Filters</button>
            <br/>
            <br/>
            <div style={{display: 'flex', flexFlow: 'row wrap', gap: '2px', paddingBottom: '5px'}}>
                {buttons}
            </div>
            {masterDataContent}
        </div>
    )
}

export default MasterData;
