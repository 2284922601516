import { useState, useEffect, useMemo } from 'react';


export const scrollPageToTop = () => {
    window.scrollTo(0, 0);
}

export const scrollPageToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
}

export const handleAwsApiError = (err, history, location) => {
    if (err.response && err.response.status === 403) {
        console.log('[handleAwsApiError fn] API call forbidden, redirecting to /location.pathname?badToken=true');
        // history.replace("/auth");
        // history.replace("/badToken?q=test");
        history.replace(location.pathname + "?badToken=true");
    } else if (err.response && err.response.status === 409) {
        console.log('409 Error Encountered, Message: ', err.response.data);
        return (err.response.data && err.response.data !== '') ? err.response.data : 'No error message received';
    } else {
        console.log('Unknown Error Encountered in handleAWSApiError function: ', err.stack);
        // console.log('err.response: ', err.response);
        if (err.response && err.response.data && err.response.data.message) {
            return err.response.data.message;
        } else if (err.response && err.response.data) {
            return err.response.data;
        } else if (err.stack) {
            return err.stack;
        } else {
            return null
        }
    }        
}

export const arraysMatch = (arr1, arr2) => {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};

export const handleBanner = (showBannerFn, bannerTextFn, text) => {
    showBannerFn(true);
    bannerTextFn(text);
    setTimeout(() => {
        showBannerFn(false);
        // bannerTextFn('');
    }, 2000);
}

export const handleBannerText = (bannerTextFn, text) => {
    bannerTextFn(text);
    setTimeout(() => {
        bannerTextFn();
    }, 3000);    
}

export const onEnterKeyPress = (e, fns, fnInputs) => {
    if (e.keyCode === 13) {
        fns.forEach((fn, i) => {
            fn(fnInputs[i]);
        })
    }
}

export const handlePrompt = (message, proceedFunction, setPromptState, subtitle, link, customTitle, subtitle2, link2) => {
    const newState = {
        show: true,
        message: message,
        proceedFunction: proceedFunction,
        subtitle: subtitle,
        link: link,
        customTitle: customTitle,
        subtitle2: subtitle2,
        link2: link2,
    };
    setPromptState(newState);
}

// get next day of week from 
export const getNextDay = (x, date) => {
    var now = new Date(date);    
    now.setDate(now.getDate() + (x+(7-now.getDay())) % 7);
    return now.toLocaleDateString('sv-SW');
}

// get previous monday from given date
export const getPrevMonday = (date) => {
    const prevMonday = new Date(date);
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
    return prevMonday.toLocaleDateString('sv-SW');     
}

export const dateDiffInDays = (a, b) => {
  
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const calculateCentrelinkWeek = (date, clMonday) => {
    // console.log('[calculateCentrelinkWeek] - clMonday: ', clMonday);
    // console.log('[calculateCentrelinkWeek] - date: ', date);
    
    const dayOfFortnightlyCycle = dateDiffInDays(new Date(clMonday), new Date(date)) % 14;   
    // console.log('[calculateCentrelinkWeek] - dayOfFortnightlyCycleLastSunday: ', dayOfFortnightlyCycle);
    return (dayOfFortnightlyCycle >=7) ? 2 : 1;
    

}

export function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => {
        return (
            new IntersectionObserver(
              ([entry]) => setIntersecting(entry.isIntersecting)
            )
        );        
    }, []); 
  
    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [ref, observer])
  
    return isIntersecting
}

export const downloadPDFFromBase64 = (pdfBase64, fileName) => {
    
    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();        
}

export const openPDFInNewTabFromBase64 = (pdfBase64, windowReference) => {
    // let pdfWindow = window.open("")
    // pdfWindow.document.write(
    //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    //     encodeURI(pdfBase64) + "'></iframe>"
    // )

    var byteCharacters = atob(pdfBase64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    // window.open(fileURL);    
    windowReference.location = fileURL;
  }

export const datePickerObjToISOFormat = (date) => {
    const dateObj = typeof date?.toDate === 'function' ? date?.toDate() : date;
    // console.log('[datePickerObjToISOFormat] : ', dateObj);
    // console.log('[datePickerObjToISOFormat] : ', dateObj?.toLocaleDateString('sv-SW'));

    const dateSQLFormat = dateObj?.toLocaleDateString('sv-SW')?.slice(0,10);
    // const dateParts = dateStr.split("/");
    // const dateSQLFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    // console.log('dataSqlFormat: ', dateSQLFormat);
    return dateSQLFormat;    
}

export const updateArrayOfObjectsInState = (updateValue, updateCol, conditionCols, conditionValues, data, updateFn) => {
    // console.log('new value: ', updateValue, updateCol, conditionCols, conditionValues);

    const newArray = data.map(obj => {
        // find the obj in array which matches the criteria passed into function
        let objToUpdate = false;
        for (let i=0; i<conditionCols.length; i++) {
            objToUpdate = obj[conditionCols[i]] === conditionValues[i];
        }


        if (objToUpdate) {
            // once we have a match, update the specified obj and return it as part of the map
            return {...obj, [updateCol]: updateValue}
        } else {
            // otherwise return obj unchanged
            return obj;
        }
    })

    // update state
    updateFn(newArray);
}


export const incrementDate = (initialDate, dayDiff, setStateFunction) => {
    let newDate = new Date(initialDate);
    newDate.setDate(newDate.getDate() + dayDiff);
    setStateFunction(newDate);
}    


// function expects array of arrays, will return true if any array is not made up of a single value (i.e. array of CCS% over time, if CCS has changed it will return true)
export const checkForArrayChanges = (arrays) => {
    let changed = false;
    for (const array of arrays) {
        const uniqueEls = array.filter((x, i, a) => a.indexOf(x) === i).length;
        if (uniqueEls !== 1) {
            changed = true;
        }
    }

    return changed;
}

 // turn db names into more readable text
 export const humanize = (str) =>  {

    // console.log('str: ', str?.length);
    
    var i, frags = str?.split('_');
    for (i=0; i<frags?.length; i++) {
        frags[i] = frags?.[i].charAt(0)?.toUpperCase() + frags?.[i]?.slice(1);
    }
    // console.log('frags: ', frags);
    const text = frags?.join(' ');
    // return text;
    
    // console.log('text: ', text?.length);
    const newText = text?.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").trim();
    // console.log('newText: ', newText?.length);
    return newText
    
}


// updated function to handle commas in values
export const arrayObjsToCsv = (arr) => {

    // alert and break out of fn if not at least one row of data passed in
    console.log('[arrayObjToCsv] - array: ', arr);
    if (!arr?.length > 0) {
        alert('Please select at least one row to export');
        return false;
    }

    // get headers for first row of CSV
    const headers = Object.keys(arr[0]);
    console.log('headers: ', headers);

    // create CSV string
    let csvContent = "data:text/csv;charset=utf-8," 
    + headers.map(header => `"${header}"`).join(",") + "\n" 
    + arr.map(row => {

        const values = Object.values(row).map(value => {
          if (typeof value === 'string' && value.includes(',')) {
            // wrap value in quotes to handle commas
            return `"${value}"`;
          }
          return value;
        });
        const string = values.join(",");
        console.log('string: ', string);
        return string
        
    }).join("\n");    

    // encode and download CSV
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link); // Required for FF
    
    link.click(); // This will download the data file named "my_data.csv".    
    return true;
}

// export const arrayObjsToCsv = (arr) => {

//     // alert and break out of fn if not at least one row of data passed in
//     console.log('[arrayObjToCsv] - array: ', arr);
//     if (!arr?.length > 0) {
//         alert('Please select at least one row to export');
//         return false;
//     }

//     // get headers for first row of CSV
//     const headers = Object.keys(arr[0]);
//     console.log('headers: ', headers);

//     const test = arr[0]
//     console.log('test: ', test);
//     const values = Object.values(test);
//     console.log('values: ', values);


//     // create CSV string
//     let csvContent = "data:text/csv;charset=utf-8," 
//     + headers.join(",") + "\n" 
//     + arr.map(row => {

//         const values = Object.values(row);
//         const string = values.join(",").replaceAll("\n", " ");
//         console.log('string: ', string);
//         return string
        
//     }).join("\n");    

//     // encode and download CSV
//     var encodedUri = encodeURI(csvContent);
//     var link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "report.csv");
//     document.body.appendChild(link); // Required for FF
    
//     link.click(); // This will download the data file named "my_data.csv".    
//     return true;
// }

export const getAgeFromDateStr = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const isBirthday = (dateOfBirth, currentDate) => {
    const birthday = new Date(dateOfBirth);
    // console.log('[isBirthday] - currentDate: ', currentDate);
    // console.log('[isBirthday] - birthday: ', birthday);
    // console.log('[isBirthday] - birthday.getMonth(): ', birthday.getMonth());
    // console.log('[isBirthday] - birthday.getDate(): ', birthday.getDate());
    // console.log('[isBirthday] - currentDate.getMonth(): ', currentDate.getMonth());
    // console.log('[isBirthday] - currentDate.getDate(): ', currentDate.getDate());
    return (
      birthday.getMonth() === currentDate.getMonth() &&
      birthday.getDate() === currentDate.getDate()
    );
};

export const arrayUniqueByKey = (key, array) => {
    return [...new Map(array.map(item =>
        [item[key], item])).values()];
}


export const handleBSTableColourCoding = (table, row, col, cell) => {



    if (table === 'pending_session_reports') {

        if (col === 'warnings' && cell > 0) {
            return 'global-danger'
        } else if (col === 'warnings' && cell <= 0) {
            return 'global-goodToGo'
        } else if (col === 'submitted' && cell === 'YES') {
            return 'global-goodToGo'
        } else if (col === 'submitted' && cell !== 'YES') {
            return 'global-neutral'
        } else if (col === 'header_status' && cell === 'CEASED') {
            return 'global-danger'
        }

    } else if (['view_submitted_session_reports', 'view_amended_session_reports', 'view_submitted_session_reports_v2', 'view_late_created_session_reports'].includes(table)) {

        if (col === 'submit_status' && cell === 'submitted') {
            return 'global-goodToGo'
        } else if (col === 'submit_status' && cell === 'failed') {
            return 'global-danger'
        } else if (col === 'validated_at' && cell !== null) {
            return 'global-goodToGo'
        } else if (col === 'validated_status' && cell && cell !== 'PROCES') {
            return 'global-neutral'
        } else if (col === 'msg' && cell && cell === 'withdrawn') {
            return 'global-neutral'
        } else if (col === 'validated_status' && cell === 'PROCES') {
            return 'global-goodToGo'
        } else if (col === 'original_invoice' && (+cell === 1 || +row?.amended_invoice === 1)) {
            return 'global-goodToGo'
        } else if (col === 'amended_invoice' && (+cell === 1 || +row?.original_invoice === 1)) {
            return 'global-goodToGo'
        } else if (col === 'original_invoice' && +cell === 0 && +row?.amended_invoice === 0) {
            return 'global-danger'
        } else if (col === 'amended_invoice' && +cell === 0 && +row?.original_invoice === 0) {
            return 'global-danger'
        }


    } else if (table === 'view_roll_changes_after_submission') {
        if (col === 'session_report_submitted' && cell?.length > 0) {
            return 'global-danger'
        }
    } else if (table === 'view_session_report_payments') {
        if (col === 'warnings' && cell > 0) {
            return 'global-danger'
        } else if (['invoice_subsidy', 'invoice_fee', 'sr_fee', 'sr_subsidy', 'submitted_at'].includes(col) && !cell) {
            return 'global-warning'
        } else if (col === 'warnings' && cell === 0) {
            return 'global-goodToGo'
        }
    } else if (['view_pending_invoices', 'view_invoices'].includes(table) ) {
        
        // if there's a period_start then invoice is from regular invoice cycle, so highlight if invoiced total doesn't equal total session report gap (if no period_start, it's a one-off invoice so there won't be a gap)
        if (col === 'total' && table !== 'view_pending_invoices' && row?.period_start && cell && +cell !== +row?.gap && Math.round(+cell, 2) !== Math.max(0, Math.round(((+row?.gap ?? 0) + (+row?.balance_brought_forward ?? 0) + (+row?.amendments ?? 0)), 2))) {
            return 'global-danger'
        } else if (col === 'total' && cell && cell !== '' && +cell < 0) {
            return 'global-highlight'
        } else if (col === 'total' && cell && cell !== '' &&  Math.round(+cell, 2) === Math.max(0, Math.round(((+row?.gap ?? 0) + (+row?.balance_brought_forward ?? 0) + (+row?.amendments ?? 0)), 2))) {
            return 'global-goodToGo'
        // } else if (col === 'gap' && cell && cell && cell !== '' && +cell === +row?.total) {
        //     return 'global-goodToGo'
        } else if (col === 'status' && cell === 'DRAFT') {
            return 'global-warning'
        } else if (row?.invoice_id > 0 && row?.period_start && (col === 'statement_created' || col === 'statement_attached') && !cell) {
            return 'global-danger'
        } else if (row?.invoice_id > 0 && (col === 'statement_created' || col === 'statement_attached') && cell) {
            return 'global-goodToGo'
        } else if (row?.invoice_id > 0 && (col === 'total') && !cell) {
            return 'global-danger'
        } else if (col === 'status' && cell === 'AUTHORISED') {
            return 'global-goodToGo'
        } else if (col === 'status' && cell === 'PAID') {
            return 'global-complete'
        } else if (col === 'status' && cell === 'VOIDED') {
            return 'global-neutral'
        } else if (col === 'email_status' && cell === 'FAILED') {
            return 'global-danger'
        } else if (col === 'email_status' && cell === 'SUCCESS') {
            return 'global-goodToGo'
        // } else if (['invoice_id', 'invoiced_gap'].includes(col) && !cell) {
            //     return 'global-warning'
        } else if (['submitted_session_reports', 'validated_session_reports'].includes(col) && cell !== row?.session_reports) {
            return 'global-danger'
        } 
    } else if (['view_general_actions_required',].includes(table) ) {
        if (col === 'status' && cell !== 'actioned') {
            return 'global-danger'
        }
    }    
}

export const formatCurrency = (num) => {
    
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });    
    
    return formatter.format(num);
};


export const getURLSubroute = (location) => {
    const pathname = location?.pathname;
    const split = pathname.split('/');
    // console.log('split: ', split);
    const subroute = split?.[2]
    return subroute;
}

export const containsNumber = str => /\d/.test(str);

export const countNumbers = str => (str.match(/\d+/g) || []).length;

export const countDigits = str => (str.match(/\d/g) || []).length;

export const removeBracketExpressionFromString = (inputString) => inputString.replace(/ *\([^)]*\) */g, "").replace(/ *\[[^\]]*\] */g, "").trim();

export const formatDateString = (inputDate) => {

    const getOrdinalSuffix = (dayOfMonth) => {
        if (dayOfMonth > 3 && dayOfMonth < 21) return 'th';
        switch (dayOfMonth % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
      };
      
      
    const date = new Date(inputDate);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const suffix = getOrdinalSuffix(dayOfMonth);
    return `${dayOfWeek} ${month} ${dayOfMonth}${suffix}`;
  };
  

  export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };