import classes from './NewBanner.module.css';

const NewBanner = () => {
    return (
        <div className={classes.BannerContainer}>
            <div className={classes.Banner}>New</div>
        </div>
    );
  };

export default NewBanner;