import {React, useState, useCallback, useEffect} from 'react';
import classes from './DeviceMgmt.module.css';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';

const DeviceMgmt = (props) => {

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');           

    // data state
    const [deviceData, setDeviceData] = useState([]);
    
    // user input state
    const [otac, setOtac] = useState();

    // react router history instance to navigate user to other pages
    let history = useHistory();       
    const location = useLocation();    

    // console logs
    console.log('deviceData: ', deviceData);
    

    const getDeviceData = useCallback(async () => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'ccs/device-info', config);
            console.log('data fetch res: ', res.data);
            setDeviceData(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);


    }, [history, props, location]);

    const activateDevice = useCallback(async (deviceName, otac) => {
        
        setLoading(true);
    
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            otac: otac,
            deviceName: deviceName,
        };
        try {
            const res = await axios.post(AWS_API + 'ccs/activate-device', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, `Device activated`);
            getDeviceData();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
            setLoading(false);
        }           


    }, [history, props, getDeviceData, location]);    

    // get initial data
    useEffect(() => {
        getDeviceData();
    }, [getDeviceData])

    let content = <SpinnerDark />;
    if (!loading) {
        content = Object.keys(deviceData).map(device => {
            return (
                <div key={device}>
                    <hr />
                    <div>Device Name: {device}</div>
                    <div>Public Key: {deviceData[device]?.publicPEM ? deviceData[device]?.publicPEM.slice(0,25) + '***' : 'No key registered with device'}</div>
                    <div>Key Expiry: {deviceData[device]?.keyExpiry}</div>
                    <div>Device Expiry: {deviceData[device]?.deviceExpiry}</div>
                    <p> </p>
                    <h6>One Time Activation Code</h6>
                    <input className="form-control" type="text" onChange={(e) => setOtac(e.target.value)} value={otac} />
                    <p> </p>
                    <button className="btn btn-block btn-success" onClick={() => activateDevice(device, otac)}>Activate Device</button>
                    <hr />
                </div>
            );
        })
    }

    // if no device data, inform user 
    if (content.length === 0) {
        content.push(<span key={'no-devices'}><i>No devices configured for this provider. 
            <br/>1. Log into PRODA using this provider ID
            <br/>2. Add device
            <br/>3. Add entry to oshc/keys in AWS Secrets Manager for this provider/device combo
            <br/>4. Activate device through UI. From this point on, device can be managed through the app.
            </i></span>)
    }

    
    return (
        <div className={classes.DeviceMgmt}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Banner show={bannerText}>{bannerText}</Banner>                

            <h3>
                CCS Device Management
            </h3>
            {content}
        </div>
    )
}

export default DeviceMgmt;