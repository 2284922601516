import React, { 
    useEffect,
    useCallback,
    useState, 
    } from 'react'
import classes from './SimpleFormio.module.css';
// import { Form } from 'react-formio';
import { useHistory, useLocation } from "react-router-dom";
import { formioAPI } from '../../../utilities/globalVariables';
import { formioConfig } from '../../../utilities/globalObjects';
import Formio from 'formiojs/Formio';
// import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import Banner from '../../../UI/Banner/Banner';
import { handleAwsApiError, handleBannerText } from '../../../utilities/functions';
import Modal from '../../../UI/Modal/Modal';
import axios from 'axios';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../utilities/globalVariables';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';


const SimpleFormio = (props) =>  {

    // get props
    const formType = props.formType;
    const centreId = props.centreId;
    const onSubmitted = props.onSubmitted;

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    const postSubmission = useCallback(async (submission, onSubmissionDone) => {
        
        
        setLoading(true);

        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        
        // construct postdata - if centre exists in top level of form data, use it for meta data and S3 partition key
        const postData = {formType: formType, centreId: centreId, data: submission}; 
        console.log('postSubmission with post data: ', postData);
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
            console.log('response from postSubmission to S3: ', res.data);
            handleBannerText(setBannerText, 'Form Submitted');
            onSubmissionDone();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
        }

        // show success banner and then redirec to accountOwner home
        setLoading(false);
    

    }, [history, props.token, formType, centreId, location]);    
    
    
    // render form is 
    useEffect(() => {

        if (formType) {

            const formToDisplay = formioConfig[formType]?.useForm ?? formType;
            console.log('loading up form for formType and formToDisplay: ', formType, formToDisplay);

            Formio.icons = 'fontawesome';
            Formio.createForm(document.getElementById('simple-formio-div'), formioAPI + formToDisplay)
            .then(function(form) {
                console.log('rendering form: ', form);
                // Prevent the submission from going to the form.io server.
                form.nosubmit = true;

                const submit = (submission) => {
                    const isValid = form.checkValidity(null, true, null, false);
                    console.log('isValid: ', isValid);  
                    
                    const onSubmissionDone = () => {
                        onSubmitted();
                        form.emit('submitDone', submission)
                    }
                    
                    // if form is invalid, figure out which fields haven't been touched and alert user, otherwise submit form
                    if (!isValid) {
                        form.setPristine(false);
                        alert('form validation failed');
                        
                    } else {
                        
                        // form passed validation, submit
                        console.log('posting submission: ', submission);
                        postSubmission(submission, onSubmissionDone)
                    }


                }
            
                form.on('submit', (submission) => submit(submission.data)); // "submission" object is different when on submit vs a custom event, therefore this is required to make data structrue match all of our customevent forms
                form.on('CustomSubmit', submit);

            });        
        }
    }, [postSubmission, formType, onSubmitted])



    return (
        <div className={classes.SimpleFormio}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Banner show={bannerText}>{bannerText}</Banner>  
            {/* {!loading && <BackdropSpinner spinner='small-light' />} */}
            {loading && <SpinnerDark />}
            <hr />
            {(formType) && !loading && <div className={classes.FormContainer}>
                <div className={classes.Form} id="simple-formio-div"></div>
            </div>}

        </div>
    );
}

export default SimpleFormio;
