import { Fragment, useEffect, useState } from 'react';
import classes from './Communications.module.css';
import CustomEmail from './CustomEmail/CustomEmail';
import Modal from '../../../UI/Modal/Modal';

const Communications = (props) => {

    const setSublinks = props.setSublinks;
    const [showContent, setShowContent] = useState('email');
    const [error, setError] = useState(false);

    // Set Sub Links
    useEffect(() => {
        setSublinks([
            {title: 'Email', icon: 'question', onClick: () => setShowContent('email')},
            // {title: 'Text', icon: 'question', onClick: () => setShowContent('text')},
        ])
    }, [setSublinks, setShowContent]);

    let content;
    if (showContent === 'email'){
        content = <CustomEmail token={props.token}/>
    }

    return (
        <Fragment>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <div className={classes.Communications}>
                {content}
            </div>
        </Fragment>
    )
}

export default Communications;