import {React} from 'react';
import classes from './Login.module.css';
import { cognitoURL } from '../../../utilities/globalVariables';
// import { NavLink } from 'react-router-dom';
// import { useEffect } from 'react';
// const moment = require("moment-timezone");


const Login = (props) => {

    // // upon accountowner render, send message to react native indicating user is authenticated
    // useEffect(() => {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({
    //         messageType: 'accountOwnerAuthenticated',
    //         email: 'kendallmacmillan@gmail.com',
    //         token: 'asdfasdfkadfblkj223235235jk'
    //     }));
    //     alert('message posted');


    // }, []);    

    
    return (
        <div className={classes.Login}>
            {/* <NavLink to="/enrol" className='btn btn-block btn-lg CustomButtonPrimary m-2'>New Enrolment</NavLink> */}
            {/* <NavLink to="/enrol" className='btn btn-block btn-lg btn-light m-2'>Log in</NavLink> */}
            <a className="btn btn-block btn-lg btn-success m-2" href={cognitoURL}>Register / Login</a>
            {/* <button className="btn btn-block btn-lg btn-outline-light m-2" onClick={test}>Test</button> */}
        </div>
    )
}

export default Login;