import { React, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import classes from './CustomEmail.module.css';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { tinyAPIKey } from '../../../../utilities/globalVariables';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import BSTable from '../../../../UI/BSTable/BSTable';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import Banner from '../../../../UI/Banner/Banner';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import CreatableSelect from 'react-select/creatable';
import { emailVariables } from '../../../../utilities/globalObjects';



const CustomEmail = (props) => {
    const [showTextBox, setShowTextBox] = useState(false);
    const [templateHeader, setTemplateHeader] = useState();
    const [showAccounts, setShowAccounts] = useState(false);
    const [showVariableLegend, setShowVariableLegend] = useState(false);
    const [accounts, setAccounts] = useState();
    const [templates, setTemplates] = useState();
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [template, setTemplate] = useState();
    const [htmlContent, setHtmlContent] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();
    const [loadingSaveTemplate, setLoadingSaveTemplate] = useState(false)
    const [loadingSendEmail, setLoadingSendEmail] = useState(false)

    const history = useHistory();
    const location = useLocation();
    
    // configuration options needed throughout component

    const getData = useCallback(async(tableName, setFn) => {
        setLoading(true);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: tableName}
        };
        
        // call lambda function to get master data
        try {
            // console.log("Table Name: ", tableName);
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log("res.data: ", res.data);
            setFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            setLoading(false);
        }            

        setLoading(false);
    }, [history, location, props.token]);

    const saveTemplate = async(templateConfig) => {
        setLoadingSaveTemplate(true);
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {
            template: templateConfig
        };

        console.log('postData: ', postData);
        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'master-data/add-email-template', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Request Sent`);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while requesting email');
            console.log('error whilst requesting email: ', err);
        } finally {
            setLoadingSaveTemplate(false)
        }
    };

    const handleSave = () => {
        // save or replace template
        saveTemplate({
            subject: templateHeader.label,
            html: htmlContent,
        });
    };

    const handleSelectAccounts = () => {
        // save or replace template
        setTemplate({
            subject: templateHeader.label,
            html: htmlContent,
        });
        // show accounts to send email
        setShowAccounts(true);
    };

    const handleShowText = () => {
        // if not shown already, show tiny text editor
        if (!showTextBox){
            setShowTextBox(true);
        }
    };

    const handleEditorChange = (content, editor) => {
        // update text in textbox in state
        setHtmlContent(content);
        console.log("content: ", content);
    };

    useEffect(() => {
        // get accounts
        getData('view_primary_contacts_info', setAccounts);
        getData('email_templates', setTemplates);
    }, [getData]);

    const handleInputChange = (newValue, actionMeta) => {
        if (newValue){
            // set template name
            setTemplateHeader({
                label: newValue.label,
                value: newValue.value
            });
            // set html body into state
            setHtmlContent(templates.find(template => template.template_name === newValue.label)?.html);
        } else{
            setTemplateHeader({});
        }
    }

    console.log("Template header: ", templateHeader);
    let content = <div/>;
    if (showTextBox){
        // Display test box
        let templateNames = [];
        templates.forEach(template => templateNames.push({ value: template.template_name, label: template.template_name}));
        content = (
            <div className={classes.TinyEditor}>
                <CreatableSelect isClearable options={templateNames} onChange={handleInputChange}/>
                <br/>
                <br/>

                <div className={classes.Editor}>

                <Editor
                    apiKey={tinyAPIKey}
                    value={htmlContent}
                    init={{
                        selector: 'textarea',
                        automatic_uploads: true,
                        plugins: 'link lists wordcount',
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic underline backcolor forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | link |' +
                        'removeformat',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onEditorChange={handleEditorChange}
                    />
                </div>
                <br/>
                <div className={classes.HeaderButtonRow}>
                    <div className={classes.ChildButton}>
                        <button className='btn btn-success btn-block' onClick={handleSave}>Save Template</button>   
                    </div>
                    <div className={classes.ChildButton}>
                        <button className='btn btn-warning btn-block' onClick={handleSelectAccounts}>Select Accounts</button>   
                    </div>
                </div>
            </div>
        )
    }
    const sendEmail = async(config) => {
        setLoadingSendEmail(true);
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {
            emailConfig: config
        };
        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'ccs/run-step-send-custom-emails', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Request Sent`);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while requesting email');
            console.log('error whilst requesting email: ', err);
        } finally {
            setShowAccounts(false);
            setLoadingSendEmail(false);
        } 
    }

    const handleSend = () => {
        const regex = /{([^}]+)}/g;
        const matches = [...template?.html.matchAll(regex)];
        console.log("Matches: ", matches);
        let foundAllVariables = true;
        for (const variable of matches){
            const foundVariable = emailVariables.some(obj => obj.syntax === variable[0]);
            if (!foundVariable){
                foundAllVariables = false;
            } 
        }
        const accounts = selectedAccounts.map(acc => acc.substring(0,acc.indexOf('_')));
        const config = {
            subject: template?.subject,
            html: `<html><head></head><body>${template?.html}</body></html>`,
            accounts: accounts,
        };
        console.log("Config: ", config);
        // sends email to list of accounts
        if (foundAllVariables){
            sendEmail(config);
        } else {
            setShowAccounts(false);
            setError('Unknown variable found in email body: Click the "Show Email Variables" button to view all available variables');
        }
    };
    
    let accountsModal = <SpinnerDark/>
    if (showAccounts && !loading && accounts){
        // Select accounts
        accountsModal = (
            <div>
                <button className='btn btn-warning' onClick={handleSend}>Send Email</button>
                <BSTable
                    selected={selectedAccounts} 
                    setSelected={setSelectedAccounts} 
                    table={'view_primary_contacts_info'} 
                    data={accounts}
                />
            </div>
        )
    }
            
    let variableLegendModal = <SpinnerDark/>;
    if (showVariableLegend && !loading){
        variableLegendModal = (
            <div>
                <table>
                    <tr>
                        <th>Variable</th>
                        <th>Syntax</th>
                    </tr>
                    {emailVariables.map(variable => {
                        // return <p><strong>{variable.label}:</strong> {variable.syntax}</p>
                        return (
                            <tr>
                                <td>{variable.label}</td>
                                <td>{variable.syntax}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        )
    }
    
    return (
      <div className={classes.CustomEmail}>
        {loadingSaveTemplate && <BackdropSpinner spinner='small-light'/>}
        {loadingSendEmail && <BackdropSpinner spinner='small-light'/>}
        <Modal show={error} modalClosed={() => setError(false)}>
            <h3>Oops, something went wrong!</h3>
            <hr/>
            <p>{error}</p>
        </Modal>
        <Modal large tall show={showAccounts} modalClosed={() => setShowAccounts(false)}>
            <h3>Select accounts:</h3>
            <hr/>
            {accountsModal}
        </Modal>
        <Modal show={showVariableLegend} modalClosed={() => setShowVariableLegend(false)}>
            <h3>Email Variable Key</h3>
            <hr/>
            {variableLegendModal}
        </Modal>
        <Banner show={bannerText}>{bannerText}</Banner>
        <h3>Create Custom Email</h3>
        <hr/>
        <div className={classes.CustomEmailButtons}>
            <button className='btn btn-success' onClick={handleShowText}>Click to Open Email Text Editor</button>
            <div className={classes.CustomEmailButtonsChild}>
                <button className='btn btn-warning' onClick={() => setShowVariableLegend(true)}>Show Email Variables</button>
            </div>
        </div>
        {content}

      </div>
    );    
}

export default CustomEmail;