import React, {useState} from 'react';
import classes from './Banner.module.css';

const Banner = (props) => {
    
    const [classList, setClassList] = useState(["alert alert-success", classes.Banner]);
    
    // let classList = ["alert alert-success", classes.Banner, classes.Sticky];
    let isShow, isHidden, isShowIndex, isHiddenIndex;
    for (const item of classList) {
        if (item.includes('Visible')) {
            isShow = true;
            isShowIndex = classList.indexOf(item);
        }
        if (item.includes('Hidden')) {
            isHidden = true;
            isHiddenIndex = classList.indexOf(item);
        }
    }
    
    // console.log('isHidden: ', isHidden);
    // console.log('isShow: ', isShow);
    // console.log('isShowIndex: ', isShowIndex);
    // console.log('isHiddenIndex: ', isHiddenIndex);
    
    // if set to show, add show
    if (props.show && !isShow) {

        // add show if not there
        if (!isShow) {
            const newClasses = [...classList]
            newClasses.push(classes.Visible);
            setClassList(newClasses);
        }
        
        // set to show, so remove hidden if there
        if (isHidden) {
            const newClasses = [...classList]
            newClasses.splice(isHiddenIndex, 1);
            setClassList(newClasses);            
        }
        
    } else if (!props.show) {

        // not set to show, so remove show if there
        if (isShow) {
            const newClasses = [...classList]
            newClasses.splice(isShowIndex, 1);
            setClassList(newClasses);            
        }

        // add hidden if not there
        if (!isHidden) {
            const newClasses = [...classList]
            newClasses.push(classes.Hidden);
            setClassList(newClasses);
        }
    }

    // When the user scrolls the page, execute checkScrollPosition
    window.onscroll = function() {checkScrollPosition()};
    
    // Get the banner
    const banner = document.getElementById("banner");
    
    // Get the offset position of the navbar
    let sticky;
    if (banner) {
        sticky = banner.offsetTop;
    }
    
    // Add the sticky class to the banner when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function checkScrollPosition() {

        let isSticky, index;
        for (const item of classList) {
            if (item.includes('Sticky')) {
                isSticky = true;
                index = classList.indexOf(item);
            }
        }

        if (window.pageYOffset > sticky && !isSticky) {
            const newClasses = [...classList]
            newClasses.push(classes.Sticky);
            setClassList(newClasses);
        } else if (window.pageYOffset <= sticky && isSticky) {
            // if sticky in classes, remove
            const newClasses = [...classList]
            newClasses.splice(index, 1);
            setClassList(newClasses);       
        }
    }    
    

    return (
        <div id="banner" className={classList.join(' ')} >
            {props.children} &nbsp;
        </div> 
    );
};

export default Banner;