// import SmallSpinnerDark from "../../../UI/SmallSpinnerDark/SmallSpinnerDark";
import greenTick from '../../../assets/images/greenTick.png'
import blueTick from '../../../assets/images/blueTick.png'
// import orangeTick from '../../../assets/images/orangeTick.png'
import amberACCS from '../../../assets/images/amberACCS.png'
import redCross from '../../../assets/images/redCross.png'
import { gocardlessDashboardURL } from "../../../utilities/globalVariables";
import {AiOutlineQuestionCircle} from "react-icons/ai";

const DirectDebitStatus = (props) => {

    // data from props
    const email = props.email;
    const directDebitData = props.directDebitData;
    const alternativeBillingArrangementData = props.alternativeBillingArrangementData;

    const customers = directDebitData?.customers;
    let numCustomers;
    if (customers) {
        numCustomers = Object.keys(customers)?.length;
        // console.log('numCustomers:', numCustomers);
    }

    // check for alternative billing arrangements
    let filteredAltBillingArrangements;
    if (alternativeBillingArrangementData?.length > 0) {
        filteredAltBillingArrangements = alternativeBillingArrangementData?.filter(obj => obj?.email?.toLowerCase() === email?.toLowerCase());
    } 
    // console.log('filteredAltBillingArrangements: ', filteredAltBillingArrangements)
    let noDirectDebitTrues, doNotInvoiceTrues;
    if (filteredAltBillingArrangements) {
        doNotInvoiceTrues = filteredAltBillingArrangements?.filter(obj => obj.do_not_invoice_acct)?.length;
        noDirectDebitTrues = filteredAltBillingArrangements?.filter(obj => obj.no_direct_debit_required)?.length;
        // console.log('noDirectDebits: ', noDirectDebitTrues)
        // console.log('doNotInvoiceTrues: ', doNotInvoiceTrues)
    }


    // let directDebitLink = <SmallSpinnerDark />;
    let directDebitLink = <AiOutlineQuestionCircle size={'1rem'} color={'darkgrey'} />;
    if (email && numCustomers >= 0) {
        const customer = customers?.[email];
        const numberOfMandates = customer?.numberOfMandates;
        
        let directDebitIcon;
        if (doNotInvoiceTrues > 0) {
            directDebitIcon = blueTick;
        } else if (customer && numberOfMandates > 0) {
            directDebitIcon = greenTick;
        } else if (noDirectDebitTrues > 0) {
            directDebitIcon = amberACCS;
        } else {
            directDebitIcon = redCross;
        }
        directDebitLink = <a key={'direct_debit_' + email} target="_blank" rel="noreferrer" href={gocardlessDashboardURL}><img href={gocardlessDashboardURL} alt="direct-debit-icon" src={directDebitIcon} style={{width: '15px'}}/></a>
    }        

    return directDebitLink;
}

export default DirectDebitStatus;