import { useEffect, useState } from 'react';
import BSTable from '../../../../../UI/BSTable/BSTable';
import classes from './RollActivityLog.module.css';

const RollActivityLog = (props) => {

    // state
    const [selected, setSelected] = useState([])
    const [timeframeToggle, setTimeframeToggle] = useState('past');
    const [filteredData, setFilteredData] = useState([]);
    
    // data from props
    const data = props.childRollActivityLogData;
    
    
    // console.logs
    // console.log('[RollActivityLog] - timeframeToggle: ', timeframeToggle);
    // console.log('[RollActivityLog] - data: ', data);
    // console.log('[RollActivityLog] - filteredData: ', filteredData);
    
    // filter data based on timeframe toggle
    useEffect(() => {
        
        const currentDate = new Date();
        const timezoneOffsetInMinutes = currentDate.getTimezoneOffset();
        const dateTodayMidnight = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            0, 0 - timezoneOffsetInMinutes, 0, 0
        );
        console.log('dateTodayMidnight: ', dateTodayMidnight);
        const dateTodayGetTime = dateTodayMidnight.getTime();
        console.log('dateTodayGetTime: ', dateTodayGetTime);

        if (timeframeToggle === 'past') {
            
            // get data from today backwards, sorted desc
            const newData = data?.filter(obj => new Date(obj?.date_iso).getTime() <= dateTodayGetTime).sort((a,b) => new Date(b?.date_iso).getTime() - new Date(a?.date_iso).getTime());
            setFilteredData(newData);
        } else if (timeframeToggle === 'future') {
            const newData = data?.filter(obj => new Date(obj?.date_iso).getTime() >= dateTodayGetTime).sort((a,b) => new Date(a?.date_iso).getTime() - new Date(b?.date_iso).getTime());
            setFilteredData(newData);
        } else {
            setFilteredData([]);
        }
        
    }, [timeframeToggle, data])
    
    
    // get name from data
    const childName = filteredData?.[0]?.first_name + ' ' + filteredData?.[0]?.surname;
    const rollSessionsExist = filteredData?.[0]?.id;

    let content = <p>No roll activity found</p>
    if (rollSessionsExist) {
        content = (
            <BSTable 
                table={'view_roll_activity_log'} 
                data={filteredData} 
                stickyHeader='RollActivityLog'
                selected={selected}
                setSelected={setSelected}
                horizontalScroll

            />
        );
    }
    return (
        <div className={classes.RollActivityLog}>
            <h6>Roll Activity Log - {childName}</h6>
            <hr/>
            <div className={classes.HeaderButtonRowRight}>
                <select className='form-control' value={timeframeToggle} onChange={(e) => {setTimeframeToggle(e.target.value);}}>
                    <option value='past'>Past Bookings</option>
                    <option value='future'>Future Bookings</option>
                </select>   
            </div>
            <div>
                {content}
            </div>              
        </div>
    )
}

export default RollActivityLog;