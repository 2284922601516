import {React, Fragment, useState, useCallback, useEffect} from 'react';
import classes from './CentreMgmt.module.css';
// import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../UI/Modal/Modal';
import { useHistory, useLocation } from "react-router-dom";
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../utilities/globalVariables';
import { handleAwsApiError } from '../../../utilities/functions';
import axios from 'axios';
import NewEnrolments from './NewEnrolments/NewEnrolments';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
// import AdminRoll from '../AdminMgmt/AdminRoll/AdminRoll';
// import CentreApprovals from './CentreApprovals/CentreApprovals';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';


const CentreMgmt = (props) => {

    // UI state
    // const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState();

    // data state
    const [centres, setCentres] = useState();
    const [selectedCentreId, setSelectedCentreId] = useState();

    const [showContent, setShowContent] = useState('new-enrolments');

    
    // react router history instance to navigate user to other pages
    let history = useHistory();   
    const location = useLocation();
    
    // console logs
    console.log('[CentreMgmt.js] - centres: ', centres);
    console.log('[CentreMgmt.js] - showContent: ', showContent);

    // get data from props
    const setSublinks = props.setSublinks;

    // set leftpane sublinks
    useEffect(() => {
        setSublinks([
            {title: 'New Enrolments', icon: 'schedule',  onClick: () => setShowContent('new-enrolments')},
            // {title: 'Admin Roll', icon: 'calendar',  onClick: () => setShowContent('admin-roll')},
        ]);
    }, [setSublinks, setShowContent])    

    // get centres data to pass to children components
    const getCentresData = useCallback(async () => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'centres'}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setCentres(res.data.filter(obj => obj.location_type === 'centre') ?? []); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);

    }, [history, props, location]);    

    // // get initial centres data
    useEffect(() => {
        
        // currently don't need centres data here but leave for future use
        // if (false) {

            getCentresData();
        // }
    }, [getCentresData])

    // if centres data length is 1, set that as selected centre
    useEffect(() => {
        if (centres?.length === 1) {
            setSelectedCentreId(centres?.[0]?.centre_id);
        }
    }, [centres])


    // render service choices
    let services;
    if (centres?.length > 0) {
        services = (
            <select className='form-control' value={selectedCentreId ?? ''} onChange={(e) => setSelectedCentreId(e.target.value)}>
                <option disabled value=''>Select Centre</option>
                {centres.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
            </select>        
        );

    }
    

    let content;
    // components with data dependencies 
    if (selectedCentreId) {
        if (showContent === 'new-enrolments') {
            content = <NewEnrolments centreId={selectedCentreId} token={props.token}/>
        } 
        // else if (showContent === 'admin-roll') {
        //     content = <AdminRoll token={props.token} centreAdminSelectedCentre={selectedCentreId} centres={centres} role='centreAdmin'/>
        // }
    }


    return (
        <Fragment >
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            {loading && <BackdropSpinner spinner='small-light' />}
            <div className={classes.CentreMgmt}>
                <h4>Select a Centre</h4>
                {services}
                <br/>
                <hr />
                <br/>
                {content}
            </div>
        </Fragment>
    );
}

export default CentreMgmt;