import { Fragment } from "react";
import BSTable from "../../../../UI/BSTable/BSTable";
import { handleBSTableColourCoding } from "../../../../utilities/functions";
import { bootstrapTableConfig } from "../../../../utilities/globalObjects";
import classes from './SubmissionsTable.module.css';

const SubmissionsTable = (props) => {

    const data = props.data;
    const table = props.table;
    const selected = props.selected;
    const setSelected = props.setSelected;
    const getSubmissionObject = props.getSubmissionObject;

    console.log('[SubmissionsTable] - data:', data)

    const handleGetSubmissionObject = (selected) => {
        
        // abort if more than one form id passed in
        if (selected.length !== 1) {
            alert('Only one item can be loaded at a time');
            return false;
        }       

        
        console.log('selected: ', selected);
        
        // get form submissions
        const id = selected[0]
        const row = data.filter(obj => obj.id === id)[0];        
        getSubmissionObject(row);
    }

    // action buttons based on mode activated
    let buttons;
    if (table === 'view_filing_cabinet') {
        buttons = (
            <div className={classes.HeaderButtonRow}>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => handleGetSubmissionObject(selected)}>Download</button>
            </div>  
        );
    }


    const dynamicClassFunction = (cell, row, rowIndex, colIndex) => {
        // console.log('rowIndex: ', rowIndex);
        // console.log('colIndex: ', colIndex);
        
        // get table and column of cell in question
        const keys = Object.keys(row).filter(key => !bootstrapTableConfig[table]?.excludeColumns?.includes(key));
        const col = keys[colIndex];
        
        const className = handleBSTableColourCoding(table, row, col, cell);
        return className;
    }


    return (
        <Fragment>
            {data?.length > 0 
                ? <div className={classes.TableContainer} >
                    {buttons}
                    <BSTable 
                        selected={selected} 
                        setSelected={setSelected} 
                        dynamicClassFunction={dynamicClassFunction} 
                        table={table} 
                        data={data} 
                        paginationConfig={{sizePerPage: 25}}
                    /> 

                </div>
            : null}              
        </Fragment>        
    );
}

export default SubmissionsTable;