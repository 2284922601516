import React, { useState } from 'react'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { bscStartTime, ascStartTime, bscSessionLength, vacStartTime, ascSessionLength, vacSessionLength } from '../../../../utilities/globalVariables';
import classes from './Calendar.module.css';
import Modal from '../../../../UI/Modal/Modal';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import { raw } from '../../../../translations/en/raw';
import { humanize } from '../../../../utilities/functions';
import {TiTick} from "react-icons/ti";
import {ImCross} from "react-icons/im";
import {BsClockFill} from "react-icons/bs";

const Calendar = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();

    // get data from props
    const children = props.children;
    const centres = props.centres;
    const casualData = props.casualData;
    const rollData = props.rollData;
    const excursionData = props.excursionData;
    const isMobile = props.isMobile;



    // console logs
    // console.log('excursionData: ', excursionData);
    // console.log('rollData: ', rollData);
    const events = [];

    // make sure component has received props before trying to create event object
    if (children?.length > 0) {

        // iterate through each row in RDS table roll and turn row into an event object to display on calendar
        rollData
        .filter(obj => obj.location_type === 'centre' && obj.status !== 'cancelled') // filter out cancelled bookings, now not showing on roll
        .forEach(obj => {
            // const id = obj.id;
            // console.log('rollData obj for which event being created: ', obj);
            // console.log('children data: ', children);
            let title = children.filter(child => +child.child_id === +obj.child_id)[0].first_name;
            // const centre = obj?.
            const isoDate = obj?.date?.slice(0,10);

            // check if child on bus list for this same session
            let busListStatus;
            rollData.forEach(rollEntry => {
                if (rollEntry.session === obj.session && +rollEntry.child_id === +obj.child_id && rollEntry.date === obj.date && rollEntry.location_type === 'bus') {
                    busListStatus = rollEntry.status;
                }
            });            
            
            
            let startHours, endHours, color, classNames;
            if (obj.session === 'bsc') {
                color = 'pink'
                startHours = bscStartTime;
                endHours = startHours + bscSessionLength;
                title = title + ' - Before School Care';
            } else if (obj.session === 'asc') {
                color = 'blue';
                startHours = ascStartTime;
                endHours = startHours + ascSessionLength;
                title = title + ' - After School Care';
                classNames = [classes.EventASC];
                
            } else {
                color = 'green';
                startHours = vacStartTime;
                endHours = startHours + vacSessionLength;
                title = title + ' - Vacation Care';
            }
            
            // if booking cancelled, show in title
            if (obj.status !== 'confirmed') {
                title = title + ' (' + obj.status + ')';
                color = 'black';
            }

            // add centre name to booking label
            const centreName = centres.filter(centreObj => centreObj.centre_id === obj.centre_id)?.[0]?.centre_name;
            title = title + ' [' + centreName + ']';

            const start = new Date(obj.date).setTime(new Date(obj.date).getTime() + (startHours*60*60*1000));
            const end = new Date(obj.date).setTime(new Date(obj.date).getTime() + (endHours*60*60*1000));
            const newEvent = {
                title: title, start: start, end: end, color: color, classNames: classNames,
                extendedProps: {child_id: obj.child_id, date: isoDate, session: obj.session, centre_id: obj.centre_id, eventType: raw[obj.created_from] ?? obj.created_from, Status: obj.status}
                // title: title, start: start, end: end, color: color, child_id: obj.child_id, date: isoDate, session: obj.session, centre_id: obj.centre_id, eventType: raw[obj.created_from] ?? obj.created_from, extendedProps: {date: isoDate, Status: obj.status}
            };
            if (busListStatus) {
                newEvent['Bus List Status'] = busListStatus;
            }
            events.push(newEvent);
        });
    
        // go through casual bookings and find any which haven't been added to roll yet to display on calendar as pending
        casualData.filter(obj => obj.status !== 'cancelled') // filter out cancelled bookings, now not showing on roll (but keep late-cancellations, which will show on roll as absent once roll engine has run)
        .forEach(obj => {
            
            // get ISO format of date
            const casualBookingDate = obj.date.slice(0,10);

            // get booking type (current options are pending & waitlist)
            const bookingType = obj?.booking_type;
            const status = obj.status;
            // console.log('bookingType: ', bookingType);

            // status translations
            let displayStatus;
            if (status === 'late-cancellation') {
                displayStatus = 'absent';
            } else if (bookingType === 'waitlist') {
                displayStatus = 'waitlist'
            } else {
                displayStatus = 'confirmed'; 
            }

            // skip iteration of forEach if booking cancelled
            // const status = obj.status;
            // if (status && status.includes('cancel')) {
            //     return;
            // }
    
            // check if current casual booking row is anywhere in events
            let onRoll = false;
            events.forEach(event => {
                if (event?.extendedProps?.session === obj.session && +event?.extendedProps?.child_id === +obj.child_id && +event?.extendedProps?.centre_id === +obj.centre_id && event?.extendedProps?.date === casualBookingDate) {
                    onRoll = true;
                }
            });
    
            // if not on roll yet, add to events 
            if (!onRoll) {
                let title = children.filter(child => +child.child_id === +obj.child_id)[0].first_name;
                


                let startHours, endHours, color;
                const extraTitleFlag = (bookingType === 'waitlist') ? ' - WAITLIST' : '';
                if (obj.session === 'bsc') {
                    color = 'pink'
                    startHours = bscStartTime;
                    endHours = startHours + bscSessionLength;
                    title = title + extraTitleFlag + ' - Before School Care';
                } else if (obj.session === 'asc') {
                    color = 'blue';
                    startHours = ascStartTime;
                    endHours = startHours + ascSessionLength;
                    title = title + extraTitleFlag + ' - After School Care';
                } else {
                    color = 'green';
                    startHours = vacStartTime;
                    endHours = startHours + vacSessionLength;
                    title = title + extraTitleFlag + ' - Vacation Care';
                }

                // if booking a late-cancellation, show as absent (as this will be added to the roll as absent once engine runs)
                if (status === 'late-cancellation') {
                    title = title + ' (' + displayStatus + ')';
                    color = 'black';
                }         

                // add centre name to booking label
                const centreName = centres.filter(centreObj => centreObj.centre_id === obj.centre_id)?.[0]?.centre_name;
                title = title + ' [' + centreName + ']';                
                  
                
                // if status exists (i.e. cancelled, late-cancellation, late booking), show in title
                // if (status === 'late-booking') {
                //     title = title + ' (' + status + ' - pending)';
                // } else if (status) {
                //     title = title + ' (' + status + ')';
                //     color = 'black';
                // } else {
                //     title = title  + ' (pending)';
                // }

                const start = new Date(obj.date).setTime(new Date(obj.date).getTime() + (startHours*60*60*1000));;
                const end = new Date(obj.date).setTime(new Date(obj.date).getTime() + (endHours*60*60*1000));;                
                events.push({
                    // id: id, 
                    title: title, start: start, end: end, color: color, extendedProps: {child_id: obj.child_id, date: casualBookingDate, session: obj.session, centre_id: obj.centre_id, eventType: 'Casual Booking', Status: displayStatus}
                })
            }        
            // console.log('casualData object: ', obj);
        });
    }

    // console.log('events: ', events);

    // get unique excursion Ids
    const excursionIds = excursionData.map(obj => obj.excursion_id).filter((v, i, a) => a.indexOf(v) === i);
    // console.log('excursionIds: ', excursionIds);
    
    // populate excursions on calendar
    excursionIds.forEach(id => {
        const detail = excursionData.filter(obj => obj.excursion_id === id);
        // console.log('detail: ', detail);
        const start = detail[0].start_date;
        const end = detail[0].end_date ?? start;
        const color = 'rgb(136, 218, 181)';
        const excursionCentreId = detail?.[0]?.centre_id;
        const excursionCentreName = centres.filter(obj => obj.centre_id === excursionCentreId)?.[0]?.centre_name;
        const excursionTitle = detail[0]?.title + ` [${excursionCentreName}]`;
        const excursionSession = detail[0]?.session;
        const extendedProps = {type: 'excursion', centreId: excursionCentreId, session: excursionSession};
        // detail.forEach(obj => {extendedProps[obj.key] = obj.value});

        events.push({title: excursionTitle, start: start, end: end, allDay: true, color: color, extendedProps: extendedProps});

    })

    // console.log('events: ', events);

    // const events = [
    //     {
    //       id: 1,
    //       title: 'event 1',
    //       start: '2022-01-09T10:00:00',
    //       end: '2022-01-09T12:00:00',
    //     },
    //     {
    //       id: 2,
    //       title: 'event 2',
    //       start: '2022-01-16T13:00:00',
    //       end: '2022-01-16T18:00:00',
    //     },
    //     { id: 3, title: 'event 3', start: '2022-01-11', end: '2022-01-20' },
    //   ];
      
    const handleEventClick = (event) => {

        const title = event.title;
        const detail = event.extendedProps;
        const type = detail?.type;

        // handle case where event clicked is an excursion, in which case click should open new casual booking form for day and centre
        if (type === 'excursion') {

            const dateStr = event?.startStr;
            const centreId = +detail?.centreId;
            const session = detail?.session;
            console.log('event is of type excursion');
            props.newCasual(dateStr, centreId, session)
            return false;
        }

        // console.log('[handleEventClick] - event: ', event);
        // console.log('[handleEventClick] - title: ', title);
        // console.log('[handleEventClick] - extendedProps: ', detail);

        setShowModal(true);

        const content = (
            <Aux>
                <h5 className={classes.ExcursionTitle}>{title}</h5>
                {
                    Object.keys(detail).map(key => {

                        // replace IDs with names
                        let value = raw[detail[key]] ?? detail[key];
                        let label = raw[key] ?? humanize(key);
                        if (key === 'centre_id') {
                            value = centres.filter(obj => obj.centre_id === value)?.[0]?.centre_name;
                            label = 'Centre'
                        } else if (key === 'child_id') {
                            value = children.filter(obj => obj.child_id === value)?.[0]?.first_name + ' ' + children.filter(obj => obj.child_id === value)?.[0]?.surname;
                            label = 'Child'
                        }

                        return (
                            <div key={key} className={classes.ExcursionRow}>
                                <span className={classes.ExcursionKey}>{label}</span>
                                <span className={classes.ExcursionValue}>{value}</span>
                            </div>
                        );
                    })
                }
                <br />
                    {detail?.eventType?.toLowerCase().includes('booking') && !['absent', 'cancelled', 'late-cancellation'].includes(detail?.Status?.toLowerCase()) && <button className='btn btn-danger btn-block' onClick={() => props.cancelBooking(detail)}>Cancel Booking</button>}
                    {['absent', 'cancelled', 'late-cancellation'].includes(detail?.Status?.toLowerCase()) && <button className='btn btn-success btn-block' onClick={() => props.remakeCancelledBooking(detail)}>Re-make Booking</button>}

            </Aux>
        );
        
        setModalContent(content)
    }

    // set mobile/desktop config
    let initialView, headerToolbar, customButtons, height;
    // , key;
    if (isMobile) {
        initialView = "listWeek";
        height="60vh";
        headerToolbar = {
            // left: 'listWeek,dayGridWeek,newBooking',
            // left: 'listWeek',
            // left: 'changeSchedule,newBooking',
            center: '',
            right: 'prev,next'
            // right: 'changeSchedule,newBooking,prev,next'
        };
        customButtons = {};
        // customButtons = {
        //     newBooking: {
        //         text: '+',
        //         click: () => props.newCasual()
        //     },
            // changeSchedule: {
            //     text: 'permanent bookings',
            //     click: () => props.updateSchedule()
            // },            
        // };        
        
    } else {
        initialView = "dayGridMonth";
        height = 'auto';
        headerToolbar = {
            right: 'newBooking today prev next',
            // center: 'showSchedule changeSchedule',
            center: ''
        };
        customButtons = {
            newBooking: {
                text: 'New Casual Booking',
                click: () => props.newCasual()
            },
            // changeSchedule: {
            //     text: 'change permanent bookings',
            //     click: () => props.updateSchedule()
            // },
            // showSchedule: {
            //     text: 'show permanent bookings',
            //     click: () => props.showSchedule()
            // }
        };

        // key = (
        //     <div className={classes.Key}>
        //         <span>Key</span> 
        //         <ul style={{marginBottom: '3px'}}>
        //             {/* <li className={classes.BscKey}><span style={{color: '#464646'}}>{raw['bsc']}</span></li>
        //             <li className={classes.AscKey}><span style={{color: '#464646'}}>{raw['asc']}</span></li>
        //             <li className={classes.VacKey}><span style={{color: '#464646'}}>{raw['vac']}</span></li>
        //             <li className={classes.ExcursionKey}><span style={{color: '#464646'}}>test</span></li> */}
        //             <li className={classes.BscKey}>{raw['bsc']}</li>
        //             <li className={classes.AscKey}>{raw['asc']}</li>
        //             <li className={classes.VacKey}>{raw['vac']}</li>
        //             <li className={classes.ExcKey}>Excursion</li>
        //         </ul>
        //     </div>
        // );
    }

    function CustomEventContent({ event }) {

        const extendedProps = event?.extendedProps;
        console.log('extendedProps: ', extendedProps);
        const session = extendedProps?.session;
        const type = extendedProps?.type;
        const status = extendedProps?.Status;

        // set icon style
        const largeIconSize = '.8rem';
        const smallIconSize = '1.4rem';
        
        let eventClasses = [classes.DefaultEvent], symbol, symbolClasses = [];
        if (status === 'waitlist') {
            symbol = <BsClockFill size={largeIconSize} /> 
            eventClasses.push(classes.EventWaitlist);
        } else if (type === 'excursion') {
            symbol = '';
            eventClasses.push(classes.EventExcursion);
        } else if (session === 'bsc') {
            symbol = (status === 'absent') ? <ImCross size={largeIconSize} /> : <TiTick size={smallIconSize} />;
            eventClasses.push(classes.EventBSC);
            
        } else if (session === 'asc') {
            symbol = (status === 'absent') ? <ImCross size={largeIconSize}  /> : <TiTick size={smallIconSize}/>;
            eventClasses.push(classes.EventASC);
        } else if (session === 'vac') {
            symbol = (status === 'absent') ? <ImCross size={largeIconSize}  /> : <TiTick size={smallIconSize} />;
            eventClasses.push(classes.EventVAC);
        } 

        if (status === 'absent' || status === 'waitlist') {
            symbolClasses.push(classes.PaddedSymbol)
        }

        const content = (
            <div className={eventClasses.join(' ')}>
                <span className={symbolClasses.join(' ')} >{symbol}</span>
                {(status === 'waitlist' || status === 'absent') && <span>&nbsp;&nbsp;</span>}
                <span >{event.title}</span>
            </div>
        );
        return content
    }    

      
    return (
        <div className={classes.Calendar}>
            <Modal show={showModal} modalClosed={() => setShowModal(false)}>
                {modalContent}
            </Modal>
            <div className={classes.CalendarInfoBar}>
                <div>
                    <p className={classes.InfoBarText}>Please allow up to 12 hours for all new schedules to appear on the calendar</p>
                    <p className={classes.InfoBarText}>To CANCEL a session of care, choose the session you wish to cancel on the calendar then select ‘Cancel Booking’</p>
                </div>
                {/* {key} */}
            </div>
            {/* <div style={{maxWidth: '500px', margin: 'auto'}}> */}
            {isMobile && <button onClick={props.newCasual} className="btn btn-block btn-success">New Casual Booking</button>}
            {/* </div> */}
            <br/>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                initialView={initialView}
                headerToolbar={headerToolbar}
                customButtons={customButtons}
                events={events}
                eventContent={CustomEventContent}
                timeZone="Aus/Sydney"
                nowIndicator
                dateClick={(e) => props.newCasual(e.dateStr)}
                eventClick={(e) => handleEventClick(e.event)}
                height={height}
                displayEventTime={false}
                // eventClick={(info) => {
                //     alert('Event: ' + info.event.title);
                //     alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                //     alert('View: ' + info.view.type);
                
                //     // change the border color just for fun
                //     info.el.style.borderColor = 'red';
                // }}
          />
        </div>        
    );
}

export default Calendar