// import { Fragment } from 'react';
import Select from 'react-select';
import classes from './ChildSelector.module.css';

const ChildSelector = (props) => {

    // data from props
    const childrenData = props.childrenData;
    const selectedChildren = props.selectedChildren;
    const setSelectedChildren = props.setSelectedChildren;

    
    let childSelector;
    if (childrenData?.length > 0) {
        
        const childOptions = [{value: '*', label: 'All Children'}];
        childrenData.forEach(obj => {childOptions.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})});

        childSelector = (
            <div className={classes.ChildSelector}>
                <h6>Select Child</h6>
                <hr/>
                <Select isMulti options={childOptions} onChange={setSelectedChildren} value={selectedChildren} ></Select>                       
                <hr/>
            </div>
        );       
    } else {
        childSelector = <p><i>No children found</i></p>
    }
    
    return childSelector;
}

export default ChildSelector;