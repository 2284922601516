// import classes from './TertiaryNavbar.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const TertiaryNavbar = (props) => {

    
    // get config from props
    const config = props.config;
    const updateStateFn = props.updateStateFn;
    const tab = props.tab;

    // example config [{label: 'Label', value: 'value'}]
    
    // generate link for each config item
    let tabs;
    if (config?.length > 0) {
        tabs = config.map(obj => {
            
            
            // let colourClass = 'btn-light';
            // if (obj.colourClass) {
            //     colourClass = 'btn-' + obj.colourClass;
            // }
            


            // return <button className={"btn btn-sm " + colourClass} onClick={() => updateStateFn(obj.value)}>{obj.label}</button>;
            return <Tab value={obj.value} label={<span>{obj.label}</span>} />
        })
    }
    

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                // TabIndicatorProps={{style: {background:'#F5881F', color: 'black'}}}
                value={tab}
                onChange={(event, newValue) => updateStateFn(newValue)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
            >
                {tabs}
            </Tabs>
            <br/>
        </Box>           
        // <div className={classes.TertiaryNavbar}>
            
        //     {buttons}
        // </div>
    );
}

export default TertiaryNavbar;

