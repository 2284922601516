import {React, Fragment, useState, useCallback, useEffect} from 'react';
import classes from './NewEnrolments.module.css';
// import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../../UI/Modal/Modal';
import { useHistory, useLocation } from "react-router-dom";
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import SmallSpinnerDark from '../../../../UI/SmallSpinnerDark/SmallSpinnerDark';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../../utilities/globalVariables';
import { handleAwsApiError, getAgeFromDateStr } from '../../../../utilities/functions';
import axios from 'axios';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import InitialIcon from '../../../Kiosk/InitialIcon/InitialIcon';
// import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
// import CentreApprovals from './CentreApprovals/CentreApprovals';

// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';

// icons
import {AiOutlineWarning} from "react-icons/ai";
import {MdNoFood} from "react-icons/md";
import {FaNotesMedical} from "react-icons/fa";
import {FiCameraOff} from "react-icons/fi";
import u7icon from '../../../../assets/images/u7-icon-v2.png'
import { raw } from '../../../../translations/en/raw';
import NewBanner from '../../../Kiosk/NewBanner/NewBanner';
// import {BsPencilSquare} from "react-icons/bs";


const NewEnrolments = (props) => {

    // UI state
    // const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState();

    // data state
    const [newEnrolmentsData, setNewEnrolmentsData] = useState();
    const [childFlagsData, setChildFlagsData] = useState();
    const [childDetail, setChildDetail] = useState();
    const [showChildDetail, setShowChildDetail] = useState(false);    
    // const [updateRollIds, setUpdateRollIds] = useState([]);
    
    const [photos, setPhotos] = useState({});
    const [photosLoading, setPhotosLoading] = useState(false);

    
    // react router history instance to navigate user to other pages
    let history = useHistory();   
    const location = useLocation();
    
    // console logs
    console.log('[NewEnrolments.js] - newEnrolmentsData: ', newEnrolmentsData);
    console.log('[NewEnrolments.js] - childFlagsData: ', childFlagsData);

    // get data from props
    const centreId = props.centreId;


    // generic function to get data and save to state
    const getMasterData = useCallback(async (tableName, stateFn, firstCall, lastCall, conditionCol, conditionValue) => {
        
        console.log(`fetching data for table ${tableName}`);


        firstCall ? setLoading(true) : void(0);

        if (tableName) {
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {table: tableName, conditionCol: conditionCol, conditionValue: conditionValue}
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log('data fetch res: ', res.data);
                stateFn(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }            

        }

        lastCall ? setLoading(false) : void(0);
    }, [history, props, location]);  

    // get initial centres data
    useEffect(() => {
        getMasterData('view_new_child_enrolments', setNewEnrolmentsData, true, true, 'centre_id', centreId);
        getMasterData('view_active_child_flags', setChildFlagsData, false, false, 'centre_id', centreId); 
    }, [getMasterData, centreId])

    const handleChildDetail = (rollId, details, childFlags) => {
        
        setShowChildDetail(true);
        const rollEntry = newEnrolmentsData.filter(obj => obj.id === rollId)[0];
        const centreId = rollEntry.centre_id;
        const childId = rollEntry.child_id;
        

        // get child flags specific to this centre (filter out duplicates which are there so any centre can query this view by centre_id)
        // if child flags present, add this detail to summary
        const detailArray = [];
        if (childFlags) {
            const centreChildFlags = childFlagsData?.filter(obj => obj.centre_id === centreId && obj.child_id === childId);
            centreChildFlags?.forEach(obj => {
                // console.log('obj: ', obj);
                detailArray.push(
                    <div key={obj.id} className={classes.DetailRow} >
                        <span className={[classes.DetailKey, classes.ChildFlag].join(' ')}>{raw[obj.flag_type] ?? obj.flag_type}:</span>
                        <span>{obj.comment}</span>
                    </div>
        
                );                
            })
        }


            
        details.forEach(el => {
            detailArray.push(
                <div key={el} className={classes.DetailRow} >
                    <span className={classes.DetailKey}>{raw[el] ?? el}: &nbsp;</span>
                    <span>{['dob'].includes(el) ? new Date(rollEntry[el])?.toLocaleDateString() : rollEntry[el]?.toString()}</span>
                </div>

            );

        });


        setChildDetail(detailArray);
    }    

    // const handleUpdateRollIds = (rollId) => {
    //     // if ID already in array, remove it, otherwise add it
    //     if (updateRollIds.includes(rollId)) {
    //         const index = updateRollIds.indexOf(rollId);
    //         const newRollIds = [...updateRollIds];
    //         newRollIds.splice(index, 1);
    //         setUpdateRollIds(newRollIds);
    //     } else {
    //         setUpdateRollIds([...updateRollIds, rollId]);
    //     }
    // }    


    // const handleSelectAll = (checked) => {
    //     if (checked) {
    //         setUpdateRollIds(newEnrolmentsData.map(obj => obj.id).filter((x, i, a) => a.indexOf(x) === i));
    //     } else {
    //         setUpdateRollIds([]);
    //     }
   
    // } 
    
    // useEffect to fetch child photos for roll items displayed
    useEffect(() => {

        
        const getPhotos = async (childIds) => {
       


            // upcoming sessions data
            const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
            const postData = {childId: childIds, centreId: centreId};
            try {
                const res = await axios.post(AWS_API + 'form-submissions/fetch-photos', {postData: postData}, headers);
                // setUpcomingSessions(res.data);
                const newPhotos = res.data;
                console.log('res: ', newPhotos);
                
                // if there are any new children to add, update photos in state
                const existingIds = Object.keys(photos);
                const newIds = Object.keys(newPhotos);
                const difference = newIds.filter(id => !existingIds.includes(id));
                console.log('new photos fetched not already in photos for: ', difference);
                if (difference.length > 0) {
                    setPhotos({...photos, ...newPhotos});
                }
                // setLoading(false);
            } catch (err) {
                // setLoading(false);
                // setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
                console.log('error whilst fetching photos: ', err);
            }   
            
            setPhotosLoading(false);
            
      
        }

        // get child Ids for whom we need photos and call function
        if (newEnrolmentsData) {
            const childIds = newEnrolmentsData.map(obj => obj.child_id).filter((x, i, a) => a.indexOf(x) === i);
            // console.log('displayedRollIds to fetch photos for: ', displayedRollIds);
            // console.log('childIds to fetch photos for: ', childIds);
            // if photos already in state, check if we are missing a photo for any children on roll and only request those ones
            let photoMissingIds = [];
            if (photos) {
                const photosLoadedIds = Object.keys(photos);
                // console.log('photos loaded child Ids: ', photosLoadedIds);
    
                // check if we already have photos for all children, otherwise fetch new ones needed
                photoMissingIds = childIds.filter(id => !photosLoadedIds.includes(id.toString()));
                // console.log('photoMissingIds: ', photoMissingIds);

            } else {
                // no photos in state, so request photo for all children on roll
                photoMissingIds = childIds;
            }


            if (photoMissingIds.length > 0) {
                setPhotosLoading(true);
                console.log('attempting to fetch photos for ids: ', photoMissingIds);
                getPhotos(photoMissingIds);

            }

        }
        
        
    }, [props.token, newEnrolmentsData, photos, centreId]);    


    // generate list of new enrolments, excluding any empty row (i.e. one that comes back from the master-data/fetch call when there're no records so we can still see column names)
    const rows = newEnrolmentsData?.sort((a,b) => {
        if ( a.surname < b.surname ) return -1;
        if ( a.surname > b.surname ) return 1;
        return 0;
    })
    .filter(obj => obj.child_id)
    .sort((a,b) => new Date(a.photo_submitted_at)?.getTime() - new Date(b.photo_submitted_at)?.getTime())
    ?.map(obj => {

        // create child photo img tag if we have one
        // let childPhoto = <FiCameraOff size={'1.5rem'} color={'black'} /> 
        const initial1 = obj.first_name.slice(0,1);
        const initial2 = obj.surname.slice(0,1);
        const initials = (initial1 + initial2).toUpperCase();

        let childPhotoImg = <InitialIcon initials={initials}/> 
        if (photos && photos[obj.child_id]) {
            childPhotoImg = <img alt="child's face" src={'data:image/jpg;base64,' + photos[obj.child_id]} style={{width: '50px', borderRadius: '50%'}}/>
        }

        const childPhoto = <Fragment>
            <NewBanner />
            {childPhotoImg}
        </Fragment>

        // look for child flags
        const childId = obj.child_id;
        let childFlags;
        if (childFlagsData) {
            childFlags = childFlagsData.filter(obj => obj.child_id === childId);
        }
        // console.log('childFlags: ', childFlags);

        // add class based on checked in status
        let classList = [classes.Row];

        // add selected class if selected
        // if (updateRollIds.includes(obj.id)) {
        //     classList.push(classes.SelectedRow);
        // }         

        // check child's age
        const childAge = getAgeFromDateStr(obj.dob);

        // set icon style
        const iconSize = '1.2rem';
        const iconColor = 'black'

        // if not in kiosk view, render necessary flags/symbols
        const symbols = 
            <Fragment>

                {/* at risk symbol - currently court orders in children_medical, or at_risk flag in child_flags */}
                {obj.court_orders_1 || obj.court_orders_2 || childFlags?.length > 0 
                ? <div onClick={() => handleChildDetail(obj.id, ['court_orders_1', 'court_orders_2'], true)}>
                    <AiOutlineWarning size={iconSize} color={iconColor} />    
                </div> : null}

                {/* dietary restrictions symbol */}
                {obj.dietary_restrictions ? <div onClick={() => handleChildDetail(obj.id, ['dietary_restrictions_detail'])}>
                    <MdNoFood size={iconSize} color={iconColor} />    
                </div> : null}

                {/* medical condition symbol */}
                {obj.diagnosed_condition || !obj.auth_sunscreen || !obj.auth_panadol || !obj.auth_first_aid || !obj.auth_ambulance
                ? <div onClick={() => handleChildDetail(obj.id, ['diagnosed_condition_detail', 'auth_sunscreen', 'auth_panadol', 'auth_first_aid', 'auth_ambulance'])}>
                    <FaNotesMedical size={iconSize} color={iconColor} />    
                </div> : null}

                {/* photo permissions missing symbol */}
                {(!obj.photo_permissions_internal || !obj.photo_permissions_external) ? <div onClick={() => handleChildDetail(obj.id, ['photo_permissions_internal', 'photo_permissions_external'])}>
                    <FiCameraOff size={iconSize} color={iconColor} />    
                </div>  : null}  

                {/* child < 7 symbol */}
                {(childAge < 7) ? <div onClick={() => handleChildDetail(obj.id, ['dob'])}>
                    {/* <RiNumber7 size={iconSize} color={iconColor} />     */}
                    <img alt="under-7-icon" src={u7icon} style={{height: iconSize}} />   
                </div>  : null}                  
            </Fragment>
            

        return (
            <tr className={classList.join(' ')} key={obj.id}>
                <td className={classes.TableItem} 
                // onClick={() => handleUpdateRollIds(obj.id)}
                >{photosLoading ? <div style={{marginBottom: '20px'}}><SmallSpinnerDark /></div>  : childPhoto }</td>
                <td onClick={() => handleChildDetail(obj.id, ['guardian_first_name', 'guardian_surname', 'preferred_contact'])} className={classes.TableItem}>{obj.first_name + ' ' + obj.surname}</td>
                <td>{new Date(obj.created_at).toDateString()}</td>
                <td>{new Date(obj.date).toDateString()}</td>
                <td>
                    <div className={classes.TableSymbols}>
                        {symbols}
                    </div>
                </td>
            </tr>
            );        
    });    


    

    // let content = <SpinnerDark />;
    let content = (
        <Fragment>
            <h4>New Enrolments Last 2 Weeks</h4>
            <p><i>Note: Children will remain on this list until they've had a photo taken</i></p>
            <table className={["table", classes.Table].join(" ")}>
                <thead >
                    <tr>
                        <th>
                            {/* <input onChange={(e) => handleSelectAll(e.target.checked)} className="form-check" type="checkbox" id="flexCheckDefault"/> */}
                        </th>
                        <th>Child</th>
                        <th>Date Joined</th>
                        <th>Next Booking</th>
                        <th>Flags</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}  
                </tbody>
            </table>        

        </Fragment>
    )
    // components with data dependencies 


    return (
        <Fragment >
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Modal show={showChildDetail} modalClosed={() => {setShowChildDetail(false); setChildDetail();}}>
                <h3>Child Summary Info</h3>
                <hr/>
                {childDetail}
            </Modal>               
            {loading && <BackdropSpinner spinner='small-light' />}
            <div className={classes.NewEnrolments}>
                {content}
            </div>
        </Fragment>
    );
}

export default NewEnrolments;

