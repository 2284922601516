import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import classes from './ParentData.module.css';
import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../UI/Modal/Modal';
import {
    //  updateArrayOfObjectsInState, 
     handleBannerText, handleAwsApiError, arrayUniqueByKey, humanize } from '../../../utilities/functions';
// import SettingsInput from '../../../UI/SettingsInput/SettingsInput';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
// import { raw } from '../../../translations/en/raw';
import ParamsForm from '../../../UI/ParamsForm/ParamsForm';
import countries from '../../../utilities/S3 Json Files/countries.json';
import genders from '../../../utilities/S3 Json Files/genders.json';
import languages from '../../../utilities/S3 Json Files/languages.json';
import states from '../../../utilities/S3 Json Files/states.json';
import schools from '../../../utilities/S3 Json Files/schools.json';
import culturalBackgrounds from '../../../utilities/S3 Json Files/culturalBackgrounds.json';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../utilities/globalVariables';
import { accountDataGroupings, childGroupAuthDescriptions, childGuardianAuthTypes, childGuardianRelationshipTypes, generalDocTypes, schoolYears } from '../../../utilities/globalObjects';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import SimpleFormioCustomPost from '../../Misc/SimpleFormioCustomPost/SimpleFormioCustomPost';
import { raw, view_accounts_missing_fields } from '../../../translations/en/raw';
import FileUpload from '../../../UI/FileUpload/FileUpload';
import MaterialList from '../../../UI/MaterialList/MaterialList';

const ParentData = (props) =>  {

    // hoc state
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState();
    const [bannerText, setBannerText] = useState('');  
    const [showAddNewPersonModal, setShowAddNewPersonModal] = useState(props.showAddContactForm);

    // selector state
    const [selectedChildId, setSelectedChildId] = useState();
    const [selectedGuardianId, setSelectedGuardianId] = useState();
    const [selectedEmergencyContactId, setSelectedEmergencyContactId] = useState();
    // const [selected, setSelected] = useState([]);    
    
    // top level section show state
    const [editingSection, setEditingSection] = useState();

    // data state
    // guardians data
    const [newGuardianData, setNewGuardianData] = useState();
    const [newGuardianAddressData, setNewGuardianAddressData] = useState();
    const [newGuardianAuthData, setNewGuardianAuthData] = useState();
    const [newGuardianRelationshipsData, setNewGuardianRelationshipsData] = useState();

    // children data
    const [newChildData, setNewChildData] = useState();
    const [newChildGroupAuthsData, setNewChildGroupAuthsData] = useState();
    const [newChildMedicalData, setNewChildMedicalData] = useState();
    const [newChildAddressData, setNewChildAddressData] = useState();
    const [newDocumentsData, setNewDocumentsData] = useState();
    
    // emergency contacts data
    const [newECAuthData, setNewECAuthData] = useState();
    const [newECRelationshipsData, setNewECRelationshipsData] = useState();
    const [newAuthPersonLinkedChildren, setNewAuthPersonLinkedChildren] = useState();

    // document upload state
    const [files, setFiles] = useState([]);
    const [showAddDocument, setShowAddDocument] = useState();
    
    
    // react router history instance to navigate user to other pages
    let history = useHistory();
    let location = useLocation();    
    
    // get data from props
    const guardianData = props.guardianData;
    const documentsData = props.documentsData;
    const guardianAddressData = props.guardianAddressData;
    const childrenData = props.childrenData;
    const childrenAddressData = props.childrenAddressData;
    const childrenMedicalData = props.childrenMedicalData;
    const childGuardianAuthData = props.childGuardianAuthData;
    const childGuardianRelationshipData = props.childGuardianRelationshipData;
    const childGroupAuthData = props.childGroupAuthData;
    const onPostSuccessFn = props.onPostSuccessFn;
    const fetchSpecificTableData = props.fetchSpecificTableData;
    const missingAccountData = props.missingAccountData;
    const emergencyContactsData = props.emergencyContactsData;
    const adminFlag = props.adminFlag;
    // const secondaryGuardianData = props.secondaryGuardianData;

    // create list of child IDs in account
    const childIds = childrenData.map(obj => obj.child_id).filter((x,i,a) => a.indexOf(x) === i).sort((a,b) => b-a);

    // get primary guardian ID
    const primaryGuardianId = +childGuardianRelationshipData.filter(obj => obj.person_type ===  'primary_guardian')?.[0]?.person_id;
    console.log('primaryGuardianId: ', primaryGuardianId);    

    // console logs
    // console.log('documentsData: ', documentsData);

    console.log('childrenData: ', childrenData);
    console.log('guardianData: ', guardianData);
    // console.log('childrenAddressData: ', childrenAddressData);
    console.log('newChildAddressData: ', newChildAddressData);
    // console.log('newGuardianData: ', newGuardianData);
    // console.log('childGroupAuthData: ', childGroupAuthData);
    // console.log('newChildGroupAuthsData: ', newChildGroupAuthsData);
    console.log('childGuardianRelationshipData: ', childGuardianRelationshipData);
    // console.log('guardianAddressData: ', guardianAddressData);
    // console.log('newGuardianAddressData: ', newGuardianAddressData);
    // console.log('emergencyContactsData: ', emergencyContactsData);
    // console.log('newECRelationshipsData: ', newECRelationshipsData);
    // console.log('newChildData: ', newChildData);
    // console.log('setNewChildMedicalData: ', newChildMedicalData);
    // console.log('selectedChildId: ', selectedChildId);
    // console.log('childGuardianAuthData: ', childGuardianAuthData);
    // console.log('newECAuthData: ', newECAuthData);
    // console.log('newGuardianAuthData: ', newGuardianAuthData);
    // console.log('newECRelationshipsData: ', newECRelationshipsData);
    // console.log('newGuardianRelationshipsData: ', newGuardianRelationshipsData);

    // set data in state so we can modify
    useEffect(() => {
        
        // only allowing editing of user's own data currently
        // if (guardianData) {
        //     // setNewGuardianData(guardianData[0]);
        //     setNewGuardianData([...guardianData]);
        // }
        // if (guardianAddressData) {
        //     // setNewGuardianAddressData(guardianAddressData[0]);
        //     setNewGuardianAddressData([...guardianAddressData]);
        // }

        if (selectedGuardianId && guardianData && guardianAddressData) {
            setNewGuardianAddressData(guardianAddressData.filter(obj => obj.person_id === +selectedGuardianId)?.[0])
            setNewGuardianData(guardianData.filter(obj => obj.guardian_id === +selectedGuardianId)?.[0])
            setNewGuardianAuthData(childGuardianAuthData.filter(obj => +obj.person_id === +selectedGuardianId));
            setNewGuardianRelationshipsData(childGuardianRelationshipData.filter(obj => +obj.person_id === +selectedGuardianId));
        }

    }, [guardianData, guardianAddressData, selectedGuardianId, childGuardianAuthData, childGuardianRelationshipData]);

    // put selected childs data into state
    useEffect(() => {
        
        if (selectedChildId && childrenData && childrenMedicalData && childGroupAuthData && documentsData && childrenAddressData) {
            setNewChildData(childrenData.filter(obj => +obj.child_id === +selectedChildId)[0]);
            setNewChildMedicalData(childrenMedicalData.filter(obj => +obj.child_id === +selectedChildId)[0]);
            setNewChildAddressData(childrenAddressData.filter(obj => obj.child_id === +selectedChildId)?.[0])
            setNewChildGroupAuthsData(childGroupAuthData.filter(obj => +obj.child_id === +selectedChildId));
            setNewDocumentsData(documentsData.filter(obj => +obj.child_id === +selectedChildId));
            
        }
    }, [selectedChildId, childrenData, childrenMedicalData, childGroupAuthData, documentsData, childrenAddressData])

    // put selected authPersons data into state
    useEffect(() => {
        
        if (selectedEmergencyContactId) {
            setNewECAuthData(childGuardianAuthData.filter(obj => +obj.person_id === +selectedEmergencyContactId));
            setNewECRelationshipsData(childGuardianRelationshipData.filter(obj => +obj.person_id === +selectedEmergencyContactId));
        }
    }, [selectedEmergencyContactId, childGuardianAuthData, childGuardianRelationshipData])

    const handleUpdateData = async (table, updatedRow, loadingFn, showBanner) => {

        loadingFn ? loadingFn(true) : void(0);
        console.log(`updating data for ${table}`);
        // console.log(`updating data for ${table} with updatedRow: ${JSON.stringify(updatedRow)}`);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            table: table,
            updatedRow: updatedRow
        };

        try {
            const res = await axios.post(AWS_API + 'account-owner-data/update', {postData: postData}, headers);
            console.log('res: ', res.data);
            
            showBanner ? handleBannerText(setBannerText, `Data Updated Successfully`) : void(0);
            
            // if on success function passed in, execute it here (currently being used when invoked via headOffice but not via parent)
            onPostSuccessFn ? onPostSuccessFn() : void(0);

            // need to trigger get data here
            fetchSpecificTableData(table);
        } catch (err) {
            setLoading(false);
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
            console.log('error whilst recording roll event: ', err);
        }    

        loadingFn ? loadingFn(false) : void(0);
    }

    const updateChildGuardianAuthorisations = async (newAuthData) => {
        // setLoading(true);
        // console.log(`updating data for: ${newAuthData}`);
        // console.log(`updating data for ${table} with updatedRow: ${JSON.stringify(updatedRow)}`);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            newAuthData: newAuthData,
        };

        try {
            const res = await axios.post(AWS_API + 'account-owner-data/update-authorisations', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Data Updated Successfully`);

            // if on success function passed in, execute it here (currently being used when invoked via headOffice but not via parent)
            onPostSuccessFn ? onPostSuccessFn() : void(0);            
            
            // need to trigger get data here
            fetchSpecificTableData('child_guardian_authorisations');
        } catch (err) {
            setLoading(false);        
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
            console.log('error whilst recording roll event: ', err);
        }    

        // setLoading(false);        
    }

    const handleUpdateGroupAuthData = (newAuthBool, authType, childId) => {
        console.log('handling update of group auths for auth type, authorised: ', authType, newAuthBool);

        // find obj that needs updating in array
        const authRowIndex = newChildGroupAuthsData.findIndex(obj => obj.child_id === childId && obj.auth_type === authType);
        const authRow = newChildGroupAuthsData[authRowIndex];   
        console.log('authRow: ', authRow);  
        console.log('authRowIndex: ', authRowIndex);  
        
        // copy and update state
        const copiedGroupAuthData = [...newChildGroupAuthsData];
        copiedGroupAuthData[authRowIndex] = {...authRow, authorised: newAuthBool}

        setNewChildGroupAuthsData(copiedGroupAuthData);

        // const exampleRow = newChildGroupAuthsData?.[0];
        // const groupAuthDataCopy = [
        //     ...newChildGroupAuthsData
        // ];


    }

    const handleUpdateRelationshipData = (newRelationship, childId, oldRelationshipData, setNewRelationshipDataStateFn) => {

        // find obj that needs updating in array
        console.log('oldRelationshipData: ', oldRelationshipData);  
        const relationshipRowIndex = oldRelationshipData.findIndex(obj => obj.child_id === childId);
        const copiedRelationshipData = [...oldRelationshipData];

        // if relationship doesn't exist in object, add it in (in case account owner is trying to give authoristions and add relationship for an emergency contact with a new child)
        if (relationshipRowIndex === -1 && oldRelationshipData?.length > 0) {
            console.log(`relationship does not currently exist between child ${childId} and auth person, adding in`);
            const newRelationshipObj = {
                ...oldRelationshipData[0],
                child_id: childId,
                relationship: newRelationship
            };
            copiedRelationshipData.push(newRelationshipObj);
        } else {

            const relationshipRow = oldRelationshipData[relationshipRowIndex];   
            console.log('relationshipRowIndex: ', relationshipRowIndex);  
            console.log('relationshipRow: ', relationshipRow);  
            
            // copy and update state
            copiedRelationshipData[relationshipRowIndex] = {...relationshipRow, relationship: newRelationship}
        }

        console.log('setting new relationship data: ', copiedRelationshipData);
        setNewRelationshipDataStateFn(copiedRelationshipData);


    }

    const handleUpdateAuthData = (authGiven, childId, authType, oldAuthData, personId, setNewAuthDataStateFn) => {
        console.log('authGiven, childId, authType: ', authGiven, childId, authType, oldAuthData, personId, setNewAuthDataStateFn)

        // create a copy of auth data for selected person which we will then update
        let updatedAuthPersonData = [...oldAuthData];

        // handle case where selected person didn't previously have any authorisations for child, in which case we won't have their personId etc
        let authPersonIdObj = updatedAuthPersonData?.[0];
        if (!authPersonIdObj) {

            const authPersonRelationshipData = childGuardianRelationshipData.filter(obj => obj.person_id === +personId)?.[0];
            authPersonIdObj = {'person_id': authPersonRelationshipData?.person_id, 'person_type': authPersonRelationshipData?.person_type};

        }



        const authRowExistsIndex = updatedAuthPersonData.findIndex(obj => obj.child_id === childId && obj.authorisation === authType);
        const authRowExists = updatedAuthPersonData[authRowExistsIndex];
        // console.log('authRowExists: ', authRowExists)
        // console.log('authRowExistsIndex: ', authRowExistsIndex)

        // giving auth
        if (authGiven) {
            if (authRowExistsIndex >= 0) {
                // remove delete flag if exists to signify auth regiven
                updatedAuthPersonData[authRowExistsIndex] = {...authRowExists, removeAuth: false}
            } else {
                // update values in template row and add to new auth data
                const newAuthRow = {
                    ...authPersonIdObj,
                    authorisation: authType,
                    child_id: childId,
                }
                updatedAuthPersonData.push(newAuthRow);
            }

        // taking away auth
        } else {

            if (authRowExistsIndex >= 0) {
                // console.log('updatedAuthPersonData[authRowExistsIndex]: ', updatedAuthPersonData[authRowExistsIndex])
                // add removeAuth marker to row
                
                updatedAuthPersonData[authRowExistsIndex] = {...authRowExists, removeAuth: true}
                // console.log('updatedAuthPersonData[authRowExistsIndex]: ', updatedAuthPersonData[authRowExistsIndex])
                // console.log('authRowExists: ', authRowExists)


            } else {
                // do nothing, auth doesn't exist to take away
                return false;
            }

        }

        // console.log('updatedAuthPersonData: ', updatedAuthPersonData);
        setNewAuthDataStateFn(updatedAuthPersonData);
    }    


    // --------------------------------------------------------------------------------------------------------------------------------
    // generate JSX for individual section warnings (i.e. parent warnings, child warnings, emergency contact warnings)

    let missingAccountUI;
    let missingAccountCategories = [], missingAccountFields = [];
    const missingAccountJsxObj = {};
    const missingChildDocsObj = {};
    if (missingAccountData?.length > 0) {

        missingAccountData.forEach(obj => {
            
            const childName = childrenData.filter(o => o.child_id === obj.child_id)?.[0]?.first_name;
            const keys = Object.keys(obj);
            const missingFields = keys.filter(key => obj[key] === true);
            missingAccountJsxObj[childName] = {}

            // populate documents missing for child
            missingChildDocsObj[childName] = [];
            const missingDocs = missingFields.filter(field => field.includes('docs'));
            missingChildDocsObj[childName].push(...missingDocs);
            
                Object.keys(accountDataGroupings).forEach((category, i) => {

                    const categoryFields = accountDataGroupings[category];
                    const categoryMissingFields = missingFields.filter(field => categoryFields.includes(field))

                    if (categoryMissingFields?.length > 0) {
                        missingAccountCategories.push(category);
                        missingAccountFields.push(...categoryMissingFields);
                        const jsx = (
                            <div key={category + '-' + i}>
                            <span style={{fontWeight: 'bold', color: 'red'}}>{category === 'Child Details' && childName  + ' - ' } Missing {category}</span>
                                <div style={{color: 'darkgrey', fontSize: '.7rem'}}><i>{categoryMissingFields.map(el => humanize(view_accounts_missing_fields[el] ?? el)).join(', ')}</i></div>
                            </div>
                        );
                        missingAccountJsxObj[childName][category] = jsx;
                    } 
                })
        })
    }    

    console.log('missingAccountJsxObj: ', missingAccountJsxObj);
    console.log('missingAccountCategories: ', missingAccountCategories);
    console.log('missingChildDocsObj: ', missingChildDocsObj);
    // console.log('missingAccountFields: ', missingAccountFields);
    // console.log('newGuardianData: ', newGuardianData);
    // --------------------------------------------------------------------------------------------------------------------------------


    // submit warning to ensure users don't fill out multiple sections without submitting changes and lose data
    const submitWarning = <p className={classes.WarningText}><i>* Please submit changes <b>before</b> moving on to another individual in your account</i></p>;

    // render guardians table fields
    let guardianForm;
    if (newGuardianData) {
        const dobMissing = (!newGuardianData?.dob || newGuardianData?.dob?.slice(0,10) === '1900-01-01');
        console.log('dobMissing: ', dobMissing);
        guardianForm = (
            <Fragment>
                <ParamsForm 
                    warnOnEmpty
                    data={newGuardianData} 
                    setData={setNewGuardianData} 
                    config={[
                        {name: 'first_name', warnOnEmpty: true,        type: 'text'}, 
                        {name: 'surname', warnOnEmpty: true,           type: 'text'},
                        {name: 'gender', warnOnEmpty: true,            type: 'react-select', options: genders, valueProperty: 'value'},
                        {name: 'preferred_contact', warnOnEmpty: true, type: 'text', validation: {onlyNumbers: true}},
                        {name: 'additional_contact', type: 'text', validation: {onlyNumbers: true}},
                        {name: 'dob', showWarning: dobMissing,                type: 'date'},
                        {name: 'crn',                                   type: 'text', value: newGuardianData?.crn, disabled: true},
                        {name: 'email', warnOnEmpty: true,              type: 'text', value: newGuardianData?.email},
                        {name: 'nationality', warnOnEmpty: true,        type: 'react-select', options: countries, valueProperty: 'label'},
                        {name: 'language_spoken_at_home', warnOnEmpty: true,type: 'react-select', options: languages, valueProperty: 'label'}
                    ]}
                    />
                {/* <button className='btn btn-block btn-success' onClick={() => handleUpdateData('primary_guardian', newGuardianData, setLoading)}>Submit</button> */}
                {/* <button className='btn btn-block btn-success' onClick={() => handleUpdateData('guardians', newGuardianData, setLoading)}>Submit</button> */}
                {/* {submitWarning} */}
            </Fragment>
        );
    }    

    // render addresses table fields for guardian
    let guardianAddressForm, formattedAddress;
    if (newGuardianAddressData) {
        
        // construct full address from fields
        const data = newGuardianAddressData;
        formattedAddress = (data.unit ? 'UNIT ' + data.unit + ' ' : '') + (data.building ? 'BUILDING ' + data.building + ' ' : '') + data.house_number + ' ' + data.road + ', '  + data.suburb + ' ' + data.postcode + ' ' + data.state + ' ' + data.country;
        guardianAddressForm = (
            <Fragment>
                <ParamsForm
                    warnOnEmpty 
                    data={newGuardianAddressData} 
                    setData={setNewGuardianAddressData} 
                    config={[
                        {name: 'full_address', type: 'text', value: formattedAddress}, 
                        {name: 'unit', type: 'number'}, 
                        {name: 'building', type: 'text'},
                        {name: 'house_number', warnOnEmpty: true, type: 'text'},
                        {name: 'road', warnOnEmpty: true, type: 'text'},
                        {name: 'suburb', warnOnEmpty: true, type: 'text'},
                        {name: 'state', warnOnEmpty: true, type: 'select', options: states},
                        {name: 'postcode', warnOnEmpty: true, type: 'text'},
                        {name: 'country', type: 'select', options: countries},
                    ]}
                    />
                {/* <button className='btn btn-block btn-success' onClick={() => handleUpdateData('addresses:guardian', {...newGuardianAddressData, full_address: formattedAddress}, setLoading)}>Submit</button> */}
                {/* {submitWarning} */}
            </Fragment>
        );
    }    

    // render addresses table fields for child
    let childAddressForm;
    if (newChildAddressData) {
        
        // construct full address from fields
        const data = newChildAddressData;
        const formattedAddress = (data.unit ? 'UNIT ' + data.unit + ' ' : '') + (data.building ? 'BUILDING ' + data.building + ' ' : '') + data.house_number + ' ' + data.road + ', '  + data.suburb + ' ' + data.postcode + ' ' + data.state + ' ' + data.country;
        childAddressForm = (
            <Fragment>
                <ParamsForm
                    warnOnEmpty 
                    data={newChildAddressData} 
                    setData={setNewChildAddressData} 
                    config={[
                        {name: 'full_address', type: 'text', value: formattedAddress}, 
                        {name: 'unit', type: 'number'}, 
                        {name: 'building', type: 'text'},
                        {name: 'house_number', warnOnEmpty: true, type: 'text'},
                        {name: 'road', warnOnEmpty: true, type: 'text'},
                        {name: 'suburb', warnOnEmpty: true, type: 'text'},
                        {name: 'state', warnOnEmpty: true, type: 'select', options: states},
                        {name: 'postcode', warnOnEmpty: true, type: 'text'},
                        {name: 'country', type: 'select', options: countries},
                    ]}
                    />
                {/* <button className='btn btn-block btn-success' onClick={() => handleUpdateData('addresses:child', {...newChildAddressData, full_address: formattedAddress}, setLoading)}>Submit</button>
                {submitWarning} */}
            </Fragment>
        );
    }    
    

    // render all secondary guardians within account
    
    

    
    // render child table fields
    let childForm;
    if (newChildData) {
        const dobMissing = (!newChildData?.dob || newChildData?.dob?.slice(0,10) === '1900-01-01');
        childForm = (
            <Fragment>
                <ParamsForm 
                    warnOnEmpty
                    data={newChildData} 
                    setData={setNewChildData} 
                    
                    config={[
                        {name: 'first_name', warnOnEmpty: true, type: 'text'}, 
                        {name: 'surname', warnOnEmpty: true, type: 'text'},
                        {name: 'gender', warnOnEmpty: true, type: 'react-select', options: genders, valueProperty: 'value'},
                        {name: 'dob', showWarning: dobMissing, type: 'date'},
                        {name: 'crn', type: 'text', value: newChildData?.crn, disabled: true},
                        {name: 'country_of_birth', warnOnEmpty: true, type: 'react-select', options: countries, valueProperty: 'label'},
                        {name: 'language_spoken_at_home', warnOnEmpty: true, type: 'react-select', options: languages, valueProperty: 'label'},
                        {name: 'school_child_attends', warnOnEmpty: true, type: 'react-select', options: schools, valueProperty: 'value'}, // when this schools.json file is copied from S3, object properties need to be changed from ACARAid and schoolName to value and label
                        {name: 'cultural_background', warnOnEmpty: true, type: 'react-select', options: culturalBackgrounds, valueProperty: 'value'}, // when this schools.json file is copied from S3, object properties need to be changed from ACARAid and schoolName to value and label
                        {name: 'current_school_year', warnOnEmpty: true, type: 'react-select', options: schoolYears, valueProperty: 'value'},
                    ]}
                    />
                {/* <button className='btn btn-block btn-success' onClick={() => handleUpdateData('children', newChildData, setLoading)}>Submit</button>
                {submitWarning} */}
            </Fragment>
        );
    }  

    // render child_medical table fields
    let childMedicalForm;
    if (newChildMedicalData) {
        const dietaryDetailNeeded = newChildMedicalData?.dietary_restrictions;
        const conditionDetailNeeded = newChildMedicalData?.diagnosed_condition;
        childMedicalForm = (
            <Fragment>
                <ParamsForm 
                    warnOnEmpty
                    data={newChildMedicalData} 
                    setData={setNewChildMedicalData} 
                    config={[
                        {name: 'first_name', type: 'text', value: newChildData?.first_name}, 
                        {name: 'surname', type: 'text', value: newChildData?.surname}, 
                        {name: 'diagnosed_condition', type: 'checkbox'},
                        {name: 'diagnosed_condition_detail', warnOnEmpty: conditionDetailNeeded, type: 'text'},
                        {name: 'dietary_restrictions', type: 'checkbox'},
                        {name: 'dietary_restrictions_detail', warnOnEmpty: dietaryDetailNeeded, type: 'text'},
                        {name: 'med_prac_name', warnOnEmpty: true, type: 'text'},
                        {name: 'med_prac_phone', warnOnEmpty: true, type: 'text', validation: {onlyNumbers: true}},
                        {name: 'med_address_house_number', warnOnEmpty: true, type: 'text'},
                        {name: 'med_address_road', warnOnEmpty: true, type: 'text'},
                        {name: 'med_address_suburb', warnOnEmpty: true, type: 'text'},
                        {name: 'med_address_state', warnOnEmpty: true, type: 'text'},
                        {name: 'med_address_postcode', warnOnEmpty: true, type: 'number'},
                        // {name: 'med_address_country', type: 'text'},
                        {name: 'medicare_number', warnOnEmpty: true, type: 'text', validation: {onlyNumbers: true}},
                        {name: 'medicare_child_irn', warnOnEmpty: true, type: 'text', validation: {onlyNumbers: true}},
                        {name: 'medicare_expiry_date', warnOnEmpty: true, type: 'date-picker-month'},
                        // {name: 'private_health_fund_name', type: 'text'},
                        // {name: 'private_health_memb_number', type: 'text'},
                        // {name: 'private_health_cover', type: 'checkbox'},
                        {name: 'special_considerations', type: 'textarea'},
                        {name: 'ambulance_cover', type: 'checkbox'},
                        // {name: 'auth_firstaid', type: 'checkbox'},
                        // {name: 'auth_medical', type: 'checkbox'},
                        // {name: 'auth_transport', type: 'checkbox'},
                        {name: 'court_orders_1', type: 'checkbox'},
                        {name: 'court_orders_2', type: 'checkbox'},
                        {name: 'immunisations', type: 'checkbox'},
                    ]}
                    />
                {/* <button className='btn btn-block btn-success' onClick={() => handleUpdateData('children_medical', {...newChildMedicalData, first_name: undefined, surname: undefined}, setLoading)}>Submit</button> */}
                {/* {submitWarning} */}
            </Fragment>
        );
    }  
    
    // render child selector
    const childSelector = (
        <select className='form-control' value={selectedChildId ?? ''} onChange={(e) => setSelectedChildId(e.target.value)}>
            <option disabled value=''>Select Child</option>
            {childrenData.map(obj => <option key={obj.child_id} value={obj.child_id}>{
              (!obj.current_school_year || !obj.language_spoken_at_home) ? `*ACTION REQUIRED: ${obj.first_name} ${obj.surname}`  :  `${obj.first_name} ${obj.surname}`
            }</option>)}
        </select>        
    );       
        

    
    // render guardian selector
    const guardianSelector = (
        <select className='form-control' value={selectedGuardianId ?? ''} onChange={(e) => setSelectedGuardianId(e.target.value)}>
                <option disabled value=''>Select Guardian</option>
                {/* {arrayUniqueByKey('person_id', childGuardianAuthData?.map(obj => ({person_id: obj.person_id, first_name: obj.first_name, surname: obj.surname})))?.map(obj => <option key={obj.person_id} value={obj.person_id}>{obj.first_name + ' ' + obj.surname}</option>)} */}
                {arrayUniqueByKey('guardian_id', guardianData?.map(obj => ({guardian_id: obj.guardian_id, first_name: obj.first_name, surname: obj.surname, dob: obj.dob?.slice(0,10) === '1900-01-01' ? undefined : obj.dob, language_spoken_at_home: obj.language_spoken_at_home}))?.sort((a,b) => {
                    if (a.guardian_id === primaryGuardianId && b.guardian_id !== primaryGuardianId) return -1
                    else if (b.guardian_id === primaryGuardianId && a.guardian_id !== primaryGuardianId) return 1
                    else return a.guardian_id - b.guardian_id
                }))?.map(obj => 
                    <option key={obj.guardian_id} value={obj.guardian_id}>{
                        (obj.guardian_id === primaryGuardianId) ? obj.first_name + ' ' + obj.surname + ' (Primary Guardian)' 
                        : ((!obj.dob || !obj.language_spoken_at_home) && (obj.first_name || obj.surname)) ? `*ACTION REQUIRED: ${obj.first_name} ${obj.surname}` 
                        : ((!obj.dob || !obj.language_spoken_at_home) && (!obj.first_name && !obj.surname)) ? `*ACTION REQUIRED: Secondary Guardian (ID ${obj.guardian_id})` 
                        : (obj.first_name || obj.surname) ? obj.first_name + ' ' + obj.surname + ' (Secondary Guardian)'
                        :`Secondary Guardian (ID ${obj.guardian_id})`
                    }</option>)}
            </select>        


    );      
    
    // render emergency contact selector
    const emergencyContactSelector = (
        <select className='form-control' value={selectedEmergencyContactId ?? ''} onChange={(e) => setSelectedEmergencyContactId(e.target.value)}>
            <option disabled value=''>Select Person</option>
            {/* {arrayUniqueByKey('person_id', childGuardianAuthData?.map(obj => ({person_id: obj.person_id, first_name: obj.first_name, surname: obj.surname})))?.map(obj => <option key={obj.person_id} value={obj.person_id}>{obj.first_name + ' ' + obj.surname}</option>)} */}
            {emergencyContactsData.filter(obj => obj.contact_id)?.map(obj => ({person_id: obj.contact_id, first_name: obj.first_name, surname: obj.surname}))?.map(obj => <option key={obj.person_id} value={obj.person_id}>{obj.first_name + ' ' + obj.surname}</option>)}
        </select>        
    );      
    
    // // render authPerrson selector
    // const emergencyContactSelector = (
    //     <select className='form-control' value={selectedEmergencyContactId ?? ''} onChange={(e) => setSelectedEmergencyContactId(e.target.value)}>
    //         <option disabled value=''>Select Person</option>
    //         {/* {arrayUniqueByKey('person_id', childGuardianAuthData?.map(obj => ({person_id: obj.person_id, first_name: obj.first_name, surname: obj.surname})))?.map(obj => <option key={obj.person_id} value={obj.person_id}>{obj.first_name + ' ' + obj.surname}</option>)} */}
    //         {arrayUniqueByKey('person_id', childGuardianRelationshipData?.map(obj => ({person_id: obj.person_id, first_name: obj.first_name, surname: obj.surname})))?.map(obj => <option key={obj.person_id} value={obj.person_id}>{obj.first_name + ' ' + obj.surname}</option>)}
    //     </select>        
    // );      


    
 
    


let childGroupAuthsForm;
if (childrenData && newChildGroupAuthsData) {
        childGroupAuthsForm = <Fragment>

                {
                    newChildGroupAuthsData.sort((a,b) => {
                        if ( a.auth_type < b.auth_type ) return -1;
                        if ( a.auth_type > b.auth_type ) return 1;
                        return 0;
                    }).map(obj => {
                        let classList = ["form-check"];
                        if (!obj.authorised) {
                            classList.push(classes.Warning);
                        }
                        return (
                            <div className={classList.join(' ')} key={obj.auth_type}>
                                <input checked={obj.authorised} onChange={(e) => handleUpdateGroupAuthData(e.target.checked, obj.auth_type, obj.child_id)} className="form-check-input" type="checkbox"/>
                                <label ><b>{humanize(obj.auth_type) ?? obj.auth_type}</b> <div className={classes.ChildGroupAuthDescriptions}>{childGroupAuthDescriptions[obj.auth_type]}</div></label>
                                {/* <label >{humanize(obj.auth_type) ?? obj.auth_type} <i>()</i></label> */}
                            </div>
                        )
                    })
                }
            {/* <button className="btn btn-success btn-block" onClick={() => handleUpdateData('child_group_authorisations', newChildGroupAuthsData, setLoading)}>Submit</button> */}
            {/* {submitWarning} */}
        </Fragment>

    }     
    
    const postAddNewPersonSubmission = async (submission, onSubmissionDone) => {
        
        setLoading(true);
        
        // close modals
        setShowAddNewPersonModal(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            submission: submission,
            additionalParams: newAuthPersonLinkedChildren
        };
        console.log('posting submission: ', postData);
        

        try {
            const res = await axios.post(AWS_API + 'account-owner-data/add-authorised-person', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Person Added Successfully`);
            onSubmissionDone();

            // if on success function passed in, execute it here (currently being used when invoked via headOffice but not via parent)
            onPostSuccessFn ? onPostSuccessFn() : void(0);            
            
            // need to trigger get data here
            fetchSpecificTableData('child_guardian_authorisations');
            fetchSpecificTableData('child_guardian_relationships');
            fetchSpecificTableData('emergency_contacts');
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error: ', err);
        }    

        setLoading(false);            
    } 

    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn, onErrorFn) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        setShowAddNewPersonModal(false);
        setShowAddDocument(false);
        
        try {
            const res = await axios.post(AWS_API + route, postData, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            handleBannerText(setBannerText, `Request Completed Successfully`);
            
            
        } catch (err) {
            onErrorFn ? onErrorFn() : void(0);
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoading(false);
    }     

    const handleAddDocument = () => {

        // ensure only one child is selected, as this will be the child uploaded document is assiged to
        if (selectedChildId) {
            setShowAddDocument(true);
        } else {
            alert('Please select a child for whom to upload documents');
        }
    }

    const handlePostDocument = async () => {
    
        const postData = {
            postData: {
                childId: selectedChildId, 
                centreId: childrenData.filter(obj => obj.child_id === +selectedChildId)?.[0]?.home_centre_id,
                documents: [...files]
            }
        };

        await postMasterData(
            'form-submissions/upload-documents', 
            postData, 
            () => {setFiles([]); fetchSpecificTableData('documents');}, 
            () => {setFiles([]);}
        );
    
    }    

    const handleDocumentDownload = async (rowId) => {

        setLoading(true);

        console.log('fetching documents for id: ', rowId);
        // const documentRow = documentsData.filter(obj => obj.document_id === rowId)?.[0];
        // console.log('documentRow: ', documentRow);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {documentId: rowId}
        };

        let url;
        try {
            const res = await axios.get(AWS_API + 'account-owner-data/presigned-url', config);
            console.log('data fetch res: ', res.data);
            url = res.data.url;

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }        
        
        if (url) {
            window.open(url,'_newtab');
        } 

        setLoading(false);       

    }
  
    const submitFullGuardianForm = async () => {

        setEditingSection();
        setLoading(true);
        
        // go through and submit each section in turn
        await handleUpdateData('guardians', newGuardianData, undefined, false)
        await handleUpdateData('addresses:guardian', {...newGuardianAddressData, full_address: formattedAddress}, undefined, false)
        await updateChildGuardianAuthorisations(newGuardianAuthData);
        await handleUpdateData('child_guardian_relationships', newGuardianRelationshipsData, undefined, true)

        setLoading(false);
    }
    
    const submitFullChildForm = async () => {
        
        setEditingSection();
        setLoading(true);
        
        // go through and submit each section in turn
        await handleUpdateData('children', newChildData, undefined, false)
        await handleUpdateData('children_medical', {...newChildMedicalData, first_name: undefined, surname: undefined}, undefined, false)
        await handleUpdateData('addresses:child', {...newChildAddressData, full_address: formattedAddress}, undefined, false)
        await handleUpdateData('child_group_authorisations', newChildGroupAuthsData, undefined, false)
        await handlePostDocument();
        
        handleBannerText(setBannerText, `Data Updated Successfully`)
        setLoading(false);
    }

    const submitFullEmergencyForm = async () => {

        setEditingSection();
        setLoading(true);
        
        // go through and submit each section in turn
        await updateChildGuardianAuthorisations(newECAuthData);
        await handleUpdateData('child_guardian_relationships', newECRelationshipsData, undefined, true)

        setLoading(false);
    }

    const fillAddressFromPG = (personType) => {
        
        const primaryGuardianAddress = guardianAddressData.filter(obj => obj.person_id === primaryGuardianId)?.[0];
        console.log('primaryGuardianAddress: ', primaryGuardianAddress);
        
        if (personType === 'child') {
            setNewChildAddressData({
                ...newChildAddressData,
                unit: primaryGuardianAddress?.unit,
                building: primaryGuardianAddress?.building,
                house_number: primaryGuardianAddress?.house_number,
                road: primaryGuardianAddress?.road,
                suburb: primaryGuardianAddress?.suburb,
                state: primaryGuardianAddress?.state,
                postcode: primaryGuardianAddress?.postcode,
                country: primaryGuardianAddress?.country,
            })
        } else if (personType === 'guardian') {
            setNewGuardianAddressData({
                ...newGuardianAddressData,
                unit: primaryGuardianAddress?.unit,
                building: primaryGuardianAddress?.building,
                house_number: primaryGuardianAddress?.house_number,
                road: primaryGuardianAddress?.road,
                suburb: primaryGuardianAddress?.suburb,
                state: primaryGuardianAddress?.state,
                postcode: primaryGuardianAddress?.postcode,
                country: primaryGuardianAddress?.country,
            })

        }
    }

    // create options array for children to use in React-Select
    const childrenOptionsArray = childrenData.map(obj => ({value: obj.child_id, label: obj.first_name + ' ' + obj.surname}))
    // console.log('newAuthPersonLinkedChildren: ', newAuthPersonLinkedChildren);



    // add missing field classes to necessary sections
    let guardianActionRequired, childActionRequired, emergencyActionRequired;
    let guardianClasses = [classes.OuterData];
    let childrenClasses = [classes.OuterData];
    let emergencyClasses = [classes.OuterData];
    let guardianGenericBorderClass = [];
    let childGenericBorderClass = [];
    let emergencyGenericBorderClass = [];
    let guardianButtonClass = 'btn btn-block btn-secondary';
    let childButtonClass = 'btn btn-block btn-secondary';
    let emergencyButtonClass = 'btn btn-block btn-secondary';
    let guardianSelectorClasses = [];
    let childSelectorClasses = [];
    let emergencySelectorClasses = [];

    // guardians missing info
    let childGuardianMissingInfoFor;
    if (missingAccountCategories.includes('Guardian Details')) {
        guardianActionRequired = true;
        guardianClasses.push(classes.DataMissingWarning)
        guardianGenericBorderClass.push(classes.DataMissingWarning)
        guardianButtonClass = 'btn btn-block btn-success';
        guardianSelectorClasses.push(classes.Warning, classes.SelectorPadding);
        
        // get any child for which guardian is missing info for
        Object.keys(missingAccountJsxObj).forEach(child => {
            if (missingAccountJsxObj?.[child]?.['Guardian Details']) {
                childGuardianMissingInfoFor = child;
            }
        });
    }
    console.log('childGuardianMissingInfoFor: ', childGuardianMissingInfoFor)
    
    // children missing info
    if (missingAccountCategories.includes('Child Details')) {
        childActionRequired = true;
        childrenClasses.push(classes.DataMissingWarning)
        childGenericBorderClass.push(classes.DataMissingWarning)
        childButtonClass = 'btn btn-block btn-success';
        childSelectorClasses.push(classes.Warning, classes.SelectorPadding);
    }
    
    // auths/relationships missing info
    if (missingAccountCategories.includes('Emergency Contact Details')) {
        emergencyActionRequired = true;
        emergencyClasses.push(classes.DataMissingWarning)
        emergencyGenericBorderClass.push(classes.DataMissingWarning)
        emergencyButtonClass = 'btn btn-block btn-success';
        emergencySelectorClasses.push(classes.Warning, classes.SelectorPadding);
    }

    // get missing documents for selected child
    const selectedChildDocsMissing = missingChildDocsObj?.[newChildData?.first_name];

    // set variable for inner data class - currently not using per Cam styling requests
    const innerSectionClasses = [];


    return (
        <Fragment>
            <Banner show={bannerText}>{bannerText}</Banner>                
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>    
            <Modal large show={showAddNewPersonModal} modalClosed={() => setShowAddNewPersonModal(false)}>
                <h5>Add New Emergency Contact</h5>
                <ParamsForm 
                    data={newAuthPersonLinkedChildren} 
                    setData={setNewAuthPersonLinkedChildren} 
                    config={[
                        {name: 'children_authorising_for', type: 'react-select', options: childrenOptionsArray, valueProperty: 'value', isMulti: true}
                    ]}
                    />
                    {showAddNewPersonModal && <SimpleFormioCustomPost formType='newAuthorisedPerson' postSubmission={postAddNewPersonSubmission}/> }
            </Modal> 
            {/* <Modal show={showAddDocument} modalClosed={() => setShowAddDocument(false)}>
                {showAddDocument && <Fragment>
                    <h3>Upload Documents</h3>
                    <br/>
                    <p>Selected Child: <i>{childrenData?.filter(obj => obj.child_id === +selectedChildId)?.[0]?.first_name}</i></p>
                    <p>Selected centre: <i>{childrenData.filter(obj => obj.child_id === +selectedChildId)?.[0]?.home_centre_id}</i></p>
                    <hr/>
                    <FileUpload setFiles={setFiles} files={files} docTypes={generalDocTypes} useJSMimesNotCCSMimes/>    
                    <button className='btn btn-block btn-success' onClick={handlePostDocument} >Submit</button>
                </Fragment>
                }
            </Modal>                                */}
            {loading && <BackdropSpinner spinner='small-light' />}
            {missingAccountUI}

            {/* show section selector */}
            <div style={{maxWidth: '600px', margin: 'auto'}}>
                <button className={guardianButtonClass} onClick={() => setEditingSection('guardian')}>View/Edit Guardian Data</button>
                {guardianActionRequired && <p className={classes.WarningText}>* Action Required</p>}
                <button className={childButtonClass} onClick={() => setEditingSection('child')}>View/Edit Children Data</button>
                {childActionRequired && <p className={classes.WarningText}>* Action Required</p>}
                <button className={emergencyButtonClass} onClick={() => setEditingSection('emergency')}>View/Edit Emergency Contact Data</button>
                {emergencyActionRequired && <p className={classes.WarningText}>* Action Required</p>}
            </div>

            <br/>

            {/* guardian section */}

            <Modal tall show={editingSection === 'guardian'} modalClosed={() => setEditingSection()}>
                {editingSection === 'guardian' && <Fragment>
                    <div >
                    {/* <div className={guardianClasses.join(' ')}> */}
                        <h4>Guardians</h4>
                        <hr/>
                        {missingAccountJsxObj?.[childGuardianMissingInfoFor]?.['Guardian Details']}
                        <hr/>
                        <p><i>Select a guardian to edit details</i></p>
                        {/* <div className={guardianGenericBorderClass.join(' ')} > */}
                        <div className={guardianSelectorClasses.join(' ')}>
                            {guardianSelector}
                        </div>
                        {/* </div> */}
                        <br/>
                    {selectedGuardianId && <Fragment>
                        <div >
                                <h5>Personal Details</h5>
                                <div className={classes.Subtitle}><i>{newGuardianData?.first_name}</i></div>
                                <hr />
                                {guardianForm}
                            </div>
                            <br/>
                            <div style={{position: 'relative'}} className={innerSectionClasses.join(' ')}>
                                {/* {+selectedGuardianId !== primaryGuardianId && <div className={classes.HeaderButtonRowRight}>
                                    <button className="btn btn-info btn-sm" onClick={() => {fillAddressFromPG('guardian');}}>Copy Address from Primary Guardian</button>
                                </div>} */}
                                <h5>Address</h5>
                                <div className={classes.Subtitle}><i>{newGuardianData?.first_name}</i></div>
                                <hr/>
                                {+selectedGuardianId !== primaryGuardianId && <button className="btn btn-info btn-block" onClick={() => {fillAddressFromPG('guardian');}}>Copy Address from Primary Guardian</button>}
                                {guardianAddressForm}
                            </div>
                            <br/>
                            <div className={innerSectionClasses.join(' ')}> 
                                <h5>Authorisations</h5>
                                <br/>
                                <ChildGuardianAuthsForm 
                                    childIds={childIds}
                                    childrenData={childrenData}
                                    handleUpdateAuthData={handleUpdateAuthData}
                                    // updateChildGuardianAuthorisations={updateChildGuardianAuthorisations}
                                    newAuthData={newGuardianAuthData}
                                    selectedAuthPersonId={selectedGuardianId}
                                    setNewAuthDataStateFn={setNewGuardianAuthData}
                                    submitWarning={submitWarning}
                                    authPersonName={newGuardianData?.first_name}
                                />
                            </div>                
                            <br/>
                            <div className={innerSectionClasses.join(' ')}> 
                                <h5>Relationships</h5>
                                <br/>
                                <ChildGuardianRelationshipsForm 
                                    childIds={childIds}
                                    childrenData={childrenData}
                                    handleUpdateRelationshipData={handleUpdateRelationshipData}
                                    // handleUpdateData={handleUpdateData}
                                    newRelationshipData={newGuardianRelationshipsData}
                                    setNewRelationshipDataStateFn={setNewGuardianRelationshipsData}
                                    setLoading={setLoading}
                                    submitWarning={submitWarning}
                                    authPersonName={newGuardianData?.first_name}
                                />
                            </div>   
                            <hr/>
                            <button className={'btn btn-block btn-success'} onClick={() => submitFullGuardianForm()}>Submit</button>
                            {submitWarning}
                    </Fragment>}                
                    </div>
                </Fragment>
                }
            </Modal>  


            {/* children section */}
            <Modal tall show={editingSection === 'child'} modalClosed={() => setEditingSection()}>
                {/* {editingSection === 'child' && <div className={childrenClasses.join(' ')}> */}
                {editingSection === 'child' && <div>
                    <h4>Children</h4>
                    <hr/>
                    {Object.keys(missingAccountJsxObj).map(child => <Fragment>
                        {missingAccountJsxObj?.[child]?.['Child Details']}
                        <hr/>
                    </Fragment>)}
                    <p><i>Select a child to edit details</i></p>
                    {/* <div className={childGenericBorderClass.join(' ')} > */}
                    <div className={childSelectorClasses.join(' ')} >
                        {childSelector}
                    </div>                
                    <br/>
                    {selectedChildId && <Fragment>
                        <div className={innerSectionClasses.join(' ')}>
                            {childForm}
                        </div>
                        <br/>
                        <div className={innerSectionClasses.join(' ')}>
                            <h5>Child Medical Details</h5>
                            <div className={classes.Subtitle}><i>{newChildData?.first_name}</i></div>
                            <hr/>
                            {childMedicalForm} 
                        </div>
                        <br/>
                        <div style={{position: 'relative'}} className={innerSectionClasses.join(' ')}>
                            {/* <div className={classes.HeaderButtonRowRight}>
                                <button className="btn btn-success btn-sm" onClick={() => {fillAddressFromPG('child');}}>Fill from Primary Guardian</button>
                            </div> */}
                            <h5>Address</h5>
                            <div className={classes.Subtitle}><i>{newChildData?.first_name}</i></div>
                            <hr/>
                            <button className="btn btn-info btn-block" onClick={() => {fillAddressFromPG('child');}}>Copy Address from Primary Guardian</button>
                            {childAddressForm}
                        </div>
                        <br/>
                        <div className={innerSectionClasses.join(' ')}>
                            <h5>Child Centre Authorisations</h5>
                            <div className={classes.Subtitle}><i>{newChildData?.first_name}</i></div>
                            <hr/>
                            {childGroupAuthsForm} 
                        </div>
                        <br/>
                        <div style={{position: 'relative'}} className={innerSectionClasses.join(' ')}>
                            {/* Currently not handling document upload on behalf of parent from admin side as this can be done through the documents tab of the cabinet */}
                            <h5>Child Documents</h5>
                            <div className={classes.Subtitle}><i>{newChildData?.first_name}</i></div>
                            <hr/>
                            {!adminFlag && <button className="btn btn-info btn-block" onClick={() => {handleAddDocument();}}>Upload New Documents</button>}
                            <br/>
                            <p><i>{selectedChildDocsMissing?.length > 0 && <div className={classes.WarningTextBold}>Missing Documents: 
                            {selectedChildDocsMissing.map(field => humanize(raw[field]) ?? humanize(field)).join(', ')}</div>}</i></p>
                            
                            {/* <p><i>{( missingAccountFields.includes("immunisation_docs") || missingAccountFields.includes("court_docs") || missingAccountFields.includes("medical_docs") ) && <p className={classes.WarningTextBold}>Missing Documents: 
                            {missingAccountFields.filter(field => field.includes('docs')).filter(field => missingAccountFields.includes(field)).map(field => humanize(raw[field]) ?? humanize(field)).join(', ')}</p>}</i></p> */}
                            <MaterialList 
                                list={newDocumentsData} 
                                primaryAction={handleDocumentDownload}
                                // selected={selected} setSelected={setSelected} 
                                table='view_documents_metadata' primaryField='short_name' secondaryFields={['file_type', 'created_at']} 
                            />
                            {/* {!adminFlag && <div className={classes.HeaderButtonRowRight}>
                                <button className="btn btn-success btn-block" onClick={() => {handleAddDocument();}}>Add Documents</button>
                            </div>} */}
                            {showAddDocument && <Fragment>
                                <hr/>
                                <FileUpload setFiles={setFiles} files={files} docTypes={generalDocTypes} useJSMimesNotCCSMimes/>    
                                {/* <button className='btn btn-block btn-success' onClick={handlePostDocument} >Submit</button> */}
                            </Fragment>
                            }                            
                            {/* <DocumentsTable documents={documentsData} /> */}
                        </div>
                        <hr/>
                        <button className={'btn btn-block btn-success'} onClick={() => submitFullChildForm()}>Submit</button>
                        {submitWarning}
                    </Fragment>}
                </div>}
            </Modal>  

            {/* emergency section */}
            <Modal tall show={editingSection === 'emergency'} modalClosed={() => setEditingSection()}>
                {/* {editingSection === 'emergency' && <div className={emergencyClasses.join(' ')}> */}
                {editingSection === 'emergency' && <div>
                    {/* <div className={classes.HeaderButtonRow}>
                        <button className="btn btn-success" onClick={() => {setShowAddNewPersonModal(true); setEditingSection();}}>Add New Person</button>
                    </div>    */}
                    <br/>               
                    <h4>Emergency Contacts</h4>
                    <hr/>
                    {missingAccountJsxObj?.[childGuardianMissingInfoFor]?.['Emergency Contact Details']}
                    <hr/>                
                    <button className="btn btn-info btn-block" onClick={() => {setShowAddNewPersonModal(true); setEditingSection();}}>Add New Emergency Contact</button>
                    
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <p style={{margin: 'auto'}}><i>or</i></p>
                    </div>
                    {/* <div className={emergencyGenericBorderClass.join(' ')} > */}
                    <div className={emergencySelectorClasses.join(' ')} >
                        {emergencyContactSelector}
                    </div>                
                    <br/>
                    {selectedEmergencyContactId && <Fragment>
                        <div className={innerSectionClasses.join(' ')}>
                            <h5>Authorisations</h5>
                            <br/>
                            <ChildGuardianAuthsForm 
                                childIds={childIds}
                                childrenData={childrenData}
                                handleUpdateAuthData={handleUpdateAuthData}
                                // updateChildGuardianAuthorisations={updateChildGuardianAuthorisations}
                                newAuthData={newECAuthData}
                                selectedAuthPersonId={selectedEmergencyContactId}
                                setNewAuthDataStateFn={setNewECAuthData}
                                submitWarning={submitWarning}
                                authPersonName={emergencyContactsData.filter(obj => obj.contact_id === +selectedEmergencyContactId)?.[0]?.first_name}
                            />
                        </div>                
                        <div className={innerSectionClasses.join(' ')}>
                            <h5>Relationships</h5>
                            <br/>
                            <ChildGuardianRelationshipsForm 
                                childIds={childIds}
                                childrenData={childrenData}
                                handleUpdateRelationshipData={handleUpdateRelationshipData}
                                // handleUpdateData={handleUpdateData}
                                newRelationshipData={newECRelationshipsData}
                                setNewRelationshipDataStateFn={setNewECRelationshipsData}
                                setLoading={setLoading}
                                submitWarning={submitWarning}
                                authPersonName={emergencyContactsData.filter(obj => obj.contact_id === +selectedEmergencyContactId)?.[0]?.first_name}
                            />
                        </div>                
                        <hr/>
                        <button className={'btn btn-block btn-success'} onClick={() => submitFullEmergencyForm()}>Submit</button>
                        {submitWarning}
                    </Fragment>}
                </div>}
            </Modal>
        </Fragment>
    );
}

export default ParentData;


const ChildGuardianAuthsForm = (props) => {

    // props
    const childIds = props.childIds
    const childrenData = props.childrenData;
    const handleUpdateAuthData = props.handleUpdateAuthData;
    // const updateChildGuardianAuthorisations = props.updateChildGuardianAuthorisations;
    const newAuthData = props.newAuthData;
    const selectedAuthPersonId = props.selectedAuthPersonId;
    const setNewAuthDataStateFn = props.setNewAuthDataStateFn;
    // const submitWarning = props.submitWarning;
    const authPersonName = props.authPersonName;
    
    let content;
    if (childrenData && newAuthData) {
        content = <Fragment>

            {
                childIds.map(childId => {
                    const child = childrenData.filter(obj => obj.child_id === childId)?.[0];
                    const childName = child?.first_name + ' ' + child?.surname;


                    return (
                        <Fragment key={childId}>

                            <h6>{childName}</h6>
                            <hr/>
                            <div>
                            {
                                // newECAuthData.filter(obj => obj.child_id === childId).map(obj => {
                                childGuardianAuthTypes.map(obj => {
                                    
                                    const authType = obj.value;
                                    const label = obj.label;
                                    const authDataRow = newAuthData.filter(obj => obj.child_id === childId && obj.authorisation === authType)?.[0];

                                    let classList = ["form-check"];
                                    if (!authDataRow) {
                                        classList.push(classes.Warning);
                                    }                                    

                                    return (
                                        <div key={childId + '-' + authType} className={classList.join(' ')} >
                                        <input checked={(authDataRow && !authDataRow.removeAuth) ? true : false} onChange={(e) => handleUpdateAuthData(e.target.checked, childId, authType, newAuthData, selectedAuthPersonId, setNewAuthDataStateFn)} className="form-check-input" type="checkbox"/>
                                        <label >{label}</label>
                                        </div>  
                                    )
                                })
                            }
                            </div>
                            <br/>

                        </Fragment>
                    )
                })
            }
            {/* <button className="btn btn-success btn-block" onClick={() => updateChildGuardianAuthorisations(newAuthData)}>Submit</button> */}
            {/* {submitWarning} */}
        </Fragment>    
    }

    return <div>
        <p><i>I authorise {authPersonName} to complete the following for:</i></p>
        {content}
    </div>
}

const ChildGuardianRelationshipsForm = (props) => {

    // props
    const childIds = props.childIds
    const childrenData = props.childrenData;
    const handleUpdateRelationshipData = props.handleUpdateRelationshipData;
    // const handleUpdateData = props.handleUpdateData;
    const newRelationshipData = props.newRelationshipData;
    const setNewRelationshipDataStateFn = props.setNewRelationshipDataStateFn;
    // const setLoading = props.setLoading;
    // const submitWarning = props.submitWarning;
    const authPersonName = props.authPersonName;
    
    let content;
    if (childrenData && newRelationshipData) {
        content = <Fragment>

            {
                childIds.map(childId => {
                    const child = childrenData.filter(obj => obj.child_id === childId)?.[0];
                    const childName = child?.first_name + ' ' + child?.surname;
                    const childRelationshipRow = newRelationshipData.filter(obj => obj.child_id === childId)?.[0];

                    // only render a relationship form for children this person has a relationship to

                    let classList = [classes.Row];
                    if (!childRelationshipRow?.relationship || childRelationshipRow?.relationship === '') {
                        // classList.push(classes.WarningBorder);
                        classList.push(classes.Warning, classes.SelectorPadding);
                    }
                    
                    return (
                        <Fragment key={childId}>

                            <h6>{childName}</h6>
                            <hr/>
                            <div className={classList.join(' ')}>
                                {/* <span className={classes.Item}><b>Relationship to Child</b></span>  */}
                                <select className='form-control' value={(childRelationshipRow?.relationship === "" || !childRelationshipRow?.relationship)? 'default' : childRelationshipRow?.relationship} onChange={(e) => handleUpdateRelationshipData(e.target.value, childId, newRelationshipData, setNewRelationshipDataStateFn)}>
                                    <option disabled value='default'>Select Relationship to Child</option>
                                    {childGuardianRelationshipTypes.map(obj => <option key={obj.label} value={obj.label}>{obj.label}</option>)}
                                </select>
                            </div>
                            <br/>

                        </Fragment>
                    )
                })
            }
            {/* <button className="btn btn-success btn-block" onClick={() => handleUpdateData('child_guardian_relationships', newRelationshipData, setLoading)}>Submit</button> */}
            {/* {submitWarning} */}
        </Fragment>

}

    return <div>
        <p><i>What is the relationship of {authPersonName} to:</i></p>
        {content}
    </div>
}











    // // --------------------------------------------------------------------------------------------------------------------------------
    // // warn user of any missing account data
    // let missingAccountUI;
    // let missingAccountCategories = [], missingAccountFields = [];
    // const missingAccountJsxObj = {};
    // if (missingAccountData?.length > 0) {
    //     missingAccountUI = (
    //         <div >
    //             <div className={classes.MissingDataTitle}>
    //                 <h3 >Account Information Missing</h3>
    //             </div>
    //             <br />

    //             {
    //                 missingAccountData.map(obj => {
                        
    //                     const childName = childrenData.filter(o => o.child_id === obj.child_id)?.[0]?.first_name;
    //                     const keys = Object.keys(obj);
    //                     const missingFields = keys.filter(key => obj[key] === true);
    //                     missingAccountJsxObj[childName] = {}
    //                     // console.log('missingFields: ', missingFields);
    //                     return (
    //                         <div key={obj.child_id + '-' + obj.centre_id} className={classes.MissingDataCard}>
    //                             <h5>{childName}</h5>
    //                             <hr/>
    //                             {
    //                                 Object.keys(accountDataGroupings).map((category, i) => {
        
    //                                     // console.log('category: ', category);
    //                                     const categoryFields = accountDataGroupings[category];
    //                                     // console.log('categoryFields: ', categoryFields);
    //                                     const categoryMissingFields = missingFields.filter(field => categoryFields.includes(field))
    //                                     // console.log('categoryMissingFields: ', categoryMissingFields);
                                        
            
    //                                     if (categoryMissingFields?.length > 0) {
    //                                         missingAccountCategories.push(category);
    //                                         missingAccountFields.push(...categoryMissingFields);
    //                                         const jsx = (
    //                                             <Fragment key={category}>
    //                                             <span style={{fontWeight: 'bold', color: 'red'}}>{category === 'Child Details' && childName  + ' - ' } Missing {category}</span>
    //                                                 <div style={{color: 'darkgrey', fontSize: '.7rem'}}><i>{categoryMissingFields.map(el => humanize(view_accounts_missing_fields[el] ?? el)).join(', ')}</i></div>
    //                                             </Fragment>
    //                                         );
    //                                         missingAccountJsxObj[childName][category] = jsx;
    //                                         return jsx
    //                                     } else return false
    //                                 })
    //                             }

    //                         </div>
    //                     );
                        
    //                 })
    //             }
                
    //         </div>
    //     );
    // }    

    // console.log('missingAccountJsxObj: ', missingAccountJsxObj);
    // console.log('missingAccountCategories: ', missingAccountCategories);
    // // console.log('missingAccountFields: ', missingAccountFields);
    // // console.log('newGuardianData: ', newGuardianData);
    // // --------------------------------------------------------------------------------------------------------------------------------