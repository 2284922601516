import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import { useHistory, useLocation } from "react-router-dom";
import Modal from '../../../../UI/Modal/Modal';
import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import SessionSignIns from '../../../Kiosk/SessionSignIns/SessionSignIns';
import classes from './DailyRoll.module.css';
import PhotoUpload from '../../../Kiosk/PhotoUpload/PhotoUpload';
import Banner from '../../../../UI/Banner/Banner';
import Headcounts from '../Headcounts/Headcounts';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
// import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';

const DailyRoll = (props) => {

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [uploadPhotoChildId, setUploadPhotoChildId] = useState();
    const [uploadPhotoCentreId, setUploadPhotoCentreId] = useState();
    const [bannerText, setBannerText] = useState();    

    // data state
    const [upcomingSessions, setUpcomingSessions] = useState();
    const [centresData, setCentresData] = useState();
    const [centreSessionData, setCentreSessionData] = useState();
    // const [emergencyContactData, setEmergencyContactData] = useState();
    const [childFlagsData, setChildFlagsData] = useState();
    const [headcountData, setHeadcountData] = useState();
    const [childrenData, setChildrenData] = useState();
    const [parentCloseModalsFlag, setParentCloseModalsFlag] = useState(true);
    // const [permissionSlipsData, setPermissionSlipsData] = useState();
    // const [showPermissionSlip, setShowPermissionSlip] = useState();
    const [selectedCentreName, setSelectedCentreName] = useState();

    // child specific data state
    const [childSummaryData, setChildSummaryData] = useState();
    const [childSpecificEmergencyContactData, setChildSpecificEmergencyContactData] = useState();
    const [childSpecificChildFlagsData, setChildSpecificChildFlagsData] = useState();

    // UI state
    const [showHeadcounts, setShowHeadcounts] = useState();


    // console logs
    // console.log('[DailyRoll.js] - emergencyContactData: ', emergencyContactData);
    console.log('[DailyRoll.js] - selectedCentreName: ', selectedCentreName);
    console.log('upcomingSessions length: ', upcomingSessions?.length);
    // console.log('childFlagsData: ', childFlagsData);
    // console.log('headcount data: ', headcountData);
    // console.log('[DailyRoll.js] - uploadPhotoCentreId: ', uploadPhotoCentreId);
    // console.log('permissionSlipsData: ', permissionSlipsData);

    // create react-router history object to navigate user to other pages
    const history = useHistory();    
    const location = useLocation();

    // get data from props
    const isMobile = props.isMobile;

    // generic function to get data and save to state
    const getMasterData = useCallback(async (tableName, stateFn, firstCall, lastCall, conditionCol, conditionValue) => {
        
        console.log(`fetching data for table ${tableName}, with conditions ${conditionCol} = ${conditionValue}`);

        firstCall ? setLoading(true) : void(0);

        if (tableName) {
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {table: tableName, conditionCol: conditionCol, conditionValue: conditionValue}
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log('data fetch res: ', res.data);
                stateFn(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }            

        }

        lastCall ? setLoading(false) : void(0);
    }, [history, props, location]);        

    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        console.log('[DailyRoll.js] - posting data: ', postData);
        try {
            const res = await axios.post(AWS_API + route, {postData: postData}, headers);
            console.log('res: ', res.data);
            onSuccessFn();
            handleBannerText(setBannerText, `Action Completed`);
            getUpcomingSessions(selectedCentreName);

            // toggle flag to trigger modal close in child component
            setParentCloseModalsFlag(!parentCloseModalsFlag);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
            setLoading(false);
        }    
    }     
    
    // function to get child summary info when child clicked on roll
    const getChildSummaryData = (childId) => {

        getMasterData('view_child_summary', setChildSummaryData, false, false, 'child_id', childId);        
        getMasterData('view_emergency_contacts_roll_summary', setChildSpecificEmergencyContactData, false, false, 'child_id', childId);   
        getMasterData('view_active_child_flags', setChildSpecificChildFlagsData, false, false, 'child_id', childId);   

    }

    const clearChildSummaryData = () => {
        setChildSummaryData();
        setChildSpecificChildFlagsData();
        setChildSpecificEmergencyContactData();
    }

    // write bookings to roll
    const writeBlockBooking = (bookingData, onSuccessFn) => {
        console.log('writing block booking for data: ', bookingData);
        postMasterData('master-data/add-roll-sessions-block', {...bookingData}, onSuccessFn)
    } 
    // function to call API to get upcoming sessions data to display on kiosk roll summary for checkins + to call API to get outstanding permission slips
    const getUpcomingSessions = useCallback(async (centreName) => {

        getMasterData('view_roll_summary_daily', setUpcomingSessions, true, true, 'centre_name', centreName);        
        // getMasterData('view_emergency_contacts_roll_summary', setEmergencyContactData, false, false);        
        getMasterData('view_centre_sessions_calendar', setCentreSessionData, false, false);        
        getMasterData('view_active_child_flags', setChildFlagsData, false, false);        
        
  
    }, [getMasterData]);

    // get centres data
    const getCentresData = useCallback(async () => {

        getMasterData('centres', setCentresData, true, true);        
  
    }, [getMasterData]);


  
    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {
        console.log('[DailyRoll.js] - SELECTED CENTRE USE EFFECT RUNNING');
        if (selectedCentreName) {
            console.log('[DailyRoll.js] - centre name found, fetching upcoming sessions for: ', selectedCentreName);
            getUpcomingSessions(selectedCentreName);
        }

    }, [getUpcomingSessions, selectedCentreName]);
  

    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {

        getCentresData();

    }, [getCentresData]);


  

    // function to update roll with check in, check out and absents
    const updateRoll = async (rollEntries, signedInUserId, updateType, onSuccessFn) => {
        console.log('checking child in for roll session, using authorised user ID: ', rollEntries, updateType);
        // return false;
        
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rollEntries: rollEntries,
            updateType: updateType
        };
        try {
            const res = await axios.post(AWS_API + 'kiosk/update-roll', {postData: postData}, headers);
            console.log('response: ', res.data);

            onSuccessFn ? onSuccessFn() : void(0);

            getUpcomingSessions(selectedCentreName);
            handleBannerText(setBannerText, `${updateType} marking recorded`);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while checking children in');
            setLoading(false);
        }         
    }

    // function to submit headcount event
    const submitRollEvent = async (rollEntries, event, onSuccessFn) => {
        console.log('submitting headcount event for: ', rollEntries);
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rollEntries: rollEntries,
            event: event
        };
        try {
            const res = await axios.post(AWS_API + 'master-data/record-event', {postData: postData}, headers);
            console.log('res: ', res.data);

            onSuccessFn ? onSuccessFn() : void(0);

            handleBannerText(setBannerText, `${event} recorded`);
            getUpcomingSessions(selectedCentreName);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording roll event ' + event);
            console.log('error whilst recording roll event: ', err);
            setLoading(false);
        }    
    }

    // function to get headcounts completed on given centre and day
    const getHeadcounts = async (centreId, date) => {
        setLoading(true);
        setShowHeadcounts(true);

        // upcoming sessions data
        let config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {centre_id: centreId, date: date.toLocaleDateString('sv-SW')}
        };
        console.log(`sending request to get headcounts data for centre: ${centreId} and date ${date.toLocaleDateString('sv-SW')}`);
        try {
            const res = await axios.get(AWS_API + 'master-data/day-centre-headcounts', config);
            console.log('headcounts data: ', res.data);
            setHeadcountData(res.data)
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching headcounts data');
        }   
    }

    
    // function to record roll as 'marked' in db for use in parent notifications
    const submitRollAsMarked = async (sessionDate, session, centre_id, status) => {

        setLoading(true);

        // upcoming sessions data
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {sessionDate: sessionDate, session: session, centre_id: centre_id, status: status};
        console.log('postData for submit DailyRoll as Marked: ', postData);
        try {
            const res = await axios.post(AWS_API + 'master-data/record-roll-marking-status', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, 'DailyRoll marked');
            getUpcomingSessions(selectedCentreName);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording roll as marked');
            console.log('error whilst setting roll as marked: ', err);
            setLoading(false);
        } 
        
        
    }      


    const handlePhotoUpload = (rollEntry, onSuccessFn) => {
        if (rollEntry && rollEntry.length === 1) {
            setUploadPhotoChildId(rollEntry[0].child_id);
            setUploadPhotoCentreId(rollEntry[0].centre_id);
            onSuccessFn ? onSuccessFn() : void(0);
        } else {
            setError('Photos can only be uploaded for one child at a time');
        }
    }

    const onPhotoUploadDone = () => {
        setUploadPhotoChildId();
        getUpcomingSessions(selectedCentreName);
    }

    // if staff adding a new child to roll, fetch children enrolled at selected centre
    const fetchAddNewChildrenData = async (centreId) => {
        getMasterData('view_child_enrolments', setChildrenData, false, false, 'centre_id', centreId);
    }



    
    console.log('uploadchildId: ', uploadPhotoChildId);


    let content = <SpinnerDark />;
    if (centresData?.length > 0) {
        content = <SessionSignIns 
        adminFlag 
        parentCloseModalsFlag={parentCloseModalsFlag} 
        headcountData={headcountData} showHeadcounts={showHeadcounts} setShowHeadcounts={setShowHeadcounts} getHeadcounts={getHeadcounts} submitRollEvent={submitRollEvent} submitRollAsMarked={submitRollAsMarked} setError={setError} 
        headcountAllowed checkinAllowed absentAllowed 
        uploadPhoto={handlePhotoUpload} token={props.token} multipleCentres 
        upcomingSessions={upcomingSessions} 
        childFlagsData={childFlagsData} 
        // emergencyContactData={emergencyContactData}
        updateRoll={updateRoll} 
        fetchAddNewChildrenData={fetchAddNewChildrenData} childrenData={childrenData} writeBlockBooking={writeBlockBooking} postMasterData={postMasterData} isMobile={isMobile}
        getChildSummaryData={getChildSummaryData}
        clearChildSummaryData={clearChildSummaryData}
        childSpecificChildFlagsData={childSpecificChildFlagsData}
        childSpecificEmergencyContactData={childSpecificEmergencyContactData}
        childSummaryData={childSummaryData}
        setSelectedCentreName={setSelectedCentreName}
        centresData={centresData?.sort((a,b) => {
            if ( a?.centre_name?.toLowerCase() < b?.centre_name?.toLowerCase() ) return -1;
            if ( a?.centre_name?.toLowerCase() > b?.centre_name?.toLowerCase() ) return 1;
            return 0;
        })}
        centreSessionData={centreSessionData}
        />;
        // (
            // <div>
            //     <button onClick={getUpcomingSessions} className='btn btn-outline-info btn-block'>Get Test</button>
            //     <button onClick={updateRoll} className='btn btn-outline-info btn-block'>Post Test</button>
            // </div>
            // ); 
    }
        

    return (
        <div className={classes.DailyRoll}>
            <Banner show={bannerText}>{bannerText}</Banner>    
            <Modal showOnTop show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>  
            <Modal tall show={uploadPhotoChildId} modalClosed={() => setUploadPhotoChildId()}>
                <PhotoUpload token={props.token} uploadPhotoChildId={uploadPhotoChildId} uploadPhotoCentreId={uploadPhotoCentreId} completed={onPhotoUploadDone} setBannerText={setBannerText}/>
            </Modal>  
            <Modal tall show={showHeadcounts} modalClosed={() => {setShowHeadcounts(false); setHeadcountData();}}>
                {headcountData && <Headcounts headcountData={headcountData} />}
            </Modal>    
            {loading && <BackdropSpinner spinner='small-light' />}           
            {content}
        </div>
    );
}

export default DailyRoll;