import React, { 
    Fragment,
    // useEffect,
    useCallback,
    useEffect,
    useState, 
    } from 'react'
import classes from './CRM.module.css';
import { useHistory, useLocation } from "react-router-dom";
import SubmissionsTable from '../SubmissionsTable/SubmissionsTable';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../../utilities/globalVariables';
import axios from 'axios';
import { arrayUniqueByKey, handleAwsApiError, handleBannerText } from '../../../../utilities/functions';
import Modal from '../../../../UI/Modal/Modal';
import Banner from '../../../../UI/Banner/Banner';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import BSTable from '../../../../UI/BSTable/BSTable';
import { bootstrapTableConfig, CrmAccountNoteTypes } from '../../../../utilities/globalObjects';
import SelectAccount from '../SelectAccount/SelectAccount';
// import Select from 'react-select'
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';


const CRM = (props) =>  {

    // data state
    const [mode, setMode] = useState('');
    const [viewingTable, setViewingTable] = useState('')
    const [submissionTableData, setSubmissionTableData] = useState();
    const [selectedAccount, setSelectedAccount] = useState();
    const [selectedAccountChildEnrolments, setSelectedAccountChildEnrolments] = useState();
    const [subtableData, setSubtableData] = useState();
    const [viewingSubtable, setViewingSubtable] = useState('')
    const [userInputFormData, setUserInputFormData] = useState({});
    const [selectedScheduledActivity, setSelectedScheduledActivity] = useState();
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();
    
    // Bootstrap table state
    const [selected, setSelected] = useState([]);      
    // const [selectedSubtable, setSelectedSubtable] = useState([]);

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    // data from props
    const accountData = props.accountData;
    
    // console logs
    console.log('[CRM.js] - mode: ', mode);    
    console.log('[CRM.js] - selectedAccount: ', selectedAccount);    
    // console.log('[CRM.js] - selectedAccountChildEnrolments: ', selectedAccountChildEnrolments);    
    console.log('[CRM.js] - selectedScheduledActivity: ', selectedScheduledActivity);    

    const getMasterData = useCallback(async (table, setStateFn, conditions) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);

    }, [history, props, location]);        

    const resetOnModeChange = () => {
        setSelectedAccount();
        setSelectedAccountChildEnrolments();
        setSelectedScheduledActivity();
        setSubmissionTableData();
        setSubtableData();
        setViewingSubtable();
        setUserInputFormData();
    }
    
    const resetOnAccountChange = () => {
        setSelectedAccount();
        setSelectedAccountChildEnrolments();
        setSelectedScheduledActivity();
        setSubtableData();
        setViewingSubtable();
        setUserInputFormData();

    }

    const handleModeChange = (newMode) => {

        // reset
        resetOnModeChange();
        
        if (newMode === 'upcoming-activites') {
            const table = 'view_crm_activities';
            getMasterData(table, setSubmissionTableData);
            
            setViewingTable(table);
            
        } 
        
        setMode(newMode);
    }
    
    const handleAccountChange = (rowId) => {
        resetOnAccountChange();

        const uniqueSelectId = bootstrapTableConfig[viewingTable]?.uniqueSelectId;
        const selectedRow = submissionTableData?.filter(obj => obj[uniqueSelectId] === rowId)?.[0];
        const accountId = selectedRow?.account_id;
        setSelectedAccount({value: accountId});
        setSelectedScheduledActivity(rowId);
        setSubtableData();
    }
    
    // any time account ID changes, fetch account notes
    useEffect(() => {
        if (selectedAccount?.value) {
            
            // fetch account notes
            const table = 'crm_account_notes';
            getMasterData(table, setSubtableData, {column: 'account_id', value: selectedAccount?.value});
            setViewingSubtable(table);
            
            // fetch account children data
            getMasterData('view_child_enrolments', setSelectedAccountChildEnrolments, {column: 'account_id', value: selectedAccount?.value});
            
        } 
    }, [selectedAccount, getMasterData])

    const postActivity = async (formData) => {
        
        setLoading(true);
        
        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            accountId: selectedAccount?.value,
            centreId: formData?.centreId,
            childId: formData?.childId,
            noteType: formData?.noteType,
            notes: formData?.notes,
            scheduledActivityId: selectedScheduledActivity
        };
        console.log('postData: ', postData);

        try {
            const res = await axios.post(AWS_API + 'master-data/record-crm-account-notes', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Recorded`);
            getMasterData('view_crm_activities', setSubmissionTableData);
            getMasterData('crm_account_notes', setSubtableData, {column: 'account_id', value: selectedAccount?.value});

            // resets on success
            setUserInputFormData();
            setSelected([]);
            setSelectedScheduledActivity();
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
        }            
        setLoading(false);
    }

    return (
        <div className={classes.CRM}>
            {loading && <BackdropSpinner spinner='small-light' />}
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Banner show={bannerText}>{bannerText}</Banner>  
            <div className={classes.HeaderButtonRow}>
                <button className="btn btn-secondary btn-sm" onClick={() => handleModeChange('upcoming-activites')}>View Scheduled Activities</button>
                <button className="btn btn-secondary btn-sm" onClick={() => handleModeChange('account-notes')}>View Account Notes</button>
            </div>  
            <br/>            
            { mode === 'account-notes' && <SelectAccount accountData={accountData} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount}/>}
            <hr />
            {submissionTableData?.length > 0 && <div className={classes.HeaderButtonRow}>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => selected?.length === 1 ? handleAccountChange(selected[0]) : alert('Please select just one account')}>Select Activity</button>
            </div>  }            
            {submissionTableData?.length > 0 &&  <SubmissionsTable table={viewingTable} selected={selected} setSelected={setSelected} data={submissionTableData}/>}
            <hr />
            {selectedAccountChildEnrolments?.length > 0 && <AccountNotesForm selectedAccountChildEnrolments={selectedAccountChildEnrolments} userInputFormData={userInputFormData} setUserInputFormData={setUserInputFormData} postActivity={postActivity}/>}
            {/* {subtableData?.length > 0 && <BSTable table={viewingSubtable} selected={selectedSubtable} setSelected={setSelectedSubtable} data={subtableData} />} */}
            {subtableData?.length > 0 && <BSTable table={viewingSubtable}  data={subtableData} />}
        </div>
    );
}

export default CRM;


const AccountNotesForm = (props) => {

    // get data from props
    const selectedAccountChildEnrolments = props.selectedAccountChildEnrolments;
    const userInputFormData = props.userInputFormData;
    const setUserInputFormData = props.setUserInputFormData;
    const postActivity = props.postActivity;

    // get already selected values which may impact other values in form
    const childId = userInputFormData?.childId;
    const centreId = userInputFormData?.centreId;

    // console.logs
    console.log('[AccountNotesForm] - userIN: ', userInputFormData);
    // console.log('[AccountNotesForm] - centreOptions: ', centreOptions);


    // set centre options for all centres account has a child enrolled at
    const centreOptions = [];
    selectedAccountChildEnrolments.filter(obj => childId ? obj.child_id === childId : true).forEach(obj => {centreOptions.push({value: obj.centre_id, label: obj.centre_name})});
    const uniqueCentreOptions = arrayUniqueByKey('centre_id', centreOptions);
    
    // set child options to log notes against
    const childOptions = [{value: undefined, label: 'General Account Note'}];
    selectedAccountChildEnrolments.filter(obj => centreId ? obj.centre_id === centreId : true).forEach(obj => {childOptions.push({value: obj.child_id, label: obj.child_name})});

    return (
        <Fragment>
            {/* <div className={classes.NoteInputContainer}>
                <div className={classes.NoteInput}>
                    <Select options={centreOptions} onChange={(e) => {setUserInputFormData({...userInputFormData, centreId: e.value})}} value={centreOptions.filter(obj => obj.value === centreId)?.[0]} ></Select>    
                </div>
                <div className={classes.NoteInput}>
                    <Select options={childOptions} onChange={(e) => {setUserInputFormData({...userInputFormData, childId: e.value})}} value={childOptions.filter(obj => obj.value === childId)?.[0]} ></Select>    
                </div>
            </div> 
            <br/>
            <textarea type='textarea' className='form-control' value={userInputFormData.notes ?? ''} onChange={(e) => setUserInputFormData({...userInputFormData, notes: e.target.value})}/> 
            <br/>
        <br/> */}
                <ParamsForm 
                    data={userInputFormData} 
                    setData={setUserInputFormData} 
                    config={[
                        {name: 'childId', type: 'react-select', options: childOptions, valueProperty: 'value'},
                        {name: 'centreId', type: 'react-select', options: uniqueCentreOptions, valueProperty: 'value'},
                        {name: 'noteType', type: 'react-select', options: CrmAccountNoteTypes, valueProperty: 'value'},
                        {name: 'notes', type: 'textarea'},
                    ]}
                    />            
                    <button className="btn btn-success btn-block" onClick={() => postActivity(userInputFormData)}>Submit New Note</button>
                    <br/>
        </Fragment>        
    );
}
