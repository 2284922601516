import React, { Component, Fragment } from 'react';

import classes from './Notifications.module.css';
import Backdrop from '../../../UI/Backdrop/Backdrop';
import {AiOutlineForm} from "react-icons/ai";
import {AiOutlineNotification} from "react-icons/ai";
import {FaFileSignature} from "react-icons/fa";
import {RiErrorWarningLine} from "react-icons/ri";
import {BiDetail} from "react-icons/bi";


class Notifications extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.show !== this.props.show || nextProps.children !== this.props.children);
    }


    

    render () {
        
        // get props
        const isMobile = this.props.isMobile;
        const notifications = this.props.notifications;
        
        const handleClicked = (onClickFn) => {
            try {
                onClickFn();
                this.props.close();
            } catch (e) {
                
            }
                
        }
        
        // set classes
        const classList = [classes.Notifications];

        if (isMobile) {
            classList.push(classes.NotificationsMobile);
        } else {
            classList.push(classes.NotificationsDesktop);
        }

        // render notifications
        const content = notifications?.map(obj => {

            // get icon if specified
            let icon;
            const iconSize = '1rem';
            const iconColor = 'darkslategrey';
            if (obj.icon === 'form') {
                icon = <AiOutlineForm size={iconSize} color={iconColor} />;
            } else if (obj.icon === 'signature') {
                icon = <FaFileSignature size={iconSize} color={iconColor} />;
            } else if (obj.icon === 'warning') {
                icon = <RiErrorWarningLine size={iconSize} color={iconColor} />;
            } else if (obj.icon === 'personal-details') {
                icon = <BiDetail size={iconSize} color={iconColor} />;
            } else {
                icon = <AiOutlineNotification size={iconSize} color={iconColor}/>;
            }

            // determine class
            const rowClasses = [classes.Notification];
            if (obj.type === 'action') {
                rowClasses.push(classes.Action)
            } else if (obj.type === 'approved') {
                rowClasses.push(classes.Approved)
            } else if (obj.type === 'pending') {
                rowClasses.push(classes.Pending)

            }

            return (
                <div key={obj.title + '-' + obj.description} className={rowClasses.join(' ')} onClick={() => handleClicked(obj.onClick)}>
                    {icon}
                    <div className={classes.TextContainer}>
                        <span className={classes.Title}>{obj.title}</span>
                        <span className={classes.description}>{obj.description}</span>                    
                    </div>
                </div>                
            );
        })
        
        // render block for each title
        // let content = [];
        // // get unique titles
        // const titles = notifications?.map(obj => obj.title)?.filter((x, i, a) => a.indexOf(x) === i);
        // titles?.forEach(title => {

        //     // get notifications under this title
        //     const nots = notifications.filter(obj => obj.title === title);



        //     content.push(
        //         <div key={title} className={classes.Title}>
        //             <h6>{title}</h6>
        //             <hr/>
        //             {nots.map(obj => 
        //                 <div className={classes.Notification} onClick={() => handleClicked(obj.onClick)}>
        //                     - {obj.description}
        //                 </div>
        //             )}
        //         </div>
        //     )
        // })

        return (
            <Fragment>
            <Backdrop show={this.props.show} clicked={this.props.close}/>
            <div className={classList.join(' ')}
                style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>
                <h6>Notifications</h6>
                <hr/>
                {content}
                {(!notifications || notifications?.length === 0) && <p><i>No notifications</i></p>}
            </div>
            </Fragment>
        );
    }

}
export default Notifications;