import { useState, Fragment } from 'react';
import Select from 'react-select';
import classes from './UpdateDetails.module.css';
import { handlePrompt } from '../../../../../utilities/functions';
import Prompt from '../../../../../UI/Prompt/Prompt';

const UpdateDetails = (props) => {

    // state
    const [selectedEmail, setSelectedEmail] = useState();
    const [newHomeCentreObj, setNewHomeCentreObj] = useState({});
    const [prompt, setPrompt] = useState({});      

    // get data from props
    const postMasterData = props.postMasterData;
    const allUserAccountsData = props.allUserAccountsData;
    const handleUpdateDetails = props.handleUpdateDetails;
    const deleteCognitoUser = props.deleteCognitoUser;
    const childrenData = props.childrenData;
    const centresData = props.centresData;

    // console logs
    // console.log('selectedEmail: ', selectedEmail);
    // console.log('childrenData: ', childrenData);
    // console.log('centresData: ', centresData);
    console.log('newHomeCentreObj: ', newHomeCentreObj);
    
    let emailSelector = <i>Waiting for account data...</i>;

    const handleUpdateHomeCentreState = (childId, newObj) => {
        console.log(`childId ${childId}, newObj ${JSON.stringify(newObj)}`)
        setNewHomeCentreObj({
            ...newHomeCentreObj,
            [childId]: newObj.value
        })
    }

    const postUpdatedHomeCentre = (childId) => {
        const newHomeCentre = newHomeCentreObj?.[childId];
        console.log('newHomeCentre: ', newHomeCentre);
        postMasterData('master-data/update-home-centre', {postData: {childId: childId, centreId: newHomeCentre}}, undefined, ['children'])
    }

    if (allUserAccountsData?.length > 0) {
        const emailOptions = [];
        allUserAccountsData.forEach(obj => {emailOptions.push({value: obj.email, label: obj.email})})
        emailSelector = <Select options={emailOptions} onChange={setSelectedEmail} value={selectedEmail}></Select>   
    }

    const centreOptions = centresData?.map(obj => ({value: obj.centre_id, label: obj.centre_name}));
    let childHomeCentreSelect;
    if (childrenData?.length > 0) {
        childHomeCentreSelect = childrenData.map(obj => {

            const childHomeCentre = newHomeCentreObj?.[obj?.child_id] ?? obj?.home_centre_id;
            const reactSelectObj = {value: childHomeCentre, label: centresData.filter(obj => obj.centre_id === childHomeCentre)?.[0]?.centre_name ?? 'Centre Name Not Found'};

            return (
                <div className={classes.FlexRow}>
                    <label className={[classes.FlexRowItem, classes.Label].join(' ')}>{obj?.first_name + ' ' + obj?.surname}</label>
                    <div className={classes.FlexRowItem}>
                        <Select options={centreOptions} onChange={(e) => handleUpdateHomeCentreState(obj?.child_id, e)} value={reactSelectObj}></Select>
                    </div>
                    <div className={classes.FlexRowItem}>
                        <button className='btn btn-success btn-block' onClick={() => postUpdatedHomeCentre(obj.child_id)}>Update</button>
                    </div>
                </div>
            )
        })
    }

    return (
        <Fragment>
            <Prompt data={prompt} setData={setPrompt} />
            <div className={classes.Tile}>
                <h5>Account Preferences</h5>
                <hr/>
                <div className={classes.Subtitle}>Update Email</div>
                <br />
                {emailSelector}
                <br />
                <button className='btn btn-success btn-block' onClick={() => {handleUpdateDetails(selectedEmail?.value)}}>Update</button>
                <br/>
                <div className={classes.Subtitle}>Reset Cognito User</div>
                <br />
                <button className='btn btn-danger btn-block' onClick={() => handlePrompt(`reset user's login details`, deleteCognitoUser, setPrompt, 'Note: This action cannot be undone')}>Reset User</button>
            </div>
            <div className={classes.Tile}>
                <h5>Child Preferences</h5>
                <hr/>
                <div className={classes.Subtitle}>Update Home Centres</div>
                <br />
                {childHomeCentreSelect}
            </div>
        </Fragment> 

    )
}

export default UpdateDetails;