export const raw = {
    "bsc": "Before School Care",
    "asc": "After School Care",
    "vac": "Vacation Care",
    "perm_bookings": "Scheduled Booking",
    "casual_bookings": "Casual Booking",
    "admin_roll_add": "Casual Booking",
    "enrolNewChild": "Enrol New Child",
    "bulkEnrol": "Bulk Enrol",
    "updateBookingSchedule": "Update Schedule",
    "enrol": "Enrol",
    "approved-enrol": "Approved Enrolments",
    "pending-enrol": "Pending Enrolment IDs",
    "pending-adminEnrol": "Pending Admin Approved",
    "enrolmentFeedback": "Enrolment Feedback",
    "permissionSlip": "Permission Slip",
    "incidentRecord": "Incident Record",
    "dietary_restrictions_detail": "Dietary Restrictions Detail",
    "auth_transport": "Transport Permissions",
    "auth_evacuation": "Evacuation Permissions",
    "auth_sunscreen": "Sunscreen Permissions",
    "auth_first_aid": "First Aid Permissions",
    "auth_ambulance": "Ambulance Permissions",
    "auth_panadol": "Permission to administer Panadol",
    "diagnosed_condition_detail": "Diagnosed Condition Detail",
    "photo_permissions_internal": "Photo Sharing Allowed Inside Centre",
    "photo_permissions_external": "Photo Sharing Allowed Outside Centre",
    "court_orders_1": "Court Orders - Authority",
    "court_orders_2": "Court Orders - Residence",
    "immunisations": "Immunisations Up-to-Date",
    "special_considerations": "Special Considerations to Take Into Account", 
    "timezone": "Timezone",
    "bsc_session_cutoff_hours": "BSC Booking Cutoff Hours",
    "asc_session_cutoff_hours": "ASC Booking Cutoff Hours",
    "vac_session_cutoff_hours": "VAC Booking Cutoff Hours",
    "bsc_session_late_surcharge_hours": "BSC Late Surcharge Cutoff Hours",
    "asc_session_late_surcharge_hours": "ASC Late Surcharge Cutoff Hours",
    "vac_session_late_surcharge_hours": "VAC Late Surcharge Cutoff Hours",
    "bsc_accidental_cancellation_window_hours": "BSC Accidental Cancellation Hours",
    "asc_accidental_cancellation_window_hours": "ASC Accidental Cancellation Hours",
    "vac_accidental_cancellation_window_hours": "VAC Accidental Cancellation Hours",
    "booking_cancellation_window_hours": "Booking Cancellation Cutoff Hours",
    "crn": "CRN",
    "dob": "Date of Birth",
    "road": "Street",
    "at_risk": "At Risk",
    "guardian_first_name": "Guardian First Name",
    "guardian_surname": "Guardian Surname",
    "preferred_contact": "Preferred Phone Number",
    "additional_contact": "Additional Phone Number",
	"med_prac_name": "Medical Practice - Name", 
	"med_prac_phone": "Medical Practice - Phone",
	"med_address_house_number": "Medical Practice - House Number",
	"med_address_road": "Medical Practice - Street",
	"med_address_postcode": "Medical Practice - Postcode",
	"med_address_suburb": "Medical Practice - Suburb",
	"med_address_state": "Medical Practice - State",
	"med_address_country": "Medical Practice - Country",
    "cultural_background": "Is Your Child of Aboriginal and/or Torres Island Descent",
    'medicare_child_irn': 'Reference Number of Child on Card',
    'account_incomplete': 'Account Data Incomplete',
    'confirmed': 'Confirmed',
    'absent': 'Absent',
    'waitlist': 'Waitlist',
    'recurring_roll': 'Repeating',
}

export const view_accounts_missing_fields = {
    'c_first_name': 'child_first_name',
    'c_surname': 'child_surname',
    'c_gender': 'child_gender',
    'c_country_of_birth': 'child_country_of_birth',
    'c_language_spoken_at_home': 'child_language_spoken_at_home',
    'c_crn': 'child_crn',
    'c_current_school_year': 'child_current_school_year',
    'c_cultural_background': 'child_cultural_background',
    'c_address': 'child_address',
    'c_med_prac_name': 'child_medical_practice_name',
    'c_med_prac_phone': 'child_medical_practice_phone',
    'c_med_address': 'child_medical_practice_address',
    'c_med_address_house_number': 'child_medical_practice_address_house_number',
    'c_med_address_road': 'child_medical_practice_address_street',
    'c_med_address_postcode': 'child_medical_practice_address_postcode',
    'c_med_address_suburb': 'child_medical_practice_address_suburb',
    'c_med_address_state': 'child_medical_practice_address_state',
    'c_med_address_country': 'child_medical_practice_address_country',
    'pg_first_name': 'primary_guardian_first_name',
    'pg_surname': 'primary_guardian_surname',
    'pg_gender': 'primary_guardian_gender',
    'pg_preferred_contact': 'primary_guardian_preferred_contact',
    'pg_language': 'primary_guardian_language',
    'pg_crn': 'primary_guardian_crn',
    'pg_nationality': 'primary_guardian_nationality',
    'pg_house_number': 'primary_guardian_house_number',
    'pg_road': 'primary_guardian_street',
    'pg_postcode': 'primary_guardian_postcode',
    'pg_suburb': 'primary_guardian_suburb',
    'pg_relationship': 'primary_guardian_relationship',
    'sg_first_name': 'secondary_guardian_first_name',
    'sg_surname': 'secondary_guardian_surname',
    'sg_gender': 'secondary_guardian_gender',
    'sg_preferred_contact': 'secondary_guardian_preferred_contact',
    'sg_language': 'secondary_guardian_language',
    'sg_crn': 'secondary_guardian_crn',
    'sg_nationality': 'secondary_guardian_nationality',
    'sg_house_number': 'secondary_guardian_house_number',
    'sg_road': 'secondary_guardian_street',
    'sg_postcode': 'secondary_guardian_postcode',
    'sg_suburb': 'secondary_guardian_suburb',
    'sg_relationship': 'secondary_guardian_relationship',
    'ec_first_name': 'emergency_contact_first_name',
    'ec_surname': 'emergency_contact_surname',
    'ec_address_house_number': 'emergency_contact_address_house_number',
    'ec_address_road': 'emergency_contact_address_street',
    'ec_address_postcode': 'emergency_contact_address_postcode',
    'ec_address_state': 'emergency_contact_address_state',
    'ec_contact_number': 'emergency_contact_contact_number',
    'ec_relationship': 'emergency_contact_relationship',
    'c_diagnosed_condition_detail': 'child_diagnosed_condition_detail',
    'c_dietary_restrictions_detail': 'child_dietary_restrictions_detail',
    'c_school_child_attends': 'child_school_child_attends',
    'c_medicare_number': 'child_medicare_number',
    'c_medicare_child_irn': 'Reference Number of Child on Card',
    'c_dob': 'child_dob',
    'c_medicare_expiry_date': 'child_medicare_expiry_date',
    'pg_dob': 'primary_guardian_dob',
    'sg_dob': 'secondary_guardian_dob',
    'auth_transport': 'transport_authorisation',
    'auth_evacuation': 'evacuation_authorisation',
    'auth_sunscreen': 'sunscreen_authorisation',
    'auth_panadol': 'panadol_authorisation',
    'auth_ambulance': 'ambulance_authorisation',
    'auth_first_aid': 'first_aid_authorisation',
    'auth_liaise': 'liaise_authorisation',
    'auth_medical': 'medical_authorisation',
    'immunisation_docs': 'immunisation_docs',
    'photo_permissions_internal': 'photo_permissions_internal',
    'photo_permissions_external': 'photo_permissions_external',
    'c_ambulance_cover': 'child_ambulance_cover',
    'c_diagnosed_condition': 'child_diagnosed_condition',
    'c_dietary_restrictions': 'child_dietary_restrictions',
    'c_immunisations': 'child_immunisations',
    'c_court_orders_1': 'child_court_orders_1',
    'c_court_orders_2': 'child_court_orders_2',
    'pg_child_authorisations': 'primary_guardian_child_authorisations',
    'pg_collection': 'primary_guardian_collection',
    'pg_emergency': 'primary_guardian_emergency',
    'pg_medical': 'primary_guardian_medical',
    'pg_excursion': 'primary_guardian_excursion',
    'pg_transport': 'primary_guardian_transport',
    'sg_child_authorisations': 'secondary_guardian_child_authorisations',
    'sg_collection': 'secondary_guardian_collection',
    'sg_emergency': 'secondary_guardian_emergency',
    'sg_medical': 'secondary_guardian_medical',
    'sg_excursion': 'secondary_guardian_excursion',
    'sg_transport': 'secondary_guardian_transport',
    'ec_child_authorisations': 'emergency_contact_child_authorisations',
    'ec_collection': 'emergency_contact_collection',
    'ec_emergency': 'emergency_contact_emergency',
    'ec_medical': 'emergency_contact_medical',
    'ec_excursion': 'emergency_contact_excursion',
    'ec_transport': 'emergency_contact_transport',
    'medical_docs': 'medical_docs',
    'court_docs': 'court_docs',    
};