import { Fragment, useEffect } from "react";
import { useState } from "react";
import Select from 'react-select'
import classes from './EndSchedule.module.css';

const EndSchedule = (props) => {

    // data state
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedCentre, setSelectedCentre] = useState();
    const [endScheduleDate, setEndScheduleDate] = useState();

    // get data from props
    const childrenData = props.childrenData;
    const centresData = props.centresData;
    const postSubmission = props.postSubmission;
    const alreadySelectedChildren = props.alreadySelectedChildren;

    // console
    console.log('[EndSchedule] - selectedChildren: ', selectedChildren);
    
    // create child options array
    let addChildrenForm;
    if (childrenData) {
        // create options for multi select
        const childOptions = [];
        childrenData.forEach(obj => {childOptions.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})})
        addChildrenForm = (
                <div> 
                    <div  className="form-group" >
                        <label className={classes.Label}>Select Children</label>
                        <Select isMulti options={childOptions} onChange={setSelectedChildren} value={selectedChildren} ></Select>    
                    </div>  
                </div>         
        );

    }    

    // upon component load, add already ticked children to selected children
    useEffect(() => {

        // pre-selected children
        if (alreadySelectedChildren && childrenData) {

            // get value and label for selectedChildren object
            const newSelectedChildren = [];
            const filteredChildrenData = childrenData.filter(obj => alreadySelectedChildren.includes(obj.child_id));
            // console.log('filteredChildrenData: ', filteredChildrenData);
            filteredChildrenData.forEach(obj => {newSelectedChildren.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})});
            setSelectedChildren(newSelectedChildren);
        }


    }, [alreadySelectedChildren, childrenData]);

    // if selectedChildren array has > 0 selected children, look for a shared home centre - otherwise remove home centre
    let selectedHomeCentreIds = null;
    const selectedChildIds = selectedChildren.map(obj => obj.value);
    if (selectedChildIds.length > 0) {
        selectedHomeCentreIds = childrenData.filter(obj => selectedChildIds.includes(obj.child_id)).map(obj => obj.home_centre_id).filter((x,i,a) => a.indexOf(x) === i);
        console.log('selectedHomeCentreIds: ', selectedHomeCentreIds);
        
        // if there's only unique centre id for all children, add it to lookup array to allow booking, otherwise user will have to select only children who share a home centre
        if (selectedHomeCentreIds.length === 1) {
            const homeCentreId = selectedHomeCentreIds[0];
            if (selectedCentre !== homeCentreId) setSelectedCentre(homeCentreId);
            
        } else {
            if (selectedCentre) setSelectedCentre('');
        }
    } else {
        if (selectedCentre) setSelectedCentre('');
    }    

    const clear = () => {
        setSelectedCentre();
        setSelectedChildren([]);
        setEndScheduleDate();
    }


    
    const handleEndSchedule = () => {
        console.log(`ending schedule for child IDs ${selectedChildren}, date ${endScheduleDate}`)
        
        // ensure one and only one centre selected before allowing submission
        if (!selectedCentre) {
            alert('No centre found - please ensure selected children have the same home centre');
            return false;
        }

        // convert final day of schedule to start date of empty schedule
        const date = new Date(endScheduleDate);
        console.log('endScheduleDate: ', endScheduleDate);
        console.log('date: ', date);

        
        // ensure date in the future
        if (date?.getTime() < new Date().getTime()) {
            alert('Date must be in the future');
            return false;
        }
        
        // ensure date populated
        if (!date || !(date?.getTime() >= new Date().getTime())) {
            alert('Please select the final date you would like for your current schedule');
            return false;
        }

        // Increment the date by 1 day
        date.setDate(date.getDate() + 1);

        // Extract the components of the new date
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        // Format the new date string
        const newDateString = `${year}-${month}-${day}`;
        console.log('newDateString: ', newDateString)

        // populate empty schedule form for selected children and end schedule date to send to existing schedule update backend function
        const endScheduleForm = {
            "childIds": selectedChildIds,
            "centreId": selectedCentre,
            "startDate": newDateString,
            "iRequireAFortnightlyBookingSchedule": "no",
            "weekly": {
                "mon1": {
                    "bsc": false,
                    "asc": false
                },
                "tue1": {
                    "bsc": false,
                    "asc": false
                },
                "wed1": {
                    "bsc": false,
                    "asc": false
                },
                "thu1": {
                    "bsc": false,
                    "asc": false
                },
                "fri1": {
                    "bsc": false,
                    "asc": false
                }
            },
        };
        postSubmission(endScheduleForm, clear);
    }
        
    console.log('selectedChildIds: ', selectedChildIds);

    // create array showing only child's home centre
    const centreOptions = centresData.filter(obj => obj.centre_id === selectedCentre).map(obj => {
        return (
            <option  value={obj.centre_id} key={obj.centre_id} >{obj.centre_name}</option>
        );
    });
    const centreSelector = <div className="form-group">
        <label>Centre</label>
        <select className="form-control" disabled id="centreOptions" value={selectedCentre} onChange={(e) => {setSelectedCentre(+e.target.value)}}>
            {centreOptions}
        </select>
    </div>    

    return (
        <Fragment>
            <div className="form-group">
                {addChildrenForm}
            </div>   
            {centreSelector}           
            <div className="form-group">
                <label>Final Day of Schedule</label>
                <input type='date' 
                    className="form-control" 
                    placeholder="Select Last Day of Schedule"
                    value={endScheduleDate} 
                    format='YYYY-MM-DD' 
                    onChange={(e) => setEndScheduleDate(e.target.value)}
                />
            </div>
            <button onClick={handleEndSchedule} className="btn btn-block btn-danger">End All Schedules</button>
        </Fragment>
    );
}

export default EndSchedule;