import {Fragment, React, useState} from "react";
import classes from './Layout.module.css';
import Toolbar from "../../UI/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../UI/Navigation/SideDrawer/SideDrawer";
import Logo from "../../UI/Logo/Logo";
import Notifications from "./Notifications/Notifications";
import NotificationIcon from "./Notifications/NotificationIcon/NotificationIcon";
import {BsFillCalendarEventFill} from "react-icons/bs";
import {IoIosContacts} from "react-icons/io";
import {MdSchedule} from "react-icons/md";
import {FaFileInvoiceDollar} from "react-icons/fa";
import {GiReceiveMoney} from "react-icons/gi";
import {VscWorkspaceUnknown} from "react-icons/vsc";
import {FaSave} from "react-icons/fa";
import {FiDownload} from "react-icons/fi";
import {FaFileImport} from "react-icons/fa";
import {GrContact} from "react-icons/gr";

export default function Layout(props) {

    const [showSideDrawer, setShowSideDrawer] = useState(false);


    const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
    }

    const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
    }

    // get data from props
    const sublinks = props.sublinks;
    const notifications = props.notifications;


    // mobile responsiveness
    const isMobile = props.isMobile;

    // determine whether bars are needed
    const showMobileFooter = (isMobile && (sublinks?.length > 0 || notifications?.length > 0));
    const showLeftBar = (!isMobile && (sublinks?.length > 0));
  
    // determine body container (padded left on big screen)
    let bodyClasses = [classes.Body];
    if (showLeftBar) {
        bodyClasses.push(classes.LeftBarPadding);
    }

    // notifications to display
    const actionNotificationsNumber = notifications?.filter(obj => obj.type === 'action')?.length;

    // console logs
    console.log('[Layout.js] - sublinks: ', sublinks);
    console.log('[Layout.js] - notifications: ', notifications);
    console.log('[Layout.js] - actionNotificationsNumber: ', actionNotificationsNumber);

    return (
        <div className={classes.Layout}>
            <Toolbar 
                role={props.role}
                email={props.email}
                drawerToggleClicked={sideDrawerToggleHandler}
                signout={props.signout}
                actionNotificationsNumber={actionNotificationsNumber}
                notifications={notifications}
                showLeftBar={showLeftBar}
            />

            <SideDrawer 
                closed={sideDrawerClosedHandler} 
                open={showSideDrawer}
                showInstallPrompt={props.showInstallPrompt}
                showInstallInstruct={props.showInstallInstruct}
                accepted={props.accepted}
                dismissed={props.dismissed}        
                role={props.role}
                email={props.email}
                signout={props.signout}
            />

            {showLeftBar && <LeftBar actionNotificationsNumber={actionNotificationsNumber} sublinks={sublinks} />}
            <main className={bodyClasses.join(' ')}>
                {props.children}

            </main>

            {showMobileFooter && <MobileFooter notifications={notifications} actionNotificationsNumber={actionNotificationsNumber} sublinks={sublinks}/>}

        </div>
  )
}


const LeftBar = (props) => {

    // toggle hidden state
    const [showCollapsed, setShowCollapsed] = useState(false);
    
    const sublinks = props.sublinks;
    
    const mapLinkToJSX = (link, collapsed) => {
        // determine link classes
        const classList = ['btn', 'btn-block', 'position-relative', 'CustomButton'];
        
        if (link.type === 'outline-secondary' || collapsed) {
            classList.push('btn-outline-secondary')
            
        } else if (link.type === 'action') {
            classList.push('btn-outline-danger')
        } else {
            classList.push('CustomButtonPrimary')
        }

        // determine badge
        let badge = link?.badge;

        // determine badge classes
        let badgeClasses = ["position-absolute", "badge", "rounded-pill", "bg-danger", classes.TopRightBadge].join(' ');
        // console.log('badge: ', badge);
        // if (+badge === 0) {
        //     badgeClasses = ["position-absolute", "badge", "rounded-pill", classes.TopRightBadge].join(' ');
        // }

        return (
            <Fragment key={link.title}>
                <button key={link.title} type="button" className={classList.join(' ')} onClick={link.onClick}>{link.title}
                {badge && badge > 0 && <span className={badgeClasses}>
                    {/* {badge > 0 ? badge : ''} */}
                    <span style={{color: 'white'}}>{badge}</span>
                </span>}
                </button>
            </Fragment>
        )
    }
    
    // links to always show
    const shownLinksArray = sublinks.filter(link => !link.collapsed);

    // split links into those that should appear below a title header and those that shouldn't
    const headerLinks = shownLinksArray.filter(link => link.header);
    const nonHeaderLinks = shownLinksArray.filter(link => !link.header);
    
    // create JSX for links to always show which appear under a header
    const headers = headerLinks.map(link => link.header).filter((x, i, a) => a.indexOf(x) === i);
    const shownHeaderLinks = headers.map(header => {
        const filteredLinks = headerLinks.filter(link => link.header === header);
        return (
            <div key={header} className={classes.LinkHeaderContainer}>
                <h6 className={classes.LinkHeader}>{header}</h6>
                {filteredLinks.map(link => mapLinkToJSX(link))}
                <hr />
            </div>
        )
    })
    
    
    // create JSX for links to always show which don't appear under a header
    const shownLinks = nonHeaderLinks.map(link => mapLinkToJSX(link));
    
    
    // if there are links to collapse, show a toggle button
    const collapsedLinksArray = sublinks.filter(link => link.collapsed);
    const toggleCollapseButton = collapsedLinksArray?.length > 0 ? <button className={['btn', 'btn-block', 'position-relative', 'CustomButton', 'btn-outline-secondary'].join(' ')} onClick={() => setShowCollapsed(!showCollapsed)}>▼ More Options</button> : null;
    const collapsedLinks = showCollapsed ? collapsedLinksArray.map(link => mapLinkToJSX(link, true)) : null;

    return (
        <div className={classes.LeftBar}>
            
            <div className={classes.Logo}>
                <Logo />
                
            </div>    
            {shownHeaderLinks}        
            {shownLinks}
            {toggleCollapseButton}
            {collapsedLinks}
        </div>
    )    
}


const MobileFooter = (props) => {
    
    // state for mobile
    const [showNotifications, setShowNotifications] = useState();
    const [activeLink, setActiveLink] = useState();

    const sublinks = props.sublinks;
    const actionNotificationsNumber = props.actionNotificationsNumber;
    const notifications = props.notifications;

    // get links to render with icon on mobile nav bar
    const mobileLinks = sublinks?.filter(link => link.icon);
    

    const links = mobileLinks?.map(link => {

        // determine icon
        const iconSize = '1.5rem';
        const linkClasses = [classes.MobileSubLink];
        if (activeLink === link.title) {
            linkClasses.push(classes.ActiveLink);
        }        

        let icon;
        if (link.icon === 'calendar') {
            icon = <BsFillCalendarEventFill size={iconSize}  />
        } else if (link.icon === 'contactInfo') {
            icon = <IoIosContacts size={iconSize} />
        } else if (link.icon === 'invoice') {
            icon = <FaFileInvoiceDollar size={iconSize}  />
        } else if (link.icon === 'schedule') {
            icon = <MdSchedule size={iconSize}  />
        } else if (link.icon === 'subsidy') {
            icon = <GiReceiveMoney size={iconSize}  />
        } else if (link.icon === 'save') {
            icon = <FaSave size={iconSize}  />
        } else if (link.icon === 'load') {
            icon = <FiDownload size={iconSize}  />
        } else if (link.icon === 'question') {
            icon = <VscWorkspaceUnknown size={iconSize}  />
        } else if (link.icon === 'contactUs') {
            icon = <GrContact size={iconSize}  />
        } else if (link.icon === 'import') {
            icon = <FaFileImport size={iconSize}  />
        } 
        // if no icon specified, assume link not to be rendered on mobile taskbar
        // else {
        //     icon = <VscWorkspaceUnknown size={iconSize} color={iconColor} />
        // }


 
        return(
            <div key={link.title} className={linkClasses.join(' ')} onClick={() => {link.onClick(); setActiveLink(link.title);}}>
            {/* <div key={link.title} className={classes.MobileSubLink} onClick={link.onClick}> */}
                {icon}
                <div>{link.shortName}</div>           
            </div>
        );
    })
    
    return (
        <footer className={classes.MobileFooter}>
            <Notifications notifications={notifications} isMobile={true} show={showNotifications} close={() => setShowNotifications(false)}/>
            {links}
            <div className={classes.FooterNotificationIconContainer}>
                <NotificationIcon actionNotificationsNumber={actionNotificationsNumber} setShowNotifications={setShowNotifications} /> 
            </div>
        </footer>
    )    
}

