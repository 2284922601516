import classes from './NewInvoiceCreditForm.module.css';
import { useState, useEffect, Fragment } from 'react';
import { invoiceDescriptionOptions, xeroInvoiceStatusTypes, creditNoteDescriptionOptions } from '../../../../../utilities/globalObjects';
import { humanize } from '../../../../../utilities/functions';
import ParamsForm from '../../../../../UI/ParamsForm/ParamsForm';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const NewInvoiceCreditForm = (props) => {

    // state
    // const [transactionType, setTransactionType] = useState('debit');
    const [data, setData] = useState({status: 'AUTHORISED'});
    const [tab, setTab] = useState('credit');
    
    // data from props
    const selectedAccountObj = props.selectedAccountObj;
    const filteredCentres = props.filteredCentres;
    const submitDataFn = props.submitDataFn;

    // console logs
    // console.log('[NewInvoiceCreditForm] - selectedAccountObj: ', selectedAccountObj);
    // console.log('[NewInvoiceCreditForm] - data: ', data);
    // console.log('[NewInvoiceCreditForm] - tab: ', tab);

    // create centre options
    const centreOptions = filteredCentres?.map(obj => ({value: obj.centre_name, label: obj.centre_name}));

    // set default values for which there's only one options
    useEffect(() => {
        if (centreOptions?.length === 1 && !data?.centreName) {
            setData({
                ...data,
                centreName: centreOptions?.[0]?.value
            });
        }
    }, [centreOptions, data]);
    
    // reset data if account changes
    useEffect(() => {
        setData({status: 'AUTHORISED'});
    }, [selectedAccountObj]);


    // function to post data
    const handlePostData = () => {
        
        // data transforms

        // concat description
        const dataToPost = {
            ...data,
            reference: data?.description,
            description: (data?.description ?? '') + ' ' + (data?.extraDescription ?? '')
        };
        console.log('dataToPost: ', dataToPost);
        submitDataFn(dataToPost);

    }

    // class config
    let headerClass;
    if (tab === 'debit') {
        headerClass = classes.DebitHeader
    } else if (tab === 'credit') {
        headerClass = classes.CreditHeader
    }

    // set up form based on transaction type
    let config = [
        {name: 'transactionType', type: 'text', value: tab},
        {name: 'xeroContactId', type: 'text', value: selectedAccountObj?.xero_contact_id},
        {name: 'accountId', type: 'number', value: selectedAccountObj?.account_id},
        {name: 'centreName', type: 'react-select',  options: centreOptions, valueProperty: 'value'},
        {name: 'description', type: 'react-select',  options: invoiceDescriptionOptions, valueProperty: 'value'},
        {name: 'extraDescription', type: 'text'},
        {name: 'amount', type: 'number'},
        {name: 'status', type: 'react-select',  options: xeroInvoiceStatusTypes, valueProperty: 'value'},
        {name: 'dueDate', type: 'date'},
    ];

    if (tab === 'credit') {
        config = [
            {name: 'transactionType', type: 'text', value: tab},
            {name: 'xeroContactId', type: 'text', value: selectedAccountObj?.xero_contact_id},
            {name: 'accountId', type: 'number', value: selectedAccountObj?.account_id},
            {name: 'centreName', type: 'react-select',  options: centreOptions, valueProperty: 'value'},
            {name: 'description', type: 'react-select',  options: creditNoteDescriptionOptions, valueProperty: 'value'},
            {name: 'extraDescription', type: 'text'},
            {name: 'amount', type: 'number'},
            {name: 'status', type: 'react-select',  options: xeroInvoiceStatusTypes, valueProperty: 'value'},
        ];
    }

    
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        // clear form data specific to type
        setData({})
    };

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    // TabIndicatorProps={{style: {background:'#F5881F', color: 'black'}}}
                    value={tab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="secondary tabs example"
                >
                    <Tab value="credit" label={<span>Credit</span>} />
                    <Tab value="debit" label={<span>Debit</span>} />
                </Tabs>
            </Box>     
            <br/>         
            <h3 className={headerClass}>New {humanize(tab)} - {selectedAccountObj?.primary_name}</h3>
            <hr/>
            <ParamsForm 
                data={data}
                setData={setData}
                config={config}
            />
            <button className="btn btn-success btn-block" onClick={handlePostData}>Submit {humanize(tab)}</button>
        </Fragment>
    )


}

export default NewInvoiceCreditForm;