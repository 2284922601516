import React, { useState, useCallback, useEffect, Fragment } from 'react'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import classes from './SessionCalendar.module.css';
import Modal from '../../../../UI/Modal/Modal';
import Aux from '../../../../hoc/Auxiliary/Auxiliary';
import { raw } from '../../../../translations/en/raw';
import { useHistory, useLocation } from "react-router-dom";
import { ascSessionLength, AWS_API, cognitoClientId, cognitoUserpoolId, eventInformationLink, eventTermsConditionsLink, vacSessionLength} from '../../../../utilities/globalVariables';
import { handleAwsApiError, handleBannerText, humanize } from '../../../../utilities/functions';
import { bscStartTime, ascStartTime, bscSessionLength, vacStartTime } from '../../../../utilities/globalVariables';
import axios from 'axios';
import BackdropSpinner from '../../../../UI/BackdropSpinner/BackdropSpinner';
import ParamsForm from '../../../../UI/ParamsForm/ParamsForm';
import Banner from '../../../../UI/Banner/Banner';

const SessionCalendar = (props) => {

    // UI state
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [showUpdateSessions, setShowUpdateSessions] = useState();
    const [updateAllCentres, setUpdateAllCentres] = useState();
    const [bannerText, setBannerText] = useState();    

    // hoc state
    const [error, setError] = useState(false);
    const [loading1, setLoading1] = useState();    
    const [loading2, setLoading2] = useState();    

    // data state
    const [sessionData, setSessionData] = useState();
    const [excursionData, setExcursionData] = useState();

    // filter state
    const [selectedCentreId, setSelectedCentreId] = useState();
    const [selectedDate, setSelectedDate] = useState();

    // data from props
    const centresData = props.centres;
    const isMobile = props.isMobile;

    // console.logs
    console.log('selectedCentreId: ', selectedCentreId);
    console.log('sessionData: ', sessionData);
    console.log('excursionData: ', excursionData);
    console.log('centresData: ', centresData);
    
    // create react-router history object to navigate user to other pages
    const history = useHistory(); 
    let location = useLocation();       

    // render service choices
    const services = (
        <select className='form-control' value={selectedCentreId ?? ''} onChange={(e) => setSelectedCentreId(e.target.value)}>
            <option disabled value=''>Select Service/Provider</option>
            {centresData.map(obj => <option key={obj.centre_id} value={obj.centre_id}>{obj.centre_name}</option>)}
        </select>        
    );    
    

    const getMasterData = useCallback(async (table, setStateFn, setLoadingFn, conditions, notNullColumns) => {
        
        setLoadingFn(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            let filteredData = res.data;
            if (notNullColumns?.length > 0) {
                filteredData = res.data.filter(obj => obj[notNullColumns[0]]); // initially just handling one column specified as not null
            }
            setStateFn(filteredData); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoadingFn(false);

    }, [history, props, location]);    

    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn) => {
        
        setLoading1(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        setShowUpdateSessions(false);

        try {
            const res = await axios.post(AWS_API + route, {postData: postData}, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            handleBannerText(setBannerText, `Success`);
            getMasterData('centre_sessions_calendar', setSessionData, setLoading1, {column: 'centre_id', value: selectedCentreId});
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
            setLoading1(false);
        }    
    }         

    // fetch centre session data if centre selected
    useEffect(() => {

        if (selectedCentreId) {
            getMasterData('centre_sessions_calendar', setSessionData, setLoading1, {column: 'centre_id', value: selectedCentreId});
            // getMasterData('excursions', setExcursionData, setLoading2, {column: 'centre_id', value: selectedCentreId});
            getMasterData('view_excursions_with_detail', setExcursionData, setLoading2, {column: 'centre_id', value: selectedCentreId});
        }

    }, [selectedCentreId, getMasterData]);

    // reset on centre change
    useEffect(() => {

        setSelectedDate();
        setUpdateAllCentres();

    }, [selectedCentreId]);

    // console logs
    console.log('excursionData: ', excursionData);
    
    const events = [];

    // iterate through each row in RDS table roll and turn row into an event object to display on calendar
    sessionData?.forEach(obj => {
        
        // const id = obj.id;
        // console.log('rollData obj for which event being created: ', obj);
        // console.log('children data: ', children);
        // console.log('obj: ', obj);

        const isoDate = obj.date.slice(0,10);
        
        
        const addEventToCalendar = (title, color, startHours, session, sessionLength) => {
            
            const endHours = startHours + sessionLength;
            const start = new Date(obj.date).setTime(new Date(obj.date).getTime() + (startHours*60*60*1000));
            const end = new Date(obj.date).setTime(new Date(obj.date).getTime() + (endHours*60*60*1000));
            const newEvent = {
                title: title, start: start, end: end, color: color, centre_id: obj.centre_id, child_id: obj.child_id, date: isoDate, session: session, eventType: 'Scheduled Booking', extendedProps: {date: isoDate, Status: obj.status}
            };
            events.push(newEvent);
        }


        if (obj.bsc) {
            addEventToCalendar('Before School Care', 'pink', bscStartTime, 'bsc', bscSessionLength);
        } 
        
        if (obj.asc) {
            addEventToCalendar('After School Care', 'blue', ascStartTime, 'asc', ascSessionLength);
        }
        
        if (obj.vac) {
            addEventToCalendar('Vacation Care', 'green', vacStartTime, 'vac', vacSessionLength);
        }
        

    });

    console.log('events: ', events);

    // get unique excursion Ids
    const excursionIds = excursionData?.map(obj => obj.excursion_id)?.filter((v, i, a) => a.indexOf(v) === i);
    console.log('excursionIds: ', excursionIds);
    
    // populate excursions on calendar
    excursionIds?.forEach(id => {
        const detail = excursionData.filter(obj => obj.excursion_id === id);
        console.log('detail: ', detail);
        const start = detail[0].start_date;
        const end = detail[0].end_date ?? start;
        const color = 'rgb(136, 218, 181)';
        const excursionCentreId = detail?.[0]?.centre_id;
        const excursionTitle = detail[0]?.title;
        const surcharge = detail[0]?.surcharge;
        const excursionCentreName = centresData.filter(obj => obj.centre_id === excursionCentreId)?.[0]?.centre_name;
        const excursionSession = detail[0]?.session;
        const extendedProps = {
            centre: excursionCentreName, 
            session: raw[excursionSession], 
            surcharge:  `$${surcharge}`, 
            'authorisation link': <a href={eventInformationLink} target="_blank" rel="noreferrer">{eventInformationLink}</a>,
            'terms & conditions link': <a href={eventInformationLink} target="_blank" rel="noreferrer">{eventTermsConditionsLink}</a>,
        };        
        
        events.push({title: excursionTitle + ` - $${surcharge}`, start: start, end: end, allDay: true, color: color, extendedProps: extendedProps});        

    })

      
    const handleEventClick = (event) => {

        const detail = event.extendedProps;
        const title = event.title;

        console.log('[handleEventClick] - extendedProps: ', detail);
        setShowModal(true);

        const content = (
            <Aux>
                <h5 className={classes.ExcursionTitle}>{title}</h5>
                {
                    Object.keys(detail).map(key => {
                        return (
                            <div key={key} className={classes.ExcursionRow}>
                                <span className={classes.ExcursionKey}>{raw[key] ?? humanize(key)}</span>
                                <span className={classes.ExcursionValue}>{raw[detail[key]] ?? detail[key]}</span>
                            </div>
                        );
                    })
                }
                <br />
                {/* {detail?.eventType?.toLowerCase().includes('booking') && <button className='btn btn-danger btn-block' onClick={() => props.cancelBooking(detail)}>Cancel Booking</button>} */}

            </Aux>
        );
        
        setModalContent(content)
    }

    // set mobile/desktop config
    let initialView, headerToolbar, customButtons, key, height;
    if (isMobile) {
        initialView = "listWeek";
        height="60vh";
        headerToolbar = {
            left: 'listWeek,dayGridWeek,timeGridDay',
        };

        
    } else {
        initialView = "dayGridMonth";
        height = 'auto';
        headerToolbar = {
            right: 'dayGridMonth,dayGridWeek,timeGridDay updateAllCentres today prev next',
        };
        customButtons = {
            updateAllCentres: {
                text: 'update all centres',
                click: () => {setShowUpdateSessions(true); setUpdateAllCentres(true)}
            },
        };        

        key = (
            <div className={classes.Key}>
                <span>Key</span> 
                <ul style={{marginBottom: '3px'}}>
                    <li className={classes.BscKey}>{raw['bsc']}</li>
                    <li className={classes.AscKey}>{raw['asc']}</li>
                    <li className={classes.VacKey}>{raw['vac']}</li>
                    <li className={classes.ExcKey}>Excursion</li>
                </ul>
            </div>
        );
    }

    const postUpdateSessionsForm = (data) => {
        const postData = {
            dates: data?.dates,
            asc: data?.asc,
            bsc: data?.bsc,
            vac: data?.vac,
            centreIds: data?.centres,
            
        };
        console.log('posting data: ', postData);
        postMasterData('master-data/update-sessions-calendar', postData)
    }

      
    return (
        <div className={classes.SessionCalendar}>
            <Banner show={bannerText}>{bannerText}</Banner>    
            <Modal show={showModal} modalClosed={() => setShowModal(false)}>
                {modalContent}
            </Modal>
            <Modal tall show={showUpdateSessions} modalClosed={() => setShowUpdateSessions(false)}>
                {showUpdateSessions && <UpdateSessionsForm updateAllCentres={updateAllCentres} centresData={centresData} sessionData={sessionData} selectedCentreId={selectedCentreId} selectedDate={selectedDate} submitFn={postUpdateSessionsForm} />}
            </Modal>
            <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
            </Modal>     

            {loading1 && <BackdropSpinner spinner='small-light' />}        
            {loading2 && <BackdropSpinner spinner='small-light' />}        
            <h3>Session Calendar</h3>
            <p><i>Select a service/session to view roll</i></p>
            <div className={classes.Row}>
                {services}
            </div>
            <hr />              
            <div className={classes.CalendarInfoBar}>
                <span className={classes.InfoBarText}>Note: Regularly scheduled bookings will be added to roll twice per day and only show two weeks out</span>
                {key}
            </div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                initialView={initialView}
                headerToolbar={headerToolbar}
                customButtons={customButtons}
                events={events}
                timeZone="Aus/Sydney"
                nowIndicator
                dateClick={(e) => {setShowUpdateSessions(true); setSelectedDate(e.dateStr)}}
                eventClick={(e) => handleEventClick(e.event)}
                height={height}
          />
        </div>        
    );
}

export default SessionCalendar

const UpdateSessionsForm = (props) => {

    // state
    const [data, setData] = useState({});    

    // data from props
    const selectedDate = props.selectedDate;
    const selectedCentreId = props.selectedCentreId;
    const centresData = props.centresData;
    const sessionData = props.sessionData;
    const updateAllCentres = props.updateAllCentres;
    const submitFn = props.submitFn;

    // create centre options
    const centreOptions = centresData?.map(obj => ({value: obj.centre_id, label: obj.centre_name}));    

    // console.log
    console.log('[UpdateSessionsForm] - selectedDate: ', selectedDate);
    console.log('[UpdateSessionsForm] - selectedCentreId: ', selectedCentreId);
    console.log('[UpdateSessionsForm] - updateAllCentres: ', updateAllCentres);
    console.log('[UpdateSessionsForm] - data: ', data);
    // console.log('[UpdateSessionsForm] - sessionData: ', sessionData);

    // set form defaults based on dates
    useEffect(() => {

        // if date passed in, prepopulate date
        if (selectedDate && selectedCentreId) {

            const selectedDateSessions = sessionData?.filter(obj => obj.date?.slice(0,10) === selectedDate)?.[0];
            setData(prevData => ({
                ...prevData,
                dates: [selectedDate],
                asc: selectedDateSessions?.asc,
                bsc: selectedDateSessions?.bsc,
                vac: selectedDateSessions?.vac,
                centres: [+selectedCentreId]
            }));        
        }
        
        
    }, [selectedDate, sessionData, selectedCentreId]);

    // set form defaults based on dates
    useEffect(() => {

        // if date passed in, prepopulate date
        if (updateAllCentres) {

            const allCentresIds = centresData?.map(obj => obj.centre_id);
            console.log('allCentreIds: ', allCentresIds);

            setData(prevData => ({
                centres: allCentresIds
            }));   
            console.log('set data')     
        }
        
        
    }, [centresData, updateAllCentres]);

    // set form defaults based on centre
    // useEffect(() => {

    //     // if date passed in, prepopulate date
    //     if (selectedCentreId) {
            

    //         // const selectedCentreOptions = centresData?.map(obj => ({value: +obj.centre_id, label: obj.centre_name}))?.filter(obj => +obj.value === +selectedCentreId);
    //         // console.log('test: ', selectedCentreOptions)
            
    //         setData(prevData => ({
    //             ...prevData,
    //             centres: [selectedCentreId]
    //         }));     
    //         console.log('data set')   
    //     }


    // }, [selectedCentreId, centresData]);

    // reset data if account changes
    // useEffect(() => {
    //     setData({});
    //     console.log('reset data')
    // }, [selectedCentreId]);
    

    // form config
    const config = [
        {name: 'centres', type: 'react-select', isMulti: true,  options: centreOptions, valueProperty: 'value'},
        {name: 'dates', type: 'date-picker'},
        {name: 'asc', type: 'checkbox'},
        {name: 'bsc', type: 'checkbox'},
        {name: 'vac', type: 'checkbox'},
        // {name: 'centreName', type: 'react-select',  options: centreOptions, valueProperty: 'value'},
    ];

    return (
        <Fragment>
            <h3>Update Session Calendar</h3>
            <hr/>
            <ParamsForm 
                data={data}
                setData={setData}
                config={config}
            />
            <button className='btn btn-success btn-block' onClick={() => submitFn(data)}>Update</button>
        </Fragment>
    );
}