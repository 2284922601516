import { useState, useEffect, Fragment } from 'react';
import { humanize, arrayUniqueByKey } from '../../../../../utilities/functions';
import ParamsForm from '../../../../../UI/ParamsForm/ParamsForm';
import countries from '../../../../../utilities/S3 Json Files/countries.json';
import languages from '../../../../../utilities/S3 Json Files/languages.json';
import {cloneDeep} from 'lodash';


const UpdateContactDetails = (props) => {

    // state
    const [selectedContactId, setSelectedContactId] = useState();
    const [data, setData] = useState();

    // get data from props
    const contactData = props.contactData;
    const handleUpdateContactData = props.handleUpdateContactData;
    
    // console logs
    console.log('contactData: ', contactData);
    console.log('selectedContactId: ', selectedContactId);
    console.log('data: ', data);

    // set data in state so we can modify
    useEffect(() => {
        
        // only allowing editing of user's own data currently
        if (contactData?.length > 0 && selectedContactId) {
            const clonedData = cloneDeep(contactData);
            const selectedContact = clonedData.filter(obj => +obj.person_id === +selectedContactId)?.[0];
            setData(selectedContact);
            
        }

    }, [contactData, selectedContactId, setData]);    

    // handle no data passed in
    if (!contactData?.length > 0) {
        return <p><i>waiting for contact data...</i></p>;
    }


    // render child selector
    const uniqueContactData = arrayUniqueByKey('person_id', contactData);
    console.log('uniqueContactData: ', uniqueContactData);
    const contactSelector = (
        <select className='form-control' value={selectedContactId ?? ''} onChange={(e) => setSelectedContactId(e.target.value)}>
            <option disabled value=''>Select Contact</option>
            {uniqueContactData.sort((a,b) => {
                if ( a.first_name < b.first_name ) return -1;
                if ( a.first_name > b.first_name ) return 1;
                return 0;
            }).map(obj => <option key={obj.person_id} value={obj.person_id}>{obj.first_name + ' ' + obj.surname + ' (' + humanize(obj.contact_type) + ' )'}</option>)}
        </select>        
    );         
    
    // render edit contact form based on contact type selected
    const contactType = contactData.filter(obj => +obj.person_id === +selectedContactId)?.[0]?.contact_type;
    console.log('selected contact type: ', contactType);

    // guardian details
    let form;
    if (contactType === 'guardian') {
        form = (
            <Fragment>
                <ParamsForm 
                    data={data} 
                    setData={setData} 
                    config={[
                        {name: 'first_name', type: 'text'}, 
                        {name: 'surname', type: 'text'},
                        {name: 'preferred_contact', type: 'text', validation: {onlyNumbers: true}},
                        {name: 'additional_contact', type: 'text', validation: {onlyNumbers: true}},
                        {name: 'dob', type: 'date'},
                        {name: 'crn', type: 'text', value: data?.crn},
                        {name: 'email', type: 'text', value: data?.email},
                        {name: 'nationality', type: 'react-select', options: countries, valueProperty: 'label'},
                        {name: 'language_spoken_at_home', type: 'react-select', options: languages, valueProperty: 'label'}
                    ]}
                />
                <button className='btn btn-block btn-success' onClick={() => handleUpdateContactData('guardians', data)}>Update</button>
            </Fragment>
        );
    } else if (contactType === 'emergency_contact') {
        form = (
            <Fragment>
                <ParamsForm 
                    data={data} 
                    setData={setData} 
                    config={[
                        {name: 'first_name', type: 'text'}, 
                        {name: 'surname', type: 'text'},
                        {name: 'contact_number', type: 'text'},
                        {name: 'address_unit', type: 'text'},
                        {name: 'address_building', type: 'text'},
                        {name: 'address_house_number', type: 'text'},
                        {name: 'address_road', type: 'text'},
                        {name: 'address_postcode', type: 'text'},
                        {name: 'address_suburb', type: 'text'},
                        {name: 'address_state', type: 'text'},
                        {name: 'address_country', type: 'text'},
                    ]}
                />
                <button className='btn btn-block btn-success' onClick={() => handleUpdateContactData('emergency_contacts', data)}>Update</button>
            </Fragment>
        );
        
    }


    return (
        <Fragment>
            <h4>Edit Contact Information</h4>
                <hr/>
                <p><i>Select a contact to edit details</i></p>
                {contactSelector}
                <br/>    
                {form}        
        </Fragment>
    );
}

export default UpdateContactDetails;