import { useState, useCallback, useEffect } from "react";
import TertiaryNavbar from "../../../../UI/Navigation/TertiaryNavbar/TertiaryNavbar";
import BSTable from '../../../../UI/BSTable/BSTable';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from "../../../../utilities/globalVariables";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { handleAwsApiError } from "../../../../utilities/functions";
import SpinnerDark from "../../../../UI/SpinnerDark/SpinnerDark";
import classes from './KioskUserFeedback.module.css';
import Modal from "../../../../UI/Modal/Modal";
import ParamsForm from "../../../../UI/ParamsForm/ParamsForm";
import { handleBannerText } from "../../../../utilities/functions";
import Banner from "../../../../UI/Banner/Banner";
import { bootstrapTableConfig, feedbackQuestionProcesses } from "../../../../utilities/globalObjects";

const KioskUserFeedback = (props) => {

    // Read props
    const centreAdminFlag = props.centreAdminFlag;

    // Set state
    const [mode, setMode] = useState('responses');
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [selected, setSelected] = useState([]);
    const [newEntryData, setNewEntryData] = useState();
    const [bannerText, setBannerText] = useState('');
    const [selectedTable, setSelectedTable] = useState();

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();   

    const getMasterData = useCallback(async (table, setStateFn, conditions) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        } finally {
            setLoading(false);
        }    


    }, [history, props, location]);  

    const insertRow = async (table, row) => {
        
        // close modal
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            table: table,
            rows: [row], // function allows for multiple rows, but we're just inserting one at a time here
        };

        try {
            const res = await axios.post(AWS_API + 'master-data/insert', {postData: postData}, headers);
            console.log('response: ', res.data);
            handleBannerText(setBannerText, 'Entry Added Successfully')
            setNewEntryData();
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error while updating user groups');
        } finally {
            setLoading(false);
        }
        
    };

    const updateData = async (oldValue, newValue, row, column) => {
        
        setLoading(true);
        
        // get primary keys
        const primaryKeys = bootstrapTableConfig[selectedTable]?.primaryKeys;
        if (!primaryKeys) {
            setError('Primary keys not found for selected table: ' + selectedTable);
            setLoading(false);
            return false
        }        

        // construct primary keys object
        const primaryKeysObj = {};
        primaryKeys.forEach(key => primaryKeysObj[key] = row[key]);


        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};

        const updateCol = column.dataField;
        const postData = {
            table: selectedTable,
            updateObj: {[updateCol]: newValue},
            primaryKeysObj: primaryKeysObj
        };
        console.log('posting: ', postData);

        // call lambda function to update master data item
        try {
            const res = await axios.post(AWS_API + 'master-data/update', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Data Updated Successfully`);
            getMasterData(selectedTable, setData);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while updating settings');
            console.log('error whilst recording roll event: ', err);
        } finally {
            setLoading(false);        
        }
    }   

    // get data for each table
    useEffect(() => {
        setLoading(true);
        if (mode === 'responses'){
            // change mode to responses
            console.log("changing to responses");
            setSelectedTable('view_feedback_form_responses');
            getMasterData('view_feedback_form_responses', setData);
        } else if (mode === 'update-question'){
            // change mode to update-question
            console.log("changing to update-question");
            setSelectedTable('feedback_form_questions');
            getMasterData('feedback_form_questions', setData);
        } else if (mode === 'end-schedule-responses'){
            // change mode to update-question
            console.log("changing to update-question");
            setSelectedTable('view_end_schedule_responses');
            getMasterData('view_end_schedule_responses', setData);
        }
        setLoading(false);
    }, [getMasterData, mode]);

    let tabConfig;
    if (centreAdminFlag) {
        tabConfig = [

        ];
    } else {
        tabConfig = [
            {label: 'Kiosk Feedback Responses', value: 'responses'},
            {label: 'Kiosk New question', value: 'new-question'},
            {label: 'Kiosk View/Update questions', value: 'update-question'},
            {label: 'End Permanent Schedule Responses', value: 'end-schedule-responses'},
        ];
    }
    const handleModeChange = (newMode) => {
        console.log("changing to: ", newMode);
        setMode(newMode);
        setLoading(true);
    };

    let content = <SpinnerDark/>;
    if (!loading){
        // set response table
        if (mode === 'responses'){
            content = (
            <BSTable
                table={'view_feedback_form_responses'}
                // data={data ? data.sort((a,b) => b.created_at - a.created_at): data} 
                data={data ? data.sort((a,b) => b.created_at.localeCompare(a.created_at)): data} 
                selected={selected}
                setSelected={setSelected}
                horizontalScroll
                paginationConfig={{sizePerPage: 25}}      
            />
            );
        } else if (mode === 'new-question'){
            // add params form
            content = (
                <div>
                    <h5>Add New Question</h5>
                    <hr/>
                    <ParamsForm 
                        data={newEntryData}
                        setData={setNewEntryData}
                        config={[
                            {name: 'question', type: 'text'},
                            {name: 'process', type: 'react-select', options: feedbackQuestionProcesses, valueProperty: 'value'},
                            {name: 'active', type: 'checkbox'}
                        ]}
                    />
                    <button className='btn btn-block btn-success' onClick={() => insertRow('feedback_form_questions', newEntryData)}>Submit</button>
                    {/* <button className='btn btn-block btn-success'>Submit</button> */}
                </div>
            )
        } else if (mode === 'update-question'){
            content = (
            <BSTable
                table={'feedback_form_questions'}
                data={data} 
                selected={selected}
                setSelected={setSelected}
                horizontalScroll
                enableEdit
                updateFunction={updateData}
                paginationConfig={{sizePerPage: 25}}      
            />
            );
        } else if (mode === 'end-schedule-responses'){
            content = (
                <BSTable
                    table={'view_end_schedule_responses'}
                    data={data} 
                    selected={selected}
                    setSelected={setSelected}
                    horizontalScroll
                    paginationConfig={{sizePerPage: 25}}
                />
                );
        }
    }
    return (
        <div className={classes.KioskUserFeedback}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Banner show={bannerText}>{bannerText}</Banner>
            <h3>User Feedback</h3>
            <hr/>
            <TertiaryNavbar tab={mode} updateStateFn={handleModeChange} config={tabConfig} />
            {content}
        </div>
    )
}

export default KioskUserFeedback;