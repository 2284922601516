import React, { useState } from 'react';
import classes from './AdditionalParams.module.css';

const AdditionalParams = (props) => {

    const [numRows, setNumRows] = useState(1);
    const [data, setData] = useState({});

    // console.log('data: ', data);
    // const data = props.data;
    // const setData = props.setData;

    const setFormattedData = props.setParams;

    const updateFormattedData = (newData) => {

        const newFormattedData = {};
        Object.keys(newData).forEach(i => {
            const obj  = newData[i];
            console.log('obj: ', obj);
            const key = obj.key;
            const value = obj.value;
    
            newFormattedData[key] = value;
        })
        // console.log('newFormattedData: ', newFormattedData);
        setFormattedData(newFormattedData);
    }


    let rows = [];
    for (let i = 0;  i < numRows; i++) {

        rows.push(
            <div className={classes.Row}>
                
                <span className={classes.Label}>Key</span>
                <input 
                type='text' 
                className='form-control' 
                value={data?.[i]?.key ?? ''} 
                onChange={(e) => {
                    const newData = {
                        ...data,
                        [i] : {
                            ...data?.[i],
                            key: e.target.value
                        }};
                    setData(newData);
                    updateFormattedData(newData);
                }}/> 

                <span className={classes.Label}>Value</span>
                <input 
                type='text' 
                className='form-control' 
                value={data?.[i]?.value ?? ''} 
                onChange={(e) => {
                    const newData = {
                        ...data,
                        [i] : {
                            ...data?.[i],
                            value: e.target.value
                        }};
                    setData(newData);
                    updateFormattedData(newData);
                }}/> 
            </div>
        );
    }


    return (
        <div className={classes.AdditionalParams}>
            <h6>Additional Parameters</h6>
            {rows}
            <br />
            <div style={{textAlign: 'center'}}>
                <button className={classes.RoundButton} onClick={() => setNumRows(numRows + 1)}>+</button>

            </div>
        </div>
        

    );
};

export default AdditionalParams;