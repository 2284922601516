import { Fragment, React, useState } from 'react';
// import SpinnerDark from '../../../../UI/SpinnerDark/SpinnerDark';
import classes from './Drills.module.css';


const Drills = (props) => {

    const [drillCategory, setDrillCategory] = useState();
    const [drillType, setDrillType] = useState();

    // console.log('drillType: ', drillType);

    // data from props
    const submitFn = props.submitFn;
    const cleanupFn = props.cleanupFn;

    let drillTypes;
    if (drillCategory === 'evacuation') {
        drillTypes = (
            <Fragment>
                <button onClick={() => setDrillType('bomb')} className={drillType === 'bomb' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Bomb Threat</button>
                <button onClick={() => setDrillType('bush-fire')} className={drillType === 'bush-fire' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Bush Fire Evacuation</button>
                <button onClick={() => setDrillType('fire')} className={drillType === 'fire' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Fire Evacuation</button>
                <button onClick={() => setDrillType('tsunami')} className={drillType === 'tsunami' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Tsunami</button>
            </Fragment>
        );
    } else if (drillCategory === 'lockdown') {
        drillTypes = (
            <Fragment>
                <button onClick={() => setDrillType('lockdown')} className={drillType === 'lockdown' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Lockdown</button>
            </Fragment>
        );
    }

    return (
        <div className={classes.Drills}>
            <h5>Record New Drill</h5>
            <hr/>
            <h6>Category</h6>
            <div className={classes.DrillTypesRow}>
                <button onClick={() => setDrillCategory('evacuation')} className={drillCategory === 'evacuation' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Emergency Evacuation</button>
                <button onClick={() => setDrillCategory('lockdown')} className={drillCategory === 'lockdown' ? "btn CustomButtonPrimary" : "btn btn-outline-info"}>Emergency Lockdown</button>

            </div>
            <p> </p>
            <h6>Drill Type</h6>
            <div className={classes.DrillTypesRow}>
                {drillTypes}
            </div>
            <hr />
            <button className='btn btn-success btn-block' onClick={() => submitFn(props.rollEntries, 'drill-' + drillCategory + '-' + drillType, () => cleanupFn())}>Record Drill</button>
        </div>
    )
}

export default Drills;