import React, { Component } from 'react';

import classes from './OptionModal.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import OptionModalBackdrop from '../OptionModalBackdrop/OptionModalBackdrop';

class OptionModal extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.show !== this.props.show || nextProps.children !== this.props.children);
    }

    
    

    render () {

        const classList = [classes.OptionModal];


        if (this.props.showOnTop) {
            classList.push(classes.ShowOnTop)
        }

        // const x = this?.props?.clickedCellObj?.position?.x;
        // const y = this?.props?.clickedCellObj?.position?.y;

        return (
            <Aux>
            <OptionModalBackdrop showOnTop={this.props.showOnTop} show={this.props.show} clicked={this.props.modalClosed}/>
            <div className={classList.join(' ')}
                style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>
                {this.props.children}
            </div>
            </Aux>
        );
    }

}
export default OptionModal;