// library imports
import { React, useEffect, useState, useCallback, Fragment, 
    // Fragment 
} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';

// local imports
import classes from './Preferences.module.css';
import Modal from '../../../UI/Modal/Modal';
import { AWS_API } from '../../../utilities/globalVariables';
import { arrayUniqueByKey, getURLSubroute, handleAwsApiError, handleBannerText, handlePrompt } from '../../../utilities/functions';
import { cognitoClientId, cognitoUserpoolId
    // ascCutOffHour, bscCutOffHour, vacCutOffHour 
} from '../../../utilities/globalVariables';
import Banner from '../../../UI/Banner/Banner';
import Prompt from '../../../UI/Prompt/Prompt';
// import ChildSelector from '../../Admin/Cabinet/AccountMgmt/ChildSelector/ChildSelector';
import Select from 'react-select';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import ChildSelector from '../../Admin/Cabinet/AccountMgmt/ChildSelector/ChildSelector';

const Preferences = (props) => {

    // children preferences data
    const [childrenData, setChildrenData] = useState();
    const [childEnrolmentsData, setChildEnrolmentsData] = useState();
    const [filteredChildrenData, setFilteredChildrenData] = useState();
    const [filteredChildEnrolmentsData, setFilteredChildEnrolmentsData] = useState();

    // user selected state
    const [newHomeCentreObj, setNewHomeCentreObj] = useState({});
    const [selectedChildren, setSelectedChildren] = useState([]);

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');  
    const [prompt, setPrompt] = useState({});      

    // display content state
    const [displayContent, setDisplayContent] = useState('children');
    
    // console logs
    console.log('selectedChildren: ', selectedChildren);
    console.log('childrenData: ', childrenData);
    console.log('childEnrolmentsData: ', childEnrolmentsData);


    // react router history instance to navigate user to other pages
    let history = useHistory();
    let location = useLocation();    
    
    // console.log('subroute: ', subroute);

    // get data from props
    const setSublinks = props.setSublinks;

    // set leftpane sublinks
    useEffect(() => {
        setSublinks([
            {title: 'Children', shortName: 'Children', icon: 'contactInfo',  onClick: () => setDisplayContent('children')},
        ]);
    }, [setSublinks])

    // set content based on subroute
    useEffect(() => {
        // get subroute and set content
        const subroute = getURLSubroute(location);
        
        if (subroute) {
            setDisplayContent(subroute);
        }

    }, [location, setDisplayContent]);

    const getMasterData = useCallback(async (table, setStateFn, conditions, noLoading) => {
        
        noLoading ? void(0) : setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {tableName: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'account-owner-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setStateFn(res.data); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        noLoading ? void(0) : setLoading(false);

    }, [history, props, location]);      

    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {

        // loading true before calling data fetch functions
        setLoading(true); 
        
        (async () => {
            await getMasterData('children', setChildrenData, null, true);
            await getMasterData('child_enrolments', setChildEnrolmentsData, null, false);
        })();


        setLoading(false);



    }, [getMasterData]);

    // any time a selected children changes, update filtered data
    useEffect(() => {
        
        // child needs to be selected to display data
        if (childrenData?.length > 0 && childEnrolmentsData?.length > 0) {   
            
            const childIds = selectedChildren?.map(obj => obj.value);
            

            // check whether all children selected or one/multiple others
            if (childIds.includes('*')) {
                
                // no need to filter
                setFilteredChildrenData(childrenData);
                setFilteredChildEnrolmentsData(childEnrolmentsData);
            } else {
                
                // filter for selected children
                setFilteredChildrenData(childrenData?.filter(obj => childIds?.includes(obj.child_id)));
                setFilteredChildEnrolmentsData(childEnrolmentsData?.filter(obj => childIds?.includes(obj.child_id)));
            }
            
        }
            
    }, [selectedChildren, childrenData, childEnrolmentsData])    

    // post to master-data/{route}
    const postMasterData = async (route, postData, onSuccessFn) => {
        
        setLoading(true);

        // get selected rows
        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };

        // close modals
        
        try {
            const res = await axios.post(AWS_API + 'account-owner-data/' + route, postData, headers);
            console.log('res: ', res.data);
            onSuccessFn ? onSuccessFn() : void(0);
            handleBannerText(setBannerText, `Request Completed Successfully`);

            // handle specific data source updates requested by child components
            getMasterData('children', setChildrenData, null, true);
            getMasterData('child_enrolments', setChildEnrolmentsData, null, true);
            
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered');
            console.log('error whilst recording roll event: ', err);
        }    
        setLoading(false);
    }      

    const handleUpdateHomeCentreState = (childId, newObj) => {
        console.log(`childId ${childId}, newObj ${JSON.stringify(newObj)}`)
        setNewHomeCentreObj({
            ...newHomeCentreObj,
            [childId]: newObj.value
        })
    }

    const postUpdatedHomeCentre = (childId) => {
        const newHomeCentre = newHomeCentreObj?.[childId];
        console.log('newHomeCentre: ', newHomeCentre);
        postMasterData('update-home-centre', {postData: {childId: childId, centreId: newHomeCentre}})
    }    
    
    // render child home centre update UI
    let childHomeCentreUI;
    if (filteredChildrenData?.length > 0 && filteredChildEnrolmentsData?.length > 0 && selectedChildren?.length > 0) {

        // only run this for selected filteredChildren
        // console.log('filteredChildEnrolmentsData: ', filteredChildEnrolmentsData?.map(obj => ({value: obj.centre_id, label: obj.centre_name})));
        const centreOptions = arrayUniqueByKey('value', filteredChildEnrolmentsData?.map(obj => ({value: obj.centre_id, label: obj.centre_name})));
        console.log('centreOptions: ', centreOptions);
        childHomeCentreUI = filteredChildrenData.map(obj => {
            
            const childHomeCentre = newHomeCentreObj?.[obj?.child_id] ?? obj?.home_centre_id;
            const childName = obj?.first_name;
            const reactSelectObj = {value: childHomeCentre, label: filteredChildEnrolmentsData.filter(obj => obj.centre_id === childHomeCentre)?.[0]?.centre_name ?? 'Centre Name Not Found'};

            return (
                <Fragment>
                    <div className={classes.FlexRow}>
                        <label className={[classes.FlexRowItem, classes.Label].join(' ')}>{obj?.first_name + ' ' + obj?.surname}</label>
                        <div className={classes.FlexRowItem}>
                            <Select options={centreOptions} onChange={(e) => handleUpdateHomeCentreState(obj?.child_id, e)} value={reactSelectObj}></Select>
                        </div>
                    </div>
                    <button className='btn btn-success btn-block' onClick={() => handlePrompt(`update ${childName}'s home centre`, () => postUpdatedHomeCentre(obj.child_id), setPrompt) }>Update Home Centre</button>
                </Fragment>
            )
        })
    }        

    let content;
    if (displayContent === 'children') {
        content = (
            <div className={classes.Tile}>
                <h4>Children Preferences</h4>
                {childrenData && <ChildSelector childrenData={childrenData} selectedChildren={selectedChildren} setSelectedChildren={setSelectedChildren}/>}
                
                <div >
                    <h6>Preferred Centres</h6>
                    <p className={[classes.InfoText, classes.RedText].join(' ')}>To update the centre for which you can create bookings: Select a child and your preferred centre and click on "Update Home Centre"</p>
                    {childHomeCentreUI}
                </div>
            </div>                    
        );      
    } 

    return (
        <div className={classes.Preferences}>
            {/* <button className="btn btn-dark" onClick={testfn}>Test</button> */}
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            {loading && <BackdropSpinner spinner='small-light' />}
            <Banner show={bannerText}>{bannerText}</Banner>                
            <Prompt data={prompt} setData={setPrompt} />
            {content}
        </div>        
    )
}


  
export default Preferences;