import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
// import CommentIcon from '@mui/icons-material/Comment';
import {FaDownload} from "react-icons/fa";
import {CgFileDocument} from "react-icons/cg";
import {BsPersonCircle} from "react-icons/bs";
import { bootstrapTableConfig, bootstrapTableDateFields, bootstrapTableDateTimeFields } from '../../utilities/globalObjects';
import { timezone } from '../../utilities/globalVariables';

const MaterialList = (props) => {

    // state
    
    // props
    const list = props.list;
    const selected = props.selected;
    const setSelected = props.setSelected;
    const table = props.table;
    const primaryField = props.primaryField;
    const secondaryFields = props.secondaryFields;
    let primaryAction = props.primaryAction;
    let secondaryAction = props.secondaryAction;
    const icon = props.icon;

    // console logs
    // console.log('list: ', list);

    let secondaryActionProps;
    if (secondaryAction) {
        secondaryActionProps = (
            <IconButton onClick={(rowId) => secondaryAction(rowId)} edge="end" aria-label="comments">
                <FaDownload size={'.8rem'} color={'lightgreen'} />
            </IconButton>
        );
    }

    if (!primaryAction) {
       primaryAction = () => void(0); 
    }

    // checkbox handling
    const handleToggle = (value) => () => {
        const currentIndex = selected.indexOf(value);
        const newChecked = [...selected];

        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }

        setSelected(newChecked);
    };

    // get unique id field for table
    const tableConfig = bootstrapTableConfig[table];
    const uniqueSelectId = tableConfig?.uniqueSelectId; 

    let leftHandIcon = <CgFileDocument size={'1.5rem'} color={'grey'} />;
    if (icon === 'person') {
        leftHandIcon = <BsPersonCircle size={'1.5rem'} color={'darkslategrey'} />;
    }

    let content;
    if (uniqueSelectId && list?.length > 0) {
        content = (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {list.map((obj) => {
                
                const rowId = obj[uniqueSelectId];

                // conversions
                let secondaryValues = secondaryFields.map(field => {
                    let value = obj[field];
                    if (bootstrapTableDateTimeFields.includes(field) && value) {
                        value = new Date(value).toLocaleString("sv-SW", {timeZone: timezone});
                    } else if (bootstrapTableDateFields.includes(field) && value) {
                        value = new Date(value).toLocaleDateString("en-GB");
                    } 
                    return value
                });
                

                return (
                    <ListItem
                        key={rowId}
                        secondaryAction={secondaryActionProps}
                        disablePadding
                    >
                        <ListItemButton role={undefined} onClick={selected ? () => handleToggle(rowId) : () => primaryAction(rowId)} dense>
                            <ListItemIcon>
                                {selected && <Checkbox
                                    edge="start"
                                    checked={selected.indexOf(rowId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': rowId }}
                            />}
                                {!selected && leftHandIcon}
                            </ListItemIcon>
                            <ListItemText id={rowId} primary={obj[primaryField] ?? primaryField} secondary={secondaryValues.join(' - ')} />
                        </ListItemButton>
                    </ListItem>
                );
                })}
            </List>
        );
    } else if (!uniqueSelectId) {
        content = <p>No config found for specified data source {table}</p>;
    } else {
        content = <p><i>No items found</i></p>
    }

    return content
}

export default MaterialList;