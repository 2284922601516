import React, { useState } from 'react';
import classes from './PhotoUpload.module.css';
import { handleAwsApiError, handleBannerText } from '../../../utilities/functions'; 
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import Modal from '../../../UI/Modal/Modal';
import { AWS_API, cognitoClientId, cognitoUserpoolId } from '../../../utilities/globalVariables';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import Resizer from "react-image-file-resizer";


const PhotoUpload = (props) =>  {
    const [source, setSource] = useState("");
    const [file, setFile] = useState("");

    // hoc state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
  

    // create react-router history object to navigate user to other pages
    const history = useHistory();    
    const location = useLocation();

    const handleCapture = (target) => {
      if (target.files) {
        if (target.files.length !== 0) {
          const newFile = target.files[0];
          setFile(newFile);
          const newUrl = URL.createObjectURL(newFile);
          setSource(newUrl);
        }
      }
    };

    // function getBase64(file) {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function () {
    //       return (reader.result);
    //     };
    //     reader.onerror = function (error) {
    //       console.log('Error: ', error);
    //     };
    //  }   
     
    //  function getBase64(file, onLoadCallback) {
    //     return new Promise(function(resolve, reject) {
    //         var reader = new FileReader();
    //         reader.onload = function() { resolve(reader.result); };
    //         reader.onerror = reject;
    //         reader.readAsDataURL(file);
    //     });
    // }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
            file,
            256,
            512,
            "JPEG",
            50,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
        });
    



    const postPhoto = async () => {
        // const formData = new FormData();
        // formData.append("name", 7);
        // formData.append("file", file);

        // const base64Img = getBase64(file); // prints the base64 string      
        // console.log('base64Img: ', base64Img);
        // let base64Img;
        // const promise = getBase64(file);
        // promise.then(function(result) {


        // const base64Img = await getBase64(file);
        // const resizedImage = await resizeFile(file);
        // console.log('resizedImage: ', resizedImage);
        const base64Img = await resizeFile(file);

        // return false;

        // const base64Img = await getBase64(resizedImage);
        // console.log('test result: ', result);
        // });    
        
        // console.log('base64img: ', base64Img);
        console.log('length of base64img: ', base64Img.length);

        // return false;

        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {childId: props.uploadPhotoChildId, centreId: props.uploadPhotoCentreId, photo: base64Img, name: file.name};
        setLoading(true); 
                
        console.log('posting to /upload-photos, postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'form-submissions/upload-photos', {postData: postData}, headers);
            const data = res.data;
            console.log('data: ', data);
            props.completed();
            handleBannerText(props.setBannerText, 'Photo Uploaded Successfully');
            // close modal
                    
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while attempting to upload photo');
        }   
        
        setLoading(false);             
      };    

    // console.log('file: ', file);

    let content = <SpinnerDark />
    if (!loading) {
        content = (
            <div>
                <hr />
                <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => handleCapture(e.target)}
              />
              <hr />
              {source && <div >
                <img src={source} alt={"selected-pic"} className={classes.Img}></img>
              </div>}
              <hr />
              <button className="btn btn-success btn-block" onClick={postPhoto}>Submit</button>

            </div>
        );
    }

    return (
      <div className={classes.PhotoUpload}>
                      
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                
          <h3>Upload New Photo</h3>
            {content}
      </div>
    );
}

export default PhotoUpload;
