// library imports
import { React, useEffect, useState, useCallback, 
    // Fragment 
} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import { Link } from 'react-router-dom';
// import DatePicker from "react-multi-date-picker";

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';


// local imports
import classes from './AccountOwner.module.css';
// import TopDrawer from '../../UI/TopDrawer/TopDrawer';
import SpinnerDark from '../../UI/SpinnerDark/SpinnerDark';
// import Spinner from '../../UI/Spinner/Spinner';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Modal from '../../UI/Modal/Modal';
import ScheduleView from './Bookings/ScheduleView/ScheduleView';
import Calendar from './Bookings/Calendar/Calendar';
import { AWS_API, envName, eventInformationLink, eventTermsConditionsLink, prodURL } from '../../utilities/globalVariables';
import { datePickerObjToISOFormat, getURLSubroute, handleAwsApiError, handlePrompt } from '../../utilities/functions';
import { raw } from '../../translations/en/raw';
import { cognitoClientId, cognitoUserpoolId
    // ascCutOffHour, bscCutOffHour, vacCutOffHour 
} from '../../utilities/globalVariables';
import {  handleBannerText } from '../../utilities/functions';
import Notifications from './Notifications/Notifications';
// import PermissionSlip from './PermissionSlip/PermissionSlip';
import Banner from '../../UI/Banner/Banner';
import ParentData from './ParentData/ParentData';
import Statements from './Statements/Statements';
import Prompt from '../../UI/Prompt/Prompt';
import Entitlements from './Entitlements/Entitlements';
// import ChildSelector from '../Admin/Cabinet/AccountMgmt/ChildSelector/ChildSelector';
import ContactUs from './ContactUs/ContactUs';
import Select from 'react-select';
import UpdateSchedule from '../Admin/Cabinet/AccountMgmt/UpdateSchedule/UpdateSchedule';
import EndSchedule from '../Admin/Cabinet/AccountMgmt/EndSchedule/EndSchedule';
import { sessionTypes, endPermScheduleFeedbackOptions } from '../../utilities/globalObjects';
import { Checkbox, FormControlLabel, Rating } from '@mui/material';
// const childLevelModes = ['bookings', 'billing', 'entitlements'];
const question = "We're sad to see you go. How satisfied were you with the overall Active OOSH services during your time with us?";

const AccountOwner = (props) => {

    // accountOwner master data state
    const [accountData, setAccountData] = useState();
    const [formSubmissionData, setFormSubmissionData] = useState();
    const [permBookingData, setPermBookingData] = useState();
    const [casualBookingData, setCasualBookingData] = useState();
    const [childrenData, setChildrenData] = useState();
    const [childrenMedicalData, setChildrenMedicalData] = useState();
    const [centreSessionData, setCentreSessionData] = useState();
    const [childGuardianAuthData, setChildGuardianAuthData] = useState();
    const [childGuardianRelationshipData, setChildGuardianRelationshipData] = useState();
    const [centresData, setCentresData] = useState();
    const [childEnrolmentsData, setChildEnrolmentsData] = useState();
    const [rollData, setRollData] = useState();
    const [excursionData, setExcursionData] = useState();
    const [guardianData, setGuardianData] = useState();
    const [emergencyContactsData, setEmergencyContactsData] = useState();
    // const [primaryGuardianData, setPrimaryGuardianData] = useState();
    // const [secondaryGuardianData, setSecondaryGuardianData] = useState();
    const [childGroupAuthData, setChildGroupAuthData] = useState();
    const [childrenAddressData, setChildrenAddressData] = useState();
    const [guardianAddressData, setGuardianAddressData] = useState();
    const [invoicesData, setInvoicesData] = useState();
    const [entitlementsData, setEntitlementsData] = useState();
    const [missingAccountData, setMissingAccountData] = useState();
    // const [centreCapacities, setCentreCapacities] = useState();
    const [documentsData, setDocumentsData] = useState();
    const [feedbackComment, setFeedbackComment] = useState();
    // const [feedbackRating, setFeedbackRating] = useState();
    const [feedbackCentreId, setFeedbackCentreId] = useState();
    const [feedbackOption, setFeedbackOption] = useState("");
    const [recommendScore, setRecommendScore] = useState();
    const [contactChecked, setContactChecked] = useState(false);
    
    // child selector and filtered data based on selected children
    const [selectedChildObjects, setSelectedChildObjects] = useState([]);
    // const [filteredChildrenData, setFilteredChildrenData] = useState();
    // const [filteredEntitlementsData, setFilteredEntitlementsData] = useState();
    // const [filteredPermBookingData, setFilteredPermBookingData] = useState();
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');  
    const [prompt, setPrompt] = useState({});      
    
    const [showCasualBookingForm, setShowCasualBookingForm] = useState(false);
    const [showUpdateBookingSchedule, setShowUpdateBookingSchedule] = useState(false);
    const [showEndBookingSchedule, setShowEndBookingSchedule] = useState(false);
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    
    // end schedule state
    
    // display content state
    const [displayContent, setDisplayContent] = useState('calendar');
    
    // permission slip data commented out while managing authorisations via acknowledgement in booking form
    // const [permissionSlipId, setPermissionSlipId] = useState();
    // const [permissionSlipData, setPermissionSlipData] = useState();
    
    // casual booking form state
    const [casualBookingChildren, setCasualBookingChildren] = useState([]);
    const [casualBookingDates, setCasualBookingDates] = useState([]);
    const [casualBookingCentre, setCasualBookingCentre] = useState();
    const [selectedSessions, setSelectedSessions] = useState([]);  

    // const [selectedDatesSQLFormat, setSelectedDatesSQLFormat] = useState();
    // const [remainingSpaces, setRemainingSpaces] = useState();
    // const [selectedChild, setSelectedChild] = useState();
    
    // console logs
    // console.log('entitlementsData: ', entitlementsData);
    // console.log('permissionSlipData: ', permissionSlipData);
    // console.log('selectedApprovedForm: ', selectedApprovedForm);
    // console.log('selectedChildren: ', selectedChildren);
    // console.log('casualBookingChildren: ', casualBookingChildren);
    // console.log('childEnrolmentsData: ', childEnrolmentsData);
    console.log('casualBookingCentre: ', casualBookingCentre);
    // console.log('selectedSessions: ', selectedSessions);
    // console.log('casualBookingDates: ', casualBookingDates);
    // console.log('centreSessionData: ', centreSessionData);
    // console.log('formSubmissionData: ', formSubmissionData);
    console.log('guardianData: ', guardianData);
    // console.log('primaryGuardianData: ', primaryGuardianData);
    // console.log('missingAccountData: ', missingAccountData);
    // console.log('guardianAddressData: ', guardianAddressData);
    // console.log('childrenData: ', childrenData);
    // console.log('childrenMedicalData: ', childrenMedicalData);
    // console.log('centresData: ', centresData);
    // console.log('rollData: ', rollData);
    // console.log('rollData: ', rollData);
    // console.log('permission slip id: ', permissionSlipId);
    // console.log('excursionData: ', excursionData);
    // console.log('accountData: ', accountData);
    console.log("Recommend Score: ", recommendScore);

    // map of which table goes into which state object, used by children components to trigger specific data updates
    const tableToStateMap = {
        'children': setChildrenData,
        'guardians': setGuardianData,
        'child_enrolments': setChildEnrolmentsData,
        // 'primary_guardian': setPrimaryGuardianData,
        // 'secondary_guardian': setSecondaryGuardianData,
        'addresses:guardian': setGuardianAddressData,
        'addresses:child': setChildrenAddressData,
        'children_medical': setChildrenMedicalData,
        'child_guardian_authorisations': setChildGuardianAuthData,
        'child_group_authorisations': setChildGroupAuthData,
        'child_guardian_relationships': setChildGuardianRelationshipData,
        'emergency_contacts': setEmergencyContactsData,
        'documents': setDocumentsData,
        'view_accounts_missing_fields': setMissingAccountData,
        'form_submissions': setFormSubmissionData,
        'permanent_bookings': setPermBookingData,
        'roll': setRollData,
    };


    // react router history instance to navigate user to other pages
    let history = useHistory();
    let location = useLocation();    
    
    // console.log('subroute: ', subroute);

    // get data from props
    const setSublinks = props.setSublinks;
    const setNotifications = props.setNotifications;
    const isMobile = props.isMobile;

    // set leftpane sublinks
    useEffect(() => {
        setSublinks([
            {title: 'Calendar', shortName: 'Calendar', icon: 'calendar',  onClick: () => setDisplayContent('calendar')},
            {title: 'Details', shortName: 'Details', icon: 'contactInfo', onClick: () => setDisplayContent('parentData')},
            {title: 'Bookings', shortName: 'Bookings', icon: 'schedule', onClick: () => setDisplayContent('bookings')},
            {title: 'CCS', shortName: 'CCS', icon: 'subsidy', onClick: () => setDisplayContent('entitlements')},
            {title: 'Billing', shortName: 'Billing', icon: 'invoice', onClick: () => setDisplayContent('billing')},
            // {title: 'Contact Us', shortName: 'Contact', icon: 'contact-us', onClick: () => setDisplayContent('contact-us')}, // put in main URL links via subroute instead
        ]);
    }, [setSublinks])

    // set content based on subroute
    useEffect(() => {
        // get subroute and set content
        const subroute = getURLSubroute(location);
        
        if (subroute) {
            setDisplayContent(subroute);
        }

    }, [location, setDisplayContent]);

    // upon accountowner render, send message to react native indicating user is authenticated
    useEffect(() => {
        try {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    messageType: 'accountOwnerAuthenticated',
                    email: props.email,
                    authorization: props.token, 
                    appclientid: cognitoClientId, 
                    userpoolid: cognitoUserpoolId
                }));
                alert('message posted to react native webView');

            }
        } catch (err) {
            console.log('error encountered while trying to send message to react native webView');
        }

    }, [props.email, props.token]);    

    // any time selected children changes, filter children data 
    // useEffect(() => {
        
    //     // child needs to be selected to display data
    //     if (childrenData?.length > 0) {   
            
    //         const childIds = selectedChildren?.map(obj => obj.value);
            

    //         // check whether all children selected or one/multiple others
    //         if (childIds.includes('*')) {
                
    //             // no need to filter
    //             setFilteredChildrenData([...childrenData]);
    //             setFilteredPermBookingData([...permBookingData]);
    //             setFilteredEntitlementsData([...entitlementsData]);
    //         } else {
                
    //             // filter for selected children
    //             setFilteredChildrenData(childrenData?.filter(obj => childIds?.includes(obj.child_id)));
    //             setFilteredPermBookingData(permBookingData?.filter(obj => childIds?.includes(obj.child_id)));
    //             setFilteredEntitlementsData(entitlementsData?.filter(obj => childIds?.includes(obj.child_id)));
    //         }
            
    //     } 
            
    // }, [selectedChildren, childrenData, permBookingData, entitlementsData])    


    // function to call API to get data
    const fetchData = useCallback(async (tableName) => {
        // if token found, call lambda function to account master data
        if (props.token) {
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {tableName: tableName}
            };
            try {
                const res = await axios.get(AWS_API + 'account-owner-data/fetch', config);
                // console.log('response: ', res.data);
                return res.data;
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
            }   
        } else {
            console.log('no token, redirecting to auth');
            history.replace('/auth');
        }
    }, [history, props.token, location]);
    
    // fetch account owner data for portal - linked children, permanent & casual bookings
    useEffect(() => {

        // loading true before calling data fetch functions
        setLoading(true); 
          
        console.log('trigger refresh: ', triggerRefresh);
        
        (async () => {
            const parentPortalData = await fetchData('parent_portal_data');
            console.log('parentPortalData: ', parentPortalData);
            setAccountData(parentPortalData?.accountData);
            setCentresData(parentPortalData?.centres);
            setChildEnrolmentsData(parentPortalData?.child_enrolments);
            setRollData(parentPortalData?.roll);
            setFormSubmissionData(parentPortalData?.form_approvals);
            setPermBookingData(parentPortalData?.permanent_bookings);
            setCasualBookingData(parentPortalData?.casual_bookings);
            setChildGuardianAuthData(parentPortalData?.child_guardian_authorisations)
            setChildGuardianRelationshipData(parentPortalData?.child_guardian_relationships)
            setChildGroupAuthData(parentPortalData?.child_group_authorisations)
            setChildrenData(parentPortalData?.children); 
            setChildrenMedicalData(parentPortalData?.children_medical); 
            // setPermissionSlipData(parentPortalData?.permission_slips_outstanding);
            setExcursionData(parentPortalData?.excursions);
            setGuardianData(parentPortalData?.guardianData);
            setEmergencyContactsData(parentPortalData?.emergencyContacts);
            setDocumentsData(parentPortalData?.documents);
            // setPrimaryGuardianData(parentPortalData?.primaryGuardianData);
            // setSecondaryGuardianData(parentPortalData?.secondaryGuardianData);
            setGuardianAddressData(parentPortalData?.['addresses:guardian']);
            setChildrenAddressData(parentPortalData?.['addresses:child']);
            setInvoicesData(parentPortalData?.invoices);
            setEntitlementsData(parentPortalData?.entitlements)
            setMissingAccountData(parentPortalData?.missingAccountData)
            setLoading(false);
        })();

        // (async () => console.log('parentPortal fetch: ', await fetchData('parent_portal_data')))();

        // upon useEffect completion, set loading to false
        return () => {
            setLoading(false);
            setTriggerRefresh(false);
        }    

    }, [fetchData, triggerRefresh, setTriggerRefresh]);

    // background data fetch on load
    useEffect(() => {

        (async () => {
            setCentreSessionData(await fetchData('centre_sessions_calendar')); 
        })();          

    }, [fetchData])    

    const fetchSpecificTableData = (table) => {

        // get state function which holds this data 
        const setStateFn = tableToStateMap[table];
        (async () => {
            const data = await fetchData(table);
            const missingData = await fetchData('view_accounts_missing_fields')
            setStateFn(data); 
            setMissingAccountData(missingData);
        })();
    }


    // function to get calendar of allowed sessions for each centre for use in booking form
    const initiateNewCasualBooking = async (dateStr, centreId, session) => {
        

        // console.log('[initiateNewCasualBooking] - date, centre, session: ', dateStr, centreId, session);


        
        // if function received a centre input (i.e. called by user clicking excursion in calendar) - set selected centre
        if (centreId) {
            const centreAllowed = childEnrolmentsData?.map(obj => obj?.centre_id)?.includes(centreId);
            // console.log('centreAllowed: ', centreAllowed);

            if (!centreAllowed) {
                setCasualBookingCentre();
            } else {

                // preopulate selected children with all children enrolled at centre, otherwise centre will be removed
                const childIds = childEnrolmentsData?.filter(obj => obj?.centre_id === centreId)?.map(obj => obj?.child_id)?.filter((x,i,a) => a.indexOf(x) === i);
                const selectedChildrenArr = childrenData?.filter(obj => childIds?.includes(obj?.child_id)).map(obj => ({value: obj.child_id, label: obj.first_name + ' ' + obj.surname}));
                handleUpdateSelectedChildren(selectedChildrenArr);
                setCasualBookingCentre(centreId);
            }
        }
        
        // if function received a session input (i.e. called by user clicking excursion in calendar) - set selected session
        let newSelectedSessions;
        if (session) {

            newSelectedSessions = [{value: session, label: raw[session]}];
            // console.log('newSelectedSessions: ', newSelectedSessions);
            setSelectedSessions(newSelectedSessions);
        }

        // if function received a date input (i.e. called by user clicking day in calendar) - set selected date
        if (dateStr) {
            const date = new Date(dateStr);


            // check whether date is disabled using either session already in state, or session passed in from event clicked on if provided
            let dateDisabled;
            if (newSelectedSessions?.length > 0 && centreId) {
                dateDisabled = isDateDisabled(date, newSelectedSessions, centreId);
            } else {
                dateDisabled = isDateDisabled(date);
            }

            // console.log('dateDisabled: ', dateDisabled);

            if (dateDisabled) {
                setCasualBookingDates([]);
            } else {
                setCasualBookingDates([date]);
            }
        }

        setShowCasualBookingForm(true);

    }



    // function to write new casual booking to RDS
    const writeCasualBooking = async (row) => {

        
        // if row passed in, function being called via to remake a cancelled booking from the calendar, otherwise we're making a new booking from the booking form
        let childIds, centreId, dates, sessions;
        if (row?.child_id && row?.centre_id && row?.session && row?.date) {
            
            // booking being made from calendar event being rebooked
            // console.log('[writeCasualBooking] - row: ', row);

            childIds = [row?.child_id];
            centreId = row?.centre_id;
            dates = [row?.date];
            sessions = [row?.session];
        } else {
            
            // booking being made from form

            // convert date from DateObj in DatePicker to YYYY-MM-DD format needed in lambda
            const datesToSubmit = [];
            for (const date of casualBookingDates) {
                datesToSubmit.push(datePickerObjToISOFormat(date));
            } 
            
            console.log('casualBookingDates: ', casualBookingDates);
            console.log('datesToSubmit: ', datesToSubmit);

            childIds = casualBookingChildren
            centreId = casualBookingCentre
            dates = datesToSubmit;
            sessions = selectedSessions?.map(obj => obj.value);
        }

        // write booking to RDS
        console.log('running write casual booking fn');
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {childIds: childIds, centreId: centreId, dates: dates, sessions: sessions};
        setLoading(true); 
                
        try {
            const res = await axios.post(AWS_API + 'bookings/write', {postData: postData}, headers);
            const data = res.data;
            console.log('write-booking response: ', data);
            handleBannerText(setBannerText, 'Booking Completed');    
          
            // re-fetch casual booking data and permission slip data to show updated booking
            (async () => {
                setCasualBookingData(await fetchData('casual_bookings')); 
                // setPermissionSlipData(await fetchData('permission_slips_outstanding')); 
                setRollData(await fetchData('roll')); 
            })();   
            
            setCasualBookingCentre();
            setCasualBookingChildren([]);
            setSelectedChildObjects([]);
            setCasualBookingDates();
            // setSelectedDatesSQLFormat();
            setSelectedSessions([]);
            setShowCasualBookingForm(false);
            
        } catch (err) {

            // re-fetch casual booking data on error, as error may be for some sessions but not all and some may have updated
            (async () => {
                setCasualBookingData(await fetchData('casual_bookings')); 
                setRollData(await fetchData('roll')); 
            })();               

            setShowCasualBookingForm(false);
            setSelectedSessions([]);
            setError(handleAwsApiError(err, history, location) ?? 'Booking could not be completed, check child is not already booked in for this date and session.');
        }   
        
        setLoading(false);
    }

    const cancelBooking = async (row) => {
        console.log('request to delete row: ', row);

        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {childId: row.child_id, centreId: row.centre_id, session: row.session, date: row.date};

        setLoading(true); 
                
        try {
            const res = await axios.post(AWS_API + 'bookings/cancel', {postData: postData}, headers);
            const data = res.data;
            console.log('data: ', data);
            handleBannerText(setBannerText, 'Cancellation Completed');    
          
            // re-fetch casual booking data to show updated booking
            (async () => {
                setCasualBookingData(await fetchData('casual_bookings')); 
                setRollData(await fetchData('roll')); 
            })();   
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Booking could not be cancelled at this time');
        }   
        
        setLoading(false);       
    }



    // create array of available children to select from and take actions with
    // let childOptions;
    // if (childrenData) {
    //     // create input options for children of signed in user
    //     childOptions = childrenData.map(obj => {
    //         //  <option value={obj.child_id} key={obj.child_id}>{obj.first_name}</option>
    //         return (
    //             // <Aux>
    //             <div key={obj.child_id} className="form-check" >
    //                 <input 
    //                     checked={casualBookingChildren.includes(obj.child_id)} 
    //                     onChange={() => casualBookingChildren.includes(obj.child_id) ? setCasualBookingChildren([...casualBookingChildren].filter(el => el !== obj.child_id)) : setCasualBookingChildren([...casualBookingChildren, obj.child_id])} 
    //                     className="form-check-input" 
    //                     type="checkbox" 
    //                     value={obj.child_id} id="flexCheckDefault"
    //                 />
    //                 <label className="form-check-label">{obj.first_name}</label>
    //             </div>                    
    //             // </Aux>

    //         );
    //     });
    // }

    const handleUpdateSelectedChildren = (e) => {
        // console.log('e: ', )
        const childIds = e.map(obj => obj?.value);
        setSelectedChildObjects(e);
        setCasualBookingChildren(childIds);
        
    }

    let childSelector;
    if (childrenData?.length > 0) {
        
        const childOptions = [];
        childrenData.forEach(obj => {childOptions.push({value: obj.child_id, label: obj.first_name + ' ' + obj.surname})});

        childSelector = (
            <div className={classes.ChildSelector}>
                <label>Children</label>
                <Select isMulti options={childOptions} onChange={handleUpdateSelectedChildren} value={selectedChildObjects} placeholder='Select Child(ren)' ></Select>                       
            </div>
        );       
    } else {
        childSelector = <p><i>No children found</i></p>
    }        

    // get centres selected children are enrolled at
    // const enrolledCentres = childEnrolmentsData?.filter(obj => casualBookingChildren.includes(obj.child_id))?.map(obj => obj.centre_id)?.filter((x,i,a) => a.indexOf(x) === i);
    
    // get centres IN COMMON between all selected children
    let enrolledCentres = [];

    casualBookingChildren.forEach((childId, index) => {

        const childEnrolments = childEnrolmentsData.filter(enrolment => enrolment.child_id === childId);
        
        if (index === 0) {
            enrolledCentres = childEnrolments.map(enrolment => enrolment.centre_id);
        } else {
            enrolledCentres = enrolledCentres.filter(centreId =>
            childEnrolments.some(enrolment => enrolment.centre_id === centreId)
            );
        }
    });    

    // if casualBookingChildren array has > 0 selected children, look for a shared home centre - otherwise remove home centre
    if (casualBookingChildren.length > 0 && enrolledCentres?.length > 0) {

        // get unique home centres
        const selectedHomeCentreIds = [...new Set(childrenData.filter(obj => casualBookingChildren.includes(obj.child_id)).map(obj => obj.home_centre_id))];
        
        // if centre not yet selected, default to home centre
        const homeCentreId = selectedHomeCentreIds[0];
        if (!casualBookingCentre) setCasualBookingCentre(homeCentreId);

    } else {
        if (casualBookingCentre) setCasualBookingCentre();
    }
    
    // // if casualBookingChildren array has > 0 selected children, look for a shared home centre - otherwise remove home centre
    // let selectedHomeCentreIds = null;
    // if (casualBookingChildren.length > 0) {
    //     selectedHomeCentreIds = [...new Set(childrenData.filter(obj => casualBookingChildren.includes(obj.child_id)).map(obj => obj.home_centre_id))];
    //     // console.log('selectedHomeCentreIds: ', selectedHomeCentreIds);
        
    //     // if there's only unique centre id for all children, add it to lookup array to allow booking, otherwise user will have to select only children who share a home centre
    //     if (selectedHomeCentreIds.length === 1) {
    //         const homeCentreId = selectedHomeCentreIds[0];
    //         if (casualBookingCentre !== homeCentreId) setCasualBookingCentre(homeCentreId);
            
    //     } else {
    //         if (casualBookingCentre) setCasualBookingCentre('');
    //     }
    // } else {
    //     if (casualBookingCentre) setCasualBookingCentre('');
    // }
    

    const centreOptions = centresData?.filter(obj => enrolledCentres?.includes(obj.centre_id))
    ?.map(obj => {
        return (
            <option value={obj.centre_id} key={obj.centre_id} >{obj.centre_name}</option>

        );
    });
    const centreSelector = <div className="form-group">
        <label>Centre</label>
        <select className="form-control" id="centreOptions" value={casualBookingCentre} onChange={(e) => {setCasualBookingCentre(+e.target.value)}}>
            {centreOptions}
        </select>
    </div>


    // function to check what dates at selected centre are running selected sessions
    const isDateDisabled = (date, manualSessionInput, manualCentreIdInput) => {

        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        // console.log('[isDateDisabled] - date: ', date);
        // console.log('[isDateDisabled] - formattedDate: ', formattedDate);
        
        // set centre and sessions to use for checking date disabled/enabled, depending on whether function was called for a specific session before it's in state
        const selectedSessionData = (manualSessionInput) ? manualSessionInput : selectedSessions;
        const centreId = (manualCentreIdInput) ? manualCentreIdInput : casualBookingCentre;
        // if (manualSessionInput) {
            
        //     console.log('[isDateDisabled] - date: ', date);
        //     console.log('[isDateDisabled] - selectedSessionData: ', selectedSessionData);
        //     console.log('[isDateDisabled] - formattedDate: ', formattedDate);
        // }

        // get centre sessions calendar entry for selected day and centre
        const filteredSessionData = centreSessionData?.filter(obj => obj?.date?.slice(0,10) === formattedDate && +obj?.centre_id === +centreId)?.[0];
        const selectedSessionLabels = selectedSessionData?.map(obj => obj.value)

        // check if day is running all selected sessions
        const allSessionsOperating = selectedSessionLabels?.length > 0 && selectedSessionLabels.every(session => filteredSessionData?.[session] === true);

        return !allSessionsOperating;
    };

    let casualBookingForm = null;
    if (showCasualBookingForm && childrenData?.length > 0) {
        

        const sessionOptions = [];
        sessionTypes.forEach(el => {sessionOptions.push({value: el, label: raw[el]})})        
        
        const defaultMonth = casualBookingDates?.length > 0 ? new Date(casualBookingDates?.[0]) : new Date();
        // console.log('defaultMonth: ', defaultMonth);

        // form inputs for date and centre    
        casualBookingForm = (
                <div> 
                    <div className="form-group" >
                        {/* <label>Select Children</label> */}
                        {/* <select className="form-control" id="childOptions" value={selectedChild} onChange={(e) => {setSelectedChild(+e.target.value)}}>
                            {childOptions}
                        </select> */}
                        <br/>
                        {childSelector}
                        {/* {childOptions} */}
                    </div>  
                    {centreSelector}

                    <div className="form-group" >
                        <label className={classes.Label}>Session(s)</label>
                        <Select isMulti options={sessionOptions} onChange={setSelectedSessions} value={selectedSessions}></Select>    
                    </div> 
                    <br/>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div>
                            <DayPicker
                                mode="multiple"
                                selected={casualBookingDates}
                                onSelect={setCasualBookingDates}
                                disabled={isDateDisabled}
                                defaultMonth={defaultMonth}
                                // defaultMonth={new Date('2023-07-03')}
                            />
                        </div>
                    </div>
                    {!selectedSessions?.map(obj => obj?.value)?.includes('vac') && <button onClick={writeCasualBooking} disabled={!(casualBookingCentre && casualBookingDates?.length > 0 && selectedSessions?.length > 0)} className="btn btn-block btn-success">Submit</button>}
                    {selectedSessions?.map(obj => obj?.value)?.includes('vac') && <button onClick={() => handlePrompt(
                        'make this booking',
                        () => writeCasualBooking(), 
                        setPrompt,
                        'Please note some or all the vacation care days you have selected may have an activity that requires parent/caregiver authorisation. By selecting proceed, you are acknowledging and authorising the permission form/s associated with your requested date/s. To view the permission forms you are acknowledging in more detail,', 
                        eventInformationLink,
                        'Acknowledgement of Terms and Conditions',
                        'By selecting proceed you are also agreeing to the Active OOSH Vacation Care terms and conditions. This includes the cancellation policy - cancellations made after the school holidays begin will incur an absent fee. To view the terms and conditions you are acknowledging in more detail,',
                        eventTermsConditionsLink,
                    )} disabled={!(casualBookingCentre && casualBookingDates?.length > 0 && selectedSessions?.length > 0)} className="btn btn-block btn-success">Submit</button>}
                </div> 

        );
    }




    // function to post form to pending form submissions table in RDS and save submission data to S3
    const postSubmission = async (submission, onSuccessFn) => {
        
        setLoading(true);

        let endingPermBooking = false;
        if (showEndBookingSchedule){
            endingPermBooking = true;
        }
        setFeedbackCentreId(submission.centreId);
        // close modals
        setShowUpdateBookingSchedule(false);
        setShowEndBookingSchedule(false);
        
        
        // send submission to server to record in RDS and then save in S3, aborting transaction if any error encountered
        console.log('posting submission: ', submission);
        
        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        const postData = {data: submission, childId: submission.childIds[0], centreId: submission.centreId, formType: 'updateBookingSchedule'};
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
            console.log('response from postSubmission to S3: ', res.data);
            
            // fetch data
            fetchSpecificTableData('permanent_bookings'); 
            fetchSpecificTableData('roll');

            // clear state
            setCasualBookingChildren([]);

            // (async () => {
            //     setFormSubmissionData(await fetchData('form_submissions')); 
            // })();      
            onSuccessFn ? onSuccessFn() : void(0);

            handleBannerText(setBannerText, 'Request Submitted');         
            // show the feedback form when ending a permanent booking
            if (endingPermBooking){
                setShowFeedbackForm(true);
            }
        } catch (err) {
            setShowUpdateBookingSchedule(false);
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission in RDS');
        }
        
        setLoading(false);    

    }

    const postUserFeedback = async(accountId, question) => {
        setLoading(true);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            account_id: accountId,
            centre_id: feedbackCentreId,
            comment: feedbackComment,
            feedback_option: feedbackOption.value,
            recommend_score: recommendScore ? recommendScore*2 : -1,
            contact_parent: contactChecked,
        };

        try {
            const res = await axios.post(AWS_API + 'bookings/submit-user-feedback', {postData: postData}, headers);
            console.log('response: ', res.data);
            setFeedbackOption("");
            setFeedbackComment();
            setContactChecked(false);
            setRecommendScore();
            handleBannerText(setBannerText, 'Feedback received. Thank you!'); 
            setShowFeedbackForm(false);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while checking children in');
            setShowFeedbackForm(false);
            setLoading(false);
        } 
        setLoading(false);       
    }

    const handleSubmitFeedbackForm = (accountId, question) => {
        postUserFeedback(accountId, question);
    }

    // change feedback option
    const handleChangeFeedbackOption = (event) => {
        console.log("event: ", event.value);
        setFeedbackOption(event);
    }

    // change state for recommendation
    const handleChangeRecommendation = (event) => {
        setRecommendScore(event.target.value);
    }

    // set feedback form
    let feedbackForm = <SpinnerDark/>;
    if (showFeedbackForm){
        console.log("feedbackCentreId: ", feedbackCentreId);
        feedbackForm = (
            <div>
                <h3>Please select your reason for ending your permanent booking schedule:</h3>
                <br/>
                <Select options={endPermScheduleFeedbackOptions} onChange={handleChangeFeedbackOption} value={feedbackOption} placeholder='Select Option' ></Select> 
                <br/>
                {
                    <div>
                
                    <h6>Would you recommend Active OOSH to friends and family?</h6>
                    <Rating name="half-rating" size="large" precision={0.5} onChange={handleChangeRecommendation}/>
                    </div>
                    
                }
                {(feedbackOption.value === "Child no longer wishes to attend") && (
                    <FormControlLabel
                    label={"Do you consent for the Active OOSH customer service team to call you to help us improve our services?"}
                    control={<Checkbox onChange={(event) => setContactChecked(event.target.checked)}/>}
                    />
                    )
                }

                <h5>Comments:</h5>
                <textarea 
                    type='text' 
                    className={['form-control', classes.CommentInputText].join(' ')}
                    value={feedbackComment}
                    cols='2'
                    rows='3'
                    onChange={(e) => setFeedbackComment(e.target.value)}
                />
                <br/>
                <button className="btn btn-block btn-success" onClick={() => handleSubmitFeedbackForm(accountData.account_id, question)}>Submit</button>

            </div>
        )
    }


    // filter events down to only those at a child's home centre
    const homeCentreIds = childrenData?.map(obj => obj?.home_centre_id);
    const homeCentreEvents = excursionData?.filter(obj => homeCentreIds?.includes(obj?.centre_id));

    // have main content in account owner page show content depending on stage user is in having their enrolment approved
    let accountOwnerContent = (
        <div className={classes.AccountOwner}>
            {/* <p>Enrolments are currently closed while the system is undergoing maintenance, please check back later.</p> */}
            {['dev', 'test'].includes(envName) && <h6 className={classes.EnvWarning}>WARNING - This is the {envName.toUpperCase()} version of OSHC+, and is only for use by the development team. Please navigate to the live version <a style={{color: 'white', textDecoration: 'underline'}} href={prodURL}>here</a></h6>}
            <h6>Please fill out the enrolment form <Link to="/enrol">here</Link> to get started</h6>
        </div>
    ); 
    if (!loading && childrenData && childrenData.length > 0) {

        if (displayContent === 'calendar') {
            accountOwnerContent = (
                <div className={classes.AccountOwner} >
                    <h4>My Calendar</h4>
                        {rollData && childrenData && centresData && casualBookingData
                        ? <Calendar
                            showSchedule={() => {setDisplayContent('bookings')}} 
                            updateSchedule={() => setShowUpdateBookingSchedule(!showUpdateBookingSchedule)} 
                            newCasual={initiateNewCasualBooking} 
                            children={childrenData} 
                            centres={centresData} 
                            rollData={rollData} 
                            casualData={casualBookingData}
                            excursionData={homeCentreEvents}
                            // cancelBooking={cancelBooking}
                            remakeCancelledBooking = {(row) => handlePrompt('re-book this cancelled session', () => writeCasualBooking(row), setPrompt)}
                            cancelBooking={(row) => handlePrompt('cancel this booking', () => cancelBooking(row), setPrompt, 'Please note: Absent fees may apply')}
                            isMobile={isMobile}
                        /> 
                        : null}
                </div>                    
            );      


        } else if (displayContent === 'bookings') {
            accountOwnerContent = (
                <div className={classes.AccountOwner} >
                    <h4>My Bookings</h4>
                        <br/>
                        <h5>Casual Bookings</h5>
                        <p className={classes.WarningText}><i>To view or cancel any casual bookings, go to the calendar section</i></p>
                        <button onClick={() => initiateNewCasualBooking()} className="btn btn-block btn-success">New Casual Booking</button>
                        
                        <hr/> 

                        <h5>Permanent Bookings</h5>
                        {/* <p className={classes.WarningText}><i>To end your booking schedule, please click "Create / Update Routine Bookings" below and submit the form with no days selected</i></p> */}
                        <p className={classes.WarningText}>Please note: New or updated permanent booking schedules that start in the future will not display until the start date</p>
                        {/* <ChildSelector childrenData={childrenData} selectedChildren={selectedChildren} setSelectedChildren={setSelectedChildren}/> */}
                        <button onClick={() => setShowUpdateBookingSchedule(!showUpdateBookingSchedule)} className="btn btn-block btn-success">Create / Update Permanent Booking Schedule</button>
                        <button onClick={() => setShowEndBookingSchedule(!showEndBookingSchedule)} className="btn btn-block btn-danger">End All Permanent Booking Schedules</button>
                        {permBookingData && childrenData && centresData  ? <ScheduleView centres={centresData} children={childrenData} data={permBookingData}/> : null}
                        {/* {permBookingTable} */}
                </div>
            );

        } else if (displayContent === 'parentData') {
            accountOwnerContent = (
                // <div>
                <div className={classes.AccountOwner} >
                    <h4>My Details</h4>
                    <ParentData token={props.token} 
                        missingAccountData={missingAccountData} 
                        documentsData={documentsData}
                        guardianData={guardianData} 
                        emergencyContactsData={emergencyContactsData}
                        // primaryGuardianData={primaryGuardianData} secondaryGuardianData={secondaryGuardianData} 
                        childGuardianAuthData={childGuardianAuthData} 
                        childGroupAuthData={childGroupAuthData} guardianAddressData={guardianAddressData} 
                        childrenData={childrenData} childrenMedicalData={childrenMedicalData} childrenAddressData={childrenAddressData}
                        childGuardianRelationshipData={childGuardianRelationshipData}
                        fetchSpecificTableData={fetchSpecificTableData} 
                    />
                </div>
            );
        } else if (displayContent === 'entitlements') {
            accountOwnerContent = (
                <div className={classes.AccountOwner}>
                <h4>My CCS</h4>
                    <br/>
                    {/* <ChildSelector childrenData={childrenData} selectedChildren={selectedChildren} setSelectedChildren={setSelectedChildren}/> */}
                    <Entitlements centresData={centresData} entitlementsData={entitlementsData}/>
                </div>
            );
        } else if (displayContent === 'billing') {
            accountOwnerContent = (
                <div className={classes.AccountOwner}>
                    <h4>My Billing</h4>
                    <br/>
                    <Statements accountData={accountData} invoicesData={invoicesData} token={props.token} setError={setError} history={history}/>
                </div>
            );
        } else if (displayContent === 'contact-us') {
            accountOwnerContent = (
                <div className={classes.AccountOwner}>
                    <ContactUs centresData={centresData} childrenData={childrenData}/>
                </div>
            );
        }



    } 


    let content = <div style={{paddingTop: '15vh'}}><SpinnerDark /></div>
    if (!loading) {
        content = (
            <Aux>
                {/* <button className="btn btn-dark" onClick={testfn}>Test</button> */}
                <Modal show={error} modalClosed={() => setError(false)}>
                    <h3>Oops, something went wrong!</h3>
                    <hr/>
                    <p>{error}</p>
                </Modal>
                {/* <Modal large show={permissionSlipId} modalClosed={() => setPermissionSlipId()}>
                    <PermissionSlip handleBanner={(text) => handleBannerText(setBannerText, text)} excursionData={excursionData} centresData={centresData} setTriggerRefresh={setTriggerRefresh} token={props.token} permissionSlipId={permissionSlipId} permissionSlipData={permissionSlipData} childrenData={childrenData} setError={setError} setPermissionSlipId={setPermissionSlipId} history={history}/>
                </Modal> */}
                <Modal  tall show={showCasualBookingForm} modalClosed={() => setShowCasualBookingForm(false)}>
                    <div >
                    <h4>New Casual Booking</h4>
                        {casualBookingForm}
                    </div>

                </Modal>    
                {/* <Modal large show={showUpdateBookingSchedule} modalClosed={() => setShowUpdateBookingSchedule(false)}>
                    <div className={classes.Form} style={{width: '100%', margin: 'auto'}}>
                    <h5>Update Permanent Booking Schedule</h5>
                        {updateBookingScheduleForm}
                    </div>                        
                </Modal>                             */}
                <Modal large show={showUpdateBookingSchedule} modalClosed={() => setShowUpdateBookingSchedule(false)}>
                    {showUpdateBookingSchedule && <UpdateSchedule childrenData={childrenData} centresData={centresData} postSubmission={postSubmission}/> }                  
                </Modal>                            
                <Modal show={showFeedbackForm} modalClosed={() => setShowFeedbackForm(false)}>
                    {showFeedbackForm && feedbackForm}                  
                </Modal>                            
                {/* <Modal tall show={showEndBookingSchedule} modalClosed={() => setShowEndBookingSchedule(false)}>
                    <div className={classes.Form} style={{width: '100%', margin: 'auto'}}>
                    <h5>End Permanent Booking Schedule</h5>
                        {endBookingScheduleForm}
                    </div>                        
                </Modal>                             */}
                <Modal tall show={showEndBookingSchedule} modalClosed={() => setShowEndBookingSchedule(false)}>
                    {showEndBookingSchedule && <EndSchedule childrenData={childrenData} centresData={centresData} postSubmission={postSubmission}/>}               
                </Modal>                            
                <Banner show={bannerText}>{bannerText}</Banner>                
                <Prompt data={prompt} setData={setPrompt} />
                <Notifications setNotifications={setNotifications} 
                    missingAccountData={missingAccountData}
                    // primaryGuardianData={primaryGuardianData} secondaryGuardianData={secondaryGuardianData} 
                    childGuardianAuthData={childGuardianAuthData} childGroupAuthData={childGroupAuthData} guardianAddressData={guardianAddressData} childrenData={childrenData} childrenMedicalData={childrenMedicalData} 
                    formSubmissionData={formSubmissionData} 
                    entitlementsData={entitlementsData} setDisplayContent={setDisplayContent}

                    // hiding permission slips, which are now done via an authorisation step when making VAC bookings
                    // permissionSlipData={permissionSlipData} setPermissionSlipId={setPermissionSlipId} 
                />
                {accountOwnerContent}
            </Aux>
        );
    }


    return content
}


  
export default AccountOwner;