import React, { Component } from 'react';

import classes from './Modal.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.show !== this.props.show || nextProps.children !== this.props.children);
    }


    

    render () {

        const classList = [classes.Modal];

        if (this.props.large) {
            classList.push(classes.ModalLarge);
        }

        if (this.props.tall) {
            classList.push(classes.ModalTall);
        }



        if (this.props.showOnTop) {
            classList.push(classes.ShowOnTop)
        }


        return (
            <Aux>
            <Backdrop showOnTop={this.props.showOnTop} show={this.props.show} clicked={this.props.modalClosed}/>
            <div className={classList.join(' ')}
                style={{
                transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: this.props.show ? '1' : '0'
            }}>
                {this.props.children}
            </div>
            </Aux>
        );
    }

}
export default Modal;