import React, { 
    useEffect,
    useCallback,
    useState, 
    } from 'react'
import classes from './Cabinet.module.css';
// import { Form } from 'react-formio';
import { useHistory, useLocation } from "react-router-dom";
import { formioAPI } from '../../../utilities/globalVariables';
import { formioConfig } from '../../../utilities/globalObjects';
import { raw } from '../../../translations/en/raw';
import { onEnterKeyPress } from '../../../utilities/functions';
import Formio from 'formiojs/Formio';
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import Banner from '../../../UI/Banner/Banner';
import { handleAwsApiError, handleBannerText,  scrollPageToTop } from '../../../utilities/functions';
import Modal from '../../../UI/Modal/Modal';
import axios from 'axios';
import { cognitoClientId, cognitoUserpoolId, AWS_API } from '../../../utilities/globalVariables';
// import Select from 'react-select'
// import SmallSpinnerDark from '../../../UI/SmallSpinnerDark/SmallSpinnerDark';
import SubmissionsTable from './SubmissionsTable/SubmissionsTable';
import CRM from './CRM/CRM';
import AccountMgmt from './AccountMgmt/AccountMgmt';
import SelectAccount from './SelectAccount/SelectAccount';
import FailedSubmissions from './FailedSubmissions/FailedSubmissions';
import AdminEnrol from './AdminEnrol/AdminEnrol';
import IncompleteAccounts from './IncompleteAccounts/IncompleteAccounts';
import Dashboard from './Dashboard/Dashboard';
import KioskUserFeedback from './KioskUserFeedback/KioskUserFeedback';



const Cabinet = (props) =>  {

    const [filingCabinetData, setFilingCabinetData] = useState();
    const [viewingFormSubmission, setViewingFormSubmission] = useState();
    const [viewingFormRow, setViewingFormRow] = useState();
    const [selectedAccount, setSelectedAccount] = useState();
    const [selectedFormType, setSelectedFormType] = useState();
    const [accountData, setAccountData] = useState();
    const [centres, setCentres] = useState();

    // on load defaults
    const [mode, setMode] = useState('accounts');

    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState();

    // Bootstrap table state
    const [selected, setSelected] = useState([]);      

    // create react-router history object to navigate user to other pages
    const history = useHistory();
    const location = useLocation();    

    // console.log('selected: ', selected);
    // console.log('viewingFormSubmission: ', viewingFormSubmission);
    // console.log('viewingFormRow: ', viewingFormRow);
    // console.log('centres: ', centres);
    // console.log('[Cabinet.js] - mode: ', mode);
    console.log('[Cabinet.js] - selectedAccount: ', selectedAccount);
    // console.log('[Cabinet.js] - accountData: ', accountData);
    // console.log('filingCabinetData: ', filingCabinetData);
    // console.log('selectedAccount: ', selectedAccount);
    // console.log('selectedFormType: ', selectedFormType);

    // get data from props
    const setSublinks = props.setSublinks;
    const centreAdminFlag = props.centreAdminFlag;

    const handleModeChange = useCallback((newMode) => {
        resetSelections();
        setMode(newMode);
    }, []);

    // set leftpane sublinks
    useEffect(() => {
        let newSublinks = [
            {title: 'Accounts', onClick: () => handleModeChange('accounts')},
            {title: 'Incomplete Accounts', onClick: () => handleModeChange('incomplete-accounts')},
            {title: 'Migrate Accounts', onClick: () => handleModeChange('admin-enrol')},
            {title: 'Form Viewer', onClick: () => handleModeChange('form-viewer')},
            {title: 'CRM', onClick: () => handleModeChange('crm')},
            {title: 'Failed Submissions', onClick: () => handleModeChange('failed-submissions')},
            {title: 'Directors Dashboard', onClick: () => handleModeChange('dashboard')},
            {title: 'User Feedback', onClick: () => handleModeChange('kiosk-user-feedback')},
        ]

        if (centreAdminFlag) {
            newSublinks = [
                {title: 'Accounts', onClick: () => handleModeChange('accounts')},
                // {title: 'Attendance Dashboard', onClick: () => handleModeChange('dashboard')}
            ]
        }
        setSublinks(newSublinks);
    }, [setSublinks, handleModeChange, centreAdminFlag])       

    const getMasterData = useCallback(async (table, setStateFn, conditions, notNullColumns) => {
        
        setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: table}
        };
        
        // add conditions if present
        if (conditions) {
            config.params.conditionCol = conditions.column;
            config.params.conditionValue = conditions.value;
            
        }

        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            let filteredData = res.data;
            if (notNullColumns?.length > 0) {
                filteredData = res.data.filter(obj => obj[notNullColumns[0]]); // initially just handling one column specified as not null
            }
            setStateFn(filteredData); 
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        setLoading(false);

    }, [history, props, location]);        


    const getSubmissionObject = useCallback(async (submissionRow) => {
    
        setLoading(true);

        const row = submissionRow;
        const id = row?.id;
        console.log('row: ', row);
        const category = row?.category;
        const type = row?.type;
        const key = row?.key;
        const centreId = row?.centre_id;
        const submittedBy = row?.submitted_by;
        console.log('fetching id, category and type: ', id, category, type);

        const originalId = id.split("-")[1]; // id in form f-17 for form, d-23 for document, so only take number to get original ID in documents_metadata or form_submissions table
        
        // object with headers for authorising with lambda authoriser on API Gateway in subsequent API calls
        const headers = {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId};
        
        try {
            
            // set variables based on whether object to download is a form, document, or something else
            let config, postData;
            if (category === 'document') {
                config = {headers: headers, params: {urlType: 'enrolment-document', fileKey: key}};       
                const res = await axios.get(AWS_API + 'form-submissions/presigned-url', config);   
                console.log('res from document fetch: ', res);
                const url = res.data.url;
                window.open(url,'_newtab');

            } else if (category === 'form') {
                postData = {ids: [originalId], centreId: centreId, formType: type, submittedBy: submittedBy};
                const res = await axios.post(AWS_API + 'form-submissions/fetch', {postData: postData}, {headers: headers});
                console.log('response from form-submissions/fetch: ', res.data);
                setViewingFormSubmission(res.data.formSubmissions[0]);
                setViewingFormRow(row);
                handleBannerText(setBannerText, 'Form loaded successfully')
    
            } else {
                setError('File category not found');
            }
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while fetching data');
        }

        setLoading(false);

    }, [history, props, location]);    


    const refreshMasterData = useCallback( async () => {

        if (mode === 'form-viewer') {
            
            // 
            if (selectedFormType) {
                
                console.log('fetching filing cabinet data for form type: ', selectedFormType);
                getMasterData('view_filing_cabinet', setFilingCabinetData, {column: 'type', value: selectedFormType});
                
                // on form type change, reset viewing submission so we don't keep viewing data from a different form type
                setViewingFormSubmission();
                setSelected([]);
            }
        } 

    }, [mode, selectedFormType, getMasterData]);    


    const postSubmission = useCallback(async (submission, onSubmissionDone) => {
        
        
        setLoading(true);

        // if we're re-submitting an existing form submission, make sure we're not losing any additional metadata in the form json
        let originalFormMetadata;
        if (viewingFormSubmission) {
            originalFormMetadata = Object.assign({}, viewingFormSubmission);
            delete originalFormMetadata.data;
            
            // make sure metadata contains centreID from form_submissions table 
            originalFormMetadata.centreId = viewingFormRow?.centre_id;
        } else {
            // if creating a new submission, make sure we're including centre_id form should be linked to (will fail otherwise anyway)
        }

        console.log('originalFormMetadata: ', originalFormMetadata);

        const headers = {headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}};
        
        // construct postdata - if centre exists in top level of form data, use it for meta data and S3 partition key
        const postData = {formType: selectedFormType, centreId: submission?.centre, data: submission, ...originalFormMetadata}; 
        console.log('postSubmission with post data: ', postData);
        
        try {
            const res = await axios.post(AWS_API + 'form-submissions/record', {postData: postData}, headers);
            console.log('response from postSubmission to S3: ', res.data);
            handleBannerText(setBannerText, 'Form Submitted');
            onSubmissionDone();
            refreshMasterData();
            scrollPageToTop();
            // formId = res.data.formWrittenId;
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while recording form submission, transaction aborted');
        }

        // show success banner and then redirec to accountOwner home
        setLoading(false);
    

    }, [history, props.token, selectedFormType, location, viewingFormSubmission, viewingFormRow, refreshMasterData]);            

    // render form  
    useEffect(() => {

        if (viewingFormSubmission || selectedFormType) {

            // if form loaded, display that form with contents, otherwise display empty selected form if in form-viewer mode
            const formType = viewingFormSubmission?.formType ?? selectedFormType; 
            const formToDisplay = formioConfig[formType]?.useForm ?? formType;
            console.log('loading up form for formType and formToDisplay: ', formType, formToDisplay);

            Formio.icons = 'fontawesome';
            Formio.createForm(document.getElementById('formio'), formioAPI + formToDisplay)
            .then(function(form) {
                console.log('rendering form: ', form);
                // Prevent the submission from going to the form.io server.
                form.nosubmit = true;

                
                const submit = (submission) => {
                    const isValid = form.checkValidity(null, true, null, false);
                    console.log('isValid: ', isValid);  
                    
                    const onSubmissionDone = () => {
                        form.emit('submitDone', submission)
                    }
                    
                    // if form is invalid, figure out which fields haven't been touched and alert user, otherwise submit form
                    if (!isValid) {
                        form.setPristine(false);
                        alert('form validation failed');
                        
                    } else {
                        
                        // form passed validation, submit
                        console.log('posting submission: ', submission);
                        postSubmission(submission, onSubmissionDone)
                    }

                    
                }
            
                form.on('NextTab', scrollPageToTop);
                form.on('submit', (submission) => submit(submission.data)); // "submission" object is different when on submit vs a custom event, therefore this is required to make data structrue match all of our customevent forms
                form.on('CustomSubmit', submit);

                // if a draft has been restored, populate form with draft submission
                if (viewingFormSubmission) {
                    const formData = {data: viewingFormSubmission?.data};
                    form.ready.then(() => form.setSubmission(formData));
                    console.log('inserted submission into form: ', formData);
                }

            });        
        }
    }, [postSubmission, viewingFormSubmission, selectedFormType])


    
    


    const resetSelections = () => {
        setSelected([]);
        setSelectedAccount();
        setSelectedFormType();
        setViewingFormSubmission();
        setFilingCabinetData();
    }


    // on component load actions
    useEffect(() => {

        // regardless of mode, get centres data
        getMasterData('centres', setCentres, null, ['service_crn'])
        
        // if mode is accounts or CRM, load accounts data which will be used within
        if (mode === 'accounts' || mode === 'crm') {
            getMasterData('view_accounts', setAccountData);

        } else if (mode === 'form-viewer' && selectedFormType) {
            getMasterData('view_filing_cabinet', setFilingCabinetData, {column: 'type', value: selectedFormType});

        } 

    }, [mode, selectedFormType, getMasterData])

    // let backdropSpinner; 
    // if (loading) {
    //     backdropSpinner = <BackdropSpinner />;
    // }

    return (
        <div className={classes.Cabinet}>
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>                 
            <Banner show={bannerText}>{bannerText}</Banner>  
            {/* <div className={classes.HeaderButtonRowRight}>
                {mode !== 'form-viewer' && <button className="btn btn-success btn-sm" onClick={() => handleModeChange('form-viewer')}>Form Viewer</button>}
                {mode !== 'accounts' && <button className="btn btn-success btn-sm" onClick={() => handleModeChange('accounts')}>Filing Cabinet</button>}
            </div>      */}

            {mode === 'accounts' && <SelectAccount accountData={accountData} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount}/>}
            {mode === 'form-viewer' && <SelectFormType selectedFormType={selectedFormType} setSelectedFormType={setSelectedFormType} />}
            {/* <hr /> */}
            {/* <SubmissionsTable table={viewingTable} selected={selected} setSelected={setSelected} data={filingCabinetData} getSubmissionObject={getSubmissionObject}/> */}
            
            {loading && <BackdropSpinner spinner='small-light' />}
            {/* {<BackdropSpinner spinner='small-light' />} */}
            {mode === 'accounts' && selectedAccount && <AccountMgmt centreAdminFlag={centreAdminFlag} token={props.token} centres={centres} selectedAccount={selectedAccount} getSubmissionObject={getSubmissionObject} setViewingFormSubmission={setViewingFormSubmission}/>}
            {mode === 'admin-enrol' && <AdminEnrol token={props.token} centres={centres} />} 
            {mode === 'incomplete-accounts' && <IncompleteAccounts token={props.token} centres={centres} />} 
            {mode === 'form-viewer' && filingCabinetData && <SubmissionsTable table={'view_filing_cabinet'} selected={selected} setSelected={setSelected} data={filingCabinetData} getSubmissionObject={getSubmissionObject}/> }
            {mode === 'crm' && <CRM token={props.token} accountData={accountData}/> }
            {mode === 'failed-submissions' && <FailedSubmissions token={props.token} />}
            {mode === 'dashboard' && <Dashboard token={props.token} />}
            {mode === 'kiosk-user-feedback' && <KioskUserFeedback centreAdminFlag={centreAdminFlag} token={props.token} />}
            
            
            <hr />
            {(viewingFormSubmission || selectedFormType) && <div className={classes.FormContainer}>
                <div className={classes.Form} id="formio"></div>
            </div>}

        </div>
    );
}

export default Cabinet;

// const SelectAccount = (props) => {

//     // props
//     const selectedAccount = props.selectedAccount;
//     const setSelectedAccount = props.setSelectedAccount;
//     const accountData = props.accountData;

    

//     // get list of forms available in form.io (excluding those that are really just an alias, e.g. bulkEnrol which uses enrol)
//     const accountOptions = accountData?.map(obj => ({value: obj.account_id, label: '#' + obj.account_id + ' - ' + obj.primary_name + ' - ' + obj.children_surnames}));

//     let select = <SmallSpinnerDark />;
//     if (accountData) {
//         select = <Select options={accountOptions} onChange={setSelectedAccount} value={selectedAccount} ></Select>;
//     }

//     return (
//         <div  className={classes.SelectContainer}> 
//             <h6 className={classes.Item}>Select Account</h6> 
//             <hr/>
//             <div style={{display: 'flex', flexFlow: 'row wrap', gap: '10px'}}>
//                 <div style={{ flex: '3 250px'}}>
//                     {select}
//                 </div>


//             </div>

//         </div>
//     );
// }

const SelectFormType = (props) => {

    // props
    const selectedFormType = props.selectedFormType;
    const setSelectedFormType = props.setSelectedFormType;

    const [freeText, setFreeText] = useState('');

    // get list of forms available in form.io (excluding those that are really just an alias, e.g. bulkEnrol which uses enrol)
    // const formOptions = Object.keys(formioConfig).filter(key => formioConfig[key]?.useForm === key || !formioConfig[key]?.useForm).map(form => <option key={form} value={form}>{raw[form] ?? form}</option>)
    
    // get list of forms available in form.io 
    const formOptions = Object.keys(formioConfig).map(form => <option key={form} value={form}>{raw[form] ?? form}</option>)
    
    return (
        <div className={classes.SelectContainer}> 
            <h6 className={classes.Item}>Select Form Type</h6> 
            <hr/>
            <div style={{display: 'flex', flexFlow: 'row wrap', gap: '10px'}}>
                <div style={{ flex: '3 250px'}}>
                    <span className={classes.Label}>Choose from dropdown</span>
                    <select className='form-control' value={selectedFormType ?? 'default'} onChange={(e) => setSelectedFormType(e.target.value)}>
                        <option disabled value='default'>Select Form</option>
                        {formOptions}
                    </select>
                </div>
                <div style={{ flex: '3 250px'}}>
                    <span className={classes.Label}>Or manually type form name and hit enter if recently added</span>
                    <input className='form-control' type='text' onChange={(e) => setFreeText(e.target.value)} onKeyUp={(e) => onEnterKeyPress(e, [setSelectedFormType], [e.target.value])}  value={freeText} />
                </div>
                <div style={{flex: '1 50px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <span className={classes.Label}> </span>
                    <button className='btn btn-success btn' onClick={() => setSelectedFormType(freeText)}>Enter</button>
                </div>

            </div>
        </div>
    );
}

