import React , {useState}  from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import Notifications from '../../../hoc/Layout/Notifications/Notifications';
import NotificationIcon from '../../../hoc/Layout/Notifications/NotificationIcon/NotificationIcon';


const Toolbar = (props) => {


    // state for desktop 
    const [showNotifications, setShowNotifications] = useState();

    // pass through props
    const actionNotificationsNumber = props.actionNotificationsNumber;
    const showLeftBar = props.showLeftBar;

    return (
        <header className={classes.Toolbar}>
            <Notifications notifications={props.notifications} isMobile={false} show={showNotifications} close={() => setShowNotifications(false)} />
            <DrawerToggle clicked={props.drawerToggleClicked} />
            <div className={classes.Logo}>
                {!showLeftBar && <Logo />}
            </div>
            <div className={[classes.DesktopOnly, classes.FlexEnd].join(' ')}>
                <div className={[classes.DesktopOnly, classes.NotificationIcon].join(' ')}>
                    <NotificationIcon actionNotificationsNumber={actionNotificationsNumber} setShowNotifications={setShowNotifications} />
                </div>
                <nav className={classes.DesktopOnly}>
                    <NavigationItems signout={props.signout} role={props.role} email={props.email}/>
                </nav>
            </div>
        </header>

    );
};


  
export default (Toolbar);
